import {
  CheckCircle,
  CloseFullscreen,
  Edit,
  OpenInFull,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Eye, XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { FloatTextField } from "../../../../../common/FloatTextField";
import TaskService from "../../../../../services/TaskService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { checkOtp } from "../../../../../utils/OtpServiceUtils";
import { useTranslation } from "react-i18next";
import { getActiveSalaryComponents } from "../../../../../services/AdminService";

export default function PmsSalaryAdjustmentForm({
  data,
  handleClose,
  refreshData,
}) {
  const loginUser = GetLoginUserDetails();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);

  const [pmsDetails, setPmsDetails] = useState(null);
  const [pmsImpactedEmployeeList, setPmsImpactedEmployeeList] = useState([]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [employeeSalaryComponentRawList, setEmployeeSalaryComponentRawList] =
    useState([]);
  const [employeeSalaryComponentList, setEmployeeSalaryComponentList] =
    useState([]);
  const [userCurrentCtc, setUserCurrentCtc] = useState(0);
  const [userUpdatedCtc, setUserUpdatedCtcCtc] = useState(0);

  const [salaryComponentList, setSalaryComponentList] = useState([]);
  const [selectedSalaryComponent, setSelectedSalaryComponent] = useState(null);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [showEnterCtcTextField, setShowEnterCtcTextField] = useState(false);
  // const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (data && data.formDataTransId) fetchPmsInfo();
  }, [data]);

  // const fetchPmsInfo = async () => {
  //   const res = await TaskService.getPmsDetailsToCommunicateEmployee(
  //     data.formDataTransId
  //   );
  //   if (res.data) {
  //     let tempList = [];
  //     if (res.data.impactedEmployees && res.data.impactedEmployees.length > 0) {
  //       for (
  //         let index = 0;
  //         index < res.data.impactedEmployees.length;
  //         index++
  //       ) {
  //         const data = res.data.impactedEmployees[index];
  //         if (data.pmsFlag === "C") {
  //           tempList.push(data.userId);
  //         }
  //       }
  //     }
  //     setPmsImpactedEmployeeList(tempList);
  //     setPmsDetails(res.data);
  //   }
  // };

  const fetchPmsInfo = async () => {
    try {
      const { otp, requestId } = await checkOtp(
        "/pms/getPmsDetailsToCommunicateEmployee"
      );
      console.log(
        "getPmsDetailsToCommunicateEmployeeOTP ------->>>>",
        otp,
        requestId
      );

      const reqDto = {
        requestId: requestId,
        otp: otp,
        orgId: loginUser.orgId,
        pmsId: parseInt(data.formDataTransId),
      };

      const res = await TaskService.getPmsDetailsToCommunicateEmployee(reqDto);
      if (res.data) {
        let tempList = [];
        const impactedEmployees = res.data.impactedEmployees || [];
        if (impactedEmployees.length > 0) {
          for (let index = 0; index < impactedEmployees.length; index++) {
            const employeeData = impactedEmployees[index];
            if (employeeData.pmsFlag === "C") {
              // tempList.push(employeeData.userId);
              tempList.push(employeeData);
            }
          }
        }
        setPmsImpactedEmployeeList(tempList);
        setPmsDetails(res.data);
      }
    } catch (error) {
      console.error("Error fetching PMS info:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { otp, requestId } = await checkOtp(
        "/payroll/changeSalaryPositionBasedOnPMS"
      );
      const dto = {
        requestId: requestId,
        otp: otp,
        orgId: loginUser.orgId,
        userId: selectedEmployeeData.userId,
      };
      const res1 = await TaskService.getEmployeeSalaryComponents(dto);
      const res2 = await getActiveSalaryComponents(
        loginUser.orgId,
        loginUser.userId,
        loginUser.orgCountryCode
      );
      if (res2.data) {
        setSalaryComponentList(res2.data);
      }

      if (res1.data) {
        setShowEnterCtcTextField(true);
        if (res1.data.length === 0) {
          calculateAmount(res2.data);
        } else {
          calculateAmount(res1.data);
        }
      }
    };

    if (selectedEmployeeData) {
      fetchData();
    }
  }, [selectedEmployeeData]);

  const calculateAmount = async (dataList) => {
    const finalDataList = [];
    let totalCtc = 0;

    const sortedDataList = dataList.reduce((acc, item) => {
      const category = item.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
    Object.keys(sortedDataList).forEach((category, index) => {
      sortedDataList[category].forEach((item) => {
        item.dataType = "_data_";
        item.annualAmount = item.yearlyAmount || 0;
        item.updatedValueMonthly =
          item.updatedValueMonthly && item.updatedValueMonthly > 0
            ? item.updatedValueMonthly
            : 0;
        item.updatedValueYearly =
          item.updatedValueYearly && item.updatedValueYearly > 0
            ? item.updatedValueYearly
            : 0;

        totalCtc += item.annualAmount;
        finalDataList.push(item);
      });
      finalDataList.push({ dName: category, annualAmount: 0 });
    });

    if (selectedEmployeeData?.hikePc) {
      setUserUpdatedCtcCtc(
        (totalCtc * (100 + parseFloat(selectedEmployeeData.hikePc))) / 100
      );
    }
    setUserCurrentCtc(totalCtc);
    setEmployeeSalaryComponentList(finalDataList);
    setEmployeeSalaryComponentRawList(dataList);
  };

  useEffect(() => {
    if (isReadOnly && employeeSalaryComponentRawList.length !== 0) {
      calculateCurrentCtc(employeeSalaryComponentRawList);
    }
  }, [isReadOnly, employeeSalaryComponentRawList]);

  const calculateCurrentCtc = (dataList) => {
    const finalDataList = [];
    let totalCtc = 0;
    let updatedBasicAmt = 0;
    let totalYearlyAmount = 0;

    const sortedDataList = dataList.reduce((acc, item) => {
      const category = item.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
    Object.keys(sortedDataList).forEach((category, index) => {
      sortedDataList[category].forEach((item) => {
        item.dataType = "_data_";
        // item.monthlyAmount = 0;
        item.annualAmount = item.yearlyAmount || 0;
        item.updatedValueMonthly =
          item.updatedValueMonthly && item.updatedValueMonthly > 0
            ? item.updatedValueMonthly
            : 0;
        item.updatedValueYearly =
          item.updatedValueYearly && item.updatedValueYearly > 0
            ? item.updatedValueYearly
            : 0;

        // if (item.amount && item.amount > 0) {
        //   if (item.periodicity && item.periodicity === "Monthly") {
        //     item.monthlyAmount = item.amount;
        //     item.annualAmount = item.amount * 12;
        //   }
        //   if (item.periodicity && item.periodicity === "Annually") {
        //     item.annualAmount = item.amount;
        //   }
        // }

        totalCtc += item.annualAmount;
        finalDataList.push(item);
      });
      finalDataList.push({ dName: category, annualAmount: 0 });
    });

    let remaining = 0;
    if (userUpdatedCtc) {
      remaining = parseFloat(userUpdatedCtc);
    }

    const ctcPercent = finalDataList.filter(
      (item) => item.calculationType === "% of CTC"
    );
    const basedOnBasic = finalDataList.filter(
      (item) => item.calculationType === "% of Basic"
    );
    const fixedValued = finalDataList.filter(
      (item) => item.calculationType === "Flat" && item.flatAmountPc !== null
    );
    const nonFixed = finalDataList.filter(
      (item) => item.calculationType === "Flat" && item.flatAmountPc === null
    );

    let temp = [...finalDataList];
    let basicAmount = 0;

    ctcPercent.forEach((element) => {
      //
      const indx = temp.findIndex(
        (tmp) => element.salaryComponentId === tmp.salaryComponentId
      );
      if (indx >= 0) {
        const value = Math.round(
          (parseFloat(userUpdatedCtc) * element.flatAmountPc) / 100
        );
        //
        if (value <= remaining) {
          temp[indx].updatedValueMonthly = Math.round(value / 12);
          temp[indx].updatedValueYearly = value;
          remaining -= value;

          if (element.componentName?.toLowerCase() === "basic") {
            basicAmount = value;
          }
        } else {
          temp[indx].updatedValueYearly = remaining;
          temp[indx].updatedValueMonthly = Math.round(remaining / 12);
          if (element.componentName?.toLowerCase() === "basic") {
            basicAmount = remaining;
          }
          remaining = 0;
        }
      }
    });

    basedOnBasic.forEach((element) => {
      const indx = temp.findIndex(
        (tmp) => element.salaryComponentId === tmp.salaryComponentId
      );
      if (indx) {
        const value = Math.round(
          (parseFloat(basicAmount) * element.flatAmountPc) / 100
        );

        if (value <= remaining) {
          temp[indx].updatedValueMonthly = Math.round(value / 12);
          temp[indx].updatedValueYearly = value;
          remaining -= value;
        } else {
          temp[indx].updatedValueYearly = remaining;
          temp[indx].updatedValueMonthly = Math.round(remaining / 12);
          remaining = 0;
        }
      }
    });

    fixedValued.forEach((element) => {
      const indx = temp.findIndex(
        (tmp) => element.salaryComponentId === tmp.salaryComponentId
      );
      if (indx) {
        let value = element.flatAmountPc;
        if (element.periodicity == "Monthly") {
          value = element.flatAmountPc * 12;
        }

        if (value <= remaining) {
          temp[indx].updatedValueMonthly = Math.round(value / 12);
          temp[indx].updatedValueYearly = value;
          remaining -= value;
        } else {
          temp[indx].updatedValueYearly = remaining;
          temp[indx].updatedValueMonthly = Math.round(remaining / 12);
          remaining = 0;
        }
      }
    });

    nonFixed.forEach((element) => {
      const indx = temp.findIndex(
        (tmp) => element.salaryComponentId === tmp.salaryComponentId
      );
      if (indx) {
        const value = Math.round(parseFloat(remaining) / nonFixed.length);
        if (value <= remaining) {
          temp[indx].updatedValueMonthly = Math.round(value / 12);
          temp[indx].updatedValueYearly = value;
          remaining -= value;
        } else {
          temp[indx].updatedValueYearly = remaining;
          temp[indx].updatedValueMonthly = Math.round(remaining / 12);
          remaining = 0;
        }
      }
    });

    setEmployeeSalaryComponentList(temp);
    setEmployeeSalaryComponentRawList(dataList);
  };

  const _showCategoryWiseTotal = (category, periodicity) => {
    let total = 0;
    for (let i = 0; i < employeeSalaryComponentList.length; i++) {
      if (employeeSalaryComponentList[i].category === category) {
        if (periodicity === "M") {
          if (employeeSalaryComponentList[i].monthlyAmount === 0) {
            total = 0;
            break;
          }
          total += employeeSalaryComponentList[i].monthlyAmount;
        }
        if (periodicity === "Y") {
          total += employeeSalaryComponentList[i].annualAmount;
        }

        if (periodicity === "UM") {
          total += employeeSalaryComponentList[i].updatedValueMonthly;
        }
        if (periodicity === "UY") {
          total += employeeSalaryComponentList[i].updatedValueYearly;
        }
      }
    }
    return total > 0 ? total : "";
  };

  useEffect(() => {
    calculateAmount(employeeSalaryComponentRawList);
    setRefreshCount((r) => r + 1);
  }, [userUpdatedCtc]);

  const handleSubmitUserData = async () => {
    if (!isConfirmed) {
      toast.error("Please check the confirmation.");
      return;
    }

    const calculatedUpdatedCtc = employeeSalaryComponentList.reduce(
      (prev, item) => {
        if (item.updatedValueYearly && item.updatedValueYearly > 0) {
          return prev + item.updatedValueYearly;
        } else {
          return prev;
        }
      },
      0
    );

    if (parseInt(calculatedUpdatedCtc) != parseInt(userUpdatedCtc)) {
      toast.error(
        "The total calculated salary does not match the entered CTC. Please verify your adjustments."
      );
      return;
    }

    const components = [];
    if (employeeSalaryComponentList && employeeSalaryComponentList.length > 0) {
      for (let index = 0; index < employeeSalaryComponentList.length; index++) {
        const item = employeeSalaryComponentList[index];
        if (item.dataType === "_data_") {
          // let amount = 0;
          // if (item.periodicity === "Annually") {
          //   amount = item.updatedValueYearly;
          // } else if (item.periodicity === "Monthly") {
          //   amount = item.updatedValueMonthly;
          // }
          // if (amount !== 0) {
          // }
          components.push({
            componentId: item.salaryComponentId,
            // amount: amount,
            monthlyAmount: item.updatedValueMonthly,
            yearlyAmount: item.updatedValueYearly,
            configIds: item.configs.map(({ configId }) => configId),
          });
        }
      }
    }

    try {
      const { otp, requestId } = await checkOtp(
        "/payroll/changeSalaryPositionBasedOnPMS"
      );
      const reqDto = {
        orgId: loginUser.orgId,
        effectiveDate: pmsDetails?.effectiveDate,
        newPositionId: selectedEmployeeData?.newPositionId,
        userId: selectedEmployeeData?.userId,
        pmsEmpId: selectedEmployeeData.pmsEmpId,
        components: components,
        pmsId: data.formDataTransId,
        taskDetailId: data.taskId,
        requestId: requestId || "",
        otp: otp || "",
        ctc: calculatedUpdatedCtc || 0,
      };

      // return;

      const response = await TaskService.changeSalaryPositionBasedOnPMS(
        loginUser.userId,
        reqDto
      );

      if (response.data && response.data.returnValue === "1") {
        toast.success(response.data.message);
        setSelectedEmployeeData(null);
        setEmployeeSalaryComponentList([]);
        setSelectedSalaryComponent(null);
        setIsConfirmed(false);
        fetchPmsInfo();
      } else {
        toast.error(
          response.data?.message || "Failed to update salary position."
        );
      }
    } catch (error) {
      console.error("Error changing salary position based on PMS", error);
      toast.error("An error occurred while updating the salary position.");
    }
  };

  const handleSubmitFinalData = async () => {
    let isValid = true;
    if (pmsImpactedEmployeeList && pmsImpactedEmployeeList.length > 0) {
      for (let index = 0; index < pmsImpactedEmployeeList.length; index++) {
        const { pmsFlag, employeeName } = pmsImpactedEmployeeList[index];
        if (pmsFlag !== "C") {
          toast.warning(`Please submit details for ${employeeName}`);
          isValid = false;
        }
      }
    }

    if (!isValid) return;

    const response = await TaskService.submitTaskAfterUpdatingSalary(
      loginUser.userId,
      loginUser.orgId,
      data.taskId
    );
    if (response.data) {
      if (response.data.returnValue === "1") {
        toast.success(response.data.message);
        refreshData();
        handleClose();
      } else {
        toast.error(response.data.message);
      }
    }
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={{
          width: isExpanded ? "100%" : "",
        }}
      >
        <div className="flottingContainer">
          {/* header start */}
          <div className="flotHeaderPanel">
            <div class="flotHedCon"></div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    isExpanded ? t("exitFullScreen") : t("enterFullScreen")
                  }
                >
                  <IconButton onClick={(e) => setIsExpanded((r) => !r)}>
                    {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          {/* header end */}

          {/* body start */}
          <div className="flotContain">
            {pmsDetails && (
              <div className="elementFormContainer previewSpace ">
                <div className="pmsDetailInfoGrp">
                  <div class="flotHedCon">
                    {data && data.taskName ? data.taskName : ""}
                  </div>
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle">{t("pmsCycle")}:</div>
                    <div className="pmsDtlInfoDescn">
                      <span>{pmsDetails.cycleStartDate}</span>
                      {t("to")}
                      <span>{pmsDetails.cycleEndDate}</span>
                    </div>
                  </div>
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle">
                      {t("pmsApprovedBy")} :
                    </div>
                    <div className="pmsDtlInfoDescn">
                      <span>{pmsDetails.approvedByUserName}</span>
                    </div>
                  </div>
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle">
                      {t("pmsFinalReviewedBy")} :
                    </div>
                    <div className="pmsDtlInfoDescn">
                      <span>{pmsDetails.finalReviewerUserName}</span>
                    </div>
                  </div>
                  <div className="pmsDtlIndElement">
                    <div className="pmsDtlInfoTitle">
                      {t("effective_date_of_pms")} :
                    </div>
                    <div className="pmsDtlInfoDescn">
                      <span>{pmsDetails.effectiveDate}</span>
                    </div>
                  </div>
                </div>
                <div class="tskElemHeding">{t("list_of_employees")}</div>
                <div class="indentFormtable">
                  <div class="indtableResponsive">
                    <table>
                      <thead>
                        <tr>
                          <th className="w200">{t("name")}</th>
                          <th>{t("position")}</th>
                          <th className="w200 textCenter">
                            {t("hikeRecommendation")}
                          </th>
                          <th>{t("newPosition")}</th>
                          <th className="width100">{t("status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pmsDetails.impactedEmployees &&
                          pmsDetails.impactedEmployees.map((item) => (
                            <tr key={item.pmsEmpId}>
                              <td>
                                <div className="textOnly">
                                  {item.employeeName}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.currentPosition}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly textCenter">
                                  {item.hikePc}%
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.newPosition || ""}
                                </div>
                              </td>
                              <td>
                                <div className="tblActionBtnGrp">
                                  {item.pmsFlag !== "C" ? (
                                    <IconButton
                                      onClick={() => {
                                        setSelectedEmployeeData(item);
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                  ) : (
                                    <>
                                      <IconButton
                                        onClick={() => {
                                          setSelectedEmployeeData(item);
                                          setIsReadOnly(true);
                                        }}
                                      >
                                        <Eye />
                                      </IconButton>
                                      <IconButton className="pmsDoneBtn">
                                        <CheckCircle />
                                      </IconButton>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* body end */}

          {/* footer start */}
          <div className="flotFooterPanel">
            <div className="formBtnElement">
              {pmsImpactedEmployeeList &&
                pmsDetails &&
                pmsDetails.impactedEmployees &&
                pmsImpactedEmployeeList.length ===
                  pmsDetails.impactedEmployees.length && (
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={handleSubmitFinalData}
                  >
                    {t("submit")}
                  </Button>
                )}
              <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
                {t("close")}
              </Button>
            </div>
          </div>
          {/* footer end */}
        </div>
      </div>

      {selectedEmployeeData && (
        <>
          <div className="bgOfset">
            <div className="bgOfsetOverlay"></div>
            <Box
              Box
              className="ModelBox pmsModelAutoWidth"
              sx={{ boxShadow: 24, p: 4 }}
            >
              <div className="addMoreAgendaModel">
                <div className="employeeDtlHeader">
                  <ul className="empdtlUL">
                    <li className="empDtlList">
                      <span className="empDtlInfoHeAD">
                        {t("employee_name")}:
                      </span>
                      <span className="empDtlInfo">
                        {selectedEmployeeData.employeeName}
                      </span>
                    </li>
                    <li className="empDtlList">
                      <span className="empDtlInfoHeAD">{t("positions")}:</span>
                      <span className="empDtlInfo">
                        {selectedEmployeeData.currentPosition}
                      </span>
                    </li>
                    {loginUser.signleUnitId > 0 && (
                      <li className="empDtlList">
                        <span className="empDtlInfoHeAD">
                          {t("unit_name")}:
                        </span>
                        <span className="empDtlInfo">
                          {selectedEmployeeData.unitName}
                        </span>
                      </li>
                    )}
                    <li className="empDtlList">
                      <span className="empDtlInfoHeAD">
                        {t("hikeRecommendation")} :
                      </span>
                      <span className="empDtlInfo">
                        {selectedEmployeeData.hikePc}%
                      </span>
                    </li>
                    {selectedEmployeeData.newPosition && (
                      <li className="empDtlList">
                        <span className="empDtlInfoHeAD">
                          {t("newPosition")}:
                        </span>
                        <span className="empDtlInfo">
                          {selectedEmployeeData.newPosition}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="elementFormContainer">
                  {!isReadOnly && (
                    <div className="warningsMsg">
                      <span className="hilighttxt">
                        {t("important_notice")}
                      </span>
                      <span className="warnigtxt">
                        {t("please_ensure_all_information_in_this_document")}
                      </span>
                    </div>
                  )}
                  <div className="indentFormtable">
                    <div className="modResptable">
                      <table className="pmsReviewTable">
                        <thead>
                          <tr>
                            <th className="mMxW200"></th>
                            <th className="curntStrct textCenter" colSpan={2}>
                              {t("current_structure")}
                              <span className="amuntunit">
                                ({selectedEmployeeData.currency})
                              </span>
                            </th>
                            <th className="newStrct textCenter" colSpan={2}>
                              {t("new_structure")}
                              <span className="amuntunit">
                                ({selectedEmployeeData.currency})
                              </span>
                            </th>
                          </tr>
                          {/* <tr>
                            <th className="mMxW200">Salary Head</th>
                            <th className="curntStrct">Monthly</th>
                            <th className="curntStrct">Annually</th>
                            <th className="newStrct">Monthly</th>
                            <th className="newStrct">Annually</th>
                          </tr> */}
                          <tr>
                            <th className="mMxW200">{t("salaryHead")}</th>
                            <th className="curntStrct">{t("monthly")}</th>
                            <th className="curntStrct">{t("annually")}</th>
                            <th className="newStrct">{t("monthly")}</th>
                            <th className="newStrct">{t("annually")}</th>
                          </tr>
                        </thead>
                        {refreshCount >= 0 && (
                          <tbody>
                            {showEnterCtcTextField && !isReadOnly && (
                              <tr>
                                <td className="newsalstruc" colSpan={3}></td>
                                <td className="newsalstruc" colSpan={2}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "16px",
                                    }}
                                  >
                                    <div className="formElement">
                                      <FormControl className="formControl">
                                        <FloatTextField
                                          required
                                          className="modelTextFild"
                                          label={t("enter_user_ctc_after_hike")}
                                          variant="outlined"
                                          value={userUpdatedCtc}
                                          onChange={(val) => {
                                            const value = val
                                              ? parseFloat(val)
                                              : 0;
                                            setUserUpdatedCtcCtc(value);
                                          }}
                                        />
                                      </FormControl>
                                    </div>
                                    <div className="formElement">
                                      <Button
                                        className="dfultPrimaryBtn"
                                        onClick={() => {
                                          if (!userUpdatedCtc) {
                                            toast.error(
                                              "Please enter user updated ctc."
                                            );
                                            return;
                                          }
                                          calculateCurrentCtc(
                                            employeeSalaryComponentRawList
                                          );
                                        }}
                                      >
                                        {"Calculate"}
                                      </Button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                            {employeeSalaryComponentList &&
                              employeeSalaryComponentList.map((item) => {
                                return item.dataType === "_data_" ? (
                                  <tr key={item.salaryComponentId}>
                                    <td>
                                      <div className="textOnly">
                                        {item.componentName}
                                        <div className="textOnlySub">{`${
                                          item.flatAmountPc
                                            ? item.flatAmountPc
                                            : 0
                                        }  ${item.calculationType}`}</div>
                                      </div>
                                    </td>
                                    <td className="crntsalstruc">
                                      <div className="salElmAmunt">
                                        {item.monthlyAmount !== 0 &&
                                          item.monthlyAmount}
                                      </div>
                                    </td>
                                    <td className="crntsalstruc">
                                      <div className="salElmAmunt">
                                        {item.annualAmount}
                                      </div>
                                    </td>
                                    <td className="newsalstruc">
                                      <div className="formElement">
                                        {isReadOnly ? (
                                          <div className="textOnly">
                                            {item.updatedValueMonthly}
                                          </div>
                                        ) : (
                                          <>
                                            {item.periodicity === "Monthly" ? (
                                              <FormControl className="formControl">
                                                <FloatTextField
                                                  required
                                                  className="modelTextFild"
                                                  label={t("updated_amount")}
                                                  variant="outlined"
                                                  value={
                                                    item.updatedValueMonthly
                                                  }
                                                  onChange={(val) => {
                                                    const value = val
                                                      ? parseFloat(val)
                                                      : 0;
                                                    item.updatedValueMonthly =
                                                      value;
                                                    item.updatedValueYearly =
                                                      value * 12;
                                                    const index =
                                                      employeeSalaryComponentRawList.findIndex(
                                                        ({
                                                          salaryComponentId,
                                                        }) =>
                                                          salaryComponentId ===
                                                          item.salaryComponentId
                                                      );

                                                    if (index !== -1) {
                                                      employeeSalaryComponentRawList[
                                                        index
                                                      ].updatedValueMonthly =
                                                        value;
                                                      employeeSalaryComponentRawList[
                                                        index
                                                      ].updatedValueYearly =
                                                        value * 12;
                                                    }
                                                    setRefreshCount(
                                                      (r) => r + 1
                                                    );
                                                  }}
                                                />
                                              </FormControl>
                                            ) : (
                                              <div className="textOnly">
                                                {item.updatedValueMonthly}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </td>
                                    <td className="newsalstruc">
                                      <div className="formElement">
                                        {isReadOnly ? (
                                          <div className="textOnly">
                                            {item.updatedValueYearly}
                                          </div>
                                        ) : (
                                          <>
                                            {item.periodicity === "Annually" ? (
                                              <FormControl className="formControl">
                                                <FloatTextField
                                                  required
                                                  className="modelTextFild"
                                                  label={t("updated_amount")}
                                                  variant="outlined"
                                                  value={
                                                    item.updatedValueYearly
                                                  }
                                                  onChange={(val) => {
                                                    const value = val
                                                      ? parseFloat(val)
                                                      : 0;
                                                    item.updatedValueYearly =
                                                      value;
                                                    const index =
                                                      employeeSalaryComponentRawList.findIndex(
                                                        ({
                                                          salaryComponentId,
                                                        }) =>
                                                          salaryComponentId ===
                                                          item.salaryComponentId
                                                      );
                                                    if (index !== -1) {
                                                      employeeSalaryComponentRawList[
                                                        index
                                                      ].updatedValueYearly =
                                                        value;
                                                    }
                                                    setRefreshCount(
                                                      (r) => r + 1
                                                    );
                                                  }}
                                                />
                                              </FormControl>
                                            ) : (
                                              <div className="textOnly">
                                                {item.updatedValueYearly}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr className="salGropuHead">
                                    <td>{item.dName}</td>
                                    <td className="salElmAmunt">
                                      {_showCategoryWiseTotal(item.dName, "M")}
                                    </td>
                                    <td className="salElmAmunt">
                                      {_showCategoryWiseTotal(item.dName, "Y")}
                                    </td>
                                    <td className="salElmAmunt">
                                      {_showCategoryWiseTotal(item.dName, "UM")}
                                    </td>
                                    <td className="salElmAmunt">
                                      {_showCategoryWiseTotal(item.dName, "UY")}
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr className="emptyrow">
                              <td colSpan={5}></td>
                            </tr>
                            <tr>
                              <td>
                                <div className="tfHText">{t("total_ctc")}</div>
                              </td>
                              <td colSpan={2}>
                                <div className="salElmAmunt">
                                  {userCurrentCtc}
                                </div>
                              </td>
                              <td className="newsalstrucHigh" colSpan={2}>
                                <div className="salElmAmunt">
                                  {/* {userCurrentCtc * selectedEmployeeData.hikePc} */}
                                  {employeeSalaryComponentList &&
                                    employeeSalaryComponentList.reduce(
                                      (prev, item) => {
                                        if (
                                          item.updatedValueYearly &&
                                          item.updatedValueYearly > 0
                                        ) {
                                          return prev + item.updatedValueYearly;
                                        } else {
                                          return prev;
                                        }
                                      },
                                      0
                                    )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}

                        <tfoot>
                          {!isReadOnly && (
                            <>
                              <tr>
                                <td colSpan={5}>
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <FormGroup className="formRadioGroup">
                                        <FormControlLabel
                                          className="formRadioField"
                                          control={
                                            <Checkbox
                                              value={isConfirmed}
                                              onChange={() =>
                                                setIsConfirmed((r) => !r)
                                              }
                                            />
                                          }
                                          required
                                          label={t(
                                            "i_have_checked_statutory_compliance"
                                          )}
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </div>
                                </td>
                              </tr>
                              {!isConfirmed && (
                                <tr>
                                  <td colSpan={4}>
                                    <div className="formElement">
                                      <FormControl className="formControl">
                                        <InputLabel id="compTime">
                                          {t("select_salary_component")}
                                        </InputLabel>
                                        <Select
                                          className="modelSelectFild"
                                          variant="outlined"
                                          labelId="compTime"
                                          id="compTime-select"
                                          label={t("select_salary_component")}
                                          value={selectedSalaryComponent}
                                          onChange={(e) => {
                                            setSelectedSalaryComponent(
                                              e.target.value
                                            );
                                          }}
                                        >
                                          {salaryComponentList &&
                                            salaryComponentList.map((item) => (
                                              <MenuItem
                                                key={item.salaryComponentId}
                                                value={item}
                                              >
                                                {item.componentName}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </td>
                                  <td>
                                    <Button
                                      className="dfultPrimaryBtn"
                                      onClick={() => {
                                        if (selectedSalaryComponent === null) {
                                          toast.warning(
                                            t("please_select_salary_head")
                                          );
                                          return;
                                        }
                                        if (
                                          selectedSalaryComponent &&
                                          employeeSalaryComponentList.find(
                                            ({ salaryComponentId }) =>
                                              salaryComponentId ===
                                              selectedSalaryComponent.salaryComponentId
                                          )
                                        ) {
                                          toast.warning(
                                            t("salary_head_already_exists")
                                          );
                                          return;
                                        }
                                        const obj = {
                                          ...selectedSalaryComponent,
                                          ...{
                                            employeeSalaryComponentId: 0,
                                            amount: 0,
                                          },
                                        };

                                        const tempList = [
                                          ...employeeSalaryComponentRawList,
                                        ];
                                        tempList.push(obj);
                                        calculateAmount(tempList);
                                        setSelectedSalaryComponent(null);
                                      }}
                                    >
                                      {t("add_new")}
                                    </Button>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="modActionBtnGrp">
                  {!isReadOnly && (
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={handleSubmitUserData}
                      // onClick={() => setShowPreview(true)}
                    >
                      {t("save")}
                    </Button>
                  )}
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => {
                      setSelectedEmployeeData(null);
                      setIsReadOnly(false);
                      setEmployeeSalaryComponentList([]);
                      setSelectedSalaryComponent(null);
                      setIsConfirmed(false);
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </>
      )}

      {/* don't remove need for future */}
      {/* {showPreview && (
        <>
          <div className="bgOfset">
            <div className="bgOfsetOverlay"></div>
            <Box
              Box
              className="ModelBox pmsModelAutoWidth"
              sx={{ boxShadow: 24, p: 4 }}
            >
              <Box sx={style}>
                <div className="maindiv">
                  <div className="header">
                    <div className="logo-c">
                      <img src={loginUser.orgLogo} alt={"vimg"} class="plogo" />
                    </div>
                  </div>

                  <div className="pmshdng">Promotion letter</div>

                  <div className="userdesp">
                    <div className="usernm">
                      Dear, <span>Tarique Ahamed</span>
                    </div>
                    <div className="usersdres">Chinnarpark,700136</div>
                  </div>
                  <div className="welcmtxt">Greetings!!!</div>

                  <div className="bodytxt">
                    We are pleased to inform you that your exceptional
                    performance, dedication, and contributions to (company name)
                    have been outstanding. It is with great pleasure that we
                    announce your promotion to (new position), effective from{" "}
                    <span>20/08/2024</span>. Alongside this promotion, you have
                    also been considered for an increment.
                  </div>

                  <div className="bodytxt">
                    Enclosed with this letter, you will find the updated
                    compensation structure reflecting your new salary and
                    benefits. This adjustment is a testament to our confidence
                    in your abilities and the value you bring to our team.
                  </div>

                  <div className="bodytxt">
                    Your achievements have significantly impacted our success,
                    and we are excited about the continued growth you will bring
                    to your new role.
                  </div>

                  <div className="bodytxt">
                    Congratulations once again on this well-deserved
                    recognition. We look forward to your continued contributions
                    and success at Minion Technology.
                  </div>

                  <div className="bodytxt">
                    Enclosure: Updated Compensation Structure.
                  </div>
                  <div className="closebtn">
                    <Button
                      onClick={handleClose}
                      variant="text"
                      className="pclose"
                    >
                      close
                    </Button>
                  </div>
                </div>
              </Box>
            </Box>
          </div>
        </>
      )} */}
    </>
  );
}
