import React from "react";
import "../css/landingPage.css";
import "../css/custom_landing_page.css";
import NavBarLP from "./component/NavBarLP";
import { useState } from "react";
import LoginRegister from "./component/LoginRegister";
import FooterLP from "./component/FooterLP";
import HomePage from "./component/HomePage";
import { useRef } from "react";
import PageNotFound from "./component/PageNotFound";
import FeaturesPage from "./component/FeaturesPage";
import AboutUs from "./component/AboutUs";

export default function LandingPage() {
  const [showLogReg, setShowLogReg] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);

  const handelCloseLogRegContain = () => {
    setShowLogReg(false);
  };

  const handelOpenLogRegContain = () => {
    setShowLogReg(true);
  };

  const handelCloseFeatures = () => {
    setShowLogReg(false);
  };
  const handelOpenFeatures = () => {
    setShowFeatures(true);
    setShowAboutUs(false);
  };
  const handelOpenAboutUs = () => {
    setShowAboutUs(true);
    setShowFeatures(false);
  };

  const scrollableNavRef = useRef(null);

  return (
    <>
      <div className="lp-full-container" ref={scrollableNavRef}>
        <NavBarLP
          handelOpenLogRegContain={handelOpenLogRegContain}
          scrollableNavRef={scrollableNavRef}
          handelOpenFeatures={handelOpenFeatures}
          handelOpenAboutUs={handelOpenAboutUs}
        />
        {showLogReg && (
          <LoginRegister handelCloseLogRegContain={handelCloseLogRegContain} />
        )}
        {/* <HomePage handelOpenFeatures={handelOpenFeatures} /> */}
        {!showFeatures && !showAboutUs && (
          <HomePage handelOpenFeatures={handelOpenFeatures} />
        )}
        {showFeatures && <FeaturesPage />}
        {showAboutUs && <AboutUs />}
        {/* <PageNotFound /> */}
        <FooterLP />
      </div>
    </>
  );
}
