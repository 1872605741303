import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { toast } from "react-toastify";
import TaskService from "../../../../../services/TaskService";
import DateUtils from "../../../../../utils/DateUtils";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function CreatePmsRequestForm({
  isExpanded,
  setIsExpanded,
  handleClose,
  handleSubmit,
}) {
  const loginUser = GetLoginUserDetails();
  const { t, i18n } = useTranslation();
  const [pmsPeriodFrom, setPmsPeriodFrom] = useState(null);
  const [pmsPeriodTo, setPmsPeriodTo] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [joinedBefore, setJoinedBefore] = useState(null);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [evaluationMetricsList, setEvaluationMetricsList] = useState([]);

  const [pmsEligibleEmpCount, setPmsEligibleEmpCount] = useState(null);
  const [selectedMetricsList, setSelectedMetricsList] = useState([]);
  const [approverDetails, setApproverDetails] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await TaskService.getStaticDDOfOrg(loginUser.orgId, "EVALUATION_METRICS")
        .then((response) => {
          if (response && response.data != null) {
            setEvaluationMetricsList(response.data);
          }
        })
        .catch((err) => console.error(err.toString()));
    };

    calculateDate();
    fetchData();
  }, []);

  const calculateDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    const firstDayNextMonth = new Date(year, month + 1, 1);
    const firstDayLastYearNextMonth = new Date(year - 1, month + 1, 1);
    const lastDayCurrentMonth = new Date(firstDayNextMonth - 1);

    setPmsPeriodFrom(firstDayLastYearNextMonth);
    setPmsPeriodTo(lastDayCurrentMonth);
    setEffectiveDate(firstDayNextMonth);
    setJoinedBefore(firstDayLastYearNextMonth);
  };

  useEffect(() => {
    const fetchData = async () => {
      const reqDto = {
        joingingDateBefore: DateUtils.getDateInDDMMYYYY(joinedBefore),
        empStatus: [],
      };
      await TaskService.pmsEligibleEmpCount(loginUser.orgId, reqDto)
        .then((response) => {
          if (response && response.data != null) {
            setPmsEligibleEmpCount(response.data);
          }
        })
        .catch((err) => console.error(err.toString()));
    };

    if (joinedBefore !== null) {
      fetchData();
    }
  }, [joinedBefore]);

  const handleClickPreview = async () => {
    let isValid = true;

    if (selectedMetricsList == null || selectedMetricsList.length === 0) {
      toast.error(t("select_evaluation_metrics"));
      isValid = false;
    } else {
      for (let i = 0; i < selectedMetricsList.length; i++) {
        const data = selectedMetricsList[i];
        if (!data.type || data.type === "") {
          toast.warning(t("select_option_for", { metricName: data.name }));
          isValid = false;
        }
        if (
          (data.type === "rating" || data.type === "both") &&
          parseInt(data.value) <= 0
        ) {
          toast.warning(
            t("enter_rating_more_than_zero", { metricName: data.name })
          );
          isValid = false;
        }
      }
    }

    if (!isValid) return;
    await TaskService.getPmsApprover(loginUser.userId, loginUser.orgId).then(
      (response) => {
        if (response && response.data != null) {
          toast.info(
            t("approver_message", { approverName: response.data.userName })
          );
          setApproverDetails(response.data);
        }
      }
    );
    setShowPreview(true);
  };

  const handleClickSave = () => {
    let metrics = [];
    for (let i = 0; i < selectedMetricsList.length; i++) {
      metrics.push({
        maxRating: selectedMetricsList[i].value,
        metrics: selectedMetricsList[i].name,
        metricsType: selectedMetricsList[i].type,
      });
    }
    const reqDto = {
      cycleStartDate: DateUtils.getDateInDDMMYYYY(pmsPeriodFrom),
      cycleEndDate: DateUtils.getDateInDDMMYYYY(pmsPeriodTo),
      effectiveDate: DateUtils.getDateInDDMMYYYY(effectiveDate),
      applicableJoiningDate: DateUtils.getDateInDDMMYYYY(joinedBefore),
      effectedEmployeeCount: pmsEligibleEmpCount.effectedEmployeeCount,
      pmsDoneCount: pmsEligibleEmpCount.pmsDoneCount,
      joinedAfterPmsStart: pmsEligibleEmpCount.joinedPmsStartAfter30Days,
      metrics: metrics,
    };
    handleSubmit(reqDto, approverDetails.userId);
  };

  return (
    <>
      <div className="flotHeaderPanel">
        <div class="flotHedCon">{t("create_pms_request")}</div>
        <div class="flotActionBtnGrp">
          <div class="flotActBtn closeBtnD10">
            <Tooltip
              arrow
              title={
                isExpanded ? t("exit_full_screen") : t("Enter_full_screen")
              }
            >
              <IconButton
                onClick={(e) => {
                  if (isExpanded) {
                    setIsExpanded(false);
                  } else {
                    setIsExpanded(true);
                  }
                }}
              >
                {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
              </IconButton>
            </Tooltip>
          </div>

          <div class="flotActBtn closeBtnD10">
            <IconButton onClick={handleClose}>
              <XLg />
            </IconButton>
          </div>
        </div>
      </div>

      <div className="flotContain">
        <div className="elementFormContainer ">
          <div className="formElementGroup">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="pmsPeriodFrom" className="setCompletionDate">
                  {t("pms_period_from")}
                </InputLabel>
                <ReactDatePicker
                  locale={currentLocale}
                  showIcon
                  toggleCalendarOnIconClick
                  labelId="pmsPeriodFrom"
                  className="formDatePicker"
                  selected={pmsPeriodFrom}
                  minDate={new Date().setFullYear(new Date().getFullYear - 2)}
                  maxDate={new Date()}
                  dateFormat="dd/MMM/yyyy"
                  onChange={(date) => {
                    setPmsPeriodFrom(date);
                    setJoinedBefore(date);
                  }}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="pmsPeriodTo" className="setCompletionDate">
                  {t("pms_period_to")}
                </InputLabel>
                <ReactDatePicker
                  locale={currentLocale}
                  showIcon
                  toggleCalendarOnIconClick
                  labelId="pmsPeriodTo"
                  className="formDatePicker"
                  selected={pmsPeriodTo}
                  minDate={new Date()}
                  maxDate={new Date().setFullYear(new Date().getFullYear + 2)}
                  dateFormat="dd/MMM/yyyy"
                  onChange={setPmsPeriodTo}
                />
              </FormControl>
            </div>
          </div>

          <div className="formElementGroup">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="effectiveDate" className="setCompletionDate">
                  {t("expected_effective_date")}
                </InputLabel>
                <ReactDatePicker
                  locale={currentLocale}
                  showIcon
                  toggleCalendarOnIconClick
                  labelId="effectiveDate"
                  className="formDatePicker"
                  selected={effectiveDate}
                  minDate={new Date()}
                  maxDate={new Date().setFullYear(new Date().getFullYear + 2)}
                  dateFormat="dd/MMM/yyyy"
                  onChange={setEffectiveDate}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="effectiveDate" className="setCompletionDate">
                  {t("applicable_for_employees_who_joined_before")}
                </InputLabel>
                <ReactDatePicker
                  locale={currentLocale}
                  showIcon
                  toggleCalendarOnIconClick
                  labelId="effectiveDate"
                  className="formDatePicker"
                  selected={joinedBefore}
                  minDate={new Date().setFullYear(new Date().getFullYear - 2)}
                  maxDate={new Date()}
                  dateFormat="dd/MMM/yyyy"
                  onChange={setJoinedBefore}
                />
              </FormControl>
            </div>
          </div>

          {pmsEligibleEmpCount && (
            <>
              <div className="formElement">
                <FormControl className="formControl">
                  <p className="fontColorWhite">
                    {t("numbers_of_effected_employees")} :{" "}
                    {pmsEligibleEmpCount.effectedEmployeeCount}
                  </p>
                  <p className="fontColorWhite">
                    {t("already_pms_done_count")} :{" "}
                    {pmsEligibleEmpCount.pmsDoneCount}
                  </p>
                  <p className="fontColorWhite">
                    {t(
                      "numbers_of_employees_who_joined_30_days_before_applicable_join_date"
                    )}{" "}
                    : {pmsEligibleEmpCount.joinedPmsStartAfter30Days}
                  </p>
                </FormControl>
              </div>
            </>
          )}

          <br />

          {evaluationMetricsList && evaluationMetricsList.length > 0 && (
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="formInputField" className="setCompletionDate">
                  {t("select_evaluation_metrics")} *
                </InputLabel>
                <Select
                  hiddenLabel
                  className="formInputField"
                  variant="outlined"
                  multiple
                  value={selectedMetricsList}
                  onChange={(event) => {
                    const value = event.target.value;
                    setSelectedMetricsList(value);
                  }}
                  renderValue={(selected) =>
                    selected.map(({ name }) => name).join(", ")
                  }
                >
                  {evaluationMetricsList.map((item) => (
                    <MenuItem key={`${item.id}+${item.name}`} value={item}>
                      <Checkbox checked={selectedMetricsList.includes(item)} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {selectedMetricsList && selectedMetricsList.length > 0 && (
            <>
              {selectedMetricsList.map((item, index) => {
                return (
                  <div className="formElementGroup">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <p className="fontColorWhite">{item.name}</p>
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="setTasktype"
                          onChange={(e) => {
                            const value = e.target.value;
                            let tempList = [...selectedMetricsList];
                            tempList[index].type = value;
                            if (value === "rating" || value === "both") {
                              tempList[index].value = 5;
                            } else {
                              tempList[index].value = 0;
                            }
                            setSelectedMetricsList(tempList);
                          }}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="rating"
                            control={<Radio />}
                            label={t("rating")}
                          />

                          <FormControlLabel
                            className="formRadioField"
                            value="text"
                            control={<Radio />}
                            label={t("text")}
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="both"
                            control={<Radio />}
                            label={t("both")}
                          />
                          {(item.type === "rating" || item.type === "both") && (
                            <TextField
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t("rating")}
                              label={t("rating_out_of")}
                              value={item.value}
                              type="number"
                              onChange={(e) => {
                                let tempList = [...selectedMetricsList];
                                tempList[index].value = e.target.value;
                                setSelectedMetricsList(tempList);
                              }}
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>

      <div className="flotFooterPanel">
        <div className="formBtnElement">
          <Button
            className="dfultPrimaryBtn"
            onClick={() =>
              !showPreview ? handleClickPreview() : handleClickSave()
            }
          >
            {!showPreview ? t("preview") : t("save")}
          </Button>
          <Button
            className="dfultDarkSecondaryBtn"
            onClick={() =>
              !showPreview ? handleClose() : setShowPreview(false)
            }
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </>
  );
}
