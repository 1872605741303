import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import TaskService from "../../../../../services/TaskService";
import { getLoginUserDetailsLocal } from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import zhPatientService from "../../../../../services/ZhPatientService";
import IntegerTextField from "../../../../../common/IntegerTextField";
import { Add, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { getStaticDDOfOrg } from "../../../../../services/AdminService";

export const AddParameterAdmin = ({ onClose, mode = "add", editData }) => {
  const { t } = useTranslation();
  const loginUserDetails = getLoginUserDetailsLocal();
  const [loading, setLoading] = useState(false);
  const [parameterName, setParameterName] = useState("");
  const [parameterObj, setParameterObj] = useState({
    parameterId: 0,
    parameterName: "",
    parameterReferances: [],
  });

  const [showAddReferenceRenge, setShowAddReferenceRange] = useState(false);
  const [newReferenceValue, setNewReferenceValue] = useState({
    refRangeId: 0,
    parameterType: "1",
    gender: "C",
    pregnantFlag: false,
    paramUnit: "",
    rangeType: "",
    valLl: "",
    valUl: "",
    technique: null,
    ageLl: 0,
    ageUl: 0,
    minAgeYear: 0,
    minAgeMonth: 0,
    minAgeDay: 0,
    maxAgeYear: 200,
    maxAgeMonth: 0,
    maxAgeDay: 0,
    rangeCd: "",
  });

  const [rangeTypes, setRangeTypes] = useState([]);

  useEffect(() => {
    initializeForm();
    if (mode === "add") {
      setShowAddReferenceRange(true);
    }
    getStaticDDOfOrg(loginUserDetails.orgId, "PARAMETER_RANGE_TYPE").then(
      (response) => {
        console.log("parameter range types", response.data);
        setRangeTypes(response.data);
      }
    );
  }, []);

  function convertDaysToYearMonthDay(totalDays) {
    const daysInYear = 365; // Approximation
    const daysInMonth = 30; // Approximation

    const years = Math.floor(totalDays / daysInYear);
    const remainingDaysAfterYears = totalDays % daysInYear;

    const months = Math.floor(remainingDaysAfterYears / daysInMonth);
    const days = remainingDaysAfterYears % daysInMonth;

    return { years, months, days };
  }

  useEffect(() => {
    if (editData) {
      const { ageLl, ageUl } = editData;
      const {
        years: minAgeYear,
        months: minAgeMonth,
        days: minAgeDay,
      } = convertDaysToYearMonthDay(ageLl);
      const {
        years: maxAgeYear,
        months: maxAgeMonth,
        days: maxAgeDay,
      } = convertDaysToYearMonthDay(ageUl);
      const temp = { ...editData };
      editData.minAgeYear = minAgeYear;
      editData.minAgeMonth = minAgeMonth;
      editData.minAgeDay = minAgeDay;
      editData.maxAgeYear = maxAgeYear;
      editData.maxAgeMonth = maxAgeMonth;
      editData.maxAgeDay = maxAgeDay;
      // temp.minAgeYear=minAgeYear
      // temp.minAgeMonth=minAgeMonth
      // temp.minAgeDay=minAgeDay
      // temp.maxAgeYear=maxAgeYear
      // temp.maxAgeMonth=maxAgeMonth
      // temp.maxAgeDay=maxAgeDay
      setParameterName(editData.parameterName);
      setParameterObj({ ...editData });
    }
  }, [editData]);

  const calculateValueRange = (ll, ul) => {
    if (ul?.includes("<")) {
      return `${ll}${ul}`;
    } else {
      return `${ll}-${ul}`;
    }
  };

  function convertDaysToYMD(totalDays) {
    const daysInYear = 365;
    const daysInMonth = 30; // Approximation for a month

    const years = Math.floor(totalDays / daysInYear);
    totalDays %= daysInYear; // Remaining days after calculating years

    const months = Math.floor(totalDays / daysInMonth);
    const days = totalDays % daysInMonth; // Remaining days after calculating months

    return { years, months, days };
  }

  // Example usage

  const calculateAgeLimit = (startAge, endAge) => {
    // let endString = endAge;

    // if (endAge?.includes("<")) {
    //   const endDate = endAge.split("<");
    //   endString = endDate[endDate.length - 1];
    // }

    const startAgeYMD = convertDaysToYMD(parseInt(startAge));
    // const endAgeYMD = convertDaysToYMD(parseInt(endString));
    const endAgeYMD = convertDaysToYMD(parseInt(endAge));

    return `${startAgeYMD.years}Y ${startAgeYMD.months}M ${startAgeYMD.days}D -  ${endAgeYMD.years}Y ${endAgeYMD.months}M ${endAgeYMD.days}D`;
  };

  const calculateAgeInDays = (yr, mn, dy) => {
    let year = yr || 0;
    let month = mn || 0;
    let day = dy || 0;
    const total = year * 365 + month * 30 + day;
    return total;
  };

  const handleSubmit = () => {
    if (!parameterName) {
      toast.error(t("please_provide_parameter_name"));
      return;
    }
    if (parameterObj?.parameterReferances?.length <= 0) {
      toast.error(t("no_reference_range_found"));
      return;
    }

    const item = { ...parameterObj };
    if (mode === "edit") {
      const tempItem = { ...parameterObj };
      tempItem.parameterReferances =
        tempItem?.parameterReferances?.map((item) => {
          const temp = { ...item };
          temp.pregnantFlag = item.pregnantFlag ? "Y" : "N";
          const minAge = calculateAgeInDays(
            item.minAgeYear,
            item.minAgeMonth,
            item.minAgeDay
          );
          const maxAge = calculateAgeInDays(
            item.maxAgeYear,
            item.maxAgeMonth,
            item.maxAgeDay
          );
          temp.ageLl = minAge;
          temp.ageUl = maxAge;
          return temp;
        }) || [];
      zhPatientService
        .editParameter(
          loginUserDetails.orgId,
          loginUserDetails.orgCountryCode,
          tempItem
        )
        .then((response) => {
          console.log("response =", response.data);
          if (response.data) {
            toast.success(t("parameter_value_updated_successfully"));
            onClose(true);
          } else {
            toast.error(t("parameter_could_not_be_updated"));
            onClose(true);
          }
        });
    } else if (mode === "add") {
      const tempItem = { ...parameterObj };
      tempItem.parameterReferances =
        tempItem?.parameterReferances?.map((item) => {
          const temp = { ...item };
          temp.pregnantFlag = item.pregnantFlag ? "Y" : "N";
          const minAge = calculateAgeInDays(
            item.minAgeYear,
            item.minAgeMonth,
            item.minAgeDay
          );
          const maxAge = calculateAgeInDays(
            item.maxAgeYear,
            item.maxAgeMonth,
            item.maxAgeDay
          );
          temp.ageLl = minAge;
          temp.ageUl = maxAge;
          return temp;
        }) || [];

      console.log("parameter Obj =", tempItem);
      // return;
      zhPatientService
        .addParameter(
          loginUserDetails.orgId,
          loginUserDetails.orgCountryCode,
          tempItem
        )
        .then((response) => {
          console.log("response =", response.data);
          if (response.data.returnValue == "0") {
            toast.error(t("parameter_could_not_be_updated"));
          } else if (response.data.returnValue == "-1") {
            toast.error(t("parameter_already_exists"));
          } else {
            toast.success(t("parameter_value_updated_successfully"));
            onClose(true);
          }
        });
    }
  };

  const initializeForm = () => {
    setNewReferenceValue({
      refRangeId: 0,
      parameterType: "1",
      gender: "C",
      pregnantFlag: false,
      paramUnit: "",
      rangeType: "",
      valLl: "",
      valUl: "",
      technique: null,
      ageLl: 0,
      ageUl: 73000,
      minAgeYear: 0,
      minAgeMonth: 0,
      minAgeDay: 0,
      maxAgeYear: 200,
      maxAgeMonth: 0,
      maxAgeDay: 0,
      rangeCd: "",
    });
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">
            {mode === "add" ? t("add_parameter") : t("edit_prameter")}
          </div>

          <IconButton className="CloseBtn" onClick={() => onClose()}>
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer gap10 custParamUl">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : (
            <>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    // hiddenLabel
                    label={t("parameter_name")}
                    required
                    autoComplete="off"
                    variant="outlined"
                    className="formTextFieldArea"
                    value={parameterName}
                    onChange={(e) => {
                      let temp = { ...parameterObj };
                      temp.parameterName = e.target.value;
                      setParameterObj(temp);
                      setParameterName(e.target.value);
                    }}
                    // disabled
                  />
                </FormControl>
              </div>

              <div className="taskContainEditBtnGrp">
                <div className="tskElemHeding">{t("reference_ranges")}</div>

                {!showAddReferenceRenge && (
                  <Tooltip arrow title={t("add_parameter_reference")}>
                    <IconButton
                      className="editTimelineBtn"
                      onClick={() => {
                        setShowAddReferenceRange(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                )}

                {showAddReferenceRenge && (
                  <>
                    {/* <Tooltip arrow title={t("save_parameter_reference")}>
                      <IconButton
                        className="editTimelineBtn"
                        onClick={() => {
                          const temp = { ...parameterObj };

                          const tempObj = { ...newReferenceValue };
                          temp.pregnantFlag = tempObj.pregnantFlag ? "Y" : "N";
                          const minAge = calculateAgeInDays(
                            tempObj.minAgeYear,
                            tempObj.minAgeMonth,
                            tempObj.minAgeDay
                          );
                          const maxAge = calculateAgeInDays(
                            tempObj.maxAgeYear,
                            tempObj.maxAgeMonth,
                            tempObj.maxAgeDay
                          );
                          tempObj.ageLl = minAge;
                          tempObj.ageUl = maxAge;

                          temp.parameterReferances.push(tempObj);
                          setParameterObj(temp);
                          // setNewReferenceValue({
                          //   refRangeId: 0,
                          //   parameterType: "1",
                          //   gender: "",
                          //   pregnantFlag: false,
                          //   paramUnit: "",
                          //   rangeType: "",
                          //   valLl: "",
                          //   valUl: "",
                          //   technique: null,
                          //   ageLl: 0,
                          //   ageUl: 0,
                          //   minAgeYear: 0,
                          //   minAgeMonth: 0,
                          //   minAgeDay: 0,
                          //   maxAgeYear: 200,
                          //   maxAgeMonth: 0,
                          //   maxAgeDay: 0,
                          // });
                          initializeForm()
                          setShowAddReferenceRange(false);
                        }}
                      >
                        <Save />
                      </IconButton>
                    </Tooltip> */}

                    <Tooltip arrow title={t("save_parameter_reference")}>
                      <IconButton
                        className="editTimelineBtn"
                        onClick={() => {
                          initializeForm();
                          setShowAddReferenceRange(false);
                        }}
                      >
                        <Cancel />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>

              {showAddReferenceRenge && (
                <>
                  <div className="formElementGroup">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="compTime">
                          {t("parameter_value_type") + "*"}
                        </InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="compTime"
                          id="compTime-select"
                          value={newReferenceValue.parameterType}
                          label={t("completion_time")}
                          onChange={(e) => {
                            const {
                              target: { value },
                            } = e;
                            const temp = { ...newReferenceValue };
                            temp.parameterType = e.target.value;
                            if (value === "2" || value === "3") {
                              temp.valLl = "";
                              temp.valUl = "";
                            }
                            setNewReferenceValue(temp);
                          }}
                        >
                          <MenuItem value={"1"}>{"Range"}</MenuItem>
                          <MenuItem value={"2"}>{"Boolean"}</MenuItem>
                          <MenuItem value={"3"}>{"Freetext"}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    {newReferenceValue.parameterType !== "2" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            label={t("parameter_unit")}
                            // required
                            variant="outlined"
                            className="formTextFieldArea"
                            value={newReferenceValue.paramUnit}
                            onChange={(e) => {
                              const temp = { ...newReferenceValue };
                              temp.paramUnit = e.target.value;
                              setNewReferenceValue(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    )}
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <FormGroup
                        aria-label="position"
                        row
                        className="feformGroup"
                      >
                        <FormControlLabel
                          className="formCheckBox"
                          // value="Profit Center"
                          control={
                            <Checkbox
                              checked={newReferenceValue.pregnantFlag}
                              onChange={(e) => {
                                const temp = { ...newReferenceValue };
                                temp.pregnantFlag = e.target.checked;
                                setNewReferenceValue(temp);
                              }}
                            />
                          }
                          label={t("is_pregnant")}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={"Technique"}
                        // required
                        variant="outlined"
                        className="formTextFieldArea"
                        value={newReferenceValue.technique}
                        onChange={(e) => {
                          const temp = { ...newReferenceValue };
                          temp.technique = e.target.value;
                          setNewReferenceValue(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="formElementGroup">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="compTime">
                          {t("parameter_gender")}
                        </InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="compTime"
                          id="compTime-select"
                          value={newReferenceValue.gender}
                          label={t("completion_time")}
                          onChange={(e) => {
                            const temp = { ...newReferenceValue };
                            temp.gender = e.target.value;
                            setNewReferenceValue(temp);
                          }}
                        >
                          <MenuItem value={"C"}>{t("both")}</MenuItem>
                          <MenuItem value={"M"}>{t("male")}</MenuItem>
                          <MenuItem value={"F"}>{t("female")}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    {newReferenceValue.parameterType === "1" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="compTime">
                            {t("range_type")}
                          </InputLabel>
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId="compTime"
                            id="compTime-select"
                            value={newReferenceValue.rangeType}
                            label={t("completion_time")}
                            onChange={(e) => {
                              const temp = { ...newReferenceValue };
                              const foundCd = rangeTypes?.find(
                                (item) => item.id === e.target.value
                              );

                              temp.rangeType =
                                foundCd.name || e.target.value || "";
                              temp.rangeCd = foundCd.id || e.target.value || "";
                              setNewReferenceValue(temp);
                            }}
                          >
                            {rangeTypes?.map((item) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div>
                  <div className="formElementGroup">
                    {newReferenceValue.parameterType === "1" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            label={t("value_lower_limit")}
                            // required
                            variant="outlined"
                            className="formTextFieldArea"
                            value={newReferenceValue.valLl}
                            onChange={(e) => {
                              const temp = { ...newReferenceValue };
                              temp.valLl = e.target.value;
                              setNewReferenceValue(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    )}
                    {newReferenceValue.parameterType === "1" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            label={t("value_upper_limit")}
                            // required
                            variant="outlined"
                            className="formTextFieldArea"
                            value={newReferenceValue.valUl}
                            onChange={(e) => {
                              const temp = { ...newReferenceValue };
                              temp.valUl = e.target.value;
                              setNewReferenceValue(temp);
                            }}
                          />
                        </FormControl>
                      </div>
                    )}
                  </div>

                  {/* min DATe */}
                  <div className="tskElemHeding">{t("minimum_age")}</div>

                  <div className="formElementGroup">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <IntegerTextField
                          min
                          label={t("Year")}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={newReferenceValue.minAgeYear || 0}
                          onChange={(data) => {
                            const temp = { ...newReferenceValue };
                            temp.minAgeYear = data;
                            setNewReferenceValue(temp);
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <IntegerTextField
                          min
                          label={t("Month")}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={newReferenceValue.minAgeMonth || 0}
                          onChange={(data) => {
                            const temp = { ...newReferenceValue };
                            temp.minAgeMonth = data;
                            setNewReferenceValue(temp);
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <IntegerTextField
                          min
                          label={t("Day")}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={newReferenceValue.minAgeDay || 0}
                          onChange={(data) => {
                            const temp = { ...newReferenceValue };
                            temp.minAgeDay = data;
                            setNewReferenceValue(temp);
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  {/* min DATe end*/}

                  <div className="tskElemHeding">{t("maximum_age")}</div>

                  <div className="formElementGroup">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <IntegerTextField
                          label={t("Max Year")}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={newReferenceValue.maxAgeYear}
                          onChange={(data) => {
                            const temp = { ...newReferenceValue };
                            temp.maxAgeYear = data;
                            setNewReferenceValue(temp);
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <IntegerTextField
                          label={t("Max Month")}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={newReferenceValue.maxAgeMonth}
                          onChange={(data) => {
                            const temp = { ...newReferenceValue };
                            temp.maxAgeMonth = data;
                            setNewReferenceValue(temp);
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <IntegerTextField
                          label={t("Max Days")}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={newReferenceValue.maxAgeDay}
                          onChange={(data) => {
                            const temp = { ...newReferenceValue };
                            temp.maxAgeDay = data;
                            setNewReferenceValue(temp);
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div>
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={() => {
                        const temp = { ...parameterObj };

                        const tempObj = { ...newReferenceValue };
                        temp.pregnantFlag = tempObj.pregnantFlag ? "Y" : "N";
                        const minAge = calculateAgeInDays(
                          tempObj.minAgeYear,
                          tempObj.minAgeMonth,
                          tempObj.minAgeDay
                        );
                        const maxAge = calculateAgeInDays(
                          tempObj.maxAgeYear,
                          tempObj.maxAgeMonth,
                          tempObj.maxAgeDay
                        );
                        tempObj.ageLl = minAge;
                        tempObj.ageUl = maxAge;

                        temp.parameterReferances.push(tempObj);
                        setParameterObj(temp);
                        initializeForm();
                        setShowAddReferenceRange(false);
                      }}
                      startIcon={<Save />}
                    >
                      {t("save")}
                    </Button>
                  </div>
                </>
              )}

              <div className="bb"></div>

              <ul className="parameterTdUl">
                {parameterObj?.parameterReferances?.map((reference, index) => {
                  return (
                    <>
                      {!reference?.editMode ? (
                        <li className="parameterTdLi">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: " center",
                            }}
                          >
                            <div className="orgPText">
                              <span>{`${t("parameter_gender")}: ${
                                reference.gender === "C"
                                  ? t("both")
                                  : reference.gender === "M"
                                  ? t("male")
                                  : t("female")
                              }, ${
                                reference.pregnantFlag === "Y"
                                  ? `${t("pregnant")}: ${
                                      reference.pregnantFlag
                                    }`
                                  : ""
                              }`}</span>
                            </div>
                            <div className="btnGroup">
                              <Tooltip arrow title={t("edit")}>
                                <IconButton
                                  className="editTimelineBtn"
                                  onClick={() => {
                                    const temp = { ...parameterObj };
                                    temp.parameterReferances[
                                      index
                                    ].editMode = true;
                                    setParameterObj(temp);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>

                              <Tooltip arrow title={t("delete")}>
                                <IconButton
                                  className="editTimelineBtn"
                                  onClick={() => {
                                    const temp = { ...parameterObj };
                                    temp.parameterReferances.splice(index);
                                    setParameterObj(temp);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                          {/* {reference.pregnantFlag === "Y" && (
                            <div className="orgPText">
                              {reference.pregnantFlag}
                            </div>
                          )} */}
                          <div className="orgPText">
                            {/* <span>Range Type: </span>
                            <span>{reference.rangeType}</span> */}
                            <span>
                              {`Range Type: ${reference.rangeType}, Unit: ${reference.paramUnit}`}{" "}
                            </span>
                          </div>
                          {/* <div className="orgPText">
                            <span>Unit: </span>
                            <span>{reference.paramUnit}</span>
                          </div> */}
                          <div className="orgPText">
                            {/* <span>Value: </span>
                            <span>
                              {calculateValueRange(
                                reference.valLl,
                                reference.valUl
                              )}
                            </span> */}
                            <span>
                              {`Value: ${calculateValueRange(
                                reference.valLl,
                                reference.valUl
                              )}, Age Limit: ${calculateAgeLimit(
                                reference.ageLl,
                                reference.ageUl
                              )}`}{" "}
                            </span>
                          </div>
                          {/* <div className="orgPText">
                            <span>Age Limit: </span>
                            <span>
                              {calculateAgeLimit(
                                reference.ageLl,
                                reference.ageUl
                              )}
                            </span>
                          </div> */}
                        </li>
                      ) : (
                        <li className="parameterTdLi">
                          <>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel id="compTime">
                                  {t("parameter_value_type")}
                                </InputLabel>
                                <Select
                                  className="formInputField"
                                  variant="outlined"
                                  labelId="compTime"
                                  id="compTime-select"
                                  value={reference.parameterType}
                                  label={t("completion_time")}
                                  onChange={(e) => {
                                    const temp = { ...parameterObj };
                                    temp.parameterReferances[
                                      index
                                    ].parameterType = e.target.value;
                                    setParameterObj(temp);
                                  }}
                                >
                                  <MenuItem value={"1"}>{"Range"}</MenuItem>
                                  <MenuItem value={"2"}>{"Boolean"}</MenuItem>
                                  <MenuItem value={"3"}>{"Freetext"}</MenuItem>
                                </Select>
                              </FormControl>
                            </div>

                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  label={"Parameter Unit"}
                                  // required
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  value={reference.paramUnit}
                                  onChange={(e) => {
                                    const temp = { ...parameterObj };
                                    temp.parameterReferances[index].paramUnit =
                                      e.target.value;
                                    setParameterObj(temp);
                                  }}
                                />
                              </FormControl>
                            </div>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  label={"Technique"}
                                  // required
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  value={reference.technique}
                                  onChange={(e) => {
                                    const temp = { ...parameterObj };
                                    temp.parameterReferances[index].technique =
                                      e.target.value;
                                    setParameterObj(temp);
                                  }}
                                />
                              </FormControl>
                            </div>
                            <div className="formElementGroup">
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <InputLabel id="compTime">
                                    {t("parameter_gender")}
                                  </InputLabel>
                                  <Select
                                    className="formInputField"
                                    variant="outlined"
                                    labelId="compTime"
                                    id="compTime-select"
                                    value={reference.gender}
                                    label={t("completion_time")}
                                    onChange={(e) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[index].gender =
                                        e.target.value;
                                      setParameterObj(temp);
                                    }}
                                  >
                                    <MenuItem value={"C"}>{t("both")}</MenuItem>
                                    <MenuItem value={"M"}>{t("male")}</MenuItem>
                                    <MenuItem value={"F"}>
                                      {t("female")}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <InputLabel id="compTime">
                                    {t("range_type")}
                                  </InputLabel>
                                  <Select
                                    className="formInputField"
                                    variant="outlined"
                                    labelId="compTime"
                                    id="compTime-select"
                                    value={reference.rangeType}
                                    label={t("completion_time")}
                                    onChange={(e) => {
                                      const temp = { ...parameterObj };
                                      const foundCd = rangeTypes?.find(
                                        (item) => item.id === e.target.value
                                      );
                                      temp.parameterReferances[
                                        index
                                      ].rangeType =
                                        foundCd.name || e.target.value || "";
                                      temp.parameterReferances[
                                        index
                                      ].rangeType =
                                        foundCd.id || e.target.value || "";
                                      setParameterObj(temp);
                                    }}
                                  >
                                    {rangeTypes?.map((item) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>

                            <div className="formElementGroup">
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <FormGroup
                                    aria-label="position"
                                    row
                                    className="feformGroup"
                                  >
                                    <FormControlLabel
                                      className="formCheckBox"
                                      // value="Profit Center"
                                      control={
                                        <Checkbox
                                          checked={reference.pregnantFlag}
                                          onChange={(e) => {
                                            const temp = { ...parameterObj };
                                            temp.parameterReferances[
                                              index
                                            ].pregnantFlag = e.target.value;
                                            setParameterObj(temp);
                                          }}
                                        />
                                      }
                                      label={t("is_pregnant")}
                                      labelPlacement="start"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </div>
                            </div>
                            <div className="formElementGroup">
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <TextField
                                    label={t("value_lower_limit")}
                                    // required
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.valLl}
                                    onChange={(e) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[index].valLl =
                                        e.target.value;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <TextField
                                    label={t("value_upper_limit")}
                                    // required
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.valUl}
                                    onChange={(e) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[index].valUl =
                                        e.target.value;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </div>
                            {/* <div className="formElementGroup">
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <IntegerTextField
                                    min
                                    label={t("age_lower_limit")}
                                    // required
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.ageLl}
                                    onChange={(data) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[index].ageLl =
                                        data;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <IntegerTextField
                                    label={t("age_upper_limit")}
                                    // required
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.ageUl}
                                    onChange={(data) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[index].ageUl =
                                        data;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </div> */}

                            <div className="tskElemHeding">
                              {t("minimum_age")}
                            </div>

                            <div className="formElementGroup">
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <IntegerTextField
                                    min
                                    label={t("year")}
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.minAgeYear || 0}
                                    onChange={(data) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[
                                        index
                                      ].minAgeYear = data;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <IntegerTextField
                                    min
                                    label={t("month")}
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.minAgeMonth || 0}
                                    onChange={(data) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[
                                        index
                                      ].minAgeMonth = data;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <IntegerTextField
                                    min
                                    label={t("day")}
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.minAgeDay || 0}
                                    onChange={(data) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[
                                        index
                                      ].minAgeDay = data;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </div>

                            <div className="tskElemHeding">
                              {t("maximum_age")}
                            </div>

                            <div className="formElementGroup">
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <IntegerTextField
                                    label={t("Max Year")}
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.maxAgeYear}
                                    onChange={(data) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[
                                        index
                                      ].maxAgeYear = data;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <IntegerTextField
                                    label={t("Max Month")}
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.maxAgeMonth}
                                    onChange={(data) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[
                                        index
                                      ].maxAgeMonth = data;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <IntegerTextField
                                    label={t("Max Days")}
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reference.maxAgeDay}
                                    onChange={(data) => {
                                      const temp = { ...parameterObj };
                                      temp.parameterReferances[
                                        index
                                      ].maxAgeDay = data;
                                      setParameterObj(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </div>

                            <div>
                              <Button
                                className="dfultPrimaryBtn"
                                onClick={() => {
                                  const temp = { ...parameterObj };
                                  temp?.parameterReferances?.forEach(
                                    (item, index) => {
                                      const minAge = calculateAgeInDays(
                                        item.minAgeYear,
                                        item.minAgeMonth,
                                        item.minAgeDay
                                      );
                                      const maxAge = calculateAgeInDays(
                                        item.maxAgeYear,
                                        item.maxAgeMonth,
                                        item.maxAgeDay
                                      );
                                      temp.parameterReferances[index].ageLl =
                                        minAge;
                                      temp.parameterReferances[index].ageUl =
                                        maxAge;
                                    }
                                  );
                                  temp.parameterReferances[
                                    index
                                  ].editMode = false;
                                  setParameterObj(temp);
                                }}
                                startIcon={<Save />}
                              >
                                {t("save")}
                              </Button>
                            </div>
                          </>
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>

              {/* <ul>
                </ul> */}
            </>
          )}
        </div>

        <div className="elementFooter ">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => handleSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("submit")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => onClose()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
