import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

export const getUserProfileImageLink = (userId, orgId) => {
  return http.get(`misc/getUserProfileImageLink/${userId}/${orgId}`, {
    headers: authHeader(),
  });
};

export const createEvent = (orgId, userId, reqDto) => {
  return http.post(`calendar/createEvent/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const empOnLeaveList = (reqDto) => {
  return http.post(`calendar/empOnLeaveList`, reqDto, {
    headers: authHeader(),
  });
};

export const sendCallInvitePersonal = (
  reqDto,
  meetingId,
  userId,
  eventType
) => {
  //console.log(reqDto);
  return http.post(
    `/meeting/sendCallInvitePersonal/${meetingId}/${userId}/${eventType}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const getAllDays = () => {
  return http.get(`calendar/getAllDays`, {
    headers: authHeader(),
  });
};

export const createScheduleEvent = (orgId, userId, reqDto) => {
  return http.post(`calendar/createScheduleEvent/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const sendInvite = (reqDto, userId) => {
  return http.put(`contacts/sendInvite/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getContacts = (userId) => {
  return http.get(`contacts/getContacts/${userId}`, {
    headers: authHeader(),
  });
};

export const getAllUsersMapOfOrg = (orgId) => {
  return http.get(`person/getAllUsersMapOfOrg/${orgId}`, {
    headers: authHeader(),
  });
};

export const addContract = (userId, reqDto) => {
  return http.post(`contract/addContract/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getMyEvents = (userId) => {
  return http.get(`calendar/getMyEvents/${userId}`, {
    headers: authHeader(),
  });
};

export const getPendingNotificationList = (userId) => {
  return http.get(`notification/getMyPendingNotifications/${userId}`, {
    headers: authHeader(),
  });
};

export const getDateWiseTasks = (userId) => {
  return http.get(`task/getDateWiseTasks/${userId}`, {
    headers: authHeader(),
  });
};

export const getHolidaysList = (orgId) => {
  return http.get(`calendar/getHolidaysList/${orgId}`, {
    headers: authHeader(),
  });
};

export const getLeaveList = (userId) => {
  return http.get(`calendar/getLeaveList/${userId}`, {
    headers: authHeader(),
  });
};

export const getMyEventsInDateRanges = (userId, fromDate, toDate) => {
  return http.get(
    `/calendar/getMyEventsInDateRanges/${userId}/${fromDate}/${toDate}`,
    {
      headers: authHeader(),
    }
  );
};

export const cancelEvent = (
  eventId,
  userId,
  applicableForAllRecurringEvents,
  cancellationReason
) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.put(
    `calendar/cancelEvent/${eventId}/${userId}/${applicableForAllRecurringEvents}`,
    cancellationReason,
    config
  );
};

export const getUserTree = (orgId) => {
  return http.get(`person/getUserTree/${orgId}`, {
    headers: authHeader(),
  });
};

export const getExternalUsersOfOrg = (orgId) => {
  return http.get(`person/getExternalUsersOfOrg/${orgId}`, {
    headers: authHeader(),
  });
};

export const createVendorPartner = (userId, orgId, reqDto) => {
  return http.post(`person/createVendorPartner/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getEventParticipants = (eventId) => {
  return http.get(`calendar/getParticipantsOfEvent/${eventId}`, {
    headers: authHeader(),
  });
};

export const getPrivateNotes = (userId, eventId) => {
  return http.get(`calendar/getPrivateNotes/${userId}/${eventId}`, {
    headers: authHeader(),
  });
};

export const getChatAllContacts = (userId, orgId) => {
  return http.get(`chat/getChatAllContacts/${userId}/${orgId}`, {
    headers: authHeader(),
  });
};

export const addParticipants = (
  eventId,
  userId,
  eventType,
  appForAllRecEvents,
  reqDto
) => {
  return http.put(
    `calendar/addParticipants/${eventId}/${userId}/${eventType}/${appForAllRecEvents}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const addPrivateNotes = (userId, eventId, reqDto) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(
    `calendar/addPrivateNotes/${userId}/${eventId}`,
    reqDto,
    config,
    {
      headers: authHeader(),
    }
  );
};

export const changeConvenerChairperson = (eventId, userId, reqDto) => {
  return http.put(
    `calendar/changeConvenerChairperson/${eventId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const deletePrivateNotes = (userId, eventNoteId) => {
  return http.put(`calendar/deletePrivateNotes/${userId}/${eventNoteId}`, {
    headers: authHeader(),
  });
};

export const getChairpersonOrConvenerList = (eventId, type) => {
  return http.get(`calendar/getChairpersonOrConvenerList/${eventId}/${type}`, {
    headers: authHeader(),
  });
};

export const removeParticipantOfEvent = (
  eventParticipantId,
  userId,
  eventType,
  applicableForAllRecurringEvents
) => {
  return http.put(
    `calendar/removeParticipantOfEvent/${eventParticipantId}/${userId}/${eventType}/${applicableForAllRecurringEvents}`,
    {
      headers: authHeader(),
    }
  );
};

export const createWebinar = (orgId, userId, reqDto) => {
  return http.post(`calendar/createWebinar/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getWorksDetailOnWeekendHoliday = (userId, notWorkingDate) => {
  return http.get(
    `leave/getWorksDetailOnWeekendHoliday/${userId}/${notWorkingDate}`,
    { headers: authHeader() }
  );
};

export const getLeaveBalance = (userId, leaveRuleId) => {
  return http.get(`leave/getLeaveBalance/${userId}/${leaveRuleId}`, {
    headers: authHeader(),
  });
};

export const getLeaveRulesOfUser = (userId) => {
  return http.get(`leave/getLeaveRulesOfUser/${userId}`, {
    headers: authHeader(),
  });
};

export const leaveRequest = (userId, orgId, reqDto) => {
  return http.post(`leave/leaveRequest/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getLeaveRequestHistory = (userId, fromDt, toDt) => {
  return http.get(`leave/getLeaveRequestHistory/${userId}/${fromDt}/${toDt}`, {
    headers: authHeader(),
  });
};

export const submitWorksDetailOnWeekendHoliday = (
  orgId,
  userId,
  notWorkingDate,
  reqDto
) => {
  return http.post(
    `leave/submitWorksDetailOnWeekendHoliday/${orgId}/${userId}/${notWorkingDate}`,
    reqDto,
    { headers: authHeader() }
  );
};

export const getUsersEventsForConvenientCalendar = (reqDto, date) => {
  return http.post(
    `calendar/getUsersEventsForConvenientCalendar/${date}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const createMeeting = (orgId, userId, reqDto) => {
  return http.post(`calendar/createMeeting/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const cancelLeaveRequest = (leaveRequestId, userId) => {
  return http.put(`leave/cancelLeaveRequest/${leaveRequestId}/${userId}`, {
    headers: authHeader(),
  });
};

export const withdrawalLeaveRequest = (leaveRequestId, userId) => {
  return http.put(`leave/withdrawalLeaveRequest/${leaveRequestId}/${userId}`, {
    headers: authHeader(),
  });
};

export const getAssociateTasks = (userId) => {
  return http.get(`task/getAssociateTasks/${userId}`, {
    headers: authHeader(),
  });
};

export const getAssociateUserIdsOfTaskId = (taskIds) => {
  return http.post(`task/getAssociateUserIdsOfTaskId`, taskIds, {
    headers: authHeader(),
  });
};

export const getUserBasicDetails = (userId) => {
  return http.get(`person/getUserBasicDetails/${userId}`, {
    headers: authHeader(),
  });
};

export const getRefreshToken = (userId) => {
  return http.get(`calendar/getRefreshToken/${userId}`, {
    headers: authHeader(),
  });
};

export const getNextEvent = (userId) => {
  return http.get(`calendar/getNextEvent/${userId}`, {
    headers: authHeader(),
  });
};

export const sendMeetingInviteToExternalUsers = (meetingId, userId, reqDto) => {
  return http.put(
    `meeting/sendMeetingInviteToExternalUsers/${meetingId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};
