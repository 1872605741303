import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  AttachFile,
  CancelRounded,
  CheckCircle,
  DownloadForOffline,
  Notes,
  Translate,
  TranslateSharp,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { XLg } from "react-bootstrap-icons";
import {
  getSubtitlesOfMeeting,
  getBcp47LanguageCodeNameOfUser,
  getOrgImageBase64,
  getParticipantsOfEvent,
  getAllFilesUploaded,
  getMeetingAgendas,
  downloadFile,
  getMeetingParticipantsDetailsAndTiming,
} from "../../../../../../services/VideoConferenceService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import {
  apiEndPoint,
  publicUrl,
} from "../../../../../../constants/url_provider";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AppUtils from "../../../../../../utils/AppUtils";
import DateUtils from "../../../../../../utils/DateUtils";
import { GOOGLE_TRANSLATE_SUPPORTED_LANG } from "../../../../../../constants/const_values";
import ZoyelRTERnd from "../../../../../RTE/zoyelRTERnd";
import { CustomModal } from "../../../../../task/view/component/Modals/CustomModal";
import {
  chatAI,
  getEventParticipants,
  getMeetingMomDetails,
  getMeetingRecordingLink,
  submitMinutesOfMeeting,
  updateUsersPermissionMOM,
} from "../../../../../../services/MeetingService";
import { AddTabContext } from "../../../../../dashboard/view/DashboardPage";
import { useTranslation } from "react-i18next";
import "../css/MeetingSummary.css";
import { OneFingerSelectHandGesture, SpockHandGesture } from "iconoir-react";
import { GetLoginUserDetails } from "../../../../../../utils/Utils";
import SecureIndexedDB from "../../../../../../utils/IndexedDB";
import zhAIService from "../../../../../../services/ZhAIService";
import MeetingSummarySuggestedTaskCard from "./MeetingSummarySuggestedTaskCard";

const MeetingSummary = ({
  handleMenuCloseButton,
  roomNumber,
  tabKey,
  selectedEvent,
  isConvenor,
}) => {
  //demo remove it
  // roomNumber = "448227607";
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [subtitles, setSubtitles] = useState([]);
  const [targetLanguage, setTargetLanguage] = useState(null);

  const userDetails = GetLoginUserDetails();
  const [intervalId, setIntervalId] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [generating, setGenerating] = useState(false);

  const [rteHtml, setRteHtml] = useState("");
  const [rteRawJson, setRteRawJson] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [rteText, setRteText] = useState("");
  const [showMom, setShowMom] = useState(false);
  const { updateTab } = useContext(AddTabContext);

  console.log("selectedEvent", selectedEvent);

  const languages = GOOGLE_TRANSLATE_SUPPORTED_LANG;

  console.log("languages", languages);
  console.log("languages[0]", languages[0]);

  const [value, setValue] = useState(languages[0]);

  const [momDetails, setMomDetails] = useState(null);
  const [momExists, setMomExists] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);

  React.useEffect(() => {
    // console.log("TranscriptionContent UseEffect", props.meetingFileUploaded);
    setLoading(true);
    getMeetingMomDetails(selectedEvent.eventLink).then((response) => {
      console.log("getMeetingMomDetails", response.data);
      if (response && response.data && response.data.momId > 0) {
        const filteredData = response.data.usersWithAccess.filter(
          (option) => option.userId !== userDetails.userId
        );
        setSelectedUsers(filteredData);
        for (
          let index = 0;
          index < response.data.usersWithAccess.length;
          index++
        ) {
          const element = response.data.usersWithAccess[index];
          if (element.userId === userDetails.userId) {
            setMomExists(true);
            setMomDetails(response.data);
            break;
          }
          if (response.data.usersWithAccess.length - 1 === index) {
            setMomExists(false);
          }
        }
      } else {
        setMomExists(false);
      }
    });
    getSubtitlesOfMeeting(selectedEvent.eventLink).then(async (response) => {
      console.log(response.data);
      if (response.data) {
        if (await SecureIndexedDB.getItem("meetingSubs")) {
          console.log(
            "1",
            await SecureIndexedDB.getItem("meetingSubs"),
            await SecureIndexedDB.getItem("ORG_ID")
          );
          let tempList = JSON.parse(
            await SecureIndexedDB.getItem("meetingSubs")
          );
          if (response.data.length > 0) {
            for (
              let index = tempList.length;
              index < response.data.length;
              index++
            ) {
              const subDto = response.data[index];
              if (typeof subDto?.transcript === "string") {
                tempList.push(subDto);
              }
            }
            setSubtitles(tempList);
          }
        } else {
          setSubtitles(response.data);
        }

        if (await SecureIndexedDB.getItem("sub-lang")) {
          setTargetLanguage(await SecureIndexedDB.getItem("sub-lang"));
          let langObj = {
            languageCode: await SecureIndexedDB.getItem("sub-lang"),
            languageName: await SecureIndexedDB.getItem("sub-langName"),
          };
          setValue(langObj);
          //   SecureIndexedDB.setItem("sub-lang", "en-US");
        } else {
          await getBcp47LanguageCodeNameOfUser(userDetails.userId).then(
            async (response) => {
              if (response && response.data && response.data.length > 0) {
                // for (let i = 0; i < response.data.length; i++) {
                //   response.data[i].selected = true;
                //   spokenLanguagesTemp.push(response.data[i]);
                //   spokenLanguagesCode.push(response.data[i].id);
                // }
                let isEnglishPresent = false;
                for (let index = 0; index < response.data.length; index++) {
                  const langDto = response.data[index];
                  if (langDto.name.substring(0, 2).toLowerCase() === "en") {
                    isEnglishPresent = true;
                  }
                }
                if (isEnglishPresent) {
                  setTargetLanguage("en-US");
                  SecureIndexedDB.setItem("sub-lang", "en-US");
                  SecureIndexedDB.setItem("sub-langName", "English");
                  let langObj = {
                    languageCode: await SecureIndexedDB.getItem("sub-lang"),
                    languageName: await SecureIndexedDB.getItem("sub-langName"),
                  };
                  setValue(langObj);
                } else {
                  setTargetLanguage(response.data[0].id);
                  SecureIndexedDB.setItem("sub-lang", response.data[0].id);
                  SecureIndexedDB.setItem(
                    "sub-langName",
                    response.data[0].name
                  );
                  let langObj = {
                    languageCode: await SecureIndexedDB.getItem("sub-lang"),
                    languageName: await SecureIndexedDB.getItem("sub-langName"),
                  };
                  setValue(langObj);
                }
              } else {
                setTargetLanguage("en-US");
                SecureIndexedDB.setItem("sub-lang", "en-US");
                SecureIndexedDB.setItem("sub-langName", "English");
                let langObj = {
                  languageCode: await SecureIndexedDB.getItem("sub-lang"),
                  languageName: await SecureIndexedDB.getItem("sub-langName"),
                };
                setValue(langObj);
              }
            }
          );
        }
      }
      setLoading(false);
    });
  }, [roomNumber]);

  React.useEffect(() => {
    if (tabKey) {
      updateTab({ data: selectedEvent }, tabKey);
    }
  }, [tabKey, selectedEvent]);

  const translateSubtitles = async () => {
    setLoading(true);
    console.log("translateSubtitles UseEffect", targetLanguage);
    let tempSubList = [];
    for (let index = 0; index < subtitles.length; index++) {
      let subDto = subtitles[index];
      console.log("translateSubtitles UseEffect", subDto);
      if (
        targetLanguage.substring(0, 2).toLowerCase() !==
        subDto.languageCode.toLowerCase().substring(0, 2)
      ) {
        let translatedText = await AppUtils.googleTranslate(
          subDto.transcript,
          subDto.languageCode,
          targetLanguage
        );
        subDto.transcript = translatedText;
        subDto.languageCode = targetLanguage;
      }
      tempSubList.push(subDto);
    }
    setSubtitles(tempSubList);
    setRefresh(refresh + 1);
    setLoading(false);
    const jsonString = JSON.stringify(tempSubList);
    SecureIndexedDB.setItem("meetingSubs", jsonString);
  };

  const translateText = async (id, text, fromLang) => {
    // let translatedText = await AppUtils.googleTranslate(
    //   text,
    //   fromLang,
    //   targetLanguage
    // );
    // console.log("translateText--->>", translatedText);

    const reqDto = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "user",
          content:
            "Please translate the this line to " +
            targetLanguage +
            " just return the line and don't write anything else i will directly display to user, line is: " +
            text,
        },
      ],
    };

    const response = await chatAI(reqDto);
    const translatedText = response.data.choices[0].message.content;

    // subtitles.forEach((subDto) => {
    //   if (subDto.id === id) {
    //     console.log("Here--->>", subDto);
    //     subDto.transcript = text;
    //     subDto.languageCode = targetLanguage;
    //   }
    // });
    await setSubtitles((prevData) => {
      const newData = [...prevData];
      newData[id].transcript = translatedText;
      newData[id].languageCode = targetLanguage;
      return newData;
    });
    let tempList = subtitles;
    const jsonString = JSON.stringify(tempList);
    SecureIndexedDB.setItem("meetingSubs", jsonString);
    // setRefresh(refresh + 1);
  };

  const refreshSubtitles = () => {
    setLoading(true);
    let isNewSubPresent = false;
    getSubtitlesOfMeeting(roomNumber).then(async (response) => {
      let tempSubs =
        JSON.parse(await SecureIndexedDB.getItem("meetingSubs")) || [];
      if (response.data && response.data.length > tempSubs.length) {
        isNewSubPresent = true;
        tempSubs = [...tempSubs, ...response.data.slice(tempSubs.length)];
      }
      if (isNewSubPresent) {
        toast.success(t("subtitles_loaded_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSubtitles(tempSubs);
      } else {
        toast.info(t("no_more_subtitles_present"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setLoading(false);
    });
  };

  const refreshSubtitlesTimer = () => {
    let isNewSubPresent = false;
    getSubtitlesOfMeeting(roomNumber).then(async (response) => {
      let tempSubs =
        JSON.parse(await SecureIndexedDB.getItem("meetingSubs")) || [];
      if (response.data && response.data.length > tempSubs.length) {
        isNewSubPresent = true;
        tempSubs = [...tempSubs, ...response.data.slice(tempSubs.length)];
      }
      if (isNewSubPresent) {
        setSubtitles(tempSubs);
      }
    });
  };

  const translateAll = () => {
    translateSubtitles();
  };

  const generatePDF = async (data) => {
    console.log("generatePDF- selectedEvent", selectedEvent);
    setDownloading(true);
    toast.info(t("downloading_transcriptions_please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });
    const fontUrl = publicUrl + "/zoyel_elements/fonts/ArialUnicodeMS.ttf";
    const fontName = "NotoSans";

    const fontOptions = {
      url: fontUrl,
      name: fontName,
      fontStyle: "normal",
      fontWeight: "normal",
    };

    const doc = new jsPDF();

    doc.addFont(
      fontOptions.url,
      fontOptions.name,
      fontOptions.fontStyle,
      fontOptions.fontWeight
    );

    doc.setFont(fontOptions.name);
    const tableColumnHeaders = ["Time", "Speaker Name", "Speaker Text"];
    const tableRows = [];

    const tableHeaderColor = "#8f95eb";
    const headerColor = "#000000";

    const dateExported = new Date();
    const headerText = `Meeting Transcript of ${selectedEvent.eventTitle}`;
    const footerTextLeft = `Transcript exported at ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
      dateExported
    )}`;
    const footerTextRight = userDetails.orgName;
    const response = await getOrgImageBase64(userDetails.orgId);
    const responseParticipants = await getParticipantsOfEvent(
      selectedEvent.eventId
    );

    const listItems = [
      `Description: ${selectedEvent.eventDescription}`,
      `Start Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selectedEvent.eventStartTime
      )}`,
      `End Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selectedEvent.eventEndTime
      )}`,
      `Participants: ${responseParticipants.data
        .map((obj) => obj.userName)
        .join(", ")}`,
    ];

    data.forEach((obj) => {
      const dataRow = [
        DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(obj.audioStartTime),
        obj.userName,
        obj.transcript,
      ];
      tableRows.push(dataRow);
    });

    const cellWidth = 40;
    const cellHeight = 5;
    const startY = 40;

    doc.addImage(response.data, "PNG", 15, 5, 15, 15);

    doc.setFontSize(16);
    doc.setTextColor(headerColor);
    doc.text(headerText, doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    const lineY = 25;
    const lineWidth = doc.internal.pageSize.getWidth() - 20;
    const lineSpacing = 2;
    doc.setDrawColor(0);
    doc.setLineDash([lineSpacing]);
    doc.line(15, lineY, 5 + lineWidth, lineY);

    const listItemX = 15;
    const listItemY = 30;
    const listItemSpacing = 1;

    listItems.forEach((item, index) => {
      const listItemPosY = listItemY + index * (cellHeight + listItemSpacing);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(item, listItemX, listItemPosY, {
        maxWidth: 180,
        align: "left",
        baseline: "top",
        angle: 0,
      });
    });

    doc.setLineDash([0]);
    const tableStartY =
      listItemY + listItems.length * (cellHeight + listItemSpacing) + 10;

    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: tableStartY,
      theme: "grid",
      styles: {
        font: fontOptions.name,
        fontStyle: "normal",
      },
      headStyles: {
        fillColor: tableHeaderColor,
      },
    });

    const pageCount = doc.internal.getNumberOfPages();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(footerTextLeft, 15, doc.internal.pageSize.getHeight() - 10, {
        align: "left",
      });
      doc.text(
        footerTextRight,
        doc.internal.pageSize.getWidth() - 15,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
    }

    doc.save(
      `${roomNumber}_${DateUtils.getDateInddmmyy_hhmmss(dateExported)}.pdf`
    );
    setDownloading(false);
  };

  const generateMomPdf = async () => {
    const fontUrl = publicUrl + "/zoyel_elements/fonts/ArialUnicodeMS.ttf";
    const fontName = "NotoSans";

    const fontOptions = {
      url: fontUrl,
      name: fontName,
      fontStyle: "normal",
      fontWeight: "normal",
    };

    const doc = new jsPDF();
    doc.addFont(
      fontOptions.url,
      fontOptions.name,
      fontOptions.fontStyle,
      fontOptions.fontWeight
    );

    doc.setFont(fontOptions.name);

    const dateExported = new Date();
    const headerText = `Meeting MOM of ${selectedEvent.eventTitle}`;
    const footerTextLeft = `MOM exported at ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
      dateExported
    )}`;

    const footerTextRight = userDetails.orgName;
    const response = await getOrgImageBase64(userDetails.orgId);
    const responseParticipants = await getParticipantsOfEvent(
      selectedEvent.eventId
    );

    const listItems = [
      `Description: ${selectedEvent.eventDescription}`,
      `Start Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selectedEvent.eventStartTime
      )}`,
      `End Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selectedEvent.eventEndTime
      )}`,
      `Participants: ${responseParticipants.data
        .map((obj) => obj.userName)
        .join(", ")}`,
    ];

    const cellWidth = 40;
    const cellHeight = 5;
    const startY = 40;

    doc.addImage(response.data, "PNG", 15, 5, 15, 15);

    const headerColor = "#000000";
    doc.setFontSize(16);
    doc.setTextColor(headerColor);
    doc.text(headerText, doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    const lineY = 25;
    const lineWidth = doc.internal.pageSize.getWidth() - 20;
    const lineSpacing = 2;
    doc.setDrawColor(0);
    doc.setLineDash([lineSpacing]);
    doc.line(15, lineY, 5 + lineWidth, lineY);

    const listItemX = 15;
    const listItemY = 30;
    const listItemSpacing = 1;

    listItems.forEach((item, index) => {
      const listItemPosY = listItemY + index * (cellHeight + listItemSpacing);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(item, listItemX, listItemPosY, {
        maxWidth: 180,
        align: "left",
        baseline: "top",
        angle: 0,
      });
    });

    doc.setLineDash([0]);

    // const options = {
    //   html2canvas: { scale: 2 },
    //   jsPDF: { unit: "pt", format: "a4" },
    // };

    // Use html2pdf plugin
    // doc.html(rteHtml, options).then(() => {
    //   // Save the PDF
    //   doc.save("document.pdf");
    // });

    const marginLeft = 10;
    const marginTop = 64;
    const maxLineWidth = 180;
    const splitText = doc.splitTextToSize(rteText, maxLineWidth);

    doc.text(splitText, marginLeft, marginTop);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(footerTextLeft, 15, doc.internal.pageSize.getHeight() - 10, {
        align: "left",
      });
      doc.text(
        footerTextRight,
        doc.internal.pageSize.getWidth() - 15,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
    }

    doc.save(
      `${roomNumber}_${DateUtils.getDateInddmmyy_hhmmss(dateExported)}.pdf`
    );

    setDownloading(false);
  };

  const [loadingMOM, setLoadingMOM] = useState(false);
  const generateMOM = () => {
    setLoadingMOM(true);
    getSubtitlesOfMeeting(selectedEvent.eventLink)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          let tempString = response.data
            .map(
              (item) =>
                `${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                  item.audioStartTime
                )} [${item.userName}]  ${item.transcript}`
            )
            .join(",");

          tempString =
            tempString + ", this is the transcript,generate MOM,Please ";

          const reqDto = {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "user",
                content: tempString,
              },
            ],
          };

          chatAI(reqDto).then((res) => {
            console.log(res.data);
            const msg = res.data.choices[0].message.content;
            setRteText(msg);
            setShowMom(true);
          });
          setLoadingMOM(false);
        } else {
          toast.error(t("could_not_generate_mom"));
          setLoadingMOM(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingMOM(false);
      });
  };

  //Meeting summary code

  const [recordingLink, setRecordingLink] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [meetingAgendasList, setMeetingAgendasList] = useState([]);
  const [meetingStartTime, setMeetingStartTime] = useState(null);
  const [meetingEndTime, setMeetingEndTime] = useState(null);
  const [
    meetingParticipantsDetailsAndTiming,
    setMeetingParticipantsDetailsAndTiming,
  ] = React.useState([]);
  const [meetingParticipants, setMeetingParticipants] = useState([]);
  useEffect(() => {
    //testing

    if (selectedEvent) {
      // selectedEvent.eventLink = "9702757653";
      // selectedEvent.eventId = 8742;
      getMeetingRecordingLink(selectedEvent.eventLink).then((response) => {
        console.log(response.data);
        if (response.data.returnValue === "1") {
          setRecordingLink(response.data.message);
        }
      });
      getAllFilesUploaded(selectedEvent.eventLink).then((response) => {
        console.log("getFiles Details", response.data);
        setFileList(response.data);
      });
      getMeetingAgendas(selectedEvent.eventId).then((response) => {
        setMeetingAgendasList(response.data);
      });
      getEventParticipants(selectedEvent.eventId).then((response) => {
        let sortedData = [];
        if (response.data.length > 1) {
          sortedData = response.data.sort(
            (a, b) => (b.convener === "Y") - (a.convener === "Y")
          );
        } else {
          sortedData = response.data;
        }
        console.log("sortedData-->>>", sortedData);
        setMeetingParticipants(sortedData);
      });
      getMeetingParticipantsDetailsAndTiming(selectedEvent.eventLink).then(
        (response) => {
          console.log("getMeetingParticipantsDetailsAndTiming", response.data);

          if (response.data && response.data.length > 0) {
            console.log("startTime", selectedEvent.eventStartTime);
            console.log("startTime", response.data[0].joinTime);
            setMeetingStartTime(response.data[0].joinTime);
          } else {
            setMeetingStartTime(selectedEvent.eventStartTime);
          }
          let meetingEndTimeTemp = null;
          for (let index = 0; index < response.data.length; index++) {
            const meetingParDto = response.data[index];
            if (null != meetingParDto.leaveTime) {
              if (null === meetingEndTimeTemp) {
                meetingEndTimeTemp = meetingParDto.leaveTime;
              }
              if (
                null !== meetingEndTimeTemp &&
                new Date(meetingEndTimeTemp) -
                  new Date(meetingParDto.leaveTime) <
                  0
              ) {
                meetingEndTimeTemp = meetingParDto.leaveTime;
              }
            }
          }
          if (!meetingEndTimeTemp) {
            meetingEndTimeTemp = selectedEvent.eventEndTime;
          }
          setMeetingEndTime(meetingEndTimeTemp);
          setMeetingParticipantsDetailsAndTiming(response.data);
        }
      );
    }
  }, [selectedEvent.eventLink]);

  const handleDownloadFile = (fileName, folderName, fileType) => {
    toast.info(t("downloading_file_please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });
    window.open(
      apiEndPoint + `/appTest/downloadFileTest/${fileName}/${folderName}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handelDownloadFileFromUrl = (files) => {
    files.forEach((file) => {
      downloadFile(file.fileName, userDetails.orgId).then((response) => {
        window.open(
          apiEndPoint +
            `/appTest/downloadFileTest/${file.fileName}/${userDetails.orgId}`,
          "_blank",
          "noopener,noreferrer"
        );
      });
    });
  };

  const [taskFromTranscript, setTaskFromTranscript] = useState(null);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const fetchTaskFromSubtitles = async () => {
    setLoadingTasks(true);
    let reqDto = "";
    subtitles.forEach((subDto) => {
      reqDto += subDto.userName + ": " + subDto.transcript + ", ";
    });
    console.log("fetchTaskFromSubtitles 1", reqDto);
    const response = await zhAIService.getTaskByTranscript(reqDto);
    console.log("fetchTaskFromSubtitles 2", response.data);
    if (response && response.data) {
      // let tempList = [];
      const combinedList = [
        ...response.data,
        // ...response.data,
        // ...response.data,
        // ...response.data,
        // ...response.data,
      ];
      setTaskFromTranscript(combinedList);
    }
    setLoadingTasks(false);
  };

  useEffect(() => {
    if (subtitles && subtitles.length > 0) {
      fetchTaskFromSubtitles();
    } else {
      setLoadingTasks(false);
    }
  }, [subtitles]);

  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

  const handleChangeAutoCompleteInputValue = (event, newInputValue) => {
    setAutocompleteInputValue(newInputValue);
  };

  const computedList = useMemo(() => {
    let filtered = [];
    if (
      autocompleteInputValue.length > 0 &&
      autocompleteInputValue.trim() !== ""
    ) {
      filtered = meetingParticipants.filter((option) =>
        option.userName
          .toLowerCase()
          .includes(autocompleteInputValue.toLowerCase())
      );
      filtered = filtered.filter(
        (option) => option.userId !== userDetails.userId
      );
    }

    return filtered;
  }, [autocompleteInputValue, meetingParticipants]);

  const [loadingSubmitMom, setLoadingSubmitMom] = useState(false);

  const finalizeMOM = async () => {
    toast.info(t("please_wait"));
    setLoadingSubmitMom(true);
    console.log("finalizeMOM", selectedUsers);
    const response = await submitMinutesOfMeeting(
      rteText,
      selectedEvent.eventLink,
      selectedEvent.eventId,
      userDetails.userId
    );
    if (response && response.data.returnValue === "1") {
      console.log("submitMinutesOfMeeting", response.data);
      const momId = response.data.message;
      const userIds = selectedUsers.map((user) => user.userId);
      console.log("momId", momId, userIds);
      userIds.push(userDetails.userId);
      const response2 = await updateUsersPermissionMOM(userIds, momId);
      if (response2 && response2.data.returnValue === "1") {
        toast.success(t("mom_submitted_successfully"));
        ///
        getMeetingMomDetails(selectedEvent.eventLink)
          .then((response) => {
            console.log("getMeetingMomDetails", response.data);
            if (response && response.data && response.data.momId > 0) {
              const filteredData = response.data.usersWithAccess.filter(
                (option) => option.userId !== userDetails.userId
              );
              setSelectedUsers(filteredData);
              // setSelectedUsers(response.data.usersWithAccess);
              for (
                let index = 0;
                index < response.data.usersWithAccess.length;
                index++
              ) {
                const element = response.data.usersWithAccess[index];
                if (element.userId === userDetails.userId) {
                  setMomExists(true);
                  setMomDetails(response.data);
                  break;
                }
                if (response.data.usersWithAccess.length - 1 === index) {
                  setMomExists(false);
                }
              }
            } else {
              setMomExists(false);
            }
            setLoadingSubmitMom(false);
            setShowMom(false);
          })
          .catch((err) => {
            console.log(err);
            setLoadingSubmitMom(false);
          });
        ///
      } else {
        toast.error(t("something_went_wrong"));
        setLoadingSubmitMom(false);
      }
    } else {
      toast.error(t("something_went_wrong"));
      setLoadingSubmitMom(false);
    }
  };

  const viewMOM = () => {
    setRteText(momDetails.momText);
    setShowMom(true);
  };

  return (
    <>
      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="transcripHeader"></div>
          <div className="transcriptionContainArea">
            <div class="meeting-summary">
              <div class="left-section">
                <h2>{t("meeting_details")}</h2>
                <p>
                  <strong>{t("meeting_name")}:</strong>{" "}
                  {selectedEvent?.eventTitle}
                </p>
                <p>
                  <strong>{t("meeting_description")}:</strong>{" "}
                  {selectedEvent?.eventDescription}
                </p>
                <p>
                  <strong>{t("meeting_scheduled_time")}:</strong>{" "}
                  {DateUtils.printEventDate(
                    new Date(selectedEvent.eventStartTime),
                    new Date(selectedEvent.eventEndTime)
                  )}
                </p>

                <p>
                  <strong>{t("meeting_convened_time")}:</strong>{" "}
                  {DateUtils.printEventDate(
                    new Date(meetingStartTime),
                    new Date(meetingEndTime)
                  )}
                </p>

                <div class="meeting-details">
                  <h3>{t("guests")}</h3>
                  {meetingParticipants.map((participantDto) => {
                    return (
                      <>
                        <div className={`metGustLstInd gustAsConvenor`}>
                          <div className="metGstImg">
                            <img src={participantDto.profileImage} alt="" />

                            {participantDto.availability === "Y" && (
                              <span
                                className="metConVerd"
                                title={t("available")}
                              >
                                <CheckCircle />
                              </span>
                            )}
                            {participantDto.availability === "N" && (
                              <span
                                className="metConRej"
                                title={t("unavailable")}
                              >
                                <CancelRounded />
                              </span>
                            )}
                            {participantDto.availability !== "N" &&
                              participantDto.availability !== "Y" && (
                                <span className="metConNS">
                                  <CheckCircle />
                                </span>
                              )}
                          </div>
                          <div className="metGstName">
                            {participantDto.userName}
                            {participantDto.convener === "Y" && (
                              <span className="metConvenor">
                                ({t("convenor")})
                              </span>
                            )}
                            {participantDto.chairperson === "Y" && (
                              <span className="metConvenor">
                                ({t("chairperson")})
                              </span>
                            )}
                          </div>
                        </div>
                        <ul>
                          {/* {!meetingParticipantsDetailsAndTiming.some(
                            (participant) =>
                              participant.participantId ===
                              participantDto.userId
                          ) && (
                            <span style={{ color: "#ff7373" }}>
                              {t("did_not_join_the_call")}
                            </span>
                          )} */}
                          {meetingParticipantsDetailsAndTiming.map(
                            (meetingParDto) => {
                              if (
                                meetingParDto.partcipantId ===
                                participantDto.userId
                              ) {
                                return (
                                  <>
                                    <li>
                                      {!meetingParDto.joinTime
                                        ? ""
                                        : t("joined_at") +
                                          DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                            new Date(meetingParDto.joinTime)
                                          )}
                                      {!meetingParDto.leaveTime
                                        ? ""
                                        : ", " +
                                          t("left_at") +
                                          DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                            new Date(meetingParDto.leaveTime)
                                          )}
                                      {/* {(!meetingParDto.joinTime ||
                                          !meetingParDto.leaveTime) &&
                                          `${participantDto.userName} was not present in the meeting`} */}
                                    </li>

                                    <br></br>
                                  </>
                                );
                              } else {
                                return <></>;
                              }
                            }
                          )}
                        </ul>
                        <br></br>
                      </>
                    );
                  })}
                </div>
              </div>
              <div class="right-section">
                {/* recording links */}
                {isConvenor && (
                  <>
                    <h2>{t("meeting_recordings")}</h2>
                    <div class="section meeting-links">
                      {recordingLink != null
                        ? recordingLink.split("~").map((data, index) => {
                            return (
                              <a href={data} rel="noreferrer" target="_blank">
                                {t("recording")} {index + 1}
                              </a>
                            );
                          })
                        : t("no_recordings_present")}
                    </div>
                  </>
                )}

                {/*meeting transcripts  */}
                {isConvenor && (
                  <div class="section meeting-transcript">
                    <h2>{t("meeting_transcript")}</h2>
                    <div class="buttons">
                      <Autocomplete
                        className="transcripAutoCompleteView"
                        disablePortal
                        id="transcripAutoComplete"
                        options={languages}
                        value={value}
                        onChange={(event, newValue) => {
                          setTargetLanguage(newValue?.languageCode);
                          SecureIndexedDB.setItem(
                            "sub-lang",
                            newValue?.languageCode
                          );
                          SecureIndexedDB.setItem(
                            "sub-langName",
                            newValue?.languageName
                          );
                          setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        getOptionLabel={(option) => option?.languageName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            hiddenLabel
                            placeholder={t("select_translate_language")}
                          />
                        )}
                      />
                      {/* <Button
                        className="transAllBtn"
                        startIcon={<Translate />}
                        onClick={() => {
                          translateAll();
                        }}
                      >
                        {t("translate_all")}
                      </Button> */}

                      {/* <Button
                        className="transAllBtn"
                        startIcon={<DownloadForOffline />}
                        onClick={() => {
                          generatePDF(subtitles);
                        }}
                        disabled={downloading}
                      >
                        {downloading ? t("please_wait") : t("download")}
                      </Button> */}
                      {/* <button>Download MOM</button> */}
                      {/* <button>Download PDF</button> */}
                    </div>
                    <div class="transcript">
                      {/* <p>
                      <strong>John:</strong> Welcome everyone to the meeting...
                    </p>
                    <p>
                      <strong>Jane:</strong> Thank you, John. Today we'll
                      discuss...
                    </p>
                    <p>
                      <strong>Mike:</strong> I'd like to add on...
                    </p> */}
                      {subtitles &&
                        (!targetLanguage || targetLanguage === "") &&
                        "Please select target language to display the subtitles"}
                      {subtitles &&
                        targetLanguage &&
                        subtitles.map((subDto, index) => {
                          return (
                            <p key={subDto.id}>
                              <span>
                                {DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                  subDto.audioStartTime
                                )}
                                :
                              </span>
                              <span>
                                &nbsp;&nbsp;[{subDto.userName}]&nbsp;&nbsp;
                              </span>
                              <span>
                                {typeof subDto.transcript === "string" &&
                                  subDto.transcript}
                              </span>
                              {targetLanguage &&
                                subDto.languageCode
                                  .substring(0, 2)
                                  .toLowerCase() !==
                                  targetLanguage
                                    .substring(0, 2)
                                    .toLowerCase() && (
                                  <span>
                                    <button
                                      className="translateEachBtn"
                                      onClick={() =>
                                        translateText(
                                          index,
                                          subDto.transcript,
                                          subDto.languageCode
                                        )
                                      }
                                      title={t("translate")}
                                    >
                                      {/* {t("translate")} */}
                                      <TranslateSharp />
                                    </button>
                                  </span>
                                )}
                            </p>
                          );
                        })}
                      {subtitles &&
                        subtitles.length === 0 &&
                        targetLanguage &&
                        t("no_subtitles_present")}
                      {targetLanguage && (
                        <span className="loadMoreSpn">
                          <Button
                            onClick={() => {
                              refreshSubtitles();
                            }}
                            className="loadMoreBtn"
                          >
                            {subtitles && subtitles.length === 0
                              ? t("load_subtitles")
                              : t("load_more")}
                          </Button>
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {/* Meeting Mom */}
                {!momExists && isConvenor && (
                  <>
                    <h2>{t("minutes_of_the_meeting")}</h2>
                    <div class="section meeting-links">
                      <Button
                        className="transAllBtn"
                        onClick={() => {
                          generateMOM();
                        }}
                        disabled={loadingMOM}
                      >
                        {loadingMOM ? t("please_wait") : t("generate_mom")}
                      </Button>
                    </div>
                  </>
                )}
                {!momExists && !isConvenor && (
                  <>
                    <h2>{t("minutes_of_the_meeting")}</h2>
                    <div class="section meeting-links">
                      {t("minutes_of_the_meeting_is_not_available")}
                    </div>
                  </>
                )}

                {momExists && momDetails && (
                  <>
                    <h2>{t("minutes_of_the_meeting")}</h2>
                    <div class="section meeting-links">
                      <Button
                        className="transAllBtn"
                        onClick={() => {
                          viewMOM();
                        }}
                      >
                        {t("view_mom")}
                      </Button>
                    </div>
                  </>
                )}

                {/*suggested tasks  */}
                <h2>{t("suggested_tasks")}</h2>
                <div class="section meeting-tasks">
                  {taskFromTranscript && (
                    <MeetingSummarySuggestedTaskCard
                      tasks={taskFromTranscript}
                    />
                  )}
                  {!taskFromTranscript && !loadingTasks && "No tasks found"}
                  {loadingTasks && (
                    <>
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "0.9rem",
                          bgcolor: "#ff9900",
                          width: "31vw",
                          lineHeight: "20px",
                        }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "1rem",
                          bgcolor: "#ff9900",
                          width: "31vw",
                          lineHeight: "20px",
                        }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "1rem",
                          bgcolor: "#ff9900",
                          width: "14vw",
                          lineHeight: "20px",
                        }}
                      />
                    </>
                  )}
                </div>

                {/*meeting files uploaded  */}
                <h2>{t("meeting_files")}</h2>
                <div class="section meeting-files">
                  {/* <a href="#">File 1</a>
                  <a href="#">File 2</a>
                  <a href="#">File 3</a> */}
                  {(fileList === null || fileList?.length === 0) &&
                    t("no_files_were_uploaded_during_the_meeting")}
                  {fileList.map((file, index) => (
                    <>
                      <a
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleDownloadFile(
                            file.field1,
                            file.field3,
                            file.field4
                          )
                        }
                      >
                        {index + 1 + ") "}
                        {file.field1}
                      </a>
                    </>
                  ))}
                </div>

                {/*meeting agenda  */}
                <h2>{t("meeting_agenda")}</h2>
                <div class="section meeting-agenda">
                  {meetingAgendasList !== null &&
                  meetingAgendasList.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th>{t("sl_no")}</th>
                          <th>{t("subject")}</th>
                          <th>{t("details")}</th>
                          <th>{t("polling_show_of_hands")}</th>
                          <th>{t("files")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                        <td>10:00 AM</td>
                        <td>Introduction</td>
                        <td>John</td>
                        <td>Overview of the meeting</td>
                      </tr> */}
                        {meetingAgendasList.map((agenda, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{agenda.noticeSubject}</td>
                            <td>{agenda.noticeDetail}</td>
                            <td>
                              {agenda.polling === "Y" && (
                                <Tooltip
                                  title={t("polling")}
                                  arrow
                                  placement="right"
                                >
                                  <OneFingerSelectHandGesture className="attachIcoSelected" />
                                </Tooltip>
                              )}
                              {agenda.showOfHand === "Y" && (
                                <Tooltip
                                  title={t("show_of_hand")}
                                  arrow
                                  placement="right"
                                >
                                  <SpockHandGesture className="attachIcoSelected" />
                                </Tooltip>
                              )}
                            </td>
                            <td style={{ cursor: "pointer" }}>
                              {agenda.fileLinks.length > 0 && (
                                <>
                                  <Tooltip
                                    title={
                                      <ul>
                                        {agenda.fileLinks.map(
                                          (file, fileIndex) => (
                                            <li key={fileIndex}>
                                              {file.fileName}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    }
                                  >
                                    <AttachFile
                                      className="attachIcoSelected"
                                      onClick={() =>
                                        handelDownloadFileFromUrl(
                                          agenda.fileLinks
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    t("no_meeting_agendas_present")
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showMom && rteText !== "" && (
        <CustomModal
          modalTitle={momExists ? t("view_mom") : t("update_mom")}
          closeModal={() => {
            setShowMom(false);
          }}
          className={"width90vwModel"}
        >
          <div className="zRTEmomMainDiv">
            <ZoyelRTERnd
              setRteHtml={setRteHtml}
              setRteText={setRteText}
              setImageList={setImageList}
              imageList={imageList}
              placeholder={t("write_your_meeting_mom_here")}
              rteRawJson={rteRawJson}
              rteText={rteText}
              setRteRawJson={setRteRawJson}
              readOnly={momExists}
            />
            {/* <Button
              onClick={() => {
                console.log("Creating Pdf");
                generateMomPdf();
              }}
            >
              {t("download_pdf")}
            </Button> */}
            <div className="zRTEmomMainDivFooter">
              <FormControl className="formControl mSMomMW">
                <Autocomplete
                  noOptionsText={t("please_type_user_name")}
                  multiple={true}
                  variant="outlined"
                  size="small"
                  value={selectedUsers}
                  options={computedList}
                  getOptionLabel={(option) => option.userName}
                  inputValue={autocompleteInputValue}
                  onChange={(e, newValue) => {
                    setSelectedUsers(newValue);
                  }}
                  onInputChange={handleChangeAutoCompleteInputValue}
                  getOptionSelected={(option) => {
                    return option.userId;
                  }}
                  getOptionDisabled={(option) =>
                    selectedUsers.some(
                      (userDto) => userDto.userId === option.userId
                    )
                  }
                  renderOption={(props, option) => {
                    return (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {`${option.userName}`}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("users_with_mom_access")}
                    />
                  )}
                />
              </FormControl>
              {!momExists && (
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    console.log("Creating Pdf");
                    finalizeMOM();
                  }}
                  disabled={loadingSubmitMom}
                >
                  {loadingSubmitMom ? t("please_wait") : t("finalize_mom")}
                </Button>
              )}
              {momExists && (
                <Button
                  className="dfultPrimaryBtn"
                  onClick={async () => {
                    const momId = momDetails.momId;
                    const userIds = selectedUsers.map((user) => user.userId);
                    console.log("momId", momId, userIds);
                    if (!userIds.includes(userDetails.userId)) {
                      userIds.push(userDetails.userId);
                    }

                    const response2 = await updateUsersPermissionMOM(
                      userIds,
                      momId
                    );
                    if (response2.data.returnValue === "1") {
                      getMeetingMomDetails(selectedEvent.eventLink).then(
                        (response) => {
                          console.log("getMeetingMomDetails", response.data);
                          const filteredData =
                            response.data.usersWithAccess.filter(
                              (option) => option.userId !== userDetails.userId
                            );
                          setSelectedUsers(filteredData);
                        }
                      );
                      toast.success(t("users_access_updated_successfully"));
                    }
                  }}
                >
                  {t("update_user_access")}
                </Button>
              )}
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default MeetingSummary;
