import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../css/task.css";
import { useDispatch, useSelector } from "react-redux";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import DocumentTab from "../../../../../common/DocumentTab";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../services/DashboardServices";
import {
  clearTaskModalData,
  moveToPreviousTask,
  pushLinkTask,
  setTaskModalData,
} from "../../../../../redux/reducers/taskTab";
import { Button, IconButton, Tooltip } from "@mui/material";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Audiotrack,
  Cancel,
  DescriptionOutlined,
} from "@mui/icons-material";
import { ArrowLeft, Play, XLg } from "react-bootstrap-icons";
import isAssignedByMe from "../../../taskUtil";
import { Box, Send } from "iconoir-react";
import CloseTask from "../CloseTask";
import { LinkedTaskModule } from "../LinkedTaskModule";
import { v4 as uuidv4 } from "uuid";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import { useTimeProgressor } from "../hooks/useTimerProgressor";
import { PriorityComponent } from "../TaskComponent/PriorityComponent";
import { TaskNameModal } from "../Modals/TaskNameModal";
import { DescriptionComponent } from "../Modals/DescriptionComponent";
import { TaskContractModal } from "../Modals/TaskContractModal";
import { useTranslation } from "react-i18next";

export default function TaskTabCancelRejected({
  data,
  refreshData,
  removeFromStore,
}) {
  const scrollRef = useRef();

  let { t } = useTranslation();

  const dispatch = useDispatch();
  const loginUserDetail = GetLoginUserDetails();
  const { addTab } = useContext(AddTabContext);

  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);

  const [showCloseTask, setShowCloseTask] = useState(false);
  const [cancelTaskRemark, setCancelTaskRemark] = useState("");

  const commentInputRef = useRef();

  const [showAudioModal, setShowAudioModal] = useState(false);
  const audioUrl = useRef();

  const links = useSelector((state) => state.taskList.taskLinks);

  const gotoPreviousTAsk = () => {
    dispatch(moveToPreviousTask());
  };

  // left right tags scroll
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const handleLinkTaskClick = async (task) => {
    // console.log("task id =", taskId);
    const values = await DashboardServices.getTaskById(task.taskId);
    dispatch(pushLinkTask(data));
    dispatch(setTaskModalData({ ...task, ...values.data }));
  };

  // const clearAllModals = () => {
  //   setShowCloseTask(false);
  // };

  const openFileInTab = (file) => {
    addTab({
      key: uuidv4(),
      title: `File_${file.fileDisplayName}`,
      content: <DocumentTab file={file} />,
      isDraggable: true,
    });
  };
  const openTemplateInTab = (file) => {
    addTab({
      key: uuidv4(),
      title: `Temp_${file.fileDisplayName}`,
      content: <DocumentTab file={file} />,
      isDraggable: true,
    });
  };

  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const [fileDivWidth, setFileDivWidth] = useState(0);

  const sendComment = (e) => {
    if (!data) {
      toast.error(t("something_went_wrong"));
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          console.log("out response data= ", response);
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];
            dispatch(setTaskModalData(tempData));
            commentInputRef.current.value = "";

            const timeoutValue = setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
              clearTimeout(timeoutValue);
            }, 500);
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      );
    }
  };

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const getFormattedDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDateString = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(dateObject);

    return formattedDateString;
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              <div className="taskVDContBtn">
                <Button
                  variant="contained"
                  startIcon={<Cancel />}
                  onClick={(e) => {
                    // clearAllModals();
                    setShowCloseTask(true);
                  }}
                >
                  {t("cancel_task")}
                </Button>
              </div>
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    dispatch(clearTaskModalData());
                  }}
                >
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {links && links.length > 0 && (
                  <div className="linktaskBreadCrumb">
                    <IconButton onClick={gotoPreviousTAsk}>
                      <ArrowLeft />
                    </IconButton>
                    <div className="prentTaskNM">
                      {links &&
                        links.length > 0 &&
                        links[links.length - 1].taskName}
                    </div>
                  </div>
                )}
                {/* <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data && data.taskName}</div>
                </div>

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Description</div>
                  <div className="tskElemntInfo">
                    {data && data.taskDescription}
                  </div>
                </div> */}

                <TaskNameModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <DescriptionComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="tskElemntInfo">
                        <IconButton
                          className="elemntMrFileBtn"
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>{t("play_audio_message")}</span>
                      </div>
                    </div>
                  </div>
                )}
                <TaskContractModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {/* {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Contract</div>
                    <div className="taskContractNm">
                      {data && data.contractName}
                    </div>
                  </div>
                )} */}

                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Status</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // ps50 ps80 ps100
                          className="progress-done ps50"
                          style={{ width: `${progressWidth}%`, opacity: 1 }}
                        >
                          <span className="pwCount">{progressWidth}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Timeline</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            data && dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* Templates start */}

                {data && data.templates && data.templates.length > 0 && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("template")}</div>

                    <div class="elementFileListedGrp">
                      <div className="horaizonScroll">
                        <IconButton
                          className="goPrevousBtn"
                          onClick={() => {
                            handleHorizantalScroll(
                              elementRef.current,
                              25,
                              200,
                              -10
                            );
                          }}
                          disabled={arrowDisable}
                        >
                          <ArrowBackIosNew />
                        </IconButton>
                        {/* <div> */}
                        <div class="elemntFilsgrp" ref={elementRef}>
                          {data &&
                            data.templates.map((template) => (
                              <div className="elemntFile">
                                <Tooltip
                                  title={template.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {template.fileDisplayName}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                        {/* </div> */}
                        {tempDivWidth > 270 && (
                          <IconButton
                            className="goNextBtn"
                            onClick={() => {
                              handleHorizantalScroll(
                                elementRef.current,
                                25,
                                200,
                                10
                              );
                            }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        )}
                      </div>
                      {/* <div class="elmntAddMrFiles">
                      <Button className="elemntMrFileBtn" variant="outlined">
                        <Plus />
                      </Button>
                    </div> */}
                    </div>
                  </div>
                )}
                {/* Templates end */}

                {/* files start */}

                {data && data.documents && data.documents.length > 0 && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("files")}</div>

                    <div class="elementFileListedGrp">
                      <div className="horaizonScroll">
                        <IconButton
                          className="goPrevousBtn"
                          onClick={() => {
                            filehandleHorizantalScroll(
                              fileElementRef.current,
                              25,
                              200,
                              -10
                            );
                          }}
                          disabled={filearrowDisable}
                        >
                          <ArrowBackIosNew />
                        </IconButton>

                        <div class="elemntFilsgrp" ref={fileElementRef}>
                          {data &&
                            data.documents &&
                            data.documents.map((doc) => (
                              <div
                                className="elemntFile"
                                onClick={openFileInTab(doc)}
                              >
                                <Tooltip
                                  title={doc.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {doc.fileDisplayName}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                        {fileDivWidth > 370 && (
                          <IconButton
                            className="goNextBtn"
                            onClick={() => {
                              filehandleHorizantalScroll(
                                fileElementRef.current,
                                25,
                                200,
                                10
                              );
                            }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* files end */}

                {/* {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <IconButton
                      className="elemntMrFileBtn"
                      variant="outlined"
                      onClick={(e) => {
                        DashboardServices.generatePreSignedUrlS3Object(
                          data.audioNoteFileName,
                          loginUserDetail.orgId
                        ).then((response) => {
                          audioUrl.current = response.data;
                          setShowAudioModal(true);
                        });
                      }}
                    >
                      <Audiotrack />
                    </IconButton>
                  </div>
                )} */}

                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Priority</div>
                  <div className="tskPrioArea">
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class="priStat">
                        {data.priority === 0
                          ? "Regular"
                          : data.priority === 1
                          ? "Important"
                          : "Critical"}
                      </div>
                    </div>
                  </div>
                </div> */}

                <PriorityComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("assigned_by")}</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? "Me"
                        : data.assignByUserName}
                    </div>
                  </div>
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("rejected_by")}</div>
                    <div className="tskElemntInfo">
                      {data &&
                      isAssignedByMe(
                        data.approveRejectConcernedPostponedByUserId
                      )
                        ? t("me")
                        : data.approveRejectConcernedPostponedByUserName}
                    </div>
                  </div>
                </div>

                <LinkedTaskModule
                  data={data}
                  handleLinkTaskClick={(data) => handleLinkTaskClick(data)}
                />

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("last_updated")}</div>
                  <div className="tskElemntInfo">
                    {data &&
                      data.comments &&
                      data.comments.length &&
                      `${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("rejection_reason")}</div>
                  <div className="tskElemntInfo">
                    {data && data.rejectionReason}
                  </div>
                </div>

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder={t("update_comment_here")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed");
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>

        {showCloseTask && (
          <CloseTask
            taskId={data.taskId}
            refreshData={refreshData}
            handleClose={(e) => setShowCloseTask(false)}
            removeFromStore={removeFromStore}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("ok")}
                </Button>
                {/* <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  Ok
                </Button> */}
              </div>
            </div>
          </Box>
        )}
      </div>
    </>
  );
}
