import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";
import { Download, XLg } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import TaskService from "../../../../../services/TaskService";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { toast } from "react-toastify";
import { CustomModal } from "../Modals/CustomModal";
import DashboardServices from "../../../../../services/DashboardServices";
import dayjs from "dayjs";
import { downloadFileFromUrl } from "../../../../../utils/FileUtils";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export const SortListResume = ({
  taskId,
  formDataTrans,
  taskNature,
  formData,
  handleClose,
}) => {
  const fullScreenStyle = {
    width: "100%",
  };
  const { t, i18n } = useTranslation();
  const initialState = { isExpanded: false };
  const reducer = (state, action) => {
    switch (action.type) {
      case "expandOrShrinkWindow":
        return { ...state, isExpanded: !state.isExpanded };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const loginUserDetail = GetLoginUserDetails();
  const [prospectiveEmployeeList, setProspectiveEmpList] = useState([]);
  const [showScheduleInterview, setShowScheduleInterview] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [interviewTime, setInterviewTime] = useState(new Date());
  const [userList, setUserList] = useState([]);
  const [selectedInterviewer, setSelectdInterviewer] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showSendForSortListModal, setShowSendForSortListModal] =
    useState(false);
  const [acceptedData, setAcceptedData] = useState([]);
  const [userPreferedTime, setUserPreferedTime] = useState(new Date());
  const [interviewerPreferedTime, setInterviewerPreferedTime] = useState(
    new Date()
  );
  const [showAccept, setShowAccept] = useState();

  const fetchPerspectiveEmployees = (expenseId, taskId) => {
    TaskService.getProspectiveEmployeesByStatus(
      expenseId,
      loginUserDetail.orgId,
      "P"
    ).then((response) => {
      if (response.data) {
        const temp = response.data.map((ppl) => ({
          ...ppl,
          preferableInterviewTime: new Date(Date.now()),
        }));
        setProspectiveEmpList(temp);
      }
    });
  };

  useEffect(() => {
    fetchPerspectiveEmployees(formData.expenseId, taskId);
    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
    if (formData) {
      console.log("form data =", formData);
    }
  }, []);

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={state.isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          <div className="flotHeaderPanel">
            <div class="flotHedCon">{t("short_man_power")}</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    state.isExpanded
                      ? t("exitFullScreen")
                      : t("enterFullScreen")
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch({ type: "expandOrShrinkWindow" });
                    }}
                  >
                    {state.isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                    {/* <Expand /> */}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flotContain">
            <div className="elementFormContainer previewSpace ">
              <div className="indentFormtable">
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      {/* <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Contact No</th>
                        <th>Email Id</th>
                        <th>Current Ctc</th>
                        <th>Expected Ctc</th>
                        <th>Experience In Month</th>
                        <th>View Resume</th>
                        <th>Actions</th>
                      </tr> */}
                      <tr>
                        <th>{t("name")}</th>
                        <th>{t("status")}</th>
                        <th>{t("contact_no")}</th>
                        <th>{t("email_id")}</th>
                        <th>{t("current_ctc")}</th>
                        <th>{t("expected_ctc")}</th>
                        <th>{t("experience_in_month")}</th>
                        <th>{t("view_resume")}</th>
                        <th>{t("actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prospectiveEmployeeList &&
                        prospectiveEmployeeList.map((item, index) => (
                          <>
                            <tr>
                              <td>
                                <div className="textOnly">
                                  {item.firstName +
                                    " " +
                                    item.middleName +
                                    " " +
                                    item.lastName}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">{item.status}</div>
                              </td>
                              <td>
                                <div className="textOnly">{item.contactNo}</div>
                              </td>
                              <td>
                                <div className="textOnly">{item.emailId}</div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.currentCtc}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.expectedCtc}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.experienceInMonth}
                                </div>
                              </td>

                              <td>
                                <IconButton
                                  onClick={() => {
                                    downloadFileFromUrl(
                                      item.resumeFileLinkData,
                                      item.resumeFileName
                                    );
                                  }}
                                >
                                  <Download />
                                </IconButton>
                              </td>
                              <td>
                                {/* {item.status === "P" && (
                                  <div>
                                    <Button
                                      onClick={(e) => {
                                        const reqDto = {
                                          prospectiveEmployeeId:
                                            item.prospectiveEmployeeId,
                                          length: 0,
                                          startTime: "2024-04-29T02:32:35.486Z",
                                          endTime: "2024-04-29T02:32:35.486Z",
                                          interviewMode: item.interveiwMode,
                                          interviewers: ["string"],
                                        };
                                        setSelectedCandidate(item);
                                        setShowScheduleInterview(true);
                                      }}
                                    >
                                      Schedule Interview
                                    </Button>
                                  </div>
                                )} */}

                                {item.status === "N" && (
                                  <div>
                                    <Button
                                      onClick={(e) => {
                                        setSelectedCandidate(item);
                                        setShowSendForSortListModal(true);
                                      }}
                                    >
                                      {t("send_for_shortlist")}
                                    </Button>
                                    <Button
                                      onClick={(e) => {
                                        const reqDto = [
                                          {
                                            prospectiveEmployeeId:
                                              item.prospectiveEmployeeId,
                                            interviewers: ["AHL000000001069"],
                                            sortListed: "C",
                                            preferableInterviewTime:
                                              item.preferableInterviewTime.toISOString(),
                                          },
                                        ];
                                        console.log("reqDto=", reqDto);
                                        // return;
                                        TaskService.filterResume(
                                          taskId,
                                          loginUserDetail.userId,
                                          reqDto
                                        ).then((response) => {
                                          if (
                                            response.data.returnValue === "1"
                                          ) {
                                            toast.success(
                                              response.data.message
                                            );
                                            fetchPerspectiveEmployees(
                                              formData.expenseId,
                                              taskId
                                            );
                                          } else {
                                            toast.error(response.data.message);
                                          }
                                        });
                                      }}
                                    >
                                      {t("cancel")}
                                    </Button>
                                  </div>
                                )}

                                {
                                  // item.status === "A" ||
                                  !item.updatedStatus &&
                                    item.status === "P" && (
                                      <>
                                        <div>
                                          <Button
                                            onClick={(e) => {
                                              setSelectedCandidate(item);
                                              setUserPreferedTime(
                                                new Date(
                                                  item.candidatePreferableTiming ||
                                                    item.preferableInterviewTime
                                                )
                                              );
                                              // setShowScheduleInterview(true);
                                              setShowAccept(true);
                                            }}
                                          >
                                            {t("accept")}
                                          </Button>
                                        </div>

                                        <div>
                                          <Button
                                            onClick={(e) => {
                                              // setSelectedCandidate(item);
                                              // setShowScheduleInterview(true);

                                              const reqdto = {
                                                prospectiveEmployeeId:
                                                  item.prospectiveEmployeeId,
                                                interviewers: [],
                                                sortListed: "R",
                                                preferableInterviewTime: "",
                                              };
                                              setAcceptedData((prev) => {
                                                return [...prev, reqdto];
                                              });
                                              const temp = [
                                                ...prospectiveEmployeeList,
                                              ];
                                              const indx =
                                                prospectiveEmployeeList.findIndex(
                                                  (emp) =>
                                                    emp.prospectiveEmployeeId ===
                                                    item.prospectiveEmployeeId
                                                );
                                              if (indx >= 0) {
                                                temp[indx].updatedStatus = "R";
                                                setProspectiveEmpList(temp);
                                              }
                                            }}
                                          >
                                            {t("reject")}
                                          </Button>
                                        </div>
                                      </>
                                    )
                                }
                                {item.updatedStatus &&
                                  item.updatedStatus === "A" && (
                                    <div>{t("accepted")}</div>
                                  )}
                                {item.updatedStatus &&
                                  item.updatedStatus === "R" && (
                                    <div>{t("rejected")}</div>
                                  )}
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  const reqDto = acceptedData;
                  console.log("reqDto = ", acceptedData);
                  // return;

                  TaskService.filterResume(
                    taskId,
                    loginUserDetail.userId,
                    reqDto
                  ).then((response) => {
                    if (response.data.returnValue === "1") {
                      toast.success(response.data.message);
                      fetchPerspectiveEmployees(formData.expenseId, taskId);
                    } else {
                      toast.error(response.data.message);
                    }
                  });
                }}
              >
                {t("submit")}
              </Button>

              {/* <Button className="dfultDarkSecondaryBtn">Edit</Button> */}
            </div>
          </div>
        </div>
      </div>

      {showAccept && (
        <CustomModal
          modalTitle={t("acceptCandidate")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowAccept(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="setStartTime" className="setCompletionDate">
                    {t("userPreferableTime")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="setStartTime"
                    className="formDatePicker"
                    minDate={new Date()}
                    disabled
                    selected={userPreferedTime}
                    // onChange={(date) => {
                    //   setStartTime(date);
                    //   let temp = dayjs(date);
                    //   temp = temp.add(30, "minute");

                    //   setEndTime(temp.toDate());
                    // }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="setEndTime" className="setCompletionDate">
                    {t("interviewerPreferedTime")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="setEndTime"
                    className="formDatePicker"
                    minDate={new Date()}
                    selected={interviewerPreferedTime}
                    onChange={(date) => {
                      setInterviewerPreferedTime(date);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  multiple
                  noOptionsText={t("typeInterviewerName")}
                  multipleclassName="modelformAutocompleteField"
                  variant="outlined"
                  value={selectedInterviewer}
                  options={userList}
                  getOptionLabel={(option) => option.userName}
                  onChange={(e, data) => setSelectdInterviewer(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField"
                      placeholder={t("selectInterviewer")}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={(e) => {
                setShowAccept(false);
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                let isValid = true;
                if (!selectedCandidate) {
                  toast.error(t("errorMessageSomethingWrong"));
                  isValid = false;
                }
                // if (userPreferedTime === null) {
                //   toast.error("Please Select Start Time");
                //   isValid = false;
                // }

                if (interviewerPreferedTime === null) {
                  toast.error(t("selectInterviewerTime"));
                  isValid = false;
                }

                if (selectedInterviewer.length === 0) {
                  toast.error(t("selectInterviewers"));
                  isValid = false;
                }

                if (!isValid) {
                  return;
                }

                const reqDto = {
                  prospectiveEmployeeId:
                    selectedCandidate.prospectiveEmployeeId,
                  interviewers: selectedInterviewer.map((intv) => intv.userId),

                  sortListed: "A",
                  preferableInterviewTime:
                    interviewerPreferedTime.toISOString(),
                };
                console.log("reqDto =", reqDto);

                const temp = [...prospectiveEmployeeList];
                const indx = prospectiveEmployeeList.findIndex(
                  (emp) =>
                    emp.prospectiveEmployeeId ===
                    selectedCandidate.prospectiveEmployeeId
                );
                if (indx >= 0) {
                  temp[indx].updatedStatus = "A";
                  setProspectiveEmpList(temp);
                }

                setAcceptedData((prev) => {
                  return [...prev, reqDto];
                });
                setShowAccept(false);
              }}
            >
              {t("add")}
            </Button>
          </div>
        </CustomModal>
      )}

      {showScheduleInterview && (
        <CustomModal
          modalTitle={t("schedule_interview")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowScheduleInterview(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="setStartTime" className="setCompletionDate">
                    {t("start_time")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="setStartTime"
                    className="formDatePicker"
                    minDate={new Date()}
                    selected={startTime}
                    onChange={(date) => {
                      setStartTime(date);
                      let temp = dayjs(date);
                      temp = temp.add(30, "minute");

                      setEndTime(temp.toDate());
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="setEndTime" className="setCompletionDate">
                    {t("end_time")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="setEndTime"
                    className="formDatePicker"
                    minDate={new Date()}
                    selected={endTime}
                    onChange={(date) => {
                      setEndTime(date);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  multiple
                  noOptionsText={t("please_type_recipient_name")}
                  multipleclassName="modelformAutocompleteField"
                  variant="outlined"
                  value={selectedInterviewer}
                  options={userList}
                  getOptionLabel={(option) => option.userName}
                  onChange={(e, data) => setSelectdInterviewer(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={(e) => {
                setShowScheduleInterview(false);
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                let isValid = true;
                if (!selectedCandidate) {
                  toast.error(t("errorMessageSomethingWrong"));
                  isValid = false;
                }
                if (startTime === null) {
                  toast.error(t("please_select_start_time"));
                  isValid = false;
                }

                if (endTime === null) {
                  toast.error(t("please_select_end_time"));
                  isValid = false;
                }

                if (selectedInterviewer.length === 0) {
                  toast.error(t("please_select_interviewers"));
                  isValid = false;
                }

                if (!isValid) {
                  return;
                }

                const reqDto = {
                  prospectiveEmployeeId:
                    selectedCandidate.prospectiveEmployeeId,
                  interviewers: selectedInterviewer.map((intv) => intv.userId),
                  length: 0,
                  startTime: startTime.toISOString(),
                  endTime: endTime.toISOString(),
                  interviewMode: selectedCandidate.interveiwMode || "physical",
                };
                console.log("reqDto =", reqDto);

                const temp = [...prospectiveEmployeeList];
                const indx = prospectiveEmployeeList.findIndex(
                  (emp) =>
                    emp.prospectiveEmployeeId ===
                    selectedCandidate.prospectiveEmployeeId
                );
                if (indx >= 0) {
                  temp[indx].updatedStatus = "A";
                  setProspectiveEmpList(temp);
                }

                setAcceptedData((prev) => {
                  return [...prev, reqDto];
                });
                // return;

                // TaskService.scheduleInterview(
                //   taskId,
                //   loginUserDetail.orgId,
                //   loginUserDetail.userId,
                //   reqDto
                // ).then((response) => {
                //   if (response.data.returnValue === "1") {
                //     toast.success(response.data.message);
                //     fetchPerspectiveEmployees(formData.expenseId, taskId);
                //     setStartTime(null);
                //     setEndTime(null);
                //     setSelectdInterviewer([]);
                //     setShowScheduleInterview(false);
                //   } else {
                //     toast.error(response.data.message);
                //   }
                // });
              }}
            >
              {t("add")}
            </Button>
          </div>
        </CustomModal>
      )}

      {showSendForSortListModal && (
        <CustomModal
          modalTitle={t("schedule_interview")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowSendForSortListModal(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="setStartTime" className="setCompletionDate">
                    {t("preferable_interview_time")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="setStartTime"
                    className="formDatePicker"
                    minDate={new Date()}
                    selected={interviewTime}
                    onChange={(date) => {
                      setInterviewTime(date);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  multiple
                  noOptionsText={t("please_type_recipient_name")}
                  multipleclassName="modelformAutocompleteField"
                  variant="outlined"
                  value={selectedInterviewer}
                  options={userList}
                  getOptionLabel={(option) => option.userName}
                  onChange={(e, data) => setSelectdInterviewer(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                let isValid = true;
                if (!selectedCandidate) {
                  toast.error(t("errorMessageSomethingWrong"));
                  isValid = false;
                }
                if (interviewTime === null) {
                  toast.error(t("please_select_start_time"));
                  isValid = false;
                }

                if (selectedInterviewer.length === 0) {
                  toast.error(t("please_select_interviewers"));
                  isValid = false;
                }

                if (!isValid) {
                  return;
                }

                const reqDto = [
                  {
                    prospectiveEmployeeId:
                      selectedCandidate.prospectiveEmployeeId,
                    interviewers: selectedInterviewer.map(
                      (intv) => intv.userId
                    ),
                    sortListed: "A",
                    preferableInterviewTime: interviewTime.toISOString(),
                  },
                ];

                console.log("reqDto =", reqDto);
                // return;
                TaskService.filterResume(
                  taskId,
                  loginUserDetail.userId,
                  reqDto
                ).then((response) => {
                  if (response.data.returnValue === "1") {
                    toast.success(response.data.message);
                    setShowSendForSortListModal(false);
                    setSelectdInterviewer([]);
                    setSelectedCandidate(null);
                    setInterviewTime(null);
                    fetchPerspectiveEmployees(formData.expenseId, taskId);
                  } else {
                    toast.error(response.data.message);
                  }
                });
              }}
            >
              {t("submit")}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};
