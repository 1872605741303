import React, { Suspense, lazy, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LandingPage from "../features/landingPage/view/LandingPage";
import { GetLoginUserDetails } from "../utils/Utils";
import { getActiveOtpServices } from "../services/AdminService";
import SecureIndexedDB from "../utils/IndexedDB";
import { useSelector } from "react-redux";
const HomePage = lazy(() => import("../features/home/view/HomePage"));
// import HomePage from "../features/home/view/HomePage";

const RouteGuard = ({ url }) => {
  const themeMode = useSelector((state) => state.theme.mode);

  useEffect(() => {
    document.body.className = themeMode;
  }, [themeMode]);

  const userDetails = GetLoginUserDetails();
  if (null == userDetails) {
    return <Navigate to="/login" />;
  } else {
    if (userDetails?.userType !== "GUEST") {
      return <Navigate to={`/${url}`} />;
    }
  }
};

const AppRoute = () => {
  let host = window.location.host.replace("www.", "");
  const domainArray = host.split(".");

  if (host.includes("ngrok")) {
    return (
      <Suspense fallback="Loading.....">
        {/* <HomePage />; */}
        <LandingPage />
      </Suspense>
    );
  } else if (domainArray.length === 3) {
    if (window.caches) {
      window.caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          window.caches.delete(cacheName);
        });
      });
    }
    window.location.replace(
      window.location.protocol + "//" + window.location.host
    );
  } else {
    return (
      <Suspense fallback="Loading.....">
        {/* <HomePage />; */}
        <LandingPage />
      </Suspense>
    );
  }
};

export { RouteGuard, AppRoute };
