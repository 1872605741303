import {
  CloseFullscreen,
  Edit,
  OpenInFull,
  StarBorderRounded,
  StarRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Eye, XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { getPositionsOfOrganization } from "../../../../../services/AdminService";
import TaskService from "../../../../../services/TaskService";
import {
  GetLoginUserDetails,
  getRatingList,
  renderRatingMessageIcon,
} from "../../../../../utils/Utils";
import { checkOtp } from "../../../../../utils/OtpServiceUtils";
import { useTranslation } from "react-i18next";

export default function PmsManagerReviewForm({
  data,
  handleClose,
  refreshData,
  removeFromStore,
}) {
  const loginUser = GetLoginUserDetails();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);

  const [positionsList, setPositionsList] = useState([]);
  const [employeePmsList, setEmployeePmsList] = useState([]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);
  const [employeeEvaluationList, setEmployeeEvaluationList] = useState(null);

  const [refreshCount, setRefreshCount] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPositionsOfOrganization(loginUser.orgId);
      if (res && res.data) setPositionsList(res.data);
    };
    if (data) {
      fetchData();
      _fetchData();
    }
  }, [data]);

  // const _fetchData = async () => {
  //   const { formDataTransId } = data;
  //   const res1 = await TaskService.getPmsEmployeesForReview(
  //     formDataTransId,
  //     loginUser.userId
  //   );
  //   if (res1 && res1.data) setEmployeePmsList(res1.data);
  // };

  const _fetchData = async () => {
    try {
      const { formDataTransId } = data;

      const { otp, requestId } = await checkOtp(
        "/pms/getPmsEmployeesForReview"
      );
      console.log("getPmsEmployeesForReviewOTP ------->>>>", otp, requestId);

      const reqDto = {
        requestId: requestId,
        otp: otp,
        orgId: loginUser.orgId,
        pmsId: formDataTransId,
      };

      const res1 = await TaskService.getPmsEmployeesForReview(
        loginUser.userId,
        reqDto
      );

      if (res1 && res1.data) {
        setEmployeePmsList(res1.data);
      }
    } catch (error) {
      console.error("Error fetching PMS employees for review:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await TaskService.getEmployeeEvaluation(
        selectedEmployeeData.pmsEmpId
      );
      if (res && res.data) setEmployeeEvaluationList(res.data);
    };

    if (selectedEmployeeData) fetchData();
  }, [selectedEmployeeData]);

  const handleSaveUserDetails = async () => {
    let isValid = true;
    for (let i = 0; i < employeeEvaluationList.length; i++) {
      const item = employeeEvaluationList[i];
      if (
        (item.metricsType === "rating" || item.metricsType === "both") &&
        (item.reviewerRating === null || item.reviewerRating === 0)
      ) {
        toast.error(`Please select rating for ${item.metrics}`);
        isValid = false;
      }
      if (
        (item.metricsType === "text" || item.metricsType === "both") &&
        (item.reviewerEvaluation === null || item.reviewerEvaluation === "")
      ) {
        toast.error(`Please enter evaluation for ${item.metrics}`);
        isValid = false;
      }
    }

    if (
      selectedEmployeeData.reviewerOverallRating == null ||
      selectedEmployeeData.reviewerOverallRating <= 0
    ) {
      toast.error("Please enter overall rating");
      isValid = false;
    }

    if (!isValid) return;

    const reqDto = {
      pmsEmpId: selectedEmployeeData.pmsEmpId,
      reviewerMetrics: employeeEvaluationList,
      reviewerRecommendPromotion:
        selectedEmployeeData.reviewerRecommendPromotion,
      reviewerRecommendPtositionId:
        selectedEmployeeData.reviewerRecommendPtositionId,
      reviewerOverallRating: selectedEmployeeData.reviewerOverallRating,
    };
    setSubmitting(true);
    const res = await TaskService.saveReviewerEvaluation(
      loginUser.userId,
      reqDto
    );
    setSubmitting(false);
    if (res && res.data && res.data > 0) {
      toast.success("Data saved successfully");
      await _fetchData();
      setSelectedEmployeeData(null);
      setEmployeeEvaluationList(null);
    } else {
      toast.error("Something want wrong. Please try again.");
    }
  };

  const handleSubmit = async () => {
    if (!employeePmsList && employeePmsList.length == 0) return;
    for (let i = 0; i < employeePmsList.length; i++) {
      if (employeePmsList[i].pmsFlag !== "R") {
        toast(
          `Please submit the details of ${employeePmsList[i].employeeName}`
        );
        return;
      }
    }
    setSubmitting(true);
    const res = await TaskService.submitReviewerEvaluation(
      loginUser.orgId,
      loginUser.userId,
      data.formDataTransId,
      data.taskId
    );
    setSubmitting(false);
    if (res && res.data && res.data > 0) {
      toast.success("Data submitted successfully");
      removeFromStore();
      refreshData();
      handleClose();
    } else {
      toast.error("Something want wrong. Please try again.");
    }
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={{
          width: isExpanded ? "100%" : "",
        }}
      >
        <div className="flottingContainer">
          {/* header start */}
          <div className="flotHeaderPanel">
            <div class="flotHedCon">
              {data && data.taskName ? data.taskName : ""}
            </div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    isExpanded ? t("exitFullScreen") : t("enterFullScreen")
                  }
                >
                  <IconButton onClick={(e) => setIsExpanded((r) => !r)}>
                    {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          {/* header end */}

          {/* body start */}
          <div className="flotContain">
            <div className="elementFormContainer previewSpace ">
              <div className="indentFormtable">
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      {/* <tr>
                        <th>Employee Name</th>
                        <th>Positions</th>
                        <th>Unit name</th>
                        <th>Current CTC</th>
                        <th>View Details</th>
                      </tr> */}
                      <tr>
                        <th>{t("employeeName")}</th>
                        <th>{t("positions")}</th>
                        <th>{t("unitName")}</th>
                        <th>{t("currentCTC")}</th>
                        <th>{t("viewDetails")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeePmsList &&
                        employeePmsList.map((item) => {
                          return (
                            <tr key={item.pmsEmpId}>
                              <td>
                                <div className="textOnly">
                                  {item.employeeName}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.positions &&
                                    item.positions.map((pos) => (
                                      <div className="textOnly">
                                        {pos.positionName}({pos.effectiveDate})
                                      </div>
                                    ))}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">{item.unitName}</div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.currentCtc}
                                  {item.currency}
                                </div>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => setSelectedEmployeeData(item)}
                                >
                                  {item.pmsFlag === "R" ? <Eye /> : <Edit />}
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* body end */}

          {/* footer start */}
          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  if (!submitting) handleSubmit();
                }}
              >
                {!submitting ? t("submit") : t("submitting")}
              </Button>
              <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
                {t("close")}
              </Button>
            </div>
          </div>
          {/* footer end */}
        </div>
      </div>

      {selectedEmployeeData && employeeEvaluationList && (
        <div className="bgOfset">
          <div className="bgOfsetOverlay"></div>
          <Box className="ModelBox pmsModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="employeeDtlHeader">
                <ul className="empdtlUL">
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">{t("employeeName")}:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.employeeName}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">{t("positions")}:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.positions &&
                        selectedEmployeeData.positions.map((pos) => {
                          const str = `${pos.positionName}(${pos.effectiveDate}), `;
                          return str.substring(0, str.length - 2);
                        })}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">{t("unitName")}:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.unitName}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">{t("currentCTC")}:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.currentCtc}
                      {selectedEmployeeData.currency}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">
                      {t("last_increment_date")}:
                    </span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.lastIncrementDate}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">{t("reviewerName")}:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.reviewerName}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">
                      {t("absentPercentage")}:
                    </span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.absentPercentage}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">
                      {t("employee_tenure")}:
                    </span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.tenure}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="elementFormContainer">
                <div className="indentFormtable">
                  <div className="modResptable mxH-55vh">
                    <table>
                      <thead>
                        {/* <tr>
                          <th>Metrics</th>
                          <th className="width100">Self Rating</th>
                          <th className="width320">Reviewer Rating</th>
                          <th>Self Evaluation</th>
                          <th>Reviewer Evaluation</th>
                        </tr> */}
                        <tr>
                          <th>{t("metrics")}</th>
                          <th className="width100">{t("selfRating")}</th>
                          <th className="width320">{t("reviewerRating")}</th>
                          <th>{t("selfEvaluation")}</th>
                          <th style={{ maxWidth: "40%" }}>
                            {t("reviewerEvaluation")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeEvaluationList.map((item) => (
                          <tr key={item.pmsEmpMetricsId}>
                            <td>
                              <div className="textOnly">{item.metrics}</div>
                            </td>
                            <td>
                              <div className="textOnly">
                                {(item.metricsType === "rating" ||
                                  item.metricsType === "both") &&
                                  item.selfRating}
                              </div>
                            </td>
                            <td>
                              {(item.metricsType === "rating" ||
                                item.metricsType === "both") &&
                                refreshCount >= 0 && (
                                  <div className="ratingWithTxt">
                                    <div className="star-rating">
                                      {getRatingList(item.maxRating).map(
                                        (star) => (
                                          <span
                                            key={star}
                                            className={
                                              star <= item.reviewerRating
                                                ? "star filled"
                                                : "star"
                                            }
                                            onClick={() => {
                                              item.reviewerRating = star;
                                              setRefreshCount((r) => r + 1);
                                            }}
                                          >
                                            {star <= item.reviewerRating ? (
                                              <StarRounded />
                                            ) : (
                                              <StarBorderRounded />
                                            )}
                                          </span>
                                        )
                                      )}
                                    </div>
                                    {renderRatingMessageIcon(
                                      item.reviewerRating
                                    )}
                                  </div>
                                )}
                            </td>
                            <td className="widthPmsManagerTableCol">
                              <div className="textOnly">
                                {(item.metricsType === "text" ||
                                  item.metricsType === "both") &&
                                  item.selfEvaluation}
                              </div>
                            </td>
                            <td className="widthPmsManagerTableCol">
                              <div className="textOnly">
                                {(item.metricsType === "text" ||
                                  item.metricsType === "both") &&
                                  refreshCount >= 0 && (
                                    <div className="formElement">
                                      <FormControl className="formControl">
                                        <TextField
                                          required
                                          className="modelTextFild"
                                          // className="descptionTextFild"
                                          label={t("evaluation")}
                                          variant="outlined"
                                          multiline
                                          // rows={1}
                                          minRows={3}
                                          // maxRows={10}
                                          value={item.reviewerEvaluation}
                                          onChange={(e) => {
                                            item.reviewerEvaluation =
                                              e.target.value;
                                            setRefreshCount((r) => r + 1);
                                          }}
                                        />
                                      </FormControl>
                                    </div>
                                  )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          {refreshCount >= 0 && (
                            <td colSpan={3}>
                              <div className="formElementGroup">
                                <div className="formElement width250">
                                  <FormControl className="formControl">
                                    <FormGroup className="formRadioGroup">
                                      <FormControlLabel
                                        className="formRadioField"
                                        control={
                                          <Checkbox
                                            defaultChecked={
                                              selectedEmployeeData.reviewerRecommendPromotion ===
                                              "Y"
                                            }
                                            value={
                                              selectedEmployeeData.reviewerRecommendPromotion ===
                                              "Y"
                                            }
                                            onChange={(e) => {
                                              selectedEmployeeData.reviewerRecommendPromotion =
                                                selectedEmployeeData.reviewerRecommendPromotion ===
                                                "Y"
                                                  ? "N"
                                                  : "Y";
                                              setRefreshCount((r) => r + 1);
                                            }}
                                          />
                                        }
                                        label={t("recommendForPromotion")}
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </div>
                                {selectedEmployeeData.reviewerRecommendPromotion ===
                                  "Y" && (
                                  <div className="formElement width250">
                                    <FormControl className="formControl">
                                      <InputLabel id="compTime">
                                        {t("selectPosition")}
                                      </InputLabel>
                                      <Select
                                        className="modelSelectFild"
                                        variant="outlined"
                                        labelId="compTime"
                                        id="compTime-select"
                                        value={
                                          selectedEmployeeData.reviewerRecommendPtositionId
                                        }
                                        label={t("selectPosition")}
                                        onChange={(e) => {
                                          selectedEmployeeData.reviewerRecommendPtositionId =
                                            e.target.value;
                                          setRefreshCount((r) => r + 1);
                                        }}
                                      >
                                        {positionsList &&
                                          positionsList.map((pos) => (
                                            <MenuItem
                                              value={pos.positionId}
                                              disabled={
                                                selectedEmployeeData.reviewerRecommendPromotion !==
                                                "Y"
                                              }
                                            >
                                              {pos.position}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                          <td colSpan={2}>
                            <div className="formElementGroup">
                              <div className="formElement width250">
                                <div className="ratingWithTxt">
                                  <div className="textOnly">
                                    {t("overall_rating") + ": "}
                                  </div>
                                  <div className="star-rating">
                                    {getRatingList(
                                      employeeEvaluationList[0]?.maxRating
                                    ).map((star) => (
                                      <span
                                        key={star}
                                        className={
                                          star <=
                                          selectedEmployeeData.reviewerOverallRating
                                            ? "star filled"
                                            : "star"
                                        }
                                        onClick={() => {
                                          selectedEmployeeData.reviewerOverallRating =
                                            star;
                                          setRefreshCount((r) => r + 1);
                                        }}
                                      >
                                        {star <=
                                        selectedEmployeeData.reviewerOverallRating ? (
                                          <StarRounded />
                                        ) : (
                                          <StarBorderRounded />
                                        )}
                                      </span>
                                    ))}
                                  </div>
                                  {renderRatingMessageIcon(
                                    selectedEmployeeData.reviewerOverallRating
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    if (!submitting) handleSaveUserDetails();
                  }}
                >
                  {!submitting ? t("save") : <CircularProgress />}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setSelectedEmployeeData(null);
                    setEmployeeEvaluationList(null);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        </div>
      )}
    </>
  );
}
