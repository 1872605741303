import axios from "axios";
import authHeader from "../config/auth-header-config";
import http from "../config/http-common";
import { socketEndPoint } from "../constants/url_provider";

class DashboardServices {
  getDashboardData(userId) {
    return http.get(`dashboard/getDashboardData/${userId}`, {
      headers: authHeader(),
    });
  }

  getCCDataOfApprovalOfExpenditure(userId, taskDetailId, taskNature, indentId) {
    return http.get(
      `task/getCCDataOfApprovalOfExpenditure/${userId}/${taskDetailId}/${taskNature}/${indentId}`,
      {
        headers: authHeader(),
      }
    );
  }

  deleteDashboardDataById(body) {
    return http.put("dashboard/deleteDashboardDataById", body);
  }

  getContracts(orgId) {
    return http.get(`contract/getContracts/${orgId}`, {
      headers: authHeader(),
    });
  }

  getActivityList(orgId) {
    return http.get(`task/getActivityList/${orgId}`, {
      headers: authHeader(),
    });
  }

  getTemplatesOfSubActivity(taskMasterId, taskDetailId) {
    return http.get(
      `task/getTemplatesOfSubActivity/${taskMasterId}/${taskDetailId}`,
      {
        headers: authHeader(),
      }
    );
  }

  getAllTemplatesOfOrganization(orgId) {
    return http.get(`task/getAllTemplatesOfOrganization/${orgId}`, {
      headers: authHeader(),
    });
  }

  addTaskTemplate(userId, reqDto, taskType) {
    return http.post(`task/addTaskTemplate/${userId}/${taskType}`, reqDto, {
      headers: authHeader(),
    });
  }

  getOrgEmployees(orgId) {
    return http.get(`person/getOrgEmployees/${orgId}`, {
      headers: authHeader(),
    });
  }

  draftTemplate(reqDto, userId) {
    return http.put(`task/draftTemplate/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
  updateCompletionTime(taskId, reqDto) {
    return http.put(`task/updateCompletionTime/${taskId}`, reqDto, {
      headers: authHeader(),
    });
  }
  updatePriority(taskId, reqDto) {
    return http.put(`task/updatePriority/${taskId}`, reqDto, {
      headers: authHeader(),
    });
  }

  uploadAudioBlog(reqDto) {
    return http.put(`misc/uploadAudioBlog`, reqDto, {
      headers: authHeader(),
    });
  }
  getTaskById(taskId) {
    return http.get(`task/getTaskById/${taskId}`, {
      headers: authHeader(),
    });
  }

  getTaskAssignees(taskDetailId) {
    return http.get(`task/getTaskAssignees/${taskDetailId}`, {
      headers: authHeader(),
    });
  }

  sendTaskComments(userId, reqDto) {
    return http.put(`task/sendTaskComments/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  uploadDocuments(orgId, userId, taskDetailId, reqDto) {
    return http.put(
      `task/uploadDocuments/${orgId}/${userId}/${taskDetailId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  transferTask(reqDto) {
    return http.put(`task/transferTask`, reqDto, { headers: authHeader() });
  }

  createLinkTask(userId, orgId, reqDto) {
    return http.post(`task/createLinkTask/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  updateProgressOfTask(taskDetailId, userId, progress) {
    return http.put(
      `task/updateProgressOfTask/${taskDetailId}/${userId}/${progress}`,
      { headers: authHeader() }
    );
  }

  concernTask(taskDetailId, userId, reqDto) {
    return http.post(`task/concernTask/${taskDetailId}/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  rejectTask(taskDetailId, userId, reqString) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `task/rejectTask/${taskDetailId}/${userId}`,
      reqString,
      config
    );
  }

  postponedTask(taskDetailId, userId, reqDto) {
    return http.put(`task/postponedTask/${taskDetailId}/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  approveTask(userId, reqDto) {
    return http.put(`task/approveTask/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  cancelTask(taskDetailId, userId, reqString) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `task/cancelTask/${taskDetailId}/${userId}`,
      reqString,
      config
    );
  }

  addressConcernOfTask(taskDetailId, userId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `task/addressConcernOfTask/${taskDetailId}/${userId}`,
      reqDto,
      config
    );
  }
  generatePreSignedUrlS3Object(fileName, folderName) {
    return http.get(
      `misc/generatePreSignedUrlS3Object/${fileName}/${folderName}/${3 * 3600}`,
      {
        headers: authHeader(),
      }
    );
  }

  disagreementTask(taskDetailId, userId, progress, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `task/disagreementTask/${taskDetailId}/${userId}/${progress}`,
      reqDto,
      config
    );
  }

  signOffTask(userId, reqDto) {
    return http.post(`task/signOffTask/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getNextTaskSuggestion(taskMasterId, subTask) {
    return http.get(`task/getNextTaskSuggestion/${taskMasterId}/${subTask}`, {
      headers: authHeader(),
    });
  }

  getTasksListByFunctionsAndActivity(orgId, funcId, actiId) {
    return http.get(
      `task/getTasksListByFunctionsAndActivity/${orgId}/${funcId}/${actiId}`,
      {
        headers: authHeader(),
      }
    );
  }

  reassignTask(userId, reqDto) {
    return http.put(`task/reassignTask/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  resumeRequest(taskDetailId, userId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `task/resumeRequest/${taskDetailId}/${userId}`,
      reqDto,
      config
    );
  }

  getTasks(userId) {
    return http.get(`task/getTasks/${userId}`, {
      headers: authHeader(),
    });
  }

  getAssigneesOfTask(taskDetailId) {
    return http.get(`task/getAssigneesOfTask/${taskDetailId}`, {
      headers: authHeader(),
    });
  }

  openPostponedTask(taskDetailId, userId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `task/openPostponedTask/${taskDetailId}/${userId}`,
      reqDto,
      config
    );
  }

  forwardApprovedTask(userId, reqDto) {
    return http.put(`task/forwardApprovedTask/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getLinkTasks(taskId, userId, requestFrom) {
    return http.get(`task/getLinkTasks/${taskId}/${userId}/${requestFrom}`, {
      headers: authHeader(),
    });
  }

  getUserProfile(userId) {
    return http.get(`person/getUserProfile/${userId}`, {
      headers: authHeader(),
    });
  }
  getDescendantsUsers(userId) {
    return http.get(`person/getDescendantsUsers/${userId}`, {
      headers: authHeader(),
    });
  }

  getBcp47CodeName() {
    return http.get(`misc/getBcp47CodeName`, {
      headers: authHeader(),
    });
  }

  addUpdateUserLanguage(userId, reqDto) {
    return http.put(`person/addUpdateUserLanguage/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  updateField(userId, reqDto) {
    return http.put(`person/updateField/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  updateUserName(userId, reqDto) {
    return http.put(`person/updateUserName/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  changePassword(reqDto) {
    return http.put(`userLogin/changePassword`, reqDto, {
      headers: authHeader(),
    });
  }

  updateProfileImage(userId, orgId, reqDto) {
    return http.put(`person/updateProfileImage/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  checkImage(userId, orgId, reqDto) {
    return http.post(
      `/faceRegistration/checkImage/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  checkCollectionForImage(userId, orgId, reqDto) {
    return http.post(
      `/faceRegistration/checkCollectionForImage/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  addFaceToCollection(userId, orgId, reqDto) {
    return http.post(
      `/faceRegistration/addFaceToCollection/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  loginUsingFace(subdomain, reqDto) {
    return http.post(`/faceRegistration/loginUsingFace/${subdomain}`, reqDto, {
      headers: authHeader(),
    });
  }

  getFaceId(userId) {
    return http.get(`/faceRegistration/getFaceId/${userId}`, {
      headers: authHeader(),
    });
  }

  deleteFace(orgId, userId) {
    return http.put(`/faceRegistration/deleteFace/${orgId}/${userId}`, {
      headers: authHeader(),
    });
  }

  getFaceLivenessSession(userId) {
    return http.get(`/faceRegistration/getFaceLivenessSession/${userId}`, {
      headers: authHeader(),
    });
  }

  getFaceLivenessSessionResult(sessionId) {
    return http.get(
      `/faceRegistration/getFaceLivenessSessionResult/${sessionId}`,
      {
        headers: authHeader(),
      }
    );
  }
  getContacts(userId) {
    return http.get(`contacts/getContacts/${userId}`, {
      headers: authHeader(),
    });
  }

  addContacts(reqDto, userId) {
    return http.post(`contacts/addContacts/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
  deleteContacts(userId, id) {
    return http.put(`contacts/deleteContact/${userId}/${id}`, {
      headers: authHeader(),
    });
  }
  sendInvite(reqDto, userId) {
    return http.put(`contacts/sendInvite/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
  getUserProfileImageLink(userId, orgId) {
    return http.get(`misc/getUserProfileImageLink/${userId}/${orgId}`, {
      headers: authHeader(),
    });
  }
  getContactDetailsByReferralLink(link) {
    return http.get(`contacts/getContactDetailsByReferralLink/${link}`, {
      headers: authHeader(),
    });
  }
  registerWithLink(linkId) {
    return http.put(`contacts/registerWithLink/${linkId}`, {
      headers: authHeader(),
    });
  }
  getEventDetailsByMeetingId(meetingId) {
    return http.get(`meeting/getEventDetailsByMeetingId/${meetingId}`);
  }

  updateSignature(userId, orgId, reqDto) {
    return http.put(`person/updateSignature/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getNextMeetingAndPendingTasks(userId) {
    return http.get(`userLogin/getNextMeetingAndPendingTasks/${userId}`, {
      headers: authHeader(),
    });
  }

  getVisitInfoBeforeConsult(visitData) {
    return http.post(`zhPatientVisit/getVisitInfoBeforeConsult`, visitData, {
      headers: authHeader(),
    });
  }

  getGenericswithRanking(doctorId) {
    return http.get(`zoyelHealthapi/getGenericswithRanking/${doctorId}`, {
      headers: authHeader(),
    });
  }

  getGenerics(reqDto) {
    return http.post(`zoyelHealthapi/getGenerics`, reqDto, {
      headers: authHeader(),
    });
  }

  getSymptoms(reqDto) {
    return http.post(`zoyelHealthapi/getSymptoms`, reqDto, {
      headers: authHeader(),
    });
  }

  getSymptomSince(reqDto) {
    return http.post(`zoyelHealthapi/getSymptomSince`, reqDto, {
      headers: authHeader(),
    });
  }

  getSymptomSeverity(reqDto) {
    return http.post(`zoyelHealthapi/getSymptomSeverity`, reqDto, {
      headers: authHeader(),
    });
  }

  getDiseases(reqDto) {
    return http.post(`zoyelHealthapi/getDiseases`, reqDto, {
      headers: authHeader(),
    });
  }

  getDiets(reqDto) {
    return http.post(`zoyelHealthapi/getDiets`, reqDto, {
      headers: authHeader(),
    });
  }

  getLifeStyles(reqDto) {
    return http.post(`zoyelHealthapi/getLifeStyles`, reqDto, {
      headers: authHeader(),
    });
  }

  getFoodAllergies(reqDto) {
    return http.post(`zoyelHealthapi/getFoodAllergies`, reqDto, {
      headers: authHeader(),
    });
  }
  getMedicalTestList(reqDto) {
    return http.post(`zoyelHealthapi/getMedicalTestList`, reqDto, {
      headers: authHeader(),
    });
  }

  getRiskFactors(reqDto) {
    return http.post(`zoyelHealthapi/getRiskFactors`, reqDto, {
      headers: authHeader(),
    });
  }

  getHereditaryDiseases(reqDto) {
    return http.post(`zoyelHealthapi/getHereditaryDiseases`, reqDto, {
      headers: authHeader(),
    });
  }

  getDurations(reqDto) {
    return http.post(`zoyelHealthapi/getDurations`, reqDto, {
      headers: authHeader(),
    });
  }

  getFrequencies(reqDto) {
    return http.post(`zoyelHealthapi/getFrequencies`, reqDto, {
      headers: authHeader(),
    });
  }

  getBrands(reqDto) {
    return http.post(`zoyelHealthapi/getBrands`, reqDto, {
      headers: authHeader(),
    });
  }

  getProcedures(reqDto) {
    return http.post(`zoyelHealthapi/getProcedures`, reqDto, {
      headers: authHeader(),
    });
  }

  getRoutes(reqDto) {
    return http.post(`zoyelHealthapi/getRoutes`, reqDto, {
      headers: authHeader(),
    });
  }

  getFormDosages(reqDto) {
    return http.post(`zoyelHealthapi/getFormDosages`, reqDto, {
      headers: authHeader(),
    });
  }

  getVitalUnits(reqDto) {
    return http.post(`zoyelHealthapi/getVitalUnits`, reqDto, {
      headers: authHeader(),
    });
  }

  getSigns(reqDto) {
    return http.post(`zoyelHealthapi/getSigns`, reqDto, {
      headers: authHeader(),
    });
  }

  getRiskFactorSince(reqDto) {
    return http.post(`zoyelHealthapi/getRiskFactorSince`, reqDto, {
      headers: authHeader(),
    });
  }

  getRelationships(reqDto) {
    return http.post(`zoyelHealthapi/getRelationships`, reqDto, {
      headers: authHeader(),
    });
  }

  getComorbidities(reqDto) {
    return http.post(`zoyelHealthapi/getComorbidities`, reqDto, {
      headers: authHeader(),
    });
  }

  getSubstanceAbuse(reqDto) {
    return http.post(`zoyelHealthapi/getSubstanceAbuse`, reqDto, {
      headers: authHeader(),
    });
  }

  getSystemicIllness(reqDto) {
    return http.post(`zoyelHealthapi/getSystemicIllness`, reqDto, {
      headers: authHeader(),
    });
  }
  getPhyExams(reqDto) {
    return http.post(`zoyelHealthapi/getPhyExams`, reqDto, {
      headers: authHeader(),
    });
  }

  getSpecializations(reqDto) {
    return http.post(`zoyelHealthapi/getSpecializations`, reqDto, {
      headers: authHeader(),
    });
  }

  getMedicationTiming(reqDto) {
    return http.post(`zoyelHealthapi/getMedicationTiming`, reqDto, {
      headers: authHeader(),
    });
  }
  getCompounds(reqDto) {
    return http.post(`zoyelHealthapi/getCompounds`, reqDto, {
      headers: authHeader(),
    });
  }

  getHospitalsOfOrg(orgId) {
    return http.get(`zhHospital/getHospitalsOfOrg/${orgId}`, {
      headers: authHeader(),
    });
  }

  submitPrescription(reqDto) {
    return http.post(`zhRx/submitPrescription`, reqDto, {
      headers: authHeader(),
    });
  }

  submitPatientVisit(reqDto) {
    return http.post(`zhPatientVisit/submitPatientVisit`, reqDto, {
      headers: authHeader(),
    });
  }

  generateVisitId(orgId) {
    return http.get(`zhPatientVisit/generateVisitId/${orgId}`, {
      headers: authHeader(),
    });
  }

  getPreviousMedicalRecords(patientId) {
    return http.get(`zhPatientVisit/getPreviousMedicalRecords/${patientId}`, {
      headers: authHeader(),
    });
  }

  getPatientPastUploadedDocuments(patientId) {
    return http.get(
      `zhPatientVisit/getPatientPastUploadedDocuments/${patientId}`,
      {
        headers: authHeader(),
      }
    );
  }

  startConsultation(visitId) {
    return http.put(`zhPatientVisit/startConsultation/${visitId}`, {
      headers: authHeader(),
    });
  }

  getAvailableDoctorsofUnit(unitId) {
    return http.get(`zhDoctor/getAvailableDoctorsofUnit/${unitId}`, {
      headers: authHeader(),
    });
  }

  getPatientDocumentsOfUnit(reqDto) {
    return http.post(`zhPatientDocument/getPatientDocumentsOfUnit`, reqDto, {
      headers: authHeader(),
    });
  }

  getDoctorsForSwitchPatient(unitId, doctorId) {
    return http.get(
      `zhDoctor/getDoctorsForSwitchPatient/${unitId}/${doctorId}`,
      {
        headers: authHeader(),
      }
    );
  }
  switchPatient(userId, reqDto) {
    return http.post(`zhPatientVisit/switchPatient/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getDoctorQueue(doctorId) {
    return http.get(`zhPatientVisit/getDoctorQueue/${doctorId}`, {
      headers: authHeader(),
    });
  }

  getClinicQueue(unitId) {
    return http.get(`zhPatientVisit/getClinicQueue/${unitId}`, {
      headers: authHeader(),
    });
  }

  connectDoctor(visitId) {
    return http.put(`zhPatientVisit/connectDoctor/${visitId}`, {
      headers: authHeader(),
    });
  }

  patientLeft(visitId, userId, reqDto) {
    return http.put(`zhPatientVisit/patientLeft/${visitId}/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  patientBack(visitId, userId) {
    return http.put(`zhPatientVisit/patientBack/${visitId}/${userId}`, {
      headers: authHeader(),
    });
  }

  reAssignCancelConsultation(visitId, userId, reqDto) {
    return http.put(
      `zhPatientVisit/reAssignCancelConsultation/${visitId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  cancelConsultation(visitId, userId, cancellationRemark) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };

    return http.put(
      `zhPatientVisit/cancelConsultation/${visitId}/${userId}`,
      cancellationRemark,
      config
    );
  }

  getDoctorsWithQueue(reqDto) {
    return http.post(`zhDoctor/getDoctorsWithQueue`, reqDto, {
      headers: authHeader(),
    });
  }

  getfeedbackMaster(orgId, feedbackFrom) {
    return http.get(`zhFeedback/getfeedbackMaster/${orgId}/${feedbackFrom}`, {
      headers: authHeader(),
    });
  }

  submitFeedback(reqDto) {
    return http.post(`zhFeedback/submitFeedback`, reqDto, {
      headers: authHeader(),
    });
  }

  getDoctorRoaster(doctorId) {
    return http.get(`zhDoctor/getDoctorRoaster/${doctorId}`, {
      headers: authHeader(),
    });
  }

  saveDoctorRoaster(doctorId, reqDto) {
    return http.post(`zhDoctor/saveDoctorRoaster/${doctorId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getDoctorListWithAvailableSlot(unitId) {
    return http.get(
      `zhBookAppointment/getDoctorListWithAvailableSlot/${unitId}`,
      {
        headers: authHeader(),
      }
    );
  }

  getDoctorDetails(userId) {
    return http.get(`zhDoctor/getDoctorDetails/${userId}`, {
      headers: authHeader(),
    });
  }

  deactivateZhAccount(userType, userId, deactivateByUserId) {
    return http.put(
      `person/deactivateZhAccount/${userType}/${userId}/${deactivateByUserId}`,
      {
        headers: authHeader(),
      }
    );
  }

  activateZhAccount(userType, userId, activateByUserId) {
    return http.put(
      `person/activateZhAccount/${userType}/${userId}/${activateByUserId}`,
      {
        headers: authHeader(),
      }
    );
  }

  getDoctorSlots(reqDto) {
    return http.post(`zhBookAppointment/getDoctorSlots`, reqDto, {
      headers: authHeader(),
    });
  }

  getDoctorsofUnitForAppointment(unitId) {
    return http.get(`zhDoctor/getDoctorsofUnitForAppointment/${unitId}`, {
      headers: authHeader(),
    });
  }

  getDoctorsAppointmentOnDate(unitId, appoinmentDate) {
    return http.get(
      `zhBookAppointment/getDoctorsAppointmentOnDate/${unitId}/${appoinmentDate}`,
      {
        headers: authHeader(),
      }
    );
  }

  getPatientListByEmailId(orgId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `zhPatientReg/getPatientListByEmailId/${orgId}`,
      reqDto,
      config
    );
  }

  getDoctorAllSlots(reqDto) {
    return http.post(`zhBookAppointment/getDoctorAllSlots`, reqDto, {
      headers: authHeader(),
    });
  }

  requestSlotReschedule(userId, reqDto) {
    return http.put(
      `zhBookAppointment/requestSlotReschedule/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  makeSlotsUnavailable(userId, reqDto) {
    return http.put(
      `zhBookAppointment/makeSlotsUnavailable/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  makeSlotsAvailable(userId, reqDto) {
    return http.put(`zhBookAppointment/makeSlotsAvailable/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getReviewPatients(unitId, date) {
    return http.get(
      `zhReviewReferPatient/getReviewPatients/${unitId}/${date}`,
      {
        headers: authHeader(),
      }
    );
  }

  getDoctorConsultation(userId, fromDate, toDate) {
    return http.get(
      `zhDoctor/getDoctorConsultation/${userId}/${fromDate}/${toDate}`,
      {
        headers: authHeader(),
      }
    );
  }

  getReferredPatients(unitId, date) {
    return http.get(
      `zhReviewReferPatient/getReferredPatients/${unitId}/${date}`,
      {
        headers: authHeader(),
      }
    );
  }

  refreshUnitReferReview(reqDto) {
    return http.post(`ax/refreshUnitReferReview`, reqDto, {
      headers: authHeader(),
    });
  }

  refreshUnitInvestigation(reqDto) {
    return http.post(`ax/refreshUnitInvestigation`, reqDto, {
      headers: authHeader(),
    });
  }

  getMedicalTestList(reqDto) {
    return http.post(`zoyelHealthapi/getMedicalTestList`, reqDto, {
      headers: authHeader(),
    });
  }

  getAllUnitsMappedWithTest(orgId, testId) {
    return http.get(
      `zhTestController/getAllUnitsMappedWithTest/${orgId}/${testId}`,
      {
        headers: authHeader(),
      }
    );
  }

  mapTestToUnits(testId, reqDto) {
    return http.post(`zhTestController/mapTestToUnits/${testId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getTestListConductedAtUnit(orgId) {
    return http.get(`zhTestController/getTestListConductedAtUnit/${orgId}`, {
      headers: authHeader(),
    });
  }

  getPatientDocumentsOfUnit(unitId, fromDate, toDate) {
    return http.get(
      `zhPatientDocument/getPatientDocumentsOfUnit/${unitId}/${fromDate}/${toDate}`,
      {
        headers: authHeader(),
      }
    );
  }

  getTestReport(patientId, unitId, reqDto) {
    return http.post(
      `zhPatientDocument/getTestReport/${patientId}/${unitId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  refreshManagementConsultation(reqDto) {
    return http.post(`ax/refreshManagementConsultation`, reqDto, {
      headers: authHeader(),
    });
  }

  refreshManagementFeedback(reqDto) {
    return http.post(`ax/refreshManagementFeedback`, reqDto, {
      headers: authHeader(),
    });
  }

  refreshManagementDoctor(reqDto) {
    return http.post(`ax/refreshManagementDoctor`, reqDto, {
      headers: authHeader(),
    });
  }

  refreshManagementTrends(reqDto) {
    return http.post(`ax/refreshManagementTrends`, reqDto, {
      headers: authHeader(),
    });
  }

  refreshManagementUnits(reqDto) {
    return http.post(`ax/refreshManagementUnits`, reqDto, {
      headers: authHeader(),
    });
  }

  refreshManagementDisease(reqDto) {
    return http.post(`ax/refreshManagementDisease`, reqDto, {
      headers: authHeader(),
    });
  }

  changePasswordRequiredorNot(userId) {
    return http.get(`userLogin/changePasswordRequiredorNot/${userId}`, {
      headers: authHeader(),
    });
  }

  changePasswordByUserId(userId, data) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(`userLogin/changePassword/${userId}`, data, config);
  }

  getOrgBusinessTypes(reqDto) {
    return http.put(`org/getOrgBusinessTypes`, reqDto, {
      headers: authHeader(),
    });
  }

  checkUserActiveOrNot(userId) {
    return http.get(`userLogin/checkUserActiveOrNot/${userId}`, {
      headers: authHeader(),
    });
  }

  getOrganizationsActiveDoctors(orgId) {
    return http.get(`zhDoctor/getOrganizationsActiveDoctors/${orgId}`, {
      headers: authHeader(),
    });
  }

  getUnitsForMapDoctor(orgId, doctorId) {
    return http.get(`zhDoctor/getUnitsForMapDoctor/${orgId}/${doctorId}`, {
      headers: authHeader(),
    });
  }

  submitDoctorUnits(doctorId, reqDto) {
    return http.post(`zhDoctor/submitDoctorUnits/${doctorId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getOrganizationsActiveUnits(orgId) {
    return http.get(`org/getOrganizationsActiveUnits/${orgId}`, {
      headers: authHeader(),
    });
  }

  getDoctorsForMapUnit(orgId, unitId) {
    return http.get(`zhDoctor/getDoctorsForMapUnit/${orgId}/${unitId}`, {
      headers: authHeader(),
    });
  }

  submitUnitDoctors(unitId, reqDto) {
    return http.post(`zhDoctor/submitUnitDoctors/${unitId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getAllUnitsMappedWithTest(orgId, testId) {
    return http.get(
      `zhTestController/getAllUnitsMappedWithTest/${orgId}/${testId}`,
      {
        headers: authHeader(),
      }
    );
  }

  getAllTestMappedWithUnit(orgId, unitId) {
    return http.get(
      `zhTestController/getAllTestMappedWithUnit/${orgId}/${unitId}`,
      {
        headers: authHeader(),
      }
    );
  }

  submitUnitsOfTest(testId, reqDto) {
    return http.post(`zhTestController/submitUnitsOfTest/${testId}`, reqDto, {
      headers: authHeader(),
    });
  }

  submitTestsOfUnits(unitId, reqDto) {
    return http.post(`zhTestController/submitTestsOfUnits/${unitId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getVitalConfigs(reqDto) {
    return http.post(`zoyelHealthapi/getVitalConfigs`, reqDto, {
      headers: authHeader(),
    });
  }

  updateVitalConfig(userId, reqDto) {
    return http.put(`zoyelHealthapi/updateVitalConfig/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  refreshSupportTicket(reqDto) {
    return http.post(`ax/refreshSupportTicket`, reqDto, {
      headers: authHeader(),
    });
  }

  isPatientInVideo(visitId) {
    axios.post(`${socketEndPoint}/api/health/isPatientInVideo/${visitId}`);
  }

  getDoctorConsultationWaitTimeConsutTime(reqDto) {
    return http.post(`ax/getDoctorConsultationWaitTimeConsutTime`, reqDto, {
      headers: authHeader(),
    });
  }

  getQueueHistory(reqDto) {
    return http.post(`ax/getQueueHistory`, reqDto, {
      headers: authHeader(),
    });
  }

  refreshManagementTasks(reqDto) {
    return http.post(`ax/refreshManagementTasks`, reqDto, {
      headers: authHeader(),
    });
  }


  getUserOnboardingPolicy(orgId, userId) {
    return http.get(`org/getUserOnboardPolicies/${orgId}/${userId}`, {
      headers: authHeader(),
    });
  }

  logUserPolicyAcceptance(policyId, userId, orgId) {
    return http.post(
      `org/logUserPolicyAcceptance/${policyId}/${userId}/${orgId}`,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new DashboardServices();
