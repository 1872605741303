import { createSlice } from "@reduxjs/toolkit";
import SecureIndexedDB from "../../utils/IndexedDB";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    mode: localStorage.getItem("theme") || "darkMode", // Persisted theme
  },
  reducers: {
    toggleTheme: (state) => {
      state.mode = state.mode === "lightMode" ? "darkMode" : "lightMode";
      localStorage.setItem("theme", state.mode);
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
