import {
  Button,
  Checkbox,
  IconButton,
  Menu,
  TablePagination,
} from "@mui/material";
import { EditPencil, Trash } from "iconoir-react";
import React, { useEffect, useState } from "react";
import "../css/emailContainer.css";

import {
  AccessTimeOutlined,
  Bookmark,
  BookmarkBorder,
  Inbox,
  InsertDriveFileOutlined,
  SendOutlined,
  StarBorder,
  StarBorderOutlined,
  StarRate,
} from "@mui/icons-material";
import ComposeMail from "./ComposeMail";
import EmailsView from "./EmailsView";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessages,
  deleteMessage,
  fetchMsgBody,
  fetchThreadList,
  modifyMsgLabels,
  moveMessageToTrash,
} from "../../../redux/reducers/mailSlice";
import { mailSocket } from "../../../socket";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { GetLoginUserDetails } from "../../../utils/Utils";
import { socketEndPoint } from "../../../constants/url_provider";
import {
  clearUnreadMessages,
  getUnreadMessages,
} from "../../../redux/reducers/dashboardMailSlice";
import { List, Search, XLg } from "react-bootstrap-icons";
import EmailService from "../../../services/EmailService";
import dayjs from "dayjs";
import { useRef } from "react";
import SecureIndexedDB from "../../../utils/IndexedDB";

export default function EmailContainer() {
  const [showEmailView, setShowEmailView] = useState(false);
  const loginUser = GetLoginUserDetails();
  const label = { inputProps: { "aria-label": "Starred" } };
  const [showComposeMail, setShowComposeMail] = useState(false);
  const [messages, setMessages] = useState([]);

  const [accessToken, setAccessToken] = useState(null);
  const mailSlice = useSelector((state) => state.mail);
  const [labels, setLabels] = useState([]);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("INBOX");
  const [labelId, setLabelId] = useState([]);
  const [query, setQuery] = useState("");
  const [mailCount, setMailCount] = useState(0);
  const [pageTokenList, setPageTokenList] = useState([null]);
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [showHideMenu, setShowHideMenu] = useState(false);
  const childWindowRef = useRef();

  const hitApi = async () => {
    console.log("hitting api");
    try {
      const response = await axios.get(
        `${socketEndPoint}/api/mail/authenticate/${loginUser.userId}`
      );
      if (response.data.url) {
        // window.open(response.data.url, "Zoyel Authentication", "popup");
        childWindowRef.current = window.open(
          response.data.url,
          "popup",
          "width=600,height=400,left=100,top=100,resizable=yes,scrollbars=yes,status=yes,menubar=no,toolbar=no,location=no"
        );
      } else {
        handleAccessTokenData(response.data);
      }
    } catch (error) {
      console.log("error happened", error);
    }
  };

  const handleAccessTokenData = async (data) => {
    const tempData = data;
    console.log("jwt token data ", tempData.id_token);
    const decodedData = jwtDecode(tempData.id_token);
    const { email, name } = decodedData;
    setAccessToken(tempData.access_token);
    listLabels();
    SecureIndexedDB.setItem("current_mailId", email);
    SecureIndexedDB.setItem("email_user_name", name);
    SecureIndexedDB.setItem("access_token", tempData.access_token);
    SecureIndexedDB.setItem("expiry_date", tempData.expiry_date);
    if (childWindowRef.current) {
      childWindowRef.current.close();
    }
  };

  useEffect(() => {
    const process = async () => {
      let userMailId = null;
      userMailId = await SecureIndexedDB.getItem("current_mailId");
      if (!userMailId) {
        SecureIndexedDB.setItem("current_mailId", null);
      }
      const authenticate = async () => {
        const expiry_date = await SecureIndexedDB.getItem("expiry_date");
        const access_token = await SecureIndexedDB.getItem("access_token");
        const timestamp = parseInt(expiry_date, 10);
        const currentTimestamp = Date.now();
        const timeDifference = timestamp - currentTimestamp;
        if (timeDifference > 2 * 60 * 1000) {
          setAccessToken(access_token);
          setTimeout(() => {
            hitApi();
          }, timeDifference - 2 * 60 * 1000);
          console.log("");
        } else {
          hitApi();
        }

        if (expiry_date) {
          console.log("typeof expiry date = ", typeof expiry_date);
        }
      };
      const authData = authenticate();
      // console.log("authenticated data= ", authData);
      console.log("mailSlice=", mailSlice);
      // const script = document.createElement("script");
      // script.async = true;
      // script.defer = true;
      // script.src = "https://apis.google.com/js/api.js";
      // script.onload = gapiLoaded;

      // document.body.appendChild(script);
      // return () => {
      //   document.body.removeChild(script);
      // };
      setLabelId(["INBOX", "CATEGORY_PERSONAL"]);
      setQuery("-in:draft -in:sent");
      setSelected("INBOX");
      const access_token = await SecureIndexedDB.getItem("access_token");
      if (access_token) {
        getEmailList(["INBOX", "CATEGORY_PERSONAL"], "-in:draft -in:sent");
      }
    };
    process();
  }, []);

  useEffect(() => {
    // console.log(" gapi inited = ", gapiInited, " access Token = ", accessToken);
    const setTokenInGapi = async () => {
      if (window.gapi && accessToken) {
        await window.gapi.auth.setToken({
          access_token: accessToken,
        });
        listLabels();
        dispatch(getUnreadMessages());
        // dispatch(clearUnreadMessages());
      }
    };
    setTokenInGapi();
  }, [accessToken]);

  useEffect(() => {
    if (mailSocket) {
      mailSocket.emit("joinMailUser", { userId: loginUser.userId });
      mailSocket.on("receiveTokenFromServer", async (tokenObj) => {
        console.log("receiveTokenFromServer", tokenObj);
        try {
          handleAccessTokenData(tokenObj);
        } catch (error) {}
      });
    }
  }, [mailSocket]);

  // const gapiLoaded = () => {
  //   window.gapi.load("client", initializeGapiClient);
  // };

  // const initializeGapiClient = async () => {
  //   console.log("initialize value =");
  //   await window.gapi.client.init({
  //     apiKey: API_KEY,
  //     discoveryDocs: [
  //       "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
  //     ],
  //   });

  //   setGapiInited(true);
  //   // listLabels();
  //   // getEmailList(["INBOX", "CATEGORY_PERSONAL"], "-in:draft -in:sent");
  // };

  async function listLabels() {
    console.log("lables for ");
    let response;
    try {
      response = await window.gapi.client.gmail.users.labels.list({
        userId: "me",
      });
    } catch (err) {
      return;
    }
    const labels = response.result.labels;
    console.log("lables =", labels);
    if (!labels || labels.length == 0) {
      console.log("no labels found");
      return;
    }
    const labls = labels.map((item) => ({ name: item.name, id: item.id }));
    setLabels(labls);
  }

  const getEmailList = (labelId, q, mailPerPage) => {
    console.log("email list value = ***********");
    try {
      window.gapi.client.gmail.users.messages
        .list({
          userId: "me",
          labelIds: [...labelId],
          maxResults: mailPerPage,
          q: q,
        })
        .then(
          function (response) {
            console.log("response=", response);
            if (response.result.messages) {
              setMessages(response.result.messages);
              setNextPageToken(response.result.nextPageToken);
              let temp = [response.result.nextPageToken];
              setPageTokenList(temp);
              setCurrentPage(0);
              setMailCount(response.result.resultSizeEstimate);
              // console.log("Messages:", response.result.messages);

              dispatch(clearMessages());
              dispatch(fetchMsgBody(response.result.messages));
              // response.result.messages.forEach((item) => {
              //   dispatch(fetchMsgBody(item.id));
              // });
            } else {
              console.log("No messages found for label:", labelId);
            }
          },
          async function (error) {
            console.error("Error retrieving messages:", error);
            if (error.status && error.status === 403) {
              //if access token is valid then update refresh token and then receive new refreshtoken

              const token_exp_time = parseInt(
                await SecureIndexedDB.getItem("expiry_date")
              );
              const currentTime = dayjs().valueOf();
              if (token_exp_time > currentTime) {
                EmailService.getNewRefreshToken(loginUser.userId).then(
                  (response) => {
                    if (response.data && response.data.url) {
                      window.open(response.data.url, "_blank");
                    }
                  }
                );
              }
            } else if (error.status && error.status === 401) {
              //check user token validity and update local storage accoridngly
            }
          }
        );
    } catch (error) {
      console.log("get email error =", error);
    }
  };

  const getDateFromString = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    // const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    const day = date.getDate();
    const month = date.toLocaleString("en-us", { month: "short" });
    const year = date.getFullYear();

    // return `${hours}:${minutes}`;
    return `${day} ${month} ${year}`;
  };

  // pagination start
  const [page, setPage] = React.useState(0);
  const [mailPerPage, setMailPerPage] = React.useState(50);

  const handleChangePage = (event, newPage) => {
    console.log("new page = ", newPage);
    setPage(newPage);
    let pageToken = null;
    if (newPage > page) {
      pageToken = pageTokenList[pageTokenList.length - 1];
    } else if (newPage === 0) {
      pageToken = null;
    } else {
      pageTokenList.pop();
      pageToken = pageTokenList[newPage - 1];
    }
    window.gapi.client.gmail.users.messages
      .list({
        userId: "me",
        labelIds: [...labelId],
        maxResults: mailPerPage,
        q: query,
        pageToken: pageToken,
      })
      .then(
        function (response) {
          console.log("response=", response);
          if (response.result.messages) {
            setMessages(response.result.messages);
            setNextPageToken(response.result.nextPageToken);
            // console.log("Messages:", response.result.messages);
            let temp = [...pageTokenList, response.result.nextPageToken];
            setPageTokenList(temp);
            setCurrentPage(0);

            dispatch(clearMessages());
            dispatch(fetchMsgBody(response.result.messages));
            // response.result.messages.forEach((item) => {
            //   dispatch(fetchMsgBody(item.id));
            // });
          } else {
            console.log("No messages found for label:", labelId);
          }
        },
        function (error) {
          console.error("Error retrieving messages:", error);
        }
      );
  };

  const handleChangeMailPerPage = (event) => {
    console.log("mail per page = ", event.target.value);
    setMailPerPage(parseInt(event.target.value, 10));
    setPage(0);

    if (labelId && labelId.length === 0) {
      getEmailList(
        ["INBOX", "CATEGORY_PERSONAL"],
        "-in:draft -in:sent",
        event.target.value
      );
    } else {
      getEmailList(labelId, query, event.target.value);
    }
  };
  // pagination end
  return (
    <>
      <div className="emailContainer">
        <div
          className={`leftEmailMenuBar mobLftEmail ${
            showHideMenu ? "showMobLftEmail " : ""
          } `}
        >
          <div className="leftEmailMenu">
            <div className="menuComposeMail">
              <Button
                className="composeBtn"
                variant="contained"
                startIcon={<EditPencil />}
                onClick={() => {
                  setShowComposeMail(true);
                }}
              >
                Compose
              </Button>

              <IconButton
                className="mobBackToMailList"
                onClick={() => setShowHideMenu(false)}
              >
                <XLg />
              </IconButton>
            </div>
            <div className="menuTypeMail">
              <div className="menutypelist">
                <div
                  className={`mailType ${selected === "INBOX" ? "active" : ""}`}
                  onClick={() => {
                    console.log("clicked inbox");
                    setSelected("INBOX");
                    setLabelId(["INBOX", "CATEGORY_PERSONAL"]);
                    setQuery("-in:draft -in:sent");
                    getEmailList(
                      ["INBOX", "CATEGORY_PERSONAL"],
                      "-in:draft -in:sent",
                      mailPerPage
                    );
                    setShowEmailView(false);
                    setShowHideMenu(false);
                  }}
                >
                  <div className="mailtypeIcon">
                    <Inbox />
                  </div>
                  <div className="mailtypeName">Inbox</div>
                  <div className="mailtypeAction"></div>
                </div>

                <div
                  className={`mailType ${
                    selected === "STARRED" ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelected("STARRED");
                    setLabelId(["STARRED"]);
                    setQuery("is:starred");
                    getEmailList(["STARRED"], "is:starred", mailPerPage);
                    setShowEmailView(false);
                    setShowHideMenu(false);
                  }}
                >
                  <div className="mailtypeIcon">
                    <StarBorderOutlined />
                  </div>
                  <div className="mailtypeName">Starred</div>
                  <div className="mailtypeAction"></div>
                </div>

                <div className="mailType ">
                  <div className="mailtypeIcon">
                    <AccessTimeOutlined />
                  </div>
                  <div className="mailtypeName">Snoozed</div>
                  <div className="mailtypeAction"></div>
                </div>

                <div
                  className={`mailType ${selected === "SENT" ? "active" : ""}`}
                  onClick={() => {
                    setSelected("SENT");
                    setLabelId(["SENT"]);
                    setQuery("");
                    getEmailList(["SENT"], "", mailPerPage);
                    setShowEmailView(false);
                    setShowHideMenu(false);
                  }}
                >
                  <div className="mailtypeIcon">
                    <SendOutlined />
                  </div>
                  <div className="mailtypeName">Sent</div>
                  <div className="mailtypeAction"></div>
                </div>

                {/* <div
                  className={`mailType ${selected === "DRAFT" ? "active" : ""}`}
                  onClick={() => {
                    setSelected("DRAFT");
                    setLabelId(["DRAFT"]);
                    setQuery("");
                    getEmailList(["DRAFT"], "", mailPerPage);
                  }}
                >
                  <div className="mailtypeIcon">
                    <InsertDriveFileOutlined />
                  </div>
                  <div className="mailtypeName">Drafts</div>
                  <div className="mailtypeAction"></div>
                </div> */}

                {labels &&
                  labels.length > 0 &&
                  labels
                    .filter((label) => {
                      return (
                        label.name !== "DRAFT" &&
                        label.name !== "SENT" &&
                        label.name !== "STARRED" &&
                        label.name !== "INBOX"
                      );
                    })
                    .map((label) => {
                      return (
                        <div
                          className={`mailType ${
                            selected === label.name ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelected(label.name);
                            setLabelId([label.name]);
                            setQuery("");
                            getEmailList([label.name], "", mailPerPage);
                            setShowEmailView(false);
                            setShowHideMenu(false);
                          }}
                        >
                          <div className="mailtypeIcon">
                            <InsertDriveFileOutlined />
                          </div>
                          <div className="mailtypeName">{label.name}</div>
                          <div className="mailtypeAction"></div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>

        {!showEmailView && (
          <div
            className={`rightEmailContain mobRgtEmail ${
              showHideMenu ? "hideMobRgtEmail " : ""
            } `}
          >
            <div className="rightMailTopbar">
              <div className="emailMenuButton">
                <IconButton
                  onClick={() => {
                    setShowHideMenu((prev) => !prev);
                  }}
                >
                  <List />
                </IconButton>
              </div>

              <div class="searchUser">
                <div class="searchIcon">
                  <Search />
                </div>
                <input
                  className="serchInput"
                  type="text"
                  id="searchQuery"
                  name="searchQuery"
                  placeholder="Enter your search phrase"
                  onChange={(e) => {
                    getEmailList([], e.target.value, mailPerPage);
                  }}
                />
              </div>

              <div className="paginationMail">
                <TablePagination
                  component="div"
                  count={mailCount}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={mailPerPage}
                  onRowsPerPageChange={handleChangeMailPerPage}
                />
              </div>
            </div>
            <div className="rightMailContainerGroup">
              <div className="mailListGroup" id="mailListGroup">
                {mailSlice &&
                  mailSlice.messages.length > 0 &&
                  mailSlice.messages.map((item) => {
                    return (
                      <div
                        className="mailListInd"
                        onClick={(e) => {
                          if (item.threadId) {
                            if (item.labels.includes("DRAFT")) {
                              //TODO: if draft, open mail Composer
                              console.log("draft clicked");
                            } else {
                              dispatch(fetchThreadList(item.threadId));
                              setShowEmailView(true);
                            }
                          }
                        }}
                      >
                        <div className="mailIndIconsGroup">
                          {/* <div className="mailIndIcons">
                          <Checkbox
                            {...label}
                            size="small"
                            className="selectMailCheck"
                          />
                        </div> */}
                          <div className="mailIndIcons">
                            <Checkbox
                              size="small"
                              {...label}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onChange={(e) => {
                                if (item.labels.includes("STARRED")) {
                                  dispatch(
                                    modifyMsgLabels({
                                      messageId: item.messageId,
                                      labelsToAdd: [],
                                      labelsToRemove: ["STARRED"],
                                    })
                                  );
                                } else {
                                  dispatch(
                                    modifyMsgLabels({
                                      messageId: item.messageId,
                                      labelsToAdd: ["STARRED"],
                                      labelsToRemove: [],
                                    })
                                  );
                                }
                              }}
                              icon={<StarBorder />}
                              checkedIcon={<StarRate />}
                              className="starCheck"
                              checked={item.labels.includes("STARRED")}
                            />
                          </div>
                          <div className="mailIndIcons">
                            <Checkbox
                              size="small"
                              {...label}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onChange={(e) => {
                                if (item.labels.includes("IMPORTANT")) {
                                  dispatch(
                                    modifyMsgLabels({
                                      messageId: item.messageId,
                                      labelsToAdd: [],
                                      labelsToRemove: ["IMPORTANT"],
                                    })
                                  );
                                } else {
                                  dispatch(
                                    modifyMsgLabels({
                                      messageId: item.messageId,
                                      labelsToAdd: ["IMPORTANT"],
                                      labelsToRemove: [],
                                    })
                                  );
                                }
                              }}
                              icon={<BookmarkBorder />}
                              checkedIcon={<Bookmark />}
                              className="bookmarkcheck"
                              checked={item.labels.includes("IMPORTANT")}
                            />
                          </div>
                        </div>
                        <div className="mailUserName">
                          <div className="userFullName">
                            {item.labels.includes("DRAFT")
                              ? "Draft"
                              : item.senderName !== ""
                              ? item.senderName
                              : item.senderEmail}
                          </div>
                        </div>
                        <div className="mailTextInfo">
                          <div className="mailText">
                            {item.subject === ""
                              ? "(No Subject)"
                              : item.subject}
                          </div>
                        </div>
                        <div className="mailTime">
                          <div className="mailInOutTime">
                            <span>{getDateFromString(item.sendingDate)}</span>
                          </div>
                        </div>

                        <div className="mailDltIcon">
                          <Trash
                            onClick={(e) => {
                              e.stopPropagation();
                              console.log("delete clicked");
                              if (item.labels.includes("TRASH")) {
                                console.log("in trash");
                                dispatch(deleteMessage(item.messageId));
                              } else {
                                dispatch(moveMessageToTrash(item.messageId));
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}

                {/* <div className="mailListInd">
                <div className="mailIndIconsGroup">
                  <div className="mailIndIcons">
                    <Checkbox
                      {...label}
                      size="small"
                      className="selectMailCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<StarBorder />}
                      checkedIcon={<StarRate />}
                      className="starCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<BookmarkBorder />}
                      checkedIcon={<Bookmark />}
                      className="bookmarkcheck"
                    />
                  </div>
                </div>
                <div className="mailUserName">
                  <div className="userFullName">Sanket Santra</div>
                </div>
                <div className="mailTextInfo">
                  <div className="mailText">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry...
                  </div>
                </div>
                <div className="mailTime">
                  <div className="mailInOutTime">
                    <span>13:30</span>
                  </div>
                </div>
              </div>

              <div className="mailListInd">
                <div className="mailIndIconsGroup">
                  <div className="mailIndIcons">
                    <Checkbox
                      {...label}
                      size="small"
                      className="selectMailCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<StarBorder />}
                      checkedIcon={<StarRate />}
                      className="starCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<BookmarkBorder />}
                      checkedIcon={<Bookmark />}
                      className="bookmarkcheck"
                    />
                  </div>
                </div>
                <div className="mailUserName">
                  <div className="userFullName">Sanket Santra</div>
                </div>
                <div className="mailTextInfo">
                  <div className="mailText">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry...
                  </div>
                </div>
                <div className="mailTime">
                  <div className="mailInOutTime">
                    <span>13:30</span>
                  </div>
                </div>
              </div>

              <div className="mailListInd">
                <div className="mailIndIconsGroup">
                  <div className="mailIndIcons">
                    <Checkbox
                      {...label}
                      size="small"
                      className="selectMailCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<StarBorder />}
                      checkedIcon={<StarRate />}
                      className="starCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<BookmarkBorder />}
                      checkedIcon={<Bookmark />}
                      className="bookmarkcheck"
                    />
                  </div>
                </div>
                <div className="mailUserName">
                  <div className="userFullName">Sanket Santra</div>
                </div>
                <div className="mailTextInfo">
                  <div className="mailText">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry...
                  </div>
                </div>
                <div className="mailTime">
                  <div className="mailInOutTime">
                    <span>13:30</span>
                  </div>
                </div>
              </div>

              <div className="mailListInd">
                <div className="mailIndIconsGroup">
                  <div className="mailIndIcons">
                    <Checkbox
                      {...label}
                      size="small"
                      className="selectMailCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<StarBorder />}
                      checkedIcon={<StarRate />}
                      className="starCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<BookmarkBorder />}
                      checkedIcon={<Bookmark />}
                      className="bookmarkcheck"
                    />
                  </div>
                </div>
                <div className="mailUserName">
                  <div className="userFullName">Sanket Santra</div>
                </div>
                <div className="mailTextInfo">
                  <div className="mailText">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry...
                  </div>
                </div>
                <div className="mailTime">
                  <div className="mailInOutTime">
                    <span>13:30</span>
                  </div>
                </div>
              </div>

              <div className="mailListInd">
                <div className="mailIndIconsGroup">
                  <div className="mailIndIcons">
                    <Checkbox
                      {...label}
                      size="small"
                      className="selectMailCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<StarBorder />}
                      checkedIcon={<StarRate />}
                      className="starCheck"
                    />
                  </div>
                  <div className="mailIndIcons">
                    <Checkbox
                      size="small"
                      {...label}
                      icon={<BookmarkBorder />}
                      checkedIcon={<Bookmark />}
                      className="bookmarkcheck"
                    />
                  </div>
                </div>
                <div className="mailUserName">
                  <div className="userFullName">Sanket Santra</div>
                </div>
                <div className="mailTextInfo">
                  <div className="mailText">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry...
                  </div>
                </div>
                <div className="mailTime">
                  <div className="mailInOutTime">
                    <span>13:30</span>
                  </div>
                </div>
              </div> */}
              </div>

              {/* <div className="mailViewCreateGroup"><EmailsView /></div> */}
            </div>
          </div>
        )}

        {showEmailView && (
          <div className="rightEmailViewContain mobRgtEmail showMobRgtEmail">
            <EmailsView
              close={() => {
                setShowEmailView(false);
              }}
            />
          </div>
        )}
      </div>
      {showComposeMail && (
        <ComposeMail
          open={showComposeMail}
          onClose={() => setShowComposeMail(false)}
          closeComposeMail={() => {
            setShowComposeMail(false);
          }}
        />
      )}
    </>
  );
}
