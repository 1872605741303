import { useCallback, useEffect, useRef, useState } from "react";
import { convertBlobToBase64 } from "./Utils";

const useAudio = () => {
  const tempAudioChunk = useRef([]);
  //   const [audioDataBase64, setAudiDataBase64] = useState(null);
  //   const [recordingLength, setRecordingLength] = useState(0);
  //   const [recordingLink, setRecordingLink] = useState();

  const handleAudio = async (combinedBlob) => {
    return new Promise((resolve, reject) => {
      let audioFileDataBase64;
      if (combinedBlob?.length > 0) {
        // const combinedTempBlob = getSingleBlob()
        const audioBlob = new Blob(tempAudioChunk.current, {
          type: "audio/wav",
        });
        if (audioBlob) {
          convertBlobToBase64(audioBlob)
            .then((base64Data) => {
              audioFileDataBase64 = base64Data;
              resolve(audioFileDataBase64);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(audioFileDataBase64);
        }
      } else {
        resolve(audioFileDataBase64);
      }
    });
  };

  const getSingleBlob = () => {
    const promises = [];

    for (const blob of tempAudioChunk.current) {
      const promise = blob.arrayBuffer();
      promises.push(promise);
    }

    return Promise.all(promises)
      .then((arrayBuffers) => {
        const concatenatedArrayBuffer = arrayBuffers.reduce((acc, buffer) => {
          const totalLength = acc.byteLength + buffer.byteLength;
          const combined = new Uint8Array(totalLength);
          combined.set(new Uint8Array(acc), 0);
          combined.set(new Uint8Array(buffer), acc.byteLength);
          return combined.buffer;
        }, new ArrayBuffer(0));

        const combinedBlob = new Blob([concatenatedArrayBuffer], {
          type: "audio/wav",
        });
        // tempAudioChunk.current = [combinedBlob];
        // console.log(tempAudioChunk.current);
        return combinedBlob;
      })
      .catch((error) => {
        console.error("Error occurred while combining Blobs:", error);
      });
  };

  const getAudioBase64 = async () => {
    const singleBlob = await getSingleBlob();
    const tempAudio = await handleAudio([singleBlob]);
    //   setAudiDataBase64(tempAudio);
    return tempAudio;
  };

  const setAudioChunk = (data) => {
    tempAudioChunk.current = data;
  };

  //   useEffect(() => {
  //     getAudioBase64();
  //   }, [tempAudioChunk.current, getAudioBase64]);

  return { tempAudioChunk, getAudioBase64, setAudioChunk };
};

export default useAudio;
