import {
  Badge,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { GetLoginUserDetails } from "../../../utils/Utils";
import RightSideMeetingsContainer from "../../dashboard/view/component/right-panel/RightSideMeetingsContainer";
import { Trash3, XLg } from "react-bootstrap-icons";
import DateUtils, { weekDays } from "../../../utils/DateUtils";
import { Forward, Trash } from "iconoir-react";
import {
  ArrowForward,
  ArrowForwardIos,
  ArrowRightAlt,
  Forward5,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { publicUrl, socketEndPoint } from "../../../constants/url_provider";
import axios from "axios";
import { useTranslation } from "react-i18next";
export default function SchRightSideSettings({
  additionalEventDetails,
  closeFromDashboard,
  setSelectedEvent,
  weekStartDay,
  handleChangeFirstDayWeek,
  defaultWorkWeek,
  defaultValues,
  handleChanged,
  sortedWeekDays,
  slotDuration,
  handleChangeSlotDuration,
  startTime,
  setStartTime,
  setRows,
  startTimeList,
  endTime,
  minStartTimeEvent,
  setEndTime,
  endTimeList,
  maxEndTimeEvent,
  events,
  setEvents,
  selectedDate,
  isGcalSynced,
  setIsGcalSynced,
  setGcalLastSynced,
  gcalLastSynced,
}) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 16;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5.0 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const loginUser = GetLoginUserDetails();
  const { t } = useTranslation();

  const handleGoogle = async () => {
    const { startDate, endDate } =
      DateUtils.generateDatesForGoogleCalendar(selectedDate);
    console.log(
      "generateDatesForGoogleCalendar---->>>>>>>>",
      startDate,
      endDate
    );
    // return;
    // let startDate = currentWeek[0].toISOString();
    // let endDate = currentWeek[currentWeek.length - 1].toISOString();
    const respEventData = await axios.get(
      `${socketEndPoint}/api/gcal/getAllEvents/${startDate.toISOString()}/${endDate.toISOString()}/${
        loginUser.userId
      }`
    );
    // console.log("respEventData", respEventData.data);
    // console.log("respEventData", respEventData.data.data.data.items);
    // return;
    if (respEventData.data.returnValue !== "1") {
      var SCOPES = "https://www.googleapis.com/auth/calendar.events";
      const client = window.google.accounts.oauth2.initCodeClient({
        client_id:
          "806760841521-j7f6o10atmhib5da5q498pbkcoprtrgl.apps.googleusercontent.com",
        scope: SCOPES,
        ux_mode: "popup",
        callback: async (response) => {
          try {
            if (!response.code) {
              toast.error(t("something_went_wrong"));
              return;
            }
            const reqDto = {
              code: response.code,
            };
            const responseSaveToken = await axios.post(
              `${socketEndPoint}/api/gcal/storeRefreshToken/${loginUser.userId}`,
              reqDto
            );

            if (responseSaveToken.data.returnValue === "1") {
              toast.info(t("syncing_please_wait"));
              const respEventDataSynced = await axios.get(
                `${socketEndPoint}/api/gcal/getAllEvents/${startDate.toISOString()}/${endDate.toISOString()}/${
                  loginUser.userId
                }`
              );
              setIsGcalSynced(true);

              updateGoogleEvents(respEventDataSynced);
            } else {
              toast.error(t("something_went_wrong"));
            }
          } catch (error) {
            toast.error(t("something_went_wrong"));
            console.log(error);
          }
        },
      });
      client.requestCode();
    } else {
      toast.info(t("syncing_please_wait"));
      updateGoogleEvents(respEventData);
    }
  };

  const updateGoogleEvents = (respEventData) => {
    const gCalEventsData = respEventData.data.data.data.items;
    let gCalEvents = [];
    setGcalLastSynced(new Date());
    for (let index = 0; index < gCalEventsData.length; index++) {
      const gCalEventDto = gCalEventsData[index];
      const startTimeRounded = DateUtils.roundTimeToNearest15Minutes(
        new Date(gCalEventDto.start.dateTime)
      );
      const endTimeRounded = DateUtils.roundTimeToNearest15Minutes(
        new Date(gCalEventDto.end.dateTime)
      );
      if (!events.some((obj) => obj.eventId === gCalEventDto.id)) {
        let conferenceLink = gCalEventDto.htmlLink;

        if (
          gCalEventDto.conferenceData &&
          gCalEventDto.conferenceData.entryPoints &&
          gCalEventDto.conferenceData.entryPoints.length > 0
        ) {
          for (
            let index = 0;
            index < gCalEventDto.conferenceData.entryPoints.length;
            index++
          ) {
            const element = gCalEventDto.conferenceData.entryPoints[index];

            if (element.entryPointType === "video") {
              conferenceLink = element.uri;
            } else if (element.entryPointType === "more") {
              gCalEventDto.moreEntryPoint = element.uri;
            }
          }
        }

        let reqDto = {
          source: "google",
          eventId: gCalEventDto.id,
          title: gCalEventDto.summary,
          description: gCalEventDto.summary,
          length: 15,
          startTimeOrg: gCalEventDto.start.dateTime,
          endTimeOrg: gCalEventDto.end.dateTime,
          start: startTimeRounded,
          end: endTimeRounded,
          location: gCalEventDto.location,
          link: conferenceLink,
          createdByUserId: loginUser.userId,
          eventType: "Event",
          convener: "Y",
          chairperson: "N",
          calledBy: "N",
          futureEvent: "N",
          renderChairBy: null,
          showMOM: "N",
          webinarStreamKey: null,
          webinarSlug: null,
          sourceData: gCalEventDto,
        };
        gCalEvents.push(reqDto);
      }
    }

    console.log("gCalEvents", gCalEvents);
    setEvents((prevData) => prevData.concat(gCalEvents));
    toast.success(t("google_calendar_data_synced_successfully"));
  };

  return (
    <>
      <div
        className="SchduleRightFlotingContainerSettings"
        style={{ width: "30vw !important" }}
      >
        <div className="flotingAreaContainer">
          <div className="flotingAreaClose">
            <div className="facHeader">{t("calendar_settings")}</div>
            <IconButton className="CloseBtn" onClick={closeFromDashboard}>
              <XLg />
            </IconButton>
          </div>
          <div className={`right-panel`}>
            <div className="control-panel">
              <span
                className="googleCalendar"
                onClick={() => {
                  handleGoogle();
                }}
              >
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={isGcalSynced}
                  className="schedularSettingsInfo"
                >
                  <img
                    alt="gcal"
                    src={publicUrl + "/google-calendar.png"}
                    width={50}
                    height={50}
                  />
                </Badge>
                <div className="googleCalTxt">
                  <span>
                    {t("sync_with_google_calendar")}
                    {!isGcalSynced && t("re_sync_needed")}
                  </span>
                  {isGcalSynced && (
                    <span>
                      {t("last_synced")}
                      {DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                        gcalLastSynced
                      )}
                    </span>
                  )}
                </div>
              </span>

              <div className="col-row">
                {/* <div className="col-left">
                  <label>first Day_of_week</label>
                </div> */}
                <div className="col-right">
                  <div style={{ marginBottom: "10px" }}>
                    {t("first_day_of_the_week")}
                  </div>
                  <div>
                    <FormControl
                      variant="standard"
                      sx={{ m: 0, minWidth: 200 }}
                    >
                      <Select
                        value={weekStartDay}
                        onChange={handleChangeFirstDayWeek}
                        label={t("select_days")}
                        sx={{
                          color: "white !important",
                          backgroundColor: "#333",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        size="small"
                      >
                        {defaultWorkWeek.map((weekObj) => {
                          return (
                            <MenuItem value={weekObj.id}>
                              {t(weekObj.day)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-row">
                {/* <div className="col-left">
                  <label>{"work_week"}</label>
                </div> */}
                <div className="col-right">
                  <div style={{ marginBottom: "10px" }}>{t("work_week")}</div>
                  <div>
                    <FormControl variant="standard" sx={{ m: 0, width: 200 }}>
                      <Select
                        multiple
                        value={defaultValues}
                        onChange={handleChanged}
                        // renderValue={(selected) => selected.join(", ")}
                        renderValue={(selected) =>
                          selected.map((day) => t(day)).join(", ")
                        }
                        MenuProps={MenuProps}
                        label={t("work_week")}
                        sx={{
                          color: "white !important",
                          backgroundColor: "#333",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        size="small"
                      >
                        {sortedWeekDays.map((wekDay) => (
                          <MenuItem key={wekDay} value={wekDay}>
                            <Checkbox
                              checked={
                                defaultWorkWeek
                                  .map(({ day }) => t(day))
                                  .indexOf(wekDay) > -1
                              }
                            />
                            <ListItemText primary={t(wekDay)} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-row">
                {/* <div className="col-left">
                  <label>slot_duration</label>
                </div> */}
                <div className="col-right">
                  <div style={{ marginBottom: "10px" }}>{t("time_slot")}</div>
                  <div>
                    <FormControl
                      variant="standard"
                      sx={{ m: 0, minWidth: 200 }}
                    >
                      <Select
                        value={slotDuration}
                        onChange={handleChangeSlotDuration}
                        label={t("select_slot_duration")}
                        sx={{
                          color: "white !important",
                          backgroundColor: "#333",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        size="small"
                      >
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-row">
                {/* <div className="col-left">
                  <label>start_time</label>
                </div> */}
                <div className="col-right">
                  <div style={{ marginBottom: "10px" }}>{t("start_time")}</div>
                  <div>
                    <FormControl
                      variant="standard"
                      sx={{ m: 0, minWidth: 200 }}
                    >
                      <Select
                        value={startTime}
                        onChange={(event) => {
                          // console.log(event.target);
                          setStartTime(event.target.value);
                          setRows([
                            ...Array(
                              (endTime + 1 - event.target.value) *
                                (60 / slotDuration)
                            ).keys(),
                          ]);
                        }}
                        label={t("start_time")}
                        MenuProps={MenuProps}
                        sx={{
                          color: "white !important",
                          backgroundColor: "#333",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        size="small"
                      >
                        {startTimeList.map((startTimeDto) => {
                          if (
                            startTimeDto.value <
                              (endTime === 24 ? endTime - 1 : endTime) &&
                            startTimeDto.value <=
                              (minStartTimeEvent === 25 ? 0 : minStartTimeEvent)
                          ) {
                            return (
                              <MenuItem value={startTimeDto.value}>
                                {startTimeDto.label}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-row">
                {/* <div className="col-left">
                  <label>end_time</label>
                </div> */}
                <div className="col-right">
                  <div style={{ marginBottom: "10px" }}>{t("end_time")}</div>
                  <div>
                    <FormControl
                      variant="standard"
                      sx={{ m: 0, minWidth: 200 }}
                    >
                      <Select
                        value={endTime === 24 ? endTime - 1 : endTime}
                        onChange={(event) => {
                          // console.log(event.target);
                          setEndTime(event.target.value);
                          setRows([
                            ...Array(
                              (event.target.value + 1 - startTime) *
                                (60 / slotDuration)
                            ).keys(),
                          ]);
                        }}
                        label={t("end_time")}
                        MenuProps={MenuProps}
                        sx={{
                          color: "white !important",
                          backgroundColor: "#333",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        size="small"
                      >
                        {endTimeList.map((endTimeDto) => {
                          if (
                            endTimeDto.value > startTime &&
                            endTimeDto.value >=
                              (maxEndTimeEvent === -1 ? 0 : maxEndTimeEvent)
                          ) {
                            return (
                              <MenuItem value={endTimeDto.value}>
                                {endTimeDto.label}
                              </MenuItem>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  {/* <div onClick={() => handleGoogle()}>Google Calendar</div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="elementFormContainerAdditionalEvents">
            <div className="formElement">
              {additionalEventDetails.map((eventDto) => {
                return (
                  <Tooltip
                    title={`View details of ${eventDto.title} `}
                    // placement="right-end"
                  >
                    <div
                      className="additionalEvents myCalendar"
                      onClick={() => {
                        // closeFromDashboard();
                        eventDto.additional = true;
                        setSelectedEvent(eventDto);
                      }}
                    >
                      <div>
                        <div className="additionalEventsTitle">
                          {eventDto.title}
                        </div>
                        <div className="additionalEventsDate">
                          {DateUtils.displayDateMonthViewToolTip(eventDto)}
                        </div>
                      </div>
                      <div>
                        <IconButton className="deleteAdditionalEvent">
                          <ArrowForwardIos />
                        </IconButton>
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div> */}

          {/* <div className="elementFooter">
            <div className="formBtnElement">
              <Button className="dfultPrimaryBtn">Save</Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => closeFromDashboard()}
              >
                Cancel
              </Button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
