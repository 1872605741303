import React, { useRef, useState } from "react";
import "./css/privacy-policy.css";
import Logo from "./assets/tc.png";
import FooterLP from "../features/landingPage/view/component/FooterLP";
import NavBarLP from "../features/landingPage/view/component/NavBarLP";
import LoginRegister from "../features/landingPage/view/component/LoginRegister";
import { Trans, useTranslation } from "react-i18next";

export default function PrivacyPolicyZH() {
  const { t } = useTranslation();
  const [showLogReg, setShowLogReg] = useState(false);

  const handelCloseLogRegContain = () => {
    setShowLogReg(false);
  };

  const handelOpenLogRegContain = () => {
    setShowLogReg(true);
  };

  const scrollableNavRef = useRef(null);
  return (
    <>
      <div className="lp-full-container" ref={scrollableNavRef}>
        <NavBarLP
          handelOpenLogRegContain={handelOpenLogRegContain}
          scrollableNavRef={scrollableNavRef}
        />
        {showLogReg && (
          <LoginRegister handelCloseLogRegContain={handelCloseLogRegContain} />
        )}
        <>
          {/* ------------------------Start-------------------------- */}
          <div class="tcmaindiv">
            <div class="tcleft">
              <div class="tcimagearea">
                <img src={Logo} class="trmcndimage" alt="displayImage" />
              </div>
            </div>
            {/* pp_header_zh */}
            <div class="tcright">
              <div class="tctextarea">
                <div class="tcmainheading">
                  <h1 class="tcheading">
                    {t("privacy_incaps")}{" "}
                    <span class="cndtn">{t("policy_incaps_zh")}</span>
                  </h1>
                </div>

                <div class="tctext">
                  <p className="tcsubtext bold-privacy-policy">
                    <Trans i18nKey="privacy_policy_header_zh" />
                  </p>
                  <p className="tcsubtext">
                    <Trans i18nKey="privacy_policy_header_text_zh_p1" />
                    {
                      <span className="bold-privacy-policy">
                        {" "}
                        <Trans i18nKey="privacy_policy_header_text_zh_p2" />
                      </span>
                    }
                  </p>
                  <p className="tcsubtext bold-privacy-policy">
                    <Trans i18nKey="privacy_policy_header_last_updated_zh" />
                  </p>
                  <p className="tcsubtext bold-privacy-policy">
                    <Trans i18nKey="privacy_policy_header_overview_zh" />
                  </p>
                  <p className="tcsubtext">
                    <Trans
                      i18nKey="privacy_policy_zh"
                      components={[
                        <a
                          href="https://zoyel.health"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://zoyel.health
                        </a>,
                      ]}
                    />
                  </p>
                  <p className="tcsubtext">
                    <Trans i18nKey="privacy_policy_intro_zh" />
                  </p>
                  <p className="tcsubtext">
                    <Trans i18nKey="privacy_policy_usage_zh" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* pp_header_zh */}
          {/* pp_acceptance_header_zh */}
          <div class="tcsubtextarea">
            <div class="subheadingtc">
              <h2 class="tcsubheading">
                {" "}
                <Trans i18nKey="pp_acceptance_header_zh" />
              </h2>
            </div>

            <div class="subheadingtc">
              <p className="tcabout">
                {" "}
                <Trans i18nKey="pp_acceptance_header_zh_p1" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_acceptance_header_zh_p2" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_acceptance_header_zh_p3" />
              </p>
            </div>
            {/* pp_acceptance_header_zh */}
            {/* pp_information_collect_zh */}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                {" "}
                <Trans i18nKey="pp_information_collect_zh" />
              </h2>
            </div>

            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_information_collect_zh_p1" />
              </p>
              <p className="tcabout">
                {" "}
                <Trans i18nKey="pp_information_collect_zh_p2" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_information_collect_zh_p3" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_information_collect_zh_p4" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_information_collect_zh_p5" />
              </p>
            </div>
            {/* pp_information_collect_zh */}

            {/* pp_use_information_zh */}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_use_information_zh" />
              </h2>
            </div>

            <div class="subheadingtc">
              <p className="tcabout">
                {" "}
                <Trans i18nKey="pp_use_information_zh_p1" />
              </p>
              <div class="tclinetextarea">
                <ul class="tcul">
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_use_information_zh_p2_1" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_use_information_zh_p2_2" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_use_information_zh_p2_3" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_use_information_zh_p2_4" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_use_information_zh_p2_5" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_use_information_zh_p2_6" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_use_information_zh_p2_7" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_use_information_zh_p2_8" />
                    </p>
                  </li>
                </ul>
              </div>
              <p className="tcabout">
                <Trans i18nKey="pp_use_information_zh_p3" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_use_information_zh_p4" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_use_information_zh_p5" />
              </p>
              <p className="tcabout bold-privacy-policy">
                <Trans i18nKey="pp_use_information_zh_p6" />
              </p>
              <p className="tcabout bold-privacy-policy">
                <Trans i18nKey="pp_use_information_zh_p7" />
              </p>
            </div>
            {/* pp_use_information_zh */}

            {/* pp_communicate_with_you_zh */}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_communicate_with_you_zh" />
              </h2>
            </div>

            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_communicate_with_you_zh_p1" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_communicate_with_you_zh_p2" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_communicate_with_you_zh_p3" />
              </p>
              <p className="tcabout">
                <Trans i18nKey="pp_communicate_with_you_zh_p4" />
              </p>
            </div>
            {/* pp_communicate_with_you_zh */}

            {/*  The Information We Disclose to Outside Parties*/}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_information_we_disclose" />
              </h2>
            </div>

            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_information_we_disclose_p1" />
              </p>
              {/* <p className="tcabout">
                <Trans i18nKey="pp_information_we_disclose_p2" />
              </p> */}
              <div class="tclinetextarea">
                <ul class="tcul">
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p2_1" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p2_2" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p2_3" />
                    </p>
                  </li>
                </ul>
              </div>
              <p className="tcabout">
                <Trans i18nKey="pp_information_we_disclose_p3" />
              </p>
              {/* <p className="tcabout">
                <Trans i18nKey="pp_information_we_disclose_p4" />
              </p> */}
              <div class="tclinetextarea">
                <ul class="tcul">
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_1" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_2" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_3" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_4" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_5" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_6" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_7" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_8" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_9" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_10" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_11" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_12" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_13" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_14" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_15" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_information_we_disclose_p4_16" />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            {/* The Information We Disclose to Outside Parties */}
            {/* pp_exercise_your_rights */}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_exercise_your_rights" />
              </h2>
            </div>
            {/* pp_exercise_your_rights */}
            {/*  pp_opt_out_choices*/}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_opt_out_choices" />
              </h2>
            </div>
            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_opt_out_choices_p1" />
              </p>
              <div class="tclinetextarea">
                <ul class="tcul">
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_opt_out_choices_p2_1" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_opt_out_choices_p2_2" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_opt_out_choices_p2_3" />
                    </p>
                  </li>
                </ul>
              </div>
              <p className="tcabout">
                <Trans i18nKey="pp_opt_out_choices_p3" />
              </p>
            </div>
            {/*  pp_opt_out_choices*/}
            {/* pp_safeguarding_personal_info_zh */}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_safeguarding_personal_info_zh" />
              </h2>
            </div>
            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_safeguarding_personal_info_zh_p1" />
              </p>
            </div>
            {/*  pp_safeguarding_personal_info_zh*/}
            {/* pp_privacy_safeguarding_links_zh */}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_privacy_safeguarding_links_zh" />
              </h2>
            </div>
            <div class="subheadingtc">
              <p className="tcabout">
                <Trans
                  i18nKey="pp_privacy_safeguarding_links_zh_p1"
                  components={[
                    <a
                      href="https://zoyel.health"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://zoyel.health
                    </a>,
                  ]}
                />
              </p>
            </div>
            {/*  pp_privacy_safeguarding_links_zh*/}
            {/* pp_exclusions_zh */}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_exclusions_zh" />
              </h2>
            </div>
            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_exclusions_zh_p1" />
              </p>
            </div>
            {/* pp_exclusions_zh */}
            {/* pp_children_privacy_zh */}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_children_privacy_zh" />
              </h2>
            </div>
            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_children_privacy_zh_p1" />
              </p>
            </div>
            {/* pp_children_privacy_zh */}

            {/*  pp_retention_personal_info*/}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_retention_personal_info" />
              </h2>
            </div>
            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_retention_personal_info_p1" />
              </p>
              <div class="tclinetextarea">
                <ul class="tcul">
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_retention_personal_info_p2_1" />
                    </p>
                  </li>
                  <li>
                    <p className="tcabout">
                      <Trans i18nKey="pp_retention_personal_info_p2_2" />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            {/*  pp_retention_personal_info*/}

            {/* pp_children_privacy_zh */}
            <div class="subheadingtc">
              <h2 class="tcsubheading ">
                <Trans i18nKey="pp_questions_feedback_zh" />
              </h2>
            </div>
            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_questions_feedback_zh_p1" />
              </p>
            </div>
            <div class="subheadingtc">
              <p className="tcabout">
                <Trans i18nKey="pp_questions_feedback_zh_p2" />
              </p>
            </div>
            {/* pp_children_privacy_zh */}

            {/* ------------------------END-------------------------- */}
          </div>
        </>
        <FooterLP />
      </div>
    </>
  );
}
