import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import { centerSocket, doctorSocket } from "../../../../../socket";
import { useTranslation } from "react-i18next";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import zhPatientService from "../../../../../services/ZhPatientService";
import SecureIndexedDB from "../../../../../utils/IndexedDB";

export const SelectOnlineDoctor = ({ closeModal, visitData }) => {
  const { t } = useTranslation();
  const loginUserDetail = GetLoginUserDetails();
  console.log("visitData", visitData);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [unitAllDoctorList, setUnitAllDoctorList] = useState([]);

  const getAvailableDoctorsofUnit = async () => {
    const diseasetring = await SecureIndexedDB.getItem("unitDoctorList");
    if (diseasetring) {
      setUnitAllDoctorList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getAvailableDoctorsofUnit(
      loginUserDetail.signleUnitId
    ).then((response) => {
      if (response.data) {
        setUnitAllDoctorList(response.data);
        SecureIndexedDB.setItem(
          "unitDoctorList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  useEffect(() => {
    getAvailableDoctorsofUnit();
  }, []);

  useEffect(() => {
    if (
      centerSocket != null &&
      unitAllDoctorList &&
      unitAllDoctorList.length > 0
    ) {
      centerSocket.emit("requestOnlineDoctor", {
        orgId: loginUserDetail.orgId,
      });
      centerSocket.on("responseOnlineDoctor", (doctorIdList) => {
        console.log("responseOnlineDoctor", doctorIdList);
        if (!doctorIdList || doctorIdList.length <= 0) {
          toast.error(t("no_doctor_is_online"));
          setDoctorList([]);
          return;
        }
        let tempResponse = null;
        DashboardServices.getDoctorsWithQueue(doctorIdList).then((response) => {
          const tempOnlineDoctor = [];
          tempResponse = response.data;
          unitAllDoctorList.forEach((item) => {
            const doctorQueLength = tempResponse[item.doctorId];
            let doesExist = doctorIdList.includes(item.doctorId);

            if (doctorQueLength) {
              tempOnlineDoctor.push({ ...item, queueCount: doctorQueLength });
            } else if (!doctorQueLength && doesExist) {
              tempOnlineDoctor.push({ ...item, queueCount: 0 });
            }
          });

          if (tempOnlineDoctor.length === 0) {
            toast.error(
              t(
                "unable_to_process_switch_request_as_no_doctors_of_the_same_specialty_are_currently_online_please_try_again_later"
              )
            );
            closeModal();
          }

          setDoctorList(tempOnlineDoctor);
        });
      });
    }

    return () => {
      centerSocket.off("responseOnlineDoctor");
    };
  }, [centerSocket, unitAllDoctorList]);

  if (doctorList?.length > 0) {
    return (
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("select_doctor")}</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="adduser">Add User</InputLabel> */}
                <Autocomplete
                  freeSolo
                  className="modelformAutocompleteField"
                  variant="outlined"
                  options={doctorList}
                  value={selectedDoctor}
                  onChange={(e, newValue) => {
                    setSelectedDoctor(newValue);
                  }}
                  getOptionLabel={(option) => option.doctorName || ""}
                  renderOption={(props, option) => (
                    <div
                      {...props}
                      style={{ padding: "4px 12px" }}
                      className="sDocOptList"
                    >
                      <div className="sDname">{option.doctorName}</div>
                      <div className="sDElm">{option.specializationName}</div>
                      <div className="sDElmGrp">
                        <div className="sDElm">
                          {t("experience")}: <span>{option.experience}</span>
                        </div>
                        <div className="sDElm">
                          {t("fees")}:
                          <span>
                            {option.fees} {option.feesCurrency}
                          </span>
                        </div>
                        <div className="sDElm">
                          {t("language")} : <span>{option.languages}</span>
                        </div>
                        <div className="sDElm">
                          {t("patient_in_queue")}:{" "}
                          <span>{option.queueCount}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("search_doctor")} *`}
                      className="formAutoComInputField autocompFildPlaceholder"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                console.log(selectedDoctor);
                if (!selectedDoctor) {
                  toast.error(t("please_select_doctor"));
                  return;
                }
                const dto = {
                  doctorId: selectedDoctor?.doctorId || "ND",
                  avgConsultTime: selectedDoctor?.avgConsultTime || 0,
                  doctorName: selectedDoctor?.doctorName || "",
                  doctorSpecilization: selectedDoctor?.specializationName || "",
                };
                // return;
                zhPatientService
                  .assignDoctorToNDQueue(visitData.visitId, dto)
                  .then((response) => {
                    if (response.data == 0) {
                      toast.error(t("something_went_wrong_please_try_again"));
                    } else {
                      toast.success(t("doctor_assigned_successfully"));
                      closeModal();
                    }
                  });
              }}
            >
              {t("submit")}
            </Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                closeModal();
              }}
            >
              {t("close")}
            </Button>
          </div>
        </div>
      </Box>
    );
  } else {
    return <></>;
  }
};
