import * as React from "react";
// import { isMobile } from "react-device-detect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, IconButton, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { apiEndPoint } from "../../../../../../constants/url_provider";
import { Share } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
// import { apiEndPoint } from "../../http-common";

export default function ShareOptions(props) {
  const { t } = useTranslation();
  let roomNumber = props.roomNumber;
  const [openSharePopper, setOpenSharePopper] = React.useState(false);
  //const [anchorEl, setAnchorEl] = React.useState(null);

  const showShareOptions = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSharePopper(!openSharePopper);
  };
  const canBeOpenPopperShare = openSharePopper && Boolean(anchorEl);
  const id = canBeOpenPopperShare ? "transition-popper" : undefined;

  const handleShare = (e) => {
    e.preventDefault();
    const ahref = `${apiEndPoint}/join/` + roomNumber;

    const encodedAhref = encodeURIComponent(ahref);
    var link;

    switch (e.currentTarget.id) {
      case "gmail":
        let subject = "Zoyel One Meeting Invite";
        let messageBody = `You have been invited to a meeting,%0APlease click the link below to join%0A${encodedAhref}`;
        link = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${subject}&body=${messageBody}&ui=2&tf=1&pli=1`;
        //setOpenSharePopper(!openSharePopper);
        openLink(link);
        setAnchorEl(null);
        break;

      case "facebook":
        link = `https://www.facebook.com/sharer/sharer.php?u=${ahref}`;
        //setOpenSharePopper(!openSharePopper);
        openLink(link);
        setAnchorEl(null);
        break;

      case "twitter":
        link = `https://twitter.com/intent/tweet?url=${encodedAhref}`;
        // setOpenSharePopper(!openSharePopper);
        openLink(link);
        break;

      case "reddit":
        link = `https://www.reddit.com/submit?url=${encodedAhref}`;
        // setOpenSharePopper(!openSharePopper);
        setAnchorEl(null);
        openLink(link);
        break;

      case "whatsapp":
        if (false) {
          link = `whatsapp://send?text=${encodedAhref}`;
        } else {
          link = `https://api.whatsapp.com/send?text=${encodedAhref}`;
        }
        setAnchorEl(null);
        //  setOpenSharePopper(!openSharePopper);
        openLink(link);
        break;

      case "copy":
        navigator.clipboard.writeText(ahref);
        setAnchorEl(null);
        // setOpenSharePopper(!openSharePopper);
        break;
      case "copyRoomNumber":
        navigator.clipboard.writeText(roomNumber);
        setAnchorEl(null);
        // setOpenSharePopper(!openSharePopper);
        break;

      default:
        break;
    }
  };

  const openLink = (socialLink) => {
    window.open(socialLink, "_blank");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      {/* <Button
        className="meetingMenuBtn"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Tooltip title={"Share Link"}>
          <ShareOutlinedIcon />
        </Tooltip>
      </Button> */}
      <Tooltip title={t("share_link")} arrow>
        <IconButton
          aria-label="Share"
          size="large"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Share />
        </IconButton>
      </Tooltip>
      <Menu
        id="end-call-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem id="gmail" onClick={handleShare}>
        {t("gmail")}
        </MenuItem>
        <MenuItem id="facebook" onClick={handleShare}>
        {t("facebook")}
        </MenuItem>
        <MenuItem id="twitter" onClick={handleShare}>
        {t("twitter")}
        </MenuItem>
        <MenuItem id="reddit" onClick={handleShare}>
        {t("reddit")}
        </MenuItem>
        <MenuItem id="whatsapp" onClick={handleShare}>
        {t("whatsapp")}
        </MenuItem>
        <MenuItem id="copy" onClick={handleShare}>
        {t("copy_link")}
        </MenuItem>
        <MenuItem id="copyRoomNumber" onClick={handleShare}>
        {t("copy_room_number")}
        </MenuItem>
      </Menu>

      {/* <Button aria-describedby={id} onClick={showShareOptions} >
             <ShareRoundedIcon  />
          </Button>
          
         <Popper id={id} open={openSharePopper} anchorEl={anchorEl} transition onMo>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                    <List dense={true}>
                    <ListItem
                        button
                        style={{ paddingTop: ".75em" }}
                        id="gmail"
                        onClick={handleShare}
                      >
                        <ListItemIcon>
                          <AlternateEmailIcon />
                        </ListItemIcon>
                        <ListItemText primary="GMail" />
                      </ListItem>
                      <ListItem
                        button
                        style={{ paddingTop: ".75em" }}
                        id="facebook"
                        onClick={handleShare}
                      >
                        <ListItemIcon>
                          <FacebookIcon />
                        </ListItemIcon>
                        <ListItemText primary="Facebook" />
                      </ListItem>
                      <ListItem
                        button
                        style={{ paddingTop: ".75em" }}
                        id="twitter"
                        onClick={handleShare}
                      >
                        <ListItemIcon>
                          <TwitterIcon />
                        </ListItemIcon>
                        <ListItemText primary="Twitter" />
                      </ListItem>
                      <ListItem
                        button
                        style={{ paddingTop: ".75em" }}
                        id="reddit"
                        onClick={handleShare}
                      >
                        <ListItemIcon>
                          <RedditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Reddit" />
                      </ListItem>

                      <ListItem
                        button
                        style={{ paddingTop: ".75em" }}
                        id="whatsapp"
                        onClick={handleShare}
                      >
                        <ListItemIcon>
                          <WhatsAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Whatsapp" />
                      </ListItem>


                      <ListItem
                        button
                        style={{ paddingTop: ".75em" }}
                        id="copy"
                        onClick={handleShare}
                      >
                        <ListItemIcon>
                          <LinkIcon />
                        </ListItemIcon>
                        <ListItemText primary="Copy Link" />
                      </ListItem>
                      
                    </List>
                  </Paper>
            </Fade>
          )}
        </Popper> */}
    </ThemeProvider>
  );
}
