import { Container, Grid, Tooltip } from "@mui/material";
import { React, useState, useEffect, useRef } from "react";

import OmniPresence from "../../images/features/OmniPresenceH.jpg";
import Organization from "../../images/features/OrganizationH.jpg";
import TaskManagement from "../../images/features/TaskManagementH.jpg";
import Productivity from "../../images/features/ProductivityH.jpg";
import Compliances from "../../images/features/CompliancesH.jpg";
import Collaboration from "../../images/features/CollaborationH.jpg";
import Security from "../../images/features/SecurityH.jpg";
import TimeSpaceLanguage from "../../images/features/TimeSpaceLanguageH.jpg";
import Meetings from "../../images/features/MeetingsH.jpg";
import completeWorkflows from "../../images/features/CompleteWorkflowsH.jpg";
import KillWastage from "../../images/features/KillWastageH.jpg";
import Analytics from "../../images/features/AnalyticsH.jpg";
import Healthcare from "../../images/features/healthcareH.jpg";

export default function FeaturesPage() {
  const [activeSection, setActiveSection] = useState("sec1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const sections = [
      "sec1",
      "sec2",
      "sec3",
      "sec4",
      "sec5",
      "sec6",
      "sec7",
      "sec8",
      "sec9",
      "sec10",
      "sec11",
      "sec12",
      "sec13",
    ];

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
          console.log("Active Section: ", entry.target.id);
        }
      });
    }, options);

    sections.forEach((sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sections.forEach((sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  const [isSticky, setIsSticky] = useState(false);
  const featuresListRef = useRef(null); // Reference to the Features List div

  useEffect(() => {
    const handleScrollFixedTop = () => {
      if (featuresListRef.current) {
        const rect = featuresListRef.current.getBoundingClientRect();

        // Add class when the top of the Features List reaches the top of the viewport
        if (rect.top <= 0 && !isSticky) {
          setIsSticky(true);
        }
        // Remove class when the Features List is scrolled back below the viewport top
        else if (rect.top > 0 && isSticky) {
          setIsSticky(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScrollFixedTop);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScrollFixedTop);
    };
  }, [isSticky]);

  return (
    <>
      <section className="featuresLandingPage">
        <div className="diagonal-background"></div>
        <Container maxWidth="md" className="digContnr">
          <div className="zoyelHeadingContain">
            <div className="fetursHeading">
              Zoyel - Join the Zoyel Network today and effortlessly streamline
              your business operations for enhanced productivity and growth.
            </div>

            <div className="fetursSubtxt">
              At Zoyel, we understand the challenges that businesses face when
              managing day-to-day operations. That's why we've built the Zoyel
              Network—a comprehensive platform designed to simplify and optimize
              every aspect of your business. Whether you're a startup looking to
              scale or an established company aiming to increase efficiency, our
              network provides the tools and resources you need to succeed. With
              Zoyel, you gain access to cutting-edge technology that automates
              repetitive tasks, improves collaboration among teams, and enhances
              customer engagement. Our platform is intuitive and easy to
              integrate with your existing systems, so you can quickly start
              benefiting from its powerful features without disrupting your
              current processes. By joining the Zoyel Network, you'll not only
              streamline your workflows but also reduce costs, improve time
              management, and drive innovation. Whether it's project management,
              communication tools, or data analytics, Zoyel offers a solution
              that fits your needs. Let us help you focus on what matters
              most—growing your business.
            </div>
          </div>
        </Container>
      </section>
      <section className="featuresDetailsPage">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="zIndex10">
            <div className="zoyelFeatureList stickyLeft " ref={featuresListRef}>
              <ul className="zoyelFeatureListDesktopView">
                <li className={activeSection === "sec1" ? "active" : ""}>
                  <a href="#sec1" onClick={(e) => handleScroll(e, "sec1")}>
                    Omni Presence
                  </a>
                </li>
                <li className={activeSection === "sec2" ? "active" : ""}>
                  <a href="#sec2" onClick={(e) => handleScroll(e, "sec2")}>
                    Organization
                  </a>
                </li>
                <li className={activeSection === "sec3" ? "active" : ""}>
                  <a href="#sec3" onClick={(e) => handleScroll(e, "sec3")}>
                    Task Management
                  </a>
                </li>
                <li className={activeSection === "sec4" ? "active" : ""}>
                  <a href="#sec4" onClick={(e) => handleScroll(e, "sec4")}>
                    Productivity
                  </a>
                </li>
                <li className={activeSection === "sec5" ? "active" : ""}>
                  <a href="#sec5" onClick={(e) => handleScroll(e, "sec5")}>
                    Compliances
                  </a>
                </li>
                <li className={activeSection === "sec6" ? "active" : ""}>
                  <a href="#sec6" onClick={(e) => handleScroll(e, "sec6")}>
                    Collaboration
                  </a>
                </li>
                <li className={activeSection === "sec7" ? "active" : ""}>
                  <a href="#sec7" onClick={(e) => handleScroll(e, "sec7")}>
                    Security
                  </a>
                </li>
                <li className={activeSection === "sec8" ? "active" : ""}>
                  <a href="#sec8" onClick={(e) => handleScroll(e, "sec8")}>
                    Time, space & language
                  </a>
                </li>
                <li className={activeSection === "sec9" ? "active" : ""}>
                  <a href="#sec9" onClick={(e) => handleScroll(e, "sec9")}>
                    Meetings
                  </a>
                </li>
                <li className={activeSection === "sec10" ? "active" : ""}>
                  <a href="#sec10" onClick={(e) => handleScroll(e, "sec10")}>
                    Complete workflows
                  </a>
                </li>
                <li className={activeSection === "sec11" ? "active" : ""}>
                  <a href="#sec11" onClick={(e) => handleScroll(e, "sec11")}>
                    Kill wastage
                  </a>
                </li>
                <li className={activeSection === "sec12" ? "active" : ""}>
                  <a href="#sec12" onClick={(e) => handleScroll(e, "sec12")}>
                    Analytics
                  </a>
                </li>
                <li className={activeSection === "sec13" ? "active" : ""}>
                  <a href="#sec13" onClick={(e) => handleScroll(e, "sec13")}>
                    Zoyel Health
                  </a>
                </li>
              </ul>
              <div className={`mobile-menu ${isSticky ? "fixedOnTop" : ""}`}>
                {/* Hamburger Button */}
                <button className="fetures-menu-toggle" onClick={toggleMenu}>
                  ☰ {/* You can use an icon library or keep this simple */}
                </button>
                <div className="feturesMenuTxt">Features List</div>

                {isMenuOpen && (
                  <ul className="mob-menu-list">
                    <li className={activeSection === "sec1" ? "active" : ""}>
                      <a
                        href="#sec1"
                        onClick={(e) => {
                          handleScroll(e, "sec1");
                          setIsMenuOpen(false);
                        }}
                      >
                        Omni Presence
                      </a>
                    </li>
                    <li className={activeSection === "sec2" ? "active" : ""}>
                      <a
                        href="#sec2"
                        onClick={(e) => {
                          handleScroll(e, "sec2");
                          setIsMenuOpen(false);
                        }}
                      >
                        Organization
                      </a>
                    </li>
                    <li className={activeSection === "sec3" ? "active" : ""}>
                      <a
                        href="#sec3"
                        onClick={(e) => {
                          handleScroll(e, "sec3");
                          setIsMenuOpen(false);
                        }}
                      >
                        Task Management
                      </a>
                    </li>
                    <li className={activeSection === "sec4" ? "active" : ""}>
                      <a
                        href="#sec4"
                        onClick={(e) => {
                          handleScroll(e, "sec4");
                          setIsMenuOpen(false);
                        }}
                      >
                        Productivity
                      </a>
                    </li>
                    <li className={activeSection === "sec5" ? "active" : ""}>
                      <a
                        href="#sec5"
                        onClick={(e) => {
                          handleScroll(e, "sec5");
                          setIsMenuOpen(false);
                        }}
                      >
                        Compliances
                      </a>
                    </li>
                    <li className={activeSection === "sec6" ? "active" : ""}>
                      <a
                        href="#sec6"
                        onClick={(e) => {
                          handleScroll(e, "sec6");
                          setIsMenuOpen(false);
                        }}
                      >
                        Collaboration
                      </a>
                    </li>
                    <li className={activeSection === "sec7" ? "active" : ""}>
                      <a
                        href="#sec7"
                        onClick={(e) => {
                          handleScroll(e, "sec7");
                          setIsMenuOpen(false);
                        }}
                      >
                        Security
                      </a>
                    </li>
                    <li className={activeSection === "sec8" ? "active" : ""}>
                      <a
                        href="#sec8"
                        onClick={(e) => {
                          handleScroll(e, "sec8");
                          setIsMenuOpen(false);
                        }}
                      >
                        Time, space & language
                      </a>
                    </li>
                    <li className={activeSection === "sec9" ? "active" : ""}>
                      <a
                        href="#sec9"
                        onClick={(e) => {
                          handleScroll(e, "sec9");
                          setIsMenuOpen(false);
                        }}
                      >
                        Meetings
                      </a>
                    </li>
                    <li className={activeSection === "sec10" ? "active" : ""}>
                      <a
                        href="#sec10"
                        onClick={(e) => {
                          handleScroll(e, "sec10");
                          setIsMenuOpen(false);
                        }}
                      >
                        Complete workflows
                      </a>
                    </li>
                    <li className={activeSection === "sec11" ? "active" : ""}>
                      <a
                        href="#sec11"
                        onClick={(e) => {
                          handleScroll(e, "sec11");
                          setIsMenuOpen(false);
                        }}
                      >
                        Kill wastage
                      </a>
                    </li>
                    <li className={activeSection === "sec12" ? "active" : ""}>
                      <a
                        href="#sec12"
                        onClick={(e) => {
                          handleScroll(e, "sec12");
                          setIsMenuOpen(false);
                        }}
                      >
                        Analytics
                      </a>
                    </li>
                    <li className={activeSection === "sec13" ? "active" : ""}>
                      <a
                        href="#sec13"
                        onClick={(e) => {
                          handleScroll(e, "sec13");
                          setIsMenuOpen(false);
                        }}
                      >
                        Zoyel Health
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="featuresDetailsSect" id="sec1">
              <div className="fetContainDtl">
                <div className="fethead">
                  Omni Presence:
                  <span> Simplify Your Web Presence in Just a Few Clicks</span>
                </div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      In today's digital world, having a robust web presence is
                      essential for individuals and businesses alike. However,
                      managing domains, web hosting, and IT infrastructure can
                      be overwhelming and time-consuming. Omni Presence is here
                      to change that.
                    </div>
                    <div className="fetText">
                      With Omni Presence, you can create and manage your entire
                      web presence effortlessly, without the need for complex
                      technical knowledge. There's no need to worry about
                      purchasing domains or setting up hosting servers. Simply
                      choose your preferred design, customize your content, and
                      you're live online in just a few clicks. Our platform
                      eliminates the hassle of traditional web development,
                      empowering you to focus on what truly matters—your content
                      and audience.
                    </div>
                    <div className="fetText">
                      Whether you're a small business, an entrepreneur, or an
                      influencer, Omni Presence provides all the tools you need
                      to succeed online. Save time, reduce costs, and streamline
                      your digital journey with our all-in-one solution. From
                      design templates to built-in SEO optimization, we've got
                      everything covered.
                    </div>
                    <div className="fetText">
                      Create your web presence the smart way—fast, simple, and
                      without the tech headaches. Start with Omni Presence today
                      and unlock your digital potential.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={OmniPresence} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec2">
              <div className="fetContainDtl">
                <div className="fethead">Organization:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      To establish a successful organization, we will create a
                      dynamic structure centered around our mission to deliver
                      innovative solutions. Our organization will consist of
                      five key departments: Operations, Human Resources,
                      Marketing, Finance, and IT.
                    </div>
                    <div className="fetText">
                      The <strong>Operations Department</strong>, led by the
                      Operations Manager, will oversee production and ensure
                      efficiency in service delivery. Human Resources, headed by
                      the HR Director, will manage recruitment, employee
                      relations, and training programs to foster a positive
                      workplace culture.
                    </div>
                    <div className="fetText">
                      The Marketing Department, under the leadership of the
                      Marketing Director, will develop strategies to enhance
                      brand awareness and drive customer engagement through
                      various channels. The Finance Department, led by the Chief
                      Financial Officer, will handle budgeting, financial
                      reporting, and risk management to ensure financial health.
                    </div>
                    <div className="fetText">
                      Finally, the IT Department, managed by the IT Director,
                      will maintain the organization's technology infrastructure
                      and support digital initiatives. Each department will be
                      staffed with skilled professionals, and clear lines of
                      communication will be established for efficient
                      collaboration.
                    </div>
                    <div className="fetText">
                      With roles defined and departments in place, we will
                      implement onboarding processes and training sessions to
                      prepare our team. Once operational systems are finalized,
                      we will go live, focusing on delivering exceptional value
                      to our clients while fostering continuous improvement
                      within the organization.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={Organization} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec3">
              <div className="fetContainDtl">
                <div className="fethead">Task Management:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Efficient task management empowers teams to easily create,
                      assign, and monitor tasks, reducing unnecessary
                      administrative burdens and streamlining workflows. By
                      simplifying task creation, team members can quickly define
                      what needs to be done, set deadlines, and prioritize their
                      workload without wasting time on lengthy processes.
                    </div>
                    <div className="fetText">
                      Monitoring tasks becomes straightforward with real-time
                      tracking features that provide visibility into progress
                      and status updates. This eliminates the need for frequent
                      check-ins, allowing managers and team members to stay
                      informed without micromanaging. Automated reminders and
                      notifications further reduce manual oversight, ensuring
                      that tasks are completed on schedule.
                    </div>
                    <div className="fetText">
                      The removal of management overheads enables teams to focus
                      on actual execution, driving productivity. Task management
                      tools not only help organize work but also foster better
                      collaboration, as everyone has access to updated
                      information and can communicate efficiently within the
                      platform.
                    </div>
                    <div className="fetText">
                      Additionally, task management systems offer valuable
                      insights through reporting and analytics, helping teams
                      identify bottlenecks, measure performance, and optimize
                      future projects. By removing complexities, task management
                      facilitates smoother project execution, higher
                      productivity, and more effective resource utilization,
                      contributing to overall organizational success.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={TaskManagement} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec4">
              <div className="fetContainDtl">
                <div className="fethead">Productivity:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Boosting productivity is easier with an integrated suite
                      of tools designed to handle all your business needs. From
                      word processors to spreadsheets and presentation software,
                      everything is built into one seamless platform. These
                      tools allow you to create, edit, and manage documents,
                      analyze data, and design engaging presentations without
                      the need to switch between multiple applications.
                    </div>
                    <div className="fetText">
                      With the inclusion of cloud-based storage through a drive
                      system, you can access and collaborate on files from
                      anywhere. Real-time editing and sharing capabilities
                      enhance teamwork by allowing multiple users to work on the
                      same document simultaneously, ensuring faster and more
                      efficient project completion.
                    </div>
                    <div className="fetText">
                      Pre-built business templates for reports, financial
                      analyses, presentations, and other tasks save time and
                      standardize processes, ensuring professional results every
                      time. These templates provide a structured starting point,
                      allowing users to focus on content creation rather than
                      formatting.
                    </div>
                    <div className="fetText">
                      By bringing all productivity tools together in one place,
                      organizations can simplify workflows, improve
                      collaboration, and streamline document management. This
                      leads to enhanced efficiency, reduced operational
                      friction, and a more focused approach to achieving
                      business goals.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={Productivity} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect col21" id="sec5">
              <div className="fetContainDtl">
                <div className="fethead">Compliances:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Managing corporate compliance is streamlined with a robust
                      solution that supports holding Board meetings, Annual
                      General Meetings (AGMs), and other governance activities.
                      With integrated features, you can issue meeting agendas,
                      conduct polls, and record discussions in real time,
                      ensuring that all legal and regulatory obligations are met
                      seamlessly.
                    </div>
                    <div className="fetText">
                      Setting up Board or AGM meetings is simple, with tools to
                      schedule and distribute agenda items to all participants
                      well in advance. During meetings, real-time polling allows
                      for swift decision-making, while voting results are
                      automatically recorded and stored for future reference.
                      This eliminates the need for manual documentation,
                      ensuring accurate and timely record-keeping.
                    </div>
                    <div className="fetText">
                      The platform also allows you to track compliance
                      requirements with ease, ensuring that your organization
                      stays up-to-date on legal obligations such as statutory
                      filings, regulatory deadlines, and corporate governance
                      practices. Automated reminders for key compliance dates
                      reduce the risk of missing important obligations.
                    </div>
                    <div className="fetText">
                      By centralizing these tasks, you can streamline governance
                      processes, minimize the risk of non-compliance, and ensure
                      that meetings and decisions are effectively documented.
                      This leads to enhanced transparency, better regulatory
                      oversight, and improved operational efficiency across the
                      organization.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={Compliances} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec6">
              <div className="fetContainDtl">
                <div className="fethead">Collaboration:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Our platform offers a complete collaboration suite,
                      eliminating the need to subscribe to external providers.
                      With all tools integrated into one ecosystem, teams can
                      seamlessly communicate, share files, and work together in
                      real time, boosting productivity and reducing operational
                      complexity.
                    </div>
                    <div className="fetText">
                      Key features include chat, video conferencing, and
                      document sharing, allowing team members to collaborate
                      from anywhere, on any device. Real-time co-editing and
                      commenting enable efficient teamwork on documents,
                      spreadsheets, and presentations, keeping everyone aligned
                      and on track.
                    </div>
                    <div className="fetText">
                      The platform also provides secure file storage, making it
                      easy to organize and share important resources with the
                      team. Automated version control ensures that you always
                      have access to the latest updates, preventing confusion or
                      duplication of efforts.
                    </div>
                    <div className="fetText">
                      By offering everything from communication to file sharing
                      in one place, the collaboration suite simplifies workflows
                      and fosters stronger teamwork, while reducing reliance on
                      multiple software subscriptions. This comprehensive
                      solution not only saves time and costs but also enhances
                      team cohesion and efficiency, enabling faster project
                      completion and better outcomes for your business.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={Collaboration} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec7">
              <div className="fetContainDtl">
                <div className="fethead">Security:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Our platform prioritizes data security with top-tier
                      network protection, encryption, and robust data safety
                      measures, ensuring that your sensitive information is
                      fully safeguarded. We implement best-in-class network
                      security protocols to protect against external threats,
                      such as cyberattacks, malware, and unauthorized access.
                    </div>
                    <div className="fetText">
                      Data is encrypted both in transit and at rest, ensuring
                      that any files, communications, or sensitive business
                      information are secure from prying eyes. With advanced
                      encryption algorithms, even if data is intercepted, it
                      remains inaccessible without the proper decryption keys.
                    </div>
                    <div className="fetText">
                      Additionally, our platform incorporates multi-factor
                      authentication (MFA) and role-based access controls to
                      provide an extra layer of security. Only authorized users
                      can access critical information, ensuring that sensitive
                      data stays in the right hands.
                    </div>
                    <div className="fetText">
                      Regular system updates, security patches, and continuous
                      monitoring protect against vulnerabilities, while audit
                      logs track all user activities to maintain transparency
                      and accountability. We adhere to industry-standard
                      compliance requirements to ensure your data meets all
                      necessary regulatory standards.
                    </div>
                    <div className="fetText">
                      By combining cutting-edge technology with comprehensive
                      data protection policies, we ensure the highest levels of
                      security, giving you peace of mind that your business
                      information is safe and secure at all times.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={Security} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec8">
              <div className="fetContainDtl">
                <div className="fethead">Time, Space & Language:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Our platform seamlessly supports global collaboration with
                      multi-language capabilities and time zone flexibility,
                      allowing teams, clients, and vendors to work together
                      across the world without barriers. No matter where your
                      colleagues or partners are located, communication and
                      project management remain smooth and efficient.
                    </div>
                    <div className="fetText">
                      With multi-language support, users can select their
                      preferred language, ensuring everyone can interact
                      comfortably in their native tongue. This fosters better
                      understanding, reduces errors, and strengthens
                      collaboration across diverse teams. The interface and
                      notifications adapt to local language settings, enhancing
                      usability and productivity.
                    </div>
                    <div className="fetText">
                      The platform also offers automatic time zone adjustments,
                      ensuring that meetings, deadlines, and project timelines
                      are aligned with global team members. Scheduling across
                      different time zones becomes effortless, as the system
                      automatically converts time stamps, so everyone sees event
                      details in their local time.
                    </div>
                    <div className="fetText">
                      Whether you're coordinating with international clients,
                      remote teams, or global vendors, our platform ensures that
                      work continues smoothly, regardless of time, space, or
                      language differences. This flexibility enhances
                      collaboration, improves communication, and helps your
                      organization thrive in the global marketplace.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={TimeSpaceLanguage} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec9">
              <div className="fetContainDtl">
                <div className="fethead">Meetings:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Our platform offers versatile meeting solutions,
                      supporting both synchronous and asynchronous meetings to
                      accommodate different work styles and time zones. Whether
                      you need a real-time discussion or prefer to collaborate
                      at your own pace, our platform provides the flexibility to
                      meet your needs.
                    </div>
                    <div className="fetText">
                      Scheduled meetings allow for organized discussions at
                      specific times, ideal for team updates, client
                      presentations, or project reviews. Instant meetings can be
                      set up on-the-fly for quick check-ins or impromptu
                      brainstorming sessions, ensuring that collaboration
                      remains fluid and efficient.
                    </div>
                    <div className="fetText">
                      For more private conversations, private meeting rooms
                      ensure confidentiality, offering secure spaces for
                      sensitive discussions. Whether it's one-on-one or with a
                      select group, these rooms are easy to create and control,
                      ensuring the right participants are always included.
                    </div>
                    <div className="fetText">
                      Our platform also supports webinars, providing a
                      professional and scalable solution for hosting large
                      events, training sessions, or presentations. With features
                      like screen sharing, Q&A tools, and audience polls, you
                      can engage with attendees seamlessly, whether it's a small
                      group or a large audience.
                    </div>
                    <div className="fetText">
                      By supporting both synchronous and asynchronous formats,
                      our platform ensures that meetings are tailored to your
                      team's needs, fostering better communication and
                      collaboration across different contexts.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={Meetings} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec10">
              <div className="fetContainDtl">
                <div className="fethead">Complete Workflows:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Our platform provides complete, customizable workflows
                      designed to streamline operations across key departments,
                      including HR, Accounts, Finance, Business, Sales, and
                      Marketing. These workflows are tailored to meet the unique
                      needs of your organization, improving efficiency and
                      coordination across all functions.
                    </div>
                    <div className="fetText">
                      For HR, workflows manage everything from recruitment and
                      onboarding to performance evaluations and employee exit
                      processes, ensuring smooth transitions and compliance with
                      policies. In Accounts and Finance, workflows automate
                      tasks such as invoicing, expense management, payroll
                      processing, and financial reporting, reducing manual work
                      and minimizing errors.
                    </div>
                    <div className="fetText">
                      Business operations workflows enhance project management,
                      vendor coordination, and resource allocation, keeping
                      everything organized and aligned with your goals. In Sales
                      and Marketing, workflows enable lead tracking, customer
                      relationship management (CRM), campaign execution, and
                      performance analytics, helping teams close deals and
                      measure impact effectively.
                    </div>
                    <div className="fetText">
                      The platform allows you to customize workflows to fit your
                      organization's specific processes, ensuring that tasks are
                      assigned, monitored, and completed with optimal
                      efficiency. Automated notifications, task dependencies,
                      and role-based access control further enhance
                      collaboration and accountability across departments.
                    </div>
                    <div className="fetText">
                      With integrated workflows across all business areas, your
                      organization can operate more cohesively, reduce
                      redundancies, and achieve better results.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={completeWorkflows} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec11">
              <div className="fetContainDtl">
                <div className="fethead">Kill Wastage:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Eliminate inefficiencies and maximize productivity by
                      integrating task-linked meetings and result-based tasks
                      into your workflow. This approach ensures that every
                      meeting is purposeful and directly tied to actionable
                      outcomes, effectively stopping the drift often associated
                      with unstructured discussions.
                    </div>
                    <div className="fetText">
                      Task-linked meetings enable you to prepare agendas that
                      focus on specific tasks or projects. By linking
                      discussions directly to these tasks, participants can come
                      prepared with updates, insights, and solutions, leading to
                      more productive interactions. This targeted approach
                      reduces time spent on irrelevant topics and keeps the
                      meeting focused on achieving tangible results.
                    </div>
                    <div className="fetText">
                      Result-based tasks emphasize outcomes rather than just
                      activities. By defining clear goals for each task, teams
                      can concentrate on what truly matters. This clarity helps
                      in prioritizing work effectively, allowing for quicker
                      decision-making and execution. When everyone is aligned on
                      expected results, teams can work collaboratively towards
                      common objectives without wasting time on unnecessary
                      back-and-forth.
                    </div>
                    <div className="fetText">
                      By eliminating waste and streamlining processes, your
                      organization can save crucial time, enhance productivity,
                      and foster a culture of accountability and efficiency.
                      This focused approach leads to better project outcomes and
                      a more engaged workforce.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={KillWastage} alt="features" />
                  </div>
                </div>
              </div>
            </div>

            <div className="featuresDetailsSect" id="sec12">
              <div className="fetContainDtl">
                <div className="fethead">Analytics:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Gain full visibility into your organization's performance
                      with powerful analytics tools that allow you to monitor
                      operations and make data-driven decisions. With
                      comprehensive insights into every aspect of your business,
                      you can easily track progress, identify trends, and
                      optimize the allocation of resources, time, and money.
                    </div>
                    <div className="fetText">
                      Our platform's real-time analytics dashboard provides an
                      overview of key metrics across departments, enabling
                      managers to monitor what's happening in real-time. From
                      project statuses and team productivity to financial
                      performance and sales growth, the data is presented in a
                      clear, actionable format.
                    </div>
                    <div className="fetText">
                      By monitoring these metrics, you can quickly identify
                      inefficiencies, reallocate resources where they are needed
                      most, and adjust timelines or budgets based on actual
                      performance. The system also provides predictive insights,
                      helping you anticipate future challenges and proactively
                      plan for them.
                    </div>
                    <div className="fetText">
                      Through customizable reports, managers can generate
                      detailed analyses tailored to specific business needs,
                      ensuring that decision-making is based on the most
                      relevant and accurate information. This helps in
                      streamlining operations, optimizing budgets, and
                      ultimately driving better outcomes.
                    </div>
                    <div className="fetText">
                      With robust analytics, you can manage your organization
                      more effectively, ensuring that resources, time, and money
                      are allocated efficiently to support growth and success.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={Analytics} alt="features" />
                  </div>
                </div>
              </div>
            </div>
            <div className="featuresDetailsSect" id="sec13">
              <div className="fetContainDtl">
                <div className="fethead">Zoyel Health:</div>
                <div className="fetContDtl">
                  <div className="fetTextGroup">
                    <div className="fetText">
                      Zoyel Health is revolutionizing healthcare by making
                      quality medical care available remotely, giving patients
                      easy access to doctors from anywhere. Our goal is to
                      bridge gaps in healthcare access, offering expert
                      treatment and guidance virtually without the need to visit
                      a clinic. Through Zoyel Health, patients connect with
                      certified medical professionals from the comfort of home,
                      ensuring that every appointment is convenient, reliable,
                      and effective.
                    </div>
                    <div className="fetText">
                      The Zoyel Health platform allows patients to access
                      medical advice and treatment quickly and conveniently,
                      eliminating long wait times and travel requirements. This
                      remote model is ideal for patients with busy schedules,
                      limited mobility, or those living in rural areas. Our
                      telemedicine approach makes healthcare more accessible to
                      people with diverse needs, empowering them to take control
                      of their health with greater flexibility.
                    </div>
                    <div className="fetText">
                      We believe remote care can be just as effective as
                      traditional visits. Zoyel Health's secure, easy-to-use
                      technology enables doctors to monitor and treat patients
                      with personalized, proactive care. From consultations to
                      follow-up care, our approach ensures patients stay on top
                      of their health without the need for frequent in-person
                      visits.
                    </div>
                    <div className="fetText">
                      Our commitment to patients goes beyond convenience. Zoyel
                      Health emphasizes quality, trust, and continuity of care.
                      Our doctors build relationships with patients, creating
                      personalized plans tailored to individual health needs and
                      providing follow-up support as needed. Each consultation
                      is delivered with expertise and compassion.
                    </div>
                    <div className="fetText">
                      Join Zoyel Health as we reshape healthcare for the modern
                      world, bringing exceptional medical care directly to
                      patients. Your health is our priority, and our doctors are
                      here for you — anytime, anywhere.
                    </div>
                  </div>

                  <div className="fetContImg">
                    <img src={Healthcare} alt="features" />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
