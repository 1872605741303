import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import * as d3 from "d3";
import { jwtDecode } from "jwt-decode";
import DashboardServices from "../../../../../services/DashboardServices";
import UnitTooltip from "./UnitTooltip";
import { socketEndPoint } from "../../../../../constants/url_provider";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { useContext } from "react";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardManagementCenter() {
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const userDetails = GetLoginUserDetails();
  const [filterBy, setFilterBy] = useState("daily");
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const ref = useRef();
  const [centerData, setCenterData] = useState({});
  const [onlineUnitList, setOnlineUnitList] = useState([]);

  const handleChangefilterop = (event) => {
    setFilterBy(event.target.value);
  };

  useEffect(() => {
    fetchCenterData();
  }, []);

  const fetchCenterData = () => {
    const reqDto = {
      unitId: 0,
      orgId: userDetails.orgId,
      userId: "",
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
    };

    console.log("reqDto", reqDto);

    DashboardServices.refreshManagementUnits(reqDto).then((response) => {
      console.log("response", response.data);
      setCenterData(response.data);
    });
  };

  useEffect(() => {
    fetchOnlineUnit();
  }, [centerData]);

  const fetchOnlineUnit = async () => {
    try {
      const response = await axios.get(
        `${socketEndPoint}/api/health/getOnlineCenter`
      );
      console.log("response online unit", response);
      const data = response.data;
      const onlineUnitIds = Array.isArray(data) ? data : [];
      setOnlineUnitList(onlineUnitIds);
    } catch (error) {
      console.error("Error fetching online doctors:", error);
    }
  };

  useEffect(()=>{
    setBookSteps([
      {
        target: '.msUnit',
        content: 'This section displays card management Units.',
        disableBeacon: true,
      },
      {
        target: '.msUnitOne',
        content: 'Click here to refresh the unit data.',
        disableBeacon: true,
      },
      {
        target: '.msUnitTwo',
        content: 'This area visualizes the unit data geographically about consultations and diseases. The color indicates whether the unit is online (green) or offline (red).',
        disableBeacon: true,
      },
    ])
  },[])

  const checkOnlineStatusOfUnit = (unitId) => {
    if (unitId && onlineUnitList.includes(unitId)) {
      return "#32ff00";
    } else {
      return "#eb2b2b";
    }
  };

  let avgConsultations =
    centerData?.mdUnits?.map((unit) => unit.avgDailyConsultation) || [];

  const minOpacity = 0.3;
  const maxOpacity = 1;

  const minConsultation = Math.min(...avgConsultations);
  const maxConsultation = Math.max(...avgConsultations);

  function calculateOpacity(consultation) {
    const opacity =
      minOpacity +
      ((consultation - minConsultation) / (maxConsultation - minConsultation)) *
        (maxOpacity - minOpacity);

    return Math.min(Math.max(opacity, minOpacity), maxOpacity);
  }

  useEffect(() => {
    if (!centerData.mdUnits) return;

    const width = 450,
      height = 180;
    const svg = d3
      .select(ref.current)
      //.append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", "0 0  450 180")
      .attr("preserveAspectRatio", "xMinYMin");

    var projection;

    const poly = svg.append("g");
    const line = svg.append("g");
    const bubble = svg.append("g");

    const dataURL =
      "https://raw.githubusercontent.com/GDS-ODSSS/unhcr-dataviz-platform/master/data/geospatial/bubble_map.csv";
    // const polygonsURL =
    //   "https://raw.githubusercontent.com/strotgen/mexico-leaflet/refs/heads/master/states.geojson";
    let polygonsURL =
      "https://r2.datahub.io/clvyjaryy0000la0cxieg4o8o/master/raw/data/countries.geojson";
    if (userDetails.orgCountryCode == 356) {
      polygonsURL =
        "https://raw.githubusercontent.com/Subhash9325/GeoJson-Data-of-Indian-States/refs/heads/master/Indian_States";
      projection = d3
        .geoMercator()
        .scale(350)
        // .center([-101, 24])
        .center([79.4, 21.97])
        .translate([width / 2.2, height / 2]);
    }
    if (userDetails.orgCountryCode == 484) {
      polygonsURL =
        "https://raw.githubusercontent.com/strotgen/mexico-leaflet/refs/heads/master/states.geojson";
      projection = d3
        .geoMercator()
        .scale(500)
        .center([-101, 24])
        //.center([79.40 , 21.97])
        .translate([width / 2.2, height / 2]);
    }

    if (!projection) {
      projection = d3
        .geoMercator()
        .scale(350)
        // .center([-101, 24])
        .center([79.4, 21.97])
        .translate([width / 2.2, height / 2]);
    }
    const polylinesURL =
      "https://raw.githubusercontent.com/GDS-ODSSS/unhcr-dataviz-platform/master/data/geospatial/world_lines_simplified.json";

    const generator = d3.geoPath().projection(projection);

    d3.csv(dataURL).then(function (population) {
      const datapoints = centerData.mdUnits.map((unit) => ({
        gis_name: unit.unitName,
        lat: unit.latitude,
        lon: unit.longitude,
        ref: unit.avgDailyConsultation,
        consultationShare: unit.consultationShare,
        rating: unit.rating,
        avgDailyConsultation: unit.avgDailyConsultation,
        diseases: unit.diseases,
        dailyConsultation: unit.dailyConsultation,
        tillDateConsultation: unit.tillDateConsultation,
        unitId: unit.unitId,
      }));

      // var obj ={
      //     gis_name: "Mexico",
      //     iso3: "MEX",
      //     lat: "24.225130568124673",
      //     lon: "-103.22757400703873",
      //     ref: "16403"
      // }

      // var datapoints =[];
      // datapoints.push(obj);
      //  obj ={
      //     gis_name: "UnitMX",
      //     iso3: "UnitMx",
      //     lat: "19.17538",
      //     lon: "-99.09072",
      //     ref: "5000"
      // }
      // datapoints.push(obj);

      const tooltip = d3.select("body").append("div").attr("class", "tooltip");

      // const mouseover = function(d) {
      // tooltip
      //   .style("opacity", 1)
      // d3.select(this)
      //   .style("fill", "#589BE5")
      //   .style("stroke", "#EF4A60")
      //   .style("opacity", 1)
      // };
      const mouseover = function (event, d) {
        console.log("d", d);
        setTooltipData({
          name: d.gis_name,
          ref: d.ref,
          consultationShare: d.consultationShare,
          rating: d.rating,
          avgDailyConsultation: d.avgDailyConsultation,
          diseases: d.diseases,
          dailyConsultation: d.dailyConsultation,
          tillDateConsultation: d.tillDateConsultation,
          unitId: d.unitId,
        });
      };

      // const mousemove = function(event,d) {

      // tooltip
      // .html("<div style='color: #0072BC'><b>" + d.gis_name + "</b></div><div>Number of Refugee: " + d.ref+"</div>")
      // .style("top", event.pageY - 10 + "px")
      // .style("left", event.pageX + 10 + "px")
      // };
      const mousemove = function (event, d) {
        // Update tooltip position based on mouse movement
        setTooltipPosition({
          x: event.pageX + 20,
          y: event.pageY - 10,
        });
      };

      // const mouseleave = function(d) {
      // tooltip
      // .style("opacity", 0)
      // d3.select(this)
      // .style("stroke", "#0072BC")
      // .style("opacity", 1)
      // };

      const mouseleave = function () {
        // Clear the tooltip data when leaving the element
        setTooltipData(null);
        console.log("");
      };

      const valueScale = d3.extent(datapoints, (d) => +d.ref);
      const size = d3.scaleSqrt().domain(valueScale).range([5, 25]);

      bubble
        .selectAll("circle")
        .style("position", "relative")
        .data(datapoints)
        .join("circle")
        .attr("cx", (d) => projection([+d.lon, +d.lat])[0])
        .attr("cy", (d) => projection([+d.lon, +d.lat])[1])
        // .attr("r", (d) => size(+d.ref))
        .attr("r", (d) => size(1.5))
        .style("fill", "#eb2b2b")
        // .style("fill", (d) => checkOnlineStatusOfUnit(+d.unitId))
        // .attr("stroke", "#0072BC")
        .attr("stroke", (d) => checkOnlineStatusOfUnit(+d.unitId))
        .attr("stroke-width", 2)
        // .attr("fill-opacity", 0.6)
        .attr("fill-opacity", (d) => calculateOpacity(+d.avgDailyConsultation))
        .on("click", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave);
    });

    d3.json(polygonsURL).then(function (topology) {
      poly
        .selectAll("path")
        .data(topology.features)
        .join("path")
        .attr("fill", "#CCCCCC")
        .attr("d", generator);
    });

    svg
      .append("text")
      .attr("class", "note")
      .attr("x", width * 0.01)
      .attr("y", height * 0.96)
      .attr("text-anchor", "start")
      .style("font-size", 7)
      .style("fill", "#666666");
    // .text('Source: Consultation data of each unit');
  }, [centerData]);

  const handleClose = () => {
    console.log("close");
    setTooltipData(null);
  };

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["manageUnitAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="anDashIndCard manageWidth50 msUnit">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("unit")}
              <IconButton
                className={`refreshIcon msUnitOne`}
                onClick={() => fetchCenterData()}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            {/* <div className="andCrdUnitCount cardFrom">
              <FormControl size="small" className="formControl w110 ">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="filterBy-select"
                  value={filterBy}
                  label=""
                  onChange={handleChangefilterop}
                >
                  <MenuItem value={"daily"}>Daily</MenuItem>
                  <MenuItem value={"monthly"}>Monthly</MenuItem>
                </Select>
              </FormControl>
            </div> */}
          </div>

          <div className="andCrdGraf align-items-center msUnitTwo">
            <div className="anDCenterGrf   pRelative">
              {/* geo location wise consultation count with a sign live or not. On
              over there will be information like till date consultation,
              percentage of consultation sharing, rating of doctor , daily
              average, monthly average. Top 3 diseases , daily consultation
              trend line chart */}
              <svg id="viz_container" ref={ref}></svg>
              {tooltipData && (
                <UnitTooltip
                  data={tooltipData}
                  position={tooltipPosition}
                  handleClose={handleClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
