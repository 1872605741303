import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {
  getPositionsOfOrganization,
  getRightsToSelect,
  addRight,
  UpdateCriticalRights,
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";

export default function CreateOrgRights({
  handelCloseCreateOrgRight,
  onSuccess,
  selectedCriticalRights,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  console.log("selectedCriticalRights", selectedCriticalRights);

  const [loading, setLoading] = useState(false);

  const [allCriticalRight, setAllCriticalRight] = useState([]);

  useEffect(() => {
    getRightsToSelect("CRITICAL_RIGHTS").then((response) => {
      console.log("critical rights", response.data);
      setAllCriticalRight(response.data);
    });
  }, []);

  const [selectPosition, setSelectPosition] = useState("");
  const [allPositionData, setPositionData] = useState([]);
  const [updatedPosition, setUpdatedPosition] = useState("");

  useEffect(() => {
    getPositionsOfOrganization(userDetails.orgId)
      .then((response) => {
        console.log("critical right Position", response.data);
        setPositionData([
          ...response.data,
          { positionId: "-1", position: "Reporting Head" },
        ]);
      })
      .catch((error) => {
        console.error("Error fetching positions", error);
      });
  }, [userDetails.orgId]);

  const handleChangeSelectPosition = (event) => {
    // setSelectPosition(event.target.value);
    const value = event.target.value;
    console.log("handleChangeSelectPosition", value);
    setSelectPosition(value);
    if (selectedCriticalRights) {
      selectedCriticalRights.positionId = value;
      setUpdatedPosition(value);
    }
  };
  const [selectCriticalRights, setSelectCriticalRights] = useState("");
  const [updatedCriticalRights, setUpdatedCriticalRights] = useState("");

  const handleChangeSelectCriticalRights = (event) => {
    // setSelectCriticalRights(event.target.value);
    const value = event.target.value;
    console.log("handleChangeSelectPosition", value);
    if (selectedCriticalRights) {
      setSelectCriticalRights(selectedCriticalRights);
    } else {
      setSelectCriticalRights(value);
    }
  };

  // const [isDelegatable, setIsDelegatable] = useState(false);

  // const handleDelegatableChange = (event) => {
  //   console.log("handleDelegatableChange", event.target.checked);
  //   setIsDelegatable(event.target.checked);
  // };

  const [isDelegatable, setIsDelegatable] = useState(
    selectedCriticalRights ? selectedCriticalRights.deligateable === "Y" : false
  );

  const handleDelegatableChange = (event) => {
    const value = event.target.checked;
    setIsDelegatable(value);
    if (selectedCriticalRights) {
      selectedCriticalRights.deligateable = value ? "Y" : "N";
      // You may need to handle state updates properly depending on your application's architecture
      // setUpdatedDelegatable(value);
    }
  };

  const finalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");

    const delegatableData = isDelegatable === true ? "Y" : "N";

    console.log("delegatableData", delegatableData);

    let positionName = "";
    const selectedPosition = allPositionData.find(
      (pos) => pos.positionId === selectPosition
    );
    if (selectedPosition) {
      positionName = selectedPosition.position;
    }

    if (selectCriticalRights.trim() === "") {
      toast.error(t("please_select_rights"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!selectPosition) {
      toast.error(t("please_select_a_position"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (selectPosition.trim() === "") {
    //   toast.error("Please Select Position", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    const reqDto = {
      rightCategory: "CRITICAL_RIGHTS",
      right: selectCriticalRights,
      deligateable: "N",
      minAmount: 0,
      maxAmount: 0,
      id: 0,
      positionId: selectPosition,
      committeeId: 0,
      positionName: positionName,
      committeeName: "",
    };

    console.log("reqDto", reqDto);

    // addRight
    addRight(userDetails.orgId, userDetails.userId, reqDto).then((response) => {
      console.log("reqDto", response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        onSuccess();
        setSelectPosition("");
        setSelectCriticalRights("");
        setIsDelegatable(false);
      }
    });
  };

  const UpdatedFinalsubmit = () => {
    setLoading(true);
    console.log("UpdatedFinalsubmit");

    let positionName = "";
    const selectedPosition = allPositionData.find(
      (pos) => pos.positionId === updatedPosition
    );
    if (selectedPosition) {
      positionName = selectedPosition.position;
    }

    const NewPositionName = positionName
      ? positionName
      : selectedCriticalRights.positionName;

    const NewPositionId = updatedPosition
      ? updatedPosition
      : selectedCriticalRights.positionId;

    const NewRights = updatedCriticalRights
      ? updatedCriticalRights
      : selectedCriticalRights.right;

    const delegatableData = isDelegatable === true ? "Y" : "N";

    const newDelegatable = delegatableData
      ? delegatableData
      : selectedCriticalRights.deligateable;

    const reqDto = {
      rightCategory: selectedCriticalRights.rightCategory,
      right: NewRights,
      deligateable: "N",
      minAmount: 0,
      maxAmount: 0,
      id: selectedCriticalRights.id,
      positionId: NewPositionId,
      committeeId: 0,
      positionName: NewPositionName,
      committeeName: "",
    };

    console.log("reqDto", reqDto);

    // return ;

    UpdateCriticalRights(userDetails.userId, reqDto).then((response) => {
      console.log("updateCritical", response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        onSuccess();
        handelCloseCreateOrgRight();
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">
            {selectedCriticalRights
              ? t("edit_critical_rights")
              : t("add_critical_rights")}
          </div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCreateOrgRight()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            {/* <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Rights Name *"
                variant="outlined"
                className="formTextFieldArea"
              />
            </FormControl>
          </div> */}

            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="select_position">
                  {t("select_rights")}*
                </InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="select_position"
                  id="selectPosition"
                  value={selectedCriticalRights?.right || selectCriticalRights}
                  label={t("select_rights")}
                  onChange={handleChangeSelectCriticalRights}
                  disabled={selectedCriticalRights}
                >
                  {/* <MenuItem value="man">Residual Rights</MenuItem>
                <MenuItem value="man">Hiring Rights</MenuItem> */}
                  {allCriticalRight.map((rights) => (
                    <MenuItem key={rights.id} value={rights.id}>
                      {rights.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="select_position">
                  {t("select_position")}*
                </InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="select_position"
                  id="selectPosition"
                  value={selectedCriticalRights?.positionId || selectPosition}
                  label={t("select_position")}
                  onChange={handleChangeSelectPosition}
                >
                  {/* <MenuItem value="man">Manager</MenuItem>
                <MenuItem value="man">HR Head</MenuItem> */}
                  {allPositionData.map((position) => (
                    <MenuItem
                      key={position.positionId}
                      value={position.positionId}
                    >
                      {position.position}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* <div className="formElement">
              <FormControl className="formControl">
                <FormGroup aria-label="position" row className="feformGroup">
                  <FormControlLabel
                    className="formCheckBox"
                    control={
                      <Checkbox
                        checked={isDelegatable}
                        onChange={handleDelegatableChange}
                      />
                    }
                    label="Delegatable"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </div> */}
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={()=>finalSubmit()}>Save</Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={() =>
                selectedCriticalRights ? UpdatedFinalsubmit() : finalSubmit()
              }
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("submit")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseCreateOrgRight()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
