import { Cancel, Edit, Save } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TaskService from "../../../../../services/TaskService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetailsLocal } from "../../../../../utils/Utils";

export const TaskContractModal = ({ data, refreshData, removeFromStore }) => {
  const { t } = useTranslation();
  const [showEditContract, setShowEditContract] = useState(false);
  const [contractList, setContractList] = useState([]);
  const loginUserDetail = getLoginUserDetailsLocal();
  const [contract, setContract] = useState("");
  const selectContract = (event) => {
    setContract(event.target.value);
  };

  useEffect(() => {
    setContract(data.contractName);
    DashboardServices.getContracts(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        const foundItem = response.data.find(
          (item) => item.name === data.contractName
        );
        if (foundItem) {
          setContract(foundItem);
        }
        setContractList(response.data);
      }
    });
  }, [data]);

  return (
    <>
      {data && data.contractName && (
        <div className="taskElementGrp">
          <div className="taskContainEditBtnGrp">
            <div className="tskElemHeding">{t("contract")}</div>
            {data.incoming === "N" && (
              <Tooltip arrow title={t("edit_contract")}>
                <IconButton
                  className="editTimelineBtn"
                  onClick={() => {
                    setShowEditContract((prev) => !prev);
                  }}
                >
                  {!showEditContract ? <Edit /> : <Cancel />}
                </IconButton>
              </Tooltip>
            )}
            {data.incoming === "N" && showEditContract && (
              <Tooltip arrow title={t("save_contract")}>
                <IconButton
                  className="editTimelineBtn"
                  onClick={() => {
                    console.log("element saving");
                    TaskService.updateTaskContract(
                      data.taskId,
                      contract.id || ""
                    ).then((response) => {
                      if (response.data != 0) {
                        toast.success(t("contract_updated"));
                        refreshData();
                        removeFromStore();
                      } else {
                        toast.error(t("contract_could_not_be_updated"));
                      }
                    });
                  }}
                >
                  <Save />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {!showEditContract && (
            <div className="tskElemntInfo">{data.contractName}</div>
          )}
          {showEditContract && (
            <div className="formElement">
              <FormControl className="formControl pRelative">
                <InputLabel id="setContract">{t("contract")}</InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="setContract"
                  id="contract-select"
                  value={contract}
                  label={t("contract")}
                  onChange={selectContract}
                >
                  <MenuItem value={""}>--{t("select_contract")}--</MenuItem>
                  {contractList &&
                    contractList.length > 0 &&
                    contractList.map((item) => (
                      <MenuItem value={item}>{item.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      )}
    </>
  );
};
