import { Button, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import DateUtils from "../../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";
const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const daysList = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export default function MonthView({
  dateWiseTask,
  currentWeek,
  selectedDate,
  events,
  holidayList,
  leaveList,
  defaultWorkWeek,
  loginUser,
  startTime,
  endTime,
  setSelectedEvent,
  setAdditionalEventDetails,
  setShowAdditionalEventDetails,
  showAdditionalEventDetails,
  setSelectedConvenientTime,
}) {
  const [eventsAndTaskData, setEventsAndTasksData] = React.useState(new Map());
  const { t } = useTranslation();

  const [displayData, setDisplayData] = React.useState([]);
  const [rows, setRows] = React.useState([...Array(6).keys()]);
  const [cols, setCols] = React.useState([...Array(7).keys()]);

  React.useEffect(() => {
    const eventsAndTasksDataTemp = new Map();
    events.forEach((eventDto) => {
      let eventDate = DateUtils.getDateInYYYYMMDD(new Date(eventDto.start));
      if (eventsAndTasksDataTemp.has(eventDate)) {
        let eventList = eventsAndTasksDataTemp.get(eventDate).events;
        eventList.push(eventDto);
        eventsAndTasksDataTemp.get(eventDate).events = eventList;
      } else {
        let tempEventArray = [];
        tempEventArray.push(eventDto);
        eventsAndTasksDataTemp.set(eventDate, {
          events: tempEventArray,
          tasks: [],
        });
      }
    });
    // dateWiseTask.forEach((taskDto) => {
    //   let taskDate = DateUtils.getDateInYYYYMMDD(new Date(taskDto.taskDate));
    //   if (eventsAndTasksDataTemp.has(taskDate)) {
    //     let taskList = eventsAndTasksDataTemp.get(taskDate).tasks;
    //     taskList.push(taskDto);
    //     eventsAndTasksDataTemp.get(taskDate).tasks = taskList;
    //   } else {
    //     let tempTaskArray = [];
    //     tempTaskArray.push(taskDto);
    //     eventsAndTasksDataTemp.set(taskDate, {
    //       events: [],
    //       tasks: tempTaskArray,
    //     });
    //   }
    // });

    var sortedEventsAndTasksData = new Map(
      [...eventsAndTasksDataTemp.entries()].sort((first, second) => {
        return DateUtils.compare(
          DateUtils.convert(first[0]),
          DateUtils.convert(second[0])
        );
      })
    );
    // console.log("MonthView------>>>>>>", sortedEventsAndTasksData);
    setEventsAndTasksData(sortedEventsAndTasksData);
    let selectedDateVar = new Date(selectedDate);
    let currentMonthTemp = DateUtils.getDaysInMonth(
      selectedDateVar.getMonth(),
      selectedDateVar.getFullYear()
    );
    // console.log("currentMonthTemp", currentMonthTemp);
    let previousMonthTemp = [];
    for (let counter = currentMonthTemp[0].getDay(); counter > 0; counter--) {
      let date = new Date(currentMonthTemp[0]);
      previousMonthTemp.push(new Date(date.setDate(date.getDate() - counter)));
    }
    // console.log("currentMonthTemp prev", previousMonthTemp);

    let nextMonthTemp = [];
    for (
      let counter = 1;
      counter <= 6 - currentMonthTemp[currentMonthTemp.length - 1].getDay();
      counter++
    ) {
      let date = new Date(currentMonthTemp[currentMonthTemp.length - 1]);
      nextMonthTemp.push(new Date(date.setDate(date.getDate() + counter)));
    }
    if (
      currentMonthTemp[0].getMonth() === 1 &&
      currentMonthTemp.length === 28
    ) {
      if (previousMonthTemp.length === 0) {
        for (let counter = 1; counter <= 7; counter++) {
          let date = new Date(currentMonthTemp[currentMonthTemp.length - 1]);
          nextMonthTemp.push(new Date(date.setDate(date.getDate() + counter)));
        }
      }
    }
    for (let counter = 1; counter <= 7; counter++) {
      if (nextMonthTemp.length === 0) {
        let date = new Date(currentMonthTemp[currentMonthTemp.length - 1]);
        nextMonthTemp.push(new Date(date.setDate(date.getDate() + 1)));
      } else {
        let date = new Date(nextMonthTemp[nextMonthTemp.length - 1]);
        nextMonthTemp.push(new Date(date.setDate(date.getDate() + 1)));
      }
    }
    // console.log("currentMonthTemp next", nextMonthTemp);

    let displayDataTemp = [];
    displayDataTemp = previousMonthTemp
      .concat(currentMonthTemp)
      .concat(nextMonthTemp);

    setDisplayData(displayDataTemp);

    console.log("currentMonthTemp displayDataTemp", displayDataTemp);
  }, [events, selectedDate]);

  return (
    <>
      <div className="monthViewContainer">
        <div className="scheduler-wrapper">
          <div className="scheduler-header">
            <div className="overview-content">
              <div className="left-panel">
                <div className="overview-scheduler">
                  <div className="e-control e-schedule e-lib schedule-overview e-keyboard">
                    <div className="e-table-container">
                      <div className="e-table-wrap e-month-view e-current-panel">
                        <table className="e-schedule-table e-outer-table">
                          <tbody>
                            <tr>
                              <td>
                                <div className="e-date-header-container">
                                  <div className="e-date-header-wrap">
                                    <table className="e-schedule-table ">
                                      <tbody>
                                        <tr>
                                          <td
                                            colspan="1"
                                            className="e-header-cells"
                                          >
                                            <span>{t("sun")}</span>
                                          </td>
                                          <td
                                            colspan="1"
                                            className="e-header-cells"
                                          >
                                            <span>{t("mon")}</span>
                                          </td>
                                          <td
                                            colspan="1"
                                            className="e-header-cells"
                                          >
                                            <span>{t("tue")}</span>
                                          </td>
                                          <td
                                            colspan="1"
                                            className="e-header-cells"
                                          >
                                            <span>{t("wed")}</span>
                                          </td>
                                          <td
                                            colspan="1"
                                            className="e-header-cells"
                                          >
                                            <span>{t("thu")}</span>
                                          </td>
                                          <td
                                            colspan="1"
                                            className="e-header-cells"
                                          >
                                            <span>{t("fri")}</span>
                                          </td>
                                          <td
                                            colspan="1"
                                            className="e-header-cells e-current-day"
                                          >
                                            <span>{t("sat")}</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  className="e-content-wrap monthCalHeight"
                                  //   style={{ height: "462px" }}
                                >
                                  <table className="e-schedule-table e-content-table">
                                    <tbody>
                                      {displayData.length > 0 &&
                                        rows.map((row) => {
                                          return (
                                            <tr>
                                              {cols.map((col, index) => {
                                                let arrIndex =
                                                  row * cols.length + index;
                                                let selectedDateVar = new Date(
                                                  selectedDate
                                                );
                                                let dateToDisplay =
                                                  displayData[arrIndex];
                                                let dateToDisplayStr =
                                                  DateUtils.getDateInYYYYMMDD(
                                                    new Date(dateToDisplay)
                                                  );
                                                let className =
                                                  "e-work-cells e-work-days";

                                                let holidays = holidayList.map(
                                                  ({ id }) => id
                                                );

                                                let leave = leaveList.map(
                                                  ({ id }) => id
                                                );

                                                let workingDays =
                                                  defaultWorkWeek.map(
                                                    ({ day }) => day
                                                  );

                                                let splDayName = [];

                                                if (
                                                  holidays.indexOf(
                                                    dateToDisplayStr
                                                  ) !== -1
                                                ) {
                                                  className =
                                                    className + " bgHoliday";
                                                  let holidayName =
                                                    holidayList.filter(
                                                      (holiday) =>
                                                        holiday.id ===
                                                        dateToDisplayStr
                                                    );
                                                  splDayName = holidayName;
                                                } else if (
                                                  leave.indexOf(
                                                    dateToDisplayStr
                                                  ) !== -1
                                                ) {
                                                  className =
                                                    className + " bgOnLeav";
                                                  let leaveName =
                                                    leaveList.filter(
                                                      (leave) =>
                                                        leave.id ===
                                                        dateToDisplayStr
                                                    );
                                                  splDayName = leaveName;
                                                } else if (
                                                  workingDays.indexOf(
                                                    weekDays[
                                                      dateToDisplay.getDay()
                                                    ]
                                                  ) === -1
                                                ) {
                                                  className =
                                                    className + " bgWekOff";
                                                } else {
                                                }

                                                if (
                                                  dateToDisplay.getMonth() !==
                                                  selectedDate.getMonth()
                                                ) {
                                                  className =
                                                    "e-work-cells e-other-month";
                                                }

                                                console.log(
                                                  "eventsAndTaskData",
                                                  eventsAndTaskData
                                                );
                                                console.log(
                                                  "eventsAndTaskData",
                                                  dateToDisplayStr,
                                                  eventsAndTaskData.has(
                                                    `${dateToDisplayStr}`
                                                  )
                                                );
                                                // console.log(
                                                //   "eventsAndTaskData",
                                                //   eventsAndTaskData.has(`${dateToDisplayStr}`)
                                                // );
                                                let eventList =
                                                  eventsAndTaskData.has(
                                                    `${dateToDisplayStr}`
                                                  )
                                                    ? eventsAndTaskData.get(
                                                        `${dateToDisplayStr}`
                                                      ).events
                                                    : [];
                                                let taskList =
                                                  eventsAndTaskData.has(
                                                    `${dateToDisplayStr}`
                                                  )
                                                    ? eventsAndTaskData.get(
                                                        `${dateToDisplayStr}`
                                                      ).tasks
                                                    : [];
                                                console.log(
                                                  "eventsAndTaskData",
                                                  dateToDisplayStr,
                                                  eventList,
                                                  taskList
                                                );
                                                let selectableClassName = "";
                                                let minDate = new Date(
                                                  dateToDisplay
                                                );

                                                // Add one day to mydate
                                                minDate.setDate(
                                                  dateToDisplay.getDate() + 1
                                                );
                                                if (
                                                  DateUtils.getDifferenceInMinutes(
                                                    minDate,
                                                    new Date()
                                                  ) <= 0
                                                ) {
                                                  selectableClassName +=
                                                    " tdCellsSelectable";
                                                }
                                                return (
                                                  <td
                                                    className={
                                                      className +
                                                      selectableClassName
                                                    }
                                                    onClick={() => {
                                                      if (
                                                        DateUtils.getDifferenceInMinutes(
                                                          minDate,
                                                          new Date()
                                                        ) <= 0
                                                      ) {
                                                        if (
                                                          DateUtils.isToday(
                                                            dateToDisplay
                                                          )
                                                        ) {
                                                          let timeValueDate =
                                                            new Date();

                                                          let nextMinute =
                                                            timeValueDate.getMinutes();
                                                          if (
                                                            nextMinute % 15 !==
                                                            0
                                                          ) {
                                                            nextMinute =
                                                              nextMinute +
                                                              (15 -
                                                                (nextMinute %
                                                                  15));
                                                          }

                                                          timeValueDate.setHours(
                                                            timeValueDate.getHours(),
                                                            nextMinute,
                                                            0,
                                                            0
                                                          );

                                                          // timeValueDate.setHours(
                                                          //   startHour,
                                                          //   startMinute,
                                                          //   0,
                                                          //   0
                                                          // );

                                                          let fromTime =
                                                            new Date(
                                                              timeValueDate
                                                            ).toLocaleTimeString(
                                                              "en-US",
                                                              {
                                                                hour: "2-digit",
                                                                minute:
                                                                  "2-digit",
                                                                hour12: true,
                                                              }
                                                            );

                                                          let endTimeStart =
                                                            new Date(
                                                              timeValueDate
                                                            );

                                                          endTimeStart.setMinutes(
                                                            endTimeStart.getMinutes() +
                                                              60
                                                          );

                                                          let toTime = new Date(
                                                            endTimeStart
                                                          ).toLocaleTimeString(
                                                            "en-US",
                                                            {
                                                              hour: "2-digit",
                                                              minute: "2-digit",
                                                              hour12: true,
                                                            }
                                                          );

                                                          setSelectedConvenientTime(
                                                            {
                                                              convStartDate:
                                                                dateToDisplay,
                                                              convFromTime:
                                                                fromTime,
                                                              convToTime:
                                                                toTime,
                                                            }
                                                          );
                                                        } else {
                                                          setSelectedConvenientTime(
                                                            {
                                                              convStartDate:
                                                                dateToDisplay,
                                                              convFromTime:
                                                                "09:00 AM",
                                                              convToTime:
                                                                "10:00 AM",
                                                            }
                                                          );
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    <div className="e-date-header e-navigate">
                                                      <span
                                                        style={{
                                                          position: "relative",
                                                          right: "10px",
                                                        }}
                                                      >
                                                        {null != splDayName &&
                                                          splDayName.length >
                                                            0 &&
                                                          splDayName[0].name}
                                                      </span>
                                                      <span>
                                                        {" "}
                                                        {dateToDisplay.getDate()}{" "}
                                                      </span>
                                                    </div>
                                                    {eventList &&
                                                      eventList.length > 0 && (
                                                        <div className="e-appointment-wrapper">
                                                          <div
                                                            className="e-appointment e-lib e-draggable  "
                                                            style={{
                                                              height: "42px",
                                                              left: "0px",
                                                              bottom: "0px",
                                                            }}
                                                          >
                                                            <Tooltip
                                                              title={`${
                                                                eventList[0]
                                                                  .title
                                                              } (${DateUtils.displayDateMonthViewToolTip(
                                                                eventList[0]
                                                              )})`}
                                                              arrow
                                                            >
                                                              <div
                                                                className={`e-appointment-details ${
                                                                  eventList[0]
                                                                    .source ===
                                                                  "google"
                                                                    ? "gCalendar"
                                                                    : "myCalendar"
                                                                } `}
                                                                onClick={() => {
                                                                  console.log(
                                                                    "setSelectedEvent",
                                                                    eventList[0]
                                                                  );
                                                                  setSelectedEvent(
                                                                    eventList[0]
                                                                  );
                                                                }}
                                                              >
                                                                <div
                                                                  className="e-subject"
                                                                  style={{
                                                                    maxHeight:
                                                                      "42px",
                                                                  }}
                                                                >
                                                                  {
                                                                    eventList[0]
                                                                      .title
                                                                  }
                                                                </div>
                                                              </div>
                                                            </Tooltip>
                                                            {eventList.length >
                                                              1 && (
                                                              <Tooltip
                                                                title={`${
                                                                  eventList[1]
                                                                    .title
                                                                } (${DateUtils.displayDateMonthViewToolTip(
                                                                  eventList[1]
                                                                )})`}
                                                                arrow
                                                              >
                                                                <div
                                                                  className={`e-appointment-details ${
                                                                    eventList[1]
                                                                      .source ===
                                                                    "google"
                                                                      ? "gCalendar"
                                                                      : "myCalendar"
                                                                  } `}
                                                                  onClick={() => {
                                                                    setSelectedEvent(
                                                                      eventList[1]
                                                                    );
                                                                  }}
                                                                >
                                                                  <div
                                                                    className="e-subject"
                                                                    style={{
                                                                      maxHeight:
                                                                        "42px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      eventList[1]
                                                                        .title
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </Tooltip>
                                                            )}
                                                            {eventList.length >
                                                              2 && (
                                                              <Button
                                                                className="e-appointment-details moreEvent"
                                                                onClick={() => {
                                                                  if (
                                                                    showAdditionalEventDetails
                                                                  ) {
                                                                    setShowAdditionalEventDetails(
                                                                      false
                                                                    );
                                                                  } else {
                                                                    let tempList =
                                                                      [];
                                                                    for (
                                                                      let index = 2;
                                                                      index <
                                                                      eventList.length;
                                                                      index++
                                                                    ) {
                                                                      tempList.push(
                                                                        eventList[
                                                                          index
                                                                        ]
                                                                      );
                                                                    }
                                                                    setAdditionalEventDetails(
                                                                      tempList
                                                                    );
                                                                    setShowAdditionalEventDetails(
                                                                      true
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                <div
                                                                  className="e-subject-more"
                                                                  style={{
                                                                    maxHeight:
                                                                      "42px",
                                                                  }}
                                                                >
                                                                  +
                                                                  {eventList.length -
                                                                    2}
                                                                </div>
                                                              </Button>
                                                            )}

                                                            {/* {eventList.length >
                                                              1 && (
                                                              <div class="e-event-resize e-right-handler mvEvents">
                                                                <div class="e-left-right-resize ">
                                                                  <HtmlTooltip
                                                                    title={renderTooltipTitleEvents(
                                                                      eventList
                                                                    )}
                                                                  >
                                                                    <span>
                                                                      {"+"}
                                                                      {eventList.length -
                                                                        1}
                                                                    </span>
                                                                  </HtmlTooltip>
                                                                </div>
                                                              </div>
                                                            )} */}
                                                          </div>
                                                        </div>
                                                      )}
                                                    {taskList &&
                                                      taskList.length > 0 && (
                                                        <div className="e-appointment-wrapper">
                                                          <div
                                                            className="e-appointment-task e-read-only"
                                                            style={{
                                                              backgroundColor:
                                                                "rgb(13, 110, 253)",
                                                              width:
                                                                "185.344px; top: 0px",
                                                            }}
                                                          >
                                                            <div className="e-appointment-details">
                                                              <div className="e-time">
                                                                {DateUtils.prettyDate(
                                                                  new Date(
                                                                    taskList[0].taskDate
                                                                  ).getTime()
                                                                )}
                                                              </div>
                                                              <Tooltip
                                                                title={
                                                                  taskList[0]
                                                                    .taskName
                                                                }
                                                              >
                                                                <div className="e-subject">
                                                                  {`${taskList[0].taskName.substring(
                                                                    0,
                                                                    12
                                                                  )} ...`}
                                                                </div>
                                                              </Tooltip>
                                                            </div>
                                                            {/* {taskList.length >
                                                              1 && (
                                                              <div class="e-event-resize e-right-handler mvEvents">
                                                                <div class="e-left-right-resize">
                                                                  <HtmlTooltip
                                                                    title={renderToolTipTitleTasks(
                                                                      taskList
                                                                    )}
                                                                  >
                                                                    <span>
                                                                      {"+"}
                                                                      {taskList.length -
                                                                        1}
                                                                    </span>
                                                                  </HtmlTooltip>
                                                                </div>
                                                              </div>
                                                            )} */}
                                                          </div>
                                                        </div>
                                                      )}
                                                  </td>
                                                );
                                              })}
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                    {/* <tbody>
                                      <tr>
                                        <td className="e-work-cells e-other-month bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            28{" "}
                                          </div>
                                          <div className="e-appointment-wrapper ">
                                            <div
                                              className="e-appointment e-lib e-draggable  "
                                              style={{
                                                height: "42px",
                                                left: "0px",
                                                bottom: "0px",
                                              }}
                                            >
                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details gCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details myCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Button className="e-appointment-details moreEvent">
                                                <div
                                                  className="e-subject-more"
                                                  style={{
                                                    maxHeight: "42px",
                                                  }}
                                                >
                                                  +3
                                                </div>
                                              </Button>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-other-month">
                                          <div className="e-date-header e-navigate">
                                            29{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-other-month">
                                          <div className="e-date-header e-navigate">
                                            30{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-other-month">
                                          <div className="e-date-header e-navigate">
                                            31{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            1{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            2{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            3{" "}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-work-cells e-work-days bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            4{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate currentDate">
                                            5{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            6{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            7{" "}
                                          </div>
                                          <div className="e-appointment-wrapper ">
                                            <div
                                              className="e-appointment e-lib e-draggable  "
                                              style={{
                                                height: "42px",
                                                left: "0px",
                                                bottom: "0px",
                                              }}
                                            >
                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details myCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            8{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            9{" "}
                                          </div>
                                          <div className="e-appointment-wrapper ">
                                            <div
                                              className="e-appointment e-lib e-draggable  "
                                              style={{
                                                height: "42px",
                                                left: "0px",
                                                bottom: "0px",
                                              }}
                                            >
                                              <Tooltip
                                                title="Morning Meeting
                                                    (Applicable for Work From
                                                    Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details myCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>
                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details myCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Button className="e-appointment-details moreEvent">
                                                <div
                                                  className="e-subject-more"
                                                  style={{
                                                    maxHeight: "42px",
                                                  }}
                                                >
                                                  +3
                                                </div>
                                              </Button>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            10{" "}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-work-cells e-work-days bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            11{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days bgHoliday">
                                          <div className="e-date-header e-navigate">
                                            12{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            13{" "}
                                          </div>
                                          <div className="e-appointment-wrapper ">
                                            <div
                                              className="e-appointment e-lib e-draggable  "
                                              style={{
                                                height: "42px",
                                                left: "0px",
                                                bottom: "0px",
                                              }}
                                            >
                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details gCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details myCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Button className="e-appointment-details moreEvent">
                                                <div
                                                  className="e-subject-more"
                                                  style={{
                                                    maxHeight: "42px",
                                                  }}
                                                >
                                                  +3
                                                </div>
                                              </Button>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            14{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            15{" "}
                                          </div>
                                          <div className="e-appointment-wrapper ">
                                            <div
                                              className="e-appointment e-lib e-draggable  "
                                              style={{
                                                height: "42px",
                                                left: "0px",
                                                bottom: "0px",
                                              }}
                                            >
                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details gCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details myCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Button className="e-appointment-details moreEvent">
                                                <div
                                                  className="e-subject-more"
                                                  style={{
                                                    maxHeight: "42px",
                                                  }}
                                                >
                                                  +3
                                                </div>
                                              </Button>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            16{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            17{" "}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-work-cells e-work-days bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            18{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            19{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            20{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days bgOnLeav">
                                          <div className="e-date-header e-navigate">
                                            21{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            22{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            23{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            24{" "}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-work-cells e-work-days bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            25{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            26{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            27{" "}
                                          </div>
                                          <div className="e-appointment-wrapper ">
                                            <div
                                              className="e-appointment e-lib e-draggable  "
                                              style={{
                                                height: "42px",
                                                left: "0px",
                                                bottom: "0px",
                                              }}
                                            >
                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details gCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details myCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Button className="e-appointment-details moreEvent">
                                                <div
                                                  className="e-subject-more"
                                                  style={{
                                                    maxHeight: "42px",
                                                  }}
                                                >
                                                  +3
                                                </div>
                                              </Button>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            28{" "}
                                          </div>
                                          <div className="e-appointment-wrapper ">
                                            <div
                                              className="e-appointment e-lib e-draggable  "
                                              style={{
                                                height: "42px",
                                                left: "0px",
                                                bottom: "0px",
                                              }}
                                            >
                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details gCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details myCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Button className="e-appointment-details moreEvent">
                                                <div
                                                  className="e-subject-more"
                                                  style={{
                                                    maxHeight: "42px",
                                                  }}
                                                >
                                                  +3
                                                </div>
                                              </Button>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-work-days">
                                          <div className="e-date-header e-navigate">
                                            29{" "}
                                          </div>
                                          <div className="e-appointment-wrapper ">
                                            <div
                                              className="e-appointment e-lib e-draggable  "
                                              style={{
                                                height: "42px",
                                                left: "0px",
                                                bottom: "0px",
                                              }}
                                            >
                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details gCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Tooltip
                                                title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                arrow
                                              >
                                                <div className="e-appointment-details myCalendar">
                                                  <div
                                                    className="e-subject"
                                                    style={{
                                                      maxHeight: "42px",
                                                    }}
                                                  >
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                              </Tooltip>

                                              <Button className="e-appointment-details moreEvent">
                                                <div
                                                  className="e-subject-more"
                                                  style={{
                                                    maxHeight: "42px",
                                                  }}
                                                >
                                                  +3
                                                </div>
                                              </Button>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-other-month">
                                          <div className="e-date-header e-navigate">
                                            1{" "}
                                          </div>
                                        </td>
                                        <td className="e-work-cells e-other-month bgWekOff">
                                          <div className="e-date-header e-navigate">
                                            2{" "}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody> */}
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
