import { Add } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import DashboardServices from "../../../../../services/DashboardServices";
import { v4 as uuidv4 } from "uuid";
import zhPatientService from "../../../../../services/ZhPatientService";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../../../utils/IndexedDB";

export default function AddCompound({ handleAddCompound, compoundList }) {
  const { t } = useTranslation();
  // const [compoundList, setCompoundList] = useState([]);
  const [formList, setFormList] = useState([]);
  const [doseUnitList, setDoseUnitList] = useState([]);
  const [doseLimitList, setDoseLimitList] = useState([]);

  const [durationList, setDurationList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);
  const [dosageList, setDosageList] = useState([]);
  const [routeList, setRoutelist] = useState([]);
  const [filteredDosageList, setFilteredDosageList] = useState([]);
  const [dependOnBodyWeight, setDependOnBodyWeight] = useState(false);

  const [selectCompound, setSelectCompound] = useState("");
  const handleChangselectCompound = (event) => {
    const compound = event.target.value;
    initializeForm();
    setSelectCompound(compound);
    getCompoundInfo(compound.compoundId);
  };
  const [selectForm, setSelectForm] = useState("");

  const initializeForm = () => {
    setSelectForm("");
    setSelectRoute("");
    setSelectDose("");
    setSelectDoseLimit("");
    setSelectDoseUnit("");
    // setSelectDuration("");
    // setSelectFrequency("");
  };
  const getCompoundInfo = (compoundId) => {
    zhPatientService
      .getDDOfRouteFormDosagesOfCompound(compoundId)
      .then((response) => {
        if (response.data) {
          const { routes, forms, dosageWitUnits } = response.data;
          if (forms?.length === 1) {
            setSelectForm(forms[0]);
          }
          if (routes?.length === 1) {
            setSelectRoute(routes[0]?.id);
          }

          setFormList(forms);
          setRoutelist(routes);
          const dosageList = Object.keys(dosageWitUnits);
          if (dosageList?.length === 1) {
            setSelectDose(dosageList[0]);
            setSelectDoseLimit(dosageList[0]);
            const units = dosageWitUnits[dosageList[0]];
            if (units?.length === 1) {
              setSelectDoseUnit(units[0]);
              setDoseUnitList(units);
            }
          }
          setFilteredDosageList(dosageList);
          setDoseLimitList(dosageList);
          setDosageList(dosageWitUnits);
        }
      });
  };
  const handleChangselectForm = (event) => {
    setSelectForm(event.target.value);
    if (dosageList?.length > 0) {
      const filteredDosageList = dosageList?.filter(
        (item) => item.formId === event.target.value
      );
      setFilteredDosageList(filteredDosageList);
    }
  };
  const [selectRoute, setSelectRoute] = useState("");
  const handleChangselectRoute = (event) => {
    setSelectRoute(event.target.value);
  };
  const [selectDose, setSelectDose] = useState("");
  const handleChangselectDose = (event) => {
    setSelectDose(event.target.value);
    const dosageUnitList = dosageList[event.target.value];
    if (dosageUnitList?.length === 1) {
      setSelectDoseUnit(dosageUnitList[0]);
    }
    setDoseUnitList(dosageUnitList);
  };
  const [selectDoseUnit, setSelectDoseUnit] = useState("");
  const handleChangselectDoseUnit = (event) => {
    setSelectDoseUnit(event.target.value);
  };
  const [selectDoseLimit, setSelectDoseLimit] = useState("");
  const handleChangselectDoseLimit = (event) => {
    setSelectDoseLimit(event.target.value);
  };
  const [selectDuration, setSelectDuration] = useState("");
  const handleChangselectDuration = (event) => {
    setSelectDuration(event.target.value);
  };
  const [selectFrequency, setSelectFrequency] = useState("");
  const handleChangselectFrequency = (event) => {
    setSelectFrequency(event.target.value);
  };

  const getRoutes = async () => {
    const brandString = await SecureIndexedDB.getItem("routeList");
    if (brandString) {
      const tempRoute = JSON.parse(brandString);
      setRoutelist(tempRoute);
      return tempRoute;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getRoutes(reqDto);
    if (response.data) {
      setRoutelist(response.data);
      SecureIndexedDB.setItem("routeList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const getFrequencies = async () => {
    const diseasetring = await SecureIndexedDB.getItem("frequencyList");
    if (diseasetring) {
      const tempFreq = JSON.parse(diseasetring);
      setFrequencyList(tempFreq);
      return tempFreq;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getFrequencies(reqDto);
    if (response.data) {
      setFrequencyList(response.data);
      SecureIndexedDB.setItem("frequencyList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const getDurations = async () => {
    const diseasetring = await SecureIndexedDB.getItem("durationList");
    if (diseasetring) {
      const tempDuration = JSON.parse(diseasetring);
      setDurationList(tempDuration);
      return tempDuration;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getDurations(reqDto);
    if (response.data) {
      setDurationList(response.data);
      SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      return response.data;
    }
  };

  // const getFormDosages = async () => {
  //   const getFormList = (dosageList) => {
  //     let formList = dosageList?.map((item) => item.formId);
  //     if (formList) {
  //       return [...new Set(formList)];
  //     }
  //     return [];
  //   };
  //   const diseasetring =  SecureIndexedDB.getItem("dosageList");
  //   if (diseasetring) {
  //     const tempDosage = JSON.parse(diseasetring);
  //     setDosageList(tempDosage);
  //     const forms = getFormList(tempDosage);
  //     setFormList(forms);
  //     return tempDosage;
  //   }

  //   const reqDto = await getHealthDto();
  //   const response = await DashboardServices.getFormDosages(reqDto);
  //   if (response.data) {
  //     setDosageList(response.data);
  //     SecureIndexedDB.setItem("dosageList", JSON.stringify(response.data));
  //     const forms = getFormList(response.data);
  //     setFormList(forms);
  //     return response.data;
  //   }
  // };

  useEffect(() => {
    // getRoutes();
    getFrequencies();
    getDurations();
    // getFormDosages();
  }, []);

  return (
    <>
      <div className="formElement">
        <FormControl className="formControl">
          <FormGroup aria-label="position" row className="feformGroup">
            {/* <FormControlLabel
                    className="formCheckBox"
                    control={
                      <Checkbox
                        checked={isDoseDippentOnAge}
                        onChange={handleDoseDippentOnAge}
                      />
                    }
                    label="Dose dependent on Age ?"
                    labelPlacement="start"
                  /> */}
            <FormControlLabel
              className="formCheckBox"
              // value="Profit Center"
              control={
                <Checkbox
                  checked={dependOnBodyWeight}
                  onChange={(e) => setDependOnBodyWeight(e.target.checked)}
                />
              }
              label={t("dose_dependent_on_body_weight")}
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
      </div>
      <div className="formElementGrp">
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectComp">{t("select_compound")}</InputLabel>
            <Select
              required
              labelId="selectComp"
              id="select_Comp"
              value={selectCompound}
              label={t("select_compound")}
              onChange={handleChangselectCompound}
              className="formInputField"
              variant="outlined"
            >
              {compoundList?.slice(0, 30)?.map((item) => {
                return <MenuItem value={item}>{item.compoundName}</MenuItem>;
              })}
              {/* <MenuItem value="comp2">Compound two</MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="selectComp">{t("form")}</InputLabel>
            <Select
              required
              labelId="selectForm"
              id="select_Form"
              value={selectForm}
              label={t("form")}
              onChange={handleChangselectForm}
              className="formInputField"
              variant="outlined"
            >
              {formList?.map((item) => {
                return <MenuItem value={item}>{item.formName}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="formElementGrp">
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectRoute">{t("rout")}</InputLabel>
            <Select
              required
              labelId="selectRoute"
              id="select_Route"
              value={selectRoute}
              label={t("rout")}
              onChange={handleChangselectRoute}
              className="formInputField"
              variant="outlined"
            >
              {routeList?.map((item) => {
                return <MenuItem value={item}>{item.route}</MenuItem>;
              })}
              {/* <MenuItem value="Route2">Route 2</MenuItem>
              <MenuItem value="Route3">Route 3</MenuItem> */}
            </Select>
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectDose">{t("dose")}</InputLabel>
            <Select
              required
              labelId="selectDose"
              id="select_minDose"
              value={selectDose}
              label={t("dose")}
              onChange={handleChangselectDose}
              className="formInputField"
              variant="outlined"
            >
              {filteredDosageList?.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
              {/* <MenuItem value="dose2">Dose 2</MenuItem> */}
            </Select>
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectDoseUnit">{t("dose_unit")}</InputLabel>
            <Select
              required
              labelId="selectDoseUnit"
              id="select_DoseUnit"
              value={selectDoseUnit}
              label={t("dose_unit")}
              onChange={handleChangselectDoseUnit}
              className="formInputField"
              variant="outlined"
            >
              {doseUnitList?.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectDoseLimit">
              {t("maximum_dose_limit")}
            </InputLabel>
            <Select
              required
              labelId="selectDoseLimit"
              id="select_DoseLimit"
              value={selectDoseLimit}
              label={t("dose_limit")}
              onChange={handleChangselectDoseLimit}
              className="formInputField"
              variant="outlined"
            >
              {doseLimitList?.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="formElementGrp">
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="selectDuration">{t("duration")}</InputLabel>
            <Select
              required
              labelId="selectDuration"
              id="select_Duration"
              value={selectDuration}
              label={t("duration")}
              onChange={handleChangselectDuration}
              className="formInputField"
              variant="outlined"
            >
              {durationList?.map((item) => {
                return <MenuItem value={item}>{item.durationName}</MenuItem>;
              })}
              {/* <MenuItem value="2day">2 Day</MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="selectFrequency">{t("frequency")}</InputLabel>
            <Select
              required
              labelId="selectFrequency"
              id="select_Frequency"
              value={selectFrequency}
              label={t("frequency")}
              onChange={handleChangselectFrequency}
              className="formInputField"
              variant="outlined"
            >
              {frequencyList?.map((item) => {
                return <MenuItem value={item}>{item.frequency}</MenuItem>;
              })}
              {/* <MenuItem value="tid">TID</MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <Button
          className="dfultPrimaryBtn width13"
          startIcon={<Add />}
          onClick={() => {
            const dto = {
              localId: uuidv4(),
              compound: selectCompound,
              form: selectForm,
              route: selectRoute,
              dose: selectDose,
              doseUnit: selectDoseUnit,
              doseLimit: selectDoseLimit,
              duration: selectDuration,
              frequency: selectFrequency,
              weightMeasured: dependOnBodyWeight,
            };
            handleAddCompound(dto);
          }}
        >
          {t("add_compound")}
        </Button>
      </div>
    </>
  );
}
