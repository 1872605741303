import React, { useState } from "react";

export const DetectMobilePopUp = ({ handleJoinApp, handleJoinBrowser }) => {
  const [link, setLink] = useState("");

  const handleNavigateToLink = () => {
    if (link) {
      window.location.href = link;
    }
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <h2 style={styles.header}>Join Options</h2>
        <p style={styles.text}>Choose how you want to join:</p>

        <button onClick={handleJoinApp} style={styles.button}>
          Join Via App
        </button>

        <button onClick={handleJoinBrowser} style={styles.button}>
          Join Via Browser
        </button>

        <div style={styles.linkSection}>
          <p style={styles.text}>Or, enter a link to visit:</p>
          <input
            type="text"
            placeholder="Enter link here"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            style={styles.input}
          />
          <button onClick={handleNavigateToLink} style={styles.navigateButton}>
            Go to Link
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    backgroundColor: "white",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    width: "400px",
  },
  header: {
    color: "#333",
    marginBottom: "15px",
  },
  text: {
    color: "#555",
    marginBottom: "15px",
  },
  button: {
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    margin: "10px",
    cursor: "pointer",
    fontSize: "16px",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  linkSection: {
    marginTop: "20px",
  },
  input: {
    width: "80%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    marginBottom: "10px",
  },
  navigateButton: {
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px 15px",
    cursor: "pointer",
  },
};
