import React, { useState, useEffect, Suspense } from "react";
import SecureIndexedDB, { migrateLocalStorageToIndexedDB } from "./IndexedDB";
import { jwtDecode } from "jwt-decode";
import Loaders from "./Loaders";
import { getActiveOtpServices } from "../services/AdminService";
import { createContext, useContext } from "react";
export const AppInitializerContext = createContext();

export const AppInitializer = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [userLoginDetails, setUserLoginDetails] = useState(null);

  const updateUserLoginDetails = async () => {
    const token = await SecureIndexedDB.getItem("token");
    console.log("GetLoginUserDetails", token);
    // if (token != null) {

    // }
    if (token != null && token !== "null") {
      try {
        // return jwtDecode(token);
        setUserLoginDetails(jwtDecode(token));
      } catch (err) {
        return null;
      }
    }
  };

  const defineOTPServices = async () => {
    console.log("defineOTPServices", userLoginDetails);
    if (
      userLoginDetails &&
      userLoginDetails.orgId &&
      userLoginDetails.orgId !== "ORG000000000000"
    ) {
      const otpServices =
        JSON.parse(await SecureIndexedDB.getItem("otpServices")) || [];
      console.log("otpServices AppInitializer", otpServices);
      if (otpServices.length <= 0) {
        getActiveOtpServices(userLoginDetails.orgId)
          .then((response) => {
            console.log("responseotpservicedata AppInitializer", response.data);
            if (Array.isArray(response.data) && response.data.length > 0) {
              SecureIndexedDB.setItem(
                "otpServices",
                JSON.stringify(response.data)
              );
            }
          })
          .catch((error) => {
            console.error("Error fetching OTP services:", error);
          });
      }
    }
  };

  useEffect(() => {
    if (isReady) {
      defineOTPServices();
    }
  }, [isReady]);

  useEffect(() => {
    const performMigration = async () => {
      await migrateLocalStorageToIndexedDB();
      await updateUserLoginDetails();
      setIsReady(true);
    };

    performMigration();
  }, []);

  if (!isReady) {
    return (
      <Suspense
        fallback={
          <>
            <Loaders />
          </>
        }
      ></Suspense>
    );
  }

  return (
    <>
      <AppInitializerContext.Provider
        value={{ userLoginDetails, setUserLoginDetails }}
      >
        {children}
      </AppInitializerContext.Provider>
    </>
  );
};
