import { HeightOutlined, MonitorWeightOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  setRef,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";

import BP from "../../../../../../dashboard/image/BP";
import Capsule from "../../../../../../dashboard/image/Capsule";
import SPO2 from "../../../../../../dashboard/image/SPO2";
import Syrup from "../../../../../../dashboard/image/Syrup";
import Tablets from "../../../../../../dashboard/image/Tablets";
import critical from "../../images/prescriptionIcons/critical.svg";
import eye from "../../images/prescriptionIcons/eye.svg";
import like from "../../images/prescriptionIcons/like.svg";
import scan from "../../images/prescriptionIcons/scan.png";
import dayjs from "dayjs";
import { getVitalSvgAccordingToVitalType } from "./consultationUtil";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DateUtils from "../../../../../../../utils/DateUtils";
import { Play } from "react-bootstrap-icons";
import "dayjs/locale/es";
import "dayjs/locale/en";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../../../common/TourProvider";

export default function PrescriptionView({
  data,
  extraData,
  closeMeeting,
  updateData,
}) {
  // console.log('updateData' , updateData());
  // console.log('extraData' , extraData);
  // console.log('data' , data);
  const { t, i18n } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const loginUserDetail = GetLoginUserDetails();
  const [vitals, setVitals] = useState([]);
  const audioUrl = useRef();
  const [showAudioModal, setShowAudioModal] = useState(false);

  const [prescriptionType, setPrescriptionType] = useState("Provisional");
  const handelChangeprescriptionType = (event) => {
    setPrescriptionType(event.target.value);
    updateData({ prescriptionType: event.target.value });
  };
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setBookSteps([
      {
        target: ".videoPrescriptionStepOne",
        content: "This section allows the doctor to preview the prescription.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepTwo",
        content: "This section displays the prescription type.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepThree",
        content: "This section shows the patient's details.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepFour",
        content: "This section shows the patient's vitals.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepFive",
        content: "This section displays the patient's complaints.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepSix",
        content: "This section shows the patient's examination and signs.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepSeven",
        content: "This section displays the patient's comorbidities.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepEight",
        content: "This section shows the patient's risk factors.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepNine",
        content: "This section displays the patient's possible diagnosis.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepTen",
        content: "This section shows the patient's investigations.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepEleven",
        content: "This section displays the patient's diagnosed conditions.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepTwelve",
        content: "This section shows the patient's procedures.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepThirteen",
        content: "This section provides advice for the patient.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepFourteen",
        content:
          "This section displays parameters to be monitored for the patient.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepFifteen",
        content: "This section provides the patient's diet recommendations.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepSixteen",
        content:
          "This section provides the patient's lifestyle recommendations.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepSeventeen",
        content: "This section shows who referred the patient.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepEightteen",
        content: "This section shows the patient's referral details.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepNineteen",
        content: "This section displays the patient's next review schedule.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepTwenty",
        content: "This section shows the doctor's notes for the patient.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepTwentyOne",
        content: "This section allows you to listen to audio.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepTwentyTwo",
        content: "This section highlights important watch-outs.",
        disableBeacon: true,
      },
      {
        target: ".videoPrescriptionStepTwentyThree",
        content: "This section lets you listen to an audio message.",
        disableBeacon: true,
      },
    ]);
  }, []);

  // useEffect(()=>{
  //   setBookSteps([
  //     {
  //       target: ".videoPrescriptionStepOne",
  //       content:
  //         "This section where the doctor can preview the prescription",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepTwo",
  //       content:
  //         "This section where you can see Prescription type",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepThree",
  //       content:
  //         "This section where you can view Patient Details",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepFour",
  //       content:
  //         "This section where you can view Patient Vitals",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepFive",
  //       content:
  //         "This section where you can view Patient Complaints",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepSix",
  //       content:
  //         "This section where you can view Patient Examination ans sign",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepSeven",
  //       content:
  //         "This section where you can view Patient comorbidity",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepEight",
  //       content:
  //         "This section where you can view Patient risk Factor",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepNine",
  //       content:
  //         "This section where you can view Patient possible Diagnosis",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepTen",
  //       content:
  //         "This section where you can view Patient investications",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepEleven",
  //       content:
  //         "This section where you can view Patient Diagnosis Condition",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepTwelve",
  //       content:
  //         "This section where you can view Patient Procedure",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepThirteen",
  //       content:
  //         "This section where you can view advice for Patient",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepFourteen",
  //       content:
  //         "This section where you can view patient Parameter to be monitor",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepFifteen",
  //       content:
  //         "This section where you can view patient's diet recommendation",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepSixteen",
  //       content:
  //         "This section where you can view patient's lifeStyle recommendation",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepSeventeen",
  //       content:
  //         "This section where you can view patient refer by",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepEightteen",
  //       content:
  //         "This section where you can view patient refer ",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepNineteen",
  //       content:
  //         "This section where you can view patient Next Review ",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepTwenty",
  //       content:
  //         "This section where you can view patient Doctor's Notes ",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepTwentyOne",
  //       content:
  //         "This section where you can listen to audio ",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepTwentyTwo",
  //       content:
  //         "This section where you can view Watch out for ",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".videoPrescriptionStepTwentyThree",
  //       content:
  //         "This section where you can listen Audio message",
  //       disableBeacon: true,
  //     },
  //   ])
  // },[])

  // **************************BMI CALCULATE**************************
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [bmi, setBmi] = useState("");
  const [heightOutput, setHeightOutput] = useState("");
  const [weightOutput, setWeightOutput] = useState("");
  const [bmiClass, setBmiClass] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(1);
  const [pescriptionData, setPescriptionnData] = useState(null);
  const [showBMI, setShowBMI] = useState(false);

  useEffect(() => {
    if (i18n.language === "es") {
      dayjs.locale("es");
    } else {
      dayjs.locale("en");
    }
    setRefresh((prev) => prev + 1);
  }, [i18n.language]);

  const uploadData = (data) => {
    setPescriptionnData(data);
  };

  const calculateBmi = () => {
    const showBmi = true;

    const heightIndex = extraData.reqValue.vitals.findIndex(
      (item) => item.vitalName === "Height"
    );
    const widthIndex = extraData.reqValue.vitals.findIndex(
      (item) => item.vitalName === "Width"
    );

    if (heightIndex === -1 || widthIndex === -1) {
      setShowBMI(false);
    }

    const heightInMeters = height / 100;
    const bmiValue = parseFloat(weight / heightInMeters ** 2).toFixed(2);
    const bmiGroupIndex = [
      [0, 18.49],
      [18.5, 24.99],
      [25, 29.99],
      [30, 34.99],
      [35, 39.99],
      [40, 200],
    ].findIndex((e) => e[0] <= bmiValue && bmiValue < e[1]);

    const heightInInches = (height * 0.393700787).toFixed(0);
    const feet = Math.floor(heightInInches / 12);
    const inches = heightInInches % 12;

    setHeightOutput(`${height} cm / ${feet}' ${inches}"`);
    setWeightOutput(`${weight} kg / ${(2.2046 * weight).toFixed(2)} lb`);
    setBmi(bmiValue);
    setSelectedGroup(bmiGroupIndex);

    const bmiClasses = [
      "bmi-underweight",
      "bmi-normal",
      "bmi-preobesity",
      "bmi-obese1",
      "bmi-obese2",
      "bmi-obese3",
    ];
    setBmiClass(bmiClasses[bmiGroupIndex]);
  };

  const getReferStringFromType = (refer) => {
    switch (refer) {
      case "higherFacility":
        return t("advise_higher_facility");

      case "immediateHospitalization":
        return t("advise_immediate_hospitalization");

      case "otherSpeciality":
        return t("refer_to_other_speciality");

      case "medicoLegal":
        return t("refer_to_medico_legal");

      default:
        return "";
    }
  };

  useEffect(() => {
    if (extraData.queueVitals && extraData.isDoctor) {
      let vitalList = extraData.queueVitals.split("~");
      if (vitalList.length > 0) {
        vitalList = vitalList.map((item) => {
          const vitalComponents = item.split("#");
          if (vitalComponents[vitalComponents.length - 1] === "Height") {
            let value = vitalComponents[1];
            value = parseInt(value.match(/\d+/)[0], 10);
            // const isKg = value.toLowerCase().includes("kg");
            setHeight(value);
          } else if (vitalComponents[vitalComponents.length - 1] === "Weight") {
            let value = vitalComponents[1];
            value = parseInt(value.match(/\d+/)[0], 10);
            // const isKg = value.toLowerCase().includes("kg");
            setWeight(value);
          }
          return vitalComponents;
        });
      }
      setVitals(vitalList);
    }
    // calculateBmi();
    if (data?.diagonesis) {
      const isProvisional = data?.diagonesis?.some(
        (item) => item.status === "Provisional"
      );
      if (isProvisional) {
        setPrescriptionType("Provisional");
        updateData({ prescriptionType: "Provisional" });
      } else {
        setPrescriptionType("Final");
        updateData({ prescriptionType: "Final" });
      }
    }
  }, []);
  useEffect(() => {
    if (height && weight) {
      calculateBmi();
    }
  }, [height, weight]);

  console.log("prescription view run", run["docPrescriptionVideoDc"], run);
  return (
    <>
      {/* <Joyride
        steps={booksteps}
        run={run["docPrescriptionVideoDc"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        locale={{
          last: "Next",
        }}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      /> */}
      <div className="Meeting_Transcription videoPrescriptionStepOne">
        <div className="elementFormContainer">
          <div className="tskElemHeding  ">{t("prescription_type")}</div>
          <div className="formElement videoPrescriptionStepTwo">
            <FormControl className="formControl">
              <RadioGroup
                className="formRadioGroup"
                labelId="setTasktype"
                name="setTasktype"
                value={prescriptionType}
                onChange={handelChangeprescriptionType}
              >
                <FormControlLabel
                  className="formRadioField"
                  value="Final"
                  control={<Radio />}
                  label={t("confirm_prescription")}
                />
                <FormControlLabel
                  className="formRadioField"
                  value="Provisional"
                  control={<Radio />}
                  label={t("provisional_prescription")}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="tskElemHeding mt10">{t("patient_info")}</div>
          <div className="pDemography videoPrescriptionStepThree">
            <div className="pDemogInd">
              <span className="pDVlue">{extraData.queuePatientName}</span>
            </div>
            <div className="pDemogInd">
              {/* <span className="pDVlue">{extraData.queuePatientGender}</span> */}
              <span className="pDVlue">
                {extraData?.queuePatientGender === "Male"
                  ? t("male")
                  : extraData?.queuePatientGender === "Female"
                  ? t("female")
                  : t("other")}
              </span>
            </div>
            <div className="pDemogInd">
              <span className="pDVlue">{extraData.queuePatientAge}</span>
            </div>
            {extraData?.reqValue?.insurance && (
              <div className="pDemogInd">
                <span>{t("insurance")}:</span>
                <span className="pDVlue">{t("yes")}</span>
              </div>
            )}
            {/* <div className="pDemogInd">
                <span>Card No:</span>
                <span className="pDVlue">012154121541</span>
              </div> */}
          </div>

          {/* vitals area */}
          <div className="pres-container">
            <div class=" leftside videoPrescriptionStepFour">
              <div className="vitalscontainer">
                {data &&
                  data?.vitals
                    ?.filter((vital) => {
                      if (
                        !vital.vitalValue ||
                        (vital.vitalCode === "BP" && vital.vitalValue === "/")
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    })
                    ?.map((vital) => {
                      let value = vital.vitalValue;
                      let foot = 0;
                      let inch = 0;
                      if (vital.unit === "ft~in") {
                        foot = parseInt(value / 12);
                        inch = parseInt(value % 12);
                      }
                      return (
                        <div className="vitals">
                          <div className="Pressurecontent">
                            <div className="Biconarea">
                              {getVitalSvgAccordingToVitalType(vital.vitalCode)}
                              {/* <BP /> */}
                            </div>

                            <div className="Btxt">
                              <div className="vitalnam">{vital.vitalName}</div>
                              {/* <div className="vitalshortfrom">BP</div> */}
                            </div>
                          </div>

                          <div className="presuremesurment">
                            <div className="Bunitarea ">
                              {vital?.unit === "ft~in" ? (
                                <div className="rate">{`${foot}${" ft "} ${inch} in`}</div>
                              ) : (
                                <div className="rate">{`${vital.vitalValue}${
                                  vital.unit && vital.vitalCode !== "LMP"
                                    ? `(${vital.unit})`
                                    : ""
                                }`}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
              {/* BMI area */}
              {height && weight && (
                <div className="bmiGroup presBmi">
                  <div className="c-bmi__result">
                    <span className="bmiCatName">{bmiClass}</span>:
                    <span name="r" className={` ${bmiClass}`}>
                      {bmi}
                    </span>
                  </div>
                  <div className="c-bmi__groups" readOnly>
                    {[
                      "Underweight",
                      "Normal",
                      "Pre-obesity",
                      "Obese I",
                      "Obese II",
                      "Obese III",
                    ].map((label, index) => (
                      <div key={index}>
                        <input
                          type="radio"
                          className="bmiInputRadio"
                          id={`bmi-g${index}`}
                          name="g"
                          checked={selectedGroup === index}
                          readOnly
                        />
                        <label htmlFor={`bmi-g${index}`}></label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* end */}
            </div>

            {data?.symptoms?.length > 0 && (
              <div className="rightside">
                <div className="vitalsactionarea">
                  <div className="vitalsinfo videoPrescriptionStepFive">
                    <div className="vitalheadngtxt">{t("complaints")}:</div>
                    <div className="complaintList">
                      <ul>
                        {data &&
                          data.symptoms &&
                          data.symptoms.map((item) => {
                            return (
                              <li>
                                <div className="complstDtlGrp">
                                  <div className="complstDtl  ">
                                    <div className="compntName">
                                      {item.selectedSymptom.symptomName}
                                    </div>

                                    <div className="compinfoGRP">
                                      {item.since && (
                                        <div className="compStime">
                                          {/* <span>Since:</span> */}
                                          <span className="data">
                                            {item.since}
                                          </span>
                                        </div>
                                      )}
                                      {item.severity && (
                                        <div className="compSeverity">
                                          {/* <span>Severity:</span> */}
                                          <span className="data">
                                            {item.severity}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>

                  {data?.singObj?.length > 0 && (
                    <div className="vitalsinfo videoPrescriptionStepSix">
                      <div className="vitalheadngtxt">
                        {t("examination_and_sign")}:
                      </div>
                      <div className="complaintList">
                        <ul>
                          {data &&
                            data.singObj &&
                            data.singObj.map((item) => {
                              return (
                                <li>
                                  <div className="complstDtlGrp">
                                    <div className="complstDtl  ">
                                      <div className="compntName">
                                        {item.sign.signName}
                                      </div>
                                      {item.userNote && (
                                        <div className="compinfoGRP">
                                          <div className="compStime">
                                            <span>{t("note")}:</span>
                                            <span className="data">
                                              {item.userNote}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {data?.comorbidity?.length > 0 && (
                    <div className="vitalsinfo videoPrescriptionStepSeven">
                      <div className="vitalheadngtxt">{t("comorbidity")}:</div>
                      <div className="complaintList">
                        <ul>
                          {data &&
                            data.comorbidity &&
                            data.comorbidity.map((item) => {
                              return (
                                <li>
                                  <div className="complstDtlGrp">
                                    <div className="complstDtl  ">
                                      <div className="compntName">
                                        {item.disease.comorbiditiesDiseasesName}
                                      </div>
                                      {item.since && (
                                        <div className="compinfoGRP">
                                          <div className="compStime">
                                            <span>{t("since")}:</span>
                                            <span className="data">
                                              {item.since}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {data?.riskFactor?.length > 0 && (
                    <div className="vitalsinfo videoPrescriptionStepEight">
                      <div className="vitalheadngtxt">{t("risk_factor")}:</div>

                      <ul className="vitlulinfo">
                        {data &&
                          data.riskFactor &&
                          data.riskFactor.map((item) => {
                            const sinceStr = item.since
                              ? `,(${item.since})`
                              : "";
                            return (
                              <li>
                                <div className="vitalinfosubtxt">
                                  {`${item.riskFactor.riskFactor} ${sinceStr}`}
                                </div>
                              </li>
                            );
                          })}

                        {/* <li>
                        <div className="vitalinfosubtxt">
                          Acute trauma, Since 2 Month
                        </div>
                      </li> */}
                      </ul>
                    </div>
                  )}

                  {data?.possibleDiagonesis?.length > 0 && (
                    <div className="vitalsinfo videoPrescriptionStepNine">
                      <div className="vitalheadngtxt">
                        {t("possible_diagnosis")}:
                      </div>
                      <div className="complaintList">
                        <ul>
                          {data &&
                            data.possibleDiagonesis &&
                            data.possibleDiagonesis.map((item) => {
                              return (
                                <li>
                                  <div className="complstDtlGrp">
                                    <div className="complstDtl  ">
                                      <div className="compntName">
                                        {item.diseaseName}
                                      </div>
                                      {item.since && (
                                        <div className="compinfoGRP">
                                          <div className="compStime">
                                            {/* <span>Since:</span> */}
                                            <span className="data">
                                              {t("provisional")}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {data?.investigation?.length > 0 && (
                    <div className="vitalsinfo videoPrescriptionStepTen">
                      <div className="vitalheadngtxt">
                        {t("investigations")}:
                      </div>
                      <ul className="vitlulinfo">
                        {data &&
                          data.investigation &&
                          data.investigation.map((item) => {
                            return (
                              <li>
                                <div className="vitalinfosubtxt">
                                  {item.testName}
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}

                  {data?.diagonesis?.length > 0 && (
                    <div className="vitalsinfo videoPrescriptionStepEleven">
                      <div className="vitalheadngtxt">
                        {t("diagnosis_condition")}:
                      </div>
                      <ul className="vitlulinfo">
                        {data &&
                          data.diagonesis &&
                          data.diagonesis.map((item) => {
                            return (
                              <li>
                                <div className="vitalinfosubtxt">
                                  {`${item.diseaseObj.diseaseName} [ ${
                                    item.diseaseObj.snowmedCt
                                      ? `SNOWMED ${item.diseaseObj.snowmedCt},`
                                      : ""
                                  } ${
                                    item.diseaseObj.icd11code
                                      ? `ICD ${item.diseaseObj.icd11code}`
                                      : ""
                                  } ] ${
                                    item.status &&
                                    `( ${t(item.status.toLowerCase())})`
                                  }`}
                                </div>
                              </li>
                            );
                          })}
                        {/* <li>
                        {" "}
                        <div className="vitalinfosubtxt">
                          Abetalipoproteinemia [ SNOWMED CT-190787008, ICD 10
                          CD-E786 ](Provisional Diagnosis)
                        </div>
                      </li> */}
                      </ul>
                    </div>
                  )}

                  {data?.procedure?.length > 0 && (
                    <div className="vitalsinfo videoPrescriptionStepTwelve">
                      <div className="vitalheadngtxt">{t("procedure")}:</div>

                      <ul className="vitlulinfo">
                        {data &&
                          data.procedure &&
                          data.procedure.map((item) => {
                            const procDate = item.date
                              ? `(${dayjs(item.date).format("DD-MMM-YYYY")})`
                              : "";
                            return (
                              <li>
                                <div className="vitalinfosubtxt">
                                  {`${item.procedure.procedureName} ${procDate}`}
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {data?.medication?.length > 0 && (
            <div className="doctoradvicearea videoPrescriptionStepThirteen">
              <div className="tskElemHeding">{t("advice")}</div>
              <table className="taskListTable mt6">
                <thead className="taskListtableHeader">
                  <tr>
                    {/* <th className="advth"></th> */}
                    <th className="advth">{t("generic_name")}</th>
                    <th className="advth textCenter">{t("quantity_dose")}</th>
                    <th className="advth textCenter">
                      {t("frequency_timing")}
                    </th>
                    <th className="advth textCenter">{t("duration")}</th>
                    <th className="advth">{t("note")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.medication &&
                    data.medication.map((item) => {
                      return (
                        <tr>
                          {/* <td>
                              <div className="mediconarea">
                                <Tablets />
                              </div>
                            </td> */}
                          <td>
                            <div className="complstDtl">
                              <div className="compntName">
                                {item?.genericName?.generic}
                              </div>
                              <div className="compntName BrandMed">
                                {item.brandName && item.brandName.brandName}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="mednam textCenter">
                              {item?.dosage?.dosage}
                            </div>
                          </td>
                          <td>
                            <div className="mednam textCenter">
                              {item?.frequency?.frequency}
                            </div>
                          </td>
                          <td>
                            <div className="mednam textCenter">
                              {item?.duration?.durationName}
                            </div>
                          </td>
                          <td>
                            <div className="mednam ">{item?.note}</div>
                          </td>
                        </tr>
                      );
                    })}

                  {/* <tr>
                    <td>
                      <div className="mediconarea">
                        <Capsule />
                      </div>
                    </td>
                    <td>
                      <div className="complstDtl">
                        <div className="compntName">Calcigen D3</div>
                        <div className="compntName BrandMed"></div>
                      </div>
                    </td>
                    <td>
                      <div className="mednam textCenter">1</div>
                    </td>
                    <td>
                      <div className="mednam textCenter">OD HS</div>
                    </td>
                    <td>
                      <div className="mednam textCenter">1 month</div>
                    </td>
                    <td>
                      <div className="mednam">medicine taken notes</div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="mediconarea">
                        <Syrup />
                      </div>
                    </td>
                    <td>
                      <div className="complstDtl">
                        <div className="compntName">Ascoril LS Syrup</div>
                        <div className="compntName BrandMed"></div>
                      </div>
                    </td>
                    <td>
                      <div className="mednam textCenter">15ml</div>
                    </td>
                    <td>
                      <div className="mednam textCenter">TDS</div>
                    </td>
                    <td>
                      <div className="mednam textCenter">5 Day</div>
                    </td>
                    <td>
                      <div className="mednam">medicine taken notes</div>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          )}

          {data?.itemToMonitor?.length > 0 && (
            <div className="taskElementGrp mt10 videoPrescriptionStepFourteen">
              <div className="tskElemHeding">
                {t("parameter_to_be_monitored")}
              </div>
              <div className="tskElemntInfo">
                {data && data.itemToMonitor && data.itemToMonitor.join(",")}
                {/* Blood Pressure, Pulse Rate, Oxygen Saturation, Respiration Rate */}
              </div>
            </div>
          )}

          {data?.diet?.length > 0 && (
            <div className="taskElementGrp mt10 videoPrescriptionStepFifteen">
              <div className="tskElemHeding">{t("diet_recommendations")}</div>
              <div className="tskElemntInfo">
                {data &&
                  data.diet &&
                  data.diet.map((item) => item.diet).join(", ")}
                {/* Diabetic Diet, Dash Diet, Dash Diet */}
              </div>
            </div>
          )}

          {data?.lifeStyle?.length > 0 && (
            <div className="taskElementGrp mt10 videoPrescriptionStepSixteen">
              <div className="tskElemHeding">
                {t("lifestyle_recommendations")}
              </div>
              <ul className="vitlulinfo">
                {data &&
                  data.lifeStyle &&
                  data.lifeStyle.map((item) => {
                    return (
                      <li>
                        <div className="vitalinfosubtxt">{item.lifeStyle}</div>
                      </li>
                    );
                  })}
                {/* <li>
                  <div className="vitalinfosubtxt">
                    Drink eight 8-ounce glasses water daily
                  </div>
                </li>
                <li>
                  <div className="vitalinfosubtxt">
                    Don't consume tobacco, alcohol and any others substance
                    abuse elements.
                  </div>
                </li> */}
              </ul>
            </div>
          )}

          {extraData &&
            extraData.queueReferredBy &&
            extraData.queueReferredBy !== "" && (
              <div className="taskElementsGrpWithTwo mt10 videoPrescriptionStepSeventeen">
                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("refered_by")}</div>
                  <div className="tskElemntInfo">
                    {extraData.queueReferredBy}
                  </div>
                </div>
              </div>
            )}

          {data && data.referType && (
            <>
              <div className="taskElementsGrpWithTwo mt10 videoPrescriptionStepEightteen">
                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("refer")}</div>
                  <div className="tskElemntInfo">
                    <span>{`${getReferStringFromType(data.referType)}`}</span>

                    {(data.referType === "higherFacility" ||
                      data.referType === "immediateHospitalization") && (
                      <div>
                        {data.hospital
                          ? `( ${data.hospital?.hospitalName || ""} )`
                          : ""}
                      </div>
                    )}

                    {data.referType === "otherSpeciality" && (
                      <div>
                        {`${
                          data.referDoctorSpeciality
                            ? `( ${
                                data.referDoctorSpeciality.specializationName
                              }  ${
                                data.doctorName ? "- " + data.doctorName : ""
                              } )`
                            : ""
                        }`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* {data?.referType === "medicoLegal" && (
                <div className="taskElementsGrpWithTwo mt10">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("referral_note")}</div>
                    <div className="tskElemntInfo">
                      <div>{data?.referralNote || ""}</div>
                    </div>
                  </div>
                </div>
              )} */}
            </>
          )}
          {data && data.reviewDate && (
            <div className="taskElementsGrpWithTwo mt10 videoPrescriptionStepNineteen">
              <div className="taskElementGrp">
                <div className="tskElemHeding">{t("next_review")}</div>
                <div className="tskElemntInfo">
                  {dayjs(DateUtils.convertToDate(data.reviewDate)).format(
                    "DD-MMM-YYYY"
                  )}
                </div>
              </div>
            </div>
          )}

          {data?.doctorNote && (
            <div className="taskElementsGrpWithTwo mt10 videoPrescriptionStepTwenty">
              <div className="taskElementGrp">
                <div className="tskElemHeding">{t("doctor_note")}</div>
                <div className="tskElemntInfo">{data.doctorNote}</div>
              </div>
            </div>
          )}
          {data?.audioData && (
            <div className="taskElementGrp videoPrescriptionStepTwentyOne">
              <div className="tskElemntInfo">
                <div className="playAudioNotes">
                  <IconButton
                    variant="outlined"
                    onClick={() => {
                      audioUrl.current = data.audioData;
                      setShowAudioModal(true);
                    }}
                  >
                    <Play />
                  </IconButton>
                  <span>{t("play_audio_message")}</span>
                </div>
              </div>
            </div>
          )}
          {data?.watchoutFor && (
            <div className="taskElementsGrpWithTwo mt10 videoPrescriptionStepTwentyTwo">
              <div className="taskElementGrp">
                <div className="tskElemHeding">{t("watch_out_for")}</div>
                <div className="tskElemntInfo">{data.watchoutFor}</div>
              </div>
            </div>
          )}

          {/* <div className="actionareacontainer">
              <div className="actionarea">
                <div className="takeaction anrml">
                  <div className="actnoriconarea">
                    <img src={like} alt={"vimg"} class="acticn" />
                  </div>
                  <div className="actiotxt">Normal</div>
                </div>

                <div className="takeaction attentn">
                  <div className="actnoriconarea">
                    <img src={eye} alt={"vimg"} class="acticn" />
                  </div>
                  <div className="actiotxt">Needs Attention</div>
                </div>

                <div className="takeaction critcl">
                  <div className="actnoriconarea">
                    <img src={critical} alt={"vimg"} class="acticn" />
                  </div>
                  <div className="actiotxt">Requires Action</div>
                </div>
              </div>

              <div className="scanareacontainer">
                <div className="scaniconarea">
                  <img src={scan} alt={"vimg"} class="acticn" />
                </div>
              </div>
            </div> */}
        </div>
      </div>
      {showAudioModal && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel videoPrescriptionStepTwentyThree">
            <div className="modelTitle">{t("audio_message")}</div>
            <div className="modActionBtnGrp">
              <audio controls>
                <source src={audioUrl.current} />
              </audio>
            </div>

            <div className="modActionBtnGrp">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  setShowAudioModal(false);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </Box>
      )}
    </>
  );
}
