import React, { useState } from "react";
import "../css/TicketBodyRightSide.css";
import { Button, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { apiEndPoint } from "../../../constants/url_provider";
import DateUtils from "../../../utils/DateUtils";
import TicketComments from "./TicketComments";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../common/TourProvider";
import { useEffect } from "react";

const TicketBodyRightSide = ({ ticket, addedComment }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
  };

  const [openModal, setOpenModal] = useState(false);

  const openImageView = (imageDto) => {
    setSelectedImage(imageDto);
    setOpenModal(true);
  };

  const closeImageView = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  const downloadFile = () => {
    console.log("downloadFile", selectedImage);
    if (selectedImage.field4 !== null) {
      toast.info(t("please_wait_file_is_getting_get_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      const newLink = selectedImage.field4.replace(/\//g, "~");
      console.log("newLink", newLink);

      let newFolderLink = selectedImage.field5
        .replace(/\//g, "~")
        .replace(/\\/g, "~");
      console.log("newLink", newFolderLink);

      window.open(
        apiEndPoint + `/appTest/downloadFileTest/${newLink}/${newFolderLink}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      toast.error(t("the_file_cannot_be_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(()=>{
   setBookSteps([
    {
      target: ".SupportTickRightStepOne",
      content: "This is the unique identifier for the ticket.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".SupportTickRightStepTwo",
      content: "This section shows the category of the issue raised.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".SupportTickRightStepThree",
      content: "This section shows the Issue Name.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".SupportTickRightStepFour",
      content: "This section shows the Issue Description.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".SupportTickRightStepFive",
      content: "This section shows the Issue Priority.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".SupportTickRightStepSix",
      content: "This section shows the Issue Created Time And Date.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".SupportTickRightStepSeven",
      content: "Here are the files uploaded for this ticket.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".SupportTickRightStepEight",
      content: "This section displays comments related to the ticket.",
      placement: "top",
      disableBeacon: true,
    },
  ])
  },[])

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["rightSideSupportDetailsST"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
    <div className="ticket-container">
      {/* <h2>Ticket Information</h2> */}
      <div className="ticket-details">
        <div className="ticket-details-body">
          <div className="ticket-row SupportTickRightStepOne">
            <p className="ticket-label">{t("ticket_id")}:</p>
            <p className="ticket-value">{ticket.supportDisplayId}</p>
          </div>
          <div className="ticket-row SupportTickRightStepTwo">
            <p className="ticket-label">{t("issue_category")}:</p>
            <p className="ticket-value">{ticket.issueCategory}</p>
          </div>
          <div className="ticket-row SupportTickRightStepThree">
            <p className="ticket-label">{t("issue_name")}:</p>
            <p className="ticket-value">{ticket.issueName}</p>
          </div>
          <div className="ticket-row SupportTickRightStepFour">
            <p className="ticket-label">{t("issue_description")}:</p>
            <p className="ticket-value">{ticket.issueDescription}</p>
          </div>
          <div className="ticket-row SupportTickRightStepFive">
            <p className="ticket-label">{t("priority")}:</p>
            <p className="ticket-value">{ticket.priority}</p>
          </div>
          <div className="ticket-row SupportTickRightStepSix">
            <p className="ticket-label">{t("created_date_and_time")}:</p>
            <p className="ticket-value">
              {DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                ticket.createdDateTime
              )}
            </p>
          </div>
        </div>

        {ticket.uploadedFiles && ticket.uploadedFiles.length > 0 && (
          <div className="uploaded-files SupportTickRightStepSeven">
            <h3>
              {t("uploaded_images")}: {ticket.uploadedFiles.length}
            </h3>
            <div className="file-list">
              {ticket.uploadedFiles.map((file, index) => (
                <div key={index} className="file-details">
                  {isImage(file.field2) ? (
                    <div className="image-preview-container">
                      <img
                        src={file.field2}
                        alt={file.field1}
                        className="file-image-preview"
                        onClick={() => openImageView(file)}
                      />
                      {/* <a
                        href={file.field2}
                        download
                        className="download-button"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a> */}
                    </div>
                  ) : (
                    <p>
                      <strong>File Name:</strong> {file.field1}
                      <br />
                      <a
                        href={file.field2}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("download_file")}
                      </a>
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {ticket && ticket.taskId > 0 && (
        <div className="ticketComments SupportTickRightStepEight">
          <TicketComments ticket={ticket} addedComment={addedComment} />
        </div>
      )}

      {/* {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Selected" />
          </div>
        </div>
      )} */}
      <Modal
        open={openModal}
        onClose={closeImageView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "600px",
            minHeight: "150px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <img src={selectedImage?.field2} alt="Selected" />
          <br></br>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              // variant="contained"
              // color="secondary"
              className="dfultPrimaryBtn"
              onClick={downloadFile}
            >
              {t("download")}
            </Button>
            <Button
              //  variant="contained"
              className="dfultDarkSecondaryBtn"
              onClick={closeImageView}
            >
              {t("close")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default TicketBodyRightSide;
