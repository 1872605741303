import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Pie } from "react-chartjs-2";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function CardPayroll({ 
  handelOpenViewPayroll,
  chartDataOfItem,
  menuModuleList,
  userMenuList
 }) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const { t } = useTranslation();

  const pieData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const pieOptions = {
    responsive: true,

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">{t("payroll")}</div>
            <div className="andCrdUnitCount">
              <span title="Attrition">
                {/* <Male /> */}
                {t("active_employee")}:
                <span className="fbold">3250</span>
              </span>

              <span title="Tenure">
                {/* <Female /> */}
                {t("tenure")}: <span className="fbold"></span>
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={pieData}
                  options={pieOptions}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">
                    {/* {chartDataOfItem.noOfEmployee} */}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Bar
                className="hw100"
                type="bar"
                data={barData}
                options={barOptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          <div className="andCrdFooter">
          {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Payroll")
                  .menuId
              ) && (
            <Button
              startIcon={<Eye />}
              className="dfultPrimaryBtn "
              onClick={() => handelOpenViewPayroll()}
            >
              {t("view")}
            </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
