import {
  AssignmentLateOutlined,
  CheckCircle,
  ListAlt,
  Send,
  ShortcutOutlined,
  VideoCall,
} from "@mui/icons-material";
import { Box, Button, IconButton, Popover, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Diagram3, People, Play, XLg } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../redux/reducers/rightSideTaskPanelSlice";
import {
  pushLinkTask,
  setTaskModalData,
} from "../../../redux/reducers/taskTab";
import DashboardServices from "../../../services/DashboardServices";
import TaskService from "../../../services/TaskService";
import { getFormattedDate, GetLoginUserDetails } from "../../../utils/Utils";
import { homeContainerContext } from "../../dashboard/view/component/HomeContainer";
import "../css/task.css";
import isAssignedByMe from "../taskUtil";
import AssigneeModal from "./component/AssigneeModal";
import ConcernTaskModal from "./component/ConcernTaskModal";
import FileList from "./component/FileList";
import PmsFinalReviewForm from "./component/Forms/PmsFinalReviewForm";
import PmsManagerReviewForm from "./component/Forms/PmsManagerReviewForm";
import { PurchaseFormRenderer } from "./component/Forms/PurchaseFormRenderer";
import SelfEvaluationPmsForm from "./component/Forms/SelfEvaluationPmsForm";
import ForwardTaskModal from "./component/ForwardTaskModal";
import LinkTaskModal from "./component/LinkTaskModal";
import { LinkedTaskModule } from "./component/LinkedTaskModule";
import MeetingModal from "./component/Modals/MeetingModal";
import RightFlotingContainer from "./component/RightFlotingContainer";
import RightTaskFileUpload from "./component/RightTaskFileUpload";
import TemplateList from "./component/TemplateList";
import { useTimeProgressor } from "./component/hooks/useTimerProgressor";
import PmsHRReviewForm from "./component/Forms/PmsSalaryAdjustmentForm";
import PmsSalaryAdjustmentForm from "./component/Forms/PmsSalaryAdjustmentForm";
import { useTranslation } from "react-i18next";

export default function TaskIncomingOngoing({
  data,
  refreshData,
  removeFromStore,
}) {
  const context = useContext(homeContainerContext);
  const { t } = useTranslation();
  console.log("testing 12345 -------\\\\\\.......");
  const { setShowConvenientMeetingView } = context;
  const convinientSelecteduser = context.setSelectedUsers;
  const setMeetingList = useRef();
  const [showMeetingModal, setShowMeetingsModal] = useState(false);
  const audioUrl = useRef();
  const scrollRef = useRef();
  // const [audioUrl, setAudioUrl] = useState();
  const numberList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const [tempUpdatePercent, setTempUpdatePercent] = useState(0);
  const loginUserDetail = GetLoginUserDetails();
  const [progressWidth, setProgressWidth] = useState(0);

  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);

  // left right tags scroll
  const elementRef = useRef(null);
  const [assigneeList, setAssigneeList] = useState([]);
  const commentInputRef = useRef();
  const dispatch = useDispatch();
  const fileDocuments = useRef(null);
  const [showForwardTask, setShowForwardTask] = useState(false);
  const [userList, setUserList] = useState([]);
  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);

  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [showConcernModal, setShowConcernModal] = useState(false);

  const [updatePercentValue, setUpdatePercentValue] = useState(0);

  const [approvalConPop, setApprovalConPop] = React.useState(null);
  const open = Boolean(approvalConPop);
  const id = open ? "simple-popover" : undefined;

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showAssigneeList, setShowAssigneeList] = useState(false);
  const [showFileModal, setShowFileUploadModal] = useState(false);

  const [formData, setFormData] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  const clearStoreData = () => {
    dispatch(clearSelectedTask());
  };

  const closeApprovalConPop = () => {
    setApprovalConPop(null);
  };

  const handleLinkTaskClick = async (task) => {
    // ;
    const values = await DashboardServices.getTaskById(task.taskId);
    dispatch(pushLinkTask(data));
    dispatch(setTaskModalData({ ...task, ...values.data }));
  };

  const handleClickFormButton = () => {
    if (
      data.taskNature === "PMS_SELF_EVALUATION" ||
      data.taskNature === "PMS_MANAGER_REVIEW" ||
      data.taskNature === "PMS_FINAL_REVIEW" ||
      data.taskNature === "PMS_SALARY_ADJUSTMENT"
    ) {
      setShowForm(true);
      return;
    }

    // TaskService.getIndentItemsByIndentId(data.formDataTransId).then(
    //   (response) => {
    //     if (response.data) {
    //       setFormData(response.data);
    //       setShowForm(true);
    //     }
    //   }
    // );
    if (data.taskNature === "INTERVIEW_FEEDBACK") {
      TaskService.getProspectiveEmployeeById(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    } else {
      TaskService.getExpenseApprovalRequest(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    }
  };

  const openApprovalConPop = (event) => {
    clearAllModals();
    setApprovalConPop(event.currentTarget);
    setUpdatePercentValue(data.progress);
  };

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    let addedTemplateList = [];
    const updatedData = { ...data };
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        } else {
          addedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
      if (updatedData.template && updatedData.templates.length > 0) {
        updatedData.templates = [...data.templates, ...addedTemplateList];
      } else {
        updatedData.templates = [...addedTemplateList];
      }
      dispatch(setSelectedTask(updatedData));
    }
    // const tempAddedTemplate = data.templates.filter(
    //   (template) => template.taskTemplateId !== 0
    // );
    // setAddedTemplateList(tempAddedTemplate);
    setShowAddTemplate(true);
  };

  const clearAllModals = () => {
    setShowForwardTask(false);
    setShowConcernModal(false);
    setShowCreateLinkTask(false);
    setShowAssigneeList(false);
    closeApprovalConPop();
    // setShowApproveModal(false);
    // setShowPostpone(false);
    // setShowReject(false);
  };

  const handleTaskSubmission = (response) => {
    // Failure message start
    if (response.data.returnValue === "0") {
      toast.error(t("something_went_wrong"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-1") {
      toast.error(t("link_task_not_completed_against_this_task"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-2") {
      toast.error(t("task_has_been_cancelled"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-3") {
      toast.error(t("task_has_been_closed"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-4") {
      toast.error(t("task_has_been_completed"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // Failure message end

    // Success message start
    else if (response.data.returnValue === "1") {
      toast.success(t("task_completed_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "2") {
      toast.success(t("concern_raised_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "3") {
      toast.success(t("approved_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "4") {
      toast.success(t("task_progress_report_submitted_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    const temp = response.data.returnValue;
    if (temp === "1" || temp === "2" || temp === "3" || temp === "4") {
      setShowConvenientMeetingView(false);
      convinientSelecteduser([]);
      dispatch(clearSelectedTask());
      refreshData();
    }
    // Success message end
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setSelectedTask(tempData));
      }
    });
  };

  const sendComment = (e) => {
    if (!data) {
      toast.error(t("something_went_wrong"));
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];
            dispatch(setSelectedTask(tempData));
            commentInputRef.current.value = "";
            const timeoutValue = setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
              clearTimeout(timeoutValue);
            }, 500);
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      );
    }
  };

  const calculateDateProgress = () => {};

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  useEffect(() => {
    setProgressWidth(data.progress);
    calculateDateProgress();
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        setUserList(response.data);
      }
    );
  }, [data]);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              <div className="taskVDContBtn">
                <Button
                  variant="contained"
                  startIcon={<CheckCircle />}
                  onClick={(e) => {
                    clearAllModals();
                    openApprovalConPop(e);
                  }}
                  // onClick={(e) => {
                  //   ;
                  //   setShowUpdateModal(true);
                  //   setUpdatePercentValue(data.progress);
                  // }}
                >
                  {t("update")}
                </Button>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={approvalConPop}
                  onClose={closeApprovalConPop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    <div className="modelTitle">{t("confirmation")}</div>
                    <div
                      className="modInputFild"
                      style={{ padding: "20px 10px 10px 0px" }}
                    >
                      {t("select_your_progress_of_task")}
                    </div>
                    <div className="workUpdateBtnGrp">
                      {}
                      {numberList &&
                        numberList.map((number) => (
                          <Button
                            className={`${
                              tempUpdatePercent === number ? "activeStatus" : ""
                            }`}
                            variant="contained"
                            disabled={number <= updatePercentValue}
                            onClick={(e) => {
                              setTempUpdatePercent(number);
                            }}
                          >
                            {number}
                          </Button>
                        ))}
                    </div>

                    <div className="modActionBtnGrp">
                      <Button
                        className="dfultPrimaryBtn"
                        onClick={(e) => {
                          if (tempUpdatePercent === 0) {
                            toast.error(t("please_select_percentage_of_work"));
                            return;
                          }
                          DashboardServices.updateProgressOfTask(
                            data.taskId,
                            loginUserDetail.userId,
                            tempUpdatePercent
                          ).then((response) => {
                            if (response.data.returnValue !== "0") {
                              toast.success(t("task_progress_updated"));
                              const temp = { ...data };
                              temp.progress = tempUpdatePercent;
                              dispatch(setSelectedTask(temp));
                              setProgressWidth(tempUpdatePercent);
                              setUpdatePercentValue(tempUpdatePercent);
                              closeApprovalConPop();
                              if (tempUpdatePercent === 100) {
                                dispatch(clearSelectedTask());
                                refreshData();
                              }
                            } else {
                              toast.error(t("something_went_wrong"));
                            }
                          });
                        }}
                      >
                        {tempUpdatePercent === 100
                          ? t("mark_as_completed")
                          : t("update")}
                      </Button>
                      <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={closeApprovalConPop}
                      >
                        {t("cancel")}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
              {/* <div className="taskVDContBtn">
                <Button variant="outlined">Approve</Button>
              </div>
              <div className="taskVDContBtn">
                <Button variant="outlined">Reject</Button>
              </div> */}
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="Forward"
                  onClick={() => {
                    clearAllModals();
                    setShowForwardTask(true);
                  }}
                >
                  <Tooltip title={t("delegate")} arrow>
                    <ShortcutOutlined />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="Concern"
                  onClick={(e) => {
                    clearAllModals();
                    setShowConcernModal(true);
                  }}
                >
                  <Tooltip title={t("concern")} arrow>
                    <AssignmentLateOutlined />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="Link Task"
                  onClick={(e) => {
                    clearAllModals();
                    setShowCreateLinkTask(true);
                  }}
                >
                  <Tooltip title={t("link_task")} arrow>
                    <Diagram3 />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="Meeting"
                  onClick={(e) => {
                    TaskService.getEventsofTask(data.taskId).then(
                      (response) => {
                        setMeetingList.current = response.data;
                        if (response.data && response.data.length > 0) {
                          setShowMeetingsModal(true);
                        } else {
                          setShowConvenientMeetingView(true);
                        }
                      }
                    );
                  }}
                >
                  <Tooltip title={t("meeting")} arrow>
                    <VideoCall />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    clearAllModals();
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title={t("user")} arrow>
                    <People />
                  </Tooltip>
                </IconButton>
                <Popover
                  id={ulId}
                  open={openUl}
                  anchorEl={anchorUl}
                  onClose={handleCloseUl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ul>
                    {assigneeList &&
                      assigneeList.map((assignee) => <li>{assignee.name}</li>)}
                  </ul>
                  {/* <Typography sx={{ p: 2 }}>
                    The content of the Popover.
                  </Typography> */}
                </Popover>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    setShowConvenientMeetingView(false);
                    convinientSelecteduser([]);
                    dispatch(clearSelectedTask());
                  }}
                >
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data.taskName}</div>
                </div>

                {data && data.taskDescription && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("description")}</div>
                    <div className="tskElemntInfo">{data.taskDescription}</div>
                  </div>
                )}

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="playAudioNotes">
                        <IconButton
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>{t("play_audio_message")}</span>
                      </div>
                    </div>
                  </div>
                )}
                {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("contract")}</div>
                    <div className="taskContractNm">{data.contractName}</div>
                  </div>
                )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("status")}</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // ps50 ps80 ps100
                          className={`progress-done ${
                            progressWidth < 50
                              ? "ps50"
                              : progressWidth < 80
                              ? "ps80"
                              : "ps100"
                          }`}
                          style={{ width: `${progressWidth}%`, opacity: 1 }}
                        >
                          <span className="pwCount">{progressWidth}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("timeline")}</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.taskCreationTime)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.taskCompletionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Templates start */}
                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Template</div>

                  <div class="elementFileListedGrp">
                    <div className="horaizonScroll">
                      <IconButton
                        className="goPrevousBtn"
                        onClick={() => {
                          handleHorizantalScroll(
                            elementRef.current,
                            25,
                            200,
                            -10
                          );
                        }}
                        disabled={arrowDisable}
                      >
                        <ArrowBackIosNew />
                      </IconButton>
                      <div>
                        <div class="elemntFilsgrp" ref={elementRef}>
                          {data &&
                            data.templates.map((template) => (
                              <div className="elemntFile">
                                <Tooltip
                                  title={template.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {template.fileDisplayName}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                      </div>
                      {tempDivWidth > 470 && (
                        <IconButton
                          className="goNextBtn"
                          onClick={() => {
                            handleHorizantalScroll(
                              elementRef.current,
                              25,
                              200,
                              10
                            );
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      )}
                    </div>
                    <div class="elmntAddMrFiles">
                      <Button
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleAddTemplate}
                      >
                        <Plus />
                      </Button>
                    </div>
                  </div>
                </div> */}

                <TemplateList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleAddTemplate={handleAddTemplate}
                />
                {/* Templates end */}

                {/* files start */}
                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Files</div>

                  <div class="elementFileListedGrp">
                    <div className="horaizonScroll">
                      <IconButton
                        className="goPrevousBtn"
                        onClick={() => {
                          filehandleHorizantalScroll(
                            fileElementRef.current,
                            25,
                            200,
                            -10
                          );
                        }}
                        disabled={filearrowDisable}
                      >
                        <ArrowBackIosNew />
                      </IconButton>

                      <div class="elemntFilsgrp" ref={fileElementRef}>
                        {data &&
                          data.documents &&
                          data.documents.map((doc) => (
                            <div className="elemntFile">
                              <Tooltip
                                title={doc.fileDisplayName}
                                arrow
                                className="elmFileTolTip"
                              >
                                <span className="elemntFilIoc">
                                  <DescriptionOutlined />
                                </span>
                                <span className="elemntFilNm">
                                  {doc.fileDisplayName}
                                </span>
                              </Tooltip>
                            </div>
                          ))}
                      </div>
                      {fileDivWidth > 470 && (
                        <IconButton
                          className="goNextBtn"
                          onClick={() => {
                            filehandleHorizantalScroll(
                              fileElementRef.current,
                              25,
                              200,
                              10
                            );
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      )}
                    </div>
                    <div class="elmntAddMrFiles">
                      <Button
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleAddnewFile}
                      >
                        <Plus />
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleChangeFile}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div> */}

                <FileList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />

                {/* files end */}

                {data &&
                  data.formDataTransId &&
                  data.formDataTransId !== "" &&
                  data.formDataTransId !== "0" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">{t("form_data")}</div>

                      <IconButton
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleClickFormButton}
                      >
                        <ListAlt />
                      </IconButton>
                    </div>
                  )}

                {/* {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <IconButton
                      className="elemntMrFileBtn"
                      variant="outlined"
                      onClick={(e) => {
                        DashboardServices.generatePreSignedUrlS3Object(
                          data.audioNoteFileName,
                          loginUserDetail.orgId
                        ).then((response) => {
                          audioUrl.current = response.data;
                          setShowAudioModal(true);
                        });
                      }}
                    >
                      <Audiotrack />
                    </IconButton>
                  </div>
                )} */}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("priority")}</div>
                  <div className="tskPrioArea">
                    {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class={`priStat `}>
                        {data.priority === 0
                          ? t("regular")
                          : data.priority === 1
                          ? t("important")
                          : t("critical")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Department</div>
                    <div className="tskElemntInfo">Administration</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Assigned to</div>
                    <div className="tskElemntInfo">Me</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("assigned_by")}</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? t("me")
                        : data.assignByUserName}
                      {/* {data && data.assignByUserName && data.assignByUserName} */}
                    </div>
                  </div>
                </div>

                <LinkedTaskModule
                  data={data}
                  handleLinkTaskClick={(data) => handleLinkTaskClick(data)}
                />

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("last_updated")}</div>
                  <div className="tskElemntInfo">
                    {data &&
                      data.comments &&
                      data.comments.length &&
                      `${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                    {/* Sandeep Mohapatra - 2:15PM, 06-12-2023 */}
                  </div>
                </div>

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder={t("update_comment_here")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>

        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
            clearStoreData={clearStoreData}
          />
        )}

        {showMeetingModal && (
          <MeetingModal
            handleClose={(e) => setShowMeetingsModal(false)}
            assigneeList={assigneeList}
            taskId={data.taskId}
          />
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={(e) => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showConcernModal && (
          <ConcernTaskModal
            data={data}
            refreshData={refreshData}
            handleClose={(e) => setShowConcernModal(false)}
            removeFromStore={removeFromStore}
          />
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}
      </div>
      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setSelectedTask(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}

      {showForm &&
        (data.taskNature === "SUBMIT_RFP" ||
          data.taskNature === "PO_APPROVAL_REQUEST" ||
          data.taskNature === "GRN_ENTRY" ||
          data.taskNature === "PAYMENT_AGAINST_REIMBURSEMENT_REQUEST" ||
          data.taskNature === "PAYMENT_AGAINST_ADVANCE_REQUEST" ||
          data.taskNature === "PAYMENT_AGAINST_SIP_REQUEST" ||
          data.taskNature === "SEARCH_MANPOWER" ||
          data.taskNature === "SHORTLIST_RESUMES" ||
          data.taskNature === "INTERVIEW_FEEDBACK") && (
          <PurchaseFormRenderer
            data={data}
            taskId={data.taskId}
            taskNature={data.taskNature}
            formData={formData}
            handleClose={() => {
              setShowForm(false);
            }}
            handleSubmitPO={(date) => {
              if (date) {
                let reqDto = {
                  taskDetailId: data.taskId,
                  orgId: loginUserDetail.orgId,
                  documents: [],

                  taskCreator: "N",
                  taskForwordToUserIdAfterApproval: "",
                  additionalInfo: {
                    "Effective Date": date,
                  },
                  progress: data.progress,
                  concern: data.concern,
                  comments: data.comments,
                  submitButtonLabel: data.submitButtonLabel,
                  partialSubmitChecked: data.progress === 100 ? "N" : "Y",
                  previousAssigness: [],
                  currentAssigness: [],
                };
                // return;
                DashboardServices.updateProgressOfTask(
                  loginUserDetail.userId,
                  reqDto
                ).then((response) => {
                  handleTaskSubmission(response);
                });
              }
            }}
          />
        )}

      {showForm && data.taskNature === "PMS_SELF_EVALUATION" && (
        <SelfEvaluationPmsForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}
        />
      )}

      {showForm && data.taskNature === "PMS_MANAGER_REVIEW" && (
        <PmsManagerReviewForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}
        />
      )}

      {showForm && data.taskNature === "PMS_FINAL_REVIEW" && (
        <PmsFinalReviewForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}
        />
      )}

      {showForm && data.taskNature === "PMS_SALARY_ADJUSTMENT" && (
        <PmsSalaryAdjustmentForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}
        />
      )}

      {/* {showForm && (
        <RfpForm
          taskId={data.taskId}
          taskNature={data.taskNature}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
        />
      )} */}
    </>
  );
}
