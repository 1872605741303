import React, { useEffect, useState } from "react";
import EmailCard from "../cards/EmailCard";
import FilterCard from "../cards/FilterCard";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoaderSmall from "../../../../../utils/LoaderSmall";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../../../utils/IndexedDB";

export default function EmailMenuComponent({
  loading,
  setLoading,
  dataList,
  selectedData,
  onClick,
  openRightMail,
}) {
  const { t } = useTranslation();
  const dashboardMails = useSelector(
    (state) => state.dashboardMail.unreadMessages
  );
  const [activeMsgId, setActiveMsgId] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    console.log("in MailMenuComponent use Effect = ", dashboardMails);
    const getMailId = async () => {
      const userEmailAddress = await SecureIndexedDB.getItem("current_mailId");
      console.log("user email id", typeof userEmailAddress);
      if (userEmailAddress && userEmailAddress !== "null") {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    };
    getMailId();
  }, []);
  const setDataNull = () => {
    onClick(null);
  };
  return (
    <>
      <div className="mnuCompoContainer">
        {/* <FilterCard /> */}

        <div className="mnuCompoIncomingInstanse">
          {loading && <LoaderSmall />}
          {isAuthenticated &&
            dashboardMails &&
            dashboardMails.length > 0 &&
            dashboardMails.map((mail) => (
              <EmailCard
                mail={mail}
                openRightMail={openRightMail}
                setDataNull={setDataNull}
                active={activeMsgId}
                setActiveMsgId={setActiveMsgId}
              />
            ))}
          {!isAuthenticated && (
            <>
              <div className="noDataCard">
                <span>{t("oops")}</span>
                {t("authenticate_email")}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
