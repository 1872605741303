import { Button, Modal, TextField } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import { requestOtp, resendOtp, validateOtp } from "../services/AdminService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "./Utils";

export default function GettingServiceOtp({ onSubmitOtp, otpServiceId }) {
  const { t } = useTranslation();
  const userDetails = GetLoginUserDetails();
  const [otpValue, setOtpValue] = useState("");
  const [timeLeft, setTimeLeft] = useState(120);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [requestIdData, setRequestIdData] = useState("");

  // Use ref to store the start time of the countdown
  const countdownStartRef = useRef(Date.now());

  const handleOtpValueChange = (event) => {
    setOtpValue(event.target.value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmitOtp = async () => {
    if (otpValue.trim() === "") {
      toast.error("Please Enter OTP", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    toast.info("Validating OTP", {
      position: toast.POSITION.TOP_RIGHT,
    });

    if (otpValue && requestIdData) {
      const reqDto = {
        requestId: requestIdData,
        otp: otpValue,
      };

      try {
        const response = await validateOtp(reqDto);
        if (response.data.returnValue == "1") {
          toast.success(t("otp_validated_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setOpenModal(false);
          onSubmitOtp(true, otpValue, requestIdData);
        } else {
          toast.error(t("failed_to_validate_otp"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setOpenModal(true);
        }
      } catch (error) {
        console.error("Error validating OTP", error);
        toast.error(t("an_error_occurred_while_validating_otp"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOpenModal(true);
      }
    } else {
      toast.error(t("otp_value_or_request_id_is_missing"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  console.log("openModal", openModal);

  const handleResendOtp = async () => {
    setOtpValue("");
    if (requestIdData) {
      try {
        const response = await resendOtp(
          userDetails.userId,
          otpServiceId,
          requestIdData
        );
        if (response.data.returnValue === "1") {
          toast.success(t("otp_resend_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setShowResendButton(false);
          setIsSubmitDisabled(false);
          startCountdown(); // Restart the countdown
        }
      } catch (error) {
        toast.error(t("failed_to_resend_otp"));
        console.error(error);
      }
    } else {
      toast.error(t("something_went_wrong"));
    }
  };

  const startCountdown = () => {
    countdownStartRef.current = Date.now();
    setTimeLeft(120);

    const timer = setInterval(() => {
      const elapsedTime = Math.floor(
        (Date.now() - countdownStartRef.current) / 1000
      );
      const newTimeLeft = 120 - elapsedTime;

      if (newTimeLeft <= 0) {
        clearInterval(timer);
        setIsSubmitDisabled(true);
        setShowResendButton(true);
        setTimeLeft(0);
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);

    return () => clearInterval(timer);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      const elapsedTime = Math.floor(
        (Date.now() - countdownStartRef.current) / 1000
      );
      const newTimeLeft = 120 - elapsedTime;

      if (newTimeLeft <= 0) {
        setIsSubmitDisabled(true);
        setShowResendButton(true);
        setTimeLeft(0);
      } else {
        setIsSubmitDisabled(false);
        setShowResendButton(false);
        setTimeLeft(newTimeLeft);
      }
    }
  };

  useEffect(() => {
    requestOtp(userDetails.userId, otpServiceId).then((response) => {
      setRequestIdData(response.data);
      startCountdown(); // Start the countdown on initial load
    });

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userDetails.userId, otpServiceId]);

  return (
    <Modal
      open={openModal}
      // onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "600px",
          minHeight: "150px",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <h2 id="modal-modal-title">
          {t("please_enter_an_otp_that_has_been_sent_to_your_email")}
        </h2>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label={t("enter_Otp")}
          value={otpValue}
          onChange={handleOtpValueChange}
          sx={{ border: "1px solid #000000" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showResendButton ? (
            <Button className="dfultPrimaryBtn" onClick={handleResendOtp}>
              {t("resend_otp")}
            </Button>
          ) : (
            // <span>{`Time left: ${timeLeft}s`}</span>
            <span>{t("timeLeft", { timeLeft })}</span>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Button
            className="dfultPrimaryBtn"
            onClick={handleSubmitOtp}
            disabled={isSubmitDisabled}
          >
            {t("submit_otp")}
          </Button>
          {/* <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button> */}
        </div>
      </div>
    </Modal>
  );
}
