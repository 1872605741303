import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../../../../utils/Utils";
import { EditDeadLineModal } from "../Modals/EditDeadLineModal";

export const TaskTimeline = ({ data,removeFromStore,refreshData,userList,assigneeList }) => {
  const { t } = useTranslation();
  const [showEditDeadline, setShowEditDeadLine] = useState(false);
  const [dateRemaning, setDateRemaning] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);
  const progress = useRef(0);

  useEffect(() => {
    getTaskDifference();
  }, [data]);

  const getTaskDifference = () => {
    // const time1=task.taskCreationTime
    // const time2 =task.taskCompletionTime
    const creationTime = new Date(data && data.createdAt);
    const completionTime = new Date(data && data.completionTime);
    const currentTime = new Date();

    //check if already time passed

    const diffFromCurrent = currentTime - completionTime;
    if (diffFromCurrent > 0) {
      setDateRemaning(100);
      return;
    }

    // Calculate the time difference in milliseconds
    const totalTimeDifference = completionTime - creationTime;
    const timeDifferenceFromNow = currentTime - creationTime;
    const milisecondPerPercentIncrease = totalTimeDifference / 100;
    const currentPercent = (timeDifferenceFromNow * 100) / totalTimeDifference;
    setDateRemaning(
      Math.floor(currentPercent) < 100 ? Math.floor(currentPercent) : 100
    );
    progress.current = Math.floor(currentPercent);
    const nextPercent = Math.ceil(currentPercent);
    const difference = nextPercent - currentPercent;
    const diffInMilisecond = milisecondPerPercentIncrease * difference;
    const clearTimeoutVal = setTimeout(() => {
      if (currentPercent < 100) {
        console.log("before interval progress", milisecondPerPercentIncrease);
        clearTimeout(clearTimeoutVal);
        setDateRemaning((prev) => prev + 1);
        progress.current = progress.current + 1;
        if (milisecondPerPercentIncrease > 0) {
          intervalProgressor(milisecondPerPercentIncrease);
        }
      }
    }, diffInMilisecond);
    console.log("currentPercent", currentPercent);
    console.log(
      "milisecondPerPercentIncrease = ",
      milisecondPerPercentIncrease / 1000
    );
  };

  const intervalProgressor = (timeDelay) => {
    const intervalId = setInterval(() => {
      console.log("interval progressor=", progressWidth);
      if (progress.current < 100) {
        setProgressWidth((prev) => prev + 1);
        progress.current = progress.current + 1;
      } else {
        clearInterval(intervalId);
      }
    }, timeDelay);
  };

  return (

    <>
    <div className="taskElementGrp">
      <div className="taskContainEditBtnGrp">
        <div className="tskElemHeding">{t("timeline")}</div>
        {data.incoming === "N" && (
          <Tooltip arrow title={t("edit_timeline")}>
            <IconButton
              className="editTimelineBtn"
              onClick={() => {
                setShowEditDeadLine(true);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="tskTimeline">
        <div className="tskTimBar">
          <div className="progress">
            <div
              // up50 up80 up100
              className={`progress-done ${
                dateRemaning < 50
                  ? "up50"
                  : dateRemaning < 80
                  ? "up80"
                  : "up100"
              }`}
              style={{ width: `${dateRemaning}%`, opacity: 1 }}
            ></div>
          </div>
        </div>
        <div className="tskDtSE">
          <div className="tskDTStart">{getFormattedDate(data.createdAt)}</div>
          <div className="tskDTEnd">
            {getFormattedDate(data.completionTime)}
          </div>
        </div>
      </div>
    </div>
    {showEditDeadline && (
          <EditDeadLineModal
            createdAt={data.createdAt}
            completionTime={data.completionTime}
            closeModal={() => {
              setShowEditDeadLine(false);
            }}
            onClose={(e) => setShowEditDeadLine(false)}
            userList={userList}
            handleClose={(e) => setShowEditDeadLine(false)}
            previousAssigness={assigneeList}
            data={data}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}
    </>
  );
};
