import React, { useContext } from "react";
import "../css/MeetingSummarySuggestedTaskCard.css";
import { AddTabContext } from "../../../../../dashboard/view/DashboardPage";
import CreateTaskContainer from "../../../../../task/view/CreateTaskContainer";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

const MeetingSummarySuggestedTaskCard = ({ tasks }) => {
  const { t } = useTranslation();
  const { addTab } = useContext(AddTabContext);
  const openCreateTaskTab = (taskDto) => {
    addTab({
      key: v4(),
      title: t("create_task"),
      content: <CreateTaskContainer suggestedTaskMeetingSummary={taskDto} />,
      isDraggable: true,
    });
  };

  return (
    <div className="task-cards-container">
      {tasks.map((task, index) => (
        <div className="task-card" key={index}>
          {/* <h3 className="task-name">{task.task_name}</h3> */}
          <p className="task-description">
            <strong>{t("task_name")}: </strong>
            {task.task_name}
          </p>
          <p className="task-description">
            <strong>{t("task_description")}: </strong>
            {task.task_description}
          </p>
          <p className="task-assignee">
            <strong>{t("assignee_of_task")}:</strong> {task.assignee}
          </p>
          <p className="task-recipients">
            <strong>{t("recipients")}:</strong> {task.recipients.join(", ")}
          </p>
          <p className="task-priority">
            <strong>{t("priority")}:</strong> {task.priority}
          </p>

          <p className="task-completion">
            <strong>{t("completion_date")}:</strong> {task.completion_date}
          </p>
          <p className="task-time">
            <strong>{t("completion_time")}:</strong> {task.completion_time}
          </p>

          <button
            className="draft-task-btn"
            onClick={() => openCreateTaskTab(task)}
          >
            {t("create_task")}
          </button>
        </div>
      ))}
    </div>
  );
};

export default MeetingSummarySuggestedTaskCard;
