import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";

import { Bar, Chart, Doughnut, Pie, PolarArea } from "react-chartjs-2";
import { Dashboard } from "iconoir-react";
import DateUtils from "../../../../../utils/DateUtils";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardManagementSupportTicket() {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const userDetails = GetLoginUserDetails();
  const [category, setCategory] = useState("Open");
  const [filterby, setFilterby] = useState("Monthly");
  const [supportTicketData, setSupportTicketData] = useState({});
  const [selectedRange, setSelectedRange] = useState("Today");

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleChangeFilterby = (event) => {
    setFilterby(event.target.value);
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".msSupportTicket",
        content: "This section displays card management about Support Tickets.",
        disableBeacon: true,
      },
      {
        target: ".msSupportTicketTwo",
        content: "Click the refresh icon to update data.",
        disableBeacon: true,
      },
      {
        target: ".msSupportTicketOne",
        content:
          "Select the ticket category (e.g., Open, Closed) to filter the data.",
        disableBeacon: true,
      },

      {
        target: ".msSupportTicketThree",
        content: "This chart shows the distribution of tickets by category.",
        disableBeacon: true,
      },
      {
        target: ".msSupportTicketFour",
        content:
          "This chart shows the distribution of tickets according to open and close",
        disableBeacon: true,
      },
    ]);
  }, []);

  useEffect(() => {
    fetchSupportTicketData();
  }, [selectedRange, category]);

  const fetchSupportTicketData = () => {
    const dateRange = calculateDateRange(selectedRange);
    // console.log("dateRange", dateRange);
    const reqDto = {
      unitId: 0,
      orgId: userDetails.orgId,
      userId: "",
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: dateRange.field1,
      toDate: dateRange.field2,
      unitDoctor: "",
      status: category,
    };
    DashboardServices.refreshSupportTicket(reqDto)
      .then((response) => {
        // console.log(response.data);
        setSupportTicketData(response.data || {});
      })
      .catch((error) => {
        console.error("Error fetching support ticket data:", error);
      });
  };

  const chartRef = useRef(null);
  const [barThickness, setBarThickness] = useState(94);
  const labels = ["0-1 Days", "1-3 Days", "3-7 Days", "7+ Days"];

  const calculateDateRange = (range) => {
    const now = new Date();
    let startDate, endDate;

    switch (selectedRange) {
      case "Today":
        startDate = new Date(now.setHours(0, 0, 0, 0));
        endDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case "Yesterday":
        startDate = new Date(now.setDate(now.getDate() - 1));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case "Last 7 Days":
        startDate = new Date(now.setDate(now.getDate() - 7));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "This Week":
        startDate = new Date(now.setDate(now.getDate() - now.getDay()));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last Week":
        const lastWeekStart = new Date(
          now.setDate(now.getDate() - now.getDay() - 7)
        );
        startDate = new Date(lastWeekStart.setHours(0, 0, 0, 0));
        endDate = new Date(now.setDate(lastWeekStart.getDate() + 6));
        endDate.setHours(23, 59, 59, 999);
        break;
      case "This Month":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last Month":
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last 30 Days":
        startDate = new Date(now.setDate(now.getDate() - 30));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "All Time":
        startDate = new Date(2024, 11, 1, 0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      default:
        return;
    }

    return {
      field1: DateUtils.getDateInDDMMYYYY(startDate),
      field2: DateUtils.getDateInDDMMYYYY(endDate),
    };
  };

  // const barLineData = {
  //   labels,
  //   datasets: [
  //     {
  //       label: "Hardware - Low Priority",
  //       backgroundColor: "#FFD700", // Yellow for hardware low
  //       data: [5, 3, 2, 4],
  //       barThickness: barThickness,
  //     },
  //     {
  //       label: "Hardware - Medium Priority",
  //       backgroundColor: "#FFA500", // Orange for hardware medium
  //       data: [6, 4, 3, 5],
  //       barThickness: barThickness,
  //     },
  //     {
  //       label: "Hardware - High Priority",
  //       backgroundColor: "#FF4500", // Red for hardware high
  //       data: [2, 3, 1, 3],
  //       barThickness: barThickness,
  //     },
  //     {
  //       label: "Software - Low Priority",
  //       backgroundColor: "#ADD8E6", // Light blue for software low
  //       data: [4, 5, 4, 3],
  //       barThickness: barThickness,
  //     },
  //     {
  //       label: "Software - Medium Priority",
  //       backgroundColor: "#4682B4", // Blue for software medium
  //       data: [5, 6, 6, 4],
  //       barThickness: barThickness,
  //     },
  //     {
  //       label: "Software - High Priority",
  //       backgroundColor: "#00008B", // Dark blue for software high
  //       data: [5, 3, 2, 4],
  //       barThickness: barThickness,
  //     },
  //   ],
  // };

  const prepareBarChartData = (ticketData) => {
    // console.log("ticketData  ------>>>>>>>>>" , ticketData);
    if (ticketData) {
      const categories = ["Hardware", "Software"];
      const priorities = ["Low", "Medium", "High"];
      const dataRanges = ["0-1 day", "1-3 days", "3-7 days", "7+ days"];

      const datasets = [];

      const colorMapping = {
        Hardware: {
          Low: "#a9da39",
          Medium: "#53992b",
          High: "#186126",
        },
        Software: {
          Low: "#ADD8E6",
          Medium: "#4682B4",
          High: "#00008B",
        },
      };

      categories.forEach((category) => {
        priorities.forEach((priority) => {
          const filteredData = ticketData.filter(
            (ticket) =>
              ticket.category === category && ticket.priority === priority
          );

          const data = dataRanges.map((range) => {
            const ticket = filteredData.find(
              (ticket) => ticket.dataRange === range
            );
            return ticket ? parseInt(ticket.noOfTicket, 10) : 0;
          });

          datasets.push({
            label: `${t(category.toLowerCase())} - ${t(priority.toLowerCase())} ${t("priority")}`,
            backgroundColor: colorMapping[category][priority],
            data,
            barThickness: barThickness,
          });
        });
      });

      return datasets || [];
    }
  };

  const barLineData = {
    labels: ["0-1 day", "1-3 days", "3-7 days", "7+ days"],
    datasets: prepareBarChartData(
      supportTicketData.ticketResolutionTimeDistribution || []
    ),
  };

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const chartWidth = chartRef.current.offsetWidth;
        const numBars = barLineData.labels.length;
        const calculatedBarThickness = Math.max(
          chartWidth / (numBars * 1.06),
          10
        );
        setBarThickness(calculatedBarThickness);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [barLineData.labels.length]);

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 8,
          color: "#d2d2d2",
          font: {
            size: 8,
            weight: "light",
          },
        },
      },
      title: {
        display: false,
        text: t("ticket_resolution_time_distribution"),
        position: "top",
        padding: {
          top: 10,
          bottom: 20,
        },
        font: {
          size: 11,
        },
        color: "#b2b2b2",
      },
    },
    scales: {
      x: {
        stacked: true,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        grid: {
          color: "#545454",
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          color: "#545454",
        },
      },
    },
    indexAxis: "x",
    layout: {
      padding: 0,
    },
    borderRadius: 0,
  };

  // // poler chart start
  // const polarChartData = [50, 25, 40, 60, 87, 30];
  // const polarChartLabels = [
  //   "Hardware - Low Priority",
  //   "Hardware - Medium Priority",
  //   "Hardware - High Priority",
  //   "Software - Low Priority",
  //   "Software - Medium Priority",
  //   "Software - High Priority",
  // ];
  // const polerChartColors = [
  //   "#FFD700",
  //   "#FFA500",
  //   "#FF4500",
  //   "#ADD8E6",
  //   "#4682B4",
  //   "#00008B",
  // ];

  // // Polar area chart data using bar chart data
  // const data = {
  //   labels: polarChartLabels,
  //   datasets: [
  //     {
  //       label: "Tickets",
  //       data: polarChartData,
  //       backgroundColor: polerChartColors,
  //       borderColor: polerChartColors,
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //       position: "bottom",
  //     },
  //   },
  //   scales: {
  //     // Configuring the grid lines for polar chart
  //     r: {
  //       grid: {
  //         color: "#545454",
  //       },
  //       angleLines: {
  //         color: "#545454",
  //       },
  //       ticks: {
  //         backdropColor: "rgba(41, 41, 41, 1)",
  //         font: {
  //           size: 10,
  //         },
  //       },
  //     },
  //   },
  //   cutout: "50%", // Adjust the size of the center circle
  // };
  // // polar chart end

  // const polarChartLabels = [
  //   "Hardware - Low Priority",
  //   "Hardware - Medium Priority",
  //   "Hardware - High Priority",
  //   "Software - Low Priority",
  //   "Software - Medium Priority",
  //   "Software - High Priority",
  // ];

  const polarChartLabels = [
    t("hardware_low_priority"),
    t("hardware_medium_priority"),
    t("hardware_high_priority"),
    t("software_low_priority"),
    t("software_medium_priority"),
    t("software_high_priority"),
  ];

  const polerChartColors = [
    "#a9da39", // LightKichenGreen for "Hardware - Low Priority"
    "#53992b", // MidKichenGreen for "Hardware - Medium Priority"
    "#186126", // DarkKichenGreen for "Hardware - High Priority"
    "#ADD8E6", // LightBlue for "Software - Low Priority"
    "#4682B4", // SteelBlue for "Software - Medium Priority"
    "#00008B", // DarkBlue for "Software - High Priority"
  ];

  // Initialize polarChartData with zeros
  const polarChartData = new Array(polarChartLabels.length).fill(0);

  // Function to map category and priority to the index in polarChartLabels
  const getLabelIndex = (category, priority) => {
    const categoryOffset = category === "Hardware" ? 0 : 3; // Hardware: 0-2, Software: 3-5
    let priorityIndex;

    switch (priority) {
      case "Low":
        priorityIndex = 0;
        break;
      case "Medium":
        priorityIndex = 1;
        break;
      case "High":
        priorityIndex = 2;
        break;
      default:
        priorityIndex = 0; // Default to "Low" if not specified
    }

    return categoryOffset + priorityIndex;
  };

  // // Populate polarChartData using supportTicketData.tickets
  // supportTicketData.tickets.forEach((ticket) => {
  //   const index = getLabelIndex(ticket.category, ticket.priority);
  //   polarChartData[index] += parseInt(ticket.noOfTicket, 10);
  // });

  // Populate polarChartData using supportTicketData.tickets
  if (supportTicketData?.tickets) {
    supportTicketData.tickets.forEach((ticket) => {
      const index = getLabelIndex(ticket.category, ticket.priority);
      polarChartData[index] += parseInt(ticket.noOfTicket, 10);
    });
  }

  // Polar area chart data
  const data = {
    labels: polarChartLabels,
    datasets: [
      {
        label: t("tickets"),
        data: polarChartData,
        backgroundColor: polerChartColors,
        borderColor: polerChartColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
    },
    scales: {
      r: {
        grid: {
          color: "#545454",
        },
        angleLines: {
          color: "#545454",
        },
        ticks: {
          backdropColor: "rgba(41, 41, 41, .2)",
          font: {
            size: 10,
          },
        },
      },
    },
    cutout: "50%",
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["manageSupportTicketAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="anDashIndCard manageWidth50 msSupportTicket">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM ">
              {t("support_ticket")}
              <IconButton
                className={`refreshIcon msSupportTicketTwo`}
                onClick={() => fetchSupportTicketData()}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount cardFrom  msSupportTicketOne">
              <FormControl size="small" className="formControl w110">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="filterBy-select"
                  value={category}
                  onChange={handleChangeCategory}
                >
                  <MenuItem value={"Open"}>{t("open")}</MenuItem>
                  <MenuItem value={"Closed"}>{t("closed")}</MenuItem>
                </Select>
              </FormControl>

              {/* <FormControl size="small" className="formControl w110 ">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="dateRange"
                  value={selectedRange}
                  onChange={(e) => setSelectedRange(e.target.value)}
                >
                  <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Yesterday">Yesterday</MenuItem>
                  <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
                  <MenuItem value="This Week">This Week</MenuItem>
                  <MenuItem value="Last Week">Last Week</MenuItem>
                  <MenuItem value="This Month">This Month</MenuItem>
                  <MenuItem value="Last Month">Last Month</MenuItem>
                  <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
                  <MenuItem value="All Time">All Time</MenuItem>
                </Select>
              </FormControl> */}
            </div>
          </div>

          <div className="andCrdGraf align-item-center">
            <div
              className="anDLftGrf w60Per msSupportTicketThree"
              ref={chartRef}
            >
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              <div className="crtElmGrpTitle">
                {t("ticket_resolution_time_distribution")}
              </div>
            </div>
            <div className="anDRightGrf w40Per msSupportTicketFour">
              <PolarArea
                className="hw100"
                type="doughnut"
                data={data}
                options={options}
              />
            </div>
          </div>
          <div className="crtElmGrp">
            <div className="crtindEllm">
              <span className="shp"></span>
              {t("software_high_priority")}
            </div>
            <div className="crtindEllm">
              <span className="smp"></span>
              {t("software_medium_priority")}
            </div>
            <div className="crtindEllm">
              <span className="slp"></span>
              {t("software_low_priority")}
            </div>
            <div className="crtindEllm">
              <span className="hhp"></span>
              {t("hardware_high_priority")}
            </div>
            <div className="crtindEllm">
              <span className="hmp"></span>
              {t("hardware_medium_priority")}
            </div>
            <div className="crtindEllm">
              <span className="hlp"></span>
              {t("hardware_low_priority")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
