import { Button, IconButton, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import { XLg } from "react-bootstrap-icons";
import useFileUpload from "react-use-file-upload";
import { convertBlobToBase64 } from "../../../../utils/Utils";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  MAX_FILE_SIZE_TASK,
  VALID_FILE_EXTENSION_TASK,
} from "../../../../constants/const_values";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const TaskUploadFile = ({ documents, setDocuments, onClose }) => {
  const { t } = useTranslation();
  const [filesList, setFilesList] = useState([]);
  const inputRef = useRef();
  useEffect(() => {
    setFilesList([]);
    console.log("documents are =", documents);
  }, []);

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const validateAndProcessFiles = async (files) => {
    const tempFileList = [...filesList];

    const getFileDataObj = async (file) => {
      const dto = {
        fileName: file.name,
        fileDataLink: await convertFileToBase64_Split(file),
      };
      return dto;
    };
    const values = await Promise.all(
      Array.from(files).map(
        (file) =>
          new Promise(async (resolve, reject) => {
            console.log("file name =", file);
            if (
              !VALID_FILE_EXTENSION_TASK.includes(
                file.name.split(".").pop().toLowerCase()
              )
            ) {
              // toast.warning(`Invalid File Type Of '${file.name}'`, {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              toast.warning(
                t("file_upload_invalid_file_type", { fileName: file.name }),
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              resolve(null);
            } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TASK) {
              // toast.warning(
              //   `'${file.name}' file need to be less then ${MAX_FILE_SIZE_TASK}MB`,
              //   {
              //     position: toast.POSITION.TOP_RIGHT,
              //   }
              // );
              toast.warning(
                t("file_upload_size_exceeded", {
                  fileName: file.name,
                  maxSize: MAX_FILE_SIZE_TASK,
                }),
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              resolve(null);
            } else if (
              filesList.find(({ fileName }) => fileName === file.name)
            ) {
              // toast.warning(`'${file.name}' Already Uploaded`, {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              toast.warning(
                t("file_upload_already_uploaded", { fileName: file.name }),
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );

              const dto = getFileDataObj(file);
              resolve(null);
            } else if (
              documents.length > 0 &&
              documents.find((obj) => obj.fileName === file.name)
            ) {
              // toast.warning(`'${file.name}' Already Uploaded`, {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              toast.warning(
                t("file_upload_already_uploaded", { fileName: file.name }),
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              const dto = getFileDataObj(file);
              resolve(null);
            } else {
              const dto = getFileDataObj(file);
              resolve(dto);
            }
          })
      )
    );
    console.log("values are ", values);
    const temp = values.filter((t) => t !== null);

    console.log("handelFileUpload", temp);
    setFilesList([...filesList, ...temp]);
  };

  const removeFileFromDocument = (index) => {
    const tempDocuments = [...documents];
    tempDocuments.splice(index, 1);
    setDocuments(tempDocuments);
  };

  return (
    <>
      <div className="taskTempaltesPannel">
        <div className="taskTemplatesContainer">
          <div className="taskTempHeaderPanel">
            <div className="tskHedCon">{t("upload_file")}</div>
            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton aria-label="close" onClick={onClose}>
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div
                onDragEnter={handleDragDropEvent}
                onDragOver={handleDragDropEvent}
                onDrop={(e) => {
                  handleDragDropEvent(e);
                  console.log("dropped file is =", e.dataTransfer.files);
                  const files = e.dataTransfer.files;
                  validateAndProcessFiles(files);
                  setFiles(e, "a");
                }}
                style={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "12px",
                  padding: "10px",
                  color: "#ffffff",
                  width: "-webkit-fill-available",
                }}
              >
                <h1
                  style={{
                    margin: "0px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {t("upload_files")}
                </h1>

                {/* <p>Please use the form to your right to upload any file(s) of your choosing.</p> */}

                <div className="form-container">
                  {/* Display the files to be uploaded */}
                  <div //css={ListCSS}
                  >
                    <ul>
                      {filesList.map((name, index) => (
                        <li key={name.fileName}>
                          <span>{name.fileName}</span>

                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const temp = [...filesList];
                              temp.splice(index, 1);
                              setFilesList(temp);
                            }}
                          >
                            &nbsp;&nbsp;
                            <font style={{ color: "red" }}>&#x2715;</font>
                          </span>
                        </li>
                      ))}
                      {/* {console.log("document in ul =", documents)} */}
                    </ul>
                  </div>

                  {/* Provide a drop zone and an alternative button inside it to upload files. */}
                  <div>
                    <p
                      style={{
                        display: "block",
                        position: "relative",
                        opacity: "0.6",
                        margin: "10px",
                        textAlign: "center",
                        fontStyle: "italic",
                      }}
                    >
                      {t("drag_and_drop_files_here")}
                    </p>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => inputRef.current.click()}
                      sx={{ marginBottom: "10px" }}
                      style={{
                        backgroundColor: "#979797",
                        borderRadius: "20px",
                        width: "100%",
                      }}
                    >
                      {t("select_files")}
                    </Button>

                    {/* Hide the crappy looking default HTML input */}
                    <input
                      ref={inputRef}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const files = e.target.files;
                        validateAndProcessFiles(files);
                        setFiles(e, "a");
                        inputRef.current.value = null;
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="uploadedFiles">
                <div className="hedUpFils">{t("uploded_files")}</div>
                <ul>
                  {documents.map((document, index) => (
                    <li key={document.fileName}>
                      <span>{document.fileName}</span>

                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => removeFileFromDocument(index)}
                      >
                        &nbsp;&nbsp;
                        <font style={{ color: "red" }}>&#x2715;</font>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="uploadedFiles">
                <div>
                  {t("allowed_file_types")}:{" "}
                  <div>{VALID_FILE_EXTENSION_TASK.join(",") + "..."}</div>
                </div>
                {/* <div>Maximum file size {MAX_FILE_SIZE_TASK} MB</div> */}
                <div>
                  {t("maximum_file_size", { maxFileSize: MAX_FILE_SIZE_TASK })}
                </div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={async (e) => {
                  // console.log("files = ", files);
                  // const base64data = await convertFileToBase64_Split(files[0]);
                  // console.log("base 64 data = ", base64data);

                  // const tempDocumentList = await Promise.all(
                  //   filesList.map(
                  //     (file) =>
                  //       new Promise(async (resolve, reject) => {
                  //         const fileDataLink = await convertFileToBase64_Split(
                  //           file
                  //         );
                  //         const obj = {
                  //           fileName: file.name,
                  //           fileDataLink: fileDataLink,
                  //         };
                  //         resolve(obj);
                  //       })
                  //   )
                  // );
                  // console.log("document list =", tempDocumentList);
                  const tempDoc = [...documents, ...filesList];
                  setDocuments(tempDoc);
                  onClose(e);
                }}
              >
                {t("save")}
              </Button>
              <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskUploadFile;
