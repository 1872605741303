import {
  Add,
  ExpandMoreRounded,
  MonitorHeartRounded,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import DashboardServices from "../../../../../../../services/DashboardServices";
import zhAIService from "../../../../../../../services/ZhAIService";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import {
  GetLoginUserDetails,
  safeReturn,
} from "../../../../../../../utils/Utils";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";

export default function PossibleDiagnosis({
  updateData,
  data,
  extraData,
  handleDiffDiagData,
}) {
  const userLoginDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [diseasesStatus, setDiseasesStatus] = useState("");
  const [selectedDisease, setSelectedDisease] = useState(null);
  const [diseaseInputString, setDiseaseInputString] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);
  const [userDiseaseList, setUserDiseaseList] = useState([]);
  const [loadingDiffDiag, setLoadingDiffDiag] = useState(false);
  const handleChangeDiseasesStatus = (event) => {
    setDiseasesStatus(event.target.value);
  };

  const initializeForm = () => {
    setDiseasesStatus("");
    setSelectedDisease(null);
    setDiseaseInputString("");
  };

  const suggestedDiseaseList = useMemo(() => {
    const userDiagonesisIdList = userDiseaseList.map((item) => item.id);
    const { queuePatientGender } = extraData;

    console.log("diseaseList =", diseaseList);
    let filteredDisease = diseaseList
      ?.filter((disease) => {
        if (queuePatientGender === "Male") {
          return disease.gender === "M" || disease.gender === "C";
        } else if (queuePatientGender === "Female") {
          return disease.gender === "F" || disease.gender === "C";
        } else {
          return disease.gender === "C";
        }
      })
      ?.filter((disease) => {
        const alreadyTaken = userDiagonesisIdList.some(
          (item) => item === disease.id
        );

        return !alreadyTaken;
      });

    const filteredList = getFilteredList(
      filteredDisease,
      diseaseInputString,
      "diseaseName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [diseaseInputString, diseaseList, userDiseaseList]);

  const diseasesString = useMemo(() => {
    const str =
      userDiseaseList.length > 0
        ? userDiseaseList.map((item) => `${item.diseaseName} `).join(", ")
        : "";
    return str;
  }, [userDiseaseList]);

  useEffect(() => {
    getDiseases();
  }, []);

  useEffect(() => {
    if (data.possibleDiagonesis) {
      setUserDiseaseList(data.possibleDiagonesis);
    }
  }, [data]);

  const getDiseases = async () => {
    const diseasetring = await SecureIndexedDB.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        SecureIndexedDB.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };

  ///DIFFERENTIAL DIAGNOSIS

  const generateDiffDiagnosis = async () => {
    let isDebugging = false;
    isDebugging = true;
    const toastLoaderId = toast.loading(
      t("loading_differential_diagnosis_please_wait")
    );
    let symptomString = "";
    if (!data.symptoms || data.symptoms.length === 0) {
      toast.update(toastLoaderId, {
        render: t("please_enter_some_symptoms"),
        type: "error",
        isLoading: false,
        autoClose: true,
      });
      return false;
    }

    // toast.dismiss(toastLoaderId);
    safeReturn(isDebugging)();

    setLoadingDiffDiag(true);
    try {
      for (let index = 0; index < data.symptoms.length; index++) {
        const symptomDto = data.symptoms[index];
        symptomString +=
          symptomDto.severity +
          " " +
          symptomDto.selectedSymptom.symptomName +
          " since " +
          symptomDto.since +
          ",";
      }
      let reqDto = {
        age: extraData.queuePatientAge,
        gender: extraData.queuePatientGender,
        lbp: 0,
        hbp: 0,
        pulse: 0,
        min_spo2: 0,
        respiration_rate: 0,
        body_temperature: 0,
        weight: 0,
        height: 0,
        symptoms: symptomString,
      };
      for (let index = 0; index < data.vitals.length; index++) {
        const vitalDto = data.vitals[index];
        try {
          if (vitalDto.vitalCode === "BP") {
            reqDto.lbp =
              vitalDto.vitalValue.split("/")[1] === ""
                ? 0
                : vitalDto.vitalValue.split("/")[1];
            reqDto.hbp =
              vitalDto.vitalValue.split("/")[0] === ""
                ? 0
                : vitalDto.vitalValue.split("/")[0];
          }
        } catch (err) {
          console.log(err);
        }

        if (vitalDto.vitalCode === "HEART_RATE") {
          reqDto.pulse = vitalDto.vitalValue === "" ? 0 : vitalDto.vitalValue;
        }

        if (vitalDto.vitalCode === "RESPIRATION_RATE") {
          reqDto.respiration_rate =
            vitalDto.vitalValue === "" ? 0 : vitalDto.vitalValue;
        }

        if (vitalDto.vitalCode === "SPO2") {
          reqDto.min_spo2 =
            vitalDto.vitalValue === "" ? 0 : vitalDto.vitalValue;
        }

        if (vitalDto.vitalCode === "TEMPERATURE") {
          reqDto.body_temperature =
            vitalDto.vitalValue === "" ? 0 : vitalDto.vitalValue;
        }

        if (vitalDto.vitalCode === "WEIGHT") {
          reqDto.weight = vitalDto.vitalValue === "" ? 0 : vitalDto.vitalValue;
        }

        if (vitalDto.vitalCode === "HEIGHT") {
          reqDto.height = vitalDto.vitalValue === "" ? 0 : vitalDto.vitalValue;
        }

        // if (vitalDto.vitalCode === "HEIGHT") {
        //   reqDto.height = vitalDto.vitalValue;
        // }
      }

      console.log(reqDto);
      let locale = "en";
      if (
        userLoginDetails.orgId === "ORG000000000319" ||
        userLoginDetails.orgId === "ORG000000000309"
      ) {
        locale = "es";
      }
      const response = await zhAIService.getDifferentialDiagnosisByLocale(
        reqDto,
        locale
      );
      try {
        if (Object.keys(response.data).length === 0) {
          toast.update(toastLoaderId, {
            render: t(
              "no_differential_diagnosis_can_be_recommended_against_the_provided_symptoms_and_patient_data"
            ),
            type: "error",
            isLoading: false,
            autoClose: true,
          });
        } else {
          toast.update(toastLoaderId, {
            render: t("differential_diagnosis_loaded_successfully"),
            type: "success",
            isLoading: false,
            autoClose: true,
          });
          handleDiffDiagData(response.data);
        }
      } catch (err) {
        console.log(err);
        toast.update(toastLoaderId, {
          render: t(
            "no_differential_diagnosis_can_be_recommended_against_the_provided_symptoms_and_patient_data"
          ),
          type: "error",
          isLoading: false,
          autoClose: true,
        });
      }
    } catch (err) {
      toast.update(toastLoaderId, {
        render: t("something_went_wrong"),
        type: "error",
        isLoading: false,
        autoClose: true,
      });
    }
    setLoadingDiffDiag(false);
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("possible_conditions")}:{"  "}
            {!accordionExpanded && <span>{diseasesString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {extraData?.isDoctor && (
            <>
              <div className="ddBtnGroup flxSPBTW">
                <div className="buttonwithWarning">
                  <Button
                    className="differnDiagnosBtn"
                    startIcon={<MonitorHeartRounded />}
                    onClick={generateDiffDiagnosis}
                    disabled={loadingDiffDiag}
                  >
                    {t("run_differential_diagnosis")}
                  </Button>
                  <div className="warnigMsg">
                    {t(
                      "these_features_are_experimental_it_would_be_best_if_you_reviewed_and_recommended_any_necessary_changes_the_data_has_been_generated_using_ai"
                    )}
                  </div>
                </div>
                {/* <div className="reviewBtnGrp">
                  <Button
                    className="dfultDarkSecondaryBtn"
                    startIcon={<AssignmentTurnedInRounded />}
                  >
                    {t("protocol")}
                  </Button>
                </div> */}
              </div>
              <div className="bb"></div>
            </>
          )}
          <div className="complaintList">
            <ul>
              {userDiseaseList &&
                userDiseaseList.map((disease, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">
                            {`${disease.diseaseName} [ ${
                              disease.snowmedCt
                                ? `SNOWMED ${disease.snowmedCt} ,`
                                : ""
                            } ICD ${disease.icd11code} ]`}
                          </div>
                        </div>
                        {extraData?.isDoctor && (
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...userDiseaseList];
                              temp.splice(index, 1);
                              updateData({ possibleDiagonesis: temp });
                              setUserDiseaseList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        )}
                      </div>
                    </li>
                  );
                })}

              {/* add new symptoms */}
              {extraData?.isDoctor && (
                <li>
                  <div className="addNewformGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          className="formAutocompleteField"
                          variant="outlined"
                          value={selectedDisease}
                          options={suggestedDiseaseList}
                          inputValue={diseaseInputString}
                          onChange={(e, newValue) => {
                            setSelectedDisease(newValue);
                          }}
                          onInputChange={(e, value, reason) => {
                            setDiseaseInputString(value);
                          }}
                          getOptionLabel={(option) => option.diseaseName}
                          renderOption={(props, item) => {
                            return (
                              <li {...props} key={item.id}>
                                {item.diseaseName}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t("search_diseases_name")}
                              label={t("disease_name")}
                              required
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    <Button
                      className="dfultPrimaryBtn "
                      startIcon={<Add />}
                      onClick={() => {
                        if (!diseaseInputString.trim()) {
                          setDiseaseInputString("");
                          toast.error(t("please_provide_disease_name"));
                          return;
                        }
                        let tempAllergicFood = selectedDisease;
                        if (!tempAllergicFood) {
                          tempAllergicFood = {
                            id: uuidv4(),
                            diseaseName: diseaseInputString,
                            icd11code: "",
                            snowmedCt: "",
                            locallyAdded: true,
                            showInDiagonesis: true,
                            status: "Provisional",
                          };
                        } else {
                          tempAllergicFood = {
                            ...tempAllergicFood,
                            showInDiagonesis: true,
                            status: "Provisional",
                          };
                        }
                        setUserDiseaseList((prev) => {
                          const temp = [...prev, tempAllergicFood];
                          updateData({ possibleDiagonesis: temp });
                          return temp;
                        });
                        initializeForm();
                      }}
                    >
                      {t("add")}
                    </Button>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
