import React, { useEffect, useState } from "react";
import { getOrgByOrgId } from "../../../services/AdminService";
import { GetLoginUserDetails } from "../../../utils/Utils";

const SignStamp = (Signature) => {
  const loginUserDetail = GetLoginUserDetails();
  const [orgDetailsData, setOrgDetailsData] = useState(null);
  const [stampDate, setStampDate] = useState(new Date());

  useEffect(() => {
    console.log("Signature", Signature);
    const updateStampDateTime = () => {
      setStampDate(new Date());
    };
    const intervalId = setInterval(updateStampDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const formattedDate = stampDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const formattedTime = stampDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  useEffect(() => {
    fetchgetOrgDetails();
  }, [loginUserDetail.orgId]);

  const fetchgetOrgDetails = () => {
    getOrgByOrgId(loginUserDetail.orgId)
      .then((response) => {
        console.log("getOrgDetails", response.data);
        setOrgDetailsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching getOrgDetails", error);
      });
  };

  return (
    <div className="signPreviewWithStamp">
      <svg width="150" height="150">
        {/* Outer and inner circles */}
        <circle
          cx="75"
          cy="75"
          r="74"
          fill="none"
          stroke="#3301ca"
          stroke-width="2"
        />
        <circle
          cx="75"
          cy="75"
          r="74"
          fill="none"
          stroke="#3301ca"
          stroke-width="2"
        />
        <circle
          cx="75"
          cy="75"
          r="54"
          fill="none"
          stroke="#3301ca"
          stroke-width="1"
        />
        <circle
          cx="75"
          cy="75"
          r="52"
          fill="none"
          stroke="#3301ca"
          stroke-width="1"
        />

        {/* Path for the text along the circle  */}
        <defs>
          <path
            id="text-path"
            // d="M75 126 A 60 60 0 1 0 66 6a60 60 0 0 0 0 120"
            d="M 75, 74 
             m -60, 0
             a 60,60 0 0,1 120,0
             a 60,60 0 0,1 -120,0"
            // d="M66, 72 m -55, 0 a 55,60 0 0,1 110,0 a 60,55 0 0,1 -126,0 "
            fill="none"
          />

          <path
            id="text-path2"
            // d="M 75, 74
            //    m -60, 0
            //    a 60,60 0 0,1 120,0
            //    a 60,60 0 0,1 -120,0"
            d="M 75, 83  m -60, 0  a 60,60 0 1,0 120,0  a 60,60 0 1,0 -120,0"
            // d="M75,74 m -60,0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0  "
            fill="none"
          />
        </defs>

        {/* Text along the circular path  */}

        <text>
          <textPath
            href="#text-path"
            startOffset="25%"
            textAnchor="middle"
            fontFamily="Inter"
            fontSize="10"
            fontWeight="600"
            fill="#3301ca"
            letterSpacing="0"
            x="60"
            y="40"
          >
            ★{" "}
            {orgDetailsData?.orgName && orgDetailsData.orgName !== ""
              ? orgDetailsData.orgName
              : ""}{" "}
            ★
          </textPath>
        </text>
        <text>
          <textPath
            href="#text-path2"
            startOffset="25%"
            text-anchor="middle"
            fontFamily="Inter"
            font-size="10"
            font-weight="600"
            fill="#3301ca"
            letterSpacing="0"
            direction="ltr"
          >
            {/* CIN: 7015424575DM */}
            {orgDetailsData?.cin && orgDetailsData.cin !== ""
              ? "CIN: " + orgDetailsData.cin
              : ""}
          </textPath>
        </text>

        {/* Image inside the circle  */}
        <image
          x="40"
          y="20"
          width="52"
          height="52"
          href={Signature.Signature}
        />

        {/*  Date inside the circle */}
        <text
          x="50%"
          y="55%"
          font-family="Inter"
          font-size="10"
          text-anchor="middle"
          fill="#3301ca"
        >
          {formattedDate}
        </text>

        {/*  Time inside the circle  */}
        <text
          x="50%"
          y="65%"
          font-family="Inter"
          font-size="10"
          text-anchor="middle"
          fill="#3301ca"
        >
          {formattedTime}
        </text>
        <text
          x="50%"
          y="75%"
          font-family="Inter"
          font-size="10"
          text-anchor="middle"
          fill="#3301ca"
        >
          GMT +5.30
        </text>
      </svg>
    </div>
  );
};

export default SignStamp;
