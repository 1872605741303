import {
  AccessibleRounded,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Cancel,
  DescriptionOutlined,
  FemaleRounded,
  MaleRounded,
  MonetizationOnOutlined,
  TranslateRounded,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PersonBoundingBox, Plus, XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import { enIN, es } from "date-fns/locale";
import dayjs from "dayjs";
import { ConfirmProvider } from "material-ui-confirm";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { FileCursor } from "../../../../../common/FileCursor";
import { TourContext } from "../../../../../common/TourProvider";
import {
  getCallingCodeList,
  getCountryDtlByCode,
} from "../../../../../services/AdminService";
import DashboardServices from "../../../../../services/DashboardServices";
import zhPatientService from "../../../../../services/ZhPatientService";
import DateUtils from "../../../../../utils/DateUtils";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import EXP from "../../../../dashboard/image/EXP";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import PatientMeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/PatientMeetingsTabContainer";
import MedicalHistory from "../../../../scheduler/view/component/meetings/view/consultation/MedicalHistory";
import PatientComplaints from "../../../../scheduler/view/component/meetings/view/consultation/PatientComplaints";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import PatientDrugAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientDrugAllurgy";
import PatientFamilyHistory from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFamilyHistory";
import PatientFoodAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFoodAllergy";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";
import "../../../css/appointmentBooking.css";
import PatientFacialBiometricsRegister from "./PatientFacialBiometricsRegister";
import PatientFileUpload from "./PatientFileUpload";
import { PatientVitals } from "./PatientVitals";
import { useValidateVital } from "./hook/useValidateVital";
import { currentSubdomain } from "../../../../../constants/const_string";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddAppointmentBooking({
  handelCloseAppointmentBooking,
  appoinmentData,
  updateBookAppoinments,
  closeBookConsultation,
}) {
  const { steps, run, setRun } = useContext(TourContext);
  console.log("appoinmentData", appoinmentData);
  console.log("updateBookAppoinments", updateBookAppoinments);
  const { t, i18n } = useTranslation();
  const patientThreshold = 2;
  const { addTab } = useContext(AddTabContext);
  const [baPatEmailId, setBaPatEmailId] = useState("");
  const [baPatTitle, setBaPatTitle] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientMaritalStatus, setPatientMaritalStatus] = useState("");
  const [selectedDob, setSelectedDob] = useState(new Date());
  const [rescheduleDate, setRescheduleDate] = useState(new Date());
  const [age, setAge] = useState({ years: null, months: null, days: null });
  const loginUserDetail = GetLoginUserDetails();
  const [patientList, setPatientList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patientIsdCode, setPatyientIsdCode] = useState("");
  const [phoneRegexPattern, setPhoneRegexPattern] = useState(/^[0-9]{10,}$/);
  const [contactNumber, setContactNumber] = useState("");
  const [patientEmailId, setPatientEmailId] = useState("");
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [vitals, setVitals] = useState({});
  const [referedBy, setReferedBy] = useState("");
  const [booksteps, setBookSteps] = useState([]);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [temperatureUnit, setTemperatureUnit] = useState(null);
  const [heightUnit, setHeightUnit] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);
  const [bloodGroup, setBloodGroup] = useState("");
  const [prescriptionData, setPrescriptionData] = useState({});

  const [systemicIllnessList, setSystemicIllnessList] = useState([]);
  const [systemicAbuseList, setSystemicAbuseList] = useState([]);

  const [abuseName, setAbuseName] = useState([]);
  const [illnessName, setIllnessName] = useState([]);
  const [systemicIllness, setSystemicIllness] = useState("no");
  const [substanceAbuse, setSubstanceAbuse] = useState("no");

  const [fileDivWidth, setFileDivWidth] = useState(0);
  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const [documents, setDocuments] = useState([]);

  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [sos, setSos] = useState(false);
  const [lmp, setLmp] = useState(null);
  const [registerFace, setRegisterFace] = useState(false);

  const [forwardTo, setForwardTo] = useState("reschedule");
  // const [VitalErrorList, setVitalErrorList] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [showCancellationReason, setShowCancellationReason] = useState();
  const [cancelReason, setCancleReason] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(-1);
  const [patientAge, setPatientAge] = useState(null);
  const [patientImg, setPatientImg] = useState("");
  const [matchedPatientDetails, setMatchedPatientDetails] = useState(false);
  const [listOfDoctorForAppointment, setListOfDoctorForAppointment] = useState(
    []
  );
  const [selectDoctor, setSelectDoctor] = useState("");
  const [extractedData, setExtractedData] = useState([]);
  const [monthSelect, setMonthSelect] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [doctorSlotBookData, setDoctorSlotBookData] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [cdCodeList, setCdCodeList] = useState([]);

  const [unitAllDoctorList, setUnitAllDoctorList] = useState([]);
  const { validateVitals, VitalErrorList } = useValidateVital();

  const [
    showAssociateFacialBiometricsModal,
    setShowAssociateFacialBiometricsModal,
  ] = useState(false);

  const [patientData, setPatientData] = useState({
    patientTitle: null,
    patientFirstName: null,
    patientMiddleName: null,
    patientLastName: null,
    patientGender: null,
    patientDob: null,
    patientCivilStatus: null,
    patientPhCountryCode: null,
    patientContactNumber: null,
    patientEmailId: null,
    patientFullAddress: null,
    patientExternalSourceId: null,
    patientId: null,
    orgId: null,
    unitId: null,
    patientUserId: null,
    patientFaceId: null,
    isParent: null,
    bloodType: null,
    rowPatientId: null,
    patientImageUrl: null,
    patientFullName: null,
    patientImageBase64Data: null,
    errorMessage: null,
    errorCode: null,
  });

  useEffect(() => {
    setBookSteps([
      {
        target: ".nsviewApointDetaislstepOne",
        content: "This is where you can view All the details of the Patient",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetaislstepTwo",
        content: "This is where you can view Doctor Info",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetaislstepThree",
        content: "This is where you can view Patient Info",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetaislstepFour",
        content:
          "This is where if a patient is new then enter the Patient Name",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetailstepFive",
        content:
          "This is where if a patient is new then enter the Patient Gender and Date of Birth",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetailstepSix",
        content:
          "This is where if a patient is new then enter the Patient Civil Status and contact Number",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetailstepSeven",
        content:
          "This is where you can choice 'Add to Queue' , 'Reschedule' , 'Cancel Appointment according to the need",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetailstepEight",
        content:
          "This is where you can add and edit all the data for the 'Add to Queue' Field ",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetailstepNine",
        content:
          "This is where you can add and edit all the data for the 'Reschedule' Field ",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetailstepTen",
        content:
          "This is where you can add and edit all the data for the 'Cancel-appointment' Field ",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewApointDetailstepEleven",
        content: "This is where you can do the submit or cancel operation",
        placement: "bottom",
        disableBeacon: true,
      },
    ]);
  }, []);

  const fetchDoctorSlot = () => {
    if (monthSelect && selectDoctor) {
      const reqDto = {
        fromDate: DateUtils.getDateInDDMMYYYY(monthSelect),
        doctorId: selectDoctor,
        unitId: loginUserDetail.signleUnitId,
        days: 3,
      };
      console.log("reqDto", reqDto);

      DashboardServices.getDoctorSlots(reqDto).then((response) => {
        console.log("response", response.data);
        setDoctorSlotBookData(response.data);
      });
    }
  };

  useEffect(() => {
    if (doctorSlotBookData.length > 0) {
      const extracted = extractSlotData(doctorSlotBookData);
      setExtractedData(extracted);
      setSelectedDay(extracted[0].slotDate);
      console.log("extractedData", extracted);
    }
  }, [doctorSlotBookData]);

  useEffect(() => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const appointmentDate = new Date(appoinmentData.date).setHours(0, 0, 0, 0);

    if (currentDate === appointmentDate && appoinmentData.slot.visitId === 0) {
      setForwardTo("add-to-queue");
    } else {
      setForwardTo("reschedule");
    }
  }, [appoinmentData.date, appoinmentData.slot.visitId]);

  function extractSlotData(data) {
    return data.map((day) => ({
      slotDate: day.slotDate,
      availableCount: day.availableCount,
      bookedCount: day.bookedCount,
      dayName: day.dayName,
      dayMonth: day.dayMonth,
      slots: day.slots.map((slot) => ({
        slotId: slot.slotId,
        fromTime: slot.fromTime,
        toTime: slot.toTime,
        patientId: slot.patientId,
        patientAge: slot.patientAge,
        patientGender: slot.patientGender,
        patientName: slot.patientName,
        patientUnitId: slot.patientUnitId,
        status: slot.status,
      })),
    }));
  }

  useEffect(() => {
    fetchDoctorSlot();
  }, [monthSelect, selectDoctor]);

  useEffect(() => {
    getCallingCodeList().then((response) => {
      // console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  useEffect(() => {
    if (loginUserDetail.orgCountryCode) {
      getCountryDtlByCode(loginUserDetail.orgCountryCode).then((response) => {
        // console.log('response' , response.data);
        setPatyientIsdCode(response.data.callingCd);
      });
    }
  }, [loginUserDetail.orgCountryCode]);

  const handleChangebaPatTitle = (event) => {
    setBaPatTitle(event.target.value);
  };

  const handleChangeMaritalStatus = (event) => {
    setPatientMaritalStatus(event.target.value);
  };

  const handleChangepatientGender = (event) => {
    setPatientGender(event.target.value);
  };
  const selectSystemicIllness = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setIllnessName([]);
    }
    setSystemicIllness(event.target.value);
  };

  const handleAgeChange = (e) => {
    const { name, value } = e.target;
    let newAge = {
      ...age,
      [name]: value ? Number(value) : null,
    };
    setAge(newAge);
    calculateDOB(newAge);
    // setRefreshDOB(refreshDOB + 1);
  };

  const handelOpenRegisterFace = () => {
    setRegisterFace(true);
  };

  const selectSubstanceAbuse = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setAbuseName([]);
    }
    setSubstanceAbuse(value);
  };

  const handleChangeAbuse = (event) => {
    const {
      target: { value },
    } = event;
    setAbuseName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  useEffect(() => {
    DashboardServices.getDoctorsofUnitForAppointment(
      loginUserDetail.signleUnitId
    )
      .then((response) => {
        console.log("response", response.data);
        const doctors = response.data || [];

        const selectedDoctor = doctors.find(
          (doctor) => doctor.doctorId === appoinmentData.doctorId
        );

        if (selectedDoctor) {
          setSelectDoctor(selectedDoctor.doctorId);
          setListOfDoctorForAppointment(doctors);
        } else {
          DashboardServices.getDoctorListWithAvailableSlot(
            loginUserDetail.signleUnitId
          ).then((response) => {
            console.log("response", response.data);
            const availableDoctors = response.data || [];

            const availableDoctor = availableDoctors.find(
              (doc) => doc.doctorId === appoinmentData.doctorId
            );

            if (availableDoctor) {
              setListOfDoctorForAppointment([
                ...doctors,
                {
                  doctorId: availableDoctor.doctorId,
                  doctorName: availableDoctor.doctorName,
                  specializationId: null,
                  fees: availableDoctor.fees,
                  feesCurrency: availableDoctor.feesCurrency,
                  specializationName: availableDoctor.specialization,
                  queueCount: 0,
                  languages: availableDoctor.language,
                  experience: availableDoctor.experience,
                  avgConsultTime: 0,
                  licenseIssuingAuth: null,
                  salutation: null,
                  qualification: null,
                  practiceStartDate: null,
                  doctorSignImageUrl: null,
                  medicalLicenseNo: null,
                  medicalLicenseCertificate: null,
                  medicalLicenseCertificateUrl: null,
                  medicalLicenseCertificateBase64Data: null,
                  doctorGender: null,
                  doctorDob: null,
                  unitIds: null,
                  doctorProfileImageUrl: null,
                  orgId: null,
                  activeFlag: null,
                },
              ]);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching doctors:", error);
      });
  }, [loginUserDetail.signleUnitId, appoinmentData]);

  const updateData = (data) => {
    // toast.success("successfully added data");
    console.log("data =", data);
    setPrescriptionData((prev) => ({ ...prev, ...data }));
  };

  const getAge = (dob) => {
    // console.log("getAge", dob);
    const ageString = calculateDateDifference(dob);
    const ageComponent = ageString?.split(" ");
    if (ageComponent?.[0]?.includes("Y")) {
      return parseInt(ageComponent[0].split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };

  const handleChangeIllness = (event) => {
    const {
      target: { value },
    } = event;
    setIllnessName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleMonthSelect = (newDate) => {
    setMonthSelect(newDate);
  };

  const submitFormData = async () => {
    const toastLoaderId = toast.loading(t("submitting_details_please_wait"));

    let tempVisistData = await DashboardServices.generateVisitId(
      loginUserDetail.orgId
    );
    tempVisistData = tempVisistData?.data;
    const itemData = prescriptionData;
    console.log("Prescription Data", itemData);

    let doc = documents?.map((item) => {
      return {
        fileName: item.fileName,
        fileLink: item.fileLink,
        fileData: item.fileData,
        id: item.id,
      };
    });

    const prevDoc =
      previousMedicalRecords
        ?.filter((item) => item.isChecked)
        ?.map((item) => {
          return {
            fileName: item.fileName,
            fileLink: item.fileLink,
            fileData: item.fileData,
            id: item.id,
          };
        }) || [];

    doc = [...doc, ...prevDoc];

    const illnesses = illnessName?.map((item) => item.systemicIllnessName);

    const abuseNameList = abuseName?.map((item) => item.substanceAbuseName);

    const foodAllergies = prescriptionData?.allergicFood?.map(
      (item) => item.foodName
    );

    let hereditaryDiseases =
      prescriptionData?.familyHistory?.map((item) => {
        return {
          diseaseName: item.disease.diseaseName,
          relationship: item.relationShip,
          id: item.disease.id,
        };
      }) || [];

    if (
      prescriptionData?.prevFamilyDisease &&
      prescriptionData?.prevFamilyDisease?.length > 0
    ) {
      hereditaryDiseases = [
        ...hereditaryDiseases,
        ...prescriptionData?.prevFamilyDisease,
      ];
    }

    let medicineAllergies =
      prescriptionData?.allergicDrug?.map((item) => {
        const { brand, genericItem } = item;
        let type = null;
        if (brand) {
          return {
            type: "BRAND",
            genericBrandId: brand.id,
            genericBrandName: brand.brandName,
          };
        } else {
          return {
            type: "GENERIC",
            genericBrandId: genericItem.id,
            genericBrandName: genericItem.generic,
          };
        }
      }) || [];
    if (
      prescriptionData?.previousDrugAllergy &&
      prescriptionData?.previousDrugAllergy?.length > 0
    ) {
      medicineAllergies = [
        ...medicineAllergies,
        ...prescriptionData?.previousDrugAllergy,
      ];
    }

    let diseases =
      prescriptionData?.pastIllness?.map((item) => {
        return {
          diseaseName: item.illness.diseaseName,
          diagnosisDate: dayjs(item.date).format("DD-MM-YYYY"),
          id: 0,
          nature: "CONFIRM",
        };
      }) || [];

    if (prescriptionData?.prevIllness) {
      diseases = [...diseases, ...prescriptionData?.prevIllness];
    }

    let procedures =
      prescriptionData?.prevSurgery?.map((item) => {
        return {
          id: 0,
          procedureName: item.surgery.procedureName,
          procedureDate: dayjs(item.date).format("DD-MM-YYYY"),
        };
      }) || [];

    if (prescriptionData?.prevSurgerry) {
      procedures = [...procedures, ...prescriptionData?.prevSurgerry];
    }

    let symptoms = prescriptionData.symptoms?.map((item, index) => {
      let symptomId = item.selectedSymptom.locallyAdded
        ? 0
        : item.selectedSymptom.id;
      return {
        symptomName: item.selectedSymptom.symptomName,
        symptomId,
        severity: item.severity,
        since: item.since,
        slNo: index + 1,
      };
    });

    const currentMedications = prescriptionData?.medication?.map((item) => {
      const { brandName, frequency, dosage, genericName } = item;

      let type = null;
      let genericBrandId = 0;
      let genericBrandName = "";
      if (brandName) {
        type = "BRAND";
        genericBrandId = brandName.id;
        genericBrandName = brandName.brandName;
      } else {
        type = "GENERIC";
        genericBrandId = genericName.id;
        genericBrandName = genericName.generic;
      }

      return {
        type: type,
        genericBrandId: genericBrandId,
        genericBrandName: genericBrandName,
        dosages: dosage.dosage,
        dosagesUnit: dosage.dosageUnit || "",
        since: dayjs(item.since).format("DD-MM-YYYY"),
        frequency: item.frequency.frequency,
      };
    });

    const tmpVitals = Object.values(vitals);
    // let selectedDoctor = appoinmentData;
    const selectedDoctor = unitAllDoctorList?.find(
      (item) => item.doctorId === appoinmentData.doctorId
    );

    const submitDto = {
      visitIdDisplay: tempVisistData,
      patientIdDisplay: selectedPatient.patientId,
      patientId: selectedPatient.rowPatientId,
      unitId: loginUserDetail.signleUnitId,
      doctorId: appoinmentData.doctorId,
      orgId: loginUserDetail.orgId,
      audioNote: prescriptionData.audioData || "",
      dob: selectedPatient.patientDob,
      // complaints: prescriptionData.patientComplaint || "",
      bloodType: bloodGroup || "",
      patientName: selectedPatient.patientFullName,
      gender: selectedPatient.patientGender || "",
      patientProfileUrl: selectedPatient.patientImageUrl,
      consultationType: "OPD",
      referredBy: referedBy,
      clinicName: loginUserDetail.unitLocationName
        ? loginUserDetail.unitLocationName
        : "",
      clinicContactNo: loginUserDetail.unitContactNo
        ? loginUserDetail.unitContactNo
        : "",
      patientType: "New",

      vitals: tmpVitals || [],
      documents: doc || [],
      systemicIllnesses: illnesses || [],
      substanceAbuse: abuseNameList || [],
      currentMedications: currentMedications || [],
      procedures: procedures || [],
      diseases: diseases || [],
      hereditaryDiseases: hereditaryDiseases || [],
      medicineAllergies: medicineAllergies || [],
      foodAllergies: foodAllergies || [],
      sos: sos ? "Y" : "N",
      doctorAvgConsultTime: selectedDoctor?.avgConsultTime || 0,
      doctorName: selectedDoctor?.doctorName,
      doctorSpecilization: selectedDoctor?.specialization || "",
      slotId: appoinmentData.slot.slotId,
      symptoms: symptoms || [],
    };

    console.log("submit Dto", submitDto);
    // toast.dismiss(toastLoaderId);
    // return;

    DashboardServices.submitPatientVisit(submitDto)
      .then((response) => {
        if (response.data.returnValue === "1") {
          toast.update(toastLoaderId, {
            render: t("patient_data_visit_sucess"),
            type: "success",
            isLoading: false,
            autoClose: true,
          });

          if (response.data.page === "Q") {
            // toast.success("Consultation booking complete");
            closeBookConsultation();
            // handelCloseAppointmentBooking();
            return;
          }

          const key = uuidv4();
          const eventDto = {
            eventTitle: `${response.data.visitId}) Consultation with ${selectedDoctor.doctorName}`,
            eventDescription: `Consultation with ${selectedDoctor.doctorName}`,
            eventStartTime: new Date().toString(),
            eventEndTime: new Date(Date.now() + 20 * 60 * 1000).toString(),
            eventId: response.data.visitId,
            eventType: "Consultation",
            eventLength: 20,
            eventLink: response.data.roomId,
            patientName: selectedPatient.patientFullName,
            patientImageUrl: selectedPatient.patientImageUrl,
          };

          const itemData = {
            queuePatientName: selectedPatient.patientFullName,
            // queueAttempsCount: data.queueAttempsCount,
            queuePatientAge: calculateDateDifference(
              selectedPatient.patientDob
            ),
            // queueAddTime: data.queueAddTime,
            queuePatientGender: selectedPatient.patientGender,
            queuePatientProfileUrl: selectedPatient.patientImageUrl,
            queueConsultationType: "OPD",
            queueVitals: tmpVitals || [],
            // queueVitals: "",
            queueComplaints: "",
            queueReferredBy: referedBy || "",
            queueClinicName: loginUserDetail.unitLocationName || "",
            queueClinicContactNo: "",
            queuePatientType: "New",
            queueVisitId: response.data.visitId,
            queuePatientId: selectedPatient.rowPatientId,
            queuePatientUnitId: loginUserDetail.signleUnitId,
            queueRoomId: response.data.roomId,
            reqValue: {},
            doctorName: selectedDoctor.doctorName,
            doctorId: selectedDoctor.doctorId,
            isDoctor: false,
            noFeedback: true,
            symptoms: symptoms || [],
            bloodGroup: bloodGroup,
          };

          DashboardServices.connectDoctor(response.data.visitId).then(
            (response) => response.data
          );

          addTab({
            key: key,
            title: `${eventDto.eventTitle}`,
            content: (
              <ConfirmProvider>
                <PatientMeetingsTabContainer
                  eventData={eventDto}
                  tabKey={key}
                  extraData={itemData}
                />
              </ConfirmProvider>
            ),
            isDraggable: true,
            type: "Meeting",
          });
        } else {
          // toast.error(response.data.message);
          toast.update(toastLoaderId, {
            render: t("something_went_wrong"),
            type: "error",
            isLoading: false,
            autoClose: true,
          });
        }
      })
      .finally((e) => {
        toast.dismiss(toastLoaderId);
      });
  };

  // const handleSubmitData = (slot) => {
  //   console.log("slot", slot);
  //   console.log("listOfDoctorForAppointment", listOfDoctorForAppointment);
  //   console.log("doctorSlotBookData", doctorSlotBookData);

  //   const delDoctor = listOfDoctorForAppointment.find(
  //     (val) => val.doctorId === selectDoctor
  //   );

  //   const delDate = doctorSlotBookData.find((val) =>
  //     val.slots.some((slotItem) => slotItem.slotId === slot.slotId)
  //   );

  //   // val.slots.slotId === slot.slotId
  //   // console.log('delDate', delDate);

  //   const [day, month, year] = delDate.slotDate.split("-");
  //   const parsedDate = new Date(`${year}-${month}-${day}`);

  //   const obj = {
  //     doctorName: delDoctor.doctorName,
  //     specialization: delDoctor.specializationName,
  //     experience: delDoctor.experience,
  //     language: delDoctor.languages,
  //     fees: delDoctor.fees,
  //     feesCurrency: delDoctor.feesCurrency,
  //     rating: null,
  //     numberOfUserRated: 0,
  //     imageLink: delDoctor.doctorProfileImageUrl,
  //     timezone: "",
  //     doctorId: delDoctor.doctorId,
  //     slot: slot,
  //     date: parsedDate,
  //   };

  //   console.log("obj", obj);

  //   // return

  //   // handelOpenAppointmentBooking(obj);
  // };

  const handlePastSevenDaysDate = () => {
    const res = extractedData[0];
    const [day, month, year] = res.slotDate.split("-");
    const parsedDate = new Date(`${year}-${month}-${day}`);
    parsedDate.setDate(parsedDate.getDate() - 7);
    if (new Date() < parsedDate) {
      setMonthSelect(parsedDate);
    } else {
      setMonthSelect(new Date());
    }
  };

  const handleChangselectDoctor = (event) => {
    setSelectDoctor(event.target.value);
  };

  const getSystemicIllness = async () => {
    const brandString = await SecureIndexedDB.getItem("substanceIllnessList");
    if (brandString) {
      setSystemicIllnessList(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSystemicIllness(reqDto).then((response) => {
      if (response.data) {
        setSystemicIllnessList(response.data);
        SecureIndexedDB.setItem(
          "substanceIllnessList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const handleFutureSevenDaysDate = () => {
    const lastIndex = extractedData.length - 1;
    const res = extractedData[lastIndex];
    const [day, month, year] = res.slotDate.split("-");
    const parsedDate = new Date(`${year}-${month}-${day}`);
    parsedDate.setDate(parsedDate.getDate() + 1);
    setMonthSelect(parsedDate);
  };

  const getSubstanceAbuse = async () => {
    const brandString = await SecureIndexedDB.getItem("substanceAbuseList");
    if (brandString) {
      setSystemicAbuseList(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSubstanceAbuse(reqDto).then((response) => {
      if (response.data) {
        setSystemicAbuseList(response.data);
        SecureIndexedDB.setItem(
          "substanceAbuseList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getAvailableDoctorsofUnit = async () => {
    const diseasetring = await SecureIndexedDB.getItem("unitDoctorList");
    if (diseasetring) {
      setUnitAllDoctorList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getAvailableDoctorsofUnit(
      loginUserDetail.signleUnitId
    ).then((response) => {
      if (response.data) {
        setUnitAllDoctorList(response.data);
        SecureIndexedDB.setItem(
          "unitDoctorList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const handleChangeSistolic = (index, e) => {
    let value = e.target.value;

    //need bug fixes

    let tempVital = { ...vitals };
    if (!vitals[index]) {
      tempVital[index] = {
        vitalName: "Blood Pressure",
        unit: null,
        vitalValue: "/",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: "BP",
      };
    }
    let sistolic = tempVital[index].vitalValue.split("/")[0] || "";
    let diastolic = tempVital[index].vitalValue.split("/")[1] || "";

    if (value === "") {
      const tempValue = `${value}/${diastolic}`;
      tempVital[index].vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    sistolic = parseInt(value);
    if (isNaN(sistolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    setVitals(tempVital);
  };

  const handleChangeDiastolic = (index, e) => {
    let value = e.target.value;

    //need bug fixes

    let tempVital = { ...vitals };
    if (!vitals[index]) {
      tempVital[index] = {
        vitalName: "Blood Pressure",
        unit: null,
        vitalValue: "/",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: "BP",
      };
    }
    let sistolic = tempVital[index].vitalValue.split("/")[0] || "";
    let diastolic = tempVital[index].vitalValue.split("/")[1] || "";

    if (value === "") {
      const tempValue = `${sistolic}/${value}`;
      tempVital[index].vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    diastolic = parseInt(value);
    if (isNaN(diastolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    setVitals(tempVital);
  };

  const handleChangeHeartRage = (vitalCode, e) => {
    const value = e.target.value;

    let tempVitals = { ...vitals };
    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Heart Rate",
        unit: null,
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }
    if (value === "" || value === null) {
      tempVitals[vitalCode].vitalValue = value;
      tempVitals[vitalCode].isNormal = "Y";
      setVitals(tempVitals);
    }
    const prValue = parseFloat(value.slice(0, 3));

    if (!prValue || isNaN(prValue) || prValue < 0) {
      return;
    }

    tempVitals[vitalCode].vitalValue = prValue;
    if (prValue > 90 || prValue < 50) {
      tempVitals[vitalCode].isNormal = "N";
    } else {
      tempVitals[vitalCode].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const handleChangeRR = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Respiration Rate",
        unit: "bpm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    const rrValue = parseFloat(value.slice(0, 3));

    if (value === "" || !value) {
      tempVitals[vitalCode].vitalValue = value;
      tempVitals[vitalCode].isNormal = "Y";
      setVitals(tempVitals);
    }

    if (!rrValue || isNaN(rrValue) || rrValue < 0) {
      return;
    }

    tempVitals[vitalCode].vitalValue = rrValue;
    if (rrValue > 20 || rrValue < 10) {
      tempVitals[vitalCode].isNormal = "N";
    } else {
      tempVitals[vitalCode].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const handleChangeSpo2 = (value, vitalCode) => {
    // let spo2arr = value.split(".");
    // let spoBD = "";
    // let spoAD = "";
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "SPO2",
        unit: "bpm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let [spoBD, spoAD] = value.split(".").map((part) => part || "");

    if (!spoAD) {
      spoAD = "";
    }

    if (spoBD.length > 2) {
      if (parseFloat(spoBD) === 100) {
        spoBD = "100";
        spoAD = "";
      } else {
        spoBD = spoBD.slice(0, 2);
      }
    }

    if (spoAD.length > 2 && parseFloat(spoAD) > 0) {
      if (parseFloat(spoBD) >= 100) {
        spoAD = "";
      } else {
        spoAD = spoAD.slice(0, 2);
      }
    }

    let temp = "";
    if (spoAD.length > 0) {
      temp = `${spoBD}.${spoAD}`;
    } else {
      temp = value.includes(".") ? `${spoBD}.` : spoBD;
    }

    tempVitals[vitalCode].vitalValue = temp;

    let spo = value.split(".");
    if (parseFloat(spo[0]) >= 95) {
      tempVitals[vitalCode].isNormal = "Y";
    } else if (parseFloat(spo[0]) >= 91 || parseFloat(spo[1]) <= 94) {
      tempVitals[vitalCode].isNormal = "M";
    } else {
      tempVitals[vitalCode].isNormal = "N";
    }
    setVitals(tempVitals);
  };

  const handleChangeTemparature = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Temperature",
        unit: temperatureUnit || "°F",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (unit.includes("F")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = tmparr[0].slice(0, 3);
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = tmparr[1].slice(0, 2);
      }

      if (value.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[vitalCode].vitalValue = tmpTemparature;

      if (
        parseFloat(tmpTemparature) >= 97 &&
        parseFloat(tmpTemparature) <= 99
      ) {
        tempVitals[vitalCode].isNormal = "Y";
      } else if (
        parseFloat(tmpTemparature) > 99 &&
        parseFloat(tmpTemparature) <= 100.3
      ) {
        tempVitals[vitalCode].isNormal = "M";
      } else {
        tempVitals[vitalCode].isNormal = "N";
      }
    } else if (unit.includes("C")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = parseInt(tmparr[0].slice(0, 2));
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = parseInt(tmparr[1].slice(0, 2));
      }

      if (isNaN(tmpBD) || isNaN(tmpAD)) {
        return;
      }

      if (value.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[vitalCode].vitalValue = tmpTemparature;

      if (parseFloat(tmpTemparature) < 36 || parseFloat(tmpTemparature) > 39) {
        tempVitals[vitalCode].isNormal = "N";
      } else {
        tempVitals[vitalCode].isNormal = "Y";
      }
    }
    setVitals(tempVitals);
  };

  const handleChangeHeadCircumference = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Head Circumference",
        unit: "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (tmparr[0] && tmparr[0].length > 3) {
      tmpBD = parseInt(tmparr[0].slice(0, 2));
    }
    if (tmparr[1] && tmparr[1].length > 2) {
      tmpAD = parseInt(tmparr[1].slice(0, 2));
    }

    if (isNaN(tmpBD) || isNaN(tmpAD)) {
      return;
    }

    if (value.includes(".")) {
      if (tmpAD.length === 0) {
        tmpTemparature = tmpBD + ".";
      } else {
        tmpTemparature = `${tmpBD}.${tmpAD}`;
      }
    } else {
      tmpTemparature = tmpBD;
    }

    tempVitals[vitalCode].vitalValue = tmpTemparature;
    setVitals(tempVitals);
  };

  const handleChangeUpperArm = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Upper Arm Circumference",
        unit: "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (tmparr[0] && tmparr[0].length > 3) {
      tmpBD = parseInt(tmparr[0].slice(0, 2));
    }
    if (tmparr[1] && tmparr[1].length > 2) {
      tmpAD = parseInt(tmparr[1].slice(0, 2));
    }

    if (isNaN(tmpBD) || isNaN(tmpAD)) {
      return;
    }

    if (value.includes(".")) {
      if (tmpAD.length === 0) {
        tmpTemparature = tmpBD + ".";
      } else {
        tmpTemparature = `${tmpBD}.${tmpAD}`;
      }
    } else {
      tmpTemparature = tmpBD;
    }

    tempVitals[vitalCode].vitalValue = tmpTemparature;
    setVitals(tempVitals);
  };

  const handleChangeWeight = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Weight",
        unit: weightUnit || "kg",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let weight = parseFloat(value);

    let wtarr = value.split(".");
    let wtBD = "";
    let wtAD = "";

    if (wtarr[0] && wtarr[0].length > 0) {
      wtBD = wtarr[0];
    }
    if (wtarr[1] && wtarr[1].length > 0) {
      wtAD = wtarr[1];
    }

    if (wtarr[0] && wtarr[0].length > 3) {
      wtBD = wtarr[0].slice(0, 3);
    }
    if (wtarr[1] && wtarr[1].length > 2) {
      wtAD = wtarr[1].slice(0, 2);
    }

    if (value === "" || !value) {
      tempVitals[vitalCode].vitalValue = value;
      setVitals(tempVitals);
      return;
    }

    if (!weight || isNaN(weight)) {
      return;
    }

    let tmpWeight = "";

    if (value.includes(".")) {
      if (wtAD.length === 0) {
        tmpWeight = wtBD + ".";
      } else {
        tmpWeight = `${wtBD}.${wtAD}`;
      }
    } else {
      tmpWeight = wtBD;
    }

    tempVitals[vitalCode].vitalValue = tmpWeight;

    setVitals(tempVitals);
  };

  const handleChangeHeight = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Height",
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    const unit = tempVitals[vitalCode].unit;
    let height = parseFloat(value);

    let htarr = value.split(".");
    let htBD = "";
    let htAD = "";

    if (htarr[0] && htarr[0].length > 0) {
      htBD = htarr[0];
    }
    if (htarr[1] && htarr[1].length > 0) {
      htAD = htarr[1];
    }

    if (value === "") {
      tempVitals[vitalCode].vitalValue = value;
      setVitals(tempVitals);
      return;
    }

    if (!height || isNaN(height)) {
      return;
    }

    if (unit === "cm") {
      if (htarr[0] && htarr[0].length > 3) {
        htBD = htarr[0].slice(0, 3);
      }
      if (htarr[1] && htarr[1].length > 2) {
        htAD = htarr[1].slice(0, 2);
      }
      let tempStr = "";
      if (value.includes(".")) {
        if (htAD.length === 0) {
          tempStr = htBD + ".";
        } else {
          tempStr = `${htBD}.${htAD}`;
        }
      } else {
        tempStr = htBD;
      }

      tempVitals[vitalCode].vitalValue = tempStr;
    } else if (unit === "ft~in") {
      tempVitals[vitalCode].vitalValue = value;
    }
    setVitals(tempVitals);
  };

  const handleChangeFoot = (value, vitalCode) => {
    let tempVitals = { ...vitals };
    let foot = 0;
    let inch = 0;

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Height",
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (
      tempVitals[vitalCode].vitalValue &&
      tempVitals[vitalCode].vitalValue !== ""
    ) {
      const tmp = parseInt(tempVitals[vitalCode].vitalValue);
      if (tmp) {
        foot = Math.floor(tmp / 12);
        inch = Math.floor(tmp % 12);
      }
    }

    if (!value || value === "") {
      tempVitals[vitalCode].vitalValue = 0 * 12 + inch;
      setVitals(tempVitals);
      return;
    }
    foot = parseInt(value);
    if (isNaN(foot)) {
      return;
    }
    tempVitals[vitalCode].vitalValue = foot * 12 + inch;
    setVitals(tempVitals);
  };

  const handleChangeInch = (value, vitalCode) => {
    let tempVitals = { ...vitals };
    let foot = 0;
    let inch = 0;

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Height",
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (
      tempVitals[vitalCode].vitalValue &&
      tempVitals[vitalCode].vitalValue !== ""
    ) {
      const tmp = parseInt(tempVitals[vitalCode].vitalValue);
      if (tmp) {
        foot = Math.floor(tmp / 12);
        inch = Math.floor(tmp % 12);
      }
    }

    if (!value || value === "") {
      tempVitals[vitalCode].vitalValue = foot * 12 + 0;
      setVitals(tempVitals);
      return;
    }
    inch = parseInt(value);
    if (isNaN(inch)) {
      return;
    }
    tempVitals[vitalCode].vitalValue = foot * 12 + inch;
    setVitals(tempVitals);
  };

  const handleLmpChange = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "LMP",
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (value) {
      tempVitals[vitalCode].vitalValue = value;
      setVitals(tempVitals);
    }
  };

  // const validateVitals = () => {
  //   const tempVitals = Object.values(vitals);
  //   let isValid = true;
  //   const problemList = [];

  //   const vitalCodeNameMap = {
  //     BP: "Blood Pressure",
  //     TEMPERATURE: "Temperature",
  //     SPO2: "SPO2",
  //     RESPIRATION_RATE: "Respiratory Rate",
  //     HEART_RATE: "Heart Rate",
  //     WEIGHT: "Weight",
  //     HEIGHT: "Height",
  //     LMP: "LMP",
  //     UPPER_ARM_CIRCUMFERENCE: "Upper Arm Circumference",
  //     HEAD_CIRCUMFERENCE: "Head Circumference",
  //   };

  //   const vitalCodeList = [
  //     "BP",
  //     "TEMPERATURE",
  //     "SPO2",
  //     "RESPIRATION_RATE",
  //     "HEART_RATE",
  //     "WEIGHT",
  //     "HEIGHT",
  //   ];

  //   if (
  //     selectedPatient.patientGender === "Female" &&
  //     getAge(selectedPatient.patientDob) >= 12
  //   ) {
  //     vitalCodeList.push("LMP");
  //   }

  //   if (getAge(selectedPatient.patientDob) <= 4) {
  //     vitalCodeList.push("UPPER_ARM_CIRCUMFERENCE");
  //     vitalCodeList.push("HEAD_CIRCUMFERENCE");
  //   }
  //   let list = [];

  //   vitalCodeList.forEach((item) => {
  //     if (!vitals[item]) {
  //       // setVitalErrorList(prev=>[...prev,`${item} `])
  //       isValid = false;
  //       // list.push(`${vitalCodeNameMap[item]} is not provided `);
  //       list.push(vitalCodeNameMap[item]);
  //     } else if (
  //       vitals[item].vitalValue === null ||
  //       vitals[item].vitalValue === ""
  //     ) {
  //       isValid = false;
  //       // list.push(`${vitalCodeNameMap[item]} is not provided`);
  //       list.push(vitalCodeNameMap[item]);
  //     }
  //   });
  //   let missingVitalString = list?.join(",");
  //   if (missingVitalString?.length > 0) {
  //     list = [missingVitalString + " " + t("are_not_provided")];
  //   }

  //   // if (!isValid) {
  //   //   setVitalErrorList((prev) => [...list]);

  //   //   setShowConfirmation(true);
  //   //   return false;
  //   // }

  //   const bp = tempVitals.find((item) => item.vitalCode === "BP");
  //   const temp = tempVitals.find((item) => item.vitalCode === "TEMPERATURE");
  //   if (bp) {
  //     const bpList = bp.vitalValue.split("/");
  //     const sistolic = parseInt(bpList[0]);
  //     const diastolic = parseInt(bpList[1]);

  //     if (sistolic === "" || !sistolic) {
  //       problemList.push("Sistolic pressure cannot be empty");
  //       toast.error(t("sistolic_pressure_cannot_be_empty"));
  //       isValid = false;
  //     }

  //     if (diastolic === "" || !diastolic) {
  //       problemList.push("Diastolic pressure cannot be empty");
  //       toast.error(t("diastolic_pressure_cannot_be_empty"));
  //       isValid = false;
  //     }

  //     if (sistolic < diastolic) {
  //       problemList.push("Sistolic pressure must be greater than diastolic");
  //       toast.error(t("sistolic_pressure_must_be_greater_than_diastolic"));
  //       isValid = false;
  //     }

  //     if (sistolic - diastolic < 30) {
  //       problemList.push(
  //         "Sistolic and diastolic pressure difference must be greater than 30"
  //       );
  //       toast.error(
  //         t(
  //           "sistolic_and_diastolic_pressure_difference_must_be_greater_than_30"
  //         )
  //       );
  //       isValid = false;
  //     }
  //   }

  //   if (temp) {
  //     const unit = temp.unit;
  //     const value = parseFloat(temp.vitalValue);
  //     if (unit.includes("C")) {
  //       if (value > 41.1 || value < 32.22) {
  //         problemList.push("Temparature must be within 32.22 to 41.10 °C");
  //         toast.error(t("temperature_range_error_in_celsius"));
  //         isValid = false;
  //       }
  //     } else if (unit.includes("F")) {
  //       if (value > 106 || value < 90) {
  //         problemList.push("Temparature must be within 90 to 106 °F");
  //         toast.error(t("temperature_range_error_in_fahrenheit"));
  //         isValid = false;
  //       }
  //     }
  //   }

  //   setVitalErrorList([...list, ...problemList]);

  //   if (!isValid) {
  //     setShowConfirmation(true);
  //   }

  //   return isValid;
  // };

  const calculateDOB = (age) => {
    // console.log("calculateDOB", age);
    const today = new Date();
    const years = age.years || 0;
    const months = age.months || 0;
    const days = age.days || 0;
    // console.log("calculateDOB", years, months, days);
    console.log(years, today.getFullYear());
    const birthDate = new Date(
      today.getFullYear() - years,
      today.getMonth() - months,
      today.getDate() - days
    );
    console.log(birthDate);
    setSelectedDob(birthDate);
    const formattedDate = format(birthDate, "dd-MM-yyyy");
  };

  const handleChangeemplyPhnCC = (event) => {
    setPatyientIsdCode(event.target.value);
    switch (event.target.value) {
      case "+91": // India
        setPhoneRegexPattern(/^[6-9]\d{9}$/);
        break;
      case "+52": // Mexico
        setPhoneRegexPattern(/^[1-9]\d{9}$/);
        break;
      case "+971": // UAE
        setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
        break;
      default:
        setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
        break;
    }
  };

  const handlePatientInfo = (patientInfo) => {
    if (patientInfo) {
      const { systemicIllnesses, substanceAbuse } = patientInfo;
      let tempSelectedIllness = [];
      systemicIllnesses?.forEach((item) => {
        const illness = systemicIllnessList.find(
          (illness) => illness.systemicIllnessName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setIllnessName(tempSelectedIllness);
        setSystemicIllness("yes");
      }

      tempSelectedIllness = [];

      substanceAbuse?.forEach((item) => {
        const illness = systemicAbuseList.find(
          (illness) => illness.substanceAbuseName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setAbuseName(tempSelectedIllness);
        setSubstanceAbuse("yes");
      }
    }
  };

  const getTodaysPatientList = async () => {
    let date = dayjs(appoinmentData?.date, "DD-MM-YYYY").format("YYYY-MM-DD");

    // const resGetTodayPatList = await zhPatientService.getPatListInUnitByDate(
    //   date,
    //   date,
    //   loginUserDetail.signleUnitId,
    //   loginUserDetail.orgId
    // );

    if (appoinmentData?.slot?.patientId) {
      const resGetTodayPatList =
        await zhPatientService.getPatDtlsByRowPatientId({
          rowPatientId: appoinmentData.slot.patientId,
        });

      // setPatientList(resGetTodayPatList.data);
      const tempPatient = resGetTodayPatList.data;
      if (tempPatient) {
        setSelectedPatient(tempPatient);
        setPatientAge(
          DateUtils.getAgeFromDateOfBirth(
            DateUtils.convertToDate(tempPatient.patientDob)
          )
        );
        setFilteredList([tempPatient]);
        DashboardServices.getPreviousMedicalRecords(
          tempPatient.rowPatientId
        ).then((response) => {
          if (response.data) {
            let patientInfo = response.data;
            setPatientInfo(patientInfo);
            handlePatientInfo(patientInfo);
          }
        });

        DashboardServices.getPatientPastUploadedDocuments(
          tempPatient.rowPatientId
        ).then((response) => {
          if (response.data) {
            setPreviousMedicalRecords(response.data);
          }
        });
      }
    }
    // setFilteredList(resGetTodayPatList.data);
  };

  const filterData = () => {
    if (!baPatEmailId) {
      return patientList;
    }
    const filteredData = patientList?.filter((patient) => {
      return patient?.patientEmailId === baPatEmailId;
    });
    if (filteredData?.length === 0) {
      setIsNewPatient(true);
    }
    return filteredData || [];
  };

  useEffect(() => {
    console.log(("********** appoinmentData =", appoinmentData));

    if (appoinmentData.date > new Date()) {
      setForwardTo("reschedule");
    }
    getTodaysPatientList();
    getSubstanceAbuse();
    getSystemicIllness();
    getAvailableDoctorsofUnit();
  }, []);

  useEffect(() => {
    getTodaysPatientList();
  }, [appoinmentData]);

  useEffect(() => {
    if (
      // appoinmentData?.slot?.status === "B" &&
      appoinmentData?.slot?.rescheduleDate &&
      appoinmentData?.slot?.rescheduleReqRmks
    ) {
      const [day, month, year] =
        appoinmentData?.slot?.rescheduleDate.split("-");
      const convertedDate = new Date(year, month - 1, day);
      setRescheduleDate(convertedDate);
      setForwardTo("reschedule");
    }
  }, [appoinmentData]);

  const getDoctorAppoinment = (date) => {
    // const toastLoaderId = toast.loading("Fetching appoinment data");
    DashboardServices.getDoctorsAppointmentOnDate(
      loginUserDetail.signleUnitId,
      date
    )
      .then((response) => {
        if (response.data) {
          console.log("Dcotor data =", response.data);
          let tempDoctorData = {};
          response.data
            .filter((item) => {
              return item.doctorId === appoinmentData.doctorId;
            })
            .forEach((item) => {
              const temp = item?.slots?.filter((slot) => {
                // slot.
                return slot.patientId === 0;
              });
              if (temp?.length > 0) {
                tempDoctorData = { ...item, slots: temp };
              }
            });
          console.log("tempDoctorData =", tempDoctorData);

          setDoctors(tempDoctorData);
        }
      })
      .catch((e) => {
        // toast.update(toastLoaderId, {
        //   render: "Could not fetch data",
        //   type: "error",
        //   isLoading: false,
        //   autoClose: true,
        // });
      });
  };

  const updatePatientData = (newProperty, newValue) => {
    setPatientData((prevData) => ({
      ...prevData,
      [newProperty]: newValue,
    }));
  };

  const onFaceLivenessCheckComplete = async (base64Image) => {
    setRegisterFace(false);
    console.log("onFaceLivenessCheckComplete", base64Image);

    let reqDto = {
      base64Image: base64Image,
      orgId: loginUserDetail.orgId,
    };
    // setPatientImg(`${base64Image}`);
    const toastLoaderId = toast.loading(
      t("fetching_facial_biometrics_data_please_wait")
    );
    const resGetFaceData = await zhPatientService.getPatDtlsByFaceData(reqDto);

    console.log("patient Detail *********** ", resGetFaceData.data);
    if (resGetFaceData?.data.rowPatientId > 0) {
      let patAge = DateUtils.getAgeFromDateOfBirth(
        DateUtils.convertToDate(resGetFaceData.data.patientDob)
      );
      resGetFaceData.data.patAge = patAge;
      resGetFaceData.data.patientImageBase64Data = base64Image;

      setMatchedPatientDetails(resGetFaceData.data);
      toast.update(toastLoaderId, {
        render: t(
          "face_details_found_please_associate_face_with_the_provided_data"
        ),
        type: "info",
        isLoading: false,
        autoClose: true,
      });
    } else {
      setShowAssociateFacialBiometricsModal(true);
      setSelectedPatient((prevData) => ({
        ...prevData,
        patientImageBase64Data: base64Image,
      }));
      toast.update(toastLoaderId, {
        render: t(
          "face_details_not_found_please_associate_face_with_the_provided_data"
        ),
        type: "info",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  function calculateDateDifference(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    const [day, month, year] = dateString.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    const yr = years ? `${years}Y ` : "";
    const mnt = months ? `${months}M ` : "";
    const dy = days ? `${days}D ` : "";

    return `${yr}${mnt}${dy}`;
  }

  const [loadingAssociate, setLoadingAssociate] = useState(false);

  const associateFaceWithBookAppAccount = async () => {
    setLoadingAssociate(true);
    console.log("associateFaceWithBookAppAccount", selectedPatient);
    // return;
    const toastLoaderId = toast.loading(
      t("adding_facial_biometrics_data_to_account_please_wait")
    );

    const response = await zhPatientService.completeRegBookAppointment(
      selectedPatient,
      loginUserDetail.userId
    );

    console.log(response.data);

    if (response.data.returnValue === "1") {
      setPatientImg(`${selectedPatient.patientImageBase64Data}`);
      setSelectedPatient((prevData) => ({
        ...prevData,
        patientFaceId: response.data.zhPatientRegisterDto.patientFaceId,
      }));
      setSelectedPatient((prevData) => ({
        ...prevData,
        patientUserId: response.data.zhPatientRegisterDto.patientUserId,
      }));
      setSelectedPatient((prevData) => ({
        ...prevData,
        patientImageUrl: response.data.zhPatientRegisterDto.patientImageUrl,
      }));
      toast.update(toastLoaderId, {
        render: t("facial_biometrics_data_added_to_account_successfully"),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      setShowAssociateFacialBiometricsModal(false);
    } else {
      toast.update(toastLoaderId, {
        render: t("something_went_wrong"),
        type: "error",
        isLoading: false,
        autoClose: true,
      });
    }
    setLoadingAssociate(false);
  };

  const submitChangePatientBookedSlot = async () => {
    setLoadingAssociate(true);
    console.log("submitChangePatientBookedSlot", matchedPatientDetails);
    // return;
    const toastLoaderId = toast.loading(
      t("adding_facial_biometrics_data_to_account_please_wait")
    );

    // const response = { data: { returnValue: "1" } };

    const response =
      await zhPatientService.updateBookedAppForFaceAlreadyPresent(
        selectedPatient.rowPatientId,
        matchedPatientDetails.rowPatientId,
        appoinmentData.slot.slotId
      );

    console.log(response.data);

    if (response.data.returnValue === "1") {
      appoinmentData.slot.patientId = matchedPatientDetails.rowPatientId;
      const tempPatient = matchedPatientDetails;
      if (tempPatient) {
        setSelectedPatient(tempPatient);
        setPatientAge(
          DateUtils.getAgeFromDateOfBirth(
            DateUtils.convertToDate(tempPatient.patientDob)
          )
        );
        setFilteredList([tempPatient]);
        DashboardServices.getPreviousMedicalRecords(
          tempPatient.rowPatientId
        ).then((response) => {
          if (response.data) {
            let patientInfo = response.data;
            setPatientInfo(patientInfo);
            handlePatientInfo(patientInfo);
          }
        });

        DashboardServices.getPatientPastUploadedDocuments(
          tempPatient.rowPatientId
        ).then((response) => {
          if (response.data) {
            setPreviousMedicalRecords(response.data);
          }
        });
      }
      updateBookAppoinments();

      toast.update(toastLoaderId, {
        render: t("patient_booked_appointment_details_changes_successfully"),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      setMatchedPatientDetails(null);
    } else {
      toast.update(toastLoaderId, {
        render: t("something_went_wrong"),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
    }
    setLoadingAssociate(false);
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewappointDetailsNs"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer nsviewApointDetaislstepOne">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("appointment_booking")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAppointmentBooking()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="tskElemHeding ">{t("doctor_info")}</div>
                <div className="doctorDetails bookedSlot nsviewApointDetaislstepTwo">
                  <div className="doctorInfoWthImg">
                    <div className="docImage">
                      <img
                        src={appoinmentData?.imageLink}
                        alt={t("doctor_image")}
                      />
                    </div>
                    <div className="doctorFullInfo">
                      <div className="doctorNmSpc">
                        <div className="docName">
                          {appoinmentData.doctorName}
                        </div>
                        <div className="docSpclty">
                          {` ( ${appoinmentData.specialization} )`}
                        </div>
                      </div>
                      <div className="docExpFeeLan">
                        <div className="docEFL">
                          <div className="eflicon fillnone">
                            <EXP />
                          </div>
                          <div className="eflText">
                            {appoinmentData.experience}
                          </div>
                        </div>

                        <div className="docEFL">
                          <div className="eflicon">
                            <MonetizationOnOutlined />
                          </div>
                          <div className="eflText">
                            {appoinmentData?.fees}
                            <span> {appoinmentData?.feesCurrency}</span>
                          </div>
                        </div>
                        <div className="docEFL">
                          <div className="eflicon">
                            <TranslateRounded />
                          </div>
                          <div className="eflText">
                            {/* {console.log(
                              "appoinmentData.language",
                              appoinmentData.language
                            )} */}
                            {/* {appoinmentData.language.join(", ")} */}
                            {appoinmentData?.language &&
                              appoinmentData?.language.length > 0 &&
                              appoinmentData?.language.map(
                                (langName, index) => {
                                  return (
                                    t(
                                      langName
                                        .replace(/\s+\(/g, "_")
                                        .replace(/\)/g, "")
                                        .replace(/\s+/g, "_")
                                    ) +
                                    (index ===
                                    appoinmentData?.language.length - 1
                                      ? ""
                                      : ", ")
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bdtSlotInfo">
                    <div className="bookingDateTime">
                      {t("booking_date_time")}
                    </div>
                    <div className="slotInfo">
                      <div className="icon">
                        {/* <StarsRounded /> */}
                        <AccessibleRounded />
                      </div>
                      <div className="slotTime">
                        <span className="date">
                          {dayjs(appoinmentData?.date).format("DD-MMM-YYYY")}
                        </span>
                        <span className="time">{`${appoinmentData?.slot?.fromTime} - ${appoinmentData?.slot?.toTime}`}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tskElemHeding mt10">{t("patient_info")}</div>

                <div className="filterPatlist appoinBookPatList nsviewApointDetaislstepThree">
                  {filteredList?.map((patient) => {
                    return (
                      <div
                        className="filterPatlistInd"
                        onClick={() => {
                          setSelectedPatient(patient);
                        }}
                      >
                        <div className="filPatFullinfo">
                          <div className="filPatImg">
                            <img
                              src={patientImg || patient.patientImageUrl}
                              alt={t("patient_image")}
                            />
                          </div>
                          <div className="filtPatDtl">
                            <div className="filtPatNm">
                              {console.log("patientchghgwgdjwg", patient)}
                              {patient?.patientFullName}{" "}
                              <span>{`${
                                patient.patientExternalSourceId
                                  ? patient.patientExternalSourceId
                                  : patient.patientId
                                  ? patient.patientId
                                  : ""
                              }`}</span>
                              <span>{`(${
                                patient?.patientGender?.toLowerCase() === "male"
                                  ? t("male")
                                  : patient?.patientGender?.toLowerCase() ===
                                    "female"
                                  ? t("female")
                                  : t("other")
                              }, ${patientAge?.years}Y ${patientAge?.months}M ${
                                patientAge?.days
                              }D )`}</span>
                            </div>
                            {/* <div className="docExpFeeLan">
                              <div className="docEFL">
                                <div className="eflicon ">
                                  <LocalPhoneOutlined />
                                </div>
                                <div className="eflText">
                                  {`${patient?.patientPhCountryCode} ${patient?.patientContactNumber}`}
                                </div>
                              </div>
                              <div className="docEFL">
                                <div className="eflicon ">
                                  <EmailOutlined />
                                </div>
                                <div className="eflText">
                                  {patient?.patientEmailId}
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        {!selectedPatient?.patientFaceId && (
                          <div className="scanFaceBtn">
                            <div className="faceRegTxt">
                              {t("register_face")}
                            </div>
                            <IconButton
                              onClick={() => handelOpenRegisterFace()}
                            >
                              <PersonBoundingBox />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="bb"></div>
                {isNewPatient && (
                  <>
                    <div className="formElementGrp nsviewApointDetaislstepFour">
                      <div className="formElement mxW-100">
                        <FormControl className="formControl">
                          <InputLabel id="patient-select-label">
                            {t("title")}
                          </InputLabel>
                          <Select
                            labelId="patient-select-label"
                            id="patient-select-title"
                            label={t("title")}
                            className="formInputField"
                            variant="outlined"
                            size="small"
                            value={baPatTitle}
                            onChange={handleChangebaPatTitle}
                          >
                            <MenuItem value="Mr">{t("mr")}</MenuItem>
                            <MenuItem value="Ms">{t("ms")}</MenuItem>
                            <MenuItem value="Mrs">{t("mrs")}</MenuItem>
                            <MenuItem value="Miss">{t("miss")}</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            label={t("first_name")}
                            required
                            autoComplete="off"
                            placeholder={t("first_name")}
                            variant="outlined"
                            className="formTextFieldArea"
                            value={firstName}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>

                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            // label={t("middle_name")}
                            autoComplete="off"
                            // placeholder={t("middle_name")}
                            label={
                              currentSubdomain === "massalud"
                                ? t("first_surname")
                                : t("middle_name")
                            }
                            placeholder={
                              currentSubdomain === "massalud"
                                ? t("first_surname")
                                : t("middle_name")
                            }
                            variant="outlined"
                            className="formTextFieldArea"
                            value={middleName}
                            onChange={(e) => {
                              setMiddleName(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>

                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            required
                            autoComplete="off"
                            // label={t("last_name")}
                            // placeholder={t("last_name")}
                            label={
                              currentSubdomain === "massalud"
                                ? t("second_surname")
                                : t("last_name")
                            }
                            placeholder={
                              currentSubdomain === "massalud"
                                ? t("second_surname")
                                : t("last_name")
                            }
                            variant="outlined"
                            className="formTextFieldArea"
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>

                    <div className="formElementGrp">
                      <div className="formElement mxW-100">
                        <FormControl className="formControl">
                          <InputLabel id="demo-simple-select-label">
                            {t("gender")}*
                          </InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={patientGender}
                            label={t("gender")}
                            onChange={handleChangepatientGender}
                            className="formInputField"
                            variant="outlined"
                          >
                            <MenuItem value="Male">{t("male")}</MenuItem>
                            <MenuItem value="Female">{t("female")}</MenuItem>
                            <MenuItem value="Others">{t("others")}</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className="formElement mxW174 nsviewApointDetailstepFive">
                        <FormControl className="formControl">
                          <InputLabel
                            id="employeeJoingDate"
                            className="setCompletionDate"
                          >
                            {t("dob")} *
                          </InputLabel>
                          <ReactDatePicker
                            locale={currentLocale}
                            toggleCalendarOnIconClick
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            showIcon
                            labelId="employeeJoingDate"
                            className="formDatePicker"
                            maxDate={new Date()}
                            dateFormat="dd-MMM-yyyy"
                            // selected={baPatDOB}
                            // onChange={(date) => setBaPatDOB(date)}

                            selected={selectedDob}
                            onChange={(dateOfBirth) => {
                              setSelectedDob(dateOfBirth);

                              if (dateOfBirth) {
                                const formattedDate = format(
                                  dateOfBirth,
                                  "dd-MM-yyyy"
                                );

                                setAge(
                                  DateUtils.getAgeFromDateOfBirth(dateOfBirth)
                                );
                              }
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="patientAgeBreack">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label={t("years")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={age?.years || ""}
                              name="years"
                              onChange={handleAgeChange}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label={t("months")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={age?.months || ""}
                              name="months"
                              onChange={handleAgeChange}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label={t("days")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={age?.days || ""}
                              name="days"
                              onChange={handleAgeChange}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="formElementGrp nsviewApointDetailstepSix">
                      <div className="formElement mxW120">
                        <FormControl className="formControl">
                          <InputLabel id="demo-simple-select-label">
                            {t("civil_status")}
                          </InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={patientMaritalStatus}
                            label={t("civil_status")}
                            onChange={handleChangeMaritalStatus}
                            className="formInputField"
                            variant="outlined"
                          >
                            <MenuItem value="single">{t("single")}</MenuItem>
                            <MenuItem value="married">{t("married")}</MenuItem>
                            <MenuItem value="separated">
                              {t("separated")}
                            </MenuItem>
                            <MenuItem value="divorced">
                              {t("divorced")}
                            </MenuItem>
                            <MenuItem value="widowed">{t("widowed")}</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="custContactNo">
                        <select
                          name="countryCode"
                          id="countryCode"
                          className="countryCode"
                          value={patientIsdCode}
                          // onChange={(e) => {
                          //   setPatyientIsdCode(e.target.value);
                          // }}
                          onChange={handleChangeemplyPhnCC}
                        >
                          {cdCodeList.map((cdCodeList) => (
                            <option key={cdCodeList} value={cdCodeList}>
                              {cdCodeList}
                            </option>
                          ))}
                          {/* <option value="+91" selected>
                            +91
                          </option> */}
                          {/* <option value="+1">+1</option> */}
                          {/* <option value="+635">+635</option> */}
                        </select>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label={t("contact_number")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={contactNumber}
                              onChange={(e) => {
                                setContactNumber(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            required
                            autoComplete="off"
                            label={t("email_id")}
                            variant="outlined"
                            className="formTextFieldArea"
                            value={patientEmailId}
                            onChange={(e) => {
                              setPatientEmailId(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}

                <div className="formElement appoinmentBookingGrp nsviewApointDetailstepSeven">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={forwardTo}
                      onChange={(e) => {
                        const value = e.target.value;
                        setForwardTo(value);
                        if (value === "reschedule") {
                          getDoctorAppoinment(
                            dayjs(new Date()).format("DD-MM-YYYY")
                          );
                        }
                      }}
                    >
                      {/* {!(new Date() < appoinmentData.date) && (
                        <>
                          <FormControlLabel
                            className="formRadioField"
                            value="add-to-queue"
                            control={<Radio />}
                            label={t("add_to_queue")}
                          />
                        </>
                      )} */}

                      {new Date().setHours(0, 0, 0, 0) ===
                        new Date(appoinmentData.date).setHours(0, 0, 0, 0) &&
                        appoinmentData.slot.visitId === 0 && (
                          <FormControlLabel
                            className="formRadioField"
                            value="add-to-queue"
                            control={<Radio />}
                            label={t("add_to_queue")}
                          />
                        )}

                      <FormControlLabel
                        className="formRadioField"
                        value="reschedule"
                        control={<Radio />}
                        label={t("reschedule")}
                      />

                      <FormControlLabel
                        className="formRadioField"
                        value="cancel-appoinment"
                        control={<Radio />}
                        label={t("cancel_appoinment")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {selectedPatient &&
                  (forwardTo === "add-to-queue" ||
                    forwardTo === "video-page") && (
                    <div className="nsviewApointDetailstepEight">
                      <div className="bb"></div>
                      {/* <div className="tskElemHeding">{t("vitals")}</div>
                      <div className="vitalsGroup">
                        <div className="vitalsInd">
                          <div className="vitIcon">
                            <BP />
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{t("blood_pressure")}</div>
                            <div
                              className={`vitValue ${
                                vitals?.BP?.isNormal === "Y" ||
                                !vitals ||
                                !vitals.BP
                                  ? "vNormal"
                                  : "vRisk"
                              }`}
                            >
                              <input
                                className="editInputVitals"
                                type="text"
                                placeholder={t("sys")}
                                value={
                                  vitals?.BP?.vitalValue?.split("/")?.[0] || ""
                                }
                                onChange={(e) => {
                                  handleChangeSistolic("BP", e);
                                }}
                              />
                              <span className="slash"></span>
                              <input
                                className="editInputVitals"
                                type="text"
                                placeholder={t("dys")}
                                value={
                                  vitals?.BP?.vitalValue?.split("/")?.[1] || ""
                                }
                                onChange={(e) => {
                                  handleChangeDiastolic("BP", e);
                                }}
                              />
                              <span>mmHg</span>
                            </div>
                          </div>
                        </div>

                        <div className="vitalsInd">
                          <div className="vitIcon">
                            <HeartPulse />
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{t("heart_rate")}</div>
                            <div
                              className={`vitValue ${
                                vitals?.HEART_RATE?.isNormal === "Y" ||
                                !vitals ||
                                !vitals.HEART_RATE
                                  ? "vNormal"
                                  : "vRisk"
                              }`}
                            >
                              <input
                                className="editInputVitals"
                                type="text"
                                placeholder={t("example_eighty")}
                                value={vitals?.HEART_RATE?.vitalValue || ""}
                                onChange={(e) =>
                                  handleChangeHeartRage("HEART_RATE", e)
                                }
                              />
                              <span>bpm</span>
                            </div>
                          </div>
                        </div>

                        <div className="vitalsInd">
                          <div className="vitIcon">
                            <Lungs />
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{t("respiration_rate")}</div>
                            <div
                              className={`vitValue ${
                                vitals?.RESPIRATION_RATE?.isNormal === "Y" ||
                                !vitals ||
                                !vitals.RESPIRATION_RATE
                                  ? "vNormal"
                                  : "vRisk"
                              }`}
                            >
                              <input
                                className="editInputVitals"
                                type="text"
                                placeholder={t("eg_12")}
                                value={
                                  vitals?.RESPIRATION_RATE?.vitalValue || ""
                                }
                                onChange={(e) => {
                                  handleChangeRR(
                                    e.target.value,
                                    "RESPIRATION_RATE"
                                  );
                                }}
                              />
                              <span>bpm</span>
                            </div>
                          </div>
                        </div>

                        <div className="vitalsInd">
                          <div className="vitIcon">
                            <SPO2 />
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{t("spo2")}</div>
                            <div
                              className={`vitValue ${
                                vitals?.SPO2?.isNormal === "Y" ||
                                !vitals ||
                                !vitals.SPO2
                                  ? "vNormal"
                                  : vitals?.SPO2?.isNormal === "M"
                                  ? "vModarate"
                                  : "vRisk"
                              }`}
                            >
                              <input
                                className="editInputVitals"
                                type="text"
                                placeholder={t("eg_98")}
                                value={vitals?.SPO2?.vitalValue || ""}
                                onChange={(e) => {
                                  handleChangeSpo2(e.target.value, "SPO2");
                                }}
                              />
                              <span>%</span>
                            </div>
                          </div>
                        </div>

                        <div className="vitalsInd">
                          <div className="vitIcon">
                            <ThermometerHalf />
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{t("temperature")}</div>
                            <div
                              className={`vitValue ${
                                vitals?.TEMPERATURE?.isNormal === "Y" ||
                                !vitals ||
                                !vitals.TEMPERATURE
                                  ? "vNormal"
                                  : vitals?.TEMPERATURE?.isNormal === "M"
                                  ? "vModarate"
                                  : "vRisk"
                              }`}
                            >
                              <input
                                className="editInputVitals"
                                type="text"
                                placeholder={t("eg_96_6")}
                                value={vitals?.TEMPERATURE?.vitalValue || ""}
                                onChange={(e) => {
                                  handleChangeTemparature(
                                    e.target.value,
                                    "TEMPERATURE"
                                  );
                                }}
                              />
                              <select
                                className="editInputUnits"
                                value={temperatureUnit}
                                onChange={(e) => {
                                  setTemperatureUnit(e.target.value);
                                  const tempVital = { ...vitals };
                                  if (!tempVital.TEMPERATURE) {
                                    tempVital.TEMPERATURE = {
                                      vitalName: "Temperature",
                                      unit: e.target.value,
                                      vitalValue: "",
                                      // recodedTime: "",
                                      isNormal: "Y",
                                      vitalCode: "TEMPERATURE",
                                    };
                                  } else {
                                    tempVital.TEMPERATURE.unit = e.target.value;
                                  }
                                  setVitals(tempVital);
                                }}
                              >
                                <option value="°F">°F </option>
                                <option value="°C">°C </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="vitalsInd">
                          <div className="vitIcon">
                            <MonitorWeightOutlined />
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{t("weight")}</div>
                            {console.log("vital height =", vitals?.WEIGHT)}
                            <div
                              className={`vitValue ${
                                vitals?.WEIGHT?.isNormal === "Y" ||
                                !vitals ||
                                !vitals.WEIGHT
                                  ? "vNormal"
                                  : vitals?.WEIGHT?.isNormal === "M"
                                  ? "vModarate"
                                  : "vRisk"
                              }`}
                            >
                              <input
                                className="editInputVitals"
                                type="text"
                                placeholder={t("eg_80")}
                                value={vitals?.WEIGHT?.vitalValue || ""}
                                onChange={(e) => {
                                  handleChangeWeight(e.target.value, "WEIGHT");
                                }}
                              />
                              <select
                                className="editInputUnits"
                                value={weightUnit}
                                onChange={(e) => {
                                  setWeightUnit(e.target.value);
                                  const tempVital = { ...vitals };
                                  if (!tempVital.WEIGHT) {
                                    tempVital.WEIGHT = {
                                      vitalName: "Weight",
                                      unit: e.target.value,
                                      vitalValue: "",
                                      // recodedTime: "",
                                      isNormal: "Y",
                                      vitalCode: "WEIGHT",
                                    };
                                  } else {
                                    tempVital.WEIGHT.unit = e.target.value;
                                  }
                                  setVitals(tempVital);
                                }}
                              >
                                <option value="kg">kg </option>
                                <option value="lb">lb </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="vitalsInd">
                          <div className="vitIcon">
                            <HeightOutlined />
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{t("height")}</div>
                            <div
                              className={`vitValue ${
                                vitals?.HEIGHT?.isNormal === "Y" ||
                                !vitals ||
                                !vitals.HEIGHT
                                  ? "vNormal"
                                  : "vRisk"
                              }`}
                            >
                              {heightUnit === "ft~in" && (
                                <>
                                  <input
                                    className="editInputVitals"
                                    type="text"
                                    placeholder={t("eg_182")}
                                    value={
                                      Math.floor(
                                        parseInt(vitals?.HEIGHT?.vitalValue) /
                                          12
                                      ) || ""
                                    }
                                    onChange={(e) => {
                                      handleChangeFoot(
                                        e.target.value,
                                        "HEIGHT"
                                      );
                                    }}
                                  />
                                  <span>ft</span>
                                  <input
                                    className="editInputVitals"
                                    type="text"
                                    placeholder={t("eg_182")}
                                    value={
                                      Math.floor(
                                        parseInt(vitals?.HEIGHT?.vitalValue) %
                                          12
                                      ) || ""
                                    }
                                    onChange={(e) => {
                                      handleChangeInch(
                                        e.target.value,
                                        "HEIGHT"
                                      );
                                    }}
                                  />
                                </>
                              )}

                              {heightUnit !== "ft~in" && (
                                <input
                                  className="editInputVitals"
                                  type="text"
                                  placeholder={t("eg_182")}
                                  value={vitals?.HEIGHT?.vitalValue || ""}
                                  onChange={(e) => {
                                    handleChangeHeight(
                                      e.target.value,
                                      "HEIGHT"
                                    );
                                  }}
                                />
                              )}
                              <select
                                className="editInputUnits"
                                value={heightUnit}
                                onChange={(e) => {
                                  setHeightUnit(e.target.value);
                                  const tempVital = { ...vitals };
                                  if (!tempVital.HEIGHT) {
                                    tempVital.HEIGHT = {
                                      vitalName: "Weight",
                                      unit: e.target.value,
                                      vitalValue: "",
                                      // recodedTime: "",
                                      isNormal: "Y",
                                      vitalCode: "HEIGHT",
                                    };
                                  } else {
                                    tempVital.HEIGHT.unit = e.target.value;
                                  }
                                  setVitals(tempVital);
                                }}
                              >
                                <option value="cm">cm </option>
                                <option value="ft~in">ft-in </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {selectedPatient &&
                          getAge(selectedPatient?.patientDob) <= 4 && (
                            <div className="vitalsInd">
                              <div className="vitIcon">
                                <HeadC />
                              </div>
                              <div className="vitInfo">
                                <div className="vitNm">
                                  {t("head_circumference")}
                                </div>
                                <div className="vitValue">
                                  <input
                                    className="editInputVitals"
                                    type="text"
                                    placeholder={t("eg_34_5")}
                                    value={
                                      vitals?.HEAD_CIRCUMFERENCE?.vitalValue ||
                                      ""
                                    }
                                    onChange={(e) => {
                                      handleChangeHeadCircumference(
                                        e.target.value,
                                        "HEAD_CIRCUMFERENCE"
                                      );
                                    }}
                                  />
                                  <select
                                    className="editInputUnits"
                                    value={temperatureUnit}
                                    onChange={(e) => {
                                      setTemperatureUnit(e.target.value);
                                      const tempVital = { ...vitals };
                                      if (!tempVital.HEAD_CIRCUMFERENCE) {
                                        tempVital.HEAD_CIRCUMFERENCE = {
                                          vitalName: "Head Circumference",
                                          unit: e.target.value,
                                          vitalValue: "",
                                          // recodedTime: "",
                                          isNormal: "Y",
                                          vitalCode: "HEAD_CIRCUMFERENCE",
                                        };
                                      } else {
                                        tempVital.HEAD_CIRCUMFERENCE.unit =
                                          e.target.value;
                                      }
                                      setVitals(tempVital);
                                    }}
                                  >
                                    <option value="cm">cm </option>
                                    <option value="in">in </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}

                        {selectedPatient &&
                          getAge(selectedPatient?.patientDob) <= 4 && (
                            <div className="vitalsInd">
                              <div className="vitIcon">
                                <UAC />
                              </div>
                              <div className="vitInfo">
                                <div className="vitNm">
                                  {t("upper_arm_circumference")}
                                </div>
                                <div className="vitValue">
                                  <input
                                    className="editInputVitals"
                                    type="text"
                                    placeholder={t("eg_12_5")}
                                    value={
                                      vitals?.UPPER_ARM_CIRCUMFERENCE
                                        ?.vitalValue || ""
                                    }
                                    onChange={(e) => {
                                      handleChangeUpperArm(
                                        e.target.value,
                                        "UPPER_ARM_CIRCUMFERENCE"
                                      );
                                    }}
                                  />
                                  <select
                                    className="editInputUnits"
                                    value={temperatureUnit}
                                    onChange={(e) => {
                                      setTemperatureUnit(e.target.value);
                                      const tempVital = { ...vitals };
                                      if (!tempVital.UPPER_ARM_CIRCUMFERENCE) {
                                        tempVital.UPPER_ARM_CIRCUMFERENCE = {
                                          vitalName: "Temperature",
                                          unit: e.target.value,
                                          vitalValue: "",
                                          // recodedTime: "",
                                          isNormal: "Y",
                                          vitalCode: "UPPER_ARM_CIRCUMFERENCE",
                                        };
                                      } else {
                                        tempVital.UPPER_ARM_CIRCUMFERENCE.unit =
                                          e.target.value;
                                      }
                                      setVitals(tempVital);
                                    }}
                                  >
                                    <option value="cm">cm </option>
                                    <option value="in">in </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}

                        {selectedPatient?.patientGender === "Female" &&
                          getAge(selectedPatient?.patientDob) >= 12 && (
                            <div className="vitalsInd">
                              <div className="vitIcon">
                                <LMP />
                              </div>
                              <div className="vitInfo">
                                <div className="vitNm">{t("lmp")}</div>
                                <div className="vitValue">
                                  <input
                                    className="editInputDate"
                                    type="date"
                                    value={
                                      vitals?.LMP?.vitalValue
                                        ? dayjs(vitals?.LMP?.vitalValue).format(
                                            "YYYY-MM-DD"
                                          )
                                        : lmp
                                    }
                                    onChange={(event) => {
                                      handleLmpChange(
                                        event.target.value,
                                        "LMP"
                                      );
                                      setLmp(event.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        <div className="vitalsInd">
                          <div className="vitIcon">
                            <BloodtypeOutlined />
                          </div>
                          <div className="vitInfo">
                            <div className="vitNm">{t("blood_group")}</div>
                            <div className="vitValue">
                              <select
                                className="editInputUnits"
                                onChange={(e) => {
                                  const tempVitals = { ...vitals };
                                  const value = e.target.value;
                                 
                                  setBloodGroup(value);
                                }}
                                value={bloodGroup || ""}
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                <option value="A+">A + </option>
                                <option value="A-">A - </option>
                                <option value="B+">B + </option>
                                <option value="B-">B - </option>
                                <option value="AB+">AB + </option>
                                <option value="AB-">AB - </option>
                                <option value="O+">O + </option>
                                <option value="O-">O - </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <Button
                          startIcon={<DeviceHddFill />}
                          className={"dfultPrimaryBtn"}
                        >
                          {t("get_vitals_from_device")}
                        </Button>
                      </div> */}

                      <PatientVitals
                        data={[]}
                        patientAge={getAge(selectedPatient?.patientDob)}
                        patientGender={selectedPatient?.patientGender}
                        showBloodgroup={false}
                        handleDataChange={(data) => {}}
                        editMode={true}
                        handleSelectBloodGroup={(data) => {
                          if (data) {
                            setBloodGroup(data);
                          }
                        }}
                        handleSaveVitals={(data) => {
                          if (data) {
                            setVitals(data);
                          }
                        }}
                      />

                      <div className="bb"></div>

                      <PatientComplaints
                        data={prescriptionData}
                        updateData={updateData}
                        extraData={patientInfo}
                      />

                      {/* files start */}

                      <div className="taskElementGrp">
                        <div className="tskElemHeding">{t("files")}</div>

                        <div class="elementFileListedGrp">
                          <FileCursor>
                            <>
                              {documents?.map((item, index) => {
                                return (
                                  <>
                                    <div className="elemntFile">
                                      <Tooltip
                                        title={item.fileName}
                                        arrow
                                        className="elmFileTolTip"
                                      >
                                        <span className="elemntFilIoc">
                                          <DescriptionOutlined />
                                        </span>
                                        <span className="elemntFilNm">
                                          {item.fileName}
                                        </span>

                                        <span
                                          className="elemntFilIoc"
                                          onClick={(e) => {
                                            const temp = [...documents];
                                            temp.splice(index, 1);
                                            setDocuments(temp);
                                          }}
                                        >
                                          <Cancel />
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </>
                                );
                              })}

                              {previousMedicalRecords
                                ?.filter((item) => item.isChecked)
                                ?.map((item) => {
                                  return (
                                    <>
                                      <div className="elemntFile">
                                        <Tooltip
                                          title={item.fileName}
                                          arrow
                                          className="elmFileTolTip"
                                        >
                                          <span className="elemntFilIoc">
                                            <DescriptionOutlined />
                                          </span>
                                          <span className="elemntFilNm">
                                            {item.fileName}
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                          </FileCursor>
                          <div class="elmntAddMrFiles">
                            <Button
                              className="elemntMrFileBtn"
                              variant="outlined"
                              // onClick={handleAddnewFile}
                              onClick={(e) => {
                                setShowFileUploadDialog(true);
                              }}
                            >
                              <Plus />
                            </Button>
                            <input
                              type="file"
                              // ref={fileInputRef}
                              // onChange={handleChangeFile}
                              style={{ display: "none" }}
                            ></input>
                          </div>
                        </div>
                      </div>

                      {/* files end */}
                      <div className="taskElementGrp mt10">
                        <div className="tskElemHeding">
                          {t("systemic_illness")}
                        </div>

                        <div className="formElementGrp flex-wrap">
                          <div className="formElement ">
                            <FormControl className="formControl">
                              <RadioGroup
                                className="formRadioGroup"
                                labelId="setTasktype"
                                name="setTasktype"
                                value={systemicIllness}
                                onChange={selectSystemicIllness}
                              >
                                <FormControlLabel
                                  className="formRadioField"
                                  value="yes"
                                  control={<Radio />}
                                  label={t("yes")}
                                />
                                <FormControlLabel
                                  className="formRadioField"
                                  value="no"
                                  control={<Radio />}
                                  label={t("no")}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          {systemicIllness === "yes" && (
                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel id="demo-multiple-checkbox-label">
                                  {t("systemic_illness")}
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  className="formInputField"
                                  variant="outlined"
                                  value={illnessName}
                                  onChange={handleChangeIllness}
                                  input={
                                    <OutlinedInput
                                      label={t("systemic_illness")}
                                    />
                                  }
                                  renderValue={(selected) =>
                                    selected
                                      .map((item) => item.systemicIllnessName)
                                      .join(", ")
                                  }
                                  MenuProps={MenuProps}
                                >
                                  {systemicIllnessList.map((item) => (
                                    <MenuItem key={item.id} value={item}>
                                      <Checkbox
                                        checked={
                                          illnessName.findIndex(
                                            (element) => element.id === item.id
                                          ) > -1
                                        }
                                      />
                                      <ListItemText
                                        primary={`${item.systemicIllnessName}`}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="taskElementGrp mt10">
                        <div className="tskElemHeding">
                          {t("substance_abuse")}
                        </div>

                        <div className="formElementGrp flex-wrap">
                          <div className="formElement ">
                            <FormControl className="formControl">
                              <RadioGroup
                                className="formRadioGroup"
                                labelId="setTasktype"
                                name="setTasktype"
                                value={substanceAbuse}
                                onChange={selectSubstanceAbuse}
                              >
                                <FormControlLabel
                                  className="formRadioField"
                                  value="yes"
                                  control={<Radio />}
                                  label={t("yes")}
                                />
                                <FormControlLabel
                                  className="formRadioField"
                                  value="no"
                                  control={<Radio />}
                                  label={t("no")}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          {substanceAbuse === "yes" && (
                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel id="demo-multiple-checkbox-label">
                                  {t("substance_abuse")}
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  className="formInputField"
                                  variant="outlined"
                                  value={abuseName}
                                  onChange={handleChangeAbuse}
                                  input={
                                    <OutlinedInput
                                      label={t("substance_abuse")}
                                    />
                                  }
                                  renderValue={(selected) =>
                                    selected
                                      .map((item) => item.substanceAbuseName)
                                      .join(", ")
                                  }
                                  MenuProps={MenuProps}
                                >
                                  {systemicAbuseList.map((item) => (
                                    <MenuItem key={item.id} value={item}>
                                      <Checkbox
                                        checked={
                                          abuseName.findIndex(
                                            (element) => element.id === item.id
                                          ) > -1
                                        }
                                      />
                                      <ListItemText
                                        primary={item.substanceAbuseName}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="taskElementGrp mt10">
                        <div className="tskElemHeding">
                          {t("reference_details")}
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              label={t("referred_by")}
                              autoComplete="off"
                              variant="outlined"
                              className="formTextFieldArea"
                              value={referedBy}
                              onChange={(e) => {
                                setReferedBy(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="bb"></div>

                      <MedicalHistory
                        data={patientInfo}
                        updateData={updateData}
                        previousInfo={patientInfo}
                      />

                      <PatientFamilyHistory
                        data={prescriptionData}
                        updateData={updateData}
                        previousInfo={patientInfo}
                      />

                      <PatientDrugAllergy
                        data={prescriptionData}
                        updateData={updateData}
                        extraData={patientInfo}
                        previousInfo={patientInfo}
                      />
                      <PatientFoodAllergy
                        data={prescriptionData}
                        updateData={updateData}
                        extraData={patientInfo}
                        previousInfo={patientInfo}
                      />
                    </div>
                  )}
                {forwardTo && forwardTo === "reschedule" && (
                  <div className="nsviewApointDetailstepNine">
                    <div className="bb"></div>
                    <div className="tskElemHeding">
                      {t("reschedule_appointment")}
                    </div>
                    {appoinmentData?.slot?.rescheduleDate &&
                    appoinmentData?.slot?.rescheduleReqRmks ? (
                      // <p style={{ color: "white" }}>
                      //   {appoinmentData?.doctorName} has rescheduled the
                      //   appointment on{" "}
                      //   <strong>{appoinmentData?.slot?.rescheduleDate}</strong>{" "}
                      //   due to{" "}
                      //   <strong>
                      //     {appoinmentData?.slot?.rescheduleReqRmks}
                      //   </strong>
                      //   .
                      // </p>
                      <p style={{ color: "white" }}>
                        {t("reschedule_message_patient", {
                          doctorName: appoinmentData?.doctorName || "Doctor",
                          rescheduleDate:
                            appoinmentData?.slot?.rescheduleDate ||
                            "Unknown Date",
                          rescheduleReqRmks:
                            appoinmentData?.slot?.rescheduleReqRmks ||
                            "Unknown Reason",
                        })}
                      </p>
                    ) : (
                      ""
                    )}
                    {/* <div className="formElement mxW174">
                      <FormControl className="formControl">
                        <InputLabel
                          id="employeeJoingDate"
                          className="setCompletionDate"
                        >
                          {t("reschedule_date")}
                        </InputLabel>
                        <ReactDatePicker
                          toggleCalendarOnIconClick
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          showIcon
                          labelId="employeeJoingDate"
                          className="formDatePicker"
                          dateFormat="dd-MMM-yyyy"
                          // selected={baPatDOB}
                          // onChange={(date) => setBaPatDOB(date)}
                          minDate={new Date()}
                          selected={rescheduleDate}
                          onChange={(dateOfBirth) => {
                            setRescheduleDate(dateOfBirth);

                            if (dateOfBirth) {
                              getDoctorAppoinment(
                                dayjs(dateOfBirth).format("DD-MM-YYYY")
                              );
                            }
                          }}
                        />
                      </FormControl>
                    </div> */}

                    {/* <div class="slots-container appoinmentBookingGrp">
                     

                      {console.log("doctorSlots =", doctors)}

                      {doctors?.slots?.map((item, index) => {
                        console.log(
                          "****************** item =",
                          selectedPatient
                        );
                        return (
                          <div
                            class={`slot-box ${
                              selectedSlotIndex === index ? "booked" : ""
                            }`}
                            onClick={() => {
                              setSelectedSlotIndex(index);
                            }}
                          >
                            <div className="slotInfo">
                              <div class="icon">
                                <AccessibleRounded />
                              </div>
                              <div className="slotTime">
                                {`${item.fromTime} - ${item.toTime}`}
                              </div>
                            </div>

                            
                          </div>
                        );
                      })}
                    </div> */}

                    {/* New UI Start */}

                    <div className="formSearchGrp">
                      <div className="formElement width250">
                        <FormControl className="formControl">
                          <InputLabel id="selectDoctor">
                            {t("select_doctor")}
                          </InputLabel>
                          <Select
                            required
                            labelId="selectDoctor"
                            id="select_Doctor"
                            // value={selectDoctor || ""}
                            value={
                              selectDoctor === undefined ||
                              selectDoctor === "undefined"
                                ? ""
                                : selectDoctor
                            }
                            label={t("select_doctor")}
                            onChange={handleChangselectDoctor}
                            className="formInputField"
                            variant="outlined"
                            renderValue={(value) => {
                              const selectedDoctor =
                                listOfDoctorForAppointment.find(
                                  (doc) => doc.doctorId === value
                                );
                              return `${selectedDoctor?.doctorName}`;
                            }}
                          >
                            {listOfDoctorForAppointment.map((doctor) => (
                              <MenuItem
                                key={doctor.doctorId}
                                value={doctor.doctorId}
                              >
                                <div
                                  style={{ padding: "4px 12px" }}
                                  className="sDocOptList"
                                >
                                  <div className="sDname">
                                    {doctor.doctorName}
                                  </div>
                                  <div className="sDElm">
                                    {doctor.specializationName}
                                  </div>
                                  <div className="sDElmGrp">
                                    <div className="sDElm">
                                      {t("experience")}:{" "}
                                      <span>{doctor.experience}</span>
                                    </div>
                                    <div className="sDElm">
                                      {t("fees")}:{" "}
                                      <span>
                                        {doctor.fees} {doctor.feesCurrency}
                                      </span>
                                    </div>
                                    <div className="sDElm">
                                      {t("language")}:{" "}
                                      <span>{doctor.languages}</span>
                                    </div>
                                    {/* <div className="sDElm">
                Patient in Queue: <span>{doctor.queueCount}</span>
              </div> */}
                                  </div>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="formElement width180">
                        <FormControl className="formControl selectMonth">
                          <InputLabel
                            id="monthSelect"
                            className="setCompletionDate"
                          >
                            {t("slots_date_from")}
                          </InputLabel>
                          <ReactDatePicker
                            locale={currentLocale}
                            toggleCalendarOnIconClick
                            yearDropdownItemNumber={100}
                            scrollableMonthYearDropdown
                            showIcon
                            labelId="monthSelect"
                            className="formDatePicker "
                            selected={monthSelect}
                            minDate={new Date()}
                            maxDate={
                              new Date(
                                new Date().setMonth(new Date().getMonth() + 4)
                              )
                            }
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => {
                              handleMonthSelect(date);
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>

                    <div className="days-header-actionBtn">
                      <IconButton onClick={() => handlePastSevenDaysDate()}>
                        <ArrowBackIosNewRounded />
                      </IconButton>

                      <div className="days-header">
                        {extractedData.map((day) => {
                          // Determine the appropriate class name for each day
                          let dayClass = "day-box";

                          if (day.slotDate === selectedDay) {
                            dayClass += " active";
                          } else if (
                            day.availableCount === 0 ||
                            day.availableCount === "0"
                          ) {
                            dayClass += " slotUnavailable";
                          }

                          return (
                            <div
                              key={day.slotDate}
                              className={dayClass}
                              onClick={() => setSelectedDay(day.slotDate)} // Update the selected day
                            >
                              <div className="daysDate">
                                <div className="ddHighTxt">{day.slotDate}</div>
                                <div className="ddSubTxt">{t(day.dayName)}</div>
                              </div>
                              <div className="slotsStatus">
                                {day.availableCount > 0 && (
                                  <div className="slotsAvailable">
                                    <div className="daysIcon">
                                      <AccessibleRounded />
                                    </div>
                                    <div className="slotsDtl">
                                      <div className="sdHtxt">
                                        {day.availableCount} {t("slots")}{" "}
                                      </div>
                                      <div className="sdSubTxt">
                                        {t("available")}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="slotsBooked">
                                  <div className="daysIcon">
                                    <AccessibleRounded />
                                  </div>
                                  <div className="slotsDtl">
                                    <div className="sdHtxt">
                                      {day.availableCount > 0
                                        ? `${day.bookedCount} ${t("slots")}`
                                        : `${day.bookedCount} ${t("booked")}`}
                                    </div>

                                    {day.availableCount > 0 ? (
                                      <div className="sdSubTxt">
                                        {t("booked")}
                                      </div>
                                    ) : (
                                      <div className="sdSubTxt">
                                        {t("slots_unavailable")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <IconButton onClick={handleFutureSevenDaysDate}>
                        <ArrowForwardIosRounded />
                      </IconButton>
                    </div>

                    <div className="appoinmentBookingSlot">
                      <div className="slots-container">
                        {console.log("extractedData", extractedData)}
                        {extractedData
                          .filter((day) => day.slotDate === selectedDay) // Filter slots based on selected day
                          .flatMap((day) =>
                            day.slots.map((slot) => {
                              // Determine the appropriate class name for each slot
                              let slotClass = "slot-box";
                              if (slot?.patientId && slot.patientId !== 0) {
                                slotClass += " booked";
                              } else if (slot.slotId === selectedSlotId) {
                                slotClass += " active";
                              } else if (slot.slotId === selectedSlotId) {
                                slotClass += "";
                              }

                              return (
                                <div
                                  key={slot.slotId}
                                  className={slotClass}
                                  onClick={() => {
                                    if (!slot.patientId) {
                                      setSelectedSlotId(slot.slotId);
                                      setSelectedSlot(slot);
                                      // handelOpenAppointmentBooking();
                                      // handleSubmitData(slot);
                                    } else {
                                      toast.error(
                                        t(
                                          "slot_already_booked_select_another_slot"
                                        )
                                      );
                                      setSelectedSlotId(null);
                                      setSelectedSlot(null);
                                    }
                                  }}
                                >
                                  <div className="slotInfo">
                                    <div className="icon">
                                      <AccessibleRounded />
                                    </div>
                                    <div className="slotTime">
                                      {" "}
                                      {slot.fromTime} - {slot.toTime}
                                    </div>
                                  </div>
                                  {slot?.patientName &&
                                    slot.patientName.trim() !== "" && (
                                      <div className="bookedPatInfo">
                                        <div className="patDtl">
                                          <div className="patgender">
                                            {slot?.patientGender &&
                                              slot.patientGender.toLowerCase() ===
                                                "male" && <MaleRounded />}
                                            {slot?.patientGender &&
                                              slot.patientGender.toLowerCase() ===
                                                "female" && <FemaleRounded />}
                                          </div>
                                          <div className="patName">
                                            {slot.patientName}
                                          </div>
                                        </div>
                                        <div className="patAge">
                                          {slot.patientAge}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              );
                            })
                          )}
                      </div>
                    </div>

                    {/* New UI End */}
                  </div>
                )}

                {forwardTo && forwardTo === "cancel-appoinment" && (
                  <div className="nsviewApointDetailstepTen">
                    <div className="bb"></div>

                    <div className="formElement">
                      <FormControl className="formControl pRelative">
                        <TextField
                          label={t("cancellation_reason")}
                          required
                          variant="outlined"
                          className="descptionTextFild"
                          multiline
                          rows={4}
                          maxRows={7}
                          value={cancelReason}
                          onChange={(e) => {
                            setCancleReason(e.target.value);
                          }}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter nsviewApointDetailstepEleven">
            <div className="doctorSelectWthSubBtn">
              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn"
                  // onClick={async () => {
                  //   const reqDto = {
                  //     slotId: appoinmentData?.slot?.slotId,
                  //     doctorId: appoinmentData?.doctorId,
                  //     patientId: selectedPatient?.rowPatientId || 0,
                  //     title: selectedPatient?.patientTitle || baPatTitle,
                  //     firstName:
                  //       selectedPatient?.patientFirstName || firstName || "",
                  //     middleName:
                  //       selectedPatient?.patientMiddleName || middleName || "",
                  //     lastName:
                  //       selectedPatient?.patientLastName || lastName || "",
                  //     gender: selectedPatient?.patientGender || patientGender,
                  //     dob: selectedPatient?.patientDob || selectedDob,
                  //     callingId: "",
                  //     contactNo:
                  //       selectedPatient?.patientContactNumber || contactNumber,
                  //     emailId:
                  //       selectedPatient?.patientEmailId || patientEmailId,
                  //     unitId: loginUserDetail?.signleUnitId || 0,
                  //     orgId: loginUserDetail?.orgId,
                  //     civilStatus: "",
                  //     patientUserId: selectedPatient?.patientUserId || "",
                  //     patientIdDisplay: "",
                  //   };
                  //   console.log("reqDto= ", reqDto);
                  //   zhPatientService
                  //     .bookAppointmentFromUnit(loginUserDetail.userId, reqDto)
                  //     .then((response) => {
                  //       if (response.data != 0) {
                  //         toast.success("Appoinment booked successfully");
                  //         handelCloseAppointmentBooking();
                  //       } else {
                  //         toast.error("Appoinment booking unsuccessful");
                  //       }
                  //     });
                  // }}

                  onClick={async () => {
                    // console.log(
                    //   "selectedPatientxxxxxxxxxxxxxx",
                    //   selectedPatient
                    // );
                    // return;

                    if (!selectedPatient) {
                      toast.error(t("please_select_patient"));
                      return;
                    }

                    if (forwardTo === "cancel-appoinment") {
                      //
                      setShowCancellationReason(true);

                      if (cancelReason.trim() === "") {
                        toast.error(t("please_enter_cancellation_reason"), {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        setShowCancellationReason(false);
                        return;
                      }

                      const cancelData =
                        await zhPatientService.cancelAppointment(
                          appoinmentData.slot.slotId,
                          selectedPatient.rowPatientId,
                          loginUserDetail.userId,
                          cancelReason
                        );

                      if (cancelData.data != 0) {
                        toast.success(t("appoinment_canceled"));
                        setShowCancellationReason(false);
                        handelCloseAppointmentBooking();
                        updateBookAppoinments();
                      } else {
                        toast.error(t("appoinment_could_not_be_canceled"));
                      }

                      return;
                    }
                    if (forwardTo === "reschedule") {
                      if (!selectedSlot) {
                        toast.error(t("no_slot_selected"));
                        return;
                      }
                      const reschduleResult =
                        await zhPatientService.rescheduleAppointment(
                          appoinmentData.slot.slotId,
                          selectedSlot.slotId,
                          // doctors.slots[selectedSlotIndex].slotId,
                          selectedPatient.rowPatientId,
                          loginUserDetail.userId,
                          "Test Schecule"
                        );
                      if (reschduleResult.data != 0) {
                        toast.success(t("appoinment_rescheduled_successfully"));
                        handelCloseAppointmentBooking();
                        updateBookAppoinments();
                      } else {
                        toast.error(t("appoinment_could_not_be_rescheduled"));
                      }
                      return;
                    }

                    if (!appoinmentData) {
                      toast.error(t("please_select_doctor"));
                      return;
                    }

                    if (
                      !selectedPatient.patientExternalSourceId &&
                      appoinmentData &&
                      !selectedPatient.patientImageBase64Data &&
                      !selectedPatient.patientImageUrl
                    ) {
                      toast.error(t("please_give_patient_image"));
                      return;
                    }

                    const isValid = validateVitals(
                      vitals,
                      getAge(selectedPatient.patientDob),
                      matchedPatientDetails.patientGender
                    );
                    if (!isValid) {
                      setShowConfirmation(true);
                      return;
                    }

                    submitFormData();
                  }}
                >
                  {t("submit")}
                </Button>

                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseAppointmentBooking()}
                >
                  {t("close")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {registerFace && (
        <ConfirmProvider>
          <PatientFacialBiometricsRegister
            open={registerFace}
            onClose={() => setRegisterFace(false)}
            setRegisterFace={setRegisterFace}
            onFaceLivenessCheckComplete={onFaceLivenessCheckComplete}
          />
        </ConfirmProvider>
      )}
      {showConfirmation && (
        <CustomModal
          modalTitle={t("do_you_want_to_continue")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div>
            <ul>
              {VitalErrorList &&
                VitalErrorList.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                submitFormData();
                setShowConfirmation(false);
              }}
            >
              {t("continue")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </CustomModal>
      )}

      {matchedPatientDetails != null &&
        matchedPatientDetails.rowPatientId > 0 && (
          <CustomModal
            modalTitle={t(
              "the_provided_facial_biometrics_are_associated_with_this_account"
            )}
            closeModal={() => {
              setMatchedPatientDetails(null);
            }}
          >
            <div class="faceAssociateParent profile-container">
              <div class="image-section">
                <img
                  src={matchedPatientDetails.patientImageBase64Data}
                  alt="associated face"
                  className="profile-image"
                />
              </div>

              <div class="info-section">
                {/* <h2 class="person-name">{selectedPatient.patientFullName}</h2> */}
                <p>
                  <strong>{t("name")}:</strong>{" "}
                  {matchedPatientDetails.patientFullName}
                </p>
                <p>
                  <strong>{t("gender")}:</strong>{" "}
                  {matchedPatientDetails.patientGender}
                </p>
                <p>
                  <strong>{t("age")}:</strong>{" "}
                  {`${matchedPatientDetails.patAge.years}Y ${matchedPatientDetails.patAge.months}M ${matchedPatientDetails.patAge.days}D`}
                </p>
                <p>
                  <strong>{t("email")}:</strong>{" "}
                  {matchedPatientDetails.patientEmailId}
                </p>
                <p>
                  <strong>{t("contact_no")}:</strong>{" "}
                  {matchedPatientDetails.patientContactNumber}
                </p>
              </div>
            </div>

            {/* <div className="disMessage">
              *On pressing continue, the above patient data will replace the
              patient data associated with{" "}
              <strong>{selectedPatient.patientEmailId} </strong>
              email id with which this appointment is booked, please note this
              will affect all other appointments booked with{" "}
              <strong>{selectedPatient.patientEmailId} </strong>
            </div> */}

            <div className="disMessage">
              {t("disMessage", { emailId: selectedPatient.patientEmailId })}
            </div>

            <div className="modActionBtnGrp actionsCreateOrg">
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={submitChangePatientBookedSlot}
                disabled={loadingAssociate}
              >
                {!loadingAssociate ? t("continue") : t("please_wait")}
              </Button>
              {!loadingAssociate && (
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    setSelectedPatient((prevData) => ({
                      ...prevData,
                      patientImageBase64Data: null,
                    }));
                    setMatchedPatientDetails(null);
                  }}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </CustomModal>
        )}

      {showAssociateFacialBiometricsModal && (
        <CustomModal
          modalTitle={t(
            "the_provided_facial_biometrics_will_be_registered_with_the_account_details_shown_below"
          )}
          closeModal={() => {
            if (!loadingAssociate) {
              setShowAssociateFacialBiometricsModal(false);
            }
          }}
          // className={"width90"}
        >
          <div class="faceAssociateParent profile-container">
            <div class="image-section">
              <img
                src={selectedPatient.patientImageBase64Data}
                alt={t("associated_face")}
                className="profile-image"
              />
            </div>

            <div class="info-section">
              {/* <h2 class="person-name">{selectedPatient.patientFullName}</h2> */}
              <p>
                <strong>{t("name")}:</strong> {selectedPatient.patientFullName}
              </p>
              <p>
                <strong>{t("gender")}:</strong> {selectedPatient.patientGender}
              </p>
              <p>
                <strong>{t("age")}:</strong>{" "}
                {`${patientAge.years}Y ${patientAge.months}M ${patientAge.days}D`}
              </p>
              <p>
                <strong>{t("email")}:</strong> {selectedPatient.patientEmailId}
              </p>
              <p>
                <strong>{t("contact_no")}:</strong>{" "}
                {selectedPatient.patientContactNumber}
              </p>
            </div>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={associateFaceWithBookAppAccount}
              disabled={loadingAssociate}
            >
              {!loadingAssociate ? t("continue") : t("please_wait")}
            </Button>
            {!loadingAssociate && (
              <Button
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  // setPatientImg(null);
                  setSelectedPatient((prevData) => ({
                    ...prevData,
                    patientImageBase64Data: null,
                  }));
                  setShowAssociateFacialBiometricsModal(false);
                }}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </CustomModal>
      )}

      {showFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <PatientFileUpload
            documents={documents}
            setDocuments={setDocuments}
            onClose={(e) => {
              setShowFileUploadDialog(false);
            }}
            previousMedicalRecords={previousMedicalRecords}
            setPreviousMedicalRecords={setPreviousMedicalRecords}
          />
        </div>
      )}

      {/* {showCancellationReason && (
        <CustomModal
          modalTitle={"Cancelation Reason"}
          closeModal={() => {
            setShowCancellationReason(false);
          }}
          className={""}
        >
          <>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={"Cancellation Reason"}
                  variant="outlined"
                  multiline
                  value={cancelReason}
                  onChange={(e) => {
                    setCancleReason(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <Button
              className="dfultPrimaryBtn"
              onClick={async () => {
                const cancelData = await zhPatientService.cancelAppointment(
                  appoinmentData.slot.slotId,
                  selectedPatient.rowPatientId,
                  loginUserDetail.userId,
                  cancelReason
                );

                if (cancelData.data != 0) {
                  toast.success("Appoinment canceled successfully");
                  setShowCancellationReason(false);
                } else {
                  toast.error("Appoinment could not be canceled");
                }
              }}
            >
              {t("submit")}
            </Button>
          </>
        </CustomModal>
      )} */}
    </>
  );
}
