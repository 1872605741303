import React from "react";
import { Line } from "react-chartjs-2";
// import "chart.js/auto";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";
import { useTranslation } from "react-i18next";
const CardWebsiteStatusLineGraphUptimeDowntime = ({ data }) => {
  const { t } = useTranslation();
  console.log("CardWebsiteStatusLineGraphUptimeDowntime", data);
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    TimeScale,
    TimeSeriesScale
  );

  const nodes = Array.from(new Set(data.map((d) => d.node)));
  const labels = Array.from(new Set(data.map((d) => d.hour)));

  //   console.log("CardWebsiteStatusLineGraph", nodes);
  //   console.log("CardWebsiteStatusLineGraph", labels);

  const dataSet = [
    {
      label: t("Uptime"),
      data: labels.map((label) => {
        let sumUptime = 0.0;
        let nodesConsidered = 0;
        nodes.forEach((node) => {
          const nodeData = data.filter((d) => d.node === node);
          const entry = nodeData.find((d) => d.hour === label);
          // console.log("entry", node, nodeData, entry);
          if (entry && entry.uptime) {
            sumUptime += parseFloat(entry.uptime);
            nodesConsidered += 1;
          }
        });
        console.log("sumUptime", sumUptime);
        let avgUptime = sumUptime / nodesConsidered;
        console.log("avgUptime", avgUptime, label);
        // if (label === "2024-11-19 07:00:00") {
        //   avgUptime = 0.8;
        // }
        return avgUptime >= 0.9598 ? 1 : avgUptime - 1;
        // let avgUptime = (sumUptime / nodesConsidered) * 100;
        // console.log("avgUptime", avgUptime);
        // return parseFloat(avgUptime).toFixed(2);
      }),
      pointBackgroundColor: labels.map((label) => {
        let sumUptime = 0.0;
        let nodesConsidered = 0;
        nodes.forEach((node) => {
          const nodeData = data.filter((d) => d.node === node);
          const entry = nodeData.find((d) => d.hour === label);
          // console.log("entry", node, nodeData, entry);
          if (entry && entry.uptime) {
            sumUptime += parseFloat(entry.uptime);
            nodesConsidered += 1;
          }
        });
        console.log("sumUptime", sumUptime);
        let avgUptime = sumUptime / nodesConsidered;
        console.log("avgUptime", avgUptime, label);
        // if (label === "2024-11-19 07:00:00") {
        //   avgUptime = 0.8;
        // }
        return avgUptime >= 0.9598 ? "green" : "red";
      }),
      fill: false,
      borderColor: "green",
      tension: 0.1,
      pointRadius: 5,
      segment: {
        borderColor: (ctx) => {
          const { p0, p1 } = ctx;
          if (p0.raw === 1 && p1.raw < 0) {
            return "red";
          }
          if (p0.raw < 0 && p1.raw === 1) {
            return "red";
          }
          return "green";
        },
      },
    },
    // {
    //   label: "Downtime",
    //   data: labels.map((label) => {
    //     let sumUptime = 0.0;
    //     let nodesConsidered = 0;
    //     nodes.forEach((node) => {
    //       const nodeData = data.filter((d) => d.node === node);
    //       const entry = nodeData.find((d) => d.hour === label);
    //       // sumUptime += parseFloat(entry.uptime);
    //       if (entry && entry.uptime) {
    //         sumUptime += parseFloat(entry.uptime);
    //         nodesConsidered += 1;
    //       }
    //     });
    //     let avgUptime = (sumUptime / nodesConsidered) * 100;
    //     return parseFloat(100 - avgUptime).toFixed(2);
    //   }),
    //   fill: false,
    //   borderColor: "red",
    //   tension: 0.1,
    // },
  ];

  console.log("CardWebsiteStatusLineGraphUptimeDowntime", dataSet);

  const chartData = {
    labels: labels,
    datasets: dataSet,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${t("Uptime")}: ${
              context.parsed.y.toFixed(2) > 0
                ? context.parsed.y.toFixed(2) * 100
                : (1 - Math.abs(context.parsed.y.toFixed(2))) * 100
            }%`;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          tooltipFormat: "DD-MMM-YYYY hhA",
          displayFormats: {
            hour: "DD-MMM-YY hhA",
          },
        },
        title: {
          display: true,
          text: t("Date_Time"),
        },
        grid: {
          color: "#545454",
        },
      },
      y: {
        // beginAtZero: true,
        min: -1,
        max: 1,
        title: {
          display: true,
        },
        ticks: {
          stepSize: 1,
          callback: function (value) {
            return value;
          },
        },
        grid: {
          color: "#545454",
        },
        // grid: {
        //   drawBorder: true,
        //   color: (context) => (context.tick.value === 1 ? "green" : "red"), // Highlight 0 line
        // },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

// Utility function to get a random color for each node
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

function getNodeColor(nodeName) {
  switch (nodeName.toLowerCase()) {
    case "stockholm":
      return "#FF5733"; // Vibrant red-orange
    case "sydney":
      return "#FF33A1"; // Vibrant pink
    case "paris":
      return "#33FF57"; // Bright green
    case "tokyo":
      return "#3357FF"; // Bright blue
    case "ashburn":
      return "#FFD700"; // Vibrant gold
    case "johannesburg":
      return "#F4500"; // Dark orange
    case "los-angeles":
      return "#FF33FF"; // Neon pink
    case "sao-paulo":
      return "#00FF7F"; // Spring green
    case "london":
      return "#8A2BE2"; // Orange-red
    case "helsinki":
      return "#7FFF00"; // Chartreuse green
    case "toronto":
      return "#00CED1"; // Dark turquoise
    case "bangalore":
      return "#FF1493"; // Deep pink
    case "singapore":
      return "#1E90FF"; // Dodger blue
    case "nuremberg":
      return "#32CD32"; // Lime green
    case "new_york":
      return "#FF8C00"; // Blue violet
    default:
      return "#808080"; // Default gray for unknown nodes
  }
}

export default CardWebsiteStatusLineGraphUptimeDowntime;
