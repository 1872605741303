import { jwtDecode } from "jwt-decode";
import {
  CHAT_DOCUMENT_EXTENSION,
  CHAT_PHOTO_EXTENSION,
  CHAT_VIDEO_EXTENSION,
} from "../constants/const_values";
import dayjs from "dayjs";
import { apiEndPoint } from "../constants/url_provider";
import {
  MoodBad,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import { AppInitializerContext } from "./AppInitializer";
import { useContext } from "react";
import SecureIndexedDB from "./IndexedDB";
import { toast } from "react-toastify";

const commonFileType = [
  "jpeg",
  "jpg",
  "png",
  "mp4",
  "gif",
  "mp3",
  "svg",
  "tiff",
  "tif",
  "ogg",
  "mp3",
  "wav",
  "7z",
  "arj",
  "pkg",
  "rar",
  "rpm",
  "tar",
  "zip",
  "bin",
  "dmg",
  "iso",
  "csv",
  "dat",
  "sql",
  "tar",
  "xml",
  "log",
  "apk",
  "bin",
  "jar",
  "py",
  "bmp",
  "webp",
  "asp",
  "aspx",
  "ppt",
  "pptx",
  "odp",
  "ods",
  "xls",
  "xlsm",
  "xlsx",
  "pdf",
  "txt",
  "odt",
  "rtf",
  "tex",
  "doc",
  "docx",
];

export const getLoginUserDetailsWithoutContext = async () => {
  // const { userLoginDetails, setUserLoginDetails } = useGlobal();
  const token = await SecureIndexedDB.getItem("token");
  // console.log("getLoginUserDetails", token);
  if (token != null && token !== "null") {
    try {
      return jwtDecode(token);
    } catch (err) {
      return null;
    }
  }
  // return userLoginDetails;
};

export const getLoginUserDetailsLocal = () => {
  const token = localStorage.getItem("token");
  // console.log("getLoginUserDetails", token);
  if (token != null && token !== "null") {
    try {
      return jwtDecode(token);
    } catch (err) {
      return null;
    }
  }
};

export const GetLoginUserDetails = () => {
  const token = localStorage.getItem("token");
  // console.log("getLoginUserDetails", token);
  if (token != null && token !== "null") {
    try {
      return jwtDecode(token);
    } catch (err) {
      return null;
    }
  }
  // const { userLoginDetails } = useContext(AppInitializerContext);
  // return userLoginDetails;
};

export const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email.toLowerCase());
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL.split(",")[1]);
    };
  });
};

export const chatFileTypeByFileExtension = (ext) => {
  if (CHAT_PHOTO_EXTENSION.includes(ext.toLowerCase())) {
    return "photo";
  } else if (CHAT_VIDEO_EXTENSION.includes(ext.toLowerCase())) {
    return "video";
  } else if (CHAT_DOCUMENT_EXTENSION.includes(ext.toLowerCase())) {
    return "document";
  } else {
    return "";
  }
};

export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    if (!(blob instanceof Blob)) {
      reject(new Error("Invalid Blob object"));
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      if (reader.result && typeof reader.result === "string") {
        const base64String = reader.result.split(",")[1]; // Extracting base64 data from the result
        resolve(base64String);
      } else {
        reject(new Error("Failed to convert Blob to base64"));
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};
export const getValidExtentions = () => {
  let str = "";
  for (let count = 0; count < commonFileType.length; count++) {
    str = str + "." + commonFileType[count] + ",";
  }
  return str;
};

export const estimateAudioDuration = (audioChunks) => {
  if (!Array.isArray(audioChunks) || audioChunks.length === 0) {
    return { minutes: 0, seconds: 0 };
  }

  const totalSize = audioChunks.reduce((acc, chunk) => acc + chunk.size, 0);

  // Adjust the sample rate according to your audio format
  const SAMPLE_RATE = 44100; // Modify this based on your audio sample rate

  // Estimate duration in seconds
  const durationInSeconds = totalSize / (SAMPLE_RATE * 2); // 2 bytes per sample

  // Calculate minutes and remaining seconds
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  return { minutes, seconds };
};

// get date in 26-12-2023 10:00 AM format
export const getDateInDDMMYYAMPM = (date) => {
  const newDate = new Date(date);

  const padValue = (value) => (value < 10 ? "0" + value : value);

  let sMonth = padValue(newDate.getMonth() + 1);
  let sDay = padValue(newDate.getDate());
  let sYear = newDate.getFullYear();
  let sHour = newDate.getHours();
  let sMinute = padValue(newDate.getMinutes());
  let sAMPM = "AM";

  let iHourCheck = parseInt(sHour);

  if (iHourCheck >= 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  } else if (iHourCheck === 0) {
    sHour = "12";
  }

  sHour = padValue(sHour);

  return `${sDay}-${sMonth}-${sYear} ${sHour}:${sMinute} ${sAMPM}`;
};

// get date in 10:00 AM format
export const getDateInAMPM = (date) => {
  const newDate = new Date(date);
  const padValue = (value) => (value < 10 ? "0" + value : value);

  let sHour = newDate.getHours();
  let sMinute = padValue(newDate.getMinutes());
  let sAMPM = "AM";

  let iHourCheck = parseInt(sHour);

  if (iHourCheck >= 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  } else if (iHourCheck === 0) {
    sHour = "12";
  }

  sHour = padValue(sHour);

  return `${sHour}:${sMinute} ${sAMPM}`;
};

// Check Given Date is Greater than Today
export const dateIsLessThanToday = (date) => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  let givenDate = new Date(date);
  givenDate.setHours(0, 0, 0, 0);

  return givenDate < today ? true : false;
};

//  download file from url start
export const downloadFileFromUrl = async (url, fileName = "") => {
  let fName = "";

  if (fileName === "") {
    const urlName = url.split("/").pop();
    fName = urlName;
  } else {
    fName = fileName;
  }

  const image = await fetch(url);
  const imageBlob = await image.blob();
  const imageURL = URL.createObjectURL(imageBlob);
  const link = document.createElement("a");
  link.href = imageURL;
  link.download = fName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
//  download file from url end

//download file from given its base64 data

export const downloadFileFromBase64 = (base64Data, fileName) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = base64Data;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const copyToClipboard = (textToCopy) => {
  try {
    // navigator.clipboard.writeText(textToCopy);
  } catch (err) {
    console.log(err);
  }
};

export const getFormattedDate = (dateString) => {
  const date = dayjs(dateString);
  const formattedString = date.format("DD-MM-YYYY hh:mm A");
  return formattedString;
  // const dateObject = new Date(dateString);
  // const formattedDateString = new Intl.DateTimeFormat("en-GB", {
  //   day: "numeric",
  //   month: "numeric",
  //   year: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  // }).format(dateObject);

  // return formattedDateString;
};

export const downloadFileFromData = async (
  fileName,
  folderName,
  orgfileName
) => {
  window.open(
    apiEndPoint +
      `/misc/downloadFileAWSViaData/${orgfileName}/${fileName}/${folderName}`,
    "_blank",
    "noopener,noreferrer"
  );
};

// pms related
const ratingLogoMessageMap = new Map([
  [
    1,
    {
      className: "poor",
      message: "Poor",
      icon: <MoodBad />,
    },
  ],
  [
    2,
    {
      className: "average",
      message: "Average",
      icon: <SentimentVeryDissatisfied />,
    },
  ],
  [
    3,
    {
      className: "good",
      message: "Good",
      icon: <SentimentSatisfied />,
    },
  ],
  [
    4,
    {
      className: "very-good",
      message: "Very Good",
      icon: <SentimentSatisfiedAlt />,
    },
  ],
  [
    5,
    {
      className: "excellent",
      message: "Excellent",
      icon: <SentimentVerySatisfied />,
    },
  ],
]);

export const getRatingList = (max) => {
  let ratings = [];
  for (let i = 1; i <= max; i++) {
    ratings.push(i);
  }
  return ratings;
};

export const renderRatingMessageIcon = (rating) => {
  if (!ratingLogoMessageMap.has(rating)) return null;
  const obj = ratingLogoMessageMap.get(rating);
  return (
    <div className={`ratingText rating-${obj.className}`}>
      {obj.icon}
      <span>{obj.message}</span>
    </div>
  );
};

export const isNullOrEmpty = (str) => {
  return !str || str.trim() === "";
};

export const calculateDateRangeByRange = (range) => {
  const now = new Date();
  let startDate, endDate;

  switch (range) {
    case "Today":
      startDate = new Date(now.setHours(0, 0, 0, 0));
      endDate = new Date(now.setHours(23, 59, 59, 999));
      break;
    case "Yesterday":
      startDate = new Date(now.setDate(now.getDate() - 1));
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(now.setHours(23, 59, 59, 999));
      break;
    case "Last 7 Days":
      startDate = new Date(now.setDate(now.getDate() - 7));
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      break;
    case "This Week":
      startDate = new Date(now.setDate(now.getDate() - now.getDay()));
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      break;
    case "Last Week":
      const lastWeekStart = new Date(
        now.setDate(now.getDate() - now.getDay() - 7)
      );
      startDate = new Date(lastWeekStart.setHours(0, 0, 0, 0));
      endDate = new Date(now.setDate(lastWeekStart.getDate() + 6));
      endDate.setHours(23, 59, 59, 999);
      break;
    case "This Month":
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      break;
    case "Last Month":
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(now.getFullYear(), now.getMonth(), 0);
      endDate.setHours(23, 59, 59, 999);
      break;
    case "Last 30 Days":
      startDate = new Date(now.setDate(now.getDate() - 30));
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      break;
    case "All Time":
      startDate = new Date(2024, 11, 1, 0, 0, 0, 0);
      endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      break;
    default:
      return;
  }

  return {
    startDate,
    endDate,
  };
};

export const safeReturn = (debugMode = false) => {
  // toast.info("safe return executing")
  return () => {
    if (debugMode && window.location.host.includes("localhost")) {
      return;
    }
  };
};
