import React, { useContext, useEffect } from "react";
// import DriveService from "../../services/DriveService";
import { DiscFullOutlined } from "@mui/icons-material";
// import PdfAnnotations from "../pdf-editor/pdfAnnotations";
import {
  checkIfUserHasFileAccess,
  updateLastAccessedTime,
} from "../services/DriveService";
import { GetLoginUserDetails } from "./Utils";
import { driveSocket } from "../socket";
import { toast } from "react-toastify";
import { AddTabContext } from "../features/dashboard/view/DashboardPage";

const DocumentEditor = (props) => {
  const userDetails = GetLoginUserDetails();
  const [isTimeUpdated, setIsTimeUpdated] = React.useState(false);
  const [fileExt, setFileExt] = React.useState(null);
  const { updateTab } = useContext(AddTabContext);

  console.log("props.fileId", props);
  console.log("props.userId", props.userId);
  console.log("props.sharedBy", props.sharedBy);
  console.log("props.fileName", props.fileName);
  console.log("props.isFullScreen", props.isFullScreen);

  const [initialPerm, setInitialPerm] = React.useState("N");
  useEffect(() => {
    if (props.tabKey) {
      updateTab(
        {
          data: {
            fileId: props.fileId,
            sharedBy: props.sharedBy,
            fullScreen: props.fullScreen,
            fileName: props.fileName,
          },
        },
        props.tabKey
      );
    }

    console.log("opened file details", props);
    if (props && props.sharedBy) {
      checkIfUserHasFileAccess(props.fileId, userDetails.userId).then(
        (response) => {
          console.log("checkIfUserHasFileAccess", response.data);
          setInitialPerm(response.data);
        }
      );
    } else {
      setInitialPerm("E");
    }
    driveSocket.on(
      "updateUserFilePermissionAck",
      ({ fileId, permission, userName, fileName }) => {
        console.log(
          "updateUserFilePermissionAck-->>",
          fileId,
          permission,
          userName,
          fileName
        );
        toast.info(
          `${userName} has updated your access to this file named ${fileName}`
        );
        if (fileId === props.fileId) {
          setInitialPerm(permission);
        }
      }
    );
  }, []);

  React.useEffect(() => {
    updateLastAccessedTime(props.fileId, props.userId, props.sharedBy).then(
      (response) => {
        if (response.data && response.data.returnValue === "1") {
          console.log(props);
          setIsTimeUpdated(true);
          const lastDotIndex = props.fileName.lastIndexOf(".");
          setFileExt(props.fileName.slice(lastDotIndex + 1));
        } else {
          console.log("isTimeUpdated Error");
        }
      }
    );
  }, []);

  return (
    <>
      {isTimeUpdated && initialPerm !== "N" && initialPerm === "V" && (
        <iframe
          title="collabora"
          style={{
            height: "89vh",
            marginTop: "5px",
          }}
          name="embed_office"
          src={props.officeUrl}
          width="100%"
          height="100vh"
          frameBorder="0"
        ></iframe>
      )}
      {isTimeUpdated && initialPerm !== "N" && initialPerm === "E" && (
        <iframe
          title="collabora"
          style={{
            height: "89vh",
            marginTop: "5px",
          }}
          name="embed_office"
          src={props.officeUrl}
          width="100%"
          height="100vh"
          frameBorder="0"
        ></iframe>
      )}
      {initialPerm !== "N" && initialPerm === "R" && (
        <div className="cuContainArea overflowHidden">
          <div className="TableContainer">
            <div
              className="e-attendLib"
              style={{
                display: "flex",
                color: "white",
                fontSize: "25px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Your access to file {props.fileName} has been revoked by the owner
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentEditor;
