import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class MiscService {
  getMassaludWebsiteStatus() {
    return http.put(`misc/getMassaludWebsiteStatus`, {
      headers: authHeader(),
    });
  }
  getMassaludWebsiteSnapshotData(reqDto) {
    return http.put(`misc/getMassaludWebsiteSnapshotData`, reqDto, {
      headers: authHeader(),
    });
  }
}
const miscService = new MiscService();
export default miscService;
