import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { Plus } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import TaskService from "../../../../services/TaskService";
import { CustomModal } from "../../../task/view/component/Modals/CustomModal";

export const UnitPaymentMethods = ({
  closeModal,
  selectedMethodId,
  setSelectedMethodId,
  vendorUserId,
  vendorUnitId,
  vendorPaymentMethods,
}) => {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const [checkedPaymentMethod, setCheckedPaymentMethod] = useState("");
  const [userPaymentBankList, setUserPaymentBankList] = useState([]);
  const [userPaymentUpiList, setUserPaymentUpiList] = useState([]);
  const [userPaymentChequeList, setUserPaymentChequeList] = useState([]);
  const { reset, register, handleSubmit } = useForm();
  const [bankingCheck1, setBankingCheck1] = useState(true);
  const [bankingCheck2, setBankingCheck2] = useState(false);
  const [bankingCheck3, setBankingCheck3] = useState(false);

  const [showAddBankAccount, setAddBankAccount] = useState(false);

  const resetAccountDetailsForm = () => {
    reset({
      paymentMethodOfUserId: "",
      paymentMode: "",
      accountNo: "",
      ifscCd: "",
      bankName: "",
      bankBranch: "",
      accountHolderName: "",
      payeeNameInCheque: "",
      swiftCd: "",
      rtgsCd: "",
      sortCd: "",
      upiId: "",
    });
  };

  const handleSubmitAddChequeDetails = (data) => {
    data.paymentMode = "C";
    data.paymentMethodOfUserId = vendorUserId;

    if (data.payeeNameInCheque === "") {
      toast.error(t("please_enter_payee_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      userPaymentChequeList.find(
        ({ payeeNameInCheque }) =>
          payeeNameInCheque.trim().toLowerCase() ===
          data.payeeNameInCheque.trim().toLowerCase()
      )
    ) {
      toast.error(t("payee_name_already_added"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!vendorUnitId) {
      toast.error(t("unit_id_is_not_provided"));
      return;
    }

    const temp = {
      paymentMethodOfUserId: vendorUserId,
      paymentMode: "C",
      accountNo: "",
      ifscCd: "",
      bankName: "",
      bankBranch: " ",
      accountHolderName: "",
      payeeNameInCheque: data.payeeNameInCheque,
      swiftCd: "",
      rtgsCd: "",
      sortCd: "",
      upiId: "",
      unitId: vendorUnitId,
    };
    console.log("data =", temp);
    // return;

    TaskService.addPaymentMethodOfUnit(vendorUserId, temp).then((response) => {
      if (response.data) {
        if (response.data > 0) {
          toast.success(t("cheque_details_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          data.id = response.data;
          let tmpList = userPaymentChequeList;
          tmpList = [...tmpList, ...[data]];
          setUserPaymentChequeList(tmpList);
          reset({ payeeNameInCheque: "" });
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };

  const handleSubmitAddBankDetails = (data) => {
    console.log("data", data);
    data.paymentMode = "B";
    data.paymentMethodOfUserId = vendorUserId;

    if (data.accountNo === "") {
      toast.error(t("please_enter_account_number"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (bankingCheck1 && data.ifscCd === "") {
      toast.error(t("please_enter_the_ifsc_code"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (data.bankName === "") {
      toast.error(t("please_enter_the_bank_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (data.bankBranch === "") {
      toast.error(t("please_enter_branch_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (data.accountHolderName === "") {
      toast.error(t("please_enter_account_holder_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    console.log("swift cd", bankingCheck2);
    if (bankingCheck2 && data.swiftCd === "") {
      toast.error(t("please_enter_swift_code"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // if (data.rtgsCd === "") {
    //   toast.error(`Please Enter RTGS Code`, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }

    if (bankingCheck3 && data.sortCd === "") {
      toast.error(t("please_enter_sort_code"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      userPaymentBankList.find(
        ({ accountNo }) => accountNo.trim() === data.accountNo.trim()
      )
    ) {
      toast.error(t("bank_account_already_added"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!bankingCheck1) {
      data.ifscCd = "";
    }
    if (!bankingCheck2) {
      data.swiftCd = "";
    }
    if (!bankingCheck3) {
      data.sortCd = "";
    }

    if (!vendorUnitId) {
      toast.error(t("unit_id_is_not_provided"));
      return;
    }

    const reqDto = {
      ...data,
      paymentMethodOfUserId: vendorUserId,
      paymentMode: "B",
      payeeNameInCheque: "",
      upiId: "",
      unitId: vendorUnitId,
    };
    console.log(reqDto);

    // return;

    TaskService.addPaymentMethodOfUnit(vendorUserId, reqDto).then(
      (response) => {
        if (response.data) {
          if (response.data > 0) {
            toast.success(t("bank_details_added_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            data.id = response.data;
            let tmpList = userPaymentBankList;
            tmpList = [...tmpList, ...[data]];
            setUserPaymentBankList(tmpList);
            resetAccountDetailsForm();
            setAddBankAccount(false);
          } else {
            toast.error(t("something_went_wrong"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      }
    );
  };

  const handleSubmitAddUpiDetails = (data) => {
    if (data.upiId === "") {
      toast.error(t("please_enter_upi_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      userPaymentUpiList.find(({ upiId }) =>
        upiId !== ""
          ? upiId.trim().toLowerCase() === data.upiId.trim().toLowerCase()
          : false
      )
    ) {
      toast.error(t("upi_id_already_added"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!vendorUnitId) {
      toast.error(t("unit_id_is_not_provided"));
      return;
    }

    const reqDto = {
      paymentMethodOfUserId: vendorUserId,
      paymentMode: "U",
      accountNo: "",
      ifscCd: "",
      bankName: "",
      bankBranch: "",
      accountHolderName: "",
      payeeNameInCheque: "",
      swiftCd: "",
      rtgsCd: "",
      sortCd: "",
      upiId: data.upiId,
      unitId: vendorUnitId,
    };

    console.log("request value=", reqDto);

    // return;

    TaskService.addPaymentMethodOfUnit(vendorUserId, reqDto).then(
      (response) => {
        if (response.data) {
          if (response.data > 0) {
            toast.success(t("upi_id_added_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            // data.id = response.data;
            reqDto.id = response.data;
            let tmpList = userPaymentUpiList;
            tmpList = [...tmpList, ...[reqDto]];
            console.log("********* templist =", tmpList);
            setUserPaymentUpiList(tmpList);
            reset({ upiId: "" });
          } else {
            toast.error(t("something_went_wrong"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      }
    );
  };

  function constHandlePaymentData(data) {
    let tmpBankDtl = [];
    let tmpUpiDtl = [];
    let tmpChqDtl = [];

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        if (element.paymentMode === "B") {
          tmpBankDtl.push(element);
        }
        if (element.paymentMode === "U") {
          tmpUpiDtl.push(element);
        }
        if (element.paymentMode === "C") {
          tmpChqDtl.push(element);
        }

        if (selectedMethodId === 0) {
          if (element.deafultMethod === "Y") {
            setSelectedMethodId(element.id);
            setCheckedPaymentMethod(element.paymentMode);
          }
        } else {
          if (element.id === selectedMethodId) {
            setCheckedPaymentMethod(element.paymentMode);
          }
        }
      }
    }

    setUserPaymentBankList(tmpBankDtl);
    setUserPaymentUpiList(tmpUpiDtl);
    setUserPaymentChequeList(tmpChqDtl);
  }

  const fetchUserPaymentMethodData = () => {
    TaskService.getPaymentMethodsOfUnit(vendorUnitId).then((response) => {
      if (response.data) {
        // console.log("getPaymentMethodsOfUser", response.data);
        constHandlePaymentData(response.data);
      }
    });
  };

  const handleBankingCheckBox1 = (e) => {
    console.log("bankingCheck1", bankingCheck1);
    console.log("bankingCheck2", bankingCheck2);
    console.log("bankingCheck3", bankingCheck3);
    if (bankingCheck1) {
      console.log("in if 1");

      if (bankingCheck2 || bankingCheck3) {
        console.log(" changing 1");

        setBankingCheck1(e.target.checked);
      } else {
        toast.error(t("select_atleast_one_code"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      console.log("in else 1");

      setBankingCheck1(e.target.checked);
    }
  };
  const handleBankingCheckBox2 = (e) => {
    if (bankingCheck2) {
      console.log("in if 2");

      if (bankingCheck1 || bankingCheck3) {
        console.log(" changing 2");

        setBankingCheck2(e.target.checked);
      } else {
        toast.error(t("select_atleast_one_code"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      console.log("in else 2");
      setBankingCheck2(e.target.checked);
    }
  };
  const handleBankingCheckBox3 = (e) => {
    if (bankingCheck3) {
      console.log("in if 3");
      if (bankingCheck2 || bankingCheck1) {
        console.log(" changing 3");

        setBankingCheck3(e.target.checked);
      } else {
        toast.error(t("select_atleast_one_code"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      console.log("in else 3");
      setBankingCheck3(e.target.checked);
    }
  };

  useEffect(() => {
    if (!vendorPaymentMethods) {
      fetchUserPaymentMethodData();
    } else {
      constHandlePaymentData(vendorPaymentMethods);
    }
  }, []);

  return (
    <>
      <CustomModal
        modalTitle={t("select_payment_mode")}
        style={{ zIndex: 10001 }}
        closeModal={() => {
          closeModal(false);
        }}
        className={""}
      >
        <div className="modBody">
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="setActivity">{t("banking_method")}</InputLabel>
                <Select
                  className="modelSelectFild"
                  label={t("banking_method")}
                  variant="outlined"
                  labelId="setActivity"
                  id="activity-select"
                  value={checkedPaymentMethod}
                  onChange={(e) => setCheckedPaymentMethod(e.target.value)}
                >
                  <MenuItem value={""}>--{t("select_activity")}--</MenuItem>
                  <MenuItem value={"B"}>{t("bank_account")}</MenuItem>
                  <MenuItem value={"U"}>{t("upi")}</MenuItem>
                  <MenuItem value={"C"}>{t("cheque")}</MenuItem>

                  {/* <MenuItem value={"Appointment"}>Appointment</MenuItem>
                      <MenuItem value={"Board Meeting"}>Board Meeting</MenuItem>
                      <MenuItem value={"Budget Requisition"}>
                        Budget Requisition
                      </MenuItem> */}
                </Select>
              </FormControl>
            </div>
          </div>

          {checkedPaymentMethod === "B" && (
            <>
              <div class="tskElemHeding mt10">{t("account_details")}</div>

              {userPaymentBankList.map((item) => {
                return (
                  <>
                    <div
                      className={`${
                        selectedMethodId === item.id
                          ? "selectedBankPayment"
                          : ""
                      } "bankingAcDtlCrd"`}
                      onClick={() => {
                        setSelectedMethodId(item.id);
                      }}
                    >
                      {/* <div className="removeBnkDtl">
              <Trash />
            </div> */}
                      <ul>
                        {console.log("item name", item)}
                        <li>
                          <span>{t("account_holder_name")}: </span>
                          {item.accountHolderName}
                        </li>
                        <li>
                          <span>{t("bank_name")}: </span>
                          {item.bankName}
                        </li>
                        <li>
                          <span>{t("bank_branch")}: </span>
                          {item.bankBranch}
                        </li>
                        <li>
                          <span>{t("account_no")}: </span>
                          {item.accountNo}
                        </li>
                        {item.ifscCd !== "" && (
                          <>
                            <li>
                              <span>{t("ifsc_code")}: </span>
                              {item.ifscCd}
                            </li>
                          </>
                        )}
                        {item.swiftCd !== "" && (
                          <>
                            <li>
                              <span>{t("swift_code")}: </span>
                              {item.swiftCd}
                            </li>
                          </>
                        )}
                        {item.sortCd !== "" && (
                          <>
                            <li>
                              <span>{t("sort_code")}: </span>
                              {item.sortCd}
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </>
                );
              })}
            </>
          )}

          {checkedPaymentMethod === "B" && (
            <div className="formBtnElement ">
              <Button
                startIcon={<Plus />}
                className="comBtnOutline"
                onClick={() => {
                  resetAccountDetailsForm();
                  setAddBankAccount(true);
                }}
              >
                {t("add_new_bank_account")}
              </Button>
            </div>
          )}

          {checkedPaymentMethod === "U" && (
            <>
              <div class="tskElemHeding mt10">{t("payee_upi_ids")}</div>

              <div className="formElement">
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup column"
                    labelId="setPayeeUpiId"
                    name="setPayeeUpiId"
                    value={selectedMethodId}
                    onChange={(e) => {
                      console.log(
                        "************* event value =",
                        typeof e.target.value
                      );
                      setSelectedMethodId(parseInt(e.target.value));
                    }}
                  >
                    {userPaymentUpiList.map((item) => {
                      return (
                        <FormControlLabel
                          className="formRadioField"
                          value={item.id}
                          control={<Radio />}
                          label={item.upiId}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
            </>
          )}

          {checkedPaymentMethod === "U" && (
            <>
              <div className="formElementGroupWthBtn mt10">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label={t("add_new_upi_id")}
                      variant="outlined"
                      multiline
                      {...register("upiId")}
                    />
                  </FormControl>
                </div>
                <Button
                  startIcon={<Add />}
                  className="addNewElmBtn"
                  onClick={handleSubmit(handleSubmitAddUpiDetails)}
                >
                  {t("add")}
                </Button>
              </div>
            </>
          )}

          {checkedPaymentMethod === "C" && (
            <>
              <div class="tskElemHeding mt10">{t("payee_name_for_cheque")}</div>

              <div className="formElement">
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup column"
                    labelId="setPayeeName"
                    name="setPayeeName"
                    value={selectedMethodId}
                    onChange={(e) => {
                      console.log(
                        "************* event value =",
                        typeof e.target.value
                      );
                      setSelectedMethodId(parseInt(e.target.value));
                    }}
                  >
                    {userPaymentChequeList.map((item) => {
                      return (
                        <FormControlLabel
                          className="formRadioField"
                          value={item.id}
                          control={<Radio />}
                          label={item.payeeNameInCheque}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
            </>
          )}

          {checkedPaymentMethod === "C" && (
            <>
              <div className="formElementGroupWthBtn mt10">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label={t("add_new_payee_name")}
                      variant="outlined"
                      multiline
                      {...register("payeeNameInCheque")}
                    />
                  </FormControl>
                </div>
                <Button
                  startIcon={<Add />}
                  className="addNewElmBtn"
                  onClick={handleSubmit(handleSubmitAddChequeDetails)}
                >
                  {t("add")}
                </Button>
              </div>
            </>
          )}
        </div>
        <div className="modActionBtnGrp actionsCreateOrg">
          <Button
            className="dfultDarkSecondaryBtn"
            onClick={() => closeModal(false)}
          >
            {t("close")}
          </Button>
          <Button
            className="dfultPrimaryBtn"
            onClick={() => {
              if (selectedMethodId === 0) {
                toast.error(t("please_select_payment_method"));
                return;
              }
              closeModal();
            }}
          >
            {t("save")}
          </Button>
        </div>
      </CustomModal>

      {showAddBankAccount && (
        <CustomModal
          modalTitle={t("add_banking_details")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setAddBankAccount(false);
          }}
          className={""}
        >
          <div className="modBody">
            <div className="elementFormContainer">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("accounts_no")}
                    variant="outlined"
                    required
                    className="modelTextFild"
                    multiline
                    {...register("accountNo")}
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("bank_name")}
                    variant="outlined"
                    required
                    className="modelTextFild"
                    multiline
                    {...register("bankName")}
                  />
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("branch_name")}
                    variant="outlined"
                    required
                    className="modelTextFild"
                    multiline
                    {...register("bankBranch")}
                  />
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("account_holder_name")}
                    variant="outlined"
                    required
                    className="modelTextFild"
                    multiline
                    {...register("accountHolderName")}
                  />
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <FormGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="setTasktype"
                    // value={taskType}
                    // onChange={selectTaskType}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bankingCheck1}
                          onChange={(e) => {
                            handleBankingCheckBox1(e);
                          }}
                        />
                      }
                      label={t("ifsc_code")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bankingCheck2}
                          onChange={(e) => {
                            handleBankingCheckBox2(e);
                          }}
                        />
                      }
                      label={t("swift_code")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bankingCheck3}
                          onChange={(e) => {
                            handleBankingCheckBox3(e);
                          }}
                        />
                      }
                      label={t("sort_code")}
                    />
                  </FormGroup>
                </FormControl>
              </div>

              {bankingCheck1 && (
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      label={t("ifsc_code")}
                      variant="outlined"
                      required
                      className="modelTextFild"
                      multiline
                      {...register("ifscCd")}
                    />
                  </FormControl>
                </div>
              )}

              {bankingCheck2 && (
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      label={t("swift_code")}
                      variant="outlined"
                      required
                      className="modelTextFild"
                      multiline
                      {...register("swiftCd")}
                    />
                  </FormControl>
                </div>
              )}

              {bankingCheck3 && (
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      label={t("sort_code")}
                      variant="outlined"
                      required
                      className="modelTextFild"
                      multiline
                      {...register("sortCd")}
                    />
                  </FormControl>
                </div>
              )}
            </div>
          </div>
          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => setAddBankAccount(false)}
            >
              {t("cancel")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={handleSubmit(handleSubmitAddBankDetails)}
            >
              {t("save")}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};
