import {
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import isAssignedByMe from "../../../taskUtil";
import { XLg } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { toast } from "react-toastify";

function RightSideCloseTask({ selectedTasks, handleClose, refreshData }) {
  const [cancellationReasonList, setCancellationReasonList] = useState([]);
  const loginUserDetail = GetLoginUserDetails();

  const cancelTask = async () => {
    const res = await Promise.all(
      selectedTasks.map((task, index) => {
        return new Promise((resolve, reject) => {
          DashboardServices.cancelTask(
            task.taskId,
            loginUserDetail.userId,
            cancellationReasonList[index]
          ).then((response) => {
            if (response.data && response.data.returnValue === "1") {
              resolve("ok");
            } else {
              toast.error(`Could Not Cancel ${task.taskName}`);
              resolve(null);
            }
          });
        });
      })
    );
    let isOkay = true;
    for (let i = 0; i < res.length; i++) {
      if (res[i] === null) {
        isOkay = false;
        break;
      }
    }
    if (isOkay) {
      toast.success("All Selected Task Closed Successfully");
    }

    refreshData();
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskTitleContrct">
              <div className="taskTitleNM">{`Reject ${selectedTasks.length} Tasks `}</div>
            </div>
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton aria-label="close" onClick={handleClose}>
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>

          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {selectedTasks &&
                  selectedTasks.length > 0 &&
                  selectedTasks.map((task, index) => {
                    return (
                      <>
                        <div className="multipleUpdateTaskCard">
                          <div className="taskTitleContrct">
                            <div className="taskHeadingNM">{task.taskName}</div>
                          </div>
                          <div className="commonUpdate">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  label="Cancellation Reason*"
                                  variant="outlined"
                                  className="descptionTextFild"
                                  multiline
                                  rows={3}
                                  maxRows={7}
                                  value={cancellationReasonList[index]}
                                  // value={reason}
                                  onChange={(e) => {
                                    const temp = [...cancellationReasonList];
                                    temp[index] = e.target.value;
                                    setCancellationReasonList(temp);
                                  }}
                                />
                              </FormControl>
                            </div>
                            {/* <div className="taskElementGrp">
                                <div className="tskElemHeding">Created By</div>
                                <div className="tskElemntInfo">
                                  {task && isAssignedByMe(task.createdByUserId)
                                    ? "Me"
                                    : task.createdByUserName}
                                </div>
                              </div> */}
                            <div className="tskCretdByElmnt">
                              <span className="assuTag">Created By :</span>
                              <span className="assuName">
                                {task && isAssignedByMe(task.createdByUserId)
                                  ? "Me"
                                  : task.createdByUserName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  cancelTask();
                  handleClose();
                }}
              >
                Concern
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={(e) => handleClose()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightSideCloseTask;
