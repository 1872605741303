import { Add, East } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import {
  getAllDepartment,
  getDesignationsOfOrganization,
  addPosition,
  getPositionsOfOrganization,
  deactivatePosition,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { GetLoginUserDetails } from "../../../../utils/Utils";

export default function CreatePosition({
  handelCloseCrtPosition,
  selectedPosition,
  refreshPositionData,
  setSelectedPosition,
}) {
  const userDetails = GetLoginUserDetails();

  const [loading, setLoading] = useState(false);

  // const [selectedPosition , setselectedPosition] = useState(selectedPosition);

  // const [uploadData , setUploadData] = useState([])
  // committee-controller

  const [positionsData, setPositionsData] = useState([]);

  console.log("selectedPosition", selectedPosition);

  // const [isBTNclick, setBTNclick] = useState(false);

  // const handleBtnSelect = () => {
  //   setBTNclick(!isBTNclick);
  // };

  // const [isSiblingBTNclick, setSiblingBTNclick] = useState(false);

  // const handleSiblingBtnSelect = () => {
  //   setSiblingBTNclick(!isSiblingBTNclick);
  // };

  // const [isPatentBTNclick, setParentBTNclick] = useState(false);

  // const handleParentBtnSelect = () => {
  //   setParentBTNclick(!isPatentBTNclick);
  // };
  const [selectedOption, setSelectedOption] = useState("");
  const [lastPosition, setLastPosition] = useState(null);

  const [allPositions, setAllPosition] = useState([]);

  useEffect(() => {
    fetchPositionData();
  }, [userDetails.orgId]);

  let uploadData;

  const fetchPositionData = () => {
    setLoading(true);
    getPositionsOfOrganization(userDetails.orgId)
      .then((response) => {
        console.log("getPosition Of Organisation", response.data);
        setPositionsData(response.data);
        setAllPosition(response.data);

        const positions = response.data;

        if (positions.length > 0) {
          const lastPosition = positions[positions.length - 1];
          setLastPosition(lastPosition);
        }

        console.log("positions", positions);
        console.log("selectedPosition", selectedPosition);
        console.log("uploadData", uploadData);

        const matchedPosition = positions.find(
          (position) => position.positionId === uploadData
        );

        console.log("matchedPosition", matchedPosition);

        // if (!selectedPosition) {
        //   setselectedPosition(lastPosition);
        // }

        // if (selectedPosition === null) {
        //   setselectedPosition(matchedPosition);
        // }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching positions", error);
        setLoading(false);
      });
  };

  console.log("selectedPosition", selectedPosition);

  const handleBtnSelect = () => {
    setSelectedOption("C");
    if (lastPosition) {
      console.log("Last Position Data:", lastPosition);
    } else {
      console.log("No position data available");
    }
  };

  const handleSiblingBtnSelect = () => {
    setSelectedOption("S");
  };

  const handleParentBtnSelect = () => {
    setSelectedOption("P");
  };

  /////////////////////////////////////// department //////////////////////////////////////////
  const [departmentData, setDepartmentData] = useState([]);
  const [selectDepartment, setdselectDepartment] = useState("");

  useEffect(() => {
    getAllDepartment(userDetails.orgId)
      .then((response) => {
        console.log("getAllDepartment", response.data);
        setDepartmentData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      });
  }, [userDetails.orgId]);

  const handleChangeselectDepartment = (event) => {
    setdselectDepartment(event.target.value);
  };

  ///////////////////////////////////////////designation//////////////////////////////////////

  const [designationData, setDesignationData] = useState([]);
  const [selectDesignarion, setdSelectDesignarion] = useState("");

  useEffect(() => {
    getDesignationsOfOrganization(userDetails.orgId)
      .then((response) => {
        console.log("getDesignationsOfOrganization", response.data);
        setDesignationData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      });
  }, [userDetails.orgId]);

  const handleChangeselectDesignarion = (event) => {
    setdSelectDesignarion(event.target.value);
  };

  ////////////////////////////////////////position/////////////////////////////////////////////

  const [showNewPosition, setNewPosition] = useState("");

  const handleInputNewPosition = (event) => {
    setNewPosition(event.target.value);
  };

  ///////////////////////////////////////////////////API///////////////////////////////////////

  const finalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");

    let currentPositionId = 0;

    if (selectedOption === "C") {
      currentPositionId = selectedPosition
        ? selectedPosition.positionId
        : lastPosition
        ? lastPosition.positionId
        : 0;
    }

    let currentPositionParId = 0;

    if (selectedPosition) {
      currentPositionParId = selectedPosition
        ? selectedPosition.positionParId
        : lastPosition
        ? lastPosition.positionParId
        : 0;
    }

    if (showNewPosition.trim() === "") {
      toast.error("Please Fill The Position", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (positionsData.length >= 1 && selectedOption.trim() === "") {
      toast.error("Please Select Between Child Or Sibling Option", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      currentPositionId: selectedPosition?.positionId,
      position: showNewPosition,
      functionId: selectDepartment,
      designationId: selectDesignarion,
      currentPositionParId: currentPositionParId,
      additionType: selectedOption,
    };

    console.log("reqDto", reqDto);

    addPosition(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log("addPosition", response.data);
        uploadData = response.data.positionId;
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
        refreshPositionData();
        setSelectedPosition(response.data);
        setNewPosition("");
        setdselectDepartment("");
        setdSelectDesignarion("");
        setSelectedOption("");
        // setselectedPosition(null);
        fetchPositionData();
        // handelCloseCrtPosition();
      }
    );
  };

  // const renderPosition = () => {
  //   const positionsToDisplay = selectedPosition
  //     ? allPositions.filter((position) => position.positionId <= selectedPosition.positionId)
  //     : allPositions;

  //   return (
  //     <div className="rotposNm">
  //       {positionsToDisplay.map((position, index) => (
  //         <div
  //           key={index}
  //           className={`nextPosition${position.positionId === (selectedPosition?.positionId || 0) ? ' activePos' : ''}`}
  //         >
  //           <span className="posIcon">
  //             <East />
  //           </span>
  //           <span className="posName">
  //             {position.position}
  //           </span>
  //         </div>
  //       ))}
  //       <div className={`nextPosition activePos${showNewPosition ? '' : ' emptyNewPos'}`}>
  //         <span className="posIcon">
  //           <East />
  //         </span>
  //         <span className="posName">
  //           {showNewPosition || "New Position"}
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderPosition = () => {
  //   const positionsToDisplay = [];
  //   let currentPos = selectedPosition;

  //   // Traverse the hierarchy and add positions to display
  //   while (currentPos) {
  //     positionsToDisplay.unshift(currentPos); // Add position to the beginning of the array
  //     currentPos = allPositions.find((pos) => pos.positionId === currentPos.positionParId);
  //   }

  //   return (
  //     <div className="rotposNm">
  //       {positionsToDisplay.map((position, index) => (
  //         <div
  //           key={index}
  //           className={`nextPosition${position.positionId === (selectedPosition?.positionId || 0) ? ' activePos' : ''}`}
  //         >
  //           <span className="posIcon">
  //             <East />
  //           </span>
  //           <span className="posName">
  //             {position.position}
  //           </span>
  //         </div>
  //       ))}
  //       <div className={`nextPosition activePos${showNewPosition ? '' : ' emptyNewPos'}`}>
  //         <span className="posIcon">
  //           <East />
  //         </span>
  //         <span className="posName">
  //           {showNewPosition || "New Position"}
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };

  const renderPosition = () => {
    const positionsToDisplay = [];
    let currentPos = selectedPosition;

    // Traverse the hierarchy and add positions to display
    while (currentPos) {
      positionsToDisplay.unshift(currentPos); // Add position to the beginning of the array
      currentPos = allPositions.find(
        (pos) => pos.positionId === currentPos.positionParId
      );
    }

    return (
      <div className="rotposNm">
        {positionsToDisplay.map((position, index) => (
          <div
            key={index}
            className={`nextPosition${
              position.positionId === (selectedPosition?.positionId || 0)
                ? " activePos"
                : ""
            }`}
          >
            <span className="posIcon">
              <East />
            </span>
            <span className="posName">{position.position}</span>
          </div>
        ))}
        <div
          className={`nextPosition activePos${
            showNewPosition ? "" : " emptyNewPos"
          }`}
        >
          <span className="posIcon">
            <East />
          </span>
          <span className="posName">{showNewPosition || "New Position"}</span>
        </div>
      </div>
    );
  };

  // const renderString = useMemo(()=>{

  //   return renderPosition()
  // },[selectedPosition])

  // const hasChildren = positionsData.some(position => position.positionParId === selectedPosition.positionId);

  const hasChildren = selectedPosition
    ? positionsData.some(
        (position) => position.positionParId === selectedPosition.positionId
      )
    : false;

  const deletePositionData = () => {
    console.log("selectedPosition.positionId", selectedPosition.positionId);
    // deactivatePosition()

    const deleteId = selectedPosition.positionId;

    deactivatePosition(deleteId, userDetails.userId).then((response) => {
      console.log("data deleted", response.data);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        refreshPositionData();
        fetchPositionData();
      }
    });

    handelCloseCrtPosition();
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">Add Position</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCrtPosition()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            {/* <div className="defRotPos">Define root Position</div> */}

            {/* {positionsData.length === 0 && (
              <div className="defRotPos">Define root Position</div>
            )} */}

            <div className="alredRotPos">
              {/* <div className="rotposNm">
              <div className="firstposition">
                Position: <span className="posName">CEO</span>
              </div>
              <div className="nextPosition">
                <span className="posIcon">
                  <East />
                </span>
                <span className="posName">Vice President</span>
              </div>
              <div className="nextPosition">
                <span className="posIcon">
                  <East />
                </span>
                <span className="posName">Senior Manager</span>
              </div>
              <div className="nextPosition activePos">
                <span className="posIcon">
                  <East />
                </span>
                <span className="posName">
                  {showNewPosition ? (
                    <span>{showNewPosition}</span>
                  ) : (
                    <span className="emptyNewPos">New Position</span>
                  )}
                </span>
              </div>
            </div> */}
              {renderPosition()}
              {/* {renderString} */}
              <div className="crtChildBtnGrp taskVDContBtn">
                {positionsData.length >= 1 && (
                  <Tooltip title="Add Child" arrow>
                    <Button
                      startIcon={<Add />}
                      // variant={isBTNclick ? "contained" : "outlined"}
                      // onClick={handleBtnSelect}
                      variant={
                        selectedOption === "C" ? "contained" : "outlined"
                      }
                      onClick={handleBtnSelect}
                    >
                      Child
                    </Button>
                  </Tooltip>
                )}

                {positionsData.length >= 1 && (
                  <Tooltip title="Add Siblings" arrow>
                    <Button
                      startIcon={<Add />}
                      // variant={isSiblingBTNclick ? "contained" : "outlined"}  onClick={handleSiblingBtnSelect}
                      variant={
                        selectedOption === "S" ? "contained" : "outlined"
                      }
                      onClick={handleSiblingBtnSelect}
                    >
                      Siblings
                    </Button>
                  </Tooltip>
                )}

                {selectedPosition?.positionParId === 0 && (
                  <Tooltip title="Add Parents" arrow>
                    <Button
                      startIcon={<Add />}
                      // variant={isPatentBTNclick ? "contained" : "outlined"}  onClick={handleParentBtnSelect}
                      variant={
                        selectedOption === "P" ? "contained" : "outlined"
                      }
                      onClick={handleParentBtnSelect}
                    >
                      Parents
                    </Button>
                  </Tooltip>
                )}

                {selectedPosition && !hasChildren && (
                  <Tooltip title="Remove Position" arrow>
                    <Button
                      startIcon={<Trash />}
                      variant="outlined"
                      onClick={() => deletePositionData()}
                    >
                      Position
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="select_depart">Select Department</InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="select_depart"
                  id="slect-SelectDepartment"
                  value={selectDepartment}
                  label="Select Department"
                  onChange={handleChangeselectDepartment}
                >
                  {departmentData.map((departmentData) => (
                    <MenuItem
                      key={departmentData.functionId}
                      value={departmentData.functionId}
                    >
                      {departmentData.functionName}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value={"account"}>Account</MenuItem>
                <MenuItem value={"Admin"}> Administration </MenuItem>
                <MenuItem value={"corporate"}> Corporate </MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="select_designation">
                  Select Designation
                </InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="select_designation"
                  id="slectdesignation"
                  value={selectDesignarion}
                  label="Select Department"
                  onChange={handleChangeselectDesignarion}
                >
                  {designationData.map((designationData) => (
                    <MenuItem
                      key={designationData.designationId}
                      value={designationData.designationId}
                    >
                      {designationData.designationName}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value={"bod"}>Board of Directors</MenuItem>
                <MenuItem value={"cbc"}> Chair of Board committee </MenuItem>
                <MenuItem value={"mbc"}> Member of Board committee </MenuItem>
                <MenuItem value={"md"}> Managing Director</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label="Enter Position*"
                  variant="outlined"
                  className="formTextFieldArea"
                  value={showNewPosition}
                  onChange={handleInputNewPosition}
                />
              </FormControl>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={() => finalSubmit()}>
              Save
            </Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={() => finalSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>Submit</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseCrtPosition()}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
