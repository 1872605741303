import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const AudioModal = ({ audioUrl, closeModal }) => {
  const { t, i18n } = useTranslation();
  return (
    <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
      <div className="addMoreAgendaModel">
        <div className="modelTitle">{t("audio_message")}</div>
        <div className="modActionBtnGrp">
          <audio controls>
            <source src={`${audioUrl.current}`} />
          </audio>
        </div>

        <div className="modActionBtnGrp">
          <Button
            className="dfultPrimaryBtn"
            onClick={() => {
              closeModal();
            }}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Box>
  );
};
