import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Filler,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { getDashboardDataOfItem } from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
// import faker from "faker";

export default function CardItems({
  handelOpenAddItems,
  handelOpenViewItems,
  chartDataOfItem,
  menuModuleList,
  userMenuList,
}) {
  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();
  // const userDetails = jwtDecode( SecureIndexedDB.getItem("token"));

  // const [chartDataOfItem, setChartDataOfItem] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setChartDataOfItem(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Title,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );
  // const pieChartData = {
  //   labels: ["Services", "Goods"],
  //   datasets: [
  //     {
  //       label: "#Items",
  //       data: [chartDataOfItem.serviceItems, chartDataOfItem.goodsItem],
  //       backgroundColor: ["#FFEAA7", "#F39C12"],
  //       hoverBackgroundColor: ["#FFEAA7", "#F39C12"],
  //       borderWidth: 0,
  //       type: "doughnut",
  //     },
  //   ],
  // };

  const pieChartlabelData = chartDataOfItem.avgInvStk || [];
  const piechartlabels = pieChartlabelData.map((entry) => entry.itemCategory);
  console.log("piechartlabels", piechartlabels);

  // const pieChartData = {
  //   labels: ["Services", "Goods"],
  //   datasets: [
  //     {
  //       label: "#Items",
  //       // data: (chartDataOfItem.serviceItems === 0 && chartDataOfItem.goodsItem === 0) ? [1] : [chartDataOfItem.serviceItems, chartDataOfItem.goodsItem],
  //       data: [Math.max(1, chartDataOfItem.serviceItems), Math.max(1, chartDataOfItem.goodsItem)],
  //       backgroundColor: (chartDataOfItem.serviceItems === 0 && chartDataOfItem.goodsItem === 0) ? ["#8A2BE2"] : ["#FFEAA7", "#F39C12"],
  //       hoverBackgroundColor: (chartDataOfItem.serviceItems === 0 && chartDataOfItem.goodsItem === 0) ? ["#8A2BE2"] : ["#FFEAA7", "#F39C12"],
  //       borderWidth: 0,
  //       type: "doughnut",
  //     },
  //   ],
  // };

  const hasServiceData = chartDataOfItem.serviceItems > 0;
  const hasGoodsData = chartDataOfItem.goodsItem > 0;

  const pieChartData = {
    labels: [],
    datasets: [
      {
        data: [
          Math.max(1, chartDataOfItem.serviceItems),
          Math.max(1, chartDataOfItem.goodsItem),
        ],
        backgroundColor:
          chartDataOfItem.serviceItems === 0 && chartDataOfItem.goodsItem === 0
            ? ["#A9A9A9"]
            : ["#FFEAA7", "#F39C12"],
        hoverBackgroundColor:
          chartDataOfItem.serviceItems === 0 && chartDataOfItem.goodsItem === 0
            ? ["#A9A9A9"]
            : ["#FFEAA7", "#F39C12"],
        borderWidth: 0,
        type: "doughnut",
      },
    ],
  };

  if (hasServiceData) {
    pieChartData.labels.push(t("services"));
  }

  if (hasGoodsData) {
    pieChartData.labels.push(t("goods"));
  }

  if (!hasServiceData && !hasGoodsData) {
    pieChartData.labels.push(t("no_data"));
  }

  const pieChartOptions = {
    cutout: "80%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // start bar chart

  // const dataLeave = {
  //   labels: ["Sales", "IT", "Accounts", "HR", "PR", "Legal", "Admin"],
  //   datasets: [
  //       {
  //           label: 'Dataset 1',
  //           backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //           data: [10, 20, 30, 40, 50, 60, 70],
  //           yAxisID: 'y-axis-1'
  //       },
  //       {
  //           label: 'Dataset 2',
  //           backgroundColor: 'rgba(54, 162, 235, 0.5)',
  //           data: [20, 30, 40, 50, 60, 70, 80],
  //           yAxisID: 'y-axis-2'
  //       }
  //   ]
  // };

  // const optionsLeave = {
  //   scales: {
  //     yAxes: [
  //         {
  //             id: 'y-axis-1',
  //             type: 'linear',
  //             position: 'left',
  //             grid: {
  //                 display: false
  //             }
  //         },
  //         {
  //             id: 'y-axis-2',
  //             type: 'linear',
  //             position: 'right',
  //             grid: {
  //                 display: false
  //             }
  //         }
  //     ]
  // },
  // plugins: {
  //     legend: {
  //         display: true,
  //         position: 'bottom'
  //     }
  // },
  // borderRadius: 4,
  // };

  // const barLabels = chartDataOfItem.avgInvStk.map(item => item.itemCategory);

  const barLabels =
    chartDataOfItem.avgInvStk?.map((item) => item.itemCategory) || [];

  const barinvValue =
    chartDataOfItem.avgInvStk?.map((item) => item.invValue) || [];

  const baritemStk =
    chartDataOfItem.avgInvStk?.map((item) => item.itemStk) || [];

  const dataLeave = {
    labels: barLabels,
    datasets: [
      {
        label: t("value"),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        data: barinvValue,
        yAxisID: "y",
      },
      {
        label: t("stock"),
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        data: baritemStk,
        yAxisID: "y1",
      },
    ],
  };

  // Options
  const optionsLeave = {
    scales: {
      y: {
        position: "left",
        grid: {
          display: false,
        },
        ticks: {
          color: "rgba(255, 99, 132, 1)",
        },
      },
      y1: {
        position: "right",
        grid: {
          display: false,
        },
        ticks: {
          color: "rgba(54, 162, 235, 1)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
    },
  };
  // curent People status bar and line chart combo end

  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">{t("items")}</div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                Avl Stock:{" "}
                <span className="fbold">
                  {Math.round(
                    ((chartDataOfItem.goodsItemVerified +
                      chartDataOfItem.serviceItemsVerified) /
                      chartDataOfItem.noOfItems) *
                      100
                  )}
                  %
                </span>
              </span> */}

              {/* <span title="Tenure">
                Out of Stock:{" "}
                <span className="fbold">{chartDataOfItem.noOfItems} items</span>
              </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={pieChartData}
                  options={pieChartOptions}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span>  */}
                  <span className="highDataCount">
                    {chartDataOfItem.noOfItems}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Bar
                className="hw100"
                type="bar"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Item View")
                  .menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenViewItems()}
                >
                  {t("view")}
                </Button>
              )}
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Item Add")
                  .menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenAddItems()}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
