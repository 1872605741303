import {
  AccessibleRounded,
  ArrowBackIosNew,
  ArrowForwardIos,
  BloodtypeOutlined,
  DescriptionOutlined,
  EmailOutlined,
  EmailRounded,
  HeightOutlined,
  LocalPhoneOutlined,
  LocalPhoneRounded,
  MaleRounded,
  MonetizationOnOutlined,
  MonitorWeightOutlined,
  Search,
  TranslateRounded,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  DeviceHddFill,
  HeartPulse,
  Lungs,
  PersonBoundingBox,
  Plus,
  ThermometerHalf,
  XLg,
} from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../../utils/DateUtils";
import EXP from "../../../../dashboard/image/EXP";
import dayjs from "dayjs";
import zhPatientService from "../../../../../services/ZhPatientService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ResheduleDoctorAppoinment({
  handleCloseResheduleDrAppointment,
  appoinmentData,
  refresReschduleDocAppointData,
}) {
  console.log("appoinmentData *****", appoinmentData);
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [baPatEmailId, setBaPatEmailId] = useState("");
  const [baPatTitle, setBaPatTitle] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientMaritalStatus, setPatientMaritalStatus] = useState("");
  const [selectedDob, setSelectedDob] = useState(new Date());
  const [rescheduleDate, setRescheduleDate] = useState(new Date());
  const [rescheduleReason, setRescheduleReason] = useState("");
  const [unAvailReason, setunAvailReason] = useState("");
  const [age, setAge] = useState({ years: null, months: null, days: null });
  const loginUserDetail = GetLoginUserDetails();
  const [patientList, setPatientList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patientIsdCode, setPatyientIsdCode] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [patientEmailId, setPatientEmailId] = useState("");
  const [isNewPatient, setIsNewPatient] = useState(false);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [forwardTo, setForwardTo] = useState("add-to-queue");

  const [patientAge, setPatientAge] = useState(null);
  const [patientImg, setPatientImg] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedAvail, setIsCheckedAvail] = useState(false);
  const [isCheckedForAvailibility, setIsCheckedAvailibility] = useState(false);

  useEffect(()=>{
    setBookSteps([
      {
        target: ".rescheduleDocStepOne",
        content: "This is where you see the appointment booking Details for slot(s)",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepTwo",
        content: "Here you can see label and and also there is an arrow and on clicking on which you go to previous page ",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepThree",
        content: "you can see Patient info Here.",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepFour",
        content: "you can reschedule from here",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepFive",
        content: "you can mark as available from here",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepSix",
        content: "you can mark as unavailable from here",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepSeven",
        content: "you can mark as unavailable reason from here",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepEight",
        content: "you can mark as available/unavailable from here",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepNine",
        content: "you can mark as available/unavailable from here",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepten",
        content: "you can mark as available/unavailable reason from here",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepEleven",
        content: "you can submit data from here",
        disableBeacon: true,
      },
      {
        target: ".rescheduleDocStepTwelve",
        content: "you can cancel from here",
        disableBeacon: true,
      }
    ])
  },[])

  const handelChangeBaPatEmailId = (event) => {
    if (!event.target.value) {
      setFilteredList(patientList);
    }
    setBaPatEmailId(event.target.value);
  };
  const handleChangebaPatTitle = (event) => {
    setBaPatTitle(event.target.value);
  };

  const handleChangeMaritalStatus = (event) => {
    setPatientMaritalStatus(event.target.value);
  };

  const handleChangepatientGender = (event) => {
    setPatientGender(event.target.value);
  };

  const handleAgeChange = (e) => {
    const { name, value } = e.target;
    let newAge = {
      ...age,
      [name]: value ? Number(value) : null,
    };
    setAge(newAge);
    calculateDOB(newAge);
    // setRefreshDOB(refreshDOB + 1);
  };

  const calculateDOB = (age) => {
    // console.log("calculateDOB", age);
    const today = new Date();
    const years = age.years || 0;
    const months = age.months || 0;
    const days = age.days || 0;
    // console.log("calculateDOB", years, months, days);
    console.log(years, today.getFullYear());
    const birthDate = new Date(
      today.getFullYear() - years,
      today.getMonth() - months,
      today.getDate() - days
    );
    console.log(birthDate);
    setSelectedDob(birthDate);
    const formattedDate = format(birthDate, "dd-MM-yyyy");
  };

  function calculateDateDifference(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    const [day, month, year] = dateString.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    return `${years}Y ${months}M ${days}D`;
  }

  const [patientData, setPatientData] = useState(null);

  useEffect(() => {
    fetchPatientDetails();
  }, [appoinmentData]);

  const fetchPatientDetails = async () => {
    if (appoinmentData?.patientId) {
      try {
        const resGetTodayPatList =
          await zhPatientService.getPatDtlsByRowPatientId({
            rowPatientId: appoinmentData?.patientId,
          });
        console.log("Patient details:", resGetTodayPatList);
        setPatientData(resGetTodayPatList.data);
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    }
  };

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [checkedSlots, setCheckedSlots] = useState({});

  useEffect(() => {
    if (
      !appoinmentData?.doctorId &&
      !appoinmentData?.doctorName &&
      appoinmentData?.slots?.length > 0
    ) {
      const updatedCheckedSlots = {};
      appoinmentData.slots.forEach((slot) => {
        if (slot.status === "C") {
          updatedCheckedSlots[slot.slotId] = true;
        }
      });
      setCheckedSlots(updatedCheckedSlots);
    }
  }, [appoinmentData]);

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setIsSelectAllChecked(isChecked);
    const updatedCheckedSlots = {};
    if (isChecked) {
      appoinmentData.slots.forEach((slot) => {
        updatedCheckedSlots[slot.slotId] = true;
      });
    }
    setCheckedSlots(updatedCheckedSlots);
  };

  const handleCheckboxChange = (slotId) => (event) => {
    setCheckedSlots((prevCheckedSlots) => ({
      ...prevCheckedSlots,
      [slotId]: event.target.checked,
    }));
  };

  const isAnySlotChecked = () => {
    // return Object.values(checkedSlots).some((isChecked) => isChecked === true);
    const selectedSlots = Object.keys(checkedSlots)
      .filter((slotId) => checkedSlots[slotId] === true)
      .map((slotId) => Number(slotId));

    return appoinmentData.slots.some((slot) => {
      return selectedSlots.includes(slot.slotId) && slot.status !== "C";
    });
  };

  useEffect(() => {
    if (
      appoinmentData?.slot?.status === "B" &&
      appoinmentData?.slot?.rescheduleDate &&
      appoinmentData?.slot?.rescheduleReqRmks
    ) {
      const [day, month, year] =
        appoinmentData?.slot?.rescheduleDate.split("-");
      const convertedDate = new Date(year, month - 1, day);
      setIsChecked(true);
      setRescheduleDate(convertedDate);
      setRescheduleReason(appoinmentData?.slot?.rescheduleReqRmks);
    }
  }, [appoinmentData]);

  const finalRescheduleSubmit = () => {
    if (!isChecked) {
      toast.error(t("please_check_reschedule_before_proceeding"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (rescheduleReason.trim() === "") {
      toast.error(t("please_enter_a_reason_for_reschedule"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reqDto = {
      rescheduleDate: DateUtils.getDateInDDMMYYYY(rescheduleDate),
      rmks: rescheduleReason,
      slots: [appoinmentData?.slot?.slotId],
    };

    console.log("Request DTO:", reqDto);

    DashboardServices.requestSlotReschedule(userDetails.userId, reqDto)
      .then((response) => {
        console.log("Response:", response.data);
        if (response.data === 1) {
          toast.success(t("request_slot_reschedule_successful"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleCloseResheduleDrAppointment();
          refresReschduleDocAppointData();
        } else {
          toast.error(t("failed_to_reschedule_slot"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error during rescheduling:", error);
        toast.error(t("an_error_occurred_while_rescheduling_the_slot"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const finalUnavailableforSingleSlot = () => {
    if (!isCheckedAvail) {
      toast.error(t("please_check_mark_as_unavailable_before_proceeding"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (unAvailReason.trim() === "") {
      toast.error(t("please_enter_remarks"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reqDto = {
      rmks: unAvailReason,
      slots: [appoinmentData?.slot?.slotId],
    };

    console.log("Request DTO:", reqDto);

    DashboardServices.makeSlotsUnavailable(userDetails.userId, reqDto)
      .then((response) => {
        console.log("Response:", response.data);
        if (response.data === 1) {
          toast.success(t("slot_marked_as_unavailable_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleCloseResheduleDrAppointment();
          refresReschduleDocAppointData();
          setunAvailReason("");
        } else {
          toast.error(t("failed_to_mark_slot_as_unavailable"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error making slots unavailable:", error);
        toast.error(t("an_error_occurred_while_making_the_slot_unavailable"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // const finalUnavailablefoMultipleSlot = () => {

  //   console.log('checkedSlots' , checkedSlots)
  //   const selectedSlots = Object.keys(checkedSlots)
  //     .filter((slotId) => checkedSlots[slotId] === true)
  //     .map((slotId) => Number(slotId));

  //   // if (selectedSlots.length === 0) {
  //   //   toast.error("No slots selected", {
  //   //     position: toast.POSITION.TOP_RIGHT,
  //   //   });
  //   //   return;
  //   // }
  //   console.log('selectedSlots' , selectedSlots)

  //   // const unselectedSlots = Object.keys(checkedSlots)
  //   // .filter((slotId) => checkedSlots[slotId] === false)
  //   // .map((slotId) => Number(slotId));

  //   const unselectedSlots = appoinmentData?.slots
  // .filter(slot => !selectedSlots.includes(slot.slotId))
  // .map(slot => slot.slotId);

  //   console.log('unselectedSlots' , unselectedSlots)

  //   return ;

  //   const reqDto = {
  //     rmks: "testing",
  //     slots: selectedSlots,
  //   };

  //   console.log("Request DTO:", reqDto);

  //   DashboardServices.makeSlotsUnavailable(userDetails.userId, reqDto)
  //     .then((response) => {
  //       console.log("Response:", response.data);
  //       if (response.data === 1) {
  //         toast.success("Slots successfully marked as unavailable", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         handleCloseResheduleDrAppointment();
  //       } else {
  //         toast.error("Failed to mark slots as unavailable", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     })

  //     DashboardServices.makeSlotsAvailable(userDetails.userId, unselectedSlots)
  //     .then((response) => {
  //       console.log("Response:", response.data);
  //       if (response.data === 1) {
  //         toast.success("Slot marked as Available successfully", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         handleCloseResheduleDrAppointment();
  //       } else {
  //         toast.error("Failed to mark slot as Available", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error making slots Available:", error);
  //       toast.error("An error occurred while making the slot unavailable", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     });

  // };

  const finalUnavailablefoMultipleSlot = () => {
    console.log("checkedSlots:", checkedSlots);

    const selectedSlots = Object.keys(checkedSlots)
      .filter((slotId) => checkedSlots[slotId] === true)
      .map((slotId) => Number(slotId));

    console.log("selectedSlots:", selectedSlots);

    const unselectedSlots = appoinmentData?.slots
      .filter((slot) => !selectedSlots.includes(slot.slotId))
      .map((slot) => slot.slotId);

    console.log("unselectedSlots:", unselectedSlots);

    const alreadyBookedSlot = appoinmentData?.slots?.find(
      (slot) => selectedSlots.includes(slot.slotId) && slot.status === "B"
    );

    if (alreadyBookedSlot) {
      toast.error(
        t("slotAlreadyBooked", {
          fromTime: alreadyBookedSlot.fromTime,
          toTime: alreadyBookedSlot.toTime,
        })
      );
      return;
    }

    if (isAnySlotChecked() && unAvailReason.trim() === "") {
      toast.error(t("please_enter_remarks"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let unavlslotts = [];
    let avlslotts = [];

    appoinmentData.slots.forEach((slot) => {
      if (selectedSlots.includes(slot.slotId) && slot.status !== "C") {
        unavlslotts.push(slot.slotId);
      } else if (!selectedSlots.includes(slot.slotId) && slot.status === "C") {
        avlslotts.push(slot.slotId);
      }
    });

    console.log("Unavailable Slots:", unavlslotts);
    console.log("Available Slots:", avlslotts);

    // toast.info(t("processing_please_wait"), {
    //   position: toast.POSITION.TOP_RIGHT,
    // });

    if (unavlslotts.length === 0 && avlslotts.length === 0) {
      toast.info(t("no_changes_made"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      // return;
    }

    const reqDto = {
      rmks: unAvailReason,
      slots: unavlslotts,
    };

    let anyApiCallMade = false;

    // return ;

    const makeSlotsUnavailable = () => {
      if (unavlslotts.length > 0) {
        anyApiCallMade = true;
        return DashboardServices.makeSlotsUnavailable(
          userDetails.userId,
          reqDto
        );
      }
      refresReschduleDocAppointData();
      return Promise.resolve();
    };

    const makeSlotsAvailable = () => {
      if (avlslotts.length > 0) {
        anyApiCallMade = true;
        return DashboardServices.makeSlotsAvailable(
          userDetails.userId,
          avlslotts
        );
      }
      return Promise.resolve();
    };

    Promise.all([makeSlotsUnavailable(), makeSlotsAvailable()])
      .then(([unavailableResponse = {}, availableResponse = {}]) => {
        if (unavailableResponse.data === 1) {
          // toast.success("Slots successfully marked as unavailable", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        } else if (unavailableResponse.data) {
          // toast.error("Failed to mark slots as unavailable", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }

        if (availableResponse.data === 1) {
          // toast.success("Slots successfully marked as available", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        } else if (availableResponse.data) {
          // toast.error("Failed to mark slots as available", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }

        console.log("anyApiCallMade", anyApiCallMade);

        if (anyApiCallMade) {
          toast.success(t("slot_availability_unavailability_is_updated"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        handleCloseResheduleDrAppointment();
        refresReschduleDocAppointData();
        setunAvailReason("");
      })
      .catch((error) => {
        console.error("Error updating slots:", error);
        toast.error(t("an_error_occurred_while_updating_slots"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const finalAvailableSingleSlot = () => {
    if (!isCheckedForAvailibility) {
      toast.error(t("please_check_mark_as_available_before_proceeding"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reqDto = [appoinmentData?.slot?.slotId];

    console.log("Request DTO:", reqDto);

    DashboardServices.makeSlotsAvailable(userDetails.userId, reqDto)
      .then((response) => {
        console.log("Response:", response.data);
        if (response.data === 1) {
          toast.success(t("slot_marked_as_available_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleCloseResheduleDrAppointment();
          refresReschduleDocAppointData();
        } else {
          toast.error(t("failed_to_mark_slot_as_available"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error making slots Available:", error);
        toast.error(t("an_error_occurred_while_making_the_slot_unavailable"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["rescheduleDoctorDc"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        // scrollToFirstStep={true}
        // scrollTo={true}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="rightFlotingPanel rescheduleDocStepOne">
        <div className="rightFlotingContainer">
          <div className="rfContHeader rescheduleDocStepTwo">
            <div className="rfcHeadText">{t("appointment_booking")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handleCloseResheduleDrAppointment()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails rescheduleDocStepThree">
              <div className="elementFormContainer">
                {appoinmentData?.slot?.slotId && (
                  <div className="tskElemHeding ">{t("patient_info")}</div>
                )}
                {appoinmentData?.slot?.slotId && (
                  // <div className="doctorDetails bookedSlot">
                  <div
                    className={
                      appoinmentData?.slot?.status === "C" ||
                      appoinmentData?.slot?.status === "B"
                        ? "doctorDetails bookedSlot mUnavailable"
                        : "doctorDetails bookedSlot"
                    }
                  >
                    {appoinmentData?.slot?.patientName && (
                      <div className="doctorInfoWthImg">
                        <div className="doctorFullInfo">
                          <div className="doctorNmSpc">
                            <div className="docName">
                              {appoinmentData?.slot?.patientName}
                            </div>
                            <div className="docName">
                              {appoinmentData?.slot?.patientExternalSourceId
                                ? appoinmentData?.slot?.patientExternalSourceId
                                : appoinmentData?.slot?.patientIdDisplay}
                            </div>
                            <div className="docSpclty">
                              {`(
                                ${
                                  appoinmentData?.slot?.patientGender === "Male"
                                    ? t("male")
                                    : appoinmentData?.slot?.patientGender ===
                                      "Female"
                                    ? t("female")
                                    : t("other")
                                }
                                   ${appoinmentData?.slot?.patientAge} )`}
                            </div>
                          </div>
                          <div className="docExpFeeLan"></div>
                        </div>
                      </div>
                    )}

                    <div className="bdtSlotInfo">
                      <div className="bookingDateTime">
                        {t("booking_date_time")}
                      </div>
                      <div className="slotInfo">
                        <div className="icon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotTime">
                          <span className="date">
                            {dayjs(appoinmentData?.date).format("DD-MMM-YYYY")}
                          </span>
                          <span className="time">{`${appoinmentData?.slot?.fromTime} - ${appoinmentData?.slot?.toTime}`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="filterPatlist appoinBookPatList">
                  <div className="filterPatlistInd">
                    {appoinmentData?.doctorId &&  appoinmentData?.slot?.patientName && (
                    <div className="filPatFullinfo">
                      <div className="filPatImg">
                        <img
                          src={patientData?.patientImageUrl ||"https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png"}
                          alt="patImage"
                        />
                      </div>
                      <div className="filtPatDtl">
                        <div className="filtPatNm">
                          {appoinmentData?.slot?.patientName}{" "}
                          <span>{`( ${appoinmentData?.slot?.patientGender}, ${appoinmentData?.slot?.patientAge} )`}</span>
                        </div>

                        <div className="docExpFeeLan">
                          <div className="docEFL">
                            <div className="eflicon ">
                              <LocalPhoneOutlined />
                            </div>
                            <div className="eflText">
                            {patientData?.patientContactNumber}
                            </div>
                          </div>
                          <div className="docEFL">
                            <div className="eflicon ">
                              <EmailOutlined />
                            </div>
                            <div className="eflText">
                              {patientData?.patientEmailId}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                    <div className="bdtSlotInfo">
                      <div className="bookingDateTime">Booking Date Time</div>
                      <div className="slotInfo">
                        <div className="icon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotTime">
                          <span
                            className="date"
                            style={{
                              color: "#fff",
                              fontSize: "14px",
                              opacity: "1",
                            }}
                          >
                            {dayjs(appoinmentData?.date).format("DD-MMM-YYYY")}
                          </span>
                          <span
                            className="time"
                            style={{
                              color: "#fff",
                              fontSize: "14px",
                              opacity: "1",
                            }}
                          >{`${appoinmentData?.slot?.fromTime} - ${appoinmentData?.slot?.toTime}`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {appoinmentData?.slot?.slotId &&
                  appoinmentData?.slot?.patientName &&
                  appoinmentData?.slot?.status === "B" && (
                    <>
                      {/* <div className="bb"></div> */}

                      <div className="empContListCheck">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup gap0DC">
                              <FormControlLabel
                                className="formRadioField rescheduleDocStepFour"
                                control={
                                  <Checkbox
                                    checked={isChecked}
                                    onChange={(e) =>
                                      setIsChecked(e.target.checked)
                                    }
                                  />
                                }
                                label={t("reschedule")}
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </div>
                    </>
                  )}

                {isChecked && appoinmentData?.slot?.status === "B" && (
                  <>
                    <div className="bb"></div>
                    <div className="tskElemHeding">
                      {t("reschedule_appointment")}
                    </div>
                    <div className="formElement mxW174">
                      <FormControl className="formControl">
                        <InputLabel
                          id="employeeJoingDate"
                          className="setCompletionDate"
                        >
                          {t("reschedule_date_on_before_after")}
                        </InputLabel>
                        <ReactDatePicker
                          locale={currentLocale}
                          toggleCalendarOnIconClick
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          showIcon
                          labelId="employeeJoingDate"
                          className="formDatePicker"
                          dateFormat="dd-MMM-yyyy"
                          // selected={baPatDOB}
                          // onChange={(date) => setBaPatDOB(date)}
                          minDate={new Date()}
                          selected={rescheduleDate}
                          onChange={(dateOfBirth) => {
                            setRescheduleDate(dateOfBirth);

                            // if (dateOfBirth) {
                            //   getDoctorAppoinment(
                            //     dayjs(dateOfBirth).format("DD-MM-YYYY")
                            //   );
                            // }
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl pRelative">
                        <TextField
                          label={t("reschedule_reason")}
                          required
                          variant="outlined"
                          className="descptionTextFild"
                          multiline
                          rows={4}
                          maxRows={7}
                          value={rescheduleReason}
                          onChange={(e) => {
                            setRescheduleReason(e.target.value);
                          }}
                        ></TextField>
                      </FormControl>
                    </div>
                  </>
                )}

                {(appoinmentData?.slot?.patientName === null ||
                  appoinmentData?.slot?.patientName === "") &&
                  appoinmentData?.slot?.status === "O" && (
                    <>
                      {/* <div className="bb"></div> */}

                      <div className="empContListCheck">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup gap0DC">
                              <FormControlLabel
                                className="formRadioField rescheduleDocStepSix"
                                control={
                                  <Checkbox
                                    checked={isCheckedAvail}
                                    onChange={(e) =>
                                      setIsCheckedAvail(e.target.checked)
                                    }
                                  />
                                }
                                label={t("mark_as_unavailable")}
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </div>

                      <div className="formElement">
                        <FormControl className="formControl pRelative">
                          <TextField
                            label={t("remarks")}
                            required
                            variant="outlined"
                            className="descptionTextFild rescheduleDocStepSeven"
                            multiline
                            rows={4}
                            maxRows={7}
                            value={unAvailReason}
                            onChange={(e) => {
                              setunAvailReason(e.target.value);
                            }}
                          ></TextField>
                        </FormControl>
                      </div>
                    </>
                  )}

                {(appoinmentData?.slot?.patientName === null ||
                  appoinmentData?.slot?.patientName === "") &&
                  appoinmentData?.slot?.status === "C" && (
                    <>
                      {/* <div className="bb"></div> */}

                      <div className="empContListCheck">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup gap0DC">
                              <FormControlLabel
                                className="formRadioField rescheduleDocStepFive"
                                control={
                                  <Checkbox
                                    checked={isCheckedForAvailibility}
                                    onChange={(e) =>
                                      setIsCheckedAvailibility(e.target.checked)
                                    }
                                  />
                                }
                                label={t("mark_as_available")}
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </div>
                    </>
                  )}

                {appoinmentData?.slotDate &&
                  appoinmentData?.slots &&
                  appoinmentData?.slots?.length > 0 && (
                    <>
                      <div className="tskElemHeding ">
                        {t("mark_slots_for_unavailability")}
                      </div>

                      <div className="bb"></div>

                      <div className="empContListCheck rescheduleDocStepEight">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup gap0DC">
                              <FormControlLabel
                                className="formRadioField"
                                control={
                                  <Checkbox
                                    checked={isSelectAllChecked}
                                    onChange={handleSelectAllChange}
                                  />
                                }
                                // label="Mark Select All For Unavailability"
                                label={
                                  <span style={{ fontWeight: "bold" }}>
                                    {t("select_all_for_unavailability")}
                                  </span>
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </div>

                      {appoinmentData.slots.map((slot) => (
                        <div key={slot.slotId} className="empContListCheck">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <FormGroup className="formRadioGroup gap0DC">
                                <FormControlLabel
                                  className="formRadioField rescheduleDocStepNine"
                                  control={
                                    <Checkbox
                                      checked={!!checkedSlots[slot.slotId]}
                                      onChange={handleCheckboxChange(
                                        slot.slotId
                                      )}
                                    />
                                  }
                                  label={`${slot.fromTime} - ${slot.toTime}`}
                                />
                              </FormGroup>
                            </FormControl>
                          </div>
                        </div>
                      ))}

                      {isAnySlotChecked() && (
                        <div className="formElement">
                          <FormControl className="formControl pRelative">
                            <TextField
                              label={t("remarks")}
                              required
                              variant="outlined"
                              className="descptionTextFild rescheduleDocStepten"
                              multiline
                              rows={4}
                              maxRows={7}
                              value={unAvailReason}
                              onChange={(e) => {
                                setunAvailReason(e.target.value);
                              }}
                            ></TextField>
                          </FormControl>
                        </div>
                      )}
                    </>
                  )}
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="doctorSelectWthSubBtn">
              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn rescheduleDocStepEleven"
                  onClick={() => {
                    const { doctorId, doctorName, slot, slots } =
                      appoinmentData || {};

                    if (slot?.status === "B" && slot?.patientName) {
                      finalRescheduleSubmit();
                    } else if (slot?.status === "C") {
                      finalAvailableSingleSlot();
                    } else if (slot?.status === "O") {
                      finalUnavailableforSingleSlot();
                    } else if (
                      appoinmentData?.slotDate &&
                      appoinmentData?.slots &&
                      slots?.length > 0
                    ) {
                      finalUnavailablefoMultipleSlot();
                    }
                  }}
                >
                  {t("submit")}
                </Button>

                <Button
                  className="dfultDarkSecondaryBtn rescheduleDocStepTwelve"
                  onClick={() => handleCloseResheduleDrAppointment()}
                >
                  {t("close")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
