import React, { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";

import {
  Accessible,
  Add,
  ManageHistoryRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import { ArrowRepeat, Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardDrBookAppointment({
  // handelOpenViewBookAppointment,
  handleOpenViewNewAppointMentBooked,
  handelOpenAppointmentSettings,
  chartDataOfItem,
  fetchDashBoardData,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  // console.log('chartDataOfItem' , chartDataOfItem)
  // console.log('chartDataOfItem' , chartDataOfItem.todayHourlyDoctorAppointment)

  useEffect(()=>{
    setBookSteps([
      {
        target: ".docBookAppointStepOne",
        content: "This is the section where a doctor can see the Appointment details",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".docBookAppointStepThree",
        content: "Click here to refresh the dashboard data and view the latest updates.",
        placement: "left",
        disableBeacon: true,
      },
      {
        target: ".docBookAppointStepFour",
        content: "This chart shows a breakdown of today's hourly appointments.",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".docBookAppointStepFive",
        content: "This number represents the total appointments booked today.",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".docBookAppointStepSix",
        content: "This bar chart displays the number of available and booked slots over time.",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".docBookAppointStepSeven",
        content: "Click here to view detailed appointment information.",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".docBookAppointStepEight",
        content: "Use this button to adjust appointment booking settings.",
        placement: "bottom",
        disableBeacon: true,
      },
    ])
  },[])

  const data = {
    labels:
      chartDataOfItem?.todayHourlyDoctorAppointment?.length > 0
        ? chartDataOfItem.todayHourlyDoctorAppointment.map((item) => item.label)
        : [],
    datasets: [
      {
        label: "",
        data:
          chartDataOfItem?.todayHourlyDoctorAppointment?.length > 0
            ? chartDataOfItem.todayHourlyDoctorAppointment.map(
                (item) => item.count
              )
            : [],
        backgroundColor: [
          "rgba(79, 212, 0, 1)",
          "rgba(234, 184, 0, 1)",
          "rgba(241, 61, 60, 1)",
          "rgba(254, 162, 59, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "80%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barLineData = {
    labels:
      chartDataOfItem?.docAppointment?.length > 0
        ? chartDataOfItem.docAppointment.map((item) => item.appointmentDate)
        : [],
    datasets: [
      {
        label: t("no_of_slots"),
        data:
          chartDataOfItem?.docAppointment?.length > 0
            ? chartDataOfItem.docAppointment.map((item) => item.noOfSlots)
            : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
      {
        label: t("booked_slots"),
        type: "line",
        data:
          chartDataOfItem?.docAppointment?.length > 0
            ? chartDataOfItem.docAppointment.map((item) => item.bookedSlots)
            : [],
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  // const handleJoyrideCallback = (data) => {
  //   const { action, type } = data;
  //   console.log("handleJoyrideCallback" , data ,  action, type )

  //   if (type === "close") {
  //     setRun(false);
  //   }
  // };


  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["appointBookdoctorDc"] === true}
        // callback={handleJoyrideCallback}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
        locale={{
          last: "Next",
        }}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
      />
      <div className="anDashIndCard docBookAppointStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("appointment_booked")}
              <IconButton
                className={`refreshIcon docBookAppointStepThree`}
                onClick={fetchDashBoardData}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition"> */}
              {/* <Male /> */}
              {/* This Month: <span className="fbold"> 215421</span> */}
              {/* </span> */}

              {/* <span title="Tenure"> */}
              {/* <Female /> */}
              {/* This week: <span className="fbold">12154</span> */}
              {/* </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf docBookAppointStepFour">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount docBookAppointStepFive">
                    {chartDataOfItem.todayDoctorAppointmentCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf docBookAppointStepSix">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn docBookAppointStepSeven"
              startIcon={<Eye />}
              onClick={() => {handleOpenViewNewAppointMentBooked();
                if (run["appointBookdoctorDc"] === true) {
                  setRun({
                    viewDoctorAppointDc: true,
                  });
                }}}
            >
              {t("view_appointment")}
            </Button>
            <Button
              className="dfultPrimaryBtn docBookAppointStepEight"
              startIcon={<ManageHistoryRounded />}
              onClick={() => {handelOpenAppointmentSettings(); 
                if (run["appointBookdoctorDc"] === true) {
                  setRun({
                    appointmentDocSettingDc: true,
                  });
                }
              }}
            >
              {t("settings")}
            </Button>
            {/* <Button
              className="dfultPrimaryBtn "
              startIcon={<Accessible />}
              onClick={() => handelOpenViewBookAppointment()}
            >
              Appointment
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
}
