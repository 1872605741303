import { Checkbox, CircularProgress, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ChevronDown, XLg, ChevronRight } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import { getUnits } from "../../../../services/AdminService";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";

// Your listToTree function
// const listToTree = (arr = []) => {
//   let map = {},
//     node,
//     res = [],
//     i;

//   for (i = 0; i < arr.length; i += 1) {
//     map[arr[i].unitId] = i;
//     arr[i].children = [];
//     arr[i].expanded = true;
//   }

//   for (i = 0; i < arr.length; i += 1) {
//     node = arr[i];
//     if (node && node.parUnitId && node.parUnitId !== "") {
//       const mapIndex = map[node.parUnitId];
//       arr[mapIndex].children.push(node);
//     } else {
//       res.push(node);
//     }
//   }

//   return res;
// };

const listToTree = (arr = []) => {
  let map = {},
    node,
    res = [],
    i;

  for (i = 0; i < arr.length; i += 1) {
    map[arr[i].unitId] = i;
    arr[i].children = [];
  }

  for (i = 0; i < arr.length; i += 1) {
    node = arr[i];
    if (node && node.parUnitId && node.parUnitId !== "") {
      const mapIndex = map[node.parUnitId];
      if (mapIndex !== undefined) {
        arr[mapIndex].children.push(node);
      } else {
        // Handle the case where node.parUnitId is not found in the map
        res.push(node);
      }
    } else {
      res.push(node);
    }
  }

  return res;
};

export default function UnitTreeView({ handleCloseUnitTreeView }) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  // const [unitsData, setUnitData] = useState([]);
  // const [treeData, setTreeData] = useState([]);
  const [positions, setPositions] = useState([]);

  const [allPositionData, setAllPositionData] = useState([]);

  const [expandedStates, setExpandedStates] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUnits(userDetails.orgId)
      .then((response) => {
        console.log("getUnits", response.data);
        // const sortedUnits = response.data.sort((a, b) => a.unitId - b.unitId);
        // setUnitData(sortedUnits);
        // const tree = listToTree(sortedUnits);
        // setTreeData(tree);
        setAllPositionData(response.data);
        setPositions(listToTree(response.data));
        setExpandedStates(
          response.data.reduce((acc, position) => {
            acc[position.unitId] = true;
            return acc;
          }, {})
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  }, [userDetails.orgId]);

  // const toggleNode = (node) => {
  //   node.expanded = !node.expanded;
  //   setTreeData([...treeData]);
  // };
  const handleToggle = (unitId) => {
    setExpandedStates((prevStates) => ({
      ...prevStates,
      [unitId]: !prevStates[unitId],
    }));
  };

  // const renderTree = (node) => (
  //   <li key={node.unitId}>
  //     <h4 className="level-1 rectangle">
  //       <span onClick={() => toggleNode(node)}>
  //         {node.children.length > 0 &&
  //           (node.expanded ? <ChevronDown /> : <ChevronRight />)}
  //       </span>
  //       <span>
  //         {/* <Checkbox defaultChecked /> */}
  //       </span>
  //       <span className="contName">{node.unitName}</span>
  //     </h4>
  //     {node.expanded && node.children.length > 0 && (
  //       <ol className="level-1-wrapper">{node.children.map(renderTree)}</ol>
  //     )}
  //   </li>
  // );

  const renderTree = (position) => {
    // if (position.positionId === positions[0].positionId) {
    //   return null;
    // }

    return (
      <li key={position.unitId}>
        <h4 className="level-1 rectangle">
          <span onClick={() => handleToggle(position.unitId)}>
            {position.children && position.children.length > 0 ? (
              expandedStates[position.unitId] ? (
                <ChevronDown />
              ) : (
                <ChevronRight />
              )
            ) : null}
          </span>
          <span>{/* <Checkbox defaultChecked /> */}</span>
          <span
            className="contName"
            // onClick={() => handelOpenCrtPosition(position)}
          >
            {position.unitName}
          </span>
        </h4>
        {position.children &&
          position.children.length > 0 &&
          expandedStates[position.unitId] && (
            <ol className="level-1-wrapper">
              {position.children.map((child) => renderTree(child))}
            </ol>
          )}
      </li>
    );
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("units_tree")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handleCloseUnitTreeView()}
          >
            <XLg />
          </IconButton>
        </div>
        {loading ? (
          <div className="center-progress" style={{ height: "65vh" }}>
            <CircularProgress
              sx={{ marginTop: "180px", marginLeft: "250px" }}
            />
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="TV_Container">
              {/* {treeData.length > 0 && (
                <h3 className="level-start rectangle">
                  <span onClick={() => toggleNode(treeData[0])}>
                    {treeData[0].children.length > 0 &&
                      (treeData[0].expanded ? (
                        <ChevronDown/>
                      ) : (
                        <ChevronRight/>
                      ))}
                  </span>
                  <span>
                  </span>
                  <span className="contName">{treeData[0].unitName}</span>
                </h3>
              )}
              {treeData.length > 0 && treeData[0].expanded && (
                <ol className="level-1-wrapper">
                  {treeData[0].children.map(renderTree)}
                </ol>
              )} */}

              <ol className="level-1-wrapper">
                {positions.map((position) => renderTree(position))}
                {/* {positions[0].children.map((position) => renderTree(position))} */}
              </ol>

              {/* <ol className="level-1-wrapper">
              <li>
                <h4 className="level-1 rectangle">
                  <span>
                    <ChevronDown />
                  </span>
                  <span>
                    <Checkbox defaultChecked />
                  </span>
                  <span className="contName">West Bengal</span>
                </h4>
                <ol className="level-1-wrapper">
                  <li>
                    <h4 className="level-1 rectangle">
                      <span>
                        <Checkbox defaultChecked />
                      </span>
                      <span className="contName">Darjeeling</span>
                    </h4>
                  </li>
                  <li>
                    <h4 className="level-1 rectangle">
                      <span>
                        <Checkbox defaultChecked />
                      </span>
                      <span className="contName">Malda</span>
                    </h4>
                  </li>
                  <li>
                    <h4 className="level-1 rectangle">
                      <span>
                        <Checkbox defaultChecked />
                      </span>
                      <span className="contName">Nodia</span>
                    </h4>
                  </li>
                  <li>
                    <h4 className="level-1 rectangle">
                      <span>
                        <ChevronDown />
                      </span>
                      <span>
                        <Checkbox defaultChecked />
                      </span>
                      <span className="contName">Kolkata</span>
                    </h4>
                    <ol className="level-1-wrapper">
                      <li>
                        <h4 className="level-1 rectangle">
                          <span>
                            <Checkbox defaultChecked />
                          </span>
                          <span className="contName">New Town</span>
                        </h4>
                      </li>
                      <li>
                        <h4 className="level-1 rectangle">
                          <span>
                            <Checkbox defaultChecked />
                          </span>
                          <span className="contName">Sector V.</span>
                        </h4>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h4 className="level-1 rectangle">
                  <span>
                    <Checkbox defaultChecked />
                  </span>
                  <span className="contName">Delhi</span>
                </h4>
              </li>
              <li>
                <h4 className="level-1 rectangle">
                  <span>
                    <Checkbox defaultChecked />
                  </span>
                  <span className="contName">Mumbai</span>
                </h4>
              </li>
              <li>
                <h4 className="level-1 rectangle">
                  <span>
                    <Checkbox defaultChecked />
                  </span>
                  <span className="contName">Pune</span>
                </h4>
              </li>
            </ol>  */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
