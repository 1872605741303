import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Help, Support } from "@mui/icons-material";
import RightSideSupportContainer from "./RightSideSupportContainer";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";

function HeaderSupportButton({
  setShowSupportRightPanel,
  showSupportRightPanel,
}) {
  const { t } = useTranslation();
  const handleClick = () => {
    setShowSupportRightPanel(!showSupportRightPanel);
  };

  return (
    <>
      <IconButton className="support-button-mob" onClick={handleClick}>
        <Help />
      </IconButton>
      <Button
        className="support-button"
        variant="contained"
        startIcon={<Help />}
        onClick={handleClick}
      >
        {t("support")}
      </Button>
    </>
  );
}

export default HeaderSupportButton;
