import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SupportService from "../../../services/SupportService";
import dayjs from "dayjs";
import { GetLoginUserDetails } from "../../../utils/Utils";
import { toast } from "react-toastify";
import DashboardServices from "../../../services/DashboardServices";
import { IconButton } from "@mui/material";
import { Send } from "react-bootstrap-icons";

const TicketComments = ({ ticket, addedComment }) => {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const scrollRef = useRef();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (ticket.taskId > 0) {
      console.log("fetchSupportTicketComments", ticket);
      fetchSupportTicketComments();
    } else {
      console.log("No Comments for Support ticket", ticket);
    }
  }, []);

  const fetchSupportTicketComments = async () => {
    const response = await SupportService.getSupportTicketTaskComments(
      ticket.taskId
    );
    setComments(response.data);
  };

  const getFormattedDate = (dateString) => {
    const date = dayjs(dateString);
    const formattedString = date.format("DD-MM-YYYY hh:mm A");
    return formattedString;
  };

  useEffect(() => {
    if (addedComment && addedComment.comment !== "") {
      setComments((prevComments) => [...prevComments, addedComment]);
    }
  }, [addedComment]);

  return (
    <>
      {" "}
      <div className="taskElementGrp">
        <div className="tskElemHeding">{t("last_updated")}</div>
        <div className="tskElemntInfo">
          {comments != null &&
            comments.length > 0 &&
            `${
              comments[comments.length - 1].commentsByUserName
            } - ${getFormattedDate(comments[comments.length - 1].commentsAt)}`}
        </div>
      </div>
      <div className="tskElemHeding ticketMessageHdr">{t("messages")}:</div>
      <div className="taskUpCommentGrp">
        {comments.length > 0 &&
          comments.map((comment) => (
            <div className="taskUserComment supportTicketComments">
              <div className="tskUderImgDT">
                <div className="tskUseComImg">
                  <img src={comment.commentsByUserProfileUrl} alt="" />
                </div>
                <div className="tskUsrCNM">
                  <div className="tskUCNM">{comment.commentsByUserName}</div>
                  <div className="tskUCDT">
                    {comment && getFormattedDate(comment.commentsAt)}
                  </div>
                </div>
              </div>
              <div className="taskUPComnt">{comment.comments}</div>
            </div>
          ))}
      </div>
      <div ref={scrollRef}></div>
    </>
  );
};

export default TicketComments;
