import React from "react";
// import "./styles.css";

const stickyDataTabs = ["Meeting Room", "Meeting", "Create Task"];

function TabContent({ title, content, isActive, type }) {
  return stickyDataTabs.indexOf(title) !== -1 ||
    type === "Meeting" ||
    type === "Whiteboard" ||
    type === "File Viewer" ||
    type === "Meeting Summary" ? (
    <div className={`${isActive ? "" : "dNone"}`}>{content}</div>
  ) : (
    isActive && <>{content}</>
  );
}

export default TabContent;
