import React from "react";
import { Line } from "react-chartjs-2";
// import "chart.js/auto";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";
import { useTranslation } from "react-i18next";
const CardWebsiteStatusLineGraph = ({ data }) => {
  const { t } = useTranslation();
  console.log("CardWebsiteStatusLineGraph", data);
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    TimeScale,
    TimeSeriesScale
  );

  const nodes = Array.from(new Set(data.map((d) => d.node)));
  const labels = Array.from(new Set(data.map((d) => d.hour))); // Unique dates/times for x-axis

  //   console.log("CardWebsiteStatusLineGraph", nodes);
  //   console.log("CardWebsiteStatusLineGraph", labels);

  const datasets = nodes.map((node) => {
    const nodeData = data.filter((d) => d.node === node);
    return {
      label: node,
      data: labels.map((label) => {
        const entry = nodeData.find((d) => d.hour === label);
        return entry ? parseFloat(entry.average_time_total) : null;
      }),
      fill: false,
      borderColor: getNodeColor(node),
      tension: 0.1,
    };
  });

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${t("Response_Time")}: ${context.parsed.y.toFixed(3)}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          tooltipFormat: "DD-MMM-YYYY hhA",
          displayFormats: {
            hour: "DD-MMM-YY hhA",
          },
        },
        title: {
          display: true,
          text: t("Date_Time"),
        },
        grid: {
          color: "#545454",
        },
      },
      y: {
        title: {
          display: true,
          text: `${t("Response_Time")}(s)`,
        },
        ticks: {
          min: 20, // Set minimum value to 10 seconds
          callback: function (value) {
            return value + "s";
          },
        },
        grid: {
          color: "#545454",
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

// Utility function to get a random color for each node
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

function getNodeColor(nodeName) {
  switch (nodeName.toLowerCase()) {
    case "stockholm":
      return "#FF5733"; // Vibrant red-orange
    case "sydney":
      return "#FF33A1"; // Vibrant pink
    case "paris":
      return "#33FF57"; // Bright green
    case "tokyo":
      return "#3357FF"; // Bright blue
    case "ashburn":
      return "#FFD700"; // Vibrant gold
    case "johannesburg":
      return "#F4500"; // Dark orange
    case "los-angeles":
      return "#FF33FF"; // Neon pink
    case "sao-paulo":
      return "#00FF7F"; // Spring green
    case "london":
      return "#8A2BE2"; // Orange-red
    case "helsinki":
      return "#7FFF00"; // Chartreuse green
    case "toronto":
      return "#00CED1"; // Dark turquoise
    case "bangalore":
      return "#FF1493"; // Deep pink
    case "singapore":
      return "#1E90FF"; // Dodger blue
    case "nuremberg":
      return "#32CD32"; // Lime green
    case "new_york":
      return "#FF8C00"; // Blue violet
    default:
      return "#808080"; // Default gray for unknown nodes
  }
}

export default CardWebsiteStatusLineGraph;
