import { Add, Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  activateSalaryComponent,
  deactivateSalaryComponent,
  getOrgByOrgId,
  getSalaryComponents,
} from "../../../../services/AdminService";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../common/TourProvider";

export default function DefineSalaryComponent({
  handelOpenNewSalaryComponent,
  handelOpnEditSalaryComponent,
  refreshSalaryComponent,
}) {
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [orgDetailsData, setOrgDetailsData] = useState(null);
  const [salaryComponentData, setSalaryComponentData] = useState([]);
  const [filterSalaryComponentData, setFilterSalaryComponentData] = useState(
    []
  );
  const [activateComponentId, setActivateComponentId] = useState(null);
  const [deactivateComponentId, setDeactivateComponentId] = useState(null);

  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchgetOrgDetails();
  }, [userDetails.orgId]);

  const fetchgetOrgDetails = () => {
    getOrgByOrgId(userDetails.orgId)
      .then((response) => {
        console.log("getOrgDetails", response.data);
        setOrgDetailsData(response.data || {});
      })
      .catch((error) => {
        console.error("Error fetching getOrgDetails", error);
      });
  };

  console.log("orgDetailsData", orgDetailsData);

  useEffect(() => {
    fetchSalaryComponentData();
  }, [orgDetailsData, refreshSalaryComponent]);

  const fetchSalaryComponentData = () => {
    if (orgDetailsData && orgDetailsData.countryCode) {
      getSalaryComponents(
        userDetails.orgId,
        userDetails.userId,
        orgDetailsData.countryCode
      )
        .then((response) => {
          console.log("getSalaryComponents", response.data);
          setSalaryComponentData(response.data);
          setFilterSalaryComponentData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching getSalaryConfigMaster", error);
          setLoading(false);
        });
    }
  };

  console.log("salaryComponentData", salaryComponentData);

  const handleChangeCupStatus = (event, salaryComponentId) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      console.log("activating");
      setActivateComponentId(salaryComponentId);
      setOpenActivateModal(true);
    } else {
      console.log("deactivating -----");
      setDeactivateComponentId(salaryComponentId);
      setOpenDeactivateModal(true);
    }
  };

  const handleCloseActivateModal = () => {
    setOpenActivateModal(false);
  };

  const handleCloseDeactivatedModal = () => {
    setOpenDeactivateModal(false);
  };

  const confirmActivate = () => {
    console.log("activated");
    if (activateComponentId) {
      activateSalaryComponent(activateComponentId, userDetails.userId)
        .then((response) => {
          console.log("response", response.data);
          if (response.data === 1) {
            toast.success(t("salary_component_activated_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchSalaryComponentData();
            setOpenActivateModal(false);
            setActivateComponentId(null);
          } else {
            toast.error(t("failed_to_activated_salary_component"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error("Error Activated Salary Component:", error);
          toast.error(t("an_error_occurred_while_activated_salary_component"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const confirmDeactivate = () => {
    console.log("Deactivated");

    if (deactivateComponentId) {
      deactivateSalaryComponent(deactivateComponentId, userDetails.userId)
        .then((response) => {
          console.log("response", response.data);
          if (response.data === 1) {
            toast.success(t("salary_component_deactivated_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchSalaryComponentData();
            setOpenDeactivateModal(false);
            setDeactivateComponentId(null);
          } else {
            toast.error(t("failed_to_deactivated_salary_component"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error("Error Deactivated Salary Component:", error);
          toast.error(
            t("an_error_occurred_while_deactivated_salary_component"),
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = salaryComponentData.filter(
      (salaryComponent) =>
        salaryComponent.componentName &&
        salaryComponent.componentName
          .toLowerCase()
          .includes(query.toLowerCase())
    );

    setFilterSalaryComponentData(filteredData);
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".tabSalaryCompStepOne",
        content: "Search for salary components",
        disableBeacon: true,
      },
      {
        target: ".tabSalaryCompStepTwo",
        content: "Click to add a new salary component",
        disableBeacon: true,
      },
      {
        target: ".tabSalaryCompStepThree",
        content: "Explore the table for salary component data",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".tabSalaryCompStepFour",
        content: "Click to edit or activate salary components",
        placement: "left",
        disableBeacon: true,
      },
    ])
  }, []);

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["viewSalaryComponetAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="searchTasklist tabSalaryCompStepOne">
            <input
              type="text"
              class="serchTaskInput"
              placeholder={t("search_component")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn tabSalaryCompStepTwo">
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handelOpenNewSalaryComponent()}
            >
              {t("component")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : salaryComponentData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>
              {t("no_salary_component_added_yet")}
              <br />
              <span className="sallMsg">
                {t("click_add_component_button_to_create_new_salary_component")}
              </span>
            </div>
          ) : (
            <div className="TableContainer">
              <table className="taskListTable tabSalaryCompStepThree">
                <thead className="taskListtableHeader">
                  <tr>
                    <th>{t("component_name")}</th>
                    <th>{t("category")}</th>
                    <th>{t("periodicity")}</th>
                    <th>{t("calculation")}</th>
                    <th>{t("configuration")}</th>
                    <th className="width100">{t("action")}</th>
                  </tr>
                </thead>
                {/* <tbody className="scrolableTableBody">
                <tr>
                  <td>
                    <div className="tskLstTaskNM">Basic</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">Fixed Component</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">Monthly</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">50% of CTC</div>
                  </td>
                  <td>
                    <ul className="tskLstTaskNMGroup">
                      <li>
                        <div className="tskLstTaskNM">
                          Always Consider for EPF
                        </div>
                      </li>
                      <li>
                        <div className="tskLstTaskNM">Consider for ESI</div>
                      </li>
                    </ul>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              </tbody> */}
                <tbody className="scrolableTableBody">
                  {filterSalaryComponentData.map((component) => (
                    <tr key={component.salaryComponentId}>
                      <td>
                        <div className="tskLstTaskNM">
                          {component.componentName || t("n_a")}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {component.category || t("n_a")}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {component.periodicity || t("n_a")}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {component.flatAmountPc !== null
                            ? `${component.flatAmountPc} ${component.calculationType}`
                            : `${component.calculationType}`}
                        </div>
                      </td>
                      <td>
                        <ul className="tskLstTaskNMGroup">
                          {component.configs.map((config) => (
                            <li key={config.salaryComponentConfigId}>
                              <div className="tskLstTaskNM">{config.rule}</div>
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp tabSalaryCompStepFour">
                          <IconButton
                            onClick={() =>
                              handelOpnEditSalaryComponent(component)
                            }
                          >
                            <Edit />
                          </IconButton>
                          {component.deleteable !== "N" && (
                            <FormControlLabel
                              className="couponStatusSwitch"
                              control={
                                <Switch
                                  checked={component.activeFlag === "Y"}
                                  onChange={(event) =>
                                    handleChangeCupStatus(
                                      event,
                                      component.salaryComponentId
                                    )
                                  }
                                  color="primary"
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label={
                                component.activeFlag === "Y"
                                  ? t("active")
                                  : t("deactive")
                              }
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <Modal
            open={openActivateModal}
            onClose={handleCloseActivateModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                maxWidth: "600px",
                minHeight: "150px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2 id="modal-modal-title">
                {t("do_you_want_to_activate_this_salary_component")} ?
              </h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button className="dfultPrimaryBtn" onClick={confirmActivate}>
                  {t("yes")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={handleCloseActivateModal}
                >
                  {t("no")}
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            open={openDeactivateModal}
            onClose={handleCloseDeactivatedModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                maxWidth: "600px",
                minHeight: "150px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2 id="modal-modal-title">
                {t("do_you_want_to_deactivate_this_salary_component")} ?
              </h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button className="dfultPrimaryBtn" onClick={confirmDeactivate}>
                  {t("yes")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={handleCloseDeactivatedModal}
                >
                  {t("no")}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
