import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {
  getPositionsOfOrganization,
  getRightsToSelect,
  getCommittees,
  addRight,
  UpdateCriticalRights,
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";

export default function CreateExpenditurPower({
  handelCloseCreateExpPwr,
  onSuccess,
  selectExpPowr,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  console.log("selectExpPowr", selectExpPowr);

  const [AllExpenseType, setAllExpenseType] = useState([]);

  useEffect(() => {
    getRightsToSelect("EXPENDITURE_RIGHTS").then((response) => {
      console.log("Expenditure rights", response.data);
      setAllExpenseType(response.data);
    });
  }, []);

  const [selectExpType, setSelectExpType] = useState("");
  const [updateExpType, setUpdateExpType] = useState("");

  const handleChangeSelectExpType = (event) => {
    // setSelectExpType(event.target.value);
    const value = event.target.value;
    console.log("handleChangeSelectPosition", value);
    // setSelectExpType(value);
    if (selectExpPowr) {
      setSelectExpType(selectExpPowr);
    } else {
      setSelectExpType(value);
    }
  };
  const [selectedOption, setSelectedOption] = useState("");
  const [selectPosition, setSelectPosition] = useState("");
  const [allPositionData, setPositionData] = useState([]);
  const [updatedPosition, setUpdatedPosition] = useState("");

  useEffect(() => {
    getPositionsOfOrganization(userDetails.orgId)
      .then((response) => {
        console.log("Expenditure right Position", response.data);
        // setPositionData(response.data);
        setPositionData([
          ...response.data,
          { positionId: "-1", position: "Reporting Head" },
        ]);
      })
      .catch((error) => {
        console.error("Error fetching positions", error);
      });
  }, [userDetails.orgId]);

  const handleChangeSelectPosition = (event) => {
    setSelectedOption("position");
    // setSelectPosition(event.target.value);
    const value = event.target.value;
    console.log("handleChangeSelectPosition", value);
    setSelectPosition(value);
    if (selectExpPowr) {
      selectExpPowr.positionId = value;
      setUpdatedPosition(value);
    }
  };

  const [committeeData, setCommitteeData] = useState([]);

  const [selectCommittee, setSelectCommittee] = useState("");
  const [updatedCommittee, setUpdatedCommittee] = useState("");

  useEffect(() => {
    getCommittees(userDetails.orgId)
      .then((response) => {
        console.log("getCommittees", response.data);
        setCommitteeData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      });
  }, [userDetails.orgId]);

  const handleSelectCommittee = (event) => {
    setSelectedOption("committee");
    // console.log('handleSelectCommittee', event.target.value);
    // setSelectCommittee(event.target.value);
    const value = event.target.value;
    console.log("handleChangeSelectPosition", value);
    setSelectCommittee(value);
    if (selectExpPowr) {
      selectExpPowr.committeeId = value;
      setUpdatedCommittee(value);
    }
  };

  const [minAmount, setMinAmount] = useState("");
  const [updateMinAmount, setUpdatedMinAmount] = useState("");

  const handleMinAmount = (event) => {
    console.log("handleMinAmount", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setMinAmount(numericValue);
    if (selectExpPowr) {
      selectExpPowr.minAmount = numericValue;
      setUpdatedMinAmount(numericValue);
    }
  };

  const [maxAmount, setMaxAmount] = useState("");
  const [updatedmaxAmount, setUpdatedMaxAmount] = useState("");

  const handleMaxAmount = (event) => {
    console.log("handleMaxAmount", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setMaxAmount(numericValue);
    if (selectExpPowr) {
      selectExpPowr.maxAmount = numericValue;
      setUpdatedMaxAmount(numericValue);
    }
  };

  const [isDelegatable, setIsDelegatable] = useState("Y");
  // const [isUpdatedDelegatable , setIsUpdatedDelegatable] = useState(selectExpPowr.deligateable)
  const [isUpdatedDelegatable, setIsUpdatedDelegatable] = useState(
    selectExpPowr ? selectExpPowr.deligateable : "Y"
  );

  const handleDelegatable = (event) => {
    // console.log('handleDelegatable' , event.target.value);
    // setIsDelegatable(event.target.value)
    const value = event.target.value;
    console.log("handleDelegatable", value);
    setIsDelegatable(value);
    if (selectExpPowr) {
      selectExpPowr.deligateable = value;
      setIsUpdatedDelegatable(value);
    }
  };

  const finalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");

    let positionName = "";
    const selectedPosition = allPositionData.find(
      (pos) => pos.positionId === selectPosition
    );
    if (selectedPosition) {
      positionName = selectedPosition.position;
    }

    let committeeName = "";
    const selectedCommittee = committeeData.find(
      (pos) => pos.committeeId === selectCommittee
    );
    if (selectedCommittee) {
      committeeName = selectedCommittee.committeeName;
    }

    if (selectExpType.trim() === "") {
      toast.error(t("please_select_expense_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!selectPosition) {
      toast.error(t("please_select_a_position"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (minAmount.trim() === "") {
      toast.error(t("please_give_minimum_amount"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (maxAmount.trim() === "") {
      toast.error(t("please_give_maximum_amount"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (maxAmount < minAmount) {
    //   toast.error("Maximum Amount should be greater than Minimum Amount", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // };

    if (parseFloat(maxAmount) < parseFloat(minAmount)) {
      toast.error(t("maximum_amount_should_be_greater_than_minimum_amount"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      rightCategory: "EXPENDITURE_RIGHTS",
      right: selectExpType,
      deligateable: "N",
      minAmount: minAmount,
      maxAmount: maxAmount,
      id: 0,
      positionId: selectPosition,
      committeeId: selectCommittee,
      positionName: positionName,
      committeeName: committeeName,
    };
    console.log("reqDto", reqDto);

    addRight(userDetails.orgId, userDetails.userId, reqDto).then((response) => {
      console.log("reqDto", response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        onSuccess();
        setSelectExpType("");
        setSelectPosition("");
        setSelectCommittee("");
        setMinAmount("");
        setMaxAmount("");
        setIsDelegatable("Y");
      }
    });
  };

  const updatedFinalSubmit = () => {
    setLoading(true);
    console.log("updatedFinalSubmit", updatedFinalSubmit);

    const newRights = updateExpType ? updateExpType : selectExpPowr.right;
    const newdeligateable = isUpdatedDelegatable
      ? isUpdatedDelegatable
      : selectExpPowr.deligateable;
    const newminAmount = updateMinAmount
      ? updateMinAmount
      : selectExpPowr.minAmount;
    const newmaxAmount = updatedmaxAmount
      ? updatedmaxAmount
      : selectExpPowr.maxAmount;

    let positionId = 0;
    let positionName = "";
    let committeeId = 0;
    let committeeName = "";

    if (updatedPosition) {
      positionId = updatedPosition;
      const selectedPosition = allPositionData.find(
        (pos) => pos.positionId === updatedPosition
      );
      if (selectedPosition) {
        positionName = selectedPosition.position;
      }
    } else if (updatedCommittee) {
      committeeId = updatedCommittee;
      const selectedCommittee = committeeData.find(
        (pos) => pos.committeeId === updatedCommittee
      );
      if (selectedCommittee) {
        committeeName = selectedCommittee.committeeName;
      }
    } else {
      positionId = selectExpPowr.positionId;
      positionName = selectExpPowr.positionName;
      committeeId = selectExpPowr.committeeId;
      committeeName = selectExpPowr.committeeName;
    }

    const reqDto = {
      rightCategory: selectExpPowr.rightCategory,
      right: newRights,
      deligateable: "N",
      minAmount: newminAmount,
      maxAmount: newmaxAmount,
      id: selectExpPowr.id,
      positionId: positionId,
      committeeId: committeeId,
      positionName: positionName,
      committeeName: committeeName,
    };

    console.log("reqDto", reqDto);

    UpdateCriticalRights(userDetails.userId, reqDto).then((response) => {
      console.log("updateExpenditure", response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        onSuccess();
        handelCloseCreateExpPwr();
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setSelectCommittee("");
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setSelectCommittee("");
  };

  const [isHoveredDone, setIsHoveredDone] = useState(false);

  const handleMouseEnterDone = () => {
    setIsHoveredDone(true);
    setSelectPosition("");
  };

  const handleMouseLeaveDone = () => {
    setIsHoveredDone(false);
    setSelectPosition("");
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">
            {selectExpPowr
              ? t("edit_expenditure_power")
              : t("add_expenditure_power")}
          </div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCreateExpPwr()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="select_expType">
                  {t("expense_type")} *
                </InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  required
                  labelId="select_expType"
                  id="selectExpType"
                  value={selectExpPowr?.right || selectExpType}
                  label={t("expense_type")}
                  onChange={handleChangeSelectExpType}
                  disabled={selectExpPowr}
                >
                  {/* <MenuItem value="cap">Capital</MenuItem>
                <MenuItem value="opa">Oparetion</MenuItem> */}
                  {AllExpenseType.map((rights) => (
                    <MenuItem key={rights.id} value={rights.id}>
                      {rights.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="formElementGrp">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="select_Position">
                    {t("select_position")} *
                  </InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    required
                    labelId="select_Position"
                    id="selectPosition"
                    value={selectExpPowr?.positionId || selectPosition}
                    label={t("select_position")}
                    onChange={handleChangeSelectPosition}
                    // disabled={selectedOption === "committee"}
                    disabled={selectedOption === "committee" && !isHovered}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {/* <MenuItem value="ceo">CEO</MenuItem>
                  <MenuItem value="COO">COO</MenuItem>
                  <MenuItem value="vp">VP</MenuItem> */}

                    {allPositionData.map((position) => (
                      <MenuItem
                        key={position.positionId}
                        value={position.positionId}
                      >
                        {position.position}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="select_Position">
                    Select Committee *
                  </InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    required
                    labelId="select_Position"
                    id="selectPosition"
                    value={selectExpPowr?.committeeId || selectCommittee}
                    label="Select Committee *"
                    onChange={handleSelectCommittee}
                    disabled={selectedOption === "position" && !isHoveredDone}
                    onMouseEnter={handleMouseEnterDone}
                    onMouseLeave={handleMouseLeaveDone}
                  >
                    {committeeData.map((Committee) => (
                      <MenuItem
                        key={Committee.committeeId}
                        value={Committee.committeeId}
                      >
                        {Committee.committeeName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div> */}
            </div>
            <div className="formElementGrp">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    // hiddenLabel
                    label={t("min_amount")}
                    required
                    variant="outlined"
                    value={selectExpPowr?.minAmount || minAmount}
                    className="formTextFieldArea"
                    onChange={handleMinAmount}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    // hiddenLabel
                    label={t("max_amount")}
                    required
                    variant="outlined"
                    value={selectExpPowr?.maxAmount || maxAmount}
                    className="formTextFieldArea"
                    onChange={handleMaxAmount}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </FormControl>
              </div>
            </div>

            {/* <div className="formElement">
              <div className="textWithOption">
                <div className="fromText">Delegatable</div>
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    value={selectExpPowr?.deligateable || isDelegatable}
                    name="deligable"
                    onChange={handleDelegatable}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Y"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="N"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div> */}

            {/* <div className="formElement">
            <div className="textWithOption">
              <div className="fromText">M&C Rules</div>
              <FormControl className="formControl">
                <RadioGroup
                  className="formRadioGroup"
                  labelId="setTasktype"
                  name="mcRules"
                >
                  <FormControlLabel
                    className="formRadioField"
                    value="Y"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    className="formRadioField"
                    value="N"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div> */}
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={()=>finalSubmit()}>Save</Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={() =>
                selectExpPowr ? updatedFinalSubmit() : finalSubmit()
              }
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("submit")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseCreateExpPwr()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
