import React, { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Bubble } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ArrowRepeat } from "react-bootstrap-icons";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import {
  MoodBadOutlined,
  SentimentDissatisfiedOutlined,
  SentimentNeutralOutlined,
  SentimentSatisfiedOutlined,
  SentimentVerySatisfiedOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController
);

export default function CardManagementFeedback() {
  const { t } = useTranslation();
  const userDetails = GetLoginUserDetails();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [feedBackData, setFeedBackData] = useState({});

  const [filterBy, setFilterBy] = useState("Patient");


  useEffect(()=>{
    setBookSteps([
      {
        target: ".msFeedback",
        content: "This section displays card management Feedback.",
        disableBeacon: true,
      },
      {
        target: ".msFeedbackOne",
        content: "Click here to refresh feedback data manually.",
        disableBeacon: true,
      },
      {
        target: ".msFeedbackTwo",
        content: "Use this dropdown to filter feedback by 'Patient' or 'Doctor'.",
        disableBeacon: true,
      },
      {
        target: ".msFeedbackThree",
        content: "This gauge chart represents the overall feedback rating.",
        disableBeacon: true,
      },
      {
        target: ".msFeedbackFour",
        content: "Analyze performance trends with this bar chart showing feedback counts.",
        disableBeacon: true,
      },
     
    ]
    )
  },[])

  const handleChangefilterop = (event) => {
    setFilterBy(event.target.value);
  };

  useEffect(() => {
    fetchFeedbackData();
  }, [filterBy]);

  const fetchFeedbackData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: filterBy,
      fromDate: "",
      toDate: "",
    };

    console.log("reqDto", reqDto);

    DashboardServices.refreshManagementFeedback(reqDto).then((response) => {
      console.log("response", response.data);
      setFeedBackData(response.data);
    });
  };

  const mdFeedback = feedBackData.mdFeedback || [];
  const label = mdFeedback.map((entry) => entry.label);
  const count = mdFeedback.map((entry) => entry.count);

  const data = {
    labels: label,
    datasets: [
      {
        label: "",
        data: count,
        backgroundColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };
  // const data = {
  //   labels: ["Feedback"],
  //   datasets: [
  //     {
  //       label: "January",
  //       data: [65],
  //       backgroundColor: "rgba(75, 192, 192, 1)",
  //     },
  //     {
  //       label: "February",
  //       data: [59],
  //       backgroundColor: "rgba(153, 102, 255, 1)",
  //     },
  //     {
  //       label: "March",
  //       data: [80],
  //       backgroundColor: "rgba(255, 159, 64, 1)",
  //     },
  //     {
  //       label: "April",
  //       data: [81],
  //       backgroundColor: "rgba(54, 162, 235, 1)",
  //     },
  //     {
  //       label: "May",
  //       data: [56],
  //       backgroundColor: "rgba(255, 206, 86, 1)",
  //     },
  //     {
  //       label: "January",
  //       data: [65],
  //       backgroundColor: "rgba(75, 192, 192, 1)",
  //     },
  //     {
  //       label: "February",
  //       data: [59],
  //       backgroundColor: "rgba(153, 102, 255, 1)",
  //     },
  //     {
  //       label: "March",
  //       data: [80],
  //       backgroundColor: "rgba(255, 159, 64, 1)",
  //     },
  //     {
  //       label: "April",
  //       data: [81],
  //       backgroundColor: "rgba(54, 162, 235, 1)",
  //     },
  //     {
  //       label: "May",
  //       data: [56],
  //       backgroundColor: "rgba(255, 206, 86, 1)",
  //     },
  //   ],
  // };

  // Define options for customizing the chart
  const options = {
    indexAxis: "x", // This sets the chart to horizontal orientation
    scales: {
      x: {
        beginAtZero: true, // Start the x-axis at zero
      },
      y: {
        beginAtZero: true, // Start the y-axis at zero
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    barThickness: 20,
    maxBarThickness: 20,
    minBarLength: 2,
  };
  // Bar Chart Data
  const barLineData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Sales",
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.4)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: [565, 459, 680, 881, 756, 565, 459, 680, 881, 756, 545, 782],
      },
    ],
  };

  const barLineOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 16,
          padding: 10,
        },
      },
      title: {
        display: true,
        text: "",
      },
    },
    barThickness: 10,
    maxBarThickness: 20,
    minBarLength: 2,
  };

  // const gaugeValue = 0.75; // gauge value
  // const gaugeValue =
  //   filterBy === "Patient"
  //     ? feedBackData?.orgPatientRating
  //       ? feedBackData.orgPatientRating / 5
  //       : 0
  //     : feedBackData?.orgDoctorRating
  //     ? feedBackData.orgDoctorRating / 5
  //     : 0;

  const gaugeValue =
    filterBy === "Patient"
      ? feedBackData?.orgPatientRating
        ? parseFloat((feedBackData.orgPatientRating / 5).toFixed(2))
        : 0
      : feedBackData?.orgDoctorRating
      ? parseFloat((feedBackData.orgDoctorRating / 5).toFixed(2))
      : 0;

  const labels = [
    <MoodBadOutlined className="emoVeryLow" />,
    <SentimentDissatisfiedOutlined className="emolow" />,
    <SentimentNeutralOutlined className="emonormal" />,
    <SentimentSatisfiedOutlined className="emohigh" />,
    <SentimentVerySatisfiedOutlined className="emoveryHigh" />,
  ];

  const bubbleData = {
    datasets: [
      {
        label: t("feedback"),
        data: mdFeedback.map((entry, index) => ({
          x: index + 1,
          y: entry.count,
          r: entry.count * 2,
          label: entry.label,
        })),
        backgroundColor: "rgba(75, 192, 192, 0.8)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const bubbleOptions = {
    scales: {
      x: {
        beginAtZero: false,
        title: {
          display: false,
          text: "",
        },
        grid: {
          color: "#545454",
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: false,
          text: "",
        },
        grid: {
          color: "#545454",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: t("feedback_bubble_chart"),
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.raw.label || ""; // Access the 'label' property
            const count = context.raw.y; // y corresponds to the count value
            return `${label}: ${count}`;
          },
        },
      },
    },
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["manageFeedBackAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="anDashIndCard manageWidth50 msFeedback">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("feedback")}
              <IconButton
                className={`refreshIcon msFeedbackOne`}
                onClick={() => fetchFeedbackData()}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount cardFrom msFeedbackTwo">
              <FormControl size="small" className="formControl w110 ">
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="filterBy-select"
                  value={filterBy}
                  label=""
                  onChange={handleChangefilterop}
                >
                  <MenuItem value={"Patient"}>{t("nurse_patient")}</MenuItem>
                  <MenuItem value={"Doctor"}>{t("doctor")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="andCrdGraf align-item-center">
            <div className=" anDLftGrf msFeedbackThree">
              <div className="centTxtShow pRelative gaugeChartContainer">
                <GaugeChart
                  className="feedbackRatingChart"
                  nrOfLevels={5}
                  arcsLength={[20, 20, 20, 20, 20]}
                  colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                  percent={gaugeValue}
                  arcPadding={0}
                  cornerRadius={0}
                  needleColor={"#707070"}
                />

                {/* Position Labels Around the Gauge */}
                {/* <div className="spdMeeterLabel">
                  {labels.map((label, index) => {
                    const totalSegments = 5; 
                    const angleStep = 180 / (totalSegments - 1); 
                    const angle = index * angleStep - 90; 
                    const radius = 90; 

                   
                    const x = 100 + radius * Math.cos((angle * Math.PI) / 180);
                    const y = 100 + radius * Math.sin((angle * Math.PI) / 180);

                    return (
                      <div
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${y}px`,
                          left: `${x}px`,
                          transform: "rotate(270deg),translate(-50%, -50%)", 
                          fontSize: "12px", 
                          fontWeight: "normal",
                          color: "#fff", 
                        }}
                      >
                        {label}
                      </div>
                    );
                  })}
                </div> */}
                <div className="gaugeChartLabels">
                  {labels.map((label, index) => (
                    <span
                      key={index}
                      className={`gaugeLabel gaugeLabel${index + 1}`}
                    >
                      {label}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="anDRightGrf msFeedbackFour">
              {/* <Bubble
                type="bubble"
                className="hw100"
                data={data}
                options={options}
              /> */}
              <Bubble
                type="bubble"
                className="hw100"
                data={bubbleData}
                options={bubbleOptions}
              />
              {/* <Bar className="hw100" type="bar" data={data} options={options} /> */}
              {/* <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOptions}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
