import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export const PatientCancelConsultation = ({ closeModal, visitId }) => {
  const { t } = useTranslation();
  const userDetails = GetLoginUserDetails();
  const [cancellationRemark, setCencallationRemark] = useState("");

  const handlePatientLogout = () => {
    if (cancellationRemark.trim() === "") {
      toast.error(t("please_enter_a_reason_for_cancellation"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    DashboardServices.patientLeft(
      visitId,
      userDetails.userId,
      cancellationRemark
    )
      .then((response) => {
        console.log("response", response.data);
        if (response.data === 1) {
          toast.success(t("patient_left_the_center"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          closeModal();
        } else {
          toast.error(t("failed_to_cancel_the_consultation_please_try_again"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(t("an_unexpected_error_occurred_please_try_again_later"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
      <div className="addMoreAgendaModel">
        <div className="modelTitle">{t("patient_leave_center")}</div>

        <div className="elementFormContainer">
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                className="modelTextFild"
                label={`${t("reason")} *`}
                variant="outlined"
                multiline
                rows={4}
                maxRows={7}
                value={cancellationRemark}
                onChange={(e) => {
                  setCencallationRemark(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </div>

        {/* <div className="modAtchFileBtn">
            <label class="btn-up">
              <input type="file" hidden />
              Attach File
            </label>
          </div> */}
        <div className="modActionBtnGrp">
          <Button
            className="dfultPrimaryBtn"
            onClick={() => handlePatientLogout()}
          >
            {t("submit")}
          </Button>
          <Button
            className="dfultDarkSecondaryBtn"
            onClick={() => {
              closeModal();
            }}
          >
            {t("close")}
          </Button>
        </div>
      </div>
    </Box>
  );
};
