import React, { useState, useEffect } from "react";
import DashboardServices from "../../../services/DashboardServices";
// import jwtDecode from "jwt-decode";
import { GetLoginUserDetails } from "../../../utils/Utils";
import { Pagination } from "@mui/material";

const LogoutEvents = () => {
  const userDetails = GetLoginUserDetails();
  const [nextMeetingData, setNextMeetingData] = useState({
    nextEventType: "",
    nextMeetingTitle: "",
    nextMeetingTime: "",
    tasks: [],
  });

  useEffect(() => {
    DashboardServices.getNextMeetingAndPendingTasks(userDetails.userId)
      .then((response) => {
        console.log(response.data);
        setNextMeetingData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userDetails.userId]);

  console.log("nextMeetingData", nextMeetingData);

  // start pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  let currentTasks = nextMeetingData.tasks.slice(startIndex, endIndex);

  return (
    <>
      {/* <ul>
        {nextMeetingData.nextEventType &&
          nextMeetingData.nextMeetingTitle &&
          nextMeetingData.nextMeetingTime && (
            <li>
              You have {nextMeetingData.nextEventType} for{" "}
              {nextMeetingData.nextMeetingTitle} at{" "}
              {nextMeetingData.nextMeetingTime}
            </li>
          )}
        {nextMeetingData.tasks.map((value, index) => (
          <li key={index}>
            You have {value.taskName} for {value.priority} at{" "}
            {value.scheduleCompletionTime}
          </li>
        ))}
      </ul> */}

      <div>
        <ul>
          {currentPage === 1 &&
            nextMeetingData.nextEventType &&
            nextMeetingData.nextMeetingTitle &&
            nextMeetingData.nextMeetingTime && (
              <li>
                Meeting {nextMeetingData.nextEventType} for{" "}
                {nextMeetingData.nextMeetingTitle} at{" "}
                {nextMeetingData.nextMeetingTime}
              </li>
            )}
          {currentTasks.map((value, index) => (
            <li key={index}>
              Task {value.taskName} for {value.priority} at{" "}
              {value.scheduleCompletionTime}
            </li>
          ))}
        </ul>
        {/* <Pagination
          count={Math.ceil(nextMeetingData.tasks.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
        /> */}
      </div>
    </>
  );
};

export default LogoutEvents;
