import {
  Add,
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  CalendarMonthRounded,
  Search,
  StarRounded,
  TodayRounded,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Diagram3, Eye, SortDown, SortUp } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  clearTaskModalData,
  setTaskModalData,
  setTaskTabList,
} from "../../../redux/reducers/taskTab";
import DashboardServices from "../../../services/DashboardServices";
import { createEvent } from "../../../services/SchedulerService";
import TaskService from "../../../services/TaskService";
import { GetLoginUserDetails } from "../../../utils/Utils";
import RightSideTaskTabList from "../../dashboard/view/component/right-panel/RightSideTaskTabList";
import { AddTabContext } from "../../dashboard/view/DashboardPage";
import CreateConvenientMeetingWrapper from "./component/Modals/CreateConvenientMeetingWrapper";
import RightSideAddressConcern from "./component/Modals/RightSideAddressConcern";
import RightSideApproveModal from "./component/Modals/RightSideApproveModal";
import RightSideCloseTask from "./component/Modals/RightSideCloseTask";
import { RightSideCommonModal } from "./component/Modals/RightSideCommonModal";
import RightSideDelegate from "./component/Modals/RightSideDelegate";
import RightSideDisagree from "./component/Modals/RightSideDisagree";
import RightSideMultipleConcern from "./component/Modals/RightSideMultipleConcern";
import RightSideMultipleUpdate from "./component/Modals/RightSideMultipleUpdate";
import RightSidePostpone from "./component/Modals/RightSidePostpone";
import { RightSideReassign } from "./component/Modals/RightSideReassign";
import RightSideRejectRequest from "./component/Modals/RightSideRejectRequest";
import RightSideSignOff from "./component/Modals/RightSideSignOff";
import RightSideTaskForward from "./component/Modals/RightSideTaskForward";
import CreateTaskContainer from "./CreateTaskContainer";
import Joyride from "react-joyride";
import { TourContext } from "../../../common/TourProvider";

export const taskTabContext = createContext();

export default function TaskTabContainer() {
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const { addTab } = useContext(AddTabContext);
  const { t } = useTranslation();
  const loginUserDetail = GetLoginUserDetails();
  const [taskList, setTaskList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [showMultipleUpdate, setShowMultipleUpdate] = useState(false);
  const [showMultipleConcern, setShowMultipleConcern] = useState(false);
  const [rightModalName, setRightModalName] = useState(null);
  const [uiButtonMap, setUiButtonMap] = useState(new Map());
  const [highlighted, setHighlited] = useState([]);

  /*---------------------  convenient meeting start --------------------- */

  const [showConvenientMeetingView, setShowConvenientMeetingView] =
    useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentWeek, setCurrentWeek] = React.useState([]);
  const [slotDuration, setSlotDuration] = React.useState(15);
  const [startTime, setStartTime] = React.useState(0); //0 for 12:00 AM
  const [endTime, setEndTime] = React.useState(24); //23 for 11:00 PM
  const [rows, setRows] = React.useState([
    ...Array((endTime - startTime) * (60 / slotDuration)).keys(),
  ]); //23 for 11:00 PM
  const [cols, setCols] = React.useState([...Array(currentWeek.length).keys()]); //23 for 11:00 PM
  const [weekStartDay, setWeekStartDay] = React.useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const [selectedConvenientTime, setSelectedConvenientTime] = useState(null);
  const [hideForm, setHideForm] = useState(false);

  const [descendentList, setDescendentList] = useState([]);
  const [selectedDescendent, setSelectedDescendent] = useState("");
  const [selectedEvaluatorStatus, setSelectedEvaluatorStatus] = useState("");
  const [categoryWiseData, setCategoryWiseData] = useState(null);

  useEffect(() => {
    fetchDecendent();

    const selectedDate = new Date();

    let day = selectedDate.getDay();
    let diff = null;
    if (weekStartDay > day) {
      diff =
        selectedDate.getDate() - day - (day === 10 ? -6 : 7 - weekStartDay);
    } else {
      diff = selectedDate.getDate() - day + (day === 10 ? -6 : weekStartDay);
    }
    let currentWeekTemp2 = [];
    let tempDate = new Date(new Date());
    currentWeekTemp2.push(new Date(tempDate.setDate(diff)));
    for (let counter = 0; counter < 6; counter++) {
      var date = new Date(currentWeekTemp2[counter]);
      date.setDate(currentWeekTemp2[counter].getDate() + 1);
      currentWeekTemp2.push(date);
    }

    setCurrentWeek(currentWeekTemp2);
    setCols([...Array(currentWeekTemp2.length).keys()]);

    return () => {
      dispatch(clearTaskModalData());
    };
  }, []);

  const fetchDecendent = () => {
    DashboardServices.getDescendantsUsers(loginUserDetail.userId).then(
      (response) => {
        if (response.data) {
          setDescendentList(response.data);
          const currentDescendent = response.data.find(
            (item) => item.userId === loginUserDetail.userId
          );
          if (currentDescendent) {
            setSelectedDescendent(currentDescendent);
          }
        }
      }
    );
  };

  const calculateTimeDifference = (startTime, endTime) => {
    // Assuming startTime and endTime are time strings in the format "hh:mm A"
    const format = " YYYY-MM-DD hh:mm A";
    const startDateTime = dayjs("2024-01-01 " + startTime, { format });
    const endDateTime = dayjs("2024-01-01 " + endTime, { format });

    // Calculate the time difference using dayjs().diff()
    const duration = endDateTime.diff(startDateTime, "minute"); // Specify 'minute' here

    return duration;
  };

  useEffect(() => {
    if (selectedConvenientTime) {
      handleCreateConvenientMeeting();
    }
  }, [selectedConvenientTime]);

  const handleCreateConvenientMeeting = () => {
    const reqDto = {
      title: "Convenient Meeting",
      description: "",
      length: calculateTimeDifference(
        selectedConvenientTime.convFromTime,
        selectedConvenientTime.convToTime
      ),
      startTime: new Date(
        new Date(selectedConvenientTime.convStartDate).toDateString() +
          " " +
          selectedConvenientTime.convFromTime
      ),
      endTime: new Date(
        new Date(selectedConvenientTime.convStartDate).toDateString() +
          " " +
          selectedConvenientTime.convToTime
      ),
      location: " ",
      participants: selectedUsers.map((user, index) => {
        return {
          userId: user.userId,
          userName: user.userName,
          convener: index === 0 ? "Y" : "N",
        };
      }),
    };

    createEvent(loginUserDetail.orgId, loginUserDetail.userId, reqDto).then(
      (response) => {
        if (response.data.returnValue === "1") {
          const status = response.data.status;
          TaskService.addEventToTask(914, status, loginUserDetail.userId).then(
            (res) => {
              if (res.data == 1) {
                toast.success(t("meeting_created_successfully"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error(t("meeting_could_not_be_created"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          );
        } else {
          toast.error(t("meeting_could_not_be_created"));
        }
      }
    );
  };

  /*   ------------------------- convenient meeting ends ---------------------------    */

  function intersectionOfLists(lists) {
    if (lists.length === 0) {
      return [];
    }

    // Start with the elements of the first list
    const result = [...lists[0]];

    // Iterate through each list
    for (const list of lists.slice(1)) {
      // Keep only the elements that are present in both lists
      const intersection = result.filter((value) => list.includes(value));
      result.length = 0;
      result.push(...intersection);
    }

    return result;
  }

  const shouldCheck = () => {
    let render = true;
    if (showMultipleUpdate || showMultipleConcern) {
      render = false;
    }
    if (rightModalName) {
      render = false;
    }
    return render;
  };

  const commonButtons = useMemo(() => {
    if (selectedTasks && selectedTasks.length === 0) {
      return [];
    }
    const ui = selectedTasks.map((task) => task.taskUIComponent);
    const uiSet = Array.from(new Set(ui));
    const buttonLists = uiSet.map((item) => uiButtonMap.get(item));

    const singleSet = intersectionOfLists(buttonLists);
    return singleSet;
  }, [selectedTasks, uiButtonMap]);

  const selectedTask = useSelector(
    (state) => state.taskList.taskRightModalData
  );

  const filteredTaskList = useMemo(() => {
    let tempTaskList = taskList;
    if (selectedEvaluatorStatus) {
      tempTaskList = taskList?.filter(
        (item) => item.taskEvaluatorStatus === selectedEvaluatorStatus
      );
    }

    if (searchString.trim() === "") {
      return tempTaskList;
    }

    return tempTaskList.filter((task) => {
      const formattedCompletionTime = dayjs(task.completionTime).format(
        "DD-MM-YY"
      );

      return (
        (task.taskName &&
          task.taskName.toLowerCase().includes(searchString.toLowerCase())) ||
        (task.taskDescription &&
          task.taskDescription
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (task.taskType &&
          task.taskType.toLowerCase().includes(searchString.toLowerCase())) ||
        (task.contractName &&
          task.contractName
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (task.progress && task.progress.toString().includes(searchString)) ||
        (formattedCompletionTime &&
          formattedCompletionTime.includes(searchString)) ||
        (task.createdByUserName &&
          task.createdByUserName
            .toLowerCase()
            .includes(searchString.toLowerCase()))
      );
    });
  }, [taskList, searchString, selectedEvaluatorStatus]);

  // const filteredTaskList = useMemo(() => {
  //   if (searchString.trim() === "") {
  //     return taskList;
  //   }
  //   const value = taskList.filter((task) =>
  //     task.taskName.toLowerCase().includes(searchString.toLowerCase())
  //   );
  //   return value;
  // }, [taskList, searchString]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...taskList].sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (aValue == null || bValue == null) return 0;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return direction === "ascending"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return direction === "ascending" ? aValue - bValue : bValue - aValue;
      }

      if (dayjs(aValue).isValid() && dayjs(bValue).isValid()) {
        return direction === "ascending"
          ? dayjs(aValue).diff(dayjs(bValue))
          : dayjs(bValue).diff(dayjs(aValue));
      }

      return 0; // Default if types don't match
    });

    setTaskList(sortedData);
  };

  const handleChangeInputRef = (e) => {
    setSearchString(e.target.value);
  };

  const openCreateTaskTab = () => {
    addTab({
      key: uuidv4(),
      title: "Create Task",
      content: <CreateTaskContainer />,
      isDraggable: true,
    });
  };
  const handleAllCategory = (data) => {
    const tempAllCategory = data.reduce((acc, curr) => {
      const { taskEvaluatorStatus } = curr;
      if (!acc[taskEvaluatorStatus]) {
        acc[taskEvaluatorStatus] = [curr];
      } else {
        acc[taskEvaluatorStatus].push(curr);
      }
      return acc;
    }, {});
    setCategoryWiseData(tempAllCategory);
  };
  const fetchTasks = async (user) => {
    let response;
    if (user.userId === loginUserDetail.userId) {
      response = await DashboardServices.getTasks(loginUserDetail.userId);
    } else {
      response = await TaskService.getOtherTasks(user.userId);
    }
    if (response.data) {
      const value = response.data.map((item) => ({
        ...item,
        checked: false,
      }));
      handleAllCategory(response.data);
      setTaskList(value);
      dispatch(setTaskTabList(response.data));
    } else {
      toast.error(t("something_went_wrong"));
    }
  };

  const refreshData = () => {
    fetchTasks(selectedDescendent);
    setSelectedTasks([]);
    setShowMultipleConcern(false);
    setShowMultipleUpdate(false);
  };

  useEffect(() => {
    uiButtonMap.set("TaskTabApprovalRequest", [
      "approve",
      "postpone",
      "reject",
      "delegate",
      "concern",
    ]);
    uiButtonMap.set("TaskTabCancelApprovalRequest", ["cancel_task"]);
    uiButtonMap.set("TaskTabSignOffApprovalRequest", ["sign_off", "forward"]);
    uiButtonMap.set("TaskTabIncomingOngoing", [
      "update",
      "delegate",
      "concern",
    ]);
    uiButtonMap.set("TaskTabOutgoingOngoing", ["update", "reassign"]);
    uiButtonMap.set("TaskTabSignOffCompleted", [
      "sign_off",
      "disagree",
      "reassign",
    ]);
    uiButtonMap.set("TaskTabReopenPostponed", [
      "resume_on",
      "reopen",
      "cancel_task",
    ]);
    uiButtonMap.set("TaskTabCancelRejected", ["cancel_task"]);
    uiButtonMap.set("TaskTabAddressConcern", ["address_concern", "reassign"]);
    uiButtonMap.set("TaskTabClosed", ["sign_off", "disagree", "reassign"]);
    uiButtonMap.set("TaskTabCompletedApprovalRequest", []);
    uiButtonMap.set("TaskTabCompleted", []);

    fetchTasks(loginUserDetail);
  }, []);

  const handleTadkListItemClick = async (task) => {
    //
    const taskNature = task?.taskNature;
    if (
      taskNature === "CC_APPROVED_EXPENDITURE" ||
      taskNature === "CC_APPROVED_EXPENDITURE_WITH_CHANGES"
    ) {
      const values = await DashboardServices.getCCDataOfApprovalOfExpenditure(
        loginUserDetail.userId,
        task.taskId,
        taskNature,
        task.taskFormDataTransId
      );
      //

      dispatch(setTaskModalData({ ...task, ...values.data }));
    } else if (taskNature === null) {
      const values = await DashboardServices.getTaskById(task.taskId);
      dispatch(setTaskModalData({ ...task, ...values.data }));
    } else {
      const values = await DashboardServices.getTaskById(task.taskId);
      dispatch(setTaskModalData({ ...task, ...values.data }));
    }
    // setSelectedTask(task.taskId);
    setShowRightPanel(true);
  };

  const calculateHighlited = (task, taskList, mode) => {
    const taskUi = task.taskUIComponent;
    const tempSet = new Set();
    let highlitedTempList = [];
    taskList.forEach((item) => {
      tempSet.add(item.taskUIComponent);
    });
    tempSet.forEach((item) => {
      const tmp = filteredTaskList.filter(
        (ele) => ele.taskUIComponent === item
      );
      if (tmp) {
        highlitedTempList = [...highlitedTempList, ...tmp];
      }
    });

    setHighlited(highlitedTempList);
  };

  const isHighlited = (taskId) => {
    const temp = highlighted.find((item) => item.taskId === taskId);
    return temp ? true : false;
  };

  const renderAppropriateIcon = (iconType) => {
    if (iconType === "incoming") {
      return (
        <>
          <div className="incomTask ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.6524 1.78286V2.77714C14.6524 3.76286 13.8525 4.56 12.874 4.56H8.12602C7.14749 4.56 6.34765 3.76286 6.34765 2.77714V1.78286C6.34765 0.797143 7.14749 0 8.12602 0H12.874C13.8525 0 14.6524 0.797143 14.6524 1.78286ZM17.5964 2.28H15.9287V2.77714C15.9287 4.46571 14.5588 5.84571 12.874 5.84571H8.12602C6.44125 5.84571 5.07131 4.46571 5.07131 2.77714V2.28H3.40357C1.5231 2.28 0 3.81429 0 5.70857V20.5714C0 22.4657 1.5231 24 3.40357 24H17.5964C19.4769 24 21 22.4657 21 20.5714V5.70857C21 3.81429 19.4769 2.28 17.5964 2.28ZM14.2061 15.2281L10.8055 18.614C10.6492 18.7694 10.4375 18.8567 10.2166 18.8567C10.2098 18.8572 10.2029 18.8572 10.196 18.8567C10.1891 18.8573 10.1822 18.8573 10.1753 18.8567C10.0659 18.8571 9.95754 18.8358 9.85647 18.7942C9.75539 18.7525 9.66363 18.6913 9.5865 18.614L6.18583 15.2281C6.03605 15.0714 5.95368 14.8627 5.9563 14.6465C5.95892 14.4302 6.04632 14.2235 6.19985 14.0705C6.35338 13.9175 6.56088 13.8303 6.77808 13.8275C6.99528 13.8247 7.20498 13.9066 7.36243 14.0556L9.35717 16.0386V9.40042C9.35717 9.18056 9.4449 8.9697 9.60104 8.81424C9.75718 8.65877 9.96896 8.57143 10.1898 8.57143C10.4106 8.57143 10.6224 8.65877 10.7785 8.81424C10.9347 8.9697 11.0224 9.18056 11.0224 9.40042V16.0571L13.0295 14.0556C13.1061 13.9754 13.1981 13.9114 13.2999 13.8672C13.4017 13.823 13.5114 13.7996 13.6225 13.7982C13.7336 13.7969 13.8438 13.8177 13.9467 13.8595C14.0496 13.9012 14.143 13.963 14.2216 14.0412C14.3001 14.1195 14.3621 14.2126 14.4039 14.3151C14.4457 14.4175 14.4665 14.5273 14.4651 14.6379C14.4637 14.7485 14.44 14.8577 14.3955 14.9591C14.3511 15.0604 14.2867 15.1519 14.2061 15.2281Z"
                fill="white"
              />
            </svg>
          </div>
        </>
      );
    } else if (iconType === "outgoing") {
      return (
        <>
          <div className="outGoingTask ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.6524 1.78286V2.77714C14.6524 3.76286 13.8525 4.56 12.874 4.56H8.12602C7.14749 4.56 6.34765 3.76286 6.34765 2.77714V1.78286C6.34765 0.797143 7.14749 0 8.12602 0H12.874C13.8525 0 14.6524 0.797143 14.6524 1.78286ZM17.5964 2.28H15.9287V2.77714C15.9287 4.46571 14.5588 5.84571 12.874 5.84571H8.12602C6.44125 5.84571 5.07131 4.46571 5.07131 2.77714V2.28H3.40357C1.5231 2.28 0 3.81429 0 5.70857V20.5714C0 22.4657 1.5231 24 3.40357 24H17.5964C19.4769 24 21 22.4657 21 20.5714V5.70857C21 3.81429 19.4769 2.28 17.5964 2.28ZM6.21527 12.2005L9.61594 8.81457C9.77218 8.65921 9.98395 8.57191 10.2048 8.57184C10.2116 8.57135 10.2185 8.57135 10.2254 8.57184C10.2323 8.57129 10.2392 8.57129 10.2461 8.57184C10.3555 8.57148 10.4639 8.59275 10.5649 8.63442C10.666 8.67608 10.7578 8.73732 10.8349 8.81457L14.2356 12.2005C14.3854 12.3571 14.4677 12.5658 14.4651 12.7821C14.4625 12.9984 14.3751 13.205 14.2216 13.358C14.068 13.511 13.8605 13.5982 13.6433 13.601C13.4261 13.6038 13.2164 13.522 13.059 13.373L11.0642 11.39V18.0281C11.0642 18.248 10.9765 18.4589 10.8204 18.6143C10.6642 18.7698 10.4524 18.8571 10.2316 18.8571C10.0108 18.8571 9.79902 18.7698 9.64288 18.6143C9.48673 18.4589 9.39901 18.248 9.39901 18.0281V11.3715L7.39187 13.373C7.31527 13.4531 7.22334 13.5172 7.1215 13.5614C7.01966 13.6055 6.90997 13.629 6.79889 13.6303C6.68781 13.6317 6.57758 13.6109 6.4747 13.5691C6.37182 13.5274 6.27837 13.4656 6.19985 13.3873C6.12133 13.3091 6.05933 13.216 6.01751 13.1135C5.97568 13.011 5.95487 12.9013 5.95631 12.7907C5.95775 12.6801 5.9814 12.5709 6.02587 12.4695C6.07035 12.3682 6.13474 12.2767 6.21527 12.2005Z"
                fill="white"
              />
            </svg>
          </div>
        </>
      );
    } else if (iconType === "observer") {
      return (
        <>
          <div className="observerIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5592 2.38041V1.52816C12.5592 0.683265 11.8736 0 11.0349 0H6.96516C6.12642 0 5.44084 0.683265 5.44084 1.52816V2.38041C5.44084 3.22531 6.12642 3.90857 6.96516 3.90857H11.0349C11.8736 3.90857 12.5592 3.22531 12.5592 2.38041ZM13.6532 1.95429H15.0827C16.6945 1.95429 18 3.26939 18 4.89306V17.6327C18 19.2563 16.6945 20.5714 15.0827 20.5714H2.91734C1.30551 20.5714 0 19.2563 0 17.6327V4.89306C0 3.26939 1.30551 1.95429 2.91734 1.95429H4.34684V2.38041C4.34684 3.82775 5.52107 5.01061 6.96516 5.01061H11.0349C12.4789 5.01061 13.6532 3.82775 13.6532 2.38041V1.95429Z"
                fill="white"
              />
              <path
                d="M5.08194 13.648C4.65694 13.096 4 12.8195 4 12C4 11.18 4.65694 10.9045 5.08194 10.352C5.93044 9.25 7.35344 8 9.44444 8C11.5354 8 12.9584 9.25 13.8069 10.352C14.2319 10.905 14.6667 11.1805 14.6667 12C14.6667 12.82 14.2319 13.0955 13.8069 13.648C12.9584 14.75 11.5354 16 9.44444 16C7.35344 16 5.93044 14.75 5.08194 13.648Z"
                stroke="black"
                stroke-width="0.8"
              />
              <path
                d="M10.9445 12C10.9445 12.3978 10.7864 12.7794 10.5051 13.0607C10.2238 13.342 9.84228 13.5 9.44446 13.5C9.04663 13.5 8.6651 13.342 8.3838 13.0607C8.10249 12.7794 7.94446 12.3978 7.94446 12C7.94446 11.6022 8.10249 11.2206 8.3838 10.9393C8.6651 10.658 9.04663 10.5 9.44446 10.5C9.84228 10.5 10.2238 10.658 10.5051 10.9393C10.7864 11.2206 10.9445 11.6022 10.9445 12Z"
                stroke="black"
                stroke-width="0.8"
              />
            </svg>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const handleCategory = useCallback(
    (data) => {
      if (selectedEvaluatorStatus === data) {
        setSelectedEvaluatorStatus("");
      } else {
        setSelectedEvaluatorStatus(data);
      }
    },
    [selectedEvaluatorStatus]
  );

  // const handleCategory = (data) => {
  //   if (selectedEvaluatorStatus === data) {
  //     setSelectedEvaluatorStatus("");
  //   }
  //   setSelectedEvaluatorStatus(data);
  // };
  // useEffect(()=>{
  //  setBookSteps([
  //   {
  //     target: ".taskTabContainerStepOne",
  //     content:
  //       "This section displays Task Of the User",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepTwo",
  //     content:
  //       "Here You can Search task By enter The Task Name",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepThree",
  //     content:
  //       "Here you can See whose Task is Displaying",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepFour",
  //     content:
  //       "From Here you can Create a new Task",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepFive",
  //     content: "This is the task list container where you can manage tasks.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepSeven",
  //     content:
  //       "Here you can see all the Task Name",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepEight",
  //     content:
  //       "Here you can see all the Task Description",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepNine",
  //     content:
  //       "Here you can see all the Task Type",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepTen",
  //     content:
  //       "Here you can see all the Contract",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepTen",
  //     content:
  //       "Here you can see all the Task Progress",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepEleven",
  //     content:
  //       "Each task has a priority status: Routine, Important, or Critical.",
  //       disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepTwelve",
  //     content: "Here you can see the task's completion date and time.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".taskTabContainerStepThirteen",
  //     content: "This column shows who created or assigned the task.",
  //     disableBeacon: true,
  //   },
  // ])
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".taskTabContainerStepOne",
        content: "This section displays the user's tasks.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepTwo",
        content: "Here, you can search for tasks by entering the task name.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepThree",
        content: "Here, you can see whose task is being displayed.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepFour",
        content: "From here, you can create a new task.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepFive",
        content: "This is the task list container where you can manage tasks.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepSeven",
        content: "Here, you can see the task names.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepEight",
        content: "Here, you can see the task descriptions.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepNine",
        content: "Here, you can see the task types.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepTen",
        content: "Here, you can see the contracts.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepTen", // This appears twice, consider updating it
        content: "Here, you can see the task's progress.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepEleven",
        content:
          "Each task has a priority status: Routine, Important, or Critical.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepTwelve",
        content: "Here, you can see the task's completion date and time.",
        disableBeacon: true,
      },
      {
        target: ".taskTabContainerStepThirteen",
        content: "This column shows who created or assigned the task.",
        disableBeacon: true,
      },
    ]);
  }, []);

  console.log("Task Tour", run["TaskDetailsAppAs"], run);

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["TaskDetailsAppAs"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <taskTabContext.Provider
        value={{
          setShowConvenientMeetingView,
          setSelectedUsers,
        }}
      >
        <Box className="taskContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
          <Grid container spacing={0} className="taskContainArea">
            <Grid item xs={12} className="pRelative ">
              {!showConvenientMeetingView && (
                <div className="taskContainer taskTabContainerStepOne">
                  <div className="taskListHeader">{t("my_task")}</div>

                  <div className="taskListTopBtnGroup">
                    <div className="taskListTopBtnLeft">
                      <div className="searchTasklist taskTabContainerStepTwo">
                        <input
                          type="text"
                          class="serchTaskInput "
                          value={searchString}
                          placeholder={t("search_or_find_task")}
                          onChange={handleChangeInputRef}
                        />
                        <IconButton className="seacrhIcon">
                          <Search />
                        </IconButton>
                      </div>
                      <div className="formElement searchTasklist">
                        <FormControl className="formControl">
                          <InputLabel id="selectPriority">
                            {t("task_of")}
                          </InputLabel>
                          <Select
                            className="formInputField taskTabContainerStepThree"
                            variant="outlined"
                            labelId="selectPriority"
                            id="priority-select"
                            value={selectedDescendent}
                            label={t("task_of")}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSelectedDescendent(e.target.value);
                              fetchTasks(e.target.value);
                              setSelectedTasks([]);
                              setHighlited([]);
                            }}
                          >
                            {descendentList?.map((item) => {
                              const userDesignation =
                                item.userId === loginUserDetail.userId
                                  ? "(Self)"
                                  : `(${item.userDtl})`;
                              return (
                                <MenuItem value={item}>
                                  {`${item.userName} ${userDesignation}`}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      {/* <IconButton className="filterTaskListBtn">
                        <Tune />
                      </IconButton> */}
                    </div>
                    <div className="taskListTopBtnRight">
                      {commonButtons &&
                        selectedTasks &&
                        selectedTasks.length > 1 &&
                        commonButtons.map((button) => {
                          if (button === "reassign") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("reassign");
                                  }
                                }}
                              >
                                {t("reassign")}
                              </Button>
                            );
                          }
                          // else if (button === "delegate") {
                          //   return (
                          //     <Button
                          //       className="dfultDarkSecondaryBtn"
                          //       // startIcon={<ShortcutOutlined />}
                          //     >
                          //       Delegate
                          //     </Button>
                          //   );
                          // }
                          else if (button === "update") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setShowMultipleUpdate(true);
                                  }
                                }}
                              >
                                {t("update")}
                              </Button>
                            );
                          } else if (button === "cancel_task") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setRightModalName("cancel_task");
                                    // setShowMultipleConcern(true);
                                  }
                                }}
                              >
                                {t("cancel")}
                              </Button>
                            );
                          } else if (button === "concern") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setShowMultipleConcern(true);
                                  }
                                }}
                              >
                                {t("concern")}
                              </Button>
                            );
                          }
                          // else if (button === "link_task") {
                          //   return (
                          //     <Button
                          //       className="dfultDarkSecondaryBtn"
                          //       // startIcon={<ShortcutOutlined />}
                          //     >
                          //       Link Task
                          //     </Button>
                          //   );
                          // }
                          else if (button === "approve") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  // if (selectedTasks.length === 1) {
                                  //   handleTadkListItemClick(selectedTasks[0]);
                                  // } else {
                                  //   // setShowMultipleConcern(true);
                                  // }
                                  setRightModalName("approve");
                                }}
                              >
                                {t("approve")}
                              </Button>
                            );
                          } else if (button === "postpone") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("postpone");
                                  }
                                }}
                              >
                                {t("postpone")}
                              </Button>
                            );
                          } else if (button === "reject") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setRightModalName("reject");
                                    // setShowMultipleConcern(true);
                                  }
                                }}
                              >
                                {t("reject")}
                              </Button>
                            );
                          } else if (button === "delegate") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}

                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("delegate");
                                  }
                                }}
                              >
                                {t("delegate")}
                              </Button>
                            );
                          } else if (button === "sign_off") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("signOff");
                                  }
                                }}
                              >
                                {t("sign_off")}
                              </Button>
                            );
                          } else if (button === "disagree") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("disagree");
                                  }
                                }}
                              >
                                {t("disagree")}
                              </Button>
                            );
                          } else if (button === "forward") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}

                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("forward");
                                  }
                                }}
                              >
                                {t("forward")}
                              </Button>
                            );
                          } else if (button === "address_concern") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setRightModalName("address_concern");
                                    // setShowMultipleConcern(true);
                                  }
                                }}
                              >
                                {t("address_concern")}
                              </Button>
                            );
                          }
                        })}
                      {/* <Button
                    disabled
                    className="dfultDarkSecondaryBtn"
                    startIcon={<CancelOutlined />}
                  >
                    Raise Concern
                  </Button>

                  <Button
                    disabled
                    className="dfultDarkSecondaryBtn"
                    startIcon={<CheckCircle />}
                  >
                    Update
                  </Button> */}

                      <Button
                        className="dfultPrimaryBtn taskTabContainerStepFour"
                        startIcon={<Add />}
                        onClick={() => {
                          openCreateTaskTab();
                          if (run["TaskDetailsAppAs"] === true) {
                            setRun({
                              addTaskTS: true,
                            });
                          }
                        }}
                      >
                        {t("create_task")}
                      </Button>
                    </div>
                  </div>

                  <div className="taskListTableContainer taskTabContainerStepFive">
                    <table className="taskListTable">
                      <thead className="taskListtableHeader">
                        <tr>
                          <th className="t-w38">
                            {/* <Checkbox
                          {...label}
                          value={selectAllChecked}
                          onChange={(e) => {
                            const chk = e.target.checked;
                            setSelectAllChecked(e.target.checked);
                            if(chk){

                            }
                          }}
                        /> */}
                          </th>
                          <th>
                            <div
                              className="tskFilter"
                              onClick={() => handleSort("taskName")}
                            >
                              <div className="tfhead">{t("task")}</div>
                              <div className="acnDcnIcon">
                                {sortConfig.key === "taskName" && (
                                  <span>
                                    {sortConfig.direction === "ascending" ? (
                                      <IconButton className="filterActive">
                                        <SortDown />
                                      </IconButton>
                                    ) : (
                                      <IconButton className="filterActive">
                                        <SortUp />
                                      </IconButton>
                                    )}
                                  </span>
                                )}
                              </div>
                              {/* <div className="acnDcnIcon">
                                <IconButton className="filterActive">
                                  <SortDown />
                                </IconButton>
                                <IconButton className="">
                                  <SortUp />
                                </IconButton>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div
                              className="tskFilter"
                              onClick={() => handleSort("taskDescription")}
                            >
                              <div className="tfhead">{t("description")}</div>
                              {/* <div className="acnDcnIcon"> */}
                              <div className="acnDcnIcon">
                                {sortConfig.key === "taskDescription" && (
                                  <span>
                                    {sortConfig.direction === "ascending" ? (
                                      <IconButton className="filterActive">
                                        <SortDown />
                                      </IconButton>
                                    ) : (
                                      <IconButton className="filterActive">
                                        <SortUp />
                                      </IconButton>
                                    )}
                                  </span>
                                )}
                              </div>

                              {/* <IconButton className="filterActive">
                                  <SortDown />
                                </IconButton>
                                <IconButton className="">
                                  <SortUp />
                                </IconButton> */}
                              {/* </div> */}
                            </div>
                          </th>
                          <th>
                            <div
                              className="tskFilter"
                              onClick={() => handleSort("taskType")}
                            >
                              <div className="tfhead">{t("type")}</div>
                              <div className="acnDcnIcon">
                                {sortConfig.key === "taskType" && (
                                  <span>
                                    {sortConfig.direction === "ascending" ? (
                                      <IconButton className="filterActive">
                                        <SortDown />
                                      </IconButton>
                                    ) : (
                                      <IconButton className="filterActive">
                                        <SortUp />
                                      </IconButton>
                                    )}
                                  </span>
                                )}
                              </div>
                              {/* <div className="acnDcnIcon">
                                <IconButton className="filterActive">
                                  <SortDown />
                                </IconButton>
                                <IconButton className="">
                                  <SortUp />
                                </IconButton>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div
                              className="tskFilter"
                              onClick={() => handleSort("contractName")}
                            >
                              <div className="tfhead">{t("contract")}</div>
                              <div className="acnDcnIcon">
                                {sortConfig.key === "contractName" && (
                                  <span>
                                    {sortConfig.direction === "ascending" ? (
                                      <IconButton className="filterActive">
                                        <SortDown />
                                      </IconButton>
                                    ) : (
                                      <IconButton className="filterActive">
                                        <SortUp />
                                      </IconButton>
                                    )}
                                  </span>
                                )}
                              </div>
                              {/* <div className="acnDcnIcon">
                                <IconButton className="filterActive">
                                  <SortDown />
                                </IconButton>
                                <IconButton className="">
                                  <SortUp />
                                </IconButton>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div
                              className="tskFilter"
                              onClick={() => handleSort("progress")}
                            >
                              <div className="tfhead">{t("status")}</div>
                              <div className="acnDcnIcon">
                                {sortConfig.key === "progress" && (
                                  <span>
                                    {sortConfig.direction === "ascending" ? (
                                      <IconButton className="filterActive">
                                        <SortDown />
                                      </IconButton>
                                    ) : (
                                      <IconButton className="filterActive">
                                        <SortUp />
                                      </IconButton>
                                    )}
                                  </span>
                                )}
                              </div>
                              {/* <div className="acnDcnIcon">
                                <IconButton className="filterActive">
                                  <SortDown />
                                </IconButton>
                                <IconButton className="">
                                  <SortUp />
                                </IconButton>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div
                              className="tskFilter"
                              onClick={() => handleSort("priority")}
                            >
                              <div className="tfhead">{t("priority")}</div>
                              <div className="acnDcnIcon">
                                {sortConfig.key === "priority" && (
                                  <span>
                                    {sortConfig.direction === "ascending" ? (
                                      <IconButton className="filterActive">
                                        <SortDown />
                                      </IconButton>
                                    ) : (
                                      <IconButton className="filterActive">
                                        <SortUp />
                                      </IconButton>
                                    )}
                                  </span>
                                )}
                              </div>
                              {/* <div className="acnDcnIcon">
                                <IconButton className="filterActive">
                                  <SortDown />
                                </IconButton>
                                <IconButton className="">
                                  <SortUp />
                                </IconButton>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div
                              className="tskFilter"
                              onClick={() => handleSort("completionTime")}
                            >
                              <div className="tfhead">
                                {t("completion_date")}
                              </div>
                              <div className="acnDcnIcon">
                                {sortConfig.key === "completionTime" && (
                                  <span>
                                    {sortConfig.direction === "ascending" ? (
                                      <IconButton className="filterActive">
                                        <SortDown />
                                      </IconButton>
                                    ) : (
                                      <IconButton className="filterActive">
                                        <SortUp />
                                      </IconButton>
                                    )}
                                  </span>
                                )}
                              </div>
                              {/* <div className="acnDcnIcon">
                                <IconButton className="filterActive">
                                  <SortDown />
                                </IconButton>
                                <IconButton className="">
                                  <SortUp />
                                </IconButton>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div
                              className="tskFilter"
                              onClick={() => handleSort("createdByUserName")}
                            >
                              <div className="tfhead">
                                {t("created_by_assigned_by")}
                              </div>
                              <div className="acnDcnIcon">
                                {sortConfig.key === "createdByUserName" && (
                                  <span>
                                    {sortConfig.direction === "ascending" ? (
                                      <IconButton className="filterActive">
                                        <SortDown />
                                      </IconButton>
                                    ) : (
                                      <IconButton className="filterActive">
                                        <SortUp />
                                      </IconButton>
                                    )}
                                  </span>
                                )}
                              </div>
                              {/* <div className="acnDcnIcon">
                                <IconButton className="filterActive">
                                  <SortDown />
                                </IconButton>
                                <IconButton className="">
                                  <SortUp />
                                </IconButton>
                              </div> */}
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="scrolableTableBody">
                        {filteredTaskList &&
                          filteredTaskList.length > 0 &&
                          filteredTaskList.map((task, index) => (
                            <tr
                              className={`${
                                selectedTask &&
                                selectedTask.taskId === task.taskId
                                  ? "trowSelected"
                                  : isHighlited(task.taskId)
                                  ? "highlitedTask"
                                  : ""
                              } `}
                              onClick={(e) => handleTadkListItemClick(task)}
                            >
                              <td
                                className="t-w38"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {task.taskUIComponent !== "TaskTabObserver" &&
                                  filteredTaskList.length > 1 && (
                                    <Checkbox
                                      checked={task.checked}
                                      onChange={(e) => {
                                        if (!shouldCheck()) {
                                          toast.error(
                                            "Can't Add Items With Container Opened. Close To Proceed."
                                          );
                                          return;
                                        }

                                        const temp = taskList;
                                        temp[index].checked = e.target.checked;
                                        setTaskList(temp);
                                        const alreadySelected = [
                                          ...selectedTasks,
                                        ];
                                        const position =
                                          alreadySelected.findIndex(
                                            (obj) => obj.taskId === task.taskId
                                          );
                                        if (position === -1) {
                                          alreadySelected.push(task);
                                          calculateHighlited(
                                            task,
                                            alreadySelected,
                                            "add"
                                          );
                                        } else {
                                          alreadySelected.splice(position, 1);
                                          calculateHighlited(
                                            task,
                                            alreadySelected,
                                            "remove"
                                          );
                                        }

                                        setSelectedTasks(alreadySelected);
                                        console.log(
                                          "task list value =",
                                          taskList
                                        );
                                      }}
                                    />
                                  )}
                              </td>
                              <td>
                                <div className="tskLstTaskNM taskTabContainerStepSeven">
                                  <span className="inOutIcon">
                                    {task?.starFlag === "Y" && (
                                      <StarRounded className="starTask" />
                                    )}
                                    {renderAppropriateIcon(
                                      task.incoming === "Y"
                                        ? "incoming"
                                        : task.incoming === "N"
                                        ? "outgoing"
                                        : task.incoming === "O"
                                        ? "observer"
                                        : ""
                                    )}
                                  </span>
                                  <Tooltip title={task.taskName} arrow>
                                    <span className="tskListTskName">
                                      {task.taskName}
                                    </span>
                                  </Tooltip>
                                  {task.taskLink && (
                                    <IconButton className="linkTskIoc">
                                      <Diagram3 />
                                    </IconButton>
                                  )}
                                </div>
                              </td>
                              <td>
                                <Tooltip title={task.taskDescription} arrow>
                                  <div className="tskLstTaskDescription taskTabContainerStepEight">
                                    {task.taskDescription}
                                  </div>
                                </Tooltip>
                              </td>
                              <td className="tskLstTaskTypeNm taskTabContainerStepNine">
                                {task.taskType}
                              </td>
                              <td>
                                <div className="tskLstTaskContract taskTabContainerStepTen">
                                  {task.contractName}
                                </div>
                              </td>
                              <td>
                                <div className="tskLstTaskStatus taskTabContainerStepTen">
                                  {task.progress}
                                </div>
                              </td>
                              <td>
                                {/* statRoutine statCritical statImportant */}
                                <div
                                  className={`tskLstTaskPriority ${
                                    task.priority === 0
                                      ? "statRoutine"
                                      : task.priority === 1
                                      ? "statImportant"
                                      : "statCritical"
                                  } taskTabContainerStepEleven`}
                                >
                                  {task.priority === 0
                                    ? t("regular")
                                    : task.priority === 1
                                    ? t("important")
                                    : t("critical")}
                                </div>
                              </td>
                              <td>
                                <div className="tskLstTaskCompDt taskTabContainerStepTwelve">
                                  <span className="compDate">
                                    {dayjs(task.completionTime).format(
                                      "DD-MM-YY"
                                    )}
                                  </span>
                                  <span className="compTime">
                                    {" "}
                                    {dayjs(task.completionTime).format(
                                      "HH:mm A"
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="tskLstTaskRequstBy taskTabContainerStepThirteen">
                                  {task.createdByUserName}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {/* start task filter */}
                  <div className="filterTaskGroup">
                    <div className="filElmInd">
                      <Tooltip
                        arrow
                        title="Outgoing Task Overdue"
                        placement="left"
                      >
                        {categoryWiseData?.["OUTGOING_OVERDUE"]?.length > 0 ? (
                          <Badge
                            badgeContent={
                              categoryWiseData?.["OUTGOING_OVERDUE"]?.length ||
                              ""
                            }
                          >
                            <IconButton
                              // className="overDue "
                              className={`overDue ${
                                selectedEvaluatorStatus === "OUTGOING_OVERDUE"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleCategory("OUTGOING_OVERDUE");
                              }}
                            >
                              <ArrowUpwardRounded />
                            </IconButton>
                          </Badge>
                        ) : (
                          <IconButton
                            // className="overDue "
                            className={`overDue ${
                              selectedEvaluatorStatus === "OUTGOING_OVERDUE"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleCategory("OUTGOING_OVERDUE");
                            }}
                          >
                            <ArrowUpwardRounded />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div>

                    <div className="filElmInd">
                      <Tooltip
                        arrow
                        title="Outgoing Task Delayed"
                        placement="left"
                      >
                        {categoryWiseData?.["OUTGOING_DELAY"]?.length > 0 ? (
                          <Badge
                            badgeContent={
                              categoryWiseData?.["OUTGOING_DELAY"]?.length || ""
                            }
                          >
                            <IconButton
                              // className="outOfTime active"
                              className={`outOfTime ${
                                selectedEvaluatorStatus === "OUTGOING_DELAY"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleCategory("OUTGOING_DELAY");
                              }}
                            >
                              <ArrowUpwardRounded />
                            </IconButton>
                          </Badge>
                        ) : (
                          <IconButton
                            // className="outOfTime active "
                            className={`outOfTime ${
                              selectedEvaluatorStatus === "OUTGOING_DELAY"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleCategory("OUTGOING_DELAY");
                            }}
                          >
                            <ArrowUpwardRounded />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div>

                    <div className="filElmInd">
                      <Tooltip
                        arrow
                        title="Outgoing Task On time"
                        placement="left"
                      >
                        {categoryWiseData?.["OUTGOING_IN_TIME"]?.length > 0 ? (
                          <Badge
                            badgeContent={
                              categoryWiseData?.["OUTGOING_IN_TIME"]?.length ||
                              ""
                            }
                          >
                            <IconButton
                              // className="onTime"
                              className={`onTime ${
                                selectedEvaluatorStatus === "OUTGOING_IN_TIME"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleCategory("OUTGOING_IN_TIME");
                              }}
                            >
                              <ArrowUpwardRounded />
                            </IconButton>
                          </Badge>
                        ) : (
                          <IconButton
                            // className="onTime "
                            className={`onTime ${
                              selectedEvaluatorStatus === "OUTGOING_IN_TIME"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleCategory("OUTGOING_IN_TIME");
                            }}
                          >
                            <ArrowUpwardRounded />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div>

                    <div className="filElmInd">
                      <Tooltip
                        arrow
                        title="Outgoing Task Before Time"
                        placement="left"
                      >
                        {categoryWiseData?.["OUTGOING_BEFORE_TIME"]?.length >
                        0 ? (
                          <Badge
                            badgeContent={
                              categoryWiseData?.["OUTGOING_BEFORE_TIME"]
                                ?.length || ""
                            }
                          >
                            <IconButton
                              // className="beforeTime"
                              className={`beforeTime ${
                                selectedEvaluatorStatus ===
                                "OUTGOING_BEFORE_TIME"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleCategory("OUTGOING_BEFORE_TIME");
                              }}
                            >
                              <ArrowUpwardRounded />
                            </IconButton>
                          </Badge>
                        ) : (
                          <IconButton
                            // className="beforeTime "
                            className={`beforeTime ${
                              selectedEvaluatorStatus === "OUTGOING_BEFORE_TIME"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleCategory("OUTGOING_BEFORE_TIME");
                            }}
                          >
                            <ArrowUpwardRounded />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div>

                    <div className="filElmInd">
                      <Tooltip
                        arrow
                        title="Incoming Task Overdue"
                        placement="left"
                      >
                        {categoryWiseData?.["INCOMING_OVERDUE"]?.length > 0 ? (
                          <Badge
                            badgeContent={
                              categoryWiseData?.["INCOMING_OVERDUE"]?.length ||
                              ""
                            }
                          >
                            <IconButton
                              // className="overDue"
                              className={`overDue ${
                                selectedEvaluatorStatus === "INCOMING_OVERDUE"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleCategory("INCOMING_OVERDUE");
                              }}
                            >
                              <ArrowDownwardRounded />
                            </IconButton>
                          </Badge>
                        ) : (
                          <IconButton
                            // className="overDue "
                            className={`overDue ${
                              selectedEvaluatorStatus === "INCOMING_OVERDUE"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleCategory("INCOMING_OVERDUE");
                            }}
                          >
                            <ArrowUpwardRounded />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div>

                    <div className="filElmInd">
                      <Tooltip
                        arrow
                        title="Incoming Task Delayed"
                        placement="left"
                      >
                        {categoryWiseData?.["INCOMING_DELAY"]?.length > 0 ? (
                          <Badge
                            badgeContent={
                              categoryWiseData?.["INCOMING_DELAY"]?.length || ""
                            }
                          >
                            <IconButton
                              // className="outOfTime"
                              className={`outOfTime ${
                                selectedEvaluatorStatus === "INCOMING_DELAY"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleCategory("INCOMING_DELAY");
                              }}
                            >
                              <ArrowDownwardRounded />
                            </IconButton>
                          </Badge>
                        ) : (
                          <IconButton
                            className={`outOfTime ${
                              selectedEvaluatorStatus === "INCOMING_DELAY"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleCategory("INCOMING_DELAY");
                            }}
                          >
                            <ArrowDownwardRounded />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div>

                    <div className="filElmInd">
                      <Tooltip
                        arrow
                        title="Incoming Task On time"
                        placement="left"
                      >
                        {categoryWiseData?.["INCOMING_IN_TIME"]?.length > 0 ? (
                          <Badge
                            badgeContent={
                              categoryWiseData?.["INCOMING_IN_TIME"]?.length ||
                              ""
                            }
                          >
                            <IconButton
                              // className="onTime"
                              className={`onTime ${
                                selectedEvaluatorStatus === "INCOMING_IN_TIME"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleCategory("INCOMING_IN_TIME");
                              }}
                            >
                              <ArrowDownwardRounded />
                            </IconButton>
                          </Badge>
                        ) : (
                          <IconButton
                            className="onTime "
                            onClick={() => {
                              handleCategory("INCOMING_IN_TIME");
                            }}
                          >
                            <ArrowDownwardRounded />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div>
                    <div className="filElmInd">
                      <Tooltip
                        arrow
                        title="Incoming Task Complete before time"
                        placement="left"
                      >
                        {categoryWiseData?.["INCOMING_BEFORE_TIME"]?.length >
                        0 ? (
                          <Badge
                            badgeContent={
                              categoryWiseData?.["INCOMING_BEFORE_TIME"]
                                ?.length || ""
                            }
                          >
                            <IconButton
                              // className="beforeTime"
                              className={`beforeTime ${
                                selectedEvaluatorStatus ===
                                "INCOMING_BEFORE_TIME"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                handleCategory("INCOMING_BEFORE_TIME");
                              }}
                            >
                              <ArrowDownwardRounded />
                            </IconButton>
                          </Badge>
                        ) : (
                          <IconButton
                            // className="beforeTime "
                            className={`beforeTime ${
                              selectedEvaluatorStatus === "INCOMING_BEFORE_TIME"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleCategory("INCOMING_BEFORE_TIME");
                            }}
                          >
                            <ArrowDownwardRounded />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div>

                    {/* <div className="filElmInd">
                      <Tooltip arrow title="Task Closed Today" placement="left">
                        <Badge badgeContent={4}>
                          <IconButton className="TodayClosed">
                            <TodayRounded />
                          </IconButton>
                        </Badge>
                      </Tooltip>
                    </div>

                    <div className="filElmInd">
                      <Tooltip
                        arrow
                        title="Task Closed last 30 Days"
                        placement="left"
                      >
                        <Badge badgeContent={50}>
                          <IconButton className="thisMonthClosed">
                            <CalendarMonthRounded />
                          </IconButton>
                        </Badge>
                      </Tooltip>
                    </div> */}
                  </div>
                  {/* end task filter */}
                </div>
              )}
              {/* {showConvenientMeetingView && (
              <div className="taskContainer">
                <ConvenientTimeView
                  usersList={selectedUsers.map((user) => user.userId)}
                  meetingStartTime={meetingStartTime}
                  meetingEndTime={meetingEndTime}
                  // meetingStartTime={new Date()}
                  // meetingEndTime={new Date()}
                  loginUser={loginUserDetail}
                  startTime={startTime}
                  endTime={endTime}
                  rows={rows}
                  slotDuration={slotDuration}
                  cols={cols}
                  defaultWorkWeek={defaultWorkWeek}
                  viewForm={(data) => {
                    if (data === "select") {
                      setHideForm(false);
                    } else {
                      setHideForm(!hideForm);
                    }
                  }}
                  hideForm={hideForm}
                  setSelectedConvenientTime={setSelectedConvenientTime}
                />
              </div>
            )} */}

              {showConvenientMeetingView && (
                <>
                  <div className="taskContainer">
                    <div className="taskListHeader">
                      {`Create A New Meeting For ${selectedTask.taskName}`}
                    </div>
                    <CreateConvenientMeetingWrapper
                      selectedUsers={selectedUsers}
                      handleClose={() => {
                        setHideForm(false);
                        setShowConvenientMeetingView(false);
                      }}
                      hideForm={hideForm}
                      setHideForm={setHideForm}
                      setSelectedConvenientTime={setSelectedConvenientTime}
                    />
                  </div>
                </>
              )}

              {!hideForm && (
                <RightSideTaskTabList
                  refreshData={() => {
                    fetchTasks(selectedDescendent);
                    // setShowRightPanel(false);
                  }}
                  handleClose={() => {
                    setShowRightPanel(false);
                  }}
                />
              )}

              {showMultipleUpdate && (
                <RightSideMultipleUpdate
                  selectedTasks={selectedTasks}
                  handleClose={(e) => {
                    setShowMultipleUpdate(false);
                  }}
                  updateTaskList={(data) => {
                    const tempTaskList = [...taskList];
                    const tempSelectedTasks = [];
                    const updatedTaskList = tempTaskList.map((item) => {
                      const matchingItemIndex = data.findIndex(
                        (d) => d.taskId === item.taskId
                      );
                      if (matchingItemIndex < 0) {
                        return item;
                      } else {
                        tempSelectedTasks.push(data[matchingItemIndex]);
                        return data[matchingItemIndex];
                      }
                    });
                    setTaskList(updatedTaskList);
                    setSelectedTasks(tempSelectedTasks);
                  }}
                />
              )}

              {showMultipleConcern && (
                <RightSideMultipleConcern
                  selectedTasks={selectedTasks}
                  handleClose={(e) => {
                    setShowMultipleConcern(false);
                  }}
                  refreshData={refreshData}
                />
              )}

              {rightModalName && (
                <RightSideCommonModal>
                  {rightModalName === "approve" && (
                    <RightSideApproveModal
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                  {rightModalName === "reject" && (
                    <RightSideRejectRequest
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                  {rightModalName === "cancel_task" && (
                    <RightSideCloseTask
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "signOff" && (
                    <RightSideSignOff
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "postpone" && (
                    <RightSidePostpone
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                  {rightModalName === "delegate" && (
                    <RightSideDelegate
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                  {rightModalName === "reassign" && (
                    <RightSideReassign
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "forward" && (
                    <RightSideTaskForward
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "disagree" && (
                    <RightSideDisagree
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "address_concern" && (
                    <RightSideAddressConcern
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                </RightSideCommonModal>
              )}
            </Grid>
          </Grid>
        </Box>
      </taskTabContext.Provider>
    </>
  );
}
