import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

export const JdHtmlView = ({ jdData, positionData }) => {
  const { t } = useTranslation();
  const [previewData, setPreviewData] = useState();
  const [keyResponsibility, setKeyResponsibility] = useState([]);
  const [jdDataCopy, setJdDataCopy] = useState(null);

  useEffect(() => {
    console.log("jd Data =", jdData);
    if (jdData && jdData.length > 0) {
      // setJdDataCopy([...jdData]);

      let keyRes = jdData.filter(
        (item) => item.label === "Key Responsibilities:"
      );

      setKeyResponsibility(groupKeyResponsibility(keyRes));

      dataGroupingHandler(jdData);
    }
  }, []);

  // useEffect(() => {
  //   if (jdDataCopy) {
  //     dataGroupingHandler(jdDataCopy);
  //   }
  // }, [jdDataCopy]);

  const groupKeyResponsibility = (data) => {
    const dta = data.reduce((acc, item) => {
      const { label, title } = item;

      if (!acc[title]) {
        acc[title] = [];
      }
      acc[title].push(item);
      return acc;
    }, {});

    const titles = Object.keys(dta);
    const tempData = [];
    titles.forEach((title) => {
      const dataItem = dta[title];
      tempData.push({
        title,
        titleId: uuidv4(),
        data: dataItem.map((itm) => ({ ...itm, tempId: uuidv4() })),
      });
    });
    console.log("Temp Data =", tempData);

    return tempData;
  };

  const dataGroupingHandler = (dataSrc) => {
    const nonKeyRes = dataSrc.filter(
      (item) => item.label !== "Key Responsibilities:"
    );

    setPreviewData(groupByLabelAndTitle(nonKeyRes));
  };
  const groupByLabelAndTitle = (data) => {
    const dta = data.reduce((acc, item) => {
      const { label, title } = item;
      if (!acc[label]) {
        acc[label] = {};
      }
      if (!acc[label][title]) {
        acc[label][title] = [];
      }
      acc[label][title].push(item);
      return acc;
    }, {});

    return dta;
  };

  return (
    <>
      {jdData && (
        <div className="JDKRAContainEdit">
          {positionData && positionData.jdSummary && (
            <div className="jdkElement">
              <div className="subElmntHead">{t("job_summary")} :</div>
              <div className="formElement">
                <p>{positionData.jdSummary}</p>
              </div>
            </div>
          )}

          {/* Responsibilities */}

          <div className="jdkElement">
            <div className="subElmntHead">{"Key Responsibilities"}</div>
            <ol className="">
              {console.log(
                "********* Key Responsibility = ",
                keyResponsibility
              )}
              {keyResponsibility &&
                keyResponsibility.map((item, subIndex) => {
                  const { title, data } = item;
                  return (
                    <li className="topList">
                      <div className="jdFromElement">
                        <div className="KratitleAdd">
                          <div className="formElement">
                            <p>{title}</p>
                          </div>
                        </div>
                        <ul>
                          {data.map((item) => {
                            return (
                              <li>
                                <div className="subElmntList">
                                  <div className="formElement">
                                    <p>
                                      <b>{item.detailHighlighter}</b>
                                      {item.detail}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  );
                })}
            </ol>
          </div>

          {/* Other Than Responsibilities */}

          {previewData &&
            Object.keys(previewData).map((label, index) => {
              return (
                <div className="jdkElement">
                  <div className="subElmntHead">{label}:</div>

                  <ol className="">
                    {previewData[label] !== "null" &&
                      Object.keys(previewData[label]).map((title, subIndex) => {
                        return (
                          <>
                            {title === "null" && (
                              <>
                                {previewData[label][title].map((item) => {
                                  return (
                                    <li>
                                      <div className="subElmntList">
                                        <div className="formElement">
                                          <p>
                                            <b>{item.detailHighlighter}</b>
                                            {item.detail}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </>
                            )}
                            {title !== "null" && (
                              <li className="toplist">
                                <div className="subElement">
                                  <div className="subElmntHead">
                                    {title !== "null" ? title : ""} :
                                  </div>
                                  <ul>
                                    {previewData[label][title].map((item) => {
                                      return (
                                        <li>
                                          <div className="subElmntList">
                                            <div className="formElement">
                                              <p>
                                                <b>{item.detailHighlighter}</b>
                                                {item.detail}
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}

                    {/* <li className="toplist">
                          <div className="subElement">
                            <div className="subElmntHead">Experience:</div>
                            <ul>
                              <li>
                                <div className="subElmntList">
                                  <div className="formElement">
                                    <p>
                                      Lorem ipsum dolor sit amet consectetur,
                                      adipisicing elit. Rem, repellendus.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li> */}
                  </ol>
                </div>
              );
            })}

          {/* <div className="jdkElement">
                    <div className="subElmntHead">Skills and Attributes:</div>
                    <ol className="">
                      <li className="topList">
                        <div className="jdFromElement">
                          <div className="KratitleAdd">
                            <div className="formElement">
                              <p>
                                <b>Heading:</b>
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Rem, repellendus.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="topList">
                        <div className="jdFromElement">
                          <div className="KratitleAdd">
                            <div className="formElement">
                              <p>
                                <b>Heading:</b>
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Rem, repellendus.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>{" "}
                      <li className="topList">
                        <div className="jdFromElement">
                          <div className="KratitleAdd">
                            <div className="formElement">
                              <p>
                                <b>Heading:</b>
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Rem, repellendus.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div> */}
        </div>
      )}
      {!jdData && <h4> {t("please_add_jd_data")}</h4>}
    </>
  );
};
