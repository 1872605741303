import {
  Accessibility,
  Add,
  ExpandMoreRounded,
  MicOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import RecordAudio from "../../../../../../../common/RecordAudio";
import { convertBlobToBase64 } from "../../../../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { getHealthDto, MAX_SUGGESTED_RESULT } from "./consultationUtil";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Trash } from "react-bootstrap-icons";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";

export default function PatientComplaints({
  data,
  updateData,
  extraData,
  setVisualSymptoms,
}) {
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [showRecAudio, setShowRecAudio] = React.useState(false);
  const tempAudioChunk = useRef([]);
  const [recordingLength, setRecordingLength] = useState(0);
  const [recordingLink, setRecordingLink] = useState();

  // const getSingleBlob = () => {
  //   const promises = [];

  //   for (const blob of tempAudioChunk.current) {
  //     const promise = blob.arrayBuffer();
  //     promises.push(promise);
  //   }

  //   Promise.all(promises)
  //     .then((arrayBuffers) => {
  //       const concatenatedArrayBuffer = arrayBuffers.reduce((acc, buffer) => {
  //         const totalLength = acc.byteLength + buffer.byteLength;
  //         const combined = new Uint8Array(totalLength);
  //         combined.set(new Uint8Array(acc), 0);
  //         combined.set(new Uint8Array(buffer), acc.byteLength);
  //         return combined.buffer;
  //       }, new ArrayBuffer(0));

  //       const combinedBlob = new Blob([concatenatedArrayBuffer], {
  //         type: "audio/wav",
  //       });
  //       tempAudioChunk.current = [combinedBlob];
  //       console.log(tempAudioChunk.current);
  //     })
  //     .catch((error) => {
  //       console.error("Error occurred while combining Blobs:", error);
  //     });
  // };

  // const handleShowRecAudioClose = () => {
  //   console.log(tempAudioChunk.current);
  //   if (tempAudioChunk.current && tempAudioChunk.current.length > 0) {
  //     getSingleBlob();
  //   }
  //   setShowRecAudio(false);
  // };

  // const handleAudio = async (combinedBlob) => {
  //   return new Promise((resolve, reject) => {
  //     let audioFileDataBase64;
  //     if (tempAudioChunk.current.length > 0) {
  //       // const combinedTempBlob = getSingleBlob()
  //       const audioBlob = new Blob(tempAudioChunk.current, {
  //         type: "audio/wav",
  //       });
  //       if (audioBlob) {
  //         convertBlobToBase64(audioBlob)
  //           .then((base64Data) => {
  //             audioFileDataBase64 = base64Data;
  //             resolve(audioFileDataBase64);
  //           })
  //           .catch((error) => {
  //             reject(error);
  //           });
  //       } else {
  //         resolve(audioFileDataBase64);
  //       }
  //     } else {
  //       resolve(audioFileDataBase64);
  //     }
  //   });
  // };

  const [severity, setSeverity] = useState("");
  const [since, setSince] = useState("");
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [symptomSearchString, setSymptomSearchString] = useState("");

  const [symptomList, setSymptomList] = useState([]);

  const [symptomSinceList, setSymptomSinceList] = useState([]);
  const [severityList, setSeverityList] = useState([]);

  const [userSymptomList, setUserSymptomList] = useState([]);

  const initializeForm = () => {
    setSeverity("");
    setSince("");
    setSelectedSymptom(null);
    setSymptomSearchString("");
  };

  const symptomString = useMemo(() => {
    const str =
      userSymptomList.length > 0
        ? userSymptomList
            .map(
              (item) =>
                `${item.selectedSymptom.symptomName}(${item.severity}, ${item.since} )`
            )
            .join(", ")
        : "";
    return str;
  }, [userSymptomList]);

  const getFilteredList = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (
        element.symptomName.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.symptomName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const suggestedSymptopList = useMemo(() => {
    const symptomIdsList = userSymptomList.map(
      (item) => item.selectedSymptom.id
    );
    let filteredData = symptomList.filter((symptom) => {
      let symptomVal = "C";
      if (extraData?.queuePatientGender === "Male") {
        symptomVal = "M";
      }
      if (extraData?.queuePatientGender === "Female") {
        symptomVal = "F";
      }
      const alreadyTaken = symptomIdsList.some((id) => id === symptom.id);
      return (
        !alreadyTaken &&
        (symptom.gender === symptomVal || symptom.gender === "C")
      );
    });
    const filteredList = getFilteredList(filteredData, symptomSearchString);
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [symptomSearchString, symptomList, userSymptomList]);

  const handleChangeSince = (event) => {
    setSince(event.target.value);
  };

  const handleChangeSeverity = (event) => {
    setSeverity(event.target.value);
  };

  useEffect(() => {
    if (!data.symptoms) {
      if (extraData && extraData?.reqValue && extraData?.reqValue?.symptoms) {
        const tempArray = extraData?.reqValue?.symptoms.map((item) => {
          let tempSymptom = {
            id: item.symptomId,
            symptomName: item.symptomName,
          };

          const temp = {
            selectedSymptom: tempSymptom,
            severity: item.severity,
            since: item.since,
          };
          return temp;
        });

        setUserSymptomList((prev) => {
          const temp = [...prev, ...tempArray];
          updateData({ symptoms: temp });
          return temp;
        });
      }
    }

    getSymptomSince();
    getSymptomSeverity();
    getSymptoms();
  }, []);

  useEffect(() => {
    if (data.symptoms) {
      setUserSymptomList(data.symptoms);
    }
  }, data?.symptoms);

  const handleAddSymptom = () => {
    if (!symptomSearchString || symptomSearchString === "") {
      toast.error(t("please_select_symptom"));
      return;
    }
    // if (!severity || severity === "") {
    //   toast.error(t("please_select_severity"));
    //   return;
    // }
    // if (!since || since === "") {
    //   toast.error("Please Select since");
    //   return;
    // }
    let tempSymptom = selectedSymptom;
    if (!tempSymptom) {
      tempSymptom = {
        id: uuidv4(),
        symptomName: symptomSearchString,
        locallyAdded: true,
      };
    }
    const dto = {
      selectedSymptom: tempSymptom,
      severity,
      since,
    };
    setUserSymptomList((prev) => {
      const temp = [...prev, dto];
      updateData({ symptoms: temp });
      return temp;
    });
    initializeForm();
  };

  const getSymptomSince = async () => {
    const symptomSinceString = await SecureIndexedDB.getItem("symptomSince");
    if (symptomSinceString) {
      setSymptomSinceList(JSON.parse(symptomSinceString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getSymptomSince(reqDto).then((response) => {
      if (response.data) {
        setSymptomSinceList(response.data);
        SecureIndexedDB.setItem("symptomSince", JSON.stringify(response.data));
      }
    });
  };

  const getSymptomSeverity = async () => {
    const symptomSeverityString = await SecureIndexedDB.getItem(
      "symptomSeverity"
    );
    if (symptomSeverityString) {
      setSeverityList(JSON.parse(symptomSeverityString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSymptomSeverity(reqDto).then((response) => {
      if (response.data) {
        setSeverityList(response.data);
        SecureIndexedDB.setItem(
          "symptomSeverity",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getSymptoms = async () => {
    const symptomString = await SecureIndexedDB.getItem("symptomList");
    if (symptomString) {
      setSymptomList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSymptoms(reqDto).then((response) => {
      if (response.data) {
        setSymptomList(response.data);
        SecureIndexedDB.setItem("symptomList", JSON.stringify(response.data));
      }
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod nsBookConsultstepsThree"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("patient_complaints")}
            {!accordionExpanded && <span></span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {/* <div className="formElement">
            <FormControl className="formControl pRelative">
              <TextField
                label={t("complaints")}
                required
                variant="outlined"
                className="descptionTextFild"
                multiline
                rows={4}
                maxRows={5}
                value={data?.patientComplaint || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  setComplaint(value);
                  updateData({ patientComplaint: value });
                }}
              ></TextField>
              <Tooltip
                className="audioNoteTooltip"
                title={t("record_patient_complaints")}
                arrow
                placement="bottom"
              >
                <IconButton
                  onClick={() => setShowRecAudio(true)}
                  className={
                    tempAudioChunk.current.length > 0
                      ? `audioNote activeStatus`
                      : "audioNote"
                  }
                >
                  <MicOutlined />
                </IconButton>
              </Tooltip>
            </FormControl>
          </div> */}

          {/* visual symptoms start */}
          {/* <div className="ddBtnGroup flxSPBTW">
            <div className="buttonwithWarning">
              <Button
                className="differnDiagnosBtn"
                startIcon={<Accessibility />}
                onClick={() => {
                  setVisualSymptoms(true);
                }}
              >
                Visual Symptoms
              </Button>
              <div className="warnigMsg">
                All infographics are for illustrative purposes only and do not
                represent real images or data.
              </div>
            </div>
          </div>
          <div className="bb"></div> */}
          {/* visual symptoms end */}

          <div className="complaintList">
            <ul>
              {userSymptomList &&
                userSymptomList.map((symptom, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">
                            {symptom.selectedSymptom.symptomName}
                          </div>
                          <div className="compinfoGRP">
                            {symptom.severity && (
                              <div className="compSeverity">
                                {/* <span>Severity:</span> */}
                                <span className="data">{symptom.severity}</span>
                              </div>
                            )}
                            {symptom.since && (
                              <div className="compStime">
                                {/* <span>Since:</span> */}
                                <span className="data">{symptom.since}</span>
                              </div>
                            )}
                          </div>
                        </div>

                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userSymptomList];
                            temp.splice(index, 1);
                            updateData({ symptoms: temp });
                            setUserSymptomList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}

              {/* add new symptoms */}

              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    {/* <FormControl className="formControl">
                      <TextField
                        label="Symptoms"
                        required
                        autoComplete="off"
                        placeholder="Enter Symptoms"
                        variant="outlined"
                        className="formTextFieldArea"
                      />
                    </FormControl> */}

                    <FormControl className="formControl">
                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedSymptom}
                        options={suggestedSymptopList}
                        inputValue={symptomSearchString}
                        onChange={(e, newValue) => {
                          setSelectedSymptom(newValue);
                        }}
                        onInputChange={(e, value, reason) => {
                          if (e && reason === "input") {
                            console.log("changed input =", value);
                            setSymptomSearchString(e.target.value);
                          } else if (reason === "reset") {
                            setSymptomSearchString(value);
                          } else if (reason === "clear") {
                            setSymptomSearchString(value);
                          }
                          // else {
                          //   setSymptomSearchString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.symptomName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.symptomName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder={t("symptom_name")}
                            required
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="severity-select-label">
                        {t("severity")}
                      </InputLabel>
                      <Select
                        labelId="severity-select-label"
                        id="severity-select"
                        value={severity}
                        label={t("severity")}
                        onChange={handleChangeSeverity}
                        className="formInputField"
                        variant="outlined"
                      >
                        {severityList &&
                          severityList.map((item) => {
                            return (
                              <MenuItem value={item} key={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="since-select-label">
                        {t("since")}
                      </InputLabel>
                      <Select
                        labelId="since-select-label"
                        id="since-select"
                        value={since}
                        label={t("since")}
                        onChange={handleChangeSince}
                        className="formInputField"
                        variant="outlined"
                      >
                        {symptomSinceList &&
                          symptomSinceList.map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  </div>
                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      handleAddSymptom();
                    }}
                  >
                    {t("add")}
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>

      {/* {showRecAudio && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">{t("audio_message")}</div>
            <RecordAudio
              closeDialog={async () => {
                const audioFileDataBase64 = await handleAudio();
                updateData({ audioData: audioFileDataBase64 });
                handleShowRecAudioClose();
              }}
              tempAudioChunk={tempAudioChunk}
              recordingLength={recordingLength}
              setRecordingLength={setRecordingLength}
              recordingLink={recordingLink}
              setRecordingLink={setRecordingLink}
            />
          </div>
        </Box>
      )} */}
    </>
  );
}
