import {
  AccessibleRounded,
  Add,
  ArrowBack,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  MaleRounded,
  MonetizationOnOutlined,
  Search,
  StarRateRounded,
  TranslateRounded,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import EXP from "../../../../dashboard/image/EXP";
import { Eye } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";
import { BookAppointmentFromNurseStep } from "../../../../../common/tour_step/BookAppointmentFromNurseStep";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export default function ViewBookAppointmentDrList({
  handelCloseViewBookAppointmentDrList,
  closeAll,
  openViewFullSlot,
  handelOpenAppointmentBooking,
  refreshbookAppointMent,
  bookAppointClass,
  registeredPatient,
  handelCloseAppointBooking,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [monthSelect, setMonthSelect] = useState(new Date());
  const [selectDoctor, setSelectDoctor] = useState("");
  const [loading, setLoading] = useState(true);

  const handleChangselectDoctor = (event) => {
    setSelectDoctor(event.target.value);
  };
  const handleMonthSelect = (newDate) => {
    setMonthSelect(newDate);
  };
  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const [doctorAvailData, setdoctorAvailData] = useState([]);
  const [FilterDoctorAvailData, setFilterDoctorAvailData] = useState([]);
  const [searchDoctorQuery, setSearchDotorQuery] = useState("");
  const [searchSpecialityQuery, setSearchSpecialityQuery] = useState("");
  const [selectedSlotId, setSelectedSlotId] = useState(null);

  useEffect(() => {
    DashboardServices.getDoctorListWithAvailableSlot(
      userDetails.signleUnitId
    ).then((response) => {
      console.log("response", response.data);
      const sortedData = response.data.sort((a, b) => {
        return a.doctorName.localeCompare(b.doctorName);
      });
      console.log("sortedData", sortedData);
      setdoctorAvailData(sortedData);
      setFilterDoctorAvailData(sortedData);

      //  setdoctorAvailData(response.data);
      // setFilterDoctorAvailData(response.data);
      setLoading(false);
    });
  }, [refreshbookAppointMent]);

  useEffect(() => {
    return () => {
      handelCloseAppointBooking();
    };
  }, []);

  const handleDotorSearch = (e) => {
    const query = e.target.value;
    setSearchDotorQuery(query);

    const filteredData = doctorAvailData.filter(
      (doctor) =>
        doctor.doctorName &&
        doctor.doctorName.toLowerCase().includes(query.toLowerCase())
    );

    setFilterDoctorAvailData(filteredData);
  };

  const handleSpecialitySearch = (e) => {
    const query = e.target.value;
    setSearchSpecialityQuery(query);

    const filteredData = doctorAvailData.filter(
      (doctor) =>
        doctor.specialization &&
        doctor.specialization.toLowerCase().includes(query.toLowerCase())
    );

    setFilterDoctorAvailData(filteredData);
  };

  const handleSlotClick = (doctorId, slot) => {
    setSelectedSlotId(slot.slotId);
    handleSubmitData(doctorId, slot);
  };

  const handleSubmitData = (doctorId, slot) => {
    console.log("doctorId", doctorId);

    console.log("slot", slot);

    const delDoctor = doctorAvailData.find((val) => val.doctorId === doctorId);

    const obj = {
      doctorName: delDoctor.doctorName,
      specialization: delDoctor.specialization,
      experience: delDoctor.experience,
      language: delDoctor.language,
      fees: delDoctor.fees,
      feesCurrency: delDoctor.feesCurrency,
      rating: delDoctor.rating,
      numberOfUserRated: delDoctor.numberOfUserRated,
      imageLink: delDoctor.imageLink,
      timezone: delDoctor.timezone,
      doctorId: delDoctor.doctorId,
      slot: {
        slotId: slot.slotId,
        fromTime: slot.fromTime,
        toTime: slot.toTime,
        patientName: "",
        patientUnitId: 0,
        patientAge: null,
        patientGender: "",
        patientId: 0,
      },
      date: new Date(slot.slotDate),
      patientData: registeredPatient || null,
    };

    console.log("obj", obj);

    handelOpenAppointmentBooking(obj);
  };

  console.log("useContext123", run, run["bookappointNs"] === true);

  return (
    <>
      <Joyride
        steps={BookAppointmentFromNurseStep}
        run={run["bookappointNs"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={0}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="AnalyticsDashboardContainer nsAppointmentstepOne">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handelCloseViewBookAppointmentDrList();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("book_doctor_appointment")}
        </div>
        <div className="anDashCardContainArea ">
          <div className="fullContainArea">
            <div className="cuHeaderGrp ">
              <div className="formSearchGrp nsAppointmentstepTwo">
                <div className="searchTasklist">
                  <input
                    type="text"
                    class="serchTaskInput"
                    placeholder={t("search_by_dr")}
                    value={searchDoctorQuery}
                    onChange={handleDotorSearch}
                  />
                  <IconButton className="seacrhIcon">
                    <Search />
                  </IconButton>
                </div>
                <div className="searchTasklist">
                  <input
                    type="text"
                    class="serchTaskInput"
                    placeholder={t("search_by_dr_speciality")}
                    value={searchSpecialityQuery}
                    onChange={handleSpecialitySearch}
                  />
                  <IconButton className="seacrhIcon">
                    <Search />
                  </IconButton>
                </div>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : doctorAvailData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  <br />
                  <span className="sallMsg">
                    {t("no_booked_doctor_slots_found")}
                  </span>
                </div>
              ) : (
                <div className="appoinmentBookingSlot">
                  <div class="slots-container docList ">
                    {FilterDoctorAvailData.map((doctor) => (
                      <div className="doctorListSlot nsAppointmentstepThree">
                        <div className="doctorDetails">
                          <div className="doctorInfoWthImg">
                            <div className="docImage">
                              <img
                                // src="https://plus.unsplash.com/premium_photo-1661551577028-80cfb8e4d236?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDV8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                                src={doctor.imageLink}
                                alt={t("doctor_image")}
                              />
                            </div>
                            <div className="doctorFullInfo">
                              <div className="doctorNmSpc">
                                <div className="docName">
                                  {doctor.doctorName}
                                </div>
                                <div className="docSpclty">
                                  ({doctor.specialization})
                                </div>
                              </div>
                              <div className="docExpFeeLan">
                                <div className="docEFL">
                                  <div className="eflicon fillnone">
                                    <EXP />
                                  </div>
                                  <div className="eflText">
                                    {doctor.experience}
                                  </div>
                                </div>
                                <div className="docEFL">
                                  <div className="eflicon">
                                    <MonetizationOnOutlined />
                                  </div>
                                  <div className="eflText">
                                    {doctor.fees}
                                    <span> {doctor.feesCurrency}</span>
                                  </div>
                                </div>
                                {doctor?.language && (
                                  <div className="docEFL">
                                    <div className="eflicon">
                                      <TranslateRounded />
                                    </div>
                                    <div className="eflText">
                                      {/* {doctor.language} */}
                                      {doctor.language &&
                                        doctor.language.split(",").length > 0 &&
                                        doctor.language
                                          .split(",")
                                          .map((langName, index) => {
                                            return (
                                              t(
                                                langName
                                                  .replace(/\s+\(/g, "_")
                                                  .replace(/\)/g, "")
                                                  .replace(/\s+/g, "_")
                                              ) +
                                              (index ===
                                              doctor.language.split(",")
                                                .length -
                                                1
                                                ? ""
                                                : ", ")
                                            );
                                          })}
                                    </div>
                                    {/* <div className="eflText">
                                      {doctor?.language
                                        ?.split(",")
                                        ?.sort((a, b) =>
                                          a.trim().localeCompare(b.trim())
                                        )
                                        ?.join(", ") || ""}
                                    </div> */}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="doctorRating">
                            <div className="docRatvalue">
                              <StarRateRounded />
                              <span className="ratingvalue">
                                {/* {doctor.rating || "N/A"} */}
                                {doctor.rating
                                  ? Math.floor(doctor.rating)
                                  : "N/A"}
                              </span>
                            </div>
                            <div className="docRatCount">
                              {doctor.numberOfUserRated} {t("user")}
                            </div>
                          </div>
                        </div>
                        <div className="doctorDetailSlots">
                          {doctor.availableSlot.map((slot) => (
                            <div
                              // class="slot-box"
                              key={slot.slotId}
                              className={
                                bookAppointClass &&
                                selectedSlotId === slot.slotId
                                  ? "slot-box active"
                                  : "slot-box nsAppointmentstepFour"
                              }
                              onClick={() => {
                                console.log("doctor data", doctor);
                                // handelOpenAppointmentBooking();
                                // handleSubmitData(doctor.doctorId ,slot)
                                handleSlotClick(doctor.doctorId, slot);
                                if (run["bookappointNs"] === true) {
                                  setRun({
                                    slotBookConfirmns: true,
                                  });
                                }
                              }}
                            >
                              <div className="slotInfo">
                                <div class="icon">
                                  <AccessibleRounded />
                                </div>
                                <div className="slotTime">
                                  <span className="date">
                                    {new Date(slot.slotDate).toLocaleDateString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                      }
                                    )}
                                  </span>
                                  <span className="time">
                                    {slot.fromTime} - {slot.toTime}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}

                          <Button
                            startIcon={<Eye />}
                            className="dfultPrimaryBtn nsAppointmentstepFive"
                            // onClick={openViewFullSlot}
                            onClick={() => {
                              openViewFullSlot(doctor.doctorId);
                              if (run["bookappointNs"] === true) {
                                setRun({
                                  viewSlotAllDoctorNs: true,
                                });
                              }
                            }}
                          >
                            {t("more_slots")}
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
