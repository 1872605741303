import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

export const getCurrencyList = () => {
  return http.get(`/misc/getcurrencyList`, { headers: authHeader() });
};

export const getCountryList = () => {
  return http.get(`/misc/getCountryDetailList`, { headers: authHeader() });
};

export const getOrgCountry = (orgId) => {
  return http.get(`/misc/getOrgCountry/${orgId}`, { headers: authHeader() });
};

export const getStateList = (countryCd) => {
  return http.get(`/misc/getStateList/${countryCd}`, { headers: authHeader() });
};

export const getCallingCodeList = () => {
  return http.get(`/misc/getCallingCdList`, { headers: authHeader() });
};

export const addUnit = (orgId, userId, reqDto) => {
  return http.post(`org/addUnit/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getUnits = (orgId) => {
  return http.get(`org/getUnits/${orgId}`, {
    headers: authHeader(),
  });
};
export const createNewDepartment = (orgId, userId, reqDto) => {
  return http.post(`function/createNewFunction/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getAllDepartment = (orgId) => {
  return http.get(`function/getAllFunctions/${orgId}`, {
    headers: authHeader(),
  });
};

export const addDesignation = (orgId, userId, reqDto) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(
    `designation/addDesignation/${orgId}/${userId}`,
    reqDto,
    config
  );
};

export const getDesignationsOfOrganization = (orgId) => {
  return http.get(`designation/getDesignationsOfOrganization/${orgId}`, {
    headers: authHeader(),
  });
};

export const updateDesignation = (userId, reqDto) => {
  return http.put(`designation/updateDesignation/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const deactivateDesignation = (designationId, userId) => {
  return http.put(
    `designation/deactivateDesignation/${designationId}/${userId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getItemType = (category, orgId) => {
  return http.get(`item/getItemTypes/${orgId}/${category}`);
};

export const addItemCatagory = (category, orgId, userId) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(`item/addCategory/${orgId}/${userId}`, category, config);
};

export const addItemType = (type, category, orgId, userId) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(
    `item/addItemType/${category}/${orgId}/${userId}`,
    type,
    config
  );
};

export const getItemTypes = (orgId, category) => {
  return http.get(`item/getItemTypes/${orgId}/${category}`);
};

export const geItemCatagory = (orgId) => {
  return http.get(`item/getItemCategories/${orgId}`);
};

export const addItemUOM = (orgId, userId, itemUOM) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(`item/addItemUOM/${orgId}/${userId}`, itemUOM, config);
};

export const getItemUOM = (orgId) => {
  return http.get(`item/getItemUOMs/${orgId}`);
};

export const getItemManufacturers = (orgId) => {
  return http.get(`item/getItemManufacturers/${orgId}`);
};

export const addItem = (orgId, userId, item) => {
  return http.post(`item/addItem/${orgId}/${userId}`, item, {
    headers: authHeader(),
  });
};

export const editItemValues = (item, userId) => {
  return http.post(`item/editItem/${userId}`, item, {
    headers: authHeader(),
  });
};

export const addPosition = (orgId, userId, reqDto) => {
  return http.post(`designation/addPosition/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getPositionsOfOrganization = (orgId) => {
  return http.get(`designation/getPositionsOfOrganization/${orgId}`, {
    headers: authHeader(),
  });
};

export const getPositionsOfOrg = (orgId, userId, unitId) => {
  return http.get(
    `designation/getPositionsOfOrganization/${orgId}/${userId}/${unitId}`,
    {
      headers: authHeader(),
    }
  );
};

export const deactivatePosition = (designationId, userId) => {
  return http.put(`designation/deactivatePosition/${designationId}/${userId}`, {
    headers: authHeader(),
  });
};

export const addCommittee = (orgId, userId, reqDto) => {
  return http.post(`committee/addCommittee/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getCommittees = (orgId) => {
  return http.get(`committee/getCommittees/${orgId}`, {
    headers: authHeader(),
  });
};

export const addRolesToCommittee = (committeeId, userId, reqDto) => {
  return http.put(
    `committee/addRolesToCommittee/${committeeId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const deactivateRole = (roleId, userId) => {
  return http.put(`committee/deactivateRole/${roleId}/${userId}`, {
    headers: authHeader(),
  });
};

export const deactivateCommittee = (committeeId, userId) => {
  return http.put(`committee/deactivateCommittee/${committeeId}/${userId}`, {
    headers: authHeader(),
  });
};

export const addItemManufacturar = (orgId, userId, manufacturar) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(
    `item/addItemManufacturer/${orgId}/${userId}`,
    manufacturar,
    config
  );
};

export const addBudgetHead = (orgId, userId, reqDto) => {
  return http.post(`budget/addBudgetHead/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getBudgetHeads = (orgId) => {
  return http.get(`budget/getBudgetHeads/${orgId}`, {
    headers: authHeader(),
  });
};

export const deactivateBudgetHead = (userId, reqDto) => {
  return http.put(`budget/deactivateBudgetHead/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const addEmployee = (userId, orgId, reqDto) => {
  return http.post(`person/addEmployee/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const addBudget = (orgId, userId, reqDto) => {
  return http.post(`budget/addBudget/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getRightsToSelect = (type) => {
  return http.get(`/misc/getStaticDD/${type}`, { headers: authHeader() });
};

export const addRight = (orgId, userId, reqDto) => {
  return http.post(`criticalRights/addRight/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getRights = (orgId) => {
  return http.get(`criticalRights/getRights/${orgId}`, {
    headers: authHeader(),
  });
};

export const deactivateRight = (rightId, userId) => {
  return http.put(`criticalRights/deactivateRight/${rightId}/${userId}`, {
    headers: authHeader(),
  });
};

export const addPolicy = (userId, orgId, reqDto) => {
  return http.post(`org/addPolicy/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getPolicies = (orgId) => {
  return http.get(`org/getPolicies/${orgId}`, { headers: authHeader() });
};

export const deletePolicy = (policyId, userId, orgId) => {
  return http.put(`org/deletePolicy/${policyId}/${userId}/${orgId}`, {
    headers: authHeader(),
  });
};
export const addStatutoryInsurancePaymentsHead = (orgId, userId, reqDto) => {
  return http.post(
    `statutoryInsurancePayments/addStatutoryInsurancePaymentsHead/${orgId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const getStatutoryInsurancePaymentsList = (orgId) => {
  return http.get(
    `statutoryInsurancePayments/getStatutoryInsurancePaymentsList/${orgId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getOrgTotalStorage = (orgId) => {
  return http.get(`/drive/getOrgTotalStorage/${orgId}`, {
    headers: authHeader(),
  });
};

export const deactivateStatutoryInsurancePaymentsHead = (id, userId) => {
  return http.put(
    `statutoryInsurancePayments/deactivateStatutoryInsurancePaymentsHead/${id}/${userId}`,
    {
      headers: authHeader(),
    }
  );
};
export const getAllDriveDetailsOfOrgUsers = (orgId) => {
  return http.get(`/drive/getAllDriveDetailsOfOrgUsers/${orgId}`, {
    headers: authHeader(),
  });
};

export const upgradeStorage = (reqDto, userId, orgId) => {
  return http.put(`/drive/upgradeStorage/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};
export const getRoutineActivity = (orgId) => {
  return http.get(`routineWork/getRoutineActivity/${orgId}`, {
    headers: authHeader(),
  });
};

export const getStaticDDOfOrg = (orgId, type) => {
  return http.get(`misc/getStaticDDOfOrg/${orgId}/${type}`, {
    headers: authHeader(),
  });
};

export const updateDepartment = (userId, reqDto) => {
  return http.put(`function/updateFunctionName/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const UpdateCriticalRights = (userId, reqDto) => {
  return http.put(`criticalRights/editRight/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const deactivateDepartment = (functionId, userId) => {
  return http.put(`function/deactivateFunction/${functionId}/${userId}`, {
    headers: authHeader(),
  });
};

export const deactivateUnit = (unitId, userId) => {
  return http.put(`org/deactivateUnit/${unitId}/${userId}`, {
    headers: authHeader(),
  });
};

export const editUnit = (userId, reqDto) => {
  return http.post(`org/editUnit/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const issueLoi = (userId, orgId, reqDto) => {
  return http.post(`routineWork/issueLoi/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const issueTaxInvoice = (orgId, unitId, userId, reqDto) => {
  return http.post(
    `routineWork/issueTaxInvoice/${orgId}/${unitId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};
export const issueProformaInvoice = (orgId, unitId, userId, reqDto) => {
  return http.post(
    `routineWork/issueProformaInvoice/${orgId}/${unitId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const getItems = (orgId) => {
  return http.get(`item/getItems/${orgId}`, {
    headers: authHeader(),
  });
};

export const verifyItem = (itemId, userId) => {
  return http.post(`/item/verifiedItem/${itemId}/${userId}`, {
    headers: authHeader(),
  });
};

export const deactivateItem = (itemId, userId) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(`item/deactivateItem/${itemId}/${userId}`, config);
};

export const replaceItem = (itemId, userId, replaceItemId) => {
  return http.post(`/item/replaceItem/${itemId}/${userId}/${replaceItemId}`, {
    headers: authHeader(),
  });
};

export const addSalaryHead = (orgId, userId, reqDto) => {
  return http.post(`payroll/addSalaryHead/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getSalaryHeads = (orgId) => {
  return http.get(`payroll/getSalaryHeads/${orgId}`, {
    headers: authHeader(),
  });
};

export const deactivategetSalaryHead = (salaryHeadId, userId) => {
  return http.put(`payroll/deactivategetSalaryHead/${salaryHeadId}/${userId}`, {
    headers: authHeader(),
  });
};

export const getEmploymentTypes = (orgId) => {
  return http.get(`person/getEmploymentTypes/${orgId}`, {
    headers: authHeader(),
  });
};

export const getLeaveTypesWithMenu = () => {
  return http.get(`leave/getLeaveTypesWithMenu`, {
    headers: authHeader(),
  });
};

export const getLeaveRulesOfOrg = (orgId) => {
  return http.get(`leave/getLeaveRulesOfOrg/${orgId}`, {
    headers: authHeader(),
  });
};

export const addLeaveRule = (userId, orgId, reqDto) => {
  return http.post(`leave/addLeaveRule/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const deactivateLeaveRule = (leaveRuleId, userId) => {
  return http.put(`leave/deactivateLeaveRule/${leaveRuleId}/${userId}`, {
    headers: authHeader(),
  });
};
export const getOrgDetailsByOrgId = (orgId) => {
  return http.get(`org/getOrgDetailsByOrgId/${orgId}`, {
    headers: authHeader(),
  });
};

export const getPendingAppointmentCandidat = (orgId) => {
  return http.get(`routineWork/getPendingAppointmentCandidate/${orgId}`, {
    headers: authHeader(),
  });
};

export const getSalaryBreakUpOfLoi = (loiId) => {
  return http.get(`routineWork/getSalaryBreakUpOfLoi/${loiId}`, {
    headers: authHeader(),
  });
};

export const getTempNo = (type, orgId) => {
  return http.get(`misc/getTempNo/${type}/${orgId}`, {
    headers: authHeader(),
  });
};

export const editLeaveRule = (userId, orgId, reqDto) => {
  return http.put(`leave/editLeaveRule/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getLeaveBalanceOfAllEmployee = (orgId) => {
  return http.get(`leave/getLeaveBalanceOfAllEmployee/${orgId}`, {
    headers: authHeader(),
  });
};

export const updateLeaveBalance = (userId, orgId, reqDto) => {
  return http.post(`leave/updateLeaveBalance/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const issueAppoinment = (userId, orgId, reqDto) => {
  return http.post(`routineWork/issueAppointment/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getExpenseApprover = (userId, orgId, reqDto) => {
  return http.post(`inventory/getExpenseApprover/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const uploadLeaveBalance = (userId, orgId, reqDto) => {
  return http.post(`leave/uploadLeaveBalance/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const addHoliday = (orgId, userId, reqDto) => {
  return http.post(`calendar/addHoliday/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const generateCouponCode = (userId, reqDto) => {
  return http.post(`accountbilling/generateCouponCode/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getHolidaysList = (orgId) => {
  return http.get(`calendar/getHolidaysList/${orgId}`, {
    headers: authHeader(),
  });
};

export const deleteHoliday = (userId, orgId, reqString) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.put(
    `calendar/deleteHoliday/${userId}/${orgId}`,
    reqString,
    config
  );
};

export const updateHoliday = (orgId, userId, holidayDate, reqDto) => {
  return http.put(
    `calendar/updateHoliday/${orgId}/${userId}/${holidayDate}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const getCouponCodes = () => {
  return http.get(`accountbilling/getCouponCodes`, {
    headers: authHeader(),
  });
};

export const deactivateCouponCode = (userId, reqDto) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(
    `accountbilling/deactivateCouponCode/${userId}`,
    reqDto,
    config
  );
};

export const getOrganizationWorkTiming = (orgId, userId) => {
  return http.get(`org/getOrganizationWorkTiming/${orgId}/${userId}`, {
    headers: authHeader(),
  });
};

export const updateWorkingTiming = (reqDto) => {
  return http.put(`org/updateWorkingTiming`, reqDto, {
    headers: authHeader(),
  });
};

export const createVendorPartner = (userId, orgId, reqDto) => {
  return http.post(`person/createVendorPartner/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getExternalUsersOfOrg = (orgId) => {
  return http.get(`person/getExternalUsersOfOrg/${orgId}`, {
    headers: authHeader(),
  });
};

export const deActivateExternalUser = (externalUserId, deactivateUserId) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.put(
    `person/deactivateExternalUser/${externalUserId}/${deactivateUserId}`,
    config
  );
};

export const updateExternalUser = (userId, orgId, reqDto) => {
  return http.put(`/person/updateExternalUser/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getActivityList = (orgId) => {
  return http.get(`task/getActivityList/${orgId}`, {
    headers: authHeader(),
  });
};

export const uploadTemplate = (userId, reqDto) => {
  return http.post(`task/uploadTemplate/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getActiveTemplatesOfOrganization = (orgId) => {
  return http.get(`task/getActiveTemplatesOfOrganization/${orgId}`, {
    headers: authHeader(),
  });
};

export const deactivateTemplate = (userId, templateId) => {
  return http.put(`task/deactivateTemplate/${userId}/${templateId}`, {
    headers: authHeader(),
  });
};

export const approveTask = (userId, reqDto) => {
  return http.put(`task/approveTask/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const approveIndentWithChanges = (userId, reqDto) => {
  return http.post(`/inventory/approveIndentWithChanges/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getPendingGrns = (unitId) => {
  return http.get(`/inventory/getPendingGrns/${unitId}`, {
    headers: authHeader(),
  });
};

export const getItemDetailsOfProformaInvoice = (proformaInvoiceId) => {
  return http.get(
    `/inventory/getItemDetailsOfProformaInvoice/${proformaInvoiceId}`,
    {
      headers: authHeader(),
    }
  );
};
export const getProformaInvoicesPendingForTaxInvoces = (unitId) => {
  return http.get(
    `/inventory/getProformaInvoicesPendingForTaxInvoces/${unitId}`,
    {
      headers: authHeader(),
    }
  );
};
export const getItemsOfPoForGrn = (poSystemNo) => {
  return http.get(`/inventory/getItemsOfPoForGrn/${poSystemNo}`, {
    headers: authHeader(),
  });
};
export const getItemsOfInvoiceForGrn = (invoiceSystemNo) => {
  return http.get(`/inventory/getItemsOfInvoiceForGrn/${invoiceSystemNo}`, {
    headers: authHeader(),
  });
};

export const addActivity = (userId, orgId, reqString) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(
    `activity/addActivity/${userId}/${orgId}`,
    reqString,
    config
  );
};

export const deleteSubActivity = (userId, subActivityId) => {
  return http.put(`activity/deleteSubActivity/${userId}/${subActivityId}`, {
    headers: authHeader(),
  });
};

export const getDashboardDataOfItem = (orgId, userId) => {
  return http.get(`ax/getDashboardData/${orgId}/${userId}`, {
    headers: authHeader(),
  });
};

export const addTermsAndConditions = (orgId, userId, reqDto) => {
  return http.post(`org/addTermsAndConditions/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getOrgTermsAndConditions = (orgId, transType) => {
  return http.get(`org/getOrgTermsAndConditions/${orgId}/${transType}`, {
    headers: authHeader(),
  });
};

export const deactivateTermsAndConditions = (id, userId) => {
  return http.put(`org/deactivateTermsAndConditions/${id}/${userId}`, {
    headers: authHeader(),
  });
};

export const getPersonsOfOrg = (orgId) => {
  return http.get(`person/getPersonsOfOrg/${orgId}`, {
    headers: authHeader(),
  });
};

export const getMappedUnmappedMenusOfUser = (userId) => {
  return http.get(`menu/getMappedUnmappedMenusOfUser/${userId}`, {
    headers: authHeader(),
  });
};

export const mapMenusToUser = (userId, loginUserId, reqDto) => {
  return http.post(`menu/mapMenusToUser/${userId}/${loginUserId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getUserListWIthMenus = (orgId) => {
  return http.get(`menu/getUserListWIthMenus/${orgId}`, {
    headers: authHeader(),
  });
};

export const editTermsAndConditions = (orgId, userId, reqDto) => {
  return http.post(`org/editTermsAndConditions/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getOrgEmployees = (orgId) => {
  return http.get(`person/getOrgEmployees/${orgId}`, {
    headers: authHeader(),
  });
};

export const getEmployees = (orgId) => {
  return http.get(`person/getEmployees/${orgId}`, {
    headers: authHeader(),
  });
};

export const getBudget = (orgId) => {
  return http.get(`budget/getBudget/${orgId}`, {
    headers: authHeader(),
  });
};

export const getBudgetItems = (budgetId) => {
  return http.get(`budget/getBudgetItems/${budgetId}`, {
    headers: authHeader(),
  });
};

export const addContract = (userId, reqDto) => {
  return http.post(`contract/addContract/${userId}`, reqDto, {
    headers: authHeader(),
  });
};
export const editContractDetails = (userId, reqDto) => {
  return http.put(`contract/editContractDetails/${userId}`, reqDto, {
    headers: authHeader(),
  });
};
export const deleteContract = (userId) => {
  return http.put(`contract/deleteContract/${userId}`, {
    headers: authHeader(),
  });
};

export const getContractList = (orgId) => {
  return http.get(`contract/getContractList/${orgId}`, {
    headers: authHeader(),
  });
};
export const getClientOfOrg = (orgId) => {
  return http.get(`person/getClientOfOrg/${orgId}`, {
    headers: authHeader(),
  });
};
export const getActiveSalaryComponents = (orgId, userId, countryCode) => {
  return http.get(
    `payroll/getActiveSalaryComponents/${orgId}/${userId}/${countryCode}`,
    {
      headers: authHeader(),
    }
  );
};

export const deactivateEmployee = (userId, userIdToDeactivate, reqDto) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.put(
    `person/deactivateEmployee/${userId}/${userIdToDeactivate}`,
    reqDto,
    config
  );
};

export const getUserPositions = (userId) => {
  return http.get(`person/getUserPositions/${userId}`, {
    headers: authHeader(),
  });
};

export const editEmployee = (userId, reqDto) => {
  return http.put(`person/editEmployee/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getOrgDetails = (userId) => {
  return http.get(`org/getOrgDetails/${userId}`, {
    headers: authHeader(),
  });
};

export const getAllTimeZones = () => {
  return http.get(`misc/getActiveTimeZones`, { headers: authHeader() });
};

export const updateLogo = (orgId, userId, reqDto) => {
  return http.put(`org/updateLogo/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const updateOrgDetails = (reqDto, userId) => {
  return http.put(`org/updateOrgDtls/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const editOrg = (userId, reqDto) => {
  return http.put(`org/editOrg/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getOrgByOrgId = (orgId) => {
  return http.get(`org/getOrgByOrgId/${orgId}`, { headers: authHeader() });
};

export const editDesignation = (designationId, userId, updatedDesignation) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.put(
    `designation/editDesignation/${designationId}/${userId}`,
    updatedDesignation,
    config
  );
};

export const getJdOfPosition = (positionId) => {
  return http.get(`designation/getJdOfPosition/${positionId}`, {
    headers: authHeader(),
  });
};

export const getKpiOfPosition = (positionId) => {
  return http.get(`designation/getKpiOfPosition/${positionId}`, {
    headers: authHeader(),
  });
};

export const getCurrencyOfUnit = (unitId) => {
  return http.get(`org/getCurrencyOfUnit/${unitId}`, {
    headers: authHeader(),
  });
};

export const getCtcBand = (orgId) => {
  return http.get(`designation/getCtcBrand/${orgId}`, {
    headers: authHeader(),
  });
};

export const copyPosition = (fromUnit, toUnit, userId) => {
  return http.post(`designation/copyPosition/${fromUnit}/${toUnit}/${userId}`, {
    headers: authHeader(),
  });
};

export const submitPosition = (orgId, userId, reqDto) => {
  return http.post(`designation/submitPosition/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getPmsConfig = (orgId, userId) => {
  return http.get(`pms/getPmsConfig/${orgId}/${userId}`, {
    headers: authHeader(),
  });
};

export const submitPmsConfig = (orgId, userId, reqDto) => {
  return http.put(`pms/submitPmsConfig/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getPositionsOfUnit = (unitId) => {
  return http.get(`designation/getPositionsOfUnit/${unitId}`, {
    headers: authHeader(),
  });
};

export const getReportingPositionEmployee = (positionId) => {
  return http.get(`person/getReportingPositionEmployee/${positionId}`, {
    headers: authHeader(),
  });
};

export const addShift = (orgId, userId, reqDto) => {
  return http.put(`org/addShift/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getShifts = (orgId, userId) => {
  return http.get(`org/getShifts/${orgId}/${userId}`, {
    headers: authHeader(),
  });
};

export const getUserShifts = (userId) => {
  return http.get(`person/getUserShifts/${userId}`, {});
};
export const deactivateShift = (shiftId, userId) => {
  return http.put(`org/deactivateShift/${shiftId}/${userId}`, {});
};

export const makeDefaultShift = (shiftId, orgId, userId) => {
  return http.put(`org/makeDefaultShift/${shiftId}/${orgId}/${userId}`, {
    headers: authHeader(),
  });
};

export const getMenusWithModule = () => {
  return http.get(`menu/geMenusWithModule`, {
    headers: authHeader(),
  });
};

export const getUserMenus = (userId) => {
  return http.get(`menu/getUserMenus/${userId}`, {
    headers: authHeader(),
  });
};

export const getSalaryConfigMaster = (countryCode) => {
  return http.get(`payroll/getSalaryConfigMaster/${countryCode}`, {
    headers: authHeader(),
  });
};

export const addSalaryComponent = (orgId, userId, reqDto) => {
  return http.post(`payroll/addSalaryComponent/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getSalaryComponents = (orgId, userId, countryCode) => {
  return http.get(
    `payroll/getSalaryComponents/${orgId}/${userId}/${countryCode}`,
    {
      headers: authHeader(),
    }
  );
};

export const addComponentCategory = (orgId, userId, slNo, reqDto) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(
    `payroll/addComponentCategory/${orgId}/${userId}/${slNo}`,
    reqDto,
    config
  );
};

export const getComponentCategories = (orgId) => {
  return http.get(`payroll/getComponentCategories/${orgId}`, {
    headers: authHeader(),
  });
};

export const updateCategory = (categoryId, userId, reqDto) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.put(
    `payroll/updateCategory/${categoryId}/${userId}`,
    reqDto,
    config
  );
};

export const deactivateComponentCategory = (categoryId, userId) => {
  return http.put(
    `payroll/deactivateComponentCategory/${categoryId}/${userId}`,
    {
      headers: authHeader(),
    }
  );
};

export const deactivateSalaryComponent = (componentId, userId) => {
  return http.put(
    `payroll/deactivateSalaryComponent/${componentId}/${userId}`,
    {
      headers: authHeader(),
    }
  );
};

export const activateSalaryComponent = (componentId, userId) => {
  return http.put(`payroll/activateSalaryComponent/${componentId}/${userId}`, {
    headers: authHeader(),
  });
};

export const updateSalaryComponent = (userId, reqDto) => {
  return http.post(`payroll/updateSalaryComponent/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

// export const getPayrollEmployees = (orgId) => {
//   return http.get(`payroll/getPayrollEmployees/${orgId}`, {
//     headers: authHeader(),
//   });
// };

export const updateEmployeeAccountDetails = (userId, reqDto) => {
  return http.put(`payroll/updateEmployeeAccountDetails/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

// export const getEmployeeSalaryComponents = (userId) => {
//   return http.get(`payroll/getEmployeeSalaryComponents/${userId}`, {
//     headers: authHeader(),
//   });
// };

export const submitEmpSalaryComponent = (orgId, userId, reqDto) => {
  return http.post(
    `payroll/submitEmpSalaryComponent/${orgId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const getRightConfig = () => {
  return http.get(`criticalRights/getRightConfig`, {
    headers: authHeader(),
  });
};

export const getCriticalRights = (orgId) => {
  return http.get(`criticalRights/getCriticalRights/${orgId}`, {
    headers: authHeader(),
  });
};

export const addCriticalRight = (orgId, userId, reqDto) => {
  return http.post(
    `criticalRights/addCriticalRight/${orgId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const editCriticalRight = (userId, reqDto) => {
  return http.put(`criticalRights/editCriticalRight/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getOtpServices = (orgId) => {
  return http.post(`otp/getOtpServices/${orgId}`, {
    headers: authHeader(),
  });
};

export const activeDeactiveOtpService = (userId, reqDto) => {
  return http.put(`otp/activeDeactiveOtpService/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getActiveOtpServices = (orgId) => {
  return http.post(`otp/getActiveOtpServices/${orgId}`, {
    headers: authHeader(),
  });
};

export const requestOtp = (userId, reqDto) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: authHeaderVal,
    },
  };

  return http.post(`otp/requestOtp/${userId}`, reqDto, config);
};

export const resendOtp = (userId, otpServiceId, reqDto) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };

  return http.post(`otp/resendOtp/${userId}/${otpServiceId}`, reqDto, config);
};

export const validateOtp = (reqDto) => {
  return http.post(`otp/validateOtp`, reqDto, {
    headers: authHeader(),
  });
};

export const getEmployeeSalaryComponents = (reqDto) => {
  return http.post(`payroll/getEmployeeSalaryComponents`, reqDto, {
    headers: authHeader(),
  });
};

export const getPayrollEmployees = (reqDto) => {
  return http.post(`payroll/getPayrollEmployees`, reqDto, {
    headers: authHeader(),
  });
};

export const getDashboardDataNew = (reqDto) => {
  return http.post(`ax/getDashboardData`, reqDto, {
    headers: authHeader(),
  });
};

export const getCountryDtlByCode = (countryCode) => {
  return http.get(`misc/getCountryDtlByCode/${countryCode}`, {
    headers: authHeader(),
  });
};

export const getDefaultPositionsOfOrganization = (orgId, userId, unitId) => {
  return http.get(
    `designation/getDefaultPositionsOfOrganization/${orgId}/${userId}/${unitId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getOrgEmployeeIds = (orgId) => {
  return http.get(`person/getOrgEmployeeIds/${orgId}`, {
    headers: authHeader(),
  });
};

export const getUserListForAccessControl = (orgId) => {
  return http.get(`person/getUserListForAccessControl/${orgId}`, {
    headers: authHeader(),
  });
};

export const refreshEmployee = (reqDto) => {
  return http.post(`ax/refreshEmployee`, reqDto, {
    headers: authHeader(),
  });
};

export const refreshHoliday = (reqDto) => {
  return http.post(`ax/refreshHoliday`, reqDto, {
    headers: authHeader(),
  });
};

export const refreshContract = (reqDto) => {
  return http.post(`ax/refreshContract`, reqDto, {
    headers: authHeader(),
  });
};

export const refreshExternalUser = (reqDto) => {
  return http.post(`ax/refreshExternalUser`, reqDto, {
    headers: authHeader(),
  });
};

export const unitDetilsForProforma = (orgId) => {
  return http.post(`org/unitDetilsForProforma/${orgId}`, {
    headers: authHeader(),
  });
};

export const getEmpForRelease = (orgId) => {
  return http.get(`person/getEmpForRelease/${orgId}`, {
    headers: authHeader(),
  });
};

export const issueReleaseLetter = (orgId, userId, reqDto) => {
  return http.post(
    `routineWork/issueReleaseLetter/${orgId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};
export const grnEntry = (orgId, userId, reqDto) => {
  return http.post(`routineWork/grnEntry/${orgId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getEmpForServiceCertificate = (orgId) => {
  return http.get(`person/getEmpForServiceCertificate/${orgId}`, {
    headers: authHeader(),
  });
};

export const getAllPositionOfEmployee = (userId, lastWorkingDate) => {
  return http.get(
    `person/getAllPositionOfEmployee/${userId}/${lastWorkingDate}`,
    {
      headers: authHeader(),
    }
  );
};

export const issueServiceCertificate = (orgId, userId, reqDto) => {
  return http.post(
    `routineWork/issueServiceCertificate/${orgId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};
