import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GetLoginUserDetails, validateEmail } from "../../../../utils/Utils";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { Trash } from "react-bootstrap-icons";
import { Add } from "@mui/icons-material";
import {
  getAllDepartment,
  getCountryList,
  getCurrencyList,
  getCurrencyOfUnit,
  getOrgDetailsByOrgId,
  getPendingAppointmentCandidat,
  getPositionsOfOrganization,
  getPositionsOfUnit,
  getSalaryBreakUpOfLoi,
  getStateList,
  getStaticDDOfOrg,
  getUnits,
} from "../../../../services/AdminService";
import DashboardServices from "../../../../services/DashboardServices";
import IntegerTextField from "../../../../common/IntegerTextField";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LoiSalaryTable } from "./LoiSalaryTable";
import { enIN, es } from "date-fns/locale";
import { currentSubdomain } from "../../../../constants/const_string";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export const AppoinmentLetter = forwardRef(
  ({ formData, setFormData, handleShowPreview }, appointRef) => {
    const loginUserDetail = GetLoginUserDetails();
    const loginUserDetails = GetLoginUserDetails();
    const [salaryInfoList, setSalaryInfoList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [countryList, setCountryList] = useState([{}]);
    const [stateList, setStateList] = useState([]);
    const [joiningdate, setjoiningdate] = useState(new Date());
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [loiCandidateList, setLoiCandidateList] = useState([]);
    const [fromIssuedLoi, setFromIssuedLoi] = useState(false);
    const { t, i18n } = useTranslation();

    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedPosition, setSelectedPosition] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [salaryBrkUps, setSalaryBrkUps] = useState([]);
    const [orgData, setOrgData] = useState(null);
    const [unitList, setUnitList] = useState([]);
    const [corCountry, setCorCountry] = useState("");
    const [corState, setCorState] = useState("");
    const tacRef = useRef();
    const [saveForFutureAppoint, setSaveForFutureAppoint] = useState(false);

    const [selectedReportingHead, setSelectedReportingHead] = useState(null);
    const [userList, setUserList] = useState([]);

    const [staticDodList, setStaticDodList] = useState([]);
    const [staticDodListCopy, setStaticDodListCopy] = useState([]);

    const [shouldCalculate, setShouldCalculate] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);
    const [defaultCurrency, setdefaultCurrency] = useState("");

    // Determine the locale based on the current language
    const currentLocale = i18n.language === "es" ? "es" : "en";

    const showFutureCheckbox = useMemo(() => {
      //
      if (staticDodList.length !== staticDodListCopy.length) {
        return true;
      }
      for (let i = 0; i < staticDodList.length; i++) {
        if (staticDodList[i] !== staticDodListCopy[i]) {
          return true;
        }
      }
      return false;
    }, [staticDodList, staticDodListCopy]);

    const handleJoiningDate = (date) => {
      setjoiningdate(date);
    };
    // const handleLoiexpdate = (date) => {
    //   setLoiexpdate(date);
    // };

    const { register, watch, control, getValues, setValue, reset } = useForm({
      defaultValues: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        pin: "",
        cor_address: "",
        cor_address1: "",
        cor_address2: "",
        cor_city: "",
        cor_pin: "",
        probationDay: "",
        priorAddressChangeDays: "",
        priorLeaveNoticeDays: "",
        absentDays: "",
        noticePeriod: "",
        employmentTitle: "",
        gpm: "",
        location: loginUserDetails.signleUnitId,
      },
    });

    const [ccLoiEmaiIds, setCcLoiEmailIds] = useState([]);
    const [ccId, setCCid] = useState("");

    const {
      firstName,
      middleName,
      lastName,
      address1,
      address2,
      city,
      pin,
      email,
      cor_address,
      cor_address1,
      cor_address2,
      cor_pin,
      cor_city,
      loiSelectedCandidate,
      // absentDays,
      noticePeriod,
      gpm,
      probationDay,
      priorAddressChangeDays,
      priorLeaveNoticeDays,
      ctc,
      location,
    } = watch();

    useEffect(() => {
      if (location) {
        getPositionsOfUnit(location).then((response) => {
          if (response.data) {
            setPositionList(response.data);
          }
        });
      }
    }, [location]);

    useEffect(() => {
      if (
        (selectedCountry && selectedCountry.countryCode) ||
        (corCountry && corCountry.countryCode)
      ) {
        const cCode = selectedCountry.countryCode || corCountry.countryCode;
        if (stateList && stateList.length === 0) {
          getStateList(cCode).then((response) => {
            if (response.data) {
              setStateList(response.data);
            }
          });
        }
      }
    }, [selectedCountry, corCountry]);

    const calculatedAnnualTotal = useMemo(() => {
      let total = 0;
      let infoList = [];
      //
      if (salaryBrkUps && salaryBrkUps.length > 0) {
        infoList = [...salaryBrkUps];
      } else {
        infoList = [...salaryInfoList];
      }

      for (let i = 0; i < infoList.length; i++) {
        if (salaryBrkUps && salaryBrkUps.length > 0) {
          total += infoList[i].yearly;
        } else {
          total += infoList[i].annual;
        }
      }

      if (isNaN(total)) {
        total = 0;
      }
      return total;
    }, [salaryInfoList]);

    useEffect(() => {
      setValue("ctc", calculatedAnnualTotal);
    }, [calculatedAnnualTotal]);

    useEffect(() => {
      if (loiSelectedCandidate) {
        getSalaryBreakUpOfLoi(loiSelectedCandidate.loiId).then((response) => {
          setSalaryBrkUps(response.data);
        });
        setValue("firstName", loiSelectedCandidate.firstName);
        setValue("middleName", loiSelectedCandidate.middleName);
        setValue("lastName", loiSelectedCandidate.lastName);
        setValue("address1", loiSelectedCandidate.addressLine1);
        setValue("address2", loiSelectedCandidate.addressLine2);
        setValue("city", loiSelectedCandidate.city);
        setValue("pin", loiSelectedCandidate.pinCode);
        setValue("email", loiSelectedCandidate.email);
        // setValue("ctc", loiSelectedCandidate.ctc);
        // setValue("gpm", loiSelectedCandidate.grossPerMnth);
        const tempDep = departmentList.find((dep) => {
          return dep.functionId === loiSelectedCandidate.functionId;
        });
        if (tempDep) {
          setSelectedDepartment(tempDep);
        }
        const tempPosition = positionList.find((pos) => {
          return pos.positionId === loiSelectedCandidate.positionId;
        });
        if (tempPosition) {
          setSelectedPosition(tempPosition);
        }

        const tempCountry = countryList.find((cou) => {
          return cou.countryCode === loiSelectedCandidate.countryCode;
        });
        if (tempCountry) {
          setSelectedCountry(tempCountry);
          getStateList(tempCountry.countryCode).then((response) => {
            if (response.data) {
              //
              setStateList(response.data);
              const tempState = response.data.find((cou) => {
                return cou.id === loiSelectedCandidate.stateId;
              });
              if (tempState) {
                setSelectedState(tempState.id);
              }
            }
          });
        }
      }
    }, [loiSelectedCandidate]);

    useEffect(() => {
      if (cor_address) {
        const formData = getValues();
        setValue("cor_address1", formData.address1);
        setValue("cor_address2", formData.address2);
        setValue("cor_city", formData.city);
        setValue("cor_pin", formData.pin);
        setCorCountry(selectedCountry);
        setCorState(selectedState);
      }
      // setValue();
    }, [cor_address]);

    useEffect(() => {
      setCcLoiEmailIds((prev) => [loginUserDetails.userEmailId]);
      getStaticDDOfOrg(
        loginUserDetails.orgId,
        "APPOINTMENT_TERMS_CONDITIONS"
      ).then((response) => {
        if (response.data) {
          setStaticDodList(response.data);
          setStaticDodListCopy(response.data);
        }
      });

      getCurrencyList().then((response) => {
        console.log("getCurrencyList", response.data);
        setCurrencyList(response.data);
      });

      getCurrencyOfUnit(loginUserDetails.signleUnitId).then((response) => {
        if (response.data) {
          setdefaultCurrency(response.data?.[0]);
        }
      });
      // getPositionsOfOrganization(loginUserDetails.orgId).then((response) => {
      //   if (response.data) {
      //     setPositionList(response.data);
      //   }
      // });
      getPositionsOfUnit(loginUserDetails.signleUnitId).then((response) => {
        if (response.data) {
          setPositionList(response.data);
        }
      });
      getAllDepartment(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setDepartmentList(response.data);
        }
      });
      getCountryList(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setCountryList(response.data);
        }
      });
      getPendingAppointmentCandidat(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setLoiCandidateList(response.data);
        }
      });
      getOrgDetailsByOrgId(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setOrgData(response.data);
        }
      });
      getUnits(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setUnitList(response.data);
          const selectedUnit = response.data?.find((item) => {
            return item.unitId === loginUserDetails.signleUnitId;
          });
          if (selectedUnit) {
            setValue("location", selectedUnit.unitId);
          }
        }
      });
      DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
        (response) => {
          console.log("all contract  = ", response.data);
          setUserList(response.data);
        }
      );
    }, []);

    return (
      <>
        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <FormGroup aria-label="position" row className="feformGroup">
                <FormControlLabel
                  className="formCheckBox"
                  // value="Cost Center"
                  control={
                    <Checkbox
                      value={fromIssuedLoi}
                      onChange={(e) => {
                        setFromIssuedLoi(e.target.checked);
                        if (!e.target.checked) {
                          setSalaryBrkUps([]);
                          reset();
                        }
                      }}
                    />
                  }
                  label={t("from_issued_loi")}
                  labelPlacement="End"
                />
              </FormGroup>
            </FormControl>
          </div>

          {fromIssuedLoi && (
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="employeeTitle">
                  {t("candidate_name")}
                </InputLabel>
                <Controller
                  name="loiSelectedCandidate"
                  control={control}
                  render={({ field }) => (
                    <Select
                      labelId="employeeTitle"
                      id="selectemployeeTitle"
                      label={t("title")}
                      className="formInputField"
                      variant="outlined"
                      {...field}
                    >
                      {loiCandidateList &&
                        loiCandidateList.map((candidate) => (
                          <MenuItem
                            value={candidate}
                          >{`${candidate.firstName} ${candidate.middleName} ${candidate.lastName}`}</MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
          )}
        </div>

        <div class="tskElemHeding mt10">{t("candidate_demography")}</div>

        <div className="formElementGrp">
          <div className="formElement mxW-100">
            <FormControl className="formControl">
              <InputLabel id="employeeTitle">{t("title")}</InputLabel>
              <Controller
                name="employeeTitle"
                control={control}
                defaultValue={"Mr"}
                render={({ field }) => (
                  <Select
                    labelId="employeeTitle"
                    id="selectemployeeTitle"
                    label={t("title")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    <MenuItem value="Mr">{t("mr")}</MenuItem>
                    <MenuItem value="Ms">{t("ms")}</MenuItem>
                    <MenuItem value="Mrs">{t("mrs")}</MenuItem>
                    <MenuItem value="Miss">{t("miss")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("first_name")}
                required
                placeholder={t("first_name")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: firstName !== "" ? true : false }}
                // autoComplete="my_first_name_off"
                // inputProps={{ autoComplete: "off" }}
                {...register("firstName")}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                // label={t("middle_name")}
                // placeholder={t("middle_name")}
                label={
                  currentSubdomain === "massalud"
                    ? t("first_surname")
                    : t("middle_name")
                }
                placeholder={
                  currentSubdomain === "massalud"
                    ? t("first_surname")
                    : t("middle_name")
                }
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: middleName !== "" ? true : false }}
                autoComplete="my_Field_off"
                {...register("middleName")}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                required
                // label={t("last_name")}
                // placeholder={t("last_name")}
                label={
                  currentSubdomain === "massalud"
                    ? t("second_surname")
                    : t("last_name")
                }
                placeholder={
                  currentSubdomain === "massalud"
                    ? t("second_surname")
                    : t("last_name")
                }
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="my_Field_off"
                InputLabelProps={{ shrink: lastName !== "" ? true : false }}
                {...register("lastName")}
              />
            </FormControl>
          </div>
        </div>

        <div className="formElementGrp">
          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="employeeJoingDate" className="setCompletionDate">
                {t("joining_date")}*
              </InputLabel>
              <ReactDatePicker
                locale={currentLocale}
                showIcon
                labelId="employeeJoingDate"
                className="formDatePicker"
                selected={joiningdate}
                // minDate={new Date()}
                minDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleJoiningDate(date);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="loiExpDate" className="setCompletionDate">
                {t("date_of_birth")}*
              </InputLabel>
              <ReactDatePicker
                locale={currentLocale}
                showIcon
                labelId="loiExpDate"
                className="formDatePicker"
                selected={dateOfBirth}
                // minDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setDateOfBirth(date);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("email_id")}
                required
                placeholder={t("ex_employe_org_com")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: email !== "" ? true : false }}
                {...register("email")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>
        </div>
        <div className="formElementGrp ">
          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectPosition">
                {t("select_location")} *
              </InputLabel>

              {/* <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="SelectPosition"
                    id="Select-Position"
                    label={t("select_location")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {unitList.map((unit) => (
                      <MenuItem value={unit.unitId}>
                        {unit.organizationAddressDto.addressDisplay}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              /> */}
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="SelectPosition"
                    id="Select-Position"
                    label={t("select_location")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {unitList?.map((unit) => (
                      <MenuItem value={unit.unitId}>
                        {unit.organizationAddressDto.addressDisplay}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </div>
          {/* <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectDepartment">
                {t("select_department")} *
              </InputLabel>

              <Select
                labelId="SelectDepartment"
                id="Select-department"
                label={t("select_department")}
                className="formInputField"
                variant="outlined"
                value={selectedDepartment}
                onChange={(e) => {
                  setSelectedDepartment(e.target.value);
                }}
              >
                {departmentList &&
                  departmentList.map((department) => (
                    <MenuItem value={department}>
                      {department.functionName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div> */}

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectPosition">
                {t("select_position")} *
              </InputLabel>

              {/* <Controller
              name="position"
              control={control}
              render={({ field }) => (
              )}
            /> */}
              <Select
                labelId="SelectPosition"
                id="Select-Position"
                label={t("select_position")}
                className="formInputField"
                variant="outlined"
                // {...field}
                value={selectedPosition}
                onChange={(e) => {
                  setSelectedPosition(e.target.value);
                }}
              >
                {positionList.map((position) => (
                  <MenuItem value={position}>{position.position}</MenuItem>
                ))}
                {/* <MenuItem value="manager">Manager</MenuItem>
                <MenuItem value="executive">Executive</MenuItem>
                <MenuItem value="interns">Interns</MenuItem> */}
              </Select>
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectPosition">
                {t("reporting_head")} *
              </InputLabel>

              {/* <Controller
              name="position"
              control={control}
              render={({ field }) => (
              )}
            /> */}
              <Select
                labelId="SelectPosition"
                id="Select-Position"
                label={t("select_reporting_head")}
                className="formInputField"
                variant="outlined"
                // {...field}
                value={selectedReportingHead}
                onChange={(e) => {
                  setSelectedReportingHead(e.target.value);
                }}
              >
                {userList.map((user) => (
                  <MenuItem value={user}>{user.userName}</MenuItem>
                ))}
                {/* <MenuItem value="manager">Manager</MenuItem>
                <MenuItem value="executive">Executive</MenuItem>
                <MenuItem value="interns">Interns</MenuItem> */}
              </Select>
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">{t("permanent_address")}</div>
        <div className="formElementGrp flex-wrap">
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("address_line_one")}
                required
                placeholder={t("address_line_one")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: address1 !== "" ? true : false }}
                {...register("address1")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("address_line_two")}
                placeholder={t("address_line_two")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: address2 !== "" ? true : false }}
                {...register("address2")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                required
                // hiddenLabel
                label={t("city")}
                placeholder={t("ex_kolkata")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: city !== "" ? true : false }}
                {...register("city")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectCountry">{t("select_country")}*</InputLabel>

              {/* <Controller
              name="country"
              control={control}
              render={({ field }) => (
              )}
            /> */}
              <Select
                required
                labelId="SelectCountry"
                id="Select-Country"
                label={t("select_country")}
                className="formInputField"
                variant="outlined"
                // {...field}
                value={selectedCountry}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
              >
                {countryList &&
                  countryList.map((c) => (
                    <MenuItem value={c}>{c.countryName}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          {selectedCountry?.countryCode === "356" && (
            <div className="formElement width13">
              <FormControl className="formControl">
                <InputLabel id="SelectState">{t("select_state")} *</InputLabel>

                {/* <Controller
              name="state"
              control={control}
              render={({ field }) => (
              )}
            /> */}
                <Select
                  required
                  labelId="SelectState"
                  id="Select-State"
                  label={t("select_state")}
                  className="formInputField"
                  variant="outlined"
                  // {...field}
                  value={selectedState}
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                  }}
                >
                  {stateList &&
                    stateList.map((state) => (
                      <MenuItem value={state.id}>{state.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                required
                label={t("pin")}
                placeholder={t("ex_pin")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: pin !== "" ? true : false }}
                {...register("pin")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">{t("correspondence_address")}</div>
        <div className="formElement">
          <FormControl className="formControl">
            <Controller
              name="cor_address"
              control={control}
              render={({ field }) => (
                <FormGroup aria-label="position" row className="feformGroup">
                  <FormControlLabel
                    className="formCheckBox"
                    // value="Cost Center"
                    control={<Checkbox {...field} />}
                    label={t("same_as_permanent_address")}
                    labelPlacement="End"
                  />
                </FormGroup>
              )}
            />
          </FormControl>
        </div>
        <div className="formElementGrp flex-wrap">
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("address_line_one")}
                required
                placeholder={t("address_line_one")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: cor_address1 !== "" ? true : false }}
                {...register("cor_address1")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("address_line_two")}
                placeholder={t("address_line_two")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: cor_address2 !== "" ? true : false }}
                {...register("cor_address2")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                required
                // hiddenLabel
                label={t("city")}
                placeholder={t("ex_kolkata")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: cor_address2 !== "" ? true : false }}
                {...register("cor_city")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectCountry">{t("select_country")}*</InputLabel>

              {/* <Controller
                name="cor_country"
                control={control}
                render={({ field }) => (
                )}
              /> */}
              <Select
                required
                labelId="SelectCountry"
                id="Select-Country"
                label={t("select_country")}
                className="formInputField"
                variant="outlined"
                value={corCountry}
                onChange={(e) => setCorCountry(e.target.value)}
              >
                {countryList &&
                  countryList.map((c) => (
                    <MenuItem value={c}>{c.countryName}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          {corCountry?.countryCode === "356" && (
            <div className="formElement width13">
              <FormControl className="formControl">
                <InputLabel id="SelectState">{t("select_state")} *</InputLabel>

                {/* <Controller
                name="state"
                control={control}
                render={({ field }) => (
                )}
              /> */}
                <Select
                  required
                  labelId="SelectState"
                  id="Select-State"
                  label={t("select_state")}
                  className="formInputField"
                  variant="outlined"
                  value={corState}
                  onChange={(e) => setCorState(e.target.value)}
                >
                  {stateList &&
                    stateList.map((state) => (
                      <MenuItem value={state.id}>{state.name}</MenuItem>
                    ))}
                  {/* <MenuItem value="Delhi">Delhi</MenuItem>
                <MenuItem value="Bihar">Bihar</MenuItem> */}
                </Select>
              </FormControl>
            </div>
          )}

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                required
                label={t("pin")}
                placeholder={t("ex_pin")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: cor_pin !== "" ? true : false }}
                {...register("cor_pin")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">
          {t("probation_confirmation_period")}
        </div>

        <div className="formElementGrp">
          <div className="formElement width13">
            <FormControl className="formControl">
              <Controller
                name="probationDay"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <IntegerTextField
                    // hiddenLabel
                    required
                    label={t("probation_days")}
                    // placeholder=""
                    variant="outlined"
                    className="formTextFieldArea"
                    InputLabelProps={{
                      shrink: probationDay !== "" ? true : false,
                    }}
                    {...field}
                    autoComplete="probation"
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <Controller
                name="priorAddressChangeDays"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <IntegerTextField
                    // hiddenLabel
                    required
                    label={t("address_change_notice")}
                    // placeholder=""
                    variant="outlined"
                    className="formTextFieldArea"
                    InputLabelProps={{
                      shrink: priorAddressChangeDays !== "" ? true : false,
                    }}
                    {...field}
                    autoComplete="address_change_notice"
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <Controller
                name="priorLeaveNoticeDays"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <IntegerTextField
                    // hiddenLabel
                    required
                    label={t("prior_leave_notice")}
                    // placeholder=""
                    variant="outlined"
                    className="formTextFieldArea"
                    InputLabelProps={{
                      shrink: priorLeaveNoticeDays !== "" ? true : false,
                    }}
                    {...field}
                    autoComplete="prior_leave"
                  />
                )}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">{t("termination_conditions")}</div>
        <div className="formElementGrp">
          <div className="formElement width13 ">
            <FormControl className="formControl">
              <Controller
                name="absentDays"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <IntegerTextField
                    // hiddenLabel
                    required
                    label={t("continuous_absent_days")}
                    // placeholder="Ex: 700001"
                    variant="outlined"
                    className="formTextFieldArea"
                    // InputLabelProps={{ shrink: absentDays !== "" ? true : false }}
                    {...field}
                    autoComplete="cont_absent_days"
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <Controller
                name="noticePeriod"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <IntegerTextField
                    // hiddenLabel
                    required
                    label={t("notice_period")}
                    // placeholder=""
                    variant="outlined"
                    className="formTextFieldArea"
                    InputLabelProps={{
                      shrink: noticePeriod !== "" ? true : false,
                    }}
                    {...field}
                    autoComplete="my_Field_off"
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="formElement width13 ">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                disabled
                required
                label={t("salary_in_lieu_of_notice")}
                placeholder={t("enter_number_of_months")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: noticePeriod !== "" ? true : false }}
                value={noticePeriod}
                autoComplete="my_Field_off"
                // disabled={true}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">{t("define_salary_breakup")}</div>

        <div className="formElementGrp">
          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectEmploymentType">
                {t("employment_type")}
              </InputLabel>
              <Controller
                name="employmentTitle"
                control={control}
                defaultValue={"Mr"}
                render={({ field }) => (
                  <Select
                    labelId="SelectEmploymentType"
                    id="Select-SelectEmploymentType"
                    label={t("employment_type")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    <MenuItem value="payroll">{t("on_payroll")}</MenuItem>
                    <MenuItem value="FTC">{t("fixed_term_contracts")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>
          {/* <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("gross_per_month")}
                required
                placeholder={t("gpm_ex")}
                variant="outlined"
                className="formTextFieldArea"
                // InputProps={{
                //   inputComponent: NumericFormatCustom,
                // }}
                {...register("gpm")}
              />
            </FormControl>
          </div> */}

          <div className="formElement w180">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("annual_ctc")}
                required
                placeholder={t("ctc_ex")}
                variant="outlined"
                className="formTextFieldArea"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                autoComplete="my_Field_off"
                {...register("ctc")}
              />
            </FormControl>
          </div>

          <Button
            onClick={() => {
              setShouldCalculate(true);
            }}
            className="dfultPrimaryBtn "
          >
            {t("calculate")}
          </Button>

          <div className="formElement w180">
            <FormControl className="formControl">
              <InputLabel id="selectUnits">{t("currency")}*</InputLabel>
              <Select
                required
                labelId="selectUnits"
                id="employee-units"
                value={defaultCurrency}
                label={t("currency")}
                onChange={(e) => setdefaultCurrency(e.target.value)}
                className="formInputField"
                variant="outlined"
              >
                {currencyList.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {/* <SalaryTable
          salaryInfoList={salaryInfoList}
          setSalaryInfoList={setSalaryInfoList}
          gpm={gpm}
          salaryBrkUps={salaryBrkUps}
        /> */}

        <LoiSalaryTable
          salaryInfoList={salaryInfoList}
          setSalaryInfoList={setSalaryInfoList}
          gpm={gpm}
          // ctcAmouunt={ctcAmouunt}
          ctcAmouunt={ctc}
          shouldCalculate={shouldCalculate}
          setShouldCalculate={setShouldCalculate}
          currency={defaultCurrency}
        />

        {/* end salary table */}

        <div class="tskElemHeding mt10">{t("other_terms_and_conditions")}</div>

        <div className="documentAddGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                required
                // hiddenLabel
                label={t("other_terms_and_conditions")}
                placeholder={t("ex_add_new_terms_and_conditions")}
                variant="outlined"
                className="formTextFieldArea"
                inputRef={tacRef}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>
          {/* <Button
            className="addDocBtn"
            variant="contained"
            startIcon={<Add />}
            onClick={(e) => {
              console.log("tac ref data=", tacRef.current.value);
              const temp = [
                ...staticDodList,
                { name: tacRef.current.value, id: tacRef.current.value },
              ];
              setStaticDodList(temp);
              tacRef.current.value = "";
            }}
          >
            {t("add")}
          </Button> */}
          <Button
            className="addDocBtn"
            variant="contained"
            startIcon={<Add />}
            onClick={(e) => {
              if (!tacRef.current.value.trim()) {
                toast.error(t("please_enter_other_terms_and_conditions"));
                return;
              }

              console.log("tac ref data=", tacRef.current.value);
              const temp = [
                ...staticDodList,
                { name: tacRef.current.value, id: tacRef.current.value },
              ];
              setStaticDodList(temp);
              tacRef.current.value = "";
            }}
          >
            {t("add")}
          </Button>
        </div>
        {staticDodList && staticDodList.length > 0 && (
          <>
            <div className="listDocRequierd">
              <div className="docLstHd">{t("terms_and_condition_list")}</div>
              <ul>
                {staticDodList &&
                  staticDodList.map((dodItem, index) => (
                    <li>
                      <div className="listDocREle">
                        <span>{dodItem.name}</span>
                        <span>
                          <IconButton
                            className="removeDoC"
                            onClick={(e) => {
                              const temp = [...staticDodList];
                              temp.splice(index, 1);
                              setStaticDodList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

            {showFutureCheckbox && (
              <div className="formElement">
                <FormControl className="formControl">
                  <FormGroup aria-label="position" row className="feformGroup">
                    <FormControlLabel
                      className="formCheckBox"
                      // value="Cost Center"
                      control={
                        <Checkbox
                          value={saveForFutureAppoint}
                          onChange={(e) =>
                            setSaveForFutureAppoint(e.target.checked)
                          }
                        />
                      }
                      label={t("save_for_this_future_appointment_letter")}
                      labelPlacement="End"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            )}
          </>
        )}

        {/* {newTermsAndConditionsList && newTermsAndConditionsList.length > 0 && (
          <div className="formElement">
            <FormControl className="formControl">
              <FormGroup aria-label="position" row className="feformGroup">
                <FormControlLabel
                  className="formCheckBox"
                  // value="Cost Center"
                  control={<Checkbox />}
                  label="Save for this future Appointment letter"
                  labelPlacement="End"
                />
              </FormGroup>
            </FormControl>
          </div>
        )} */}

        <div className="formElementGrp">
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("cc_email_id")}
                required
                placeholder={t("enter_cc_email_id")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="my_first_name_off"
                value={ccId}
                onChange={(e) => {
                  setCCid(e.target.value);
                }}
              />
            </FormControl>
          </div>

          <Button
            className="dfultPrimaryBtn "
            startIcon={<Add />}
            onClick={(e) => {
              const temp = [...ccLoiEmaiIds];
              // ;
              console.log("ccId");
              if (!ccId.trim()) {
                toast.error(t("please_enter_email_id"));
                return;
              }
              if (ccId.trim() && !validateEmail(ccId.trim())) {
                toast.error(t("please_enter_valid_email_id"));
                return;
              }

              temp.push(ccId);
              setCCid("");
              setCcLoiEmailIds(temp);
            }}
          >
            {t("add")}
          </Button>
        </div>
        <div className="complaintList mt10">
          <ul>
            {ccLoiEmaiIds?.map((item, index) => (
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="complstDtlGrp ">{item}</div>
                  <IconButton
                    style={{ color: "red" }}
                    onClick={() => {
                      const temp = [...ccLoiEmaiIds];
                      temp.splice(index, 1);
                      setCcLoiEmailIds(temp);
                    }}
                  >
                    <Trash />
                  </IconButton>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <Button
          ref={appointRef}
          style={{ display: "none" }}
          onClick={(e) => {
            console.log("button clicked ");
            let data = getValues();
            const { location } = data;
            let locData = null;
            if (location) {
              locData = unitList.find((item) => item.unitId === location);
            }
            data.location = locData;

            if (!loiSelectedCandidate) {
              data.salaryInfoList = salaryInfoList;
              data.candidateI = 0;
            } else {
              data.salaryInfoList = salaryBrkUps;
              data.candidateId = loiSelectedCandidate.candidateId;
            }
            data.staticDodList = staticDodList;
            data.country = selectedCountry;
            const tempState = stateList.find(
              (state) => state.id === selectedState
            );
            data.state = tempState;
            data.position = selectedPosition;
            data.department = selectedDepartment;
            data.reportingHead = selectedReportingHead;
            data.mode = fromIssuedLoi;

            data.joiningdate = joiningdate;
            data.dateOfBirth = dateOfBirth;
            data.orgData = orgData;
            // data.minprovp = minprovp;
            // data.maxprovp = maxprovp;
            // data.minExtProvp = minExtProvp;
            // data.maxExtProvp = maxExtProvp;

            if (loiSelectedCandidate) {
              data.loiSelectedCandidate = loiSelectedCandidate;
            }
            // data.newTermsAndConditionsList = newTermsAndConditionsList;
            const tempCorState = stateList.find(
              (state) => state.id === corState
            );
            data.corAddress = {
              cor_address1: cor_address1,
              cor_address2: cor_address2,
              cor_pin: cor_pin,
              cor_city: cor_city,
              cor_country: corCountry,
              cor_state: tempCorState,
            };
            data.saveForFutureAppoint = saveForFutureAppoint;
            data.currency = defaultCurrency;
            data.ccLoiEmaiIds = ccLoiEmaiIds;
            // data.absentPeriod = absentPeriod;
            handleShowPreview(data);
          }}
        ></Button>
      </>
    );
  }
);
