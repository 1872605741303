import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getHealthDto } from "../../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import DashboardServices from "../../../../../../services/DashboardServices";
import SecureIndexedDB from "../../../../../../utils/IndexedDB";

export const useValidateVital = () => {
  const { t } = useTranslation();
  const [vitalUnitList, setVitalUnitList] = useState([]);
  const [VitalErrorList, setVitalErrorList] = useState([]);

  const getVitalUnits = async () => {
    const diseasetring = await SecureIndexedDB.getItem("vitalUnitList");
    if (diseasetring) {
      setVitalUnitList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getVitalUnits(reqDto).then((response) => {
      if (response.data) {
        setVitalUnitList(response.data);
        SecureIndexedDB.setItem("vitalUnitList", JSON.stringify(response.data));
      }
    });
  };
  const validateVitals = (vitals, patientAge, patientGender) => {
    const tempVitals = Object.values(vitals);
    let isValid = true;
    const problemList = [];

    const vitalCodeNameMap = vitalUnitList?.reduce((acc, curr) => {
      const { vitalCode, vitalName } = curr;
      if (acc[vitalCode]) {
        return acc;
      } else {
        acc[vitalCode] = vitalName;
        return acc;
      }
    }, {});

    // const vitalCodeNameMap = {
    //   BP: "Blood Pressure",
    //   TEMPERATURE: "Temperature",
    //   SPO2: "SPO2",
    //   RESPIRATION_RATE: "Respiratory Rate",
    //   HEART_RATE: "Heart Rate",
    //   WEIGHT: "Weight",
    //   HEIGHT: "Height",
    //   LMP: "LMP",
    //   UPPER_ARM_CIRCUMFERENCE: "Upper Arm Circumference",
    //   HEAD_CIRCUMFERENCE: "Head Circumference",
    // };

    const vitalCodeList = [
      "BP",
      "TEMPERATURE",
      "SPO2",
      "RESPIRATION_RATE",
      "HEART_RATE",
      "WEIGHT",
      "HEIGHT",
    ];

    if (patientGender === "Female" && patientAge >= 12) {
      vitalCodeList.push("LMP");
    }

    if (patientAge <= 4) {
      vitalCodeList.push("UPPER_ARM_CIRCUMFERENCE");
      vitalCodeList.push("HEAD_CIRCUMFERENCE");
    }
    let list = [];

    vitalCodeList.forEach((item) => {
      if (!vitals[item]) {
        // setVitalErrorList(prev=>[...prev,`${item} `])
        isValid = false;
        // list.push(`${vitalCodeNameMap[item]} is not provided `);
        list.push(vitalCodeNameMap[item]);
      } else if (
        vitals[item].vitalCode === "BP" &&
        vitals[item].vitalValue === "/"
      ) {
        isValid = false;
        // list.push(`${vitalCodeNameMap[item]} is not provided`);
        list.push(vitalCodeNameMap[item]);
      } else if (
        vitals[item].vitalValue === null ||
        vitals[item].vitalValue === ""
      ) {
        isValid = false;
        // list.push(`${vitalCodeNameMap[item]} is not provided`);
        list.push(vitalCodeNameMap[item]);
      }
    });
    let missingVitalString = list?.join(",");
    if (missingVitalString?.length > 0) {
      list = [missingVitalString + " " + t("are_not_provided")];
    }

    // if (!isValid) {
    //   setVitalErrorList((prev) => [...list]);

    //   setShowConfirmation(true);
    //   return false;
    // }

    const bp = tempVitals.find((item) => item.vitalCode === "BP");
    const temp = tempVitals.find((item) => item.vitalCode === "TEMPERATURE");
    if (bp) {
      const bpList = bp.vitalValue.split("/");
      const sistolic = parseInt(bpList[0]);
      const diastolic = parseInt(bpList[1]);

      if (sistolic || diastolic) {
        if (sistolic === "" || !sistolic) {
          problemList.push(t("sistolic_pressure_cannot_be_empty"));
          // toast.error(t("sistolic_pressure_cannot_be_empty"));
          isValid = false;
        }

        if (diastolic === "" || !diastolic) {
          problemList.push(t("diastolic_pressure_cannot_be_empty"));
          // toast.error(t("diastolic_pressure_cannot_be_empty"));
          isValid = false;
        }

        if (sistolic < diastolic) {
          problemList.push(
            t("sistolic_pressure_must_be_greater_than_diastolic")
          );
          // toast.error(t("sistolic_pressure_must_be_greater_than_diastolic"));
          isValid = false;
        }

        if (sistolic - diastolic < 30) {
          problemList.push(
            t(
              "sistolic_and_diastolic_pressure_difference_must_be_greater_than_30"
            )
          );
          // toast.error(
          //   t(
          //     "sistolic_and_diastolic_pressure_difference_must_be_greater_than_30"
          //   )
          // );
          isValid = false;
        }
      }
    }

    if (temp) {
      const unit = temp.unit;
      const value = parseFloat(temp.vitalValue);
      if (unit.includes("C")) {
        if (value > 41.1 || value < 32.22) {
          problemList.push(t("temperature_range_error_in_celsius"));
          // toast.error(t("temperature_range_error_in_celsius"));
          isValid = false;
        }
      } else if (unit.includes("F")) {
        if (value > 106 || value < 90) {
          problemList.push(t("temperature_range_error_in_fahrenheit"));
          // toast.error(t("temperature_range_error_in_fahrenheit"));
          isValid = false;
        }
      }
    }
    console.log("useVital value =", [...list, ...problemList]);

    setVitalErrorList([...list, ...problemList]);

    return isValid;
  };

  useEffect(() => {
    getVitalUnits();
  }, []);
  return { validateVitals, VitalErrorList };
};
