import { Add, ArrowBack, Search } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Download, Trash } from "react-bootstrap-icons";
import { getPolicies, deletePolicy } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
// import {downloadFileFromUrl} from "../../../../utils/Utils"
import { downloadFileFromUrl } from "../../../../utils/FileUtils";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function DefinePolicy({
  handelCloseViewPolicies,
  handelOpenAddPolicies,
  refresh,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [loading, setLoading] = useState(true);

  const [policyData, setPolicyData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPolicyData, setFilteredPolicyData] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [policyToDelete, setPolicyToDelete] = useState(null);

  useEffect(() => {
    fetchPolicyData();
  }, [refresh]);

  const fetchPolicyData = () => {
    getPolicies(userDetails.orgId)
      .then((response) => {
        console.log("getPolicies", response.data);
        setPolicyData(response.data);
        setFilteredPolicyData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = policyData.filter(
      (policies) =>
        policies.policy &&
        policies.policy.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredPolicyData(filteredData);
  };

  // const handleDeletePolicy = (policyId) => {
  //   console.log("handleDeletePolicy", policyId);
  //   deletePolicy(policyId, userDetails.userId , userDetails.orgId).then((response) => {
  //     console.log("handleDeletePolicy data deleted", response.data);
  //     if (response.data.returnValue === "1") {
  //       toast.success(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       fetchPolicyData();
  //     }
  //   });
  // };

  const handleDeletePolicy = (policyId) => {
    console.log("handleDeletePolicy", policyId);
    setPolicyToDelete(policyId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (policyToDelete) {
      deletePolicy(policyToDelete, userDetails.userId).then((response) => {
        console.log("data deleted", response.data);
        if (response.data.returnValue === "1") {
          toast.success(t("policy_deleted_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchPolicyData();
          setOpenModal(false); // Close the modal after deletion
        }
      });
    }
  };

  useEffect(()=>{
    setBookSteps([
    {
      target: ".viewPolicyStepOne",
      content: "Use this search bar to find specific policies quickly.",
      disableBeacon: true,
    },
    {
      target: ".viewPolicyStepTwo",
      content: "Click this button to add a new policy.",
      disableBeacon: true,
    },
    {
      target: ".viewPolicyStepThree",
      content: "This section displays a list of all the policies.",
      disableBeacon: true,
    },
    {
      target: ".viewPolicyStepFour",
      content: "Use this button to delete a policy from the list.",
      disableBeacon: true,
    },
    {
      target: ".viewPolicyStepFive",
      content: "These action buttons let you download your policies.",
      disableBeacon: true,
    }
  ]
  )
  },[])

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDownLoadFile = (policy) => {
    console.log("handleDownLoadFile", policy);
    if (policy.systemFileName !== "") {
      console.log("test ok fine", policy.systemFileName, policy.fileName);
      // downloadFileFromUrl(`${policy.folderName}/${policy.systemFileName}`, policy.fileName)
      downloadFileFromUrl(policy.fileLink, policy.fileName);
    }
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["viewPolicyAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="searchTasklist viewPolicyStepOne">
            <input
              type="text"
              class="serchTaskInput"
              placeholder={t("search_policy")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn viewPolicyStepTwo">
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {handelOpenAddPolicies();
                if (run["viewPolicyAD"] === true) {
                  setRun({
                    addPolicyAD: true,
                  });
                }
              }}
            >
              {t("policy")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : policyData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>
              {t("no_policy_added_yet")}
              <br />
              <span className="sallMsg">
                {t("click_add_policy_button_to_create_new_policy")}
              </span>
            </div>
          ) : (
            <div className="TableContainer viewPolicyStepThree">
              <table className="taskListTable">
                <thead className="taskListtableHeader">
                  <tr>
                    <th className="width100">{t("sl_no")}</th>
                    <th>{t("policy_name")}</th>
                    <th>{t("onboard_checklist")}</th>
                    <th className="width100">{t("action")}</th>
                  </tr>
                </thead>
                <tbody className="scrolableTableBody">
                  {filteredPolicyData.map((policy, index) => (
                    <tr key={index}>
                      <td>
                        <div className="tdText">{index + 1}</div>
                      </td>
                      <td>
                        <div className="tdText">{policy.policy}</div>
                      </td>
                      <td>
                        <div className="tdText">
                          {policy.onboardChecklist === "Y" ? t("yes") : t("no")}
                        </div>
                      </td>

                      <td>
                        <div className="tblActionBtnGrp viewPolicyStepFive">
                          <IconButton
                            onClick={() => handleDownLoadFile(policy)}
                          >
                            <Download />
                          </IconButton>
                          <IconButton
                            className="removeRowBtn viewPolicyStepFour"
                            onClick={() => handleDeletePolicy(policy.id)}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}

                  {/* <tr >
                  <td>
                    <div className="tdText">Audit Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Election Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Finance Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Fundraising Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Program Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                </tbody>
              </table>
            </div>
          )}

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // width: "250px",
                // height: "250px",
                maxWidth: "600px",
                minHeight: "150px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2 id="modal-modal-title">
                {t("do_you_want_to_delete_this_policy")} ?
              </h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  // variant="contained"
                  // color="secondary"
                  className="dfultPrimaryBtn"
                  onClick={confirmDelete}
                >
                  {t("yes")}
                </Button>
                <Button
                  // variant="contained"
                  className="dfultDarkSecondaryBtn"
                  onClick={handleCloseModal}
                >
                  {t("no")}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
