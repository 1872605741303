import { Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  DeviceHddFill,
  HeartPulse,
  Lungs,
  ThermometerHalf,
} from "react-bootstrap-icons";
import BP from "../../../../dashboard/image/BP";
import { useTranslation } from "react-i18next";
import SPO2 from "../../../../dashboard/image/SPO2";
import {
  BloodtypeOutlined,
  Edit,
  HeightOutlined,
  MonitorWeightOutlined,
  Save,
} from "@mui/icons-material";
import UAC from "../../../../dashboard/image/UAC";
import LMP from "../../../../dashboard/image/LMP";
import dayjs from "dayjs";
import HeadC from "../../../../dashboard/image/HeadC";
import {
  getHealthDto,
  getVitalSvgAccordingToVitalType,
} from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import DashboardServices from "../../../../../services/DashboardServices";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";
import { useValidateVital } from "./hook/useValidateVital";
import { centerSocket, doctorSocket } from "../../../../../socket";
import { toast } from "react-toastify";

export const PatientVitalDoctorSide = ({
  data,
  patientAge,
  patientGender,
  showBloodgroup,
  handleSelectBloodGroup,
  showSaveButton = false,
  handleSaveVitals,
  handleChangeEditMode,
  extraData,
  updateData,
}) => {
  const { t } = useTranslation();
  const { validateVitals, VitalErrorList } = useValidateVital();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [vitals, setVitals] = useState({});
  const [firstTimeVital, setFirstTimeVital] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const [vitalUnitMap, setVitalUnitMap] = useState(null);
  const [vitalUnitList, setVitalUnitList] = useState([]);

  const [temperatureUnit, setTemperatureUnit] = useState(null);
  const [heightUnit, setHeightUnit] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);
  const [upperArmUnit, setUpperArmUnit] = useState(null);
  const [headUnit, setHeadUnit] = useState(null);
  const [bloodGroup, setBloodGroup] = useState("");
  const [listingData, setListingData] = useState([]);

  const [lmp, setLmp] = useState(null);

  const getUnitFromVitalCode = (vitalCode) => {
    const tempValue = vitalUnitMap?.[vitalCode];
    return tempValue?.[0] || null;
  };

  const mapUnitsToVitals = () => {
    const tempVitalUnitMap = {};
    vitalUnitList.forEach((item) => {
      if (!tempVitalUnitMap[item.vitalCode]) {
        tempVitalUnitMap[item.vitalCode] = [item.vitalUnit];
      } else {
        tempVitalUnitMap[item.vitalCode].push(item.vitalUnit);
      }
    });
    setVitalUnitMap(tempVitalUnitMap);
    const tempDefaultUnit =
      vitals?.TEMPERATURE?.unit || tempVitalUnitMap?.["TEMPERATURE"]?.[0];
    if (tempDefaultUnit) {
      setTemperatureUnit(tempDefaultUnit);
    }

    const heightDefaultUnit =
      vitals?.HEIGHT?.unit || tempVitalUnitMap?.["HEIGHT"]?.[0];
    if (heightDefaultUnit) {
      setHeightUnit(heightDefaultUnit);
    }

    const widthDefaultUnit =
      vitals?.WEIGHT?.unit || tempVitalUnitMap?.["WEIGHT"]?.[0];
    if (widthDefaultUnit) {
      setWeightUnit(widthDefaultUnit);
    }

    const headDefaultUnit =
      vitals?.HEAD_CIRCUMFERENCE?.unit ||
      tempVitalUnitMap?.["HEAD_CIRCUMFERENCE"]?.[0];
    if (headDefaultUnit) {
      setHeadUnit(headDefaultUnit);
    }

    const upperArmDefaultUnit =
      vitals?.UPPER_ARM_CIRCUMFERENCE?.unit ||
      tempVitalUnitMap?.["UPPER_ARM_CIRCUMFERENCE"]?.[0];
    if (upperArmDefaultUnit) {
      setUpperArmUnit(upperArmDefaultUnit);
    }
  };

  const getVitalUnits = async () => {
    const diseasetring = await SecureIndexedDB.getItem("vitalUnitList");
    let tempVitalUnitlist = [];
    if (diseasetring) {
      tempVitalUnitlist = JSON.parse(diseasetring);
      setVitalUnitList(tempVitalUnitlist);
      return tempVitalUnitlist;
    }

    const reqDto = await getHealthDto();
    tempVitalUnitlist = await DashboardServices.getVitalUnits(reqDto).then(
      (response) => {
        if (response.data) {
          setVitalUnitList(response.data);
          SecureIndexedDB.setItem(
            "vitalUnitList",
            JSON.stringify(response.data)
          );
        }
      }
    );
    return tempVitalUnitlist;
  };

  const getVitalNameFromVitalCode = (vitalCode) => {
    const vitalItem = vitalUnitList?.find((item) => {
      return item.vitalCode === vitalCode;
    });
    if (vitalItem) {
      return vitalItem.vitalName;
    } else {
      return "";
    }
  };

  const getTemperaturePlaceholder = useMemo(() => {
    const temp = vitals?.["TEMPERATURE"];
    if (temp) {
      const { unit } = temp;
      if (unit) {
        return unit.includes("C") ? t("eg_35") : t("eg_96_6");
      } else {
        return "";
      }
    } else {
      if (vitalUnitMap?.["TEMPERATURE"]?.length > 0) {
        const defaultUnit = vitalUnitMap?.["TEMPERATURE"]?.[0];
        if (defaultUnit.includes("F")) {
          return t("eg_96_6");
        } else if (defaultUnit.includes("C")) {
          return t("eg_35");
        }
      } else {
        return "";
      }
    }
    return "";
  }, [vitals, vitalUnitMap]);

  const getDummyVital = (vitalCode, vitalUnitList) => {
    let vitalNameMap =
      vitalUnitList?.reduce((acc, curr) => {
        const temp = { ...acc };
        const { vitalCode, vitalName } = curr;

        if (temp[vitalCode]) {
          return temp;
        } else {
          temp[vitalCode] = vitalName;
          return temp;
        }
      }, {}) || {};

    let vitalUnitMap = vitalUnitList?.reduce((acc, curr) => {
      const temp = { ...acc };
      const { vitalCode, vitalUnit } = curr;

      if (temp[vitalCode]) {
        return temp;
      } else {
        temp[vitalCode] = vitalUnit;
        return temp;
      }
    }, {});

    let tempVital = {
      vitalName: vitalNameMap[vitalCode],
      unit: vitalUnitMap[vitalCode] || null,
      vitalValue: vitalCode === "BP" ? "/" : "",
      // recodedTime: "",
      isNormal: "Y",
      vitalCode,
      updatedData: {
        vitalValue: vitalCode === "BP" ? "/" : "",
        unit: vitalUnitMap[vitalCode] || null,
        foot: "",
        inch: "",
      },
    };
    return tempVital;
  };

  let handleVital = async (data) => {
    const unitVitalList = await getVitalUnits();

    let vitalList = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
      "WEIGHT",
      "HEIGHT",
    ];

    if (patientAge <= 4) {
      vitalList.push("HEAD_CIRCUMFERENCE");
      vitalList.push("UPPER_ARM_CIRCUMFERENCE");
    }

    if (patientAge >= 12 && patientGender === "Female") {
      vitalList.push("LMP");
    }

    let currentVitals = null;
    if (data?.vitals?.length >= 0) {
      currentVitals = data?.vitals;
    } else {
      currentVitals = extraData?.reqValue?.vitals || [];
    }

    let tempVitalList = [];
    currentVitals?.forEach((vital) => {
      const { vitalCode, unit, vitalValue } = vital;
      vitalList = vitalList.filter((item) => item !== vitalCode);
      if (!vital.vitalValue) {
        setEditMode(true);
      }
      let foot = "";
      let inch = "";
      if (vitalCode === "HEIGHT") {
        setHeightUnit(unit);
      }
      if (vitalCode === "HEIGHT" && unit === "ft~in") {
        const tempHeight = parseFloat(vitalValue);
        foot = Math.floor(tempHeight / 12) || "";
        inch = tempHeight % 12 || "";
      }
      tempVitalList.push({
        ...vital,
        updatedData: {
          vitalValue: vital.vitalValue,
          unit: vital.unit || null,
          foot: foot,
          inch: inch,
        },
      });
    });
    if (vitalList?.length > 0) {
      setEditMode(true);
    }
    vitalList?.forEach((item) => {
      tempVitalList.push(getDummyVital(item, unitVitalList));
    });
    //

    const vitalMap = tempVitalList?.reduce((acc, curr) => {
      const { vitalCode } = curr;
      acc[vitalCode] = curr;
      return acc;
    }, {});
    setVitals(vitalMap);
    if (firstTimeVital) {
      handleSaveVitals(tempVitalList);
      setFirstTimeVital(false);
      updateData({ vitals: tempVitalList });
      setListingData(tempVitalList);
    }
  };

  useEffect(() => {
    if (vitalUnitList && vitalUnitList.length > 0) {
      mapUnitsToVitals();
    }
  }, [vitalUnitList]);

  useEffect(() => {
    getVitalUnits();
    // ;
  }, []);

  useEffect(() => {
    if (data) {
      // const vitalMap = data?.vitals?.reduce((acc, curr) => {
      //   const { vitalCode } = curr;
      //   acc[vitalCode] = curr;
      //   return acc;
      // }, {});
      // setVitals(vitalMap);
      handleVital(data);

      setFirstTimeVital(false);
    }
  }, [data]);

  useEffect(() => {
    doctorSocket.on("uploadFileFromCenter", handleFileData);
    return () => {
      doctorSocket.off("uploadFileFromCenter", handleFileData);
    };
  }, [doctorSocket]);

  const handleFileData = (payload) => {
    const { data } = payload;
    const { vitals } = data;
    // toast.error("handle file received files");

    if (vitals) {
      const temp = vitals?.reduce((acc, curr) => {
        const { vitalCode } = curr;
        acc[vitalCode] = curr;
        return acc;
      }, {});
      setVitals(temp);
      setListingData(vitals);
      // setEditMode(false);
    }
    // setPreviousDocuments((prev) => {
    //   return [...prev, ...data];
    // });
  };

  const updateVital = (dataObj) => {
    //
    // const data = Object.values(dataObj);
    // return;
    console.log("data =", dataObj);
    setVitals(dataObj);
    // handleSaveVitals(data);
  };

  const handleChangeSistolic = (index, e) => {
    let value = e.target.value;

    //need bug fixes

    let tempVital = { ...vitals };
    if (!vitals[index]) {
      const vitalNme = getVitalNameFromVitalCode("BP");
      tempVital[index] = {
        vitalName: vitalNme,
        unit: getUnitFromVitalCode("BP") || null,
        vitalValue: "/",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: "BP",
      };
    }
    let sistolic = tempVital[index].vitalValue.split("/")[0] || "";
    let diastolic = tempVital[index].vitalValue.split("/")[1] || "";

    if (value === "") {
      const tempValue = `${value}/${diastolic}`;
      if (diastolic === "") {
        tempVital[index].isNormal = "Y";
      }
      tempVital[index].vitalValue = tempValue;
      updateVital(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    sistolic = parseInt(value);
    if (isNaN(sistolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    updateVital(tempVital);
  };

  const handleChangeDiastolic = (index, e) => {
    let value = e.target.value;

    //need bug fixes

    let tempVital = { ...vitals };
    if (!vitals[index]) {
      const vitalNme = getVitalNameFromVitalCode("BP");
      tempVital[index] = {
        vitalName: vitalNme,
        unit: getUnitFromVitalCode("BP") || null,
        vitalValue: "/",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: "BP",
      };
    }
    let sistolic = tempVital[index].vitalValue.split("/")[0] || "";
    let diastolic = tempVital[index].vitalValue.split("/")[1] || "";

    if (value === "") {
      const tempValue = `${sistolic}/${value}`;
      if (sistolic === "") {
        tempVital[index].isNormal = "Y";
      }
      tempVital[index].vitalValue = tempValue;
      updateVital(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    diastolic = parseInt(value);
    if (isNaN(diastolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    updateVital(tempVital);
  };

  const handleChangeHeartRage = (vitalCode, e) => {
    const value = e.target.value;

    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }

    let tempVitals = { ...vitals };
    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode("HEART_RATE");
      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: getUnitFromVitalCode("HEART_RATE"),
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (value === "" || value === null) {
      tempVitals[vitalCode].vitalValue = value;
      tempVitals[vitalCode].isNormal = "Y";
      updateVital(tempVitals);
    }
    const prValue = parseFloat(value.slice(0, 3));

    if (!prValue || isNaN(prValue) || prValue < 0) {
      return;
    }

    tempVitals[vitalCode].vitalValue = prValue;

    if (patientAge >= 6 && patientAge <= 12) {
      if (prValue >= 70 && prValue <= 120) {
        tempVitals[vitalCode].isNormal = "Y";
      } else if (prValue >= 121 && prValue <= 140) {
        tempVitals[vitalCode].isNormal = "M";
      } else {
        tempVitals[vitalCode].isNormal = "N";
      }
    } else {
      if (prValue >= 60 && prValue <= 100) {
        tempVitals[vitalCode].isNormal = "Y";
      } else if (prValue >= 101 && prValue <= 120) {
        tempVitals[vitalCode].isNormal = "M";
      } else {
        tempVitals[vitalCode].isNormal = "N";
      }
    }

    updateVital(tempVitals);
  };

  const handleChangeRR = (value, vitalCode) => {
    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }

    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: getUnitFromVitalCode(vitalCode),
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    const rrValue = parseFloat(value.slice(0, 3));

    if (value === "" || !value) {
      tempVitals[vitalCode].vitalValue = value;
      tempVitals[vitalCode].isNormal = "Y";
      updateVital(tempVitals);
    }

    if (!rrValue || isNaN(rrValue) || rrValue < 0) {
      return;
    }

    tempVitals[vitalCode].vitalValue = rrValue;
    if (rrValue > 20 || rrValue < 10) {
      tempVitals[vitalCode].isNormal = "N";
    } else {
      tempVitals[vitalCode].isNormal = "Y";
    }
    updateVital(tempVitals);
  };

  const handleChangeSpo2 = (value, vitalCode) => {
    const inputValue = value ? value.trim() : value;

    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }

    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: getUnitFromVitalCode(vitalCode) || "%",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let [spoBD, spoAD] = inputValue.split(".").map((part) => part || "");

    if (!spoAD) {
      spoAD = "";
    }

    if (spoBD.length > 2) {
      if (parseFloat(spoBD) === 100) {
        spoBD = "100";
        spoAD = "";
      } else {
        return;
        spoBD = spoBD.slice(0, 2);
      }
    }

    if (spoAD.length > 2 && parseFloat(spoAD) > 0) {
      if (parseFloat(spoBD) >= 100) {
        spoAD = "";
      } else {
        spoAD = spoAD.slice(0, 2);
      }
    }

    let temp = "";
    if (spoAD.length > 0) {
      temp = `${spoBD}.${spoAD}`;
    } else {
      temp = inputValue.includes(".") ? `${spoBD}.` : spoBD;
    }

    tempVitals[vitalCode].vitalValue = temp;

    // let spo = value.split(".");
    let spo = parseFloat(temp);
    if (temp === "") {
      tempVitals[vitalCode].isNormal = "Y";
    } else {
      if (spo >= 95 && spo <= 100) {
        tempVitals[vitalCode].isNormal = "Y";
      } else if (spo >= 91 && spo <= 95) {
        tempVitals[vitalCode].isNormal = "M";
      } else {
        tempVitals[vitalCode].isNormal = "N";
      }
    }

    updateVital(tempVitals);
  };

  const getTemperatureStatus = (tmpTemparature, unit) => {
    let isNormal = "Y";
    if (tmpTemparature === "") {
      isNormal = "Y";
    } else {
      const tempTemperature = parseFloat(tmpTemparature);
      if (unit.includes("F")) {
        if (tempTemperature >= 97 && tempTemperature <= 99) {
          isNormal = "Y";
        } else if (tempTemperature > 99 && tempTemperature <= 100.3) {
          isNormal = "M";
        } else {
          isNormal = "N";
        }
      } else if (unit.includes("C")) {
        if (tempTemperature >= 36.1 && tempTemperature <= 37.2) {
          isNormal = "Y";
        } else if (tempTemperature > 37.2 && tempTemperature <= 37.9) {
          isNormal = "M";
        } else {
          isNormal = "N";
        }
      }
    }
    return isNormal;
  };

  const handleChangeTemparature = (value, vitalCode) => {
    const inputValue = value ? value.trim() : value;
    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }

    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: temperatureUnit || null,
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = inputValue.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (unit.includes("F")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = tmparr[0].slice(0, 3);
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = tmparr[1].slice(0, 2);
      }

      if (inputValue.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[vitalCode].vitalValue = tmpTemparature;

      const isNormal = getTemperatureStatus(tmpTemparature, unit);
      tempVitals[vitalCode].isNormal = isNormal;
    } else if (unit.includes("C")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = parseInt(tmparr[0].slice(0, 3));
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = parseInt(tmparr[1].slice(0, 2));
      }

      if (isNaN(tmpBD) || isNaN(tmpAD)) {
        return;
      }

      if (inputValue.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[vitalCode].vitalValue = tmpTemparature;
      const tempTemperature = parseFloat(tmpTemparature);

      const isNormal = getTemperatureStatus(tempTemperature, unit);
      tempVitals[vitalCode].isNormal = isNormal;
      // if (tmpTemparature === "") {
      //   tempVitals[vitalCode].isNormal = "Y";
      // } else {
      //   if (tempTemperature >= 36.1 && tempTemperature <= 37.2) {
      //     tempVitals[vitalCode].isNormal = "Y";
      //   } else if (tempTemperature > 37.2 && tempTemperature <= 37.9) {
      //     tempVitals[vitalCode].isNormal = "M";
      //   } else {
      //     tempVitals[vitalCode].isNormal = "N";
      //   }
      // }
    }
    updateVital(tempVitals);
  };

  const handleChangeHeadCircumference = (value, vitalCode) => {
    const inputValue = value ? value.trim() : value;
    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: getUnitFromVitalCode(vitalCode) || "",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = inputValue.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (tmparr[0] && tmparr[0].length > 3) {
      tmpBD = parseInt(tmparr[0].slice(0, 3));
    }
    if (tmparr[1] && tmparr[1].length > 2) {
      tmpAD = parseInt(tmparr[1].slice(0, 2));
    }

    if (isNaN(tmpBD) || isNaN(tmpAD)) {
      return;
    }

    if (inputValue.includes(".")) {
      if (tmpAD.length === 0) {
        tmpTemparature = tmpBD + ".";
      } else {
        tmpTemparature = `${tmpBD}.${tmpAD}`;
      }
    } else {
      tmpTemparature = tmpBD;
    }

    tempVitals[vitalCode].vitalValue = tmpTemparature;
    let tempIsNormal = "";

    tempIsNormal = getHeadCircumferenceColorFromValue(unit, tmpTemparature);

    tempVitals[vitalCode].isNormal = tempIsNormal;

    updateVital(tempVitals);
  };

  function getHeadCircumferenceColorFromValue(unit, tmpTemparature) {
    if (unit === "cm") {
      const tempValue = parseFloat(tmpTemparature);
      if (tmpTemparature === "") {
        return "Y";
      } else {
        if (tempValue >= 32 && tempValue <= 52) {
          return "Y";
        } else {
          return "N";
        }
      }
    } else if (unit === "in") {
      const tempValue = parseFloat(tmpTemparature);

      if (tmpTemparature === "") {
        return "Y";
      } else {
        if (tempValue >= 12.6 && tempValue <= 20.5) {
          return "Y";
        } else {
          return "N";
        }
      }
    }
  }

  const geUpperArmColorFromValue = (unit, tmpTemparature) => {
    if (unit === "cm") {
      const tempValue = parseFloat(tmpTemparature);
      if (tmpTemparature === "") {
        return "Y";
      } else {
        if (tempValue >= 13.5 && tempValue <= 23) {
          return "Y";
        } else if (tempValue >= 11.5 && tempValue <= 13.5) {
          return "M";
        } else {
          return "N";
        }
      }
    } else if (unit === "in") {
      const tempValue = parseFloat(tmpTemparature);

      if (tmpTemparature === "") {
        return "Y";
      } else {
        if (tempValue >= 5.3 && tempValue <= 9) {
          return "Y";
        } else if (tempValue >= 4.5 && tempValue <= 5.3) {
          return "M";
        } else {
          return "N";
        }
      }
    }
  };

  const handleChangeUpperArm = (value, vitalCode) => {
    const inputValue = value ? value.trim() : value;
    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: getUnitFromVitalCode(vitalCode),
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = inputValue.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (tmparr[0] && tmparr[0].length > 3) {
      tmpBD = parseInt(tmparr[0].slice(0, 2));
    }
    if (tmparr[1] && tmparr[1].length > 2) {
      tmpAD = parseInt(tmparr[1].slice(0, 2));
    }

    if (isNaN(tmpBD) || isNaN(tmpAD)) {
      return;
    }

    if (inputValue.includes(".")) {
      if (tmpAD.length === 0) {
        tmpTemparature = tmpBD + ".";
      } else {
        tmpTemparature = `${tmpBD}.${tmpAD}`;
      }
    } else {
      tmpTemparature = tmpBD;
    }

    tempVitals[vitalCode].vitalValue = tmpTemparature;
    let tempIsNormal = "";

    tempIsNormal = geUpperArmColorFromValue(unit, tmpTemparature);
    tempVitals[vitalCode].isNormal = tempIsNormal;

    updateVital(tempVitals);
  };

  const handleChangeWeight = (value, vitalCode) => {
    const inputValue = value ? value.trim() : value;
    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }

    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: weightUnit || "kg",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let weight = parseFloat(inputValue);

    let wtarr = inputValue.split(".");
    let wtBD = "";
    let wtAD = "";

    if (wtarr[0] && wtarr[0].length > 0) {
      wtBD = wtarr[0];
    }
    if (wtarr[1] && wtarr[1].length > 0) {
      wtAD = wtarr[1];
    }

    if (wtarr[0] && wtarr[0].length > 3) {
      wtBD = wtarr[0].slice(0, 3);
    }
    if (wtarr[1] && wtarr[1].length > 2) {
      wtAD = wtarr[1].slice(0, 2);
    }

    if (inputValue === "" || !inputValue) {
      tempVitals[vitalCode].vitalValue = inputValue;
      updateVital(tempVitals);
      return;
    }

    if (!weight || isNaN(weight)) {
      return;
    }

    let tmpWeight = "";

    if (inputValue.includes(".")) {
      if (wtAD.length === 0) {
        tmpWeight = wtBD + ".";
      } else {
        tmpWeight = `${wtBD}.${wtAD}`;
      }
    } else {
      tmpWeight = wtBD;
    }

    tempVitals[vitalCode].vitalValue = tmpWeight;

    updateVital(tempVitals);
  };

  const handleChangeHeight = (value, vitalCode) => {
    const inputValue = value ? value.trim() : value;

    const parsedValue = Number(value);
    if (isNaN(parsedValue)) {
      return;
    }

    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    const unit = tempVitals[vitalCode].unit;
    let height = parseFloat(inputValue);

    let htarr = inputValue.split(".");
    let htBD = "";
    let htAD = "";

    if (htarr[0] && htarr[0].length > 0) {
      htBD = htarr[0];
    }
    if (htarr[1] && htarr[1].length > 0) {
      htAD = htarr[1];
    }

    if (inputValue === "") {
      tempVitals[vitalCode].vitalValue = inputValue;
      updateVital(tempVitals);
      return;
    }

    if (!height || isNaN(height)) {
      return;
    }

    if (unit === "cm") {
      if (htarr[0] && htarr[0].length > 3) {
        htBD = htarr[0].slice(0, 3);
      }
      if (htarr[1] && htarr[1].length > 2) {
        htAD = htarr[1].slice(0, 2);
      }
      let tempStr = "";
      if (inputValue.includes(".")) {
        if (htAD.length === 0) {
          tempStr = htBD + ".";
        } else {
          tempStr = `${htBD}.${htAD}`;
        }
      } else {
        tempStr = htBD;
      }

      tempVitals[vitalCode].vitalValue = tempStr;
    } else if (unit === "ft~in") {
      tempVitals[vitalCode].vitalValue = inputValue;
    }
    updateVital(tempVitals);
  };

  const handleChangeFoot = (value, vitalCode) => {
    let tempVitals = { ...vitals };
    let foot = 0;
    let inch = 0;

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (
      tempVitals[vitalCode].vitalValue &&
      tempVitals[vitalCode].vitalValue !== ""
    ) {
      const tmp = parseInt(tempVitals[vitalCode].vitalValue);
      if (tmp) {
        foot = Math.floor(tmp / 12);
        inch = Math.floor(tmp % 12);
      }
    }

    if (!value || value === "") {
      tempVitals[vitalCode].vitalValue = 0 * 12 + inch;
      updateVital(tempVitals);
      return;
    }
    foot = parseInt(value);
    if (foot >= 10) {
      return;
    }
    if (isNaN(foot)) {
      return;
    }
    tempVitals[vitalCode].vitalValue = foot * 12 + inch;
    updateVital(tempVitals);
  };

  const handleChangeInch = (value, vitalCode) => {
    let tempVitals = { ...vitals };
    let foot = 0;
    let inch = 0;

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (
      tempVitals[vitalCode].vitalValue &&
      tempVitals[vitalCode].vitalValue !== ""
    ) {
      const tmp = parseInt(tempVitals[vitalCode].vitalValue);
      if (tmp) {
        foot = Math.floor(tmp / 12);
        inch = Math.floor(tmp % 12);
      }
    }

    if (!value || value === "") {
      tempVitals[vitalCode].vitalValue = foot * 12 + 0;
      updateVital(tempVitals);
      return;
    }
    inch = parseInt(value);
    if (isNaN(inch)) {
      return;
    }
    if (inch > 11) {
      return;
    }
    tempVitals[vitalCode].vitalValue = foot * 12 + inch;
    updateVital(tempVitals);
  };

  const handleLmpChange = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      const vitalNme = getVitalNameFromVitalCode(vitalCode);

      tempVitals[vitalCode] = {
        vitalName: vitalNme,
        unit: getUnitFromVitalCode(vitalCode) || "",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (value) {
      tempVitals[vitalCode].vitalValue = value;
      updateVital(tempVitals);
    }
  };

  return (
    <>
      <div className="vitalsGroup">
        {editMode && (
          <>
            <div className="vitalsInd">
              <div className="vitIcon">
                <BP />
              </div>
              <div className="vitInfo">
                <div className="vitNm">
                  {getVitalNameFromVitalCode("BP") || t("blood_pressure")}
                </div>
                <div
                  className={`vitValue ${
                    vitals?.BP?.isNormal === "Y" || !vitals || !vitals.BP
                      ? "vNormal"
                      : vitals?.BP?.isNormal === "M"
                      ? "vModarate"
                      : "vRisk"
                  }`}
                >
                  <input
                    className="editInputVitals"
                    type="text"
                    placeholder={t("sys")}
                    value={vitals?.BP?.vitalValue?.split("/")?.[0] || ""}
                    onChange={(e) => {
                      handleChangeSistolic("BP", e);
                    }}
                  />
                  <span className="slash"></span>
                  <input
                    className="editInputVitals"
                    type="text"
                    placeholder={t("dys")}
                    value={vitals?.BP?.vitalValue?.split("/")?.[1] || ""}
                    onChange={(e) => {
                      handleChangeDiastolic("BP", e);
                    }}
                  />
                  <span>{vitals?.BP?.unit || getUnitFromVitalCode("BP")}</span>
                </div>
              </div>
            </div>

            <div className="vitalsInd">
              <div className="vitIcon">
                <HeartPulse />
              </div>
              <div className="vitInfo">
                <div className="vitNm">
                  {getVitalNameFromVitalCode("HEART_RATE") || t("heart_rate")}
                </div>
                <div
                  className={`vitValue ${
                    vitals?.HEART_RATE?.isNormal === "Y" ||
                    !vitals ||
                    !vitals.HEART_RATE
                      ? "vNormal"
                      : vitals?.HEART_RATE?.isNormal === "M"
                      ? "vModarate"
                      : "vRisk"
                  }`}
                >
                  <input
                    className="editInputVitals"
                    type="text"
                    placeholder={t("example_eighty")}
                    value={vitals?.HEART_RATE?.vitalValue || ""}
                    onChange={(e) => handleChangeHeartRage("HEART_RATE", e)}
                  />
                  <span>{getUnitFromVitalCode("HEART_RATE") || "bpm"}</span>
                </div>
              </div>
            </div>

            <div className="vitalsInd">
              <div className="vitIcon">
                <Lungs />
              </div>
              <div className="vitInfo">
                <div className="vitNm">
                  {getVitalNameFromVitalCode("RESPIRATION_RATE") ||
                    t("respiration_rate")}
                </div>
                <div
                  className={`vitValue ${
                    vitals?.RESPIRATION_RATE?.isNormal === "Y" ||
                    !vitals ||
                    !vitals.RESPIRATION_RATE
                      ? "vNormal"
                      : vitals?.RESPIRATION_RATE?.isNormal === "M"
                      ? "vModarate"
                      : "vRisk"
                  }`}
                >
                  <input
                    className="editInputVitals"
                    type="text"
                    placeholder={t("eg_12")}
                    value={vitals?.RESPIRATION_RATE?.vitalValue || ""}
                    onChange={(e) => {
                      handleChangeRR(e.target.value, "RESPIRATION_RATE");
                    }}
                  />
                  <span>{getUnitFromVitalCode("RESPIRATION_RATE")}</span>
                </div>
              </div>
            </div>

            <div className="vitalsInd">
              <div className="vitIcon">
                <SPO2 />
              </div>
              <div className="vitInfo">
                <div className="vitNm">
                  {getVitalNameFromVitalCode("SPO2") || t("spo2")}
                </div>
                <div
                  className={`vitValue ${
                    vitals?.SPO2?.isNormal === "Y" || !vitals || !vitals.SPO2
                      ? "vNormal"
                      : vitals?.SPO2?.isNormal === "M"
                      ? "vModarate"
                      : "vRisk"
                  }`}
                >
                  <input
                    className="editInputVitals"
                    type="text"
                    placeholder={t("eg_98")}
                    value={vitals?.SPO2?.vitalValue || ""}
                    onChange={(e) => {
                      handleChangeSpo2(e.target.value, "SPO2");
                    }}
                  />
                  <span>{getUnitFromVitalCode("SPO2")}</span>
                </div>
              </div>
            </div>

            <div className="vitalsInd">
              <div className="vitIcon">
                <ThermometerHalf />
              </div>
              <div className="vitInfo">
                <div className="vitNm">
                  {getVitalNameFromVitalCode("TEMPERATURE") || t("temperature")}
                </div>
                <div
                  className={`vitValue ${
                    vitals?.TEMPERATURE?.isNormal === "Y" ||
                    !vitals ||
                    !vitals.TEMPERATURE
                      ? "vNormal"
                      : vitals?.TEMPERATURE?.isNormal === "M"
                      ? "vModarate"
                      : "vRisk"
                  }`}
                >
                  <input
                    className="editInputVitals"
                    type="text"
                    placeholder={getTemperaturePlaceholder}
                    value={vitals?.TEMPERATURE?.vitalValue || ""}
                    onChange={(e) => {
                      handleChangeTemparature(e.target.value, "TEMPERATURE");
                    }}
                  />
                  {/* <select
                    className="editInputUnits"
                    value={temperatureUnit}
                    onChange={(e) => {
                      setTemperatureUnit(e.target.value);
                      const tempVital = { ...vitals };
                      if (!tempVital.TEMPERATURE) {
                        const vitalNme =
                          getVitalNameFromVitalCode("TEMPERATURE");

                        tempVital.TEMPERATURE = {
                          vitalName: vitalNme,
                          unit: e.target.value,
                          vitalValue: "",
                          // recodedTime: "",
                          isNormal: "Y",
                          vitalCode: "TEMPERATURE",
                        };
                      } else {
                        tempVital.TEMPERATURE.unit = e.target.value;
                        const isNormal = getTemperatureStatus(
                          tempVital.TEMPERATURE.vitalValue,
                          e.target.value
                        );

                        tempVital.TEMPERATURE.isNormal = isNormal;
                      }
                      updateVital(tempVital);
                    }}
                  >
                    {vitalUnitMap?.["TEMPERATURE"]?.map((unit) => {
                      return <option value={unit}>{unit} </option>;
                    })}
                  </select> */}
                  <span>
                    {vitals?.TEMPERATURE?.unit ||
                      getUnitFromVitalCode("TEMPERATURE")}
                  </span>
                </div>
              </div>
            </div>

            <div className="vitalsInd">
              <div className="vitIcon">
                <MonitorWeightOutlined />
              </div>
              <div className="vitInfo">
                <div className="vitNm">
                  {getVitalNameFromVitalCode("WEIGHT") || t("weight")}
                </div>
                <div
                  className={`vitValue ${
                    vitals?.WEIGHT?.isNormal === "Y" ||
                    !vitals ||
                    !vitals.WEIGHT
                      ? "vNormal"
                      : vitals?.WEIGHT?.isNormal === "M"
                      ? "vModarate"
                      : "vRisk"
                  }`}
                >
                  <input
                    className="editInputVitals"
                    type="text"
                    placeholder={t("eg_80")}
                    value={vitals?.WEIGHT?.vitalValue || ""}
                    onChange={(e) => {
                      handleChangeWeight(e.target.value, "WEIGHT");
                    }}
                  />
                  {/* <select
                    className="editInputUnits"
                    value={weightUnit}
                    onChange={(e) => {
                      setWeightUnit(e.target.value);
                      const tempVital = { ...vitals };
                      if (!tempVital.WEIGHT) {
                        const vitalNme = getVitalNameFromVitalCode("WEIGHT");

                        tempVital.WEIGHT = {
                          vitalName: vitalNme,
                          unit: e.target.value,
                          vitalValue: "",
                          // recodedTime: "",
                          isNormal: "Y",
                          vitalCode: "WEIGHT",
                        };
                      } else {
                        tempVital.WEIGHT.unit = e.target.value;
                      }
                      updateVital(tempVital);
                    }}
                  >
                    {vitalUnitMap?.["WEIGHT"]?.map((unit) => {
                      return <option value={unit}>{unit} </option>;
                    })}
                  </select> */}
                  <span>
                    {vitals?.WEIGHT?.unit || getUnitFromVitalCode("WEIGHT")}
                  </span>
                </div>
              </div>
            </div>

            <div className="vitalsInd">
              <div className="vitIcon">
                <HeightOutlined />
              </div>
              <div className="vitInfo">
                <div className="vitNm">
                  {getVitalNameFromVitalCode("HEIGHT") || t("height")}
                </div>
                <div
                  className={`vitValue ${
                    vitals?.HEIGHT?.isNormal === "Y" ||
                    !vitals ||
                    !vitals.HEIGHT
                      ? "vNormal"
                      : vitals?.HEIGHT?.isNormal === "M"
                      ? "vModarate"
                      : "vRisk"
                  }`}
                >
                  {heightUnit === "ft~in" && (
                    <>
                      <input
                        className="editInputVitals"
                        type="text"
                        placeholder={"eg:5"}
                        value={
                          Math.floor(
                            parseInt(vitals?.HEIGHT?.vitalValue) / 12
                          ) || ""
                        }
                        onChange={(e) => {
                          handleChangeFoot(e.target.value, "HEIGHT");
                        }}
                      />

                      <span>feet</span>

                      {/* <select
                        className="editInputUnits"
                        value={heightUnit}
                        onChange={(e) => {
                          setHeightUnit(e.target.value);
                          const tempVital = { ...vitals };
                          if (!tempVital.HEIGHT) {
                            const vitalNme =
                              getVitalNameFromVitalCode("HEIGHT");

                            tempVital.HEIGHT = {
                              vitalName: vitalNme,
                              unit: e.target.value,
                              vitalValue: "",
                              // recodedTime: "",
                              isNormal: "Y",
                              vitalCode: "HEIGHT",
                            };
                          } else {
                            tempVital.HEIGHT.unit = e.target.value;
                          }
                          updateVital(tempVital);
                        }}
                      >
                        {vitalUnitMap?.["HEIGHT"]?.map((unit) => {
                          return (
                            <option value={unit}>
                              {unit === "ft~in" ? "ft" : unit}{" "}
                            </option>
                          );
                        })}

                      </select> */}
                      <input
                        className="editInputVitals"
                        type="text"
                        placeholder={"eg:11"}
                        value={
                          Math.floor(
                            parseInt(vitals?.HEIGHT?.vitalValue) % 12
                          ) || ""
                        }
                        onChange={(e) => {
                          handleChangeInch(e.target.value, "HEIGHT");
                        }}
                      />
                      <span>in</span>
                    </>
                  )}

                  {heightUnit !== "ft~in" && (
                    <>
                      <input
                        className="editInputVitals"
                        type="text"
                        placeholder={t("eg_182")}
                        value={vitals?.HEIGHT?.vitalValue || ""}
                        onChange={(e) => {
                          handleChangeHeight(e.target.value, "HEIGHT");
                        }}
                      />
                      {/* <select
                        className="editInputUnits"
                        value={heightUnit}
                        onChange={(e) => {
                          setHeightUnit(e.target.value);
                          const tempVital = { ...vitals };
                          if (!tempVital.HEIGHT) {
                            const vitalNme =
                              getVitalNameFromVitalCode("HEIGHT");

                            tempVital.HEIGHT = {
                              vitalName: vitalNme,
                              unit: e.target.value,
                              vitalValue: "",
                              // recodedTime: "",
                              isNormal: "Y",
                              vitalCode: "HEIGHT",
                            };
                          } else {
                            tempVital.HEIGHT.unit = e.target.value;
                          }
                          updateVital(tempVital);
                        }}
                      >
                        {vitalUnitMap?.["HEIGHT"]?.map((unit) => {
                          return (
                            <option value={unit}>
                              {unit === "ft~in" ? "ft" : unit}{" "}
                            </option>
                          );
                        })}
                      </select> */}

                      <span>
                        {vitals?.HEIGHT?.unit || getUnitFromVitalCode("HEIGHT")}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            {patientAge <= 4 && (
              <div className="vitalsInd">
                <div className="vitIcon">
                  <HeadC />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">
                    {getVitalNameFromVitalCode("HEAD_CIRCUMFERENCE") ||
                      t("head_circumference")}
                  </div>
                  <div
                    className={`vitValue ${
                      vitals?.HEAD_CIRCUMFERENCE?.isNormal === "Y" ||
                      !vitals ||
                      !vitals.HEAD_CIRCUMFERENCE
                        ? "vNormal"
                        : vitals?.HEAD_CIRCUMFERENCE?.isNormal === "M"
                        ? "vModarate"
                        : "vRisk"
                    }`}
                  >
                    <input
                      className="editInputVitals"
                      type="text"
                      placeholder={t("eg_34_5")}
                      value={vitals?.HEAD_CIRCUMFERENCE?.vitalValue || ""}
                      onChange={(e) => {
                        handleChangeHeadCircumference(
                          e.target.value,
                          "HEAD_CIRCUMFERENCE"
                        );
                      }}
                    />
                    {/* <select
                      className="editInputUnits"
                      value={headUnit}
                      onChange={(e) => {
                        setHeadUnit(e.target.value);
                        const tempVital = { ...vitals };
                        if (!tempVital.HEAD_CIRCUMFERENCE) {
                          const vitalNme =
                            getVitalNameFromVitalCode("HEAD_CIRCUMFERENCE");

                          tempVital.HEAD_CIRCUMFERENCE = {
                            vitalName: vitalNme,
                            unit: e.target.value,
                            vitalValue: "",
                            // recodedTime: "",
                            isNormal: "Y",
                            vitalCode: "HEAD_CIRCUMFERENCE",
                          };
                        } else {
                          tempVital.HEAD_CIRCUMFERENCE.unit = e.target.value;
                        }
                        const isNormal = getHeadCircumferenceColorFromValue(
                          e.target.value,
                          tempVital.HEAD_CIRCUMFERENCE.vitalValue
                        );
                        tempVital.HEAD_CIRCUMFERENCE.isNormal = isNormal;
                        updateVital(tempVital);
                      }}
                    >
                      {vitalUnitMap?.["HEAD_CIRCUMFERENCE"]?.map((unit) => {
                        return <option value={unit}>{unit} </option>;
                      })}
                    </select> */}
                    <span>
                      {vitals?.HEAD_CIRCUMFERENCE?.unit ||
                        getUnitFromVitalCode("HEAD_CIRCUMFERENCE")}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {patientAge <= 4 && (
              <div className="vitalsInd">
                <div className="vitIcon">
                  <UAC />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">
                    {getVitalNameFromVitalCode("UPPER_ARM_CIRCUMFERENCE") ||
                      t("upper_arm_circumference")}
                  </div>
                  <div
                    className={`vitValue ${
                      vitals?.UPPER_ARM_CIRCUMFERENCE?.isNormal === "Y" ||
                      !vitals ||
                      !vitals.UPPER_ARM_CIRCUMFERENCE
                        ? "vNormal"
                        : vitals?.UPPER_ARM_CIRCUMFERENCE?.isNormal === "M"
                        ? "vModarate"
                        : "vRisk"
                    }`}
                  >
                    <input
                      className="editInputVitals"
                      type="text"
                      placeholder={t("eg_12_5")}
                      value={vitals?.UPPER_ARM_CIRCUMFERENCE?.vitalValue || ""}
                      onChange={(e) => {
                        handleChangeUpperArm(
                          e.target.value,
                          "UPPER_ARM_CIRCUMFERENCE"
                        );
                      }}
                    />
                    {/* <select
                      className="editInputUnits"
                      value={upperArmUnit}
                      onChange={(e) => {
                        setUpperArmUnit(e.target.value);
                        const tempVital = { ...vitals };
                        if (!tempVital.UPPER_ARM_CIRCUMFERENCE) {
                          const vitalNme = getVitalNameFromVitalCode(
                            "UPPER_ARM_CIRCUMFERENCE"
                          );

                          tempVital.UPPER_ARM_CIRCUMFERENCE = {
                            vitalName: vitalNme,
                            unit: e.target.value,
                            vitalValue: "",
                            // recodedTime: "",
                            isNormal: "Y",
                            vitalCode: "UPPER_ARM_CIRCUMFERENCE",
                          };
                        } else {
                          tempVital.UPPER_ARM_CIRCUMFERENCE.unit =
                            e.target.value;
                        }
                        const isNormal = geUpperArmColorFromValue(
                          e.target.value,
                          tempVital.UPPER_ARM_CIRCUMFERENCE.vitalValue
                        );
                        tempVital.UPPER_ARM_CIRCUMFERENCE.isNormal = isNormal;
                        updateVital(tempVital);
                      }}
                    >
                      {vitalUnitMap?.["UPPER_ARM_CIRCUMFERENCE"]?.map(
                        (unit) => {
                          return <option value={unit}>{unit} </option>;
                        }
                      )}
                    </select> */}
                    <span>
                      {vitals?.UPPER_ARM_CIRCUMFERENCE?.unit ||
                        getUnitFromVitalCode("UPPER_ARM_CIRCUMFERENCE")}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {patientGender === "Female" && patientAge >= 12 && (
              <div className="vitalsInd">
                <div className="vitIcon">
                  <LMP />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">
                    {getVitalNameFromVitalCode("LMP") || t("lmp")}
                  </div>
                  <div className="vitValue">
                    <input
                      className="editInputDate"
                      type="date"
                      value={
                        vitals?.LMP?.vitalValue
                          ? dayjs(vitals?.LMP?.vitalValue).format("YYYY-MM-DD")
                          : lmp
                      }
                      onChange={(event) => {
                        handleLmpChange(event.target.value, "LMP");
                        setLmp(event.target.value);
                      }}
                      max={dayjs(new Date()).format("YYYY-MM-DD")}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {!editMode && (
          <>
            {listingData
              ?.filter((item) => {
                const { vitalCode, vitalValue } = item;
                if (
                  (vitalCode !== "BP" && vitalValue) ||
                  (vitalCode === "BP" && vitalValue && vitalValue !== "/")
                ) {
                  return true;
                }
                return false;
              })
              ?.map((vital, index) => {
                return (
                  <>
                    {vital.vitalValue && (
                      <div key={index} className="vitalsInd">
                        <div className="vitIcon">
                          {getVitalSvgAccordingToVitalType(vital?.vitalCode)}
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">{vital?.vitalName}</div>
                          <div
                            className={`vitValue ${
                              vital.isNormal === "Y"
                                ? "vNormal"
                                : vital.isNormal === "M"
                                ? "vModarate"
                                : "vRisk"
                            }`}
                          >
                            {console.log(
                              "********** vitals Edit Mode =",
                              editMode
                            )}
                            {!editMode && (
                              <>
                                <>
                                  {vital.vitalValue &&
                                    vital.vitalValue !== "" &&
                                    vital.unit === "ft~in" &&
                                    `${Math.floor(vital.vitalValue / 12)} ft ${
                                      vital.vitalValue % 12
                                    }  in`}
                                </>
                                <>
                                  {vital.vitalValue &&
                                    vital.vitalValue !== "" &&
                                    vital.unit !== "ft~in" &&
                                    `${vital.vitalValue}  ${
                                      vital.vitalCode !== "LMP" && vital.unit
                                        ? `${
                                            vital.unit === "per minute"
                                              ? "/min"
                                              : vital.unit
                                          }`
                                        : ""
                                    }`}
                                </>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </>
        )}

        {showBloodgroup && (
          <div className="vitalsInd">
            <div className="vitIcon">
              <BloodtypeOutlined />
            </div>
            <div className="vitInfo">
              <div className="vitNm">{t("blood_group")}</div>
              <div className="vitValue">
                <select
                  className="editInputUnits"
                  onChange={(e) => {
                    const tempVitals = { ...vitals };
                    const value = e.target.value;
                    // if (!vitals["BLOOD_GROUP"]) {
                    //   tempVitals["BLOOD_GROUP"] = {
                    //     vitalName: "Blood Group",
                    //     unit: null,
                    //     vitalValue: value,
                    //     // recodedTime: "",
                    //     isNormal: "Y",
                    //     vitalCode: "BLOOD_GROUP",
                    //   };
                    //   setVitals(tempVitals);
                    // } else {
                    //   tempVitals["BLOOD_GROUP"].vitalValue = value;
                    // }
                    setBloodGroup(value);
                    handleSelectBloodGroup(value);
                  }}
                  value={bloodGroup || ""}
                >
                  <option value="" disabled selected>
                    {t("select")}
                  </option>
                  <option value="A+">A + </option>
                  <option value="A-">A - </option>
                  <option value="B+">B + </option>
                  <option value="B-">B - </option>
                  <option value="AB+">AB + </option>
                  <option value="AB-">AB - </option>
                  <option value="O+">O + </option>
                  <option value="O-">O - </option>
                </select>
              </div>
            </div>
          </div>
        )}

        {showSaveButton && (
          <div>
            <Button
              className="joinMetBtn fullHeight"
              startIcon={<>{editMode ? <Save /> : <Edit />}</>}
              onClick={() => {
                if (editMode) {
                  // return;
                  // const temp = Object.values(vitals);

                  // handleSaveVitals(temp);
                  // handleChangeEditMode(false);

                  if (!validateVitals(vitals, patientAge, patientGender)) {
                    setShowConfirmation(true);
                    return;
                  } else {
                    const tempVitals = Object.values(vitals);
                    // ?.map((item) => ({
                    //   ...item,
                    //   vitalValue: item.updatedData.vitalValue,
                    //   unit: item.updatedData.unit,
                    // }));
                    const vitalMap = tempVitals?.reduce((acc, curr) => {
                      const { vitalCode } = curr;
                      acc[vitalCode] = curr;
                      return acc;
                    }, {});
                    console.log("vital Map =", tempVitals);
                    setVitals(vitalMap);
                    updateData({ vitals: tempVitals });
                    setEditMode((prev) => !prev);
                    setShowConfirmation(false);
                    handleSaveVitals(tempVitals);
                    setListingData(tempVitals);
                  }
                } else {
                  const tempVitals = Object.values(vitals);
                  setListingData(tempVitals);
                  setEditMode((prev) => !prev);
                  // handleChangeEditMode
                  // handleChangeEditMode(true);
                }
              }}
            >
              {t("vitals")}
            </Button>
          </div>
        )}

        <Button startIcon={<DeviceHddFill />} className={"dfultPrimaryBtn"}>
          {t("get_vitals_from_device")}
        </Button>
      </div>

      {showConfirmation && (
        <CustomModal
          modalTitle={t("do_you_want_to_continue")}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div>
            <ul>
              {VitalErrorList &&
                VitalErrorList.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                const tempVitals = Object.values(vitals);
                // ?.map((item) => ({
                //   ...item,
                //   vitalValue: item.updatedData.vitalValue,
                //   unit: item.updatedData.unit,
                // }));
                const vitalMap = tempVitals?.reduce((acc, curr) => {
                  const { vitalCode } = curr;
                  acc[vitalCode] = curr;
                  return acc;
                }, {});
                setVitals(vitalMap);
                // updateData({ vitals: tempVitals });
                setEditMode((prev) => !prev);
                setShowConfirmation(false);
                handleSaveVitals(tempVitals);
                setListingData(tempVitals);

                // centerSocket.emit("uploadFileToDoctor", {
                //   doctorId: extraData?.doctorId,
                //   data: { vitals: tempVitals },
                // });
              }}
            >
              {t("save")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("edit")}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};

//Handle update data
