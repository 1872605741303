import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class ZhMedicalEquipmentService {
  createMachineTestRequisition(userId, reqDto) {
    return http.post(
      `zhTestController/createMachineTestRequisition/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  getBiochemTestResults(requisitionId) {
    return http.get(`zhTestController/getBiochemTestResults/${requisitionId}`, {
      headers: authHeader(),
    });
  }

  getDeviceDetailsInUnitByType(unitId, deviceType) {
    return http.get(
      `deviceController/getDeviceDetailsInUnitByType/${unitId}/${deviceType}`,
      {
        headers: authHeader(),
      }
    );
  }

  getEcgTestResults(requisitionId) {
    return http.get(`zhTestController/getEcgTestResults/${requisitionId}`, {
      headers: authHeader(),
    });
  }
}
const zhMedicalEquipmentService = new ZhMedicalEquipmentService();
export default zhMedicalEquipmentService;
