import { Box, Button, IconButton, Modal, Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { HddFill, XLg } from "react-bootstrap-icons";
import { getOrgTotalStorage } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import DriveStripe from "../PaymentDriveStorage/DriveStripe";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function CreateStorage({
  handleCloseCreateOrgStorage,
  refreshStorageData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handlePaymentModal = () => {
    setShowPaymentModal(false);
  };

  const [TotalStorageData, setTotalStorageData] = useState([]);

  useEffect(() => {
    getOrgTotalStorage(userDetails.orgId).then((response) => {
      console.log("Total Storage", response.data);
      setTotalStorageData(response.data);
    });
  }, [userDetails.orgId]);

  const [addedStorage, setAddedStorage] = useState(null);

  function valuetext(value) {
    return `${value} GB`;
  }

  function valueLabelFormat(value) {
    setAddedStorage(value);
    return `${value} GB`;
  }

  useEffect(()=>{
  setBookSteps([
    {
      target: ".addOrgStoragOne",
      content: "This is where you can manage and add storage for your organization.",
      disableBeacon: true,
    },
    {
      target: ".addOrgStoragTwo",
      content: "Here you can see the current storage allocated to your organization.",
      disableBeacon: true,
    },
    {
      target: ".addOrgStoragThree",
      content: "This section shows your new total storage after the upgrade.",
      disableBeacon: true,
    },
    {
      target: ".addOrgStoragFour",
      content: "Use this slider to select the amount of additional storage you want to add.",
      disableBeacon: true,
    },
    {
      target: ".addOrgStoragFive",
      content: "Review the cost details of the additional storage you've selected.",
      disableBeacon: true,
    },
    {
      target: ".addOrgStoragSix",
      content: "Click this button to confirm and upgrade your storage.",
      disableBeacon: true,
    },
    {
      target: ".addOrgStoragSeven",
      content: "Click here to cancel the storage upgrade and return to the previous screen.",
      disableBeacon: true,
    },
  ]
  )
  },[])

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["addStorageAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="flotingAreaContainer addOrgStoragOne">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("add_organization_storage")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handleCloseCreateOrgStorage()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer">
          <div className="curntStorCont">
            <div className="CurntStrData addOrgStoragTwo">
              <span className="curntStrgTxtlight">
                {t("current")} <br />
                {t("storage")}
              </span>
              <span className="curntStrgBold">
                {TotalStorageData.storage
                  ? `${TotalStorageData.storage} GB`
                  : "0 GB"}
              </span>
            </div>

            <div className="CurntStrData addOrgStoragThree">
              <span className="curntStrgTxtlight">
                {t("upgraded")} <br />
                {t("to")}
              </span>
              {/* <span className="curntStrgBold textWhie">
                {TotalStorageData.storage + addedStorage} GB
              </span> */}
              <span className="curntStrgBold textWhie">
                {TotalStorageData.storage + addedStorage
                  ? `${TotalStorageData.storage + addedStorage} GB`
                  : "0 GB"}
              </span>
            </div>

            <div className="curntDaImg">
              <span>
                <HddFill />
              </span>
              <span className="cuStText">Zoyel {t("drive")}</span>
            </div>
          </div>

          <div class="tskElemHeding mt10">{t("add_more_storage")}</div>
          <div className="formElement mt10">
            <Slider
              className="stroageSlider addOrgStoragFour"
              // defaultValue={25}
              getAriaValueText={valuetext}
              defaultValue={1}
              step={1}
              min={25}
              max={1000}
              valueLabelFormat={valueLabelFormat}
              aria-label="Default"
              valueLabelDisplay="on"
            />
          </div>

          <div className="stroageTable addOrgStoragFive">
            <div className="strgItmResponsive">
              <table>
                <thead>
                  <tr>
                    <th className="taCenter">{t("item_name")}</th>
                    <th className="taRight">{t("unit_price")}</th>
                    <th className="taRight">{t("amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="heit150 taCenter">
                        {t("new_storage_added")} &nbsp;
                        <span> {addedStorage} GB</span>
                      </div>
                    </td>
                    <td>
                      <div className="heit150 taRight">
                        x <span>$0.025</span>
                      </div>
                    </td>
                    <td>
                      <div className="heit150 taRight">
                        <span>
                          $
                          {Math.round(
                            (0.025 * parseFloat(addedStorage) +
                              Number.EPSILON) *
                              100
                          ) / 100}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot className="crtStorgFoot">
                  <tr>
                    <td colSpan="2">
                      <div className="taRight">
                        <strong>{t("total")}</strong>
                      </div>
                    </td>
                    <td>
                      <div className="taRight">
                        <strong>
                          $
                          {Math.round(
                            (0.025 * parseFloat(addedStorage) +
                              Number.EPSILON) *
                              100
                          ) / 100}
                        </strong>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {/* end stroage Table end */}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn addOrgStoragSix"
              onClick={() => {
                setShowPaymentModal(true);
              }}
            >
              {t("upgrade_now")}
            </Button>

            <Button
              className="dfultDarkSecondaryBtn addOrgStoragSeven"
              onClick={handleCloseCreateOrgStorage}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={showPaymentModal}
        onClose={() => {
          setShowPaymentModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 300,
            //overflow: "auto",
            maxHeight: "60vh",
            // bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            backgroundColor: "#edebff",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            className="closeDilog"
            onClick={() => {
              setShowPaymentModal(false);
            }}
          >
            X
          </div>
          <DriveStripe
            itemId={"1"}
            itemHeader="STORAGE"
            itemUnits={addedStorage}
            // refreshEdition={() => refreshEdition()}
            onSuccess={() => {
              console.log("onSuccess Called");

              refreshStorageData();
            }}
            // handleNext={props.handleNext}
            handlePaymentModal={handlePaymentModal}
            handleCloseCreateOrgStorage={handleCloseCreateOrgStorage}
          />
        </Box>
      </Modal>
    </>
  );
}
