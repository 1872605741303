import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class ProfileService {
  getOrgListByLoginId(loginId) {
    console.log(loginId);
    loginId = loginId.toLowerCase().trim();
    return http.get(`org/getOrgListByLoginId/${loginId}`, {
      headers: authHeader(),
    });
  }

  getAllUserDetailsByUserId(userId) {
    return http.get(`userLogin/getAllUserDetailsByUserId/${userId}`, {
      headers: authHeader(),
    });
  }

  getAllTimeZones() {
    return http.get(`misc/getActiveTimeZones`, { headers: authHeader() });
  }

  getCountryList() {
    return http.get(`misc/getCountryList`, { headers: authHeader() });
  }

  duplicateSubdomain(subDomain) {
    return http.get(`org/duplicateSubdomain/${subDomain}`, {
      headers: authHeader(),
    });
  }

  clientLogs(log) {
    console.log(log);
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        //"Content-type": "text/plain",
        "Content-type": "application/json",
        Authorization: authHeaderVal,
      },
    };
    return http.put(`misc/clientLogs`, log, config);
  }

  createOrg(data) {
    //console.log(data);
    return http.post(`org/createOrg`, data, { headers: authHeader() });
  }

  getDefaultLandingTab(userId) {
    return http.get(`userLogin/getDefaultLandingTab/${userId}`, {
      headers: authHeader(),
    });
  }

  modifyDefaultLandingTab(userId, landingTab) {
    return http.put(
      `userLogin/modifyDefaultLandingTab/${userId}/${landingTab}`,
      { headers: authHeader() }
    );
  }
}

export default new ProfileService();
