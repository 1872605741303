export const MESSAGE_DELETED = "Message Deleted";
export const NO_PROFILE_IMAGE =
  "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png";
export const SELECT_ITEM_HEIGHT = 48;
export const SELECT_ITEM_PADDING_TOP = 16;
export const SELECT_MenuProps = {
  PaperProps: {
    style: {
      maxHeight: SELECT_ITEM_HEIGHT * 5.0 + SELECT_ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const PERSONAL_ORG_ID = "ORG000000000000";
export const MEETING_TYPES_WITH_CHAIRPERSON = [
  "Annual General Meeting",
  "Board of Director's Meeting",
  "Special BOD Meeting",
  "Extraordinary General Meeting",
];
export const patSrcDispName = window.location.hostname?.includes("massalud")
  ? "Massalud"
  : "Zoyel";

export const currentSubdomain = window.location.hostname?.includes("massalud")
  ? "massalud"
  : window.location.hostname;
