import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { ConfirmProvider, useConfirm } from "material-ui-confirm";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
import JitsiVideoConference from "./JitsiVideoConference";
import {
  createInstantMeeting,
  getEventDetailsByMeetingId,
  downloadFile,
} from "../../../../../../services/VideoConferenceService";
import { useForm } from "react-hook-form";
import { jwtDecode } from "jwt-decode";
import {
  AttachFile,
  BackHand,
  CallEnd,
  Chat,
  Description,
  DesignServices,
  FolderShared,
  GridViewOutlined,
  Groups,
  Language,
  LiveTvOutlined,
  Medication,
  MeetingRoom,
  Mic,
  MicOff,
  MoreVert,
  PersonAdd,
  Queue,
  ScreenShare,
  Speaker,
  SpeakerGroup,
  StopScreenShare,
  Videocam,
  VideocamOff,
  WallpaperOutlined,
} from "@mui/icons-material";
import {
  CcCircleFill,
  Fullscreen,
  FullscreenExit,
  XLg,
} from "react-bootstrap-icons";
import MeetingTabRightPanel from "./MeetingTabRightPanel";
import { useNavigate } from "react-router-dom";
import ShareOptions from "./ShareOptions";
import { toast } from "react-toastify";
import DateUtils from "../../../../../../utils/DateUtils";
import AppUtils from "../../../../../../utils/AppUtils";
import {
  Camera,
  Check,
  OneFingerSelectHandGesture,
  PageStar,
  SpockHandGesture,
  VideoCamera,
} from "iconoir-react";
import {
  checkDoctorFace,
  getMeetingAgendas,
} from "../../../../../../services/VideoConferenceService";
import WebinarService from "../../../../../../services/WebinarService";
import BottomOptionsTimer from "./BottomOptionsTimer";
import { meetingSocket } from "../../../../../../socket";
import { getChairpersonOrConvenerList } from "../../../../../../services/MeetingService";
import { apiEndPoint } from "../../../../../../constants/url_provider";
import { render } from "@testing-library/react";
import { AddTabContext } from "../../../../../dashboard/view/DashboardPage";
import { v4 } from "uuid";
import DrFeedback from "../../../../../analyticsDashboard/view/component/healthcare/DrFeedback";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../../../../utils/IndexedDB";
import { GetLoginUserDetails } from "../../../../../../utils/Utils";

export default function MeetingsTabContainer(props) {
  const { addTab, updateTab } = useContext(AddTabContext);
  const { t } = useTranslation();
  const [roomId, setRoomId] = useState(
    props.eventData ? props.eventData.eventLink : null
  );
  const [jitsi, setJitsi] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [selEvent, setSelEvent] = useState(props.eventData);
  const [jitsiObject, setJitsiObject] = useState(null);
  const [roomId2, setRoomId2] = useState(null);
  const userDetails = GetLoginUserDetails();
  const { handleFullscreen } = useContext(AddTabContext);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState({});

  const updatePrescriptionData = (data) => {
    // toast.success("successfully added data");
    console.log("data =", data);
    setPrescriptionData((prev) => ({ ...prev, ...data }));
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitSuccessful },
    setError,
  } = useForm();

  // const joinRoom = () => {};

  const [loadingRoom, setLoadingRoom] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    if (props.eventData && props.tabKey) {
      updateTab({ data: props.eventData.eventLink }, props.tabKey);
    }

    if (props.joinRoomId) {
      getEventDetailsByMeetingId(props.joinRoomId).then((response) => {
        console.log("joinVideoRoom", response, props.joinRoomId, roomId);
        const eventDto = {
          eventTitle: response.data.field1,
          eventDescription: response.data.field2,
          eventStartTime: response.data.field3,
          eventEndTime: response.data.field4,
          eventId: response.data.field5,
          eventType: response.data.field6,
          eventLength: parseInt(response.data.field7),
          eventLink: props.joinRoomId,
        };
        setRoomId(props.joinRoomId);
        setSelEvent(eventDto);
        updateTab({ data: props.joinRoomId }, props.tabKey);
      });
    }
    if (props.startInstantMeeting) {
      startInstantMeeting();
    }
  }, []);

  const handleFullScreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
    }
  };

  const joinRoom = async (data) => {
    setLoadingRoom(true);
    let roomIdTemp = roomId2;
    // if (data) {
    //   roomIdTemp = data;
    // }

    if (roomIdTemp === "" || roomIdTemp === "") {
      toast.error(t("please_enter_room_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingRoom(false);
      return;
    }

    const response = await getEventDetailsByMeetingId(roomIdTemp);
    console.log("joinVideoRoom", response);

    if (!response.data && response.data === "") {
      toast.error(
        t(
          "meeting_with_provided_room_number_does_not_exists_please_check_once"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoadingRoom(false);
      return;
    }

    const eventDto = {
      eventTitle: response.data.field1,
      eventDescription: response.data.field2,
      eventStartTime: response.data.field3,
      eventEndTime: response.data.field4,
      eventId: response.data.field5,
      eventType: response.data.field6,
      eventLength: parseInt(response.data.field7),
      eventLink: roomIdTemp,
    };

    const start = new Date(response.data.field3);
    const curr = new Date();
    const diff = start - curr;
    if (diff > 1000 * 60 * 5) {
      const minutesToSubtract = 5;
      const millisecondsToSubtract = minutesToSubtract * 60 * 1000;
      toast.error(
        t(
          "this_meeting_opens_5_minutes_before_the_start_time_please_join_after"
        ) +
          DateUtils.getDateInDDMMYYYY(
            new Date(start.getTime() - millisecondsToSubtract)
          ) +
          " " +
          DateUtils.printAMPM(
            new Date(start.getTime() - millisecondsToSubtract)
          ),
        DateUtils.getDateInDDMMYYYY(
          new Date(start.getTime() - millisecondsToSubtract)
        ) +
          " " +
          DateUtils.printAMPM(
            new Date(start.getTime() - millisecondsToSubtract)
          ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoadingRoom(false);
      return;
    }

    if (!AppUtils.isNumeric(roomIdTemp)) {
      toast.error(t("invalid_room_number_room_number_can_have_only_digits"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingRoom(false);
      return;
    }
    if (roomIdTemp === "" || roomIdTemp === "") {
      toast.error(t("please_enter_room_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingRoom(false);
    } else {
      setRoomId(roomIdTemp);
      // props.changeRoomId(roomIdTemp);
      // props.setSelEvent(eventDto);
      // SecureIndexedDB.setItem("video-room-id", roomIdTemp);
      setSelEvent(eventDto);
      let tempSubList = [];
      const jsonString = JSON.stringify(tempSubList);
      SecureIndexedDB.setItem("meetingSubs", jsonString);
      setLoadingRoom(false);
    }
  };

  const startInstantMeeting = async () => {
    toast.info(t("starting_instant_meeting_please_wait"));
    setLoading(true);
    try {
      const response = await createInstantMeeting(userDetails.userId);

      const response2 = await getEventDetailsByMeetingId(response.data.link);
      console.log("joinVideoRoom", response2);
      const eventDto = {
        eventTitle: response2.data.field1,
        eventDescription: response2.data.field2,
        eventStartTime: response2.data.field3,
        eventEndTime: response2.data.field4,
        eventId: response2.data.field5,
        eventType: response2.data.field6,
        eventLength: parseInt(response2.data.field7),
        eventLink: response.data.link,
      };

      console.log("createInstantMeeting-->", response);

      setRoomId(response.data.link);
      updateTab(
        { data: response.data.link, title: "Instant Meeting" },
        props.tabKey
      );
      // setIsHost(true);
      setEventId(response.data.eventId);
      console.log("eventDto78977988", eventDto);
      setSelEvent(eventDto);
      // props.changeRoomId(response.data.link);

      const tempSubList = [];
      const jsonString = JSON.stringify(tempSubList);
      SecureIndexedDB.setItem("meetingSubs", jsonString);

      // props.clearSelEvents(null);
      // props.handelTaskSubmitData();
      props.refreshData();
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  // open meeting menu start
  const [openMeetingMenu, setOpenMeetingMenu] = React.useState(false);
  const open = Boolean(openMeetingMenu);
  // open meeting menu end

  const [gridItemSize, setGridItemSize] = useState(12);

  const handleMenuClick = (size) => {
    setGridItemSize(size);
    setOpenMeetingMenu(false);
  };

  const handleMenuCloseButton = () => {
    setGridItemSize(12);
    setvalue(0);
  };

  const [value, setvalue] = useState(0);

  ///////////////////////////////
  ////////

  // const [playSound, { stop }] = useSound(mySound);
  // const [showFiles, setShowFiles] = React.useState(false);
  // const handleFile = useFullScreenHandle();
  const confirm = useConfirm();
  let navigate = useNavigate();

  const [showPrivateNote, setShowPrivateNote] = React.useState(false);
  const [showMeetingParticipants, setShowMeetingParticipants] =
    React.useState(false);
  const [showSubtitlesLang, setShowSubtitlesLang] = React.useState(false);
  const [showTranscriptDialog, setShowTranscriptDialog] = React.useState(false);
  const [showSpokenLanguages, setShowSpokenLanguages] = React.useState(false);
  const [noOfparticipants, setNoOfParticipants] = React.useState(false);
  const [showWebinarChat, setShowWebinarChat] = React.useState(false);
  const [webinarChatData, setWebinarChatData] = React.useState([]);
  const [webinarUsersList, setWebinarUsersList] = React.useState([]);
  const [showWebinarParticipants, setShowWebinarParticipants] =
    React.useState(false);
  const [showWebinarChatCount, setShowWebinarChatCount] = React.useState(0);
  const [showBreakoutRooms, setShowBreakoutRooms] = React.useState(false);
  const [breakoutRoomId, setBreakoutRoomId] = React.useState(null);
  React.useState(false);

  //   return () => {
  //     socketRTU?.removeAllListeners(`updateUserJoined`);
  //   };
  // }, []);

  const endMeeting = () => {
    confirm({ description: t("leave_meeting") })
      .then(() => {
        let path = `../dashboard`;
        navigate(path);
      })
      .catch(() => {
        console.log("Leave meeting cancelled");
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorAudioOptions, setAnchorAudioOptions] = React.useState(null);
  const openAudioOptions = Boolean(anchorAudioOptions);
  const handleClickAudioOptions = (event) => {
    setAnchorAudioOptions(event.currentTarget);
    refreshDevices();
  };
  const handleCloseAudioOptions = () => {
    setAnchorAudioOptions(null);
  };

  const [anchorVideoOptions, setAnchorVideoOptions] = React.useState(null);
  const openVideoOptions = Boolean(anchorVideoOptions);
  const handleClickVideoOptions = (event) => {
    setAnchorVideoOptions(event.currentTarget);
  };
  const handleCloseVideoOptions = () => {
    setAnchorVideoOptions(null);
  };

  const [anchorMoreOptions, setAnchorMoreOptions] = React.useState(null);
  const openMoreOptions = Boolean(anchorMoreOptions);
  const handleClickMoreOptions = (event) => {
    setAnchorMoreOptions(event.currentTarget);
  };
  const handleCloseMoreOptions = () => {
    setAnchorMoreOptions(null);
  };

  const endCallRef = React.useRef(null);

  const handleClickEndMeeting = (event) => {
    confirm({ description: "End meeting for all!" })
      .then(() => {
        // MeetingService.endMeeting(props.roomNumber).then(() => {
        //   console.log("Meeting Ended Successfully");
        // });
        // props.leaveMeetingRoom();

        if (props.openRoom) {
          let path = `../`;
          navigate(path);
        } else {
          props.leaveMeetingRoom();
        }
        props.setMeetingEndType(null);
      })
      .catch(() => {
        console.log("End meeting cancelled");
        props.setMeetingEndType(null);
      });
  };

  const handleClickLeaveMeeting = (event) => {
    confirm({ description: t("leave_meeting") })
      .then(() => {
        if (props.openRoom) {
          let path = `../`;
          navigate(path);
        } else {
          props.leaveMeetingRoom();
        }
        props.setMeetingEndType(null);
      })
      .catch(() => {
        console.log("Leave meeting cancelled");
        props.setMeetingEndType(null);
      });
  };

  const [invitePerModal, setInvitePerModal] = React.useState(false);
  const [orgPersons, setOrgPersons] = React.useState([]);
  const [filteredOrgPersons, setFilteredOrgPersons] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [showPeopleInfoModal, setShowPeopleInfoModal] = React.useState(false);
  const [peopleInfo, setPeopleInfo] = React.useState([]);
  const [showOutPersonModal, setShowOutPersonModal] = React.useState(false);
  const [outSidePersonList, setOutSidePersonList] = useState([]);
  const [selContacts, setSelContacts] = React.useState([]);
  const [selectExternalContactList, setSelectExternalContactList] =
    React.useState([]);

  const [meetingAgendasList, setMeetingAgendasList] = React.useState([]);
  const [showAgendaModal, setShowAgendaModal] = React.useState(false);
  const [streamStarted, setStreamStarted] = React.useState(false);
  const [showEndCallDialog, setShowEndCallDialog] = React.useState(false);
  console.log("Meeting selEvent", selEvent);

  useEffect(() => {
    if (selEvent) {
      getMeetingAgendas(selEvent.eventId).then((response) => {
        setMeetingAgendasList(response.data);
      });
    }
  }, [selEvent]);

  // React.useEffect(() => {
  //   let orgId = userDetails.orgId;
  //   let personId = userDetails.personId;
  //   let userId = userDetails.userId;
  //   if (orgId === "ORG000000000000") {
  //     DashboardService.getContacts(userId).then((response) => {
  //       // console.log("getContacts--->", response.data);
  //       // const filteredContactList = response.data.filter(
  //       //   (contact) =>
  //       //     contact.contactUserId != null ||
  //       //     contact.meetingId === props.roomNumber
  //       // );
  //       // console.log("getContacts--->", filteredContactList);
  //       // setContacts(response.data);
  //     });
  //   } else {
  //     // DashboardService.getAllPeople(orgId).then((response) => {
  //     //   console.log(response.data);
  //     //   const filteredPersonList = response.data.filter(
  //     //     (value) => value.personId !== personId
  //     //   );
  //     //   setOrgPersons(filteredPersonList);
  //     //   setFilteredOrgPersons(filteredPersonList);
  //     // });
  //     if (props.selEvent && props.selEvent != null && props.selEvent.eventId) {
  //       DashboardService.getMeetingAgendas(props.selEvent.eventId).then(
  //         (response) => {
  //           setMeetingAgendasList(response.data);
  //         }
  //       );
  //     }
  //   }

  //   socketRTU?.on("notifyWboOpened", (userName, whiteboardOpenedTemp) => {
  //     console.log("notifyWboOpened---->", userName, whiteboardOpenedTemp);
  //     if (!whiteboardOpenedTemp) {
  //       setWhiteboardOpened(true);
  //       toast.info(
  //         `${userName} has opened the meeting whiteboard, please check`,
  //         {
  //           position: toast.POSITION.TOP_RIGHT,
  //         }
  //       );
  //       jitsiObject.executeCommand("showNotification", {
  //         title: `Whiteboard opened`, // Title of the notification.
  //         description: `${userName} has opened the meeting whiteboard, please check`, // Content of the notification.
  //       });
  //     }
  //   });

  //   return () => {
  //     socketRTU?.removeAllListeners(`notifyWboOpened`);
  //   };
  // }, [props.roomNumber]);

  const [whiteboardOpened, setWhiteboardOpened] = React.useState(false);

  // const handleCheckInvitePer = (event, personId) => {
  //   console.log(event.target.checkek);
  //   console.log(personId);
  // };

  // const sendInvite = () => {
  //   console.log(peopleInfo);

  //   let reqDto = [];
  //   peopleInfo.forEach((people) => {
  //     reqDto.push(people.personId);
  //   });
  //   console.log(peopleInfo);
  //   // return;

  //   MeetingService.sendMeetingInvite(
  //     reqDto,
  //     props.roomNumber,
  //     userDetails.userId
  //   ).then((response) => {
  //     console.log(response);
  //     if (response.data.returnValue === "1") {
  //       toast.success("Invites sent successfully", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     } else {
  //       toast.error("Something went wrong", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   });
  // };

  // const [renderState, setRenderState] = React.useState(0);
  // const addExternalMailId = async (data) => {
  //   console.log(data);

  //   if (data.externalEmailId == null || data.externalEmailId.trim() === "") {
  //     // toast.error("Please enter mail id", {
  //     //   position: toast.POSITION.TOP_RIGHT,
  //     // });
  //     setError("externalEmailId", {
  //       type: "custom",
  //       message: "Please enter mail id",
  //     });
  //     return false;
  //   }
  //   if (!isValidEmail(data.externalEmailId)) {
  //     setError("externalEmailId", {
  //       type: "custom",
  //       message: "Please enter valid mail id",
  //     });
  //     return false;
  //   }
  //   setPeopleInfo([
  //     {
  //       personId: data.externalEmailId,
  //     },
  //     ...peopleInfo,
  //   ]);
  //   setOrgPersons([
  //     {
  //       personId: data.externalEmailId,
  //     },
  //     ...orgPersons,
  //   ]);
  //   setFilteredOrgPersons([
  //     {
  //       personId: data.externalEmailId,
  //     },
  //     ...filteredOrgPersons,
  //   ]);
  //   reset();
  //   await setInvitePerModal(false);
  //   setInvitePerModal(true);
  //   // setRenderState(renderState + 1);
  // };

  // const filterContacts = (event) => {
  //   console.log(event.target.value);
  //   let searchKey = event.target.value;
  //   if (!searchKey || searchKey.trim() === "") {
  //     setFilteredOrgPersons(orgPersons);
  //   } else {
  //     let filteredOrgPersonsTemp = orgPersons.filter(
  //       (person) =>
  //         person.personId.toLowerCase().includes(searchKey.toLowerCase()) ||
  //         (person.personName &&
  //           person.personName.toLowerCase().includes(searchKey.toLowerCase()))
  //     );
  //     setFilteredOrgPersons(filteredOrgPersonsTemp);
  //   }
  // };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // const sendInvitePersonalOrg = async () => {
  //   if (selContacts.length === 0) {
  //     toast.error("Please select atleast one user", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     return;
  //   }

  //   console.log("sendInvitePersonalOrg1", selContacts);

  //   let busyUserTempList = await getAllBusyUserListForInvite(
  //     selContacts.map((userDto) => userDto.contactUserId)
  //   );
  //   console.log(
  //     "sendInvitePersonalOrg2",
  //     selContacts.map((userDto) => userDto.contactUserId)
  //   );
  //   console.log("sendInvitePersonalOr3", busyUserTempList);
  //   setBusyUserList(busyUserTempList);
  //   if (busyUserTempList.length > 0) {
  //     setShowBusyUserCallDialog(true);
  //   } else {
  //     sendInvitePersonalOrgFinal();
  //   }
  // };

  // const sendInvitePersonalOrgFinal = () => {
  //   toast.info(`Sending Call Invite, Please wait...`, {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   let userList = [];
  //   if (selContacts.length > 0) {
  //     selContacts.forEach((contactDto) => userList.push(contactDto));
  //   }

  //   console.log("sendInvitePersonalOrg userList", userList);
  //   // return;
  //   MeetingService.sendCallInvitePersonal(
  //     userList,
  //     props.roomNumber,
  //     userDetails.userId,
  //     props.selEvent.eventType
  //   ).then((response) => {
  //     console.log("sendCallInvite", response.data);
  //     if (response.data.returnValue === "1") {
  //       toast.success("Call Invite Sent", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       setPeopleInfo([]);
  //       setOutSidePersonList([]);
  //       setSelectExternalContactList([]);
  //       setSelContacts([]);
  //       setInvitePerModal(false);
  //     } else {
  //       toast.error("Something went wrong", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   });
  // };

  // const handelDownloadFileFromUrl = (files) => {
  //   files.forEach((file) => {
  //     // var frame = document.createElement("iframe");
  //     // frame.src = file.fileLink;
  //     // frame.name = file.fileName;
  //     // frame["download"] = 1;
  //     // document.body.appendChild(frame);
  //     MeetingService.downloadFile(file.fileName, userDetails.orgId).then(
  //       (response) => {
  //         window.open(
  //           apiEndPoint +
  //             `/appTest/downloadFileTest/${file.fileName}/${userDetails.orgId}`,
  //           "_blank",
  //           "noopener,noreferrer"
  //         );
  //       }
  //     );
  //   });
  // };

  // onMessageListener()
  //   .then((payload) => {
  //     console.log("fcm notification on bottom option -->", payload);
  //     if (
  //       payload.notification.title &&
  //       props.roomNumber &&
  //       payload.notification.title.includes(props.roomNumber + "_file_uploaded")
  //     ) {
  //       console.log("file_uploaded");
  //     }
  //   })
  //   .catch((err) => console.log("failed: ", err));

  const [blinkFileUpload, setBlinkFileUpload] = React.useState(0);

  // React.useEffect(() => {
  //   console.log(
  //     "fcm notification meetingFileUploaded",
  //     props.meetingFileUploaded
  //   );
  //   setBlinkFileUpload(props.meetingFileUploaded);
  //   if (props.meetingFileUploaded > 0) {
  //     playSound();
  //   }
  // }, [props.meetingFileUploaded]);

  const [renderVideo, setRenderVideo] = React.useState(false);
  const [renderAudio, setRenderAudio] = React.useState(false);
  const [renderShareScreen, setRenderShareScreen] = React.useState(false);
  const [chatUnreadCount, setChatUnreadCount] = React.useState(0);
  // const [renderTileView, setRenderTileView] = React.useState(false);

  // React.useEffect(() => {
  //   console.log("renderVideo useEffect", renderVideo);
  //   if (jitsiObject) {
  //     jitsiObject.isVideoMuted().then((muted) => {
  //       console.log("renderVideo useEffect", muted);
  //       jitsiObject.executeCommand("toggleVideo");
  //     });
  //   }
  // }, [renderVideo]);

  // React.useEffect(() => {
  //   console.log("renderAudio useEffect", renderVideo);
  //   if (jitsiObject) {
  //     jitsiObject.isAudioMuted().then((muted) => {
  //       console.log("renderVideo useEffect", muted);
  //       jitsiObject.executeCommand("toggleAudio");
  //     });
  //   }
  // }, [renderAudio]);

  const [audioInput, setAudioInput] = React.useState([]);
  const [audioOutput, setAudioOutput] = React.useState([]);
  const [videoOutput, setVideoOutput] = React.useState([]);
  const [selAudioInput, setSelAudioInput] = React.useState(null);
  const [selAudioOutput, setSelAudioOutput] = React.useState(null);
  const [selVideoOutput, setSelVideoOutput] = React.useState(null);
  const [raiseHandOpened, setRaiseHandOpened] = React.useState(false);
  React.useEffect(() => {
    console.log("screenSharingStatusChanged useEffect", renderVideo);
    if (jitsiObject) {
      if (
        !jitsiObject._events.chatUpdated ||
        jitsiObject._events.chatUpdated.length === 0
      ) {
        jitsiObject.addListener("chatUpdated", (obj) => {
          console.log("chatUpdated 2", obj);
          if (!obj.open) {
            setChatUnreadCount(obj.unreadCount);
          } else {
            setChatUnreadCount(0);
          }
        });
      }
      const deviceListChanged = (obj) => {
        console.log("deviceListChanged", obj);
        let devices = obj.devices;
        try {
          setAudioInput(devices.audioInput);
        } catch (err) {
          console.log(err);
        }
        try {
          setAudioOutput(devices.audioOutput);
        } catch (err) {
          console.log(err);
        }
        try {
          setVideoOutput(devices.videoInput);
        } catch (err) {
          console.log(err);
        }
      };
      jitsiObject.addListener(
        "screenSharingStatusChanged",
        shareScreenListener
      );
      jitsiObject.addListener("videoMuteStatusChanged", videoMuteStatusChanged);
      jitsiObject.addListener("audioMuteStatusChanged", audioMuteStatusChanged);

      // jitsiObject.addListener("videoConferenceJoined", videoConferenceJoined);

      jitsiObject.addListener("deviceListChanged", deviceListChanged);

      // jitsiObject.addListener(
      //   "notificationTriggered",
      //   notificationTriggeredJitsi
      // );

      // jitsiObject.addListener(
      //   "nonParticipantMessageReceived",
      //   notificationTriggeredJitsi
      // );

      // jitsiObject.addListener(
      //   "endpointTextMessageReceived",
      //   notificationTriggeredJitsi
      // );

      // jitsiObject.addListener("log", notificationTriggeredJitsi);

      // jitsiObject.addListener("outgoingMessage", notificationTriggeredJitsi);

      jitsiObject.getAvailableDevices().then((devices) => {
        console.log("devices avai-->", devices);
        try {
          setAudioInput(devices.audioInput);
        } catch (err) {
          console.log(err);
        }
        try {
          setAudioOutput(devices.audioOutput);
        } catch (err) {
          console.log(err);
        }
        try {
          setVideoOutput(devices.videoInput);
        } catch (err) {
          console.log(err);
        }
      });
      jitsiObject.getCurrentDevices().then((devices) => {
        console.log("devices curr-->", devices);
        try {
          setSelAudioInput(devices.audioInput.deviceId);
        } catch (err) {
          console.log(err);
        }
        try {
          setSelAudioOutput(devices.audioOutput.deviceId);
        } catch (err) {
          console.log(err);
        }
        try {
          setSelVideoOutput(devices.videoInput.deviceId);
        } catch (err) {
          console.log(err);
        }
      });
    }
  }, [jitsiObject]);

  // let promiseTest = jitsiObject.getContentSharingParticipants();

  const shareScreenListener = (obj) => {
    console.log(obj);
    if (obj.on) {
      jitsiObject.executeCommand("showNotification", {
        title: "Screen sharing on",
        description: "",
      });
      setRenderShareScreen(true);
    } else {
      jitsiObject.executeCommand("showNotification", {
        title: "Screen sharing off",
        description: "",
      });
      setRenderShareScreen(false);
    }
  };

  const videoMuteStatusChanged = (obj) => {
    console.log(obj);
    if (obj.muted) {
      setRenderVideo(false);
    } else {
      setRenderVideo(true);
      jitsiObject.getCurrentDevices().then((devices) => {
        try {
          console.log(
            "devices refreshDevices curr-->",
            devices.videoInput.deviceId
          );

          setSelVideoOutput(devices.videoInput.deviceId);
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  const [intervalId, setIntervalId] = useState(null);
  const videoConferenceJoinedJitsi = (obj) => {
    console.log("audioMuteStatusChanged videoConferenceJoined", obj);
    obj.isAudioMuted().then((muted) => {
      console.log(
        "audioMuteStatusChanged videoConferenceJoined 2",
        muted,
        renderAudio
      );
      if (muted) {
        console.log(
          "audioMuteStatusChanged videoConferenceJoined 3",
          obj._myUserID
        );
        obj.executeCommand("showNotification", {
          title: t("please_unmute_to_speak"),
          description: "",
        });
        setRenderAudio(false);
      } else {
        setRenderAudio(true);
      }
    });

    const intervalId = setInterval(() => {
      // console.log("audioMuteStatusChanged interval", obj);
      obj.isAudioMuted().then((muted) => {
        // console.log("audioMuteStatusChanged interval 2", muted, renderAudio);
        if (muted) {
          setRenderAudio(false);
        } else {
          setRenderAudio(true);
        }
      });
    }, 1000);
    setIntervalId(intervalId);
  };

  // const jitsiObjectRef = useRef(jitsiObject);
  const [isWhiteBoardOpened, setIsWhiteBoardOpened] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(0);
  useEffect(() => {
    if (jitsiObject) {
      meetingSocket.on(
        "openWhiteBoardRec",
        ({ userName, userId, isWhiteBoardOpenedTemp }) => {
          console.log(
            "openWhiteBoardRec",
            userName,
            userId,
            jitsiObject,
            isWhiteBoardOpenedTemp
          );
          setIsWhiteBoardOpened(true);

          if (!isWhiteBoardOpenedTemp) {
            jitsiObject.executeCommand("showNotification", {
              title: `${userName} has opened the whiteboard`,
              description: "",
            });
          }
        }
      );

      if (props.extraData && props.extraData.isDoctor) {
        setOpenMeetingMenu(false);
        handleMenuClick(6);
        setvalue(9);
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [jitsiObject]);

  useEffect(() => {
    meetingSocket.on("onFileShareRecHighlight", ({ userName }) => {
      console.log("onFileShareRecHighlight", userName);
      // toast.info(`${userName} has uploaded a new file, please check...`);
      toast.info(t("file_upload_notification", { userName }));
      setIsFileUploaded(isFileUploaded + 1);
      handleMenuClick(8);
      setvalue(5);
    });

    meetingSocket.on(
      "updateWebinarChantMessageCount",
      function (message, sentUserDtls) {
        console.log("receiveChatMessage", showWebinarChatCount);
        if (value !== 8) {
          setShowWebinarChatCount(showWebinarChatCount + 1);
        } else {
          setShowWebinarChatCount(0);
        }
      }
    );

    meetingSocket.on("endWebinarAck", function (userName) {
      console.log("endWebinarAck", userName);
      toast.info(t("the_webinar_has_ended"));
      setSelEvent(null);
      setRoomId(null);
      handleMenuCloseButton();
      // socket.emit("my other event", { my: "data" });
    });

    return () => {
      meetingSocket.off("endWebinarAck");
      meetingSocket.off("updateWebinarChantMessageCount");
      meetingSocket.off("onFileShareRecHighlight");
    };
  }, []);

  // React.useEffect(() => {
  //   // This function will be called when the component mounts
  //   let intervalId = null;
  //   console.log(
  //     "audioMuteStatusChanged",
  //     "before",
  //     renderAudio,
  //     jitsiObject,
  //     jitsiObject === undefined,
  //     jitsiObjectRef
  //   );
  //   if (jitsiObjectRef?.current) {
  //     intervalId = setInterval((jitsiObjectRef) => {
  //       console.log(
  //         "audioMuteStatusChanged",
  //         "before",
  //         renderAudio,
  //         jitsiObjectRef?.current
  //       );
  //       if (jitsiObjectRef?.current) {
  //         jitsiObjectRef?.current.isAudioMuted().then((muted) => {
  //           console.log(
  //             "audioMuteStatusChanged interval Mic",
  //             muted,
  //             renderAudio
  //           );
  //           if (muted) {
  //             setRenderAudio(false);
  //           } else {
  //             setRenderAudio(true);
  //           }
  //         });
  //       }
  //     }, 1000);
  //   }
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [props.roomNumber, jitsiObject]);

  const audioMuteStatusChanged = (obj) => {
    console.log("audioMuteStatusChanged 1", obj);
    if (obj.muted) {
      setRenderAudio(false);
    } else {
      setRenderAudio(true);
    }
  };

  const notificationTriggeredJitsi = (obj) => {
    console.log("notificationTriggeredJitsi", obj);
  };

  const refreshDevices = () => {
    console.log("refreshDevices called");
    if (jitsiObject) {
      jitsiObject.getAvailableDevices().then((devices) => {
        console.log("devices refreshDevices avai-->", devices);
        try {
          setAudioInput(devices.audioInput);
        } catch (err) {
          console.log(err);
        }
        try {
          setAudioOutput(devices.audioOutput);
        } catch (err) {
          console.log(err);
        }
        try {
          setVideoOutput(devices.videoInput);
        } catch (err) {
          console.log(err);
        }
      });
      jitsiObject.getCurrentDevices().then((devices) => {
        console.log("devices refreshDevices curr-->", devices);

        try {
          setSelAudioInput(devices.audioInput.deviceId);
        } catch (err) {
          console.log(err);
        }
        try {
          setSelAudioOutput(devices.audioOutput.deviceId);
        } catch (err) {
          console.log(err);
        }
        try {
          console.log(
            "devices refreshDevices curr-->",
            devices.videoInput.deviceId
          );
          setSelVideoOutput(devices.videoInput.deviceId);
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  React.useEffect(() => {
    // attach the event listener
    console.log("Bottom Options", props.keyPressed);

    if (props.keyPressed === "!") {
      if (jitsiObject) {
        jitsiObject.isAudioMuted().then((muted) => {
          console.log("audioMuteStatusChanged Mic", muted, renderAudio);
          if (!muted) {
            setRenderAudio(false);
            jitsiObject.executeCommand("toggleAudio");
            jitsiObject.executeCommand("showNotification", {
              title: "You have been muted",
              description: "",
            });
          } else {
            setRenderAudio(true);
            jitsiObject.executeCommand("toggleAudio");
            jitsiObject.executeCommand("showNotification", {
              title: "You have been unmuted",
              description: "",
            });
          }
        });
      }
    }

    if (props.keyPressed === "@") {
      if (jitsiObject) {
        jitsiObject.isVideoMuted().then((muted) => {
          console.log("VideoMuteStatusChanged Mic", muted, renderVideo);
          if (!muted) {
            setRenderVideo(false);
            jitsiObject.executeCommand("toggleVideo");
            jitsiObject.executeCommand("showNotification", {
              title: "Video switched off",
              description: "",
            });
          } else {
            setRenderVideo(true);
            jitsiObject.executeCommand("toggleVideo");
            jitsiObject.executeCommand("showNotification", {
              title: "Video switched on",
              description: "",
            });
          }
        });
      }
    }

    if (props.keyPressed === "#") {
      if (jitsiObject) {
        jitsiObject.executeCommand("toggleShareScreen");
      }
    }

    if (props.keyPressed === "$") {
      props.openWhiteBoard();
    }

    if (props.keyPressed === "%") {
      props.openEtherpad();
    }

    if (props.keyPressed === "^") {
      // setShowFiles(true);
      setBlinkFileUpload(0);
    }

    if (props.keyPressed === "&") {
      // handleClick();
      endCallRef.current.click();
    }

    if (props.keyPressed === "*") {
      if (jitsiObject) {
        jitsiObject.executeCommand("toggleRaiseHand");
      }
    }

    if (props.keyPressed === "(") {
      if (jitsiObject) {
        jitsiObject.executeCommand("toggleTileView");
      }
    }
    if (props.keyPressed === ")") {
      if (jitsiObject) {
        jitsiObject.executeCommand("toggleVirtualBackgroundDialog");
      }
    }
    if (props.keyPressed === "_" && props.selEvent && props.selEvent !== null) {
      setShowPrivateNote(true);
    }
    if (props.keyPressed === "+" && !props.openRoom) {
      setInvitePerModal(true);
    }

    //setShowAgendaModal(true)

    // remove the event listener
    // return () => {
    //   document.removeEventListener("keydown", keyPressed);
    // };
  }, [props.keyPressed]);

  const usersListEndRef = React.useRef(null);

  // const scrollToBottom = () => {
  //   usersListEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };

  // React.useEffect(() => {
  //   scrollToBottom();
  // }, [peopleInfo.length]);

  React.useEffect(() => {
    console.log("props.meetingEndType", props.meetingEndType);
    if (props.meetingEndType) {
      // endCallRef.current.click();
      if (props.meetingEndType === "END") {
        handleClickEndMeeting();
      } else {
        handleClickLeaveMeeting();
      }
    }
    //
  }, [props.meetingEndType]);

  const [sendingInvite, setSendingInvite] = React.useState(false);
  const [showBusyUserCallDialog, setShowBusyUserCallDialog] = useState(false);
  const [busyUserList, setBusyUserList] = useState([]);
  const [sameMeetingUser, setSameMeetingUser] = useState([]);

  // const getAllBusyUserListForInvite = async (selectedUserList) => {
  //   let busyUsers = [];
  //   const filteredPeople = selectedUserList.filter((person) => {
  //     return person !== userDetails.userId;
  //   });
  //   await MeetingService.getUserEventDetailsByDate(
  //     filteredPeople,
  //     DateUtils.getDateInYYYYMMDD(new Date()),
  //     userDetails.orgId
  //   ).then((response) => {
  //     console.log("getUserEventDetailsByDate", response.data);
  //     if (response.data) {
  //       for (const key in response.data) {
  //         //   console.log("Key:", key);
  //         //   console.log("Value:", response.data[key]);
  //         response.data[key].forEach((dto) => {
  //           // console.log("dto", dto);
  //           // console.log(DateUtils.compare(new Date(), new Date(dto.field3)));
  //           // console.log(DateUtils.compare(new Date(), new Date(dto.field4)));
  //           if (
  //             DateUtils.compare(new Date(), new Date(dto.field3)) === 1 &&
  //             DateUtils.compare(new Date(), new Date(dto.field4)) === -1
  //           ) {
  //             let busyUserObj = {
  //               userId: key,
  //               userName: dto.field5,
  //               isSameMeeting: props.selEvent.eventId === parseInt(dto.field1),
  //             };
  //             if (!busyUsers.map((user) => user.userId).includes(key)) {
  //               busyUsers.push(busyUserObj);
  //               return;
  //             }
  //           }
  //         });
  //       }
  //     }
  //   });
  //   return busyUsers;
  // };

  // const orgIntUserSendInvite = async () => {
  //   console.log("orgIntUserSendInvite", peopleInfo);
  //   console.log("orgIntUserSendInvite", outSidePersonList);

  //   if (peopleInfo.length <= 0 && outSidePersonList.length <= 0) {
  //     toast.error("Please select atleast one internal or external user", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     return;
  //   }

  //   let busyUserTempList = await getAllBusyUserListForInvite([
  //     ...peopleInfo,
  //     ...outSidePersonList,
  //   ]);
  //   console.log("orgIntUserSendInvite", busyUserTempList);
  //   console.log(
  //     "orgIntUserSendInvite",
  //     busyUserTempList.filter((userDto) => !userDto.isSameMeeting)
  //   );
  //   console.log(
  //     "orgIntUserSendInvite",
  //     busyUserTempList.filter((userDto) => userDto.isSameMeeting)
  //   );
  //   setBusyUserList(
  //     busyUserTempList.filter((userDto) => !userDto.isSameMeeting)
  //   );
  //   setSameMeetingUser(
  //     busyUserTempList.filter((userDto) => userDto.isSameMeeting)
  //   );
  //   if (busyUserTempList.length > 0) {
  //     setShowBusyUserCallDialog(true);
  //   } else {
  //     orgIntUserSendInviteFinal();
  //   }
  // };

  // const orgIntUserSendInviteFinal = () => {
  //   toast.info(`Sending Call Invite, Please wait...`, {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   setSendingInvite(true);
  //   let reqDto = {
  //     internalUserIds: peopleInfo,
  //     externalUserIds: outSidePersonList,
  //   };
  //   MeetingService.sendMeetingInviteFromOrgInternalUser(
  //     reqDto,
  //     props.roomNumber,
  //     userDetails.userId
  //   ).then((response) => {
  //     console.log(response.data);
  //     setSendingInvite(false);
  //     if (response.data.returnValue === "1") {
  //       toast.success(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       setInvitePerModal(false);
  //     }
  //   });
  // };

  // const orgExtUserSendInvite = async () => {
  //   console.log("orgExtUserSendInvite", selectExternalContactList);

  //   if (selectExternalContactList.length <= 0) {
  //     toast.error("Please select atleast one user", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     return;
  //   }
  //   let busyUserTempList = await getAllBusyUserListForInvite(
  //     selectExternalContactList.map((userDto) => userDto.id)
  //   );
  //   setBusyUserList(busyUserTempList);
  //   if (busyUserTempList.length > 0) {
  //     setShowBusyUserCallDialog(true);
  //   } else {
  //     orgExtUserSendInviteFinal();
  //   }
  // };

  // const orgExtUserSendInviteFinal = () => {
  //   toast.info(`Sending Call Invite, Please wait...`, {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   let intUserList = selectExternalContactList.map((userDto) => userDto.id);
  //   setSendingInvite(true);
  //   let reqDto = {
  //     internalUserIds: intUserList,
  //   };
  //   MeetingService.sendMeetingInviteFromOrgInternalUser(
  //     reqDto,
  //     props.roomNumber,
  //     userDetails.userId
  //   ).then((response) => {
  //     console.log(response.data);
  //     setSendingInvite(false);
  //     if (response.data.returnValue === "1") {
  //       toast.success(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       setInvitePerModal(false);
  //     }
  //   });
  // };

  // const grantModeratorJitsi = () => {
  //   // jitsiObject.executeCommand("toggleVirtualBackgroundDialog");
  //   try {
  //     console.log("grantModeratorJitsi", jitsiObject);
  //     jitsiObject.getRoomsInfo().then((roomInfo) => {
  //       console.log("grantModeratorJitsi", roomInfo);
  //       console.log("grantModeratorJitsi", roomInfo.rooms[0].participants);
  //       // jitsiObject.executeCommand("grantModerator", "da38644b");
  //     });
  //   } catch (err) {
  //     console.log("grantModeratorJitsi err", err);
  //   }
  // };
  // React.useEffect(() => {
  //   console.log("useEffect setNoOfParticipants called", jitsiObject);
  //   if (jitsiObject) {
  //     setNoOfParticipants(jitsiObject._numberOfParticipants);
  //     console.log(
  //       "useEffect setNoOfParticipants called",
  //       jitsiObject._numberOfParticipants
  //     );
  //   }
  // }, [null != jitsiObject ? jitsiObject._numberOfParticipants : 0]);

  const showMessageBusyUser = () => {};

  const [activeDrags, setActiveDrags] = useState(0);
  const handleDrag = {
    onStart: () => setActiveDrags(activeDrags + 1),
    onStop: () => setActiveDrags(activeDrags - 1),
  };
  const [boundsByDirection, setBBD] = useState(false);

  //////////////////////////////

  const muteSpeaker = () => {
    jitsiObject.setAudioOutputDevice("deviceLabel", null);
  };

  const divRef = useRef(null);

  // useEffect(() => {
  //   toast.error("changing document state");
  //   setIsFullscreen(document.fullscreenElement ? false : true);
  // }, [document.fullscreenElement]);

  const [isConvenor, setIsConvenor] = React.useState(false);

  useEffect(() => {
    if (selEvent && selEvent.eventId) {
      getChairpersonOrConvenerList(selEvent?.eventId, "convener").then(
        (response) => {
          console.log(" -->>>", response.data);
          if (response.data && response.data.length > 0) {
            if (response.data[0].userId === userDetails.userId) {
              setIsConvenor(true);
            } else {
              setIsConvenor(false);
            }
          }
        }
      );
    }
  }, [selEvent]);

  const handelDownloadFileFromUrl = (files) => {
    files.forEach((file) => {
      // var frame = document.createElement("iframe");
      // frame.src = file.fileLink;
      // frame.name = file.fileName;
      // frame["download"] = 1;
      // document.body.appendChild(frame);
      downloadFile(file.fileName, userDetails.orgId).then((response) => {
        window.open(
          apiEndPoint +
            `/appTest/downloadFileTest/${file.fileName}/${userDetails.orgId}`,
          "_blank",
          "noopener,noreferrer"
        );
      });
    });
  };

  const closeMeeting = (showFeedback = false, feedbackFrom = "Patient") => {
    setSelEvent(null);
    setRoomId(null);
    handleMenuCloseButton();
    if (showFeedback) {
      addTab({
        key: v4(),
        title: t("feedback"),
        content: (
          <DrFeedback
            feedbackFrom={feedbackFrom}
            visitId={props.extraData?.queueVisitId}
          />
        ),
        isDraggable: true,
        type: "New",
        replaceTabKey: props.tabKey,
      });
    }
  };

  return (
    <Box
      // className="meetingsTabContainerArea"
      sx={{ bgcolor: "dark.pageBg" }}
      className={
        isFullscreen
          ? "meetingsTabContainerArea fullscreen-meeting"
          : "meetingsTabContainerArea"
      }
    >
      <Grid
        container
        spacing={0}
        className="meetingsTabContainArea"
        ref={divRef}
      >
        <Grid item xs={gridItemSize} className="metConfFullWidth">
          <div className="meetingsShowAreaContain">
            {roomId ? (
              <div className="fullscreenmeeting">
                <ConfirmProvider>
                  {/* <FullScreen handle={handleVideoFullScreen}> */}
                  <JitsiVideoConference
                    openRoom={true}
                    roomNumber={roomId}
                    userName={userDetails.userName}
                    setJitsi={(jitsi) => {
                      setJitsiObject(jitsi);
                      setJitsi(jitsi);
                      // if (props.eventData) {
                      //   props.eventData._jitsiObject = jitsi;
                      //   props.eventData._setRenderAudio = setRenderAudio;
                      //   props.eventData._renderAudio = renderAudio;
                      // }
                    }}
                    selEvent={selEvent}
                    leaveMeetingRoom={() => {
                      setSelEvent(null);
                      setRoomId(null);
                      handleMenuCloseButton();
                      addTab({
                        key: v4(),
                        title: t("meeting_room"),
                        content: (
                          <ConfirmProvider>
                            <MeetingsTabContainer />
                          </ConfirmProvider>
                        ),
                        isDraggable: true,
                        type: "New",
                        replaceTabKey: props.tabKey,
                      });
                    }}
                    videoConferenceJoinedJitsi={videoConferenceJoinedJitsi}
                  />
                  {/* </FullScreen> */}
                </ConfirmProvider>
                {jitsiObject && (
                  <div className="meetingControlBtnGroup">
                    <div className="meetingTimer">
                      {selEvent && (
                        <BottomOptionsTimer
                          meetingTimer={40}
                          eventData={selEvent}
                        />
                      )}
                    </div>

                    {/* <div
                      className={`meetContBtn ${
                        renderAudio ? "metBtnActive" : ""
                      }`}
                    >
                      <Tooltip title="Mic" arrow>

                        {renderAudio && (
                          <IconButton
                            aria-label="mic"
                            size="large"
                            onClick={() => {
                              // setRenderAudio(false);
                              if (jitsiObject) {
                                jitsiObject.isAudioMuted().then((muted) => {
                                  console.log(
                                    "audioMuteStatusChanged Mic",
                                    muted,
                                    renderAudio
                                  );
                                  if (!muted) {
                                    setRenderAudio(false);
                                    jitsiObject.executeCommand("toggleAudio");
                                  }
                                });
                              }
                            }}
                          >
                            <Mic />
                          </IconButton>
                        )}
                        {!renderAudio && (
                          <IconButton
                            aria-label="mic"
                            size="large"
                            onClick={async () => {
                              // setRenderAudio(true);
                              console.log(
                                "audioMuteStatusChanged Mic",
                                jitsiObject
                              );
                              if (jitsiObject) {
                                jitsiObject
                                  .isAudioMuted()
                                  .then(async (muted) => {
                                    console.log(
                                      "audioMuteStatusChanged Mic",
                                      muted,
                                      renderAudio
                                    );
                                    if (muted) {
                                      setRenderAudio(true);
                                      await jitsiObject.executeCommand(
                                        "toggleAudio"
                                      );
                                      refreshDevices();
                                    }
                                  });
                              }
                              // if (jitsiObject) {

                              // }
                            }}
                          >
                            <MicOff
                            // className="video-button-icons"
                            />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div> */}

                    {/* <div
                      className={`meetContBtn ${
                        renderVideo ? "metBtnActive" : ""
                      }`}
                    >
                      <Tooltip title="Video" arrow>
                        {renderVideo && (
                          <IconButton
                            aria-label="Videocam"
                            size="large"
                            onClick={async () => {
                              // setRenderVideo(false);
                              if (jitsiObject) {
                                jitsiObject.executeCommand("toggleVideo");
                              }
                            }}
                          >
                            <Videocam />
                          </IconButton>
                        )}
                        {!renderVideo && (
                          <IconButton
                            aria-label="Videocam"
                            size="large"
                            onClick={async () => {
                              // setRenderVideo(true);
                              if (jitsiObject) {
                                await jitsiObject.executeCommand("toggleVideo");
                                refreshDevices();
                              }
                            }}
                          >
                            <VideocamOff />
                          </IconButton>
                        )}
                      </Tooltip>
                    </div> */}

                    {/* <div
                      className={`meetContBtn ${
                        renderShareScreen ? "metBtnActive" : ""
                      }`}
                    >
                      <Tooltip title="Share Screen" arrow>
                        <IconButton aria-label="ScreenShare" size="large">
                          {!renderShareScreen && (
                            <ScreenShare
                              onClick={() => {
                                if (jitsiObject) {
                                  jitsiObject.executeCommand(
                                    "toggleShareScreen"
                                  );
                                }
                              }}
                            />
                          )}
                          {renderShareScreen && (
                            <StopScreenShare
                              onClick={() => {
                                if (jitsiObject) {
                                  jitsiObject.executeCommand(
                                    "toggleShareScreen"
                                  );
                                }
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div> */}
                    {/* <div className="meetContBtn">
                    <Tooltip title="Note" arrow>
                      <IconButton aria-label="PageEdit" size="large">
                        <NoteAlt />
                      </IconButton>
                    </Tooltip>
                  </div> */}
                    <div
                      className={`meetContBtn ${
                        value === 7 ? "metBtnActive" : ""
                      }`}
                    >
                      <Tooltip title={t("white_board")} arrow>
                        <IconButton
                          onClick={() => {
                            // props.openWhiteBoard(selEvent);
                            if (value === 7) {
                              handleMenuCloseButton();
                            } else {
                              setOpenMeetingMenu(false);
                              handleMenuClick(6);
                              setvalue(7);
                            }
                          }}
                          aria-label="DesignServices"
                          size="large"
                        >
                          <DesignServices />
                        </IconButton>
                      </Tooltip>
                    </div>

                    {selEvent &&
                      selEvent !== null &&
                      meetingAgendasList !== null &&
                      meetingAgendasList.length > 0 && (
                        <div
                          className={`meetContBtn ${
                            showAgendaModal ? "metBtnActive" : ""
                          }`}
                        >
                          <Tooltip title={t("meeting_agenda")} arrow>
                            <IconButton
                              onClick={() => {
                                setShowAgendaModal(true);
                              }}
                              aria-label="DesignServices"
                              size="large"
                            >
                              <PageStar />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    {/* <div
                    style={{ display: "none !important" }}
                    className={`meetContBtn ${false ? "metBtnActive" : ""}`}
                  >
                    <Tooltip title="Take screenshot" arrow>
                      <IconButton
                        onClick={() => {
                          toggleFullscreen();
                        }}
                        aria-label="DesignServices"
                        size="large"
                      >
                        <Screenshot />
                      </IconButton>
                    </Tooltip>
                  </div> */}

                    <div
                      className={`meetContBtn ${false ? "metBtnActive" : ""}`}
                    >
                      <Tooltip title={t("meeting_chat")} arrow>
                        {selEvent?.eventType !== "Webinar" && (
                          <IconButton
                            aria-label="Chat"
                            size="large"
                            onClick={() => {
                              jitsiObject.executeCommand("toggleChat");
                            }}
                          >
                            <Badge
                              badgeContent={chatUnreadCount}
                              color="primary"
                            >
                              <Chat />
                            </Badge>
                          </IconButton>
                        )}
                        {selEvent?.eventType === "Webinar" && (
                          <IconButton
                            aria-label="Chat"
                            size="large"
                            onClick={() => {
                              handleMenuClick(6);
                              setvalue(8);
                              setShowWebinarChatCount(0);
                              // WebinarService.getWebinarChat(roomId).then(
                              //   (response) => {
                              //     setWebinarChatData(response.data);
                              //     // setShowWebinarChat(true);
                              //     setvalue(8);
                              //   }
                              // );
                            }}
                          >
                            <Badge
                              color="primary"
                              // variant="dot"
                              // invisible={showWebinarChatCount <= 0}
                              badgeContent={
                                value === 8 ? 0 : showWebinarChatCount
                              }
                            >
                              <Chat />
                            </Badge>
                          </IconButton>
                          // <Button
                          //   className={"meetingMenuBtn"}
                          //   sx={{ padding: "25px" }}
                          //   onClick={() => {
                          //     // jitsiObject.executeCommand("toggleChat");
                          //     // WebinarService.getWebinarChat(
                          //     //   props.roomNumber
                          //     // ).then((response) => {
                          //     //   setWebinarChatData(response.data);
                          //     //   setShowWebinarChat(true);
                          //     // });
                          //   }}
                          // >
                          //   <Tooltip title={"Chat"}>
                          //     {/* <Badge badgeContent={chatUnreadCount} color="primary"> */}
                          //     <Badge
                          //       color="primary"
                          //       variant="dot"
                          //       invisible={showWebinarChatCount <= 0}
                          //     >
                          //       <ChatBubble />
                          //     </Badge>

                          //     {/* </Badge> */}
                          //   </Tooltip>

                          // </Button>
                        )}
                      </Tooltip>
                    </div>

                    <div className="meetContBtn">
                      <ShareOptions roomNumber={roomId} />
                    </div>

                    {/* <div
                      className={`meetContBtn ${false ? "metBtnActive" : ""}`}
                    >
                      <Tooltip title="Full Screen" arrow>
                        <IconButton
                          onClick={() => {
                            if (jitsiObject) {
                              handleFullscreen();
                            }
                          }}
                          aria-label="BackHand"
                          size="large"
                        >
                          {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                    
                        </IconButton>
                      </Tooltip>
                    </div> */}

                    <div
                      className={`meetContBtn ${false ? "metBtnActive" : ""}`}
                    >
                      <Tooltip title={t("raise_hand")} arrow>
                        <IconButton
                          onClick={() => {
                            if (jitsiObject) {
                              jitsiObject.executeCommand("toggleRaiseHand");
                              // setRaiseHandOpened(true);
                            }
                          }}
                          aria-label="BackHand"
                          size="large"
                        >
                          <BackHand />
                        </IconButton>
                      </Tooltip>
                    </div>
                    {userDetails.userType !== "GUEST" && (
                      <div className="meetContBtn">
                        <Tooltip title={t("invite_user")} arrow>
                          <IconButton
                            onClick={() => {
                              setOpenMeetingMenu(false);
                              handleMenuClick(8);
                              setvalue(4);
                            }}
                            aria-label="BackHand"
                            size="large"
                          >
                            <PersonAdd />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {/* <MenuItem
                    onClick={() => {
                      setOpenMeetingMenu(false);
                      handleMenuClick(8);
                      setvalue(4);
                    }}
                  >
                    <div className="meetMrMnuBtn">
                      <PersonAdd /> <span>Invite User</span>
                    </div>
                  </MenuItem> */}
                    {selEvent?.eventType === "Webinar" &&
                      isConvenor &&
                      !streamStarted && (
                        <div className="meetContBtn">
                          <Tooltip title={t("start_live_stream")} arrow>
                            <IconButton
                              onClick={() => {
                                console.log(selEvent.eventWebinarStreamKey);
                                setStreamStarted(true);
                                jitsiObject.executeCommand("startRecording", {
                                  mode: "stream",
                                  youtubeStreamKey:
                                    selEvent.eventWebinarStreamKey,
                                });
                              }}
                            >
                              <LiveTvOutlined />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    {selEvent?.eventType === "Webinar" && streamStarted && (
                      <div
                        className="meetContBtn"
                        style={{ color: "red !important" }}
                      >
                        <Tooltip title={t("stop_live_stream")} arrow>
                          <IconButton
                            onClick={() => {
                              jitsiObject.executeCommand(
                                "stopRecording",
                                "stream"
                              );
                              setStreamStarted(false);
                            }}
                          >
                            <LiveTvOutlined />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {props.extraData && props.extraData.isDoctor && (
                      <div className="meetContBtn">
                        <Tooltip title={t("medication")} arrow>
                          <IconButton
                            onClick={() => {
                              setOpenMeetingMenu(false);
                              handleMenuClick(6);
                              setvalue(9);
                            }}
                            aria-label="BackHand"
                            size="large"
                          >
                            <Medication />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    <div className="meetContBtn callEnd">
                      <Tooltip title={t("leave_call")} arrow>
                        <IconButton
                          onClick={() => {
                            if (selEvent?.eventType === "Webinar") {
                              setShowEndCallDialog(true);
                            } else {
                              confirm({
                                title: t("are_you_sure"),
                                description: t("leave_meeting"),
                                confirmationText: t("ok"),
                                cancellationText: t("cancel"),
                              })
                                .then(() => {
                                  if (props.extraData) {
                                    closeMeeting(
                                      !props.extraData.noFeedback,
                                      "Patient"
                                    );
                                    return;
                                  }
                                  console.log(props);
                                  setSelEvent(null);
                                  setRoomId(null);
                                  handleMenuCloseButton();
                                  addTab({
                                    key: v4(),
                                    title: t("meeting_room"),
                                    content: (
                                      <ConfirmProvider>
                                        <MeetingsTabContainer />
                                      </ConfirmProvider>
                                    ),
                                    isDraggable: true,
                                    type: "New",
                                    replaceTabKey: props.tabKey,
                                  });
                                })
                                .catch(() => {
                                  console.log("Leave meeting");
                                });
                            }
                          }}
                          aria-label="callEnd"
                          size="large"
                        >
                          <CallEnd />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="meetContBtn ml5">
                      <Tooltip title={t("more")} arrow>
                        <IconButton
                          aria-label="More"
                          size="large"
                          onClick={(event) =>
                            setOpenMeetingMenu(event.currentTarget)
                          }
                          aria-controls={
                            open ? "moreMenuOptionView" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVert />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <Menu
                      className="moreMenuOptionView"
                      id="moreMenuOptionView"
                      anchorEl={openMeetingMenu}
                      open={Boolean(openMeetingMenu)}
                      onClose={() => setOpenMeetingMenu(false)}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(0);
                        }}
                      >
                        {/* <Button startIcon={<CcCircleFill />}>
                        Transcription Details
                      </Button> */}
                        <div className="meetMrMnuBtn">
                          <CcCircleFill />{" "}
                          <span>{t("transcription_details")}</span>
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(1);
                        }}
                      >
                        {/* <Button startIcon={<Language />}>
                        Select Spoken Languages
                      </Button> */}
                        <div className="meetMrMnuBtn">
                          <Language />{" "}
                          <span>{t("select_spoken_languages")}</span>
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(2);
                        }}
                      >
                        {/* <Button startIcon={<Groups />}>Participants</Button> */}
                        <div className="meetMrMnuBtn">
                          <Groups /> <span>{t("participants")}</span>
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(3);
                        }}
                      >
                        {/* <Button startIcon={<Description />}>Meeting Notes</Button> */}
                        <div className="meetMrMnuBtn">
                          <Description /> <span>{t("meeting_notes")}</span>
                        </div>
                      </MenuItem>
                      {/* <MenuItem
                        onClick={(event) => {
                          handleClickAudioOptions(event);
                          setOpenMeetingMenu(false);
                          // handleMenuClick(8);
                          // setvalue(3);
                        }}
                      >
                        <div className="meetMrMnuBtn">
                          <SpeakerGroup /> <span>Audio Devices</span>
                        </div>
                      </MenuItem> */}

                      <Menu
                        className="audioOptions"
                        Private
                        Notes
                        id="audio-options"
                        anchorEl={anchorAudioOptions}
                        open={openAudioOptions}
                        onClose={handleCloseAudioOptions}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <Paper
                          sx={{
                            p: 2,
                            margin: "auto",
                            maxWidth: 300,
                            flexGrow: 1,
                          }}
                          elevation={0}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            // columnSpacing={"5px"}
                            columns={16}
                          >
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <Typography>
                                <Mic />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={13} lg={13}>
                              <Typography>{t("microphones")}</Typography>
                              <br></br>
                            </Grid>
                            {audioInput &&
                              audioInput.map((device) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Typography>
                                        {selAudioInput &&
                                          device &&
                                          device.deviceId === selAudioInput && (
                                            <Check />
                                          )}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={13}
                                      lg={13}
                                      sx={{ cursor: "pointer" }}
                                      mb={1}
                                      onClick={() => {
                                        jitsiObject.setAudioInputDevice(
                                          device.label,
                                          device.deviceId
                                        );
                                        setSelAudioInput(device.deviceId);
                                      }}
                                    >
                                      <Typography>{device.label}</Typography>
                                    </Grid>
                                  </>
                                );
                              })}
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <hr></hr>
                            </Grid>
                            <Grid item xs={12} sm={12} md={13} lg={13}>
                              <hr></hr>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <Typography>
                                <Speaker />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={13} lg={13}>
                              <Typography>{t("speakers")}</Typography>
                              <br></br>
                            </Grid>
                            {audioOutput &&
                              audioOutput.map((device) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Typography>
                                        {device &&
                                          selAudioOutput &&
                                          device.deviceId ===
                                            selAudioOutput && <Check />}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={13}
                                      lg={13}
                                      sx={{ cursor: "pointer" }}
                                      mb={1}
                                      onClick={() => {
                                        console.log(
                                          "setAudioOutputDevice-->",
                                          device
                                        );
                                        jitsiObject.setAudioOutputDevice(
                                          device.label,
                                          device.deviceId
                                        );
                                        setSelAudioOutput(device.deviceId);
                                      }}
                                    >
                                      <Typography>{device.label}</Typography>
                                    </Grid>
                                  </>
                                );
                              })}
                          </Grid>
                        </Paper>
                        {/* <List>
            <ListItem>
              <Speaker /> Speakers
            </ListItem>
            {audioOutput.map((device) => {
              return <ListItemButton>{device.label}</ListItemButton>;
            })}
          </List> */}
                      </Menu>

                      {/* <MenuItem
                        onClick={(event) => {
                          handleClickVideoOptions(event);
                          setOpenMeetingMenu(false);
                          // handleMenuClick(8);
                          // setvalue(3);
                        }}
                      >
                        <div className="meetMrMnuBtn">
                          <Camera /> <span>Video Devices</span>
                        </div>
                      </MenuItem> */}

                      <Menu
                        className="videoOptions"
                        id="video-options"
                        anchorEl={anchorVideoOptions}
                        open={openVideoOptions}
                        onClose={handleCloseVideoOptions}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <Paper
                          sx={{
                            p: 2,
                            margin: "auto",
                            maxWidth: 300,
                            flexGrow: 1,
                          }}
                          elevation={0}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            // columnSpacing={"5px"}
                            columns={16}
                          >
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <Typography>
                                <VideoCamera />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={13} lg={13}>
                              <Typography>{t("video_camera")}</Typography>
                              <br></br>
                            </Grid>
                            {videoOutput &&
                              videoOutput.map((device) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Typography>
                                        {device &&
                                          selVideoOutput &&
                                          device.deviceId ===
                                            selVideoOutput && <Check />}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={13}
                                      lg={13}
                                      sx={{ cursor: "pointer" }}
                                      mb={1}
                                      onClick={() => {
                                        jitsiObject.setVideoInputDevice(
                                          device.label,
                                          device.deviceId
                                        );
                                        setSelVideoOutput(device.deviceId);
                                      }}
                                    >
                                      <Typography>{device.label}</Typography>
                                    </Grid>
                                  </>
                                );
                              })}
                          </Grid>
                        </Paper>
                      </Menu>

                      <MenuItem
                        // onClick={() => {
                        //   setOpenMeetingMenu(false);
                        //   handleMenuClick(8);
                        //   setvalue(3);
                        // }}
                        onClick={() => {
                          if (jitsiObject) {
                            jitsiObject.executeCommand("toggleTileView");
                          }
                          setOpenMeetingMenu(false);
                          // handleMenuClick(8);
                        }}
                      >
                        {/* <Button startIcon={<Description />}>Meeting Notes</Button> */}
                        <div className="meetMrMnuBtn">
                          <GridViewOutlined /> <span>{t("grid_view")}</span>
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          if (jitsiObject) {
                            jitsiObject.executeCommand(
                              "toggleVirtualBackgroundDialog"
                            );
                          }
                          setOpenMeetingMenu(false);
                        }}
                      >
                        {/* <Button startIcon={<Description />}>Meeting Notes</Button> */}
                        <div className="meetMrMnuBtn">
                          <WallpaperOutlined />{" "}
                          <span>{t("virtual_background")}</span>
                        </div>
                      </MenuItem>
                      {/* <MenuItem
                      onClick={() => {
                        setOpenMeetingMenu(false);
                        handleMenuClick(8);
                        setvalue(4);
                      }}
                    >
                      <div className="meetMrMnuBtn">
                        <PersonAdd /> <span>Invite User</span>
                      </div>
                    </MenuItem> */}
                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(5);
                          setIsFileUploaded(0);
                        }}
                      >
                        {/* <Button startIcon={<FolderShared />}>File Share</Button> */}

                        <div className="meetMrMnuBtn">
                          <Badge
                            variant="dot"
                            color="primary"
                            invisible={isFileUploaded === 0}
                          >
                            {" "}
                            <FolderShared />
                          </Badge>{" "}
                          <span>{t("file_share")}</span>
                        </div>
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          setOpenMeetingMenu(false);
                          handleMenuClick(8);
                          setvalue(6);
                        }}
                      >
                        {/* <Button startIcon={<MeetingRoom />}>
                        Breakout Rooms
                      </Button> */}
                        <div className="meetMrMnuBtn">
                          <MeetingRoom /> <span>{t("breakout_rooms")}</span>
                        </div>
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </div>
            ) : (
              <div className="joinMeetInstanceRoomid">
                <div className="joinMeetInstanceRoomidContainer">
                  {!loading && (
                    <div className="joinMetWthRmIDGrp">
                      <div className="inputRumID">
                        <input
                          type="text"
                          placeholder={t("enter_room_number")}
                          className="joinMetRmIdInputBox"
                          value={roomId2}
                          onChange={(e) => {
                            const val = e.target.value;
                            const regx = /\D/g;
                            e.target.value = val.replace(regx, "");
                            setRoomId2(val.replace(regx, ""));
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              console.log("onKeyDown join:", event.key);
                              joinRoom(null);
                            }
                          }}
                        />
                      </div>
                      <div className="joinRumIDBtn">
                        {!loadingRoom && (
                          <Button
                            className="joinBtnRoomNo"
                            variant="contained"
                            onClick={() => {
                              joinRoom(null);
                            }}
                          >
                            {t("join")}
                          </Button>
                        )}
                        {loadingRoom && (
                          <Button variant="contained">
                            <CircularProgress
                              size={20}
                              sx={{
                                color: (theme) => theme.palette.grey[100],
                              }}
                            />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  {!loadingRoom && (
                    <>
                      {!loading && <div className="or">{t("or")}</div>}
                      <div className="joinMeetingBtnGroup">
                        {!loading && (
                          <Tooltip
                            // title={
                            //   roomId2 !== "" && roomId2 != null
                            //     ? "You have entered a room number, please use join"
                            //     : ""
                            // }
                            title={
                              roomId2 !== "" && roomId2 != null
                                ? t("enteredRoomMessage")
                                : ""
                            }
                          >
                            <Button
                              className="joinInsMetBtn"
                              startIcon={<Videocam />}
                              onClick={() =>
                                handleSubmit(startInstantMeeting)()
                              }
                              disabled={roomId2 !== "" && roomId2 != null}
                            >
                              {t("start_instant_meeting")}
                            </Button>
                          </Tooltip>
                        )}
                        {loading && (
                          <Button
                            className="joinInsMetBtn"
                            onClick={() => handleSubmit(startInstantMeeting)()}
                          >
                            <CircularProgress
                              size={20}
                              sx={{
                                color: (theme) => theme.palette.grey[100],
                              }}
                            />
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </Grid>
        {gridItemSize === 8 && (
          <Grid item xs={4} className="fullWidth100">
            <div className="meetingTabRightPanel">
              <MeetingTabRightPanel
                handleMenuCloseButton={handleMenuCloseButton}
                value={value}
                selEvent={selEvent}
                eventId={eventId}
                roomNumber={roomId}
                jitsiObject={jitsiObject}
                extraData={props.extraData}
                closeMeeting={closeMeeting}
                prescriptionData={prescriptionData}
                updatePrescriptionData={updatePrescriptionData}
              />
            </div>
          </Grid>
        )}
        {gridItemSize === 6 && (
          <Grid item xs={6} className="fullWidth100">
            <div className="meetingTabRightPanel">
              <MeetingTabRightPanel
                handleMenuCloseButton={handleMenuCloseButton}
                value={value}
                selEvent={selEvent}
                eventId={eventId}
                roomNumber={roomId}
                jitsiObject={jitsiObject}
                isWhiteBoardOpened={isWhiteBoardOpened}
                setIsWhiteBoardOpened={setIsWhiteBoardOpened}
                extraData={props.extraData}
                closeMeeting={closeMeeting}
                prescriptionData={prescriptionData}
                updatePrescriptionData={updatePrescriptionData}
              />
            </div>
          </Grid>
        )}
      </Grid>
      {showAgendaModal && (
        <Modal
          open={showAgendaModal}
          onClose={() => setShowAgendaModal(false)}
          aria-labelledby="modal-modal-invite-person"
          aria-describedby="modal-modal-invite-person"
          className="customModal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "1px solid #8d8d8d",
              borderRadius: "5px",
              boxShadow: 24,
              width: "50%",
              maxHeight: "90vh",
              overflow: "auto",
              p: 2,
            }}
            className="responsiveModalAdmin"
          >
            <div style={{ marginBottom: "20px" }}>
              <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                <label>{t("meeting_agendas")}</label>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <table className="hrActivityGroupTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("subject")}</th>
                      <th>{t("details")}</th>
                      <th>{t("p_s")}</th>
                      <th>{t("files")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {meetingAgendasList !== null &&
                    meetingAgendasList.length > 0 ? (
                      meetingAgendasList.map((agenda, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{agenda.noticeSubject}</td>
                          <td>{agenda.noticeDetail}</td>
                          <td>
                            {agenda.polling === "Y" && (
                              <Tooltip
                                title={t("polling")}
                                arrow
                                placement="right"
                              >
                                <OneFingerSelectHandGesture className="attachIcoSelected" />
                              </Tooltip>
                            )}
                            {agenda.showOfHand === "Y" && (
                              <Tooltip
                                title={t("show_of_hand")}
                                arrow
                                placement="right"
                              >
                                <SpockHandGesture className="attachIcoSelected" />
                              </Tooltip>
                            )}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {agenda.fileLinks.length > 0 && (
                              <>
                                <Tooltip
                                  title={
                                    <ul>
                                      {agenda.fileLinks.map(
                                        (file, fileIndex) => (
                                          <li key={fileIndex}>
                                            {file.fileName}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  }
                                >
                                  <AttachFile
                                    className="attachIcoSelected"
                                    onClick={() =>
                                      handelDownloadFileFromUrl(
                                        agenda.fileLinks
                                      )
                                    }
                                  />
                                </Tooltip>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">{t("no_agenda_available")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => setShowAgendaModal(false)}
              sx={{ float: "right" }}
            >
              {t("close")}
            </Button>
          </Box>
        </Modal>
      )}

      {showEndCallDialog && (
        <Modal
          open={showEndCallDialog}
          onClose={() => setShowEndCallDialog(false)}
          aria-labelledby="modal-modal-invite-person"
          aria-describedby="modal-modal-invite-person"
          className="customModal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "1px solid #8d8d8d",
              borderRadius: "5px",
              boxShadow: 24,
              width: "30%",
              maxHeight: "90vh",
              overflow: "auto",
              p: 2,
            }}
            className="responsiveModalAdmin"
          >
            <IconButton
              disabled={loading}
              className="closeWebinarLeaveModelBtn"
              onClick={() => setShowEndCallDialog(false)}
            >
              <XLg />
            </IconButton>
            <div style={{ padding: "5px" }}>
              <div
                className="modelTitle"
                style={{ padding: "5px 0px 20px 0px" }}
              >
                <span>
                  {isConvenor ? t("leave_end_webinar") : t("leave_webinar")}
                </span>
              </div>
            </div>

            <div className="webinarLeaveBtnGrp">
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => setShowEndCallDialog(false)}
              >
                {t("close")}
              </Button>
              {isConvenor && (
                <Button
                  className="endForAll"
                  onClick={() => {
                    confirm({
                      description: t(
                        "end_the_webinar_for_all_users_the_webinar_will_be_closed_and_you_will_not_be_able_to_start_it_again"
                      ),
                    })
                      .then(() => {
                        setSelEvent(null);
                        setRoomId(null);
                        handleMenuCloseButton();
                        jitsiObject.executeCommand("stopRecording", "stream");
                        setStreamStarted(false);
                        meetingSocket.emit("endWebinar", {
                          userName: userDetails.userName,
                          roomNumber: roomId,
                        });
                        setShowEndCallDialog(false);

                        let htmlOtgMsg =
                          '<div class="message-wrapper outgoing-msg">' +
                          '<div class="message">' +
                          "<span>" +
                          "The webinar has ended, you will be able to view the webinar and see the previous messages only" +
                          "</span>" +
                          '<div class="messageMeta">' +
                          "<span>" +
                          "System generated message" +
                          "</span> &nbsp" +
                          '<span class="chatDT">' +
                          DateUtils.getDateInDDMMYYYYHH12MM(new Date()) +
                          "</span>" +
                          "</div>" +
                          "</div>" +
                          "</div>";
                        // document.getElementById("nav-chat").append(htmlOtgMsg);

                        let reqDto = {
                          userId: userDetails.userId,
                          userName: "System generated message",
                          chatText:
                            "The webinar has ended, you will be able to view the recorder webinar in the webinar link and see the previous messages only",
                          roomId: roomId,
                          activeFlag: "Y",
                        };

                        WebinarService.addWebinarChat(reqDto).then(
                          (response) => {
                            console.log(
                              "addWebinarChat response",
                              response.data
                            );
                          }
                        );

                        // navTabRef.current.innerHTML += htmlOtgMsg;
                        meetingSocket.emit("sendChatMessage", {
                          roomId: roomId,
                          message:
                            "The webinar has ended, you will be able to view the recorded webinar in the webinar link and see the previous messages only",
                          userDetails: {
                            userEmailId: userDetails.userEmailId,
                            userName: "System generated message",
                          },
                        });

                        addTab({
                          key: v4(),
                          title: t("meeting_room"),
                          content: (
                            <ConfirmProvider>
                              <MeetingsTabContainer />
                            </ConfirmProvider>
                          ),
                          isDraggable: true,
                          type: "New",
                          replaceTabKey: props.tabKey,
                        });
                      })
                      .catch(() => {
                        console.log("End webinar cancelled");
                        // setShowEndCallDialog(false);
                      });
                  }}
                >
                  {t("end_webinar_for_all")}
                </Button>
              )}
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  setSelEvent(null);
                  setRoomId(null);
                  handleMenuCloseButton();
                  setShowEndCallDialog(false);
                  addTab({
                    key: v4(),
                    title: t("meeting_room"),
                    content: (
                      <ConfirmProvider>
                        <MeetingsTabContainer />
                      </ConfirmProvider>
                    ),
                    isDraggable: true,
                    type: "New",
                    replaceTabKey: props.tabKey,
                  });
                }}
              >
                {t("leave_webinar")}
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  );
}
