import { Add, Info } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {
  getEmploymentTypes,
  getLeaveTypesWithMenu,
  getLeaveRulesOfOrg,
  editLeaveRule,
  //   addLeaveRule
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";

const leaveType = [
  { label: "Privilege Leave (PL)" },
  { label: "Earned Leave (EL)" },
  { label: "Casual Leave (CL)" },
  { label: "Sick Leave (SL)" },
  { label: "Maternity Leave (ML)" },
  { label: "Compensatory Off (Comp-off)" },
  { label: "Marriage Leave" },
  { label: "Paternity Leave" },
  { label: "Bereavement Leave" },
  { label: "Leave Without Pay (LWP)" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectedViewLeaveRule({
  handleCloseViewSelectedLeaveRule,
  refreshAddLeaveRulesData,
  viewSelectedLeaveRuleData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  console.log("viewSelectedLeaveRuleData", viewSelectedLeaveRuleData);

  const [loading, setLoading] = useState(false);

  const [emplyTypes, setEmplyTypes] = useState([]);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [leaveTypeTextValue, setLeaveTypeTextvalue] = useState("");

  const initialleavevalue = {
    leaveTypeId: 0,
    leaveType: leaveTypeTextValue,
    gender: "Y",
    empStatus: "Y",
    salaryType: "Y",
    fileRequired: "Y",
    noOfLeaves: "Y",
    leaveAccruedMonthly: "Y",
    leaveAccruedYearly: "Y",
    minDays: "Y",
    maxDays: "Y",
    combineOtherLeaveTypeIds: "Y",
    minEmploymentDays: "Y",
    applicableBeforeEvent: "Y",
    applicableAfterEvent: "Y",
    carryForward: "Y",
    maximumCarryForward: "Y",
    minNoticeDays: "Y",
    encashable: "Y",
    minEncashBalCurrentEmp: "Y",
    minEncashBalExEmp: "Y",
    allowHalfDay: "Y",
    minLeaveBalanceForEncash: "Y",
    leaveBalanceExpireDays: "Y",
  };
  const [selectedLeaveType, setSelectedLeaveType] = useState(initialleavevalue);

  useEffect(() => {
    getEmploymentTypes(userDetails.orgId).then((response) => {
      console.log("Get Employment Types", response.data);
      setEmplyTypes(response.data);
    });
  }, [userDetails.orgId]);

  useEffect(() => {
    getLeaveTypesWithMenu().then((response) => {
      if (response.data && response.data.length > 0) {
        console.log("get all data", response.data);
        setLeaveTypeList(response.data);
      }
    });
  }, []);

  const [LeaveRuleData, setLeaveRuleData] = useState([]);

  useEffect(() => {
    getLeaveRulesOfOrg(userDetails.orgId).then((response) => [
      console.log("getLeaverules", response.data),
      setLeaveRuleData(response.data),
    ]);
  }, []);

  const [leaveTypeListValue, setLeaveTypeListvalue] = useState("");

  const handleLeaveTypeListvalue = (event, newValue) => {
    console.log("handleLeaveTypeListvalue", newValue);
    setLeaveTypeListvalue(newValue);
  };

  const handleLeaveTypeTextvalue = (event, newValue) => {
    console.log("handleLeaveTypeTextvalue", newValue);
    setLeaveTypeTextvalue(newValue);
  };

  const employmentTypeList = ["Intern", "Payroll", "Retainer", "Contractual"];
  const employeeStatusList = ["In Probation", "Confirmed"];
  const genderList = ["Male", "Female", "Others"];
  const comboWithLeave = [
    "Privilege Leave (PL)",
    "Earned Leave (EL)",
    "Casual Leave (CL)",
    "Sick Leave (SL)",
    "Maternity Leave (ML)",
    "Compensatory Off (Comp-off)",
    "Marriage Leave",
    "Paternity Leave",
    "Bereavement Leave",
    "Leave Without Pay (LWP)",
  ];
  const [selectGender, setSelectGender] = useState([]);
  const [selectEmploymentType, setSelectEmploymentType] = useState([]);
  const [selectEmployeeStatus, setSelectEmployeeStatus] = useState([]);
  const [selectCombWthOthLeav, setSelectCombWthOthLeav] = useState([
    viewSelectedLeaveRuleData.combineOtherLeaveTypes,
  ]);

  const handleChangeCombWthOthLeav = (event) => {
    const {
      target: { value },
    } = event;
    // setSelectCombWthOthLeav(
    //   typeof value === "string" ? value.split(",") : value
    // );
  };
  const handleChangeEmploymentType = (event) => {
    console.log("handleChangeEmploymentType", event.target.value);
    const {
      target: { value },
    } = event;
    setSelectEmploymentType(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeEmployeeStatus = (event) => {
    console.log("handleChangeEmployeeStatus", event.target.value);
    const {
      target: { value },
    } = event;
    setSelectEmployeeStatus(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeGender = (event) => {
    console.log("handleChangeGender", event.target.value);
    const {
      target: { value },
    } = event;
    setSelectGender(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const NewValue = viewSelectedLeaveRuleData.leaveTypeDisplay;
    if (NewValue) {
      const selectedvalue = leaveTypeList.find(
        (item) => item.leaveType === NewValue
      );
      console.log("my object", selectedvalue);
      // setSelectedLeaveType(selectedvalue);
      if (selectedvalue) {
        setSelectedLeaveType(selectedvalue);
      } else {
        setSelectedLeaveType(initialleavevalue);
      }
    }
  }, [leaveTypeListValue, leaveTypeList]);

  console.log("selectedLeaveType", selectedLeaveType);

  const [showCarryForwardInput, setShowCarryForwardInput] = useState(
    viewSelectedLeaveRuleData.carryForward === "Y" ? true : false
  );

  const handleShowCarryForwardInput = (e) => {
    // setShowCarryForwardInput(e.target.value === "Y");
  };

  const [showEncasableInput, setShowEncasableInput] = useState(
    viewSelectedLeaveRuleData.encashable === "Y" ? true : false
  );

  const handleshowEncasableInput = (e) => {
    // setShowEncasableInput(e.target.value === "Y");
  };

  const [fileRequiredValue, setFileRequiredValue] = useState(
    viewSelectedLeaveRuleData.fileRequired
  );
  // const [updatedfileRequiredValue, setUpdatedFileRequiredValue] = useState("");

  const handleFileRequiredValue = (event) => {
    console.log("handleFileRequiredValue", event.target.value);
    // setFileRequiredValue(event.target.value);
  };

  const [numberOfLeavesValue, setNumberOfLeavesValue] = useState(
    viewSelectedLeaveRuleData.noOfLeaves
  );

  const handleNumberOfLeavesValue = (event) => {
    console.log("handleNumberOfLeavesValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setNumberOfLeavesValue(numericValue);
  };

  const [leaveAccruedMonthlyValue, setLeaveAccruedMonthlyValue] = useState(
    viewSelectedLeaveRuleData.leaveAccruedMonthly
  );

  const handleLeaveAccruedMonthlyValue = (event) => {
    console.log("handleLeaveAccruedMonthlyValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeaveAccruedMonthlyValue(numericValue);
  };

  const [leaveAccruedYearlyValue, setLeaveAccruedYearlyValue] = useState(
    viewSelectedLeaveRuleData.leaveAccruedYearly
  );

  const handleLeaveAccruedYearlyValue = (event) => {
    console.log("handleLeaveAccruedYearlyValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeaveAccruedYearlyValue(numericValue);
  };

  const [minimumLeavesValue, setMinimumLeavesValue] = useState(
    viewSelectedLeaveRuleData.minDays
  );

  const handleMinimumLeavesValue = (event) => {
    console.log("handleMinimumLeavesValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMinimumLeavesValue(numericValue);
  };

  const [maximumLeavesValue, setMaximumLeavesValue] = useState(
    viewSelectedLeaveRuleData.maxDays
  );

  const handleMaximumLeavesValue = (event) => {
    console.log("handleMaximumLeavesValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMaximumLeavesValue(numericValue);
  };

  const [allowHalfDayValue, setAllowHalfDayValue] = useState(
    viewSelectedLeaveRuleData.allowHalfDay === "Y" ? true : false
  );

  const handleAllowHalfDayValue = (event) => {
    console.log("handleAllowHalfDay", event.target.checked);
    // setAllowHalfDayValue(event.target.checked);
  };

  const [minimumEmplyTenureValue, setMinimumEmplyTenureValue] = useState(
    viewSelectedLeaveRuleData.minEmploymentDays
  );

  const handleMinimumEmplyTenureValue = (event) => {
    console.log("handleMinimumEmplyTenureValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMinimumEmplyTenureValue(numericValue);
  };

  const [priorLeaveRequestValue, setPriorLeaveRequestValue] = useState(
    viewSelectedLeaveRuleData.minNoticeDays
  );

  const handlePriorLeaveRequestValue = (event) => {
    console.log("handlePriorLeaveRequestValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setPriorLeaveRequestValue(numericValue);
  };

  const [leaveBalanceExpDaysValue, setLeaveBalanceExpDaysValue] = useState(
    viewSelectedLeaveRuleData.leaveBalanceExpireDays
  );

  const handleLeaveBalanceExpDaysValue = (event) => {
    console.log("handleLeaveBalanceExpDaysValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeaveBalanceExpDaysValue(numericValue);
  };

  const [leavesBeforeEventValue, setLeavesBeforeEventValue] = useState(
    viewSelectedLeaveRuleData.applicableBeforeEvent
  );

  const handleLeavesBeforeEventValue = (event) => {
    console.log("handleLeavesBeforeEventValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeavesBeforeEventValue(numericValue);
  };

  const [leavesAfterEventValue, setLeavesAfterEventValue] = useState(
    viewSelectedLeaveRuleData.applicableAfterEvent
  );

  const handleLeavesAfterEventValue = (event) => {
    console.log("handleLeavesAfterEventValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeavesAfterEventValue(numericValue);
  };

  const [maximumCarryForwardValue, setMaximumCarryForwardValue] = useState(
    viewSelectedLeaveRuleData.maximumCarryForward
  );

  const handleMaximumCarryForwardValue = (event) => {
    console.log("handleMaximumCarryForwardValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMaximumCarryForwardValue(numericValue);
  };

  const [maxEncashAllowedFFValue, setMaxEncashAllowedFFValue] = useState(
    viewSelectedLeaveRuleData.minEncashBalExEmp
  );

  const handleMaximumEncashmentAllowedFandF = (event) => {
    console.log("handleMaximumEncashmentAllowedFandF", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMaxEncashAllowedFFValue(numericValue);
  };

  const [maxDaysForEncashValue, setMaxDaysForEncashValue] = useState(
    viewSelectedLeaveRuleData.minEncashBalCurrentEmp
  );

  const handleMaxDaysForEncashValue = (event) => {
    console.log("handleMaxDaysForEncashValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMaxDaysForEncashValue(numericValue);
  };

  const [minLeaveBalAfteEncashValue, setMinLeaveBalAfteEncashValue] = useState(
    viewSelectedLeaveRuleData.minLeaveBalanceForEncash
  );

  const handleMinLeaveBalAfteEncashValue = (event) => {
    console.log("handleMinLeaveBalAfteEncashValue", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMinLeaveBalAfteEncashValue(numericValue);
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("view_leave_rules_details")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handleCloseViewSelectedLeaveRule()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            {loading ? (
              <div className="meetingVEContainer">
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              </div>
            ) : (
              <div className="rfContContainDetails">
                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText={t(
                          "are_you_sure_to_add_this_as_leave_name"
                        )}
                        className="formAutocompleteField"
                        variant="outlined"
                        id="selectleaveType"
                        // options={leaveTypeList.map((item) => item.leaveType)}
                        options={[]}
                        value={viewSelectedLeaveRuleData?.leaveTypeDisplay}
                        inputValue={leaveTypeTextValue}
                        onInputChange={handleLeaveTypeTextvalue}
                        onChange={handleLeaveTypeListvalue}
                        freeSolo
                        disabled
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("select_enter_leave_type")}
                            required
                            className="formAutoComInputField autocompFildPlaceholder"
                            disabled
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  {selectedLeaveType && selectedLeaveType.gender === "Y" && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="Employee_Gender">
                          {t("select_gender")}*
                        </InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="Employee_Gender"
                          id="EmployeeGender"
                          multiple
                          // value={selectGender}
                          value={viewSelectedLeaveRuleData.gender.split(",")}
                          onChange={handleChangeGender}
                          input={<OutlinedInput label={t("select_gender")} />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                          disabled
                        >
                          {/* {genderList.map((gender) => (
                          <MenuItem key={gender} value={gender}>
                            <Checkbox
                              checked={selectGender.indexOf(gender) > -1}
                            />
                            <ListItemText primary={gender} />
                          </MenuItem>
                        ))} */}
                        </Select>
                      </FormControl>
                    </div>
                  )}

                  {selectedLeaveType && selectedLeaveType.empStatus === "Y" && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="Employee_Status">
                          {t("employee_status")}*
                        </InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="Employee_Status"
                          id="EmployeeStatus"
                          multiple
                          // value={selectEmployeeStatus}
                          value={viewSelectedLeaveRuleData.empStatus.split(",")}
                          onChange={handleChangeEmployeeStatus}
                          input={<OutlinedInput label={t("employee_status")} />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                          disabled
                        >
                          {/* {employeeStatusList.map((empStatus) => (
                            <MenuItem key={empStatus} value={empStatus}>
                              <Checkbox
                                checked={
                                  selectEmployeeStatus.indexOf(empStatus) > -1
                                }
                              />
                              <ListItemText primary={empStatus} />
                            </MenuItem>
                          ))} */}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  {selectedLeaveType &&
                    selectedLeaveType.salaryType === "Y" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="Employment_Type">
                            {t("salary_type")}*
                          </InputLabel>
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId="Employment_Type"
                            id="EmploymentType"
                            multiple
                            // value={selectEmploymentType}
                            value={viewSelectedLeaveRuleData.salaryType.split(
                              ","
                            )}
                            onChange={handleChangeEmploymentType}
                            input={<OutlinedInput label={t("salary_type")} />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            disabled
                          >
                            {/* {emplyTypes.map((typeName) => (
                              <MenuItem key={typeName} value={typeName}>
                                <Checkbox
                                  checked={
                                    selectEmploymentType.indexOf(typeName) > -1
                                  }
                                />
                                <ListItemText primary={typeName} />
                              </MenuItem>
                            ))} */}
                          </Select>
                        </FormControl>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.fileRequired === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("required_documents")}
                              placeholder={t("example_file_names")}
                              variant="outlined"
                              // value={viewSelectedLeaveRuleData?.fileRequired || fileRequiredValue}
                              value={fileRequiredValue}
                              className="formTextFieldArea"
                              onChange={handleFileRequiredValue}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t("this_is_need_for_supportive_documents")}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.noOfLeaves === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("number_of_leaves")}
                              variant="outlined"
                              // value={viewSelectedLeaveRuleData?.noOfLeaves || numberOfLeavesValue}
                              value={numberOfLeavesValue}
                              className="formTextFieldArea"
                              //   className={`formTextFieldArea ${numberOfLeavesValue ? 'custom-disabled-input' : ''}`}
                              onChange={handleNumberOfLeavesValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                              //   disabled={numberOfLeavesValue ? true : false}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "Leave_calculation_can_be_done_either_based_on_total_number_of_leaves"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.leaveAccruedMonthly === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leave_accrued_monthly")}
                              variant="outlined"
                              // value={viewSelectedLeaveRuleData?.leaveAccruedMonthly ||leaveAccruedMonthlyValue}
                              value={leaveAccruedMonthlyValue}
                              className="formTextFieldArea"
                              // className={`formTextFieldArea ${leaveAccruedMonthlyValue ? 'custom-disabled-input' : ''}`}
                              onChange={handleLeaveAccruedMonthlyValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                              // disabled={leaveAccruedMonthlyValue ? true : false}
                            />
                          </FormControl>
                        </div>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.leaveAccruedYearly === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leave_accrued_yearly")}
                              variant="outlined"
                              // value={viewSelectedLeaveRuleData.leaveAccruedYearly ||leaveAccruedYearlyValue}
                              value={leaveAccruedYearlyValue}
                              className="formTextFieldArea"
                              onChange={handleLeaveAccruedYearlyValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                      </div>
                    )}

                  {selectedLeaveType && selectedLeaveType.minDays === "Y" && (
                    <div className="formElementGrp">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // required
                            // hiddenLabel
                            label={t("minimum_leaves")}
                            variant="outlined"
                            value={minimumLeavesValue}
                            className="formTextFieldArea"
                            onChange={handleMinimumLeavesValue}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            disabled
                          />
                        </FormControl>
                      </div>
                      <Tooltip
                        title={t(
                          "minimum_number_of_leaves_an_employee_may_apply_for_a_particular_leave_type"
                        )}
                        arrow
                      >
                        <IconButton className="infoBtn">
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                  {selectedLeaveType && selectedLeaveType.maxDays === "Y" && (
                    <div className="formElementGrp">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // required
                            // hiddenLabel
                            label={t("maximum_leaves")}
                            variant="outlined"
                            value={maximumLeavesValue}
                            className="formTextFieldArea"
                            onChange={handleMaximumLeavesValue}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            disabled
                          />
                        </FormControl>
                      </div>
                      <Tooltip
                        title={t(
                          "Maximum_number_of_leaves_an_employee_may_apply_at_a_stretch"
                        )}
                        arrow
                      >
                        <IconButton className="infoBtn">
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                  {selectedLeaveType &&
                    selectedLeaveType.allowHalfDay === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FormGroup
                              aria-label="position"
                              row
                              className="feformGroup"
                            >
                              <FormControlLabel
                                className="formCheckBox"
                                // value="Cost Center"
                                control={
                                  <Checkbox
                                    checked={allowHalfDayValue}
                                    onChange={handleAllowHalfDayValue}
                                  />
                                }
                                label={t("allow_half_day")}
                                labelPlacement="end"
                                disabled
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "if_half_day_is_applicable_for_this_leave_type"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.combineOtherLeaveTypeIds === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl formcolor">
                            <InputLabel id="combwthOthLeav">
                              {t("combination_with_other_leaves")}
                            </InputLabel>
                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="combwthOthLeav"
                              id="comb_wth_OthLeav"
                              multiple
                              value={selectCombWthOthLeav}
                              onChange={handleChangeCombWthOthLeav}
                              input={
                                <OutlinedInput
                                  label={t("combination_with_other_leaves")}
                                />
                              }
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                              disabled
                            >
                              {/* {LeaveRuleData.length &&
                                LeaveRuleData.map((item) => (
                                  <MenuItem
                                    key={item.id}
                                    value={item.leaveTypeDisplay}
                                  >

                                    <Checkbox
                                      checked={
                                        selectCombWthOthLeav.indexOf(
                                          item.leaveTypeDisplay
                                        ) > -1 ||
                                        (viewSelectedLeaveRuleData.combineOtherLeaveTypeIds &&
                                          viewSelectedLeaveRuleData.combineOtherLeaveTypeIds.includes(
                                            item.id.toString()
                                          )) ||
                                        (selectedLeaveType &&
                                          selectedLeaveType.combineOtherLeaveTypeIds &&
                                          selectedLeaveType.combineOtherLeaveTypeIds.includes(
                                            item.id.toString()
                                          ))
                                      }
                                    />
                                    <ListItemText
                                      primary={item.leaveTypeDisplay}
                                    />
                                  </MenuItem>
                                ))} */}
                            </Select>
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "other_leave_type_that_may_be_combined_with_this_leave_type_as_per_the_organizational_leave_policy_for_eg_medical_sick_leave_may_be_combined_with_leave_x_leave_Y"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.minEmploymentDays === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("minimum_employment_tenure_needed")}
                              variant="outlined"
                              value={minimumEmplyTenureValue}
                              className="formTextFieldArea"
                              onChange={handleMinimumEmplyTenureValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "minimum_number_of_days_an_employee_need_to_be_working_in_the_organization_in_order_to_apply_this_leave_type"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.minNoticeDays === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("prior_leave_request")}
                              variant="outlined"
                              value={priorLeaveRequestValue}
                              className="formTextFieldArea"
                              onChange={handlePriorLeaveRequestValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "prior_leave_request_is_to_be_made_by_the_employee_for_applying_for_this_leave_type"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.leaveBalanceExpireDays === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leave_balance_expire_days")}
                              variant="outlined"
                              value={leaveBalanceExpDaysValue}
                              className="formTextFieldArea"
                              onChange={handleLeaveBalanceExpDaysValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "if_any_leave_type_has_a_specific_expiry_time_line_organizations_may_add_the_same_in_the_Leave_expiry_field"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.applicableBeforeEvent === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leaves_before_event")}
                              variant="outlined"
                              value={leavesBeforeEventValue}
                              className="formTextFieldArea"
                              onChange={handleLeavesBeforeEventValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "leave_during_pregnancy_and_after_child_birth"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.applicableAfterEvent === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leaves_after_event")}
                              variant="outlined"
                              value={leavesAfterEventValue}
                              className="formTextFieldArea"
                              onChange={handleLeavesAfterEventValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "leave_during_pregnancy_and_after_child_birth_total"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.carryForward === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <div className="textWithOption">
                            <div className="fromText">{t("carry_forward")}</div>
                            <FormControl className="formControl">
                              <RadioGroup
                                className="formRadioGroup"
                                labelId="setTasktype"
                                // value={showCarryForwardInput}
                                value={showCarryForwardInput ? "Y" : "N"}
                                name="setCarryForward"
                                onChange={handleShowCarryForwardInput}
                                disabled
                              >
                                <FormControlLabel
                                  className="formRadioField"
                                  value="Y"
                                  control={<Radio />}
                                  label={t("yes")}
                                />
                                <FormControlLabel
                                  className="formRadioField"
                                  value="N"
                                  control={<Radio />}
                                  label={t("no")}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    )}

                  {showCarryForwardInput &&
                    selectedLeaveType &&
                    selectedLeaveType.maximumCarryForward === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("maximum_carry_forward")}
                              variant="outlined"
                              value={maximumCarryForwardValue}
                              className="formTextFieldArea"
                              onChange={handleMaximumCarryForwardValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "applicable_for_leaves_that_can_be_carried_forward_to_the_next_calender_year"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.encashable === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <div className="textWithOption">
                            <div className="fromText">{t("encashable")}</div>
                            <FormControl className="formControl">
                              <RadioGroup
                                className="formRadioGroup"
                                labelId="setTasktype"
                                value={showEncasableInput ? "Y" : "N"}
                                name="setEncashable"
                                onChange={handleshowEncasableInput}
                                disabled
                              >
                                <FormControlLabel
                                  className="formRadioField"
                                  value="Y"
                                  control={<Radio />}
                                  label={t("yes")}
                                />
                                <FormControlLabel
                                  className="formRadioField"
                                  value="N"
                                  control={<Radio />}
                                  label={t("no")}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    )}

                  {showEncasableInput &&
                    selectedLeaveType &&
                    selectedLeaveType.minEncashBalExEmp === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("maximum_encashment_allowed_during_f_f")}
                              variant="outlined"
                              value={maxEncashAllowedFFValue}
                              className="formTextFieldArea"
                              onChange={handleMaximumEncashmentAllowedFandF}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "maximum_number_of_days_allowed_for_encashment_during_f_f"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  {showEncasableInput &&
                    selectedLeaveType &&
                    selectedLeaveType.minEncashBalCurrentEmp === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t(
                                "maximum_number_of_days_allowed_for_encashment"
                              )}
                              variant="outlined"
                              value={maxDaysForEncashValue}
                              className="formTextFieldArea"
                              onChange={handleMaxDaysForEncashValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit_for_current_employee"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {showEncasableInput &&
                    selectedLeaveType &&
                    selectedLeaveType.minLeaveBalanceForEncash === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t(
                                "minimum_leave_balance_needed_after_encashment"
                              )}
                              variant="outlined"
                              value={minLeaveBalAfteEncashValue}
                              className="formTextFieldArea"
                              onChange={handleMinLeaveBalAfteEncashValue}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                              disabled
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit_for_current_employee"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
          {/* 
          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>Save</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handleCloseViewSelectedLeaveRule()}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
