import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import {
  addDesignation,
  updateDesignation,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";

const names = [
  "CEO",
  "MD",
  "BOD",
  "CXO",
  "COO",
  "VP",
  "AVP",
  "Manager",
  "Trainee",
  "Intern",
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function CreateDesignation({
  handelCloseCrtDesig,
  selectedDesignation,
  refreshDesignationData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const labelvalue = ["Band A", "Band B", "Band C"];

  const [DesignationNames, setDesignationNames] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleChangeMultiselect = (event) => {
    const {
      target: { value },
    } = event;
    setDesignationNames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  console.log("selectedDesignation", selectedDesignation);

  /////////////////////////////////////////////////////////////////////////////////////////////////

  const [hierarchyName, setHierarchyName] = useState("");
  const [updatedHierarchyName, setUpdatedHierarchyName] = useState("");

  const handleHierarchyName = (e) => {
    const value = e.target.value;
    console.log("handleHierarchyName", value);
    setHierarchyName(value);
    if (selectedDesignation) {
      selectedDesignation.hierachy = value;
      setUpdatedHierarchyName(value);
    }
  };

  const [designationName, setDesignationName] = useState("");

  const [updatedDesignationName, setUpdatedDesignationName] = useState("");

  const handleDesignationName = (e) => {
    const value = e.target.value;
    console.log("handleDesignationName", value);
    setDesignationName(value);

    if (selectedDesignation) {
      selectedDesignation.designationName = value;
      setUpdatedDesignationName(value);
    }
  };

  const [bandsLabal, setbandsLabal] = useState("");
  const [bandsLabalInput, setbandsLabalInput] = useState("");
  const [updatedBandsLabal, setUpdatedBandsLabal] = useState("");

  const selectBands = (e, newvalue) => {
    const value = newvalue;
    console.log("selectBands", value);
    setbandsLabal(value);
    if (selectedDesignation) {
      selectedDesignation.levelBand = value;
      setUpdatedBandsLabal(value);
    }
  };

  const selectBandsInput = (e, newvalue) => {
    const value = newvalue;
    console.log("selectBandsInput", value);
    setbandsLabalInput(value);
    if (selectedDesignation) {
      selectedDesignation.levelBand = value;
      setUpdatedBandsLabal(value);
    }
  };

  const finalSubmit = () => {
    console.log("finalSubmit");
    setLoading(true);

    if (hierarchyName.trim() === "") {
      toast.error(t("please_enter_hierarchy_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (designationName.trim() === "") {
      toast.error(t("please_enter_designation_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (bandsLabal.trim() === "") {
    //   toast.error("Please Select Band / Label  Name", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // };

    const reqDto = {
      designationId: 0,
      designationName: designationName,
      levelBand: bandsLabalInput !== "" ? bandsLabalInput : bandsLabal,
      hierachy: hierarchyName,
    };

    console.log("reqDto", reqDto);

    addDesignation(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log("addDesignation", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshDesignationData();
          setHierarchyName("");
          setDesignationName("");
          setbandsLabal("");
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      }
    );
  };

  const updatedFinalSubmit = () => {
    console.log("updatedFinalSubmit");
    setLoading(true);

    const newDesignName = updatedDesignationName
      ? updatedDesignationName
      : selectedDesignation.designationName;

    const newHierchyName = updatedHierarchyName
      ? updatedHierarchyName
      : selectedDesignation.hierachy;

    const newBandName = updatedBandsLabal
      ? updatedBandsLabal
      : selectedDesignation.levelBand;

    const reqDto = {
      designationId: selectedDesignation.designationId,
      designationName: newDesignName,
      levelBand: newBandName,
      hierachy: newHierchyName,
    };

    console.log(" Update reqDto", reqDto);

    // return ;

    updateDesignation(userDetails.userId, reqDto).then((response) => {
      console.log("updateDesignation", response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        refreshDesignationData();
        setUpdatedHierarchyName("");
        setUpdatedDesignationName("");
        setUpdatedBandsLabal("");
        handelCloseCrtDesig();
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  };

  const filterEmplyOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return [];
    }
    return options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">
            {selectedDesignation
              ? t("edit_designation_hierarchy")
              : t("add_designation_hierarchy")}
          </div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCrtDesig()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            {/* <div className="elementWithBtn">
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="PreDefineDepartment">
                  Pre Define Designation
                </InputLabel>
                <Select
                  className="formInputField"
                  labelId="PreDefineDepartment"
                  id="PreDefineDepartment-checkbox"
                  multiple
                  value={DesignationName}
                  onChange={handleChangeMultiselect}
                  input={<OutlinedInput label="Pre Define Department" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={DesignationName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button startIcon={<Add />} className="dfultPrimaryBtn ">
              Add
            </Button>
          </div>

          <div className="orDiv">
            <span>OR</span>
          </div> */}

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("hierarchy_name")}
                  required
                  variant="outlined"
                  className="formTextFieldArea"
                  value={selectedDesignation?.hierachy || hierarchyName}
                  onChange={handleHierarchyName}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("designation_name")}
                  required
                  variant="outlined"
                  className="formTextFieldArea"
                  // value={designationName}
                  value={
                    selectedDesignation?.designationName || designationName
                  }
                  onChange={handleDesignationName}
                />
              </FormControl>
            </div>
            {/* <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="selectBand">Band / Label</InputLabel>
              <Select
                className="formInputField"
                variant="outlined"
                labelId="selectBand"
                id="select-Band"
                value={selectedDesignation?.levelBand || bandsLabal}
                label="Band / Label"
                onChange={selectBands}
              >
                <MenuItem value={"Band A"}>Band A</MenuItem>
                <MenuItem value={"Band B"}>Band B</MenuItem>
                <MenuItem value={"Band C"}>Band C</MenuItem>
              </Select>
            </FormControl>
          </div> */}

            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  id="select-Band"
                  disablePortal
                  className="formAutocompleteField"
                  variant="outlined"
                  options={labelvalue}
                  getOptionLabel={(option) => option || ""}
                  freeSolo
                  value={selectedDesignation?.levelBand || bandsLabal}
                  inputValue={bandsLabalInput}
                  onInputChange={selectBandsInput}
                  filterOptions={filterEmplyOptions}
                  onChange={selectBands}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("band_label")}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn"onClick={()=>finalSubmit()}>Save</Button> */}
            <Button
              className="dfultPrimaryBtn"
              // onClick={() => finalSubmit()}
              // onClick={()=>{updatedFinalSubmit()}}
              onClick={() =>
                selectedDesignation ? updatedFinalSubmit() : finalSubmit()
              }
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("submit")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseCrtDesig()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
