import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Filler,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
// import { getDashboardDataOfItem } from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
// import faker from "faker";
import { useTranslation } from "react-i18next";
import { refreshContract } from "../../../../../services/AdminService";
import { ArrowRepeat } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export default function CardContract({
  handelOpenAddContract,
  // chartDataOfItem,
  handelOpenViewContract,
  menuModuleList,
  userMenuList,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Title,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const userDetails = GetLoginUserDetails();
  const [chartDataOfItem, setchartDataOfItem] = useState([]);

  useEffect(() => {
    fetchEmplyCardData();
  }, []);

  const fetchEmplyCardData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
    };
    refreshContract(reqDto).then((response) => {
      console.log("response", response.data);
      setchartDataOfItem(response.data);
    });
  };

  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();

  const typeWiseContractData = chartDataOfItem.typeWiseContract || [];

  const labels = typeWiseContractData.map((item) => item.label);
  const counts = typeWiseContractData.map((item) => item.count);

  const data = {
    labels: labels,
    datasets: [
      {
        data: counts,
        backgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
        hoverBackgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
        borderWidth: 0,
        type: "pie",
      },
    ],
  };

  //  const data = {
  //   labels: ["january", "February"],
  //       datasets: [
  //         {
  //           // label: ["january", "February"],
  //           // label:"Total Contract",
  //           data: [20 , 30],
  //           // data : [chartDataOfItem.totalContract],
  //           backgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
  //           hoverBackgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
  //           borderWidth: 0,
  //           type: "pie",
  //         },
  //       ],
  //     };

  const options = {
    cutout: "80%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const optionsLeave = {
    responsive: true,
    borderRadius: 4,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 12,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      title: {
        display: false,
        text: "FYQ 2nd",
      },
    },
  };

  // const labels = ["January", "February", "March"];

  // const dataLeave = {
  //   labels,
  //   datasets: [
  //     {
  //       label: "contract",
  //       data: [400, 500, 340],
  //       backgroundColor: "rgba(0, 194, 215, 0.8)",
  //       type: "bar",
  //       barThickness: 30,
  //     },
  //   ],
  // };

  const mnthWiseContractData = chartDataOfItem.mnthWiseContract || [];

  const label = mnthWiseContractData.map((item) => item.label);
  const count = mnthWiseContractData.map((item) => item.count);

  const dataLeave = {
    labels: label,
    datasets: [
      {
        label: t("contracts"),
        data: count,
        backgroundColor: "rgba(0, 194, 215, 0.8)",
        type: "bar",
        barThickness: 16,
      },
    ],
  };

  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("contract")}
              <IconButton
                className={`refreshIcon`}
                onClick={fetchEmplyCardData}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                Salary: <span className="fbold">42%</span>
              </span>

              <span title="Tenure">
                FYQ: <span className="fbold">2nd</span>
              </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">
                    {chartDataOfItem.totalContract}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Bar
                className="hw100"
                type="bar"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Line
                className="hw100"
                type="line"
                data={dataLeave}
                options={optionsLeave}
              /> */}
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Contract View")
                  .menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenViewContract()}
                >
                  {t("view")}
                </Button>
              )}

            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Contract Add")
                  .menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenAddContract()}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
