// otpServiceutils.js
import React, { useState } from "react";
import ReactDOM from "react-dom";
import GettingServiceOtp from "./GettingServiceOtp";
import SecureIndexedDB from "./IndexedDB";

export const checkOtp = async (apiName) => {
  const otpServices =
    JSON.parse(await SecureIndexedDB.getItem("otpServices")) || [];
  const otpService = otpServices.find((service) => service.apiName === apiName);

  console.log("otpService utils", otpService);

  if (otpService) {
    const sessionOtp = await SecureIndexedDB.getItem("serviceOtp");
    const sessionExpireTime = await SecureIndexedDB.getItem("expireTime");
    const currentTime = Date.now();
    console.log("currentTime", currentTime);

    if (!sessionOtp || !sessionExpireTime || currentTime > sessionExpireTime) {
      console.log("otp required");

      const { isValid, otp, requestId } = await askForOtp(
        otpService.otpServiceId
      );
      console.log("userOtp", otp);

      if (isValid) {
        const newExpireTime = currentTime + otpService.expireInSec * 1000;
        SecureIndexedDB.setItem("serviceOtp", otp);
        SecureIndexedDB.setItem("expireTime", newExpireTime);
        SecureIndexedDB.setItem("requestId", requestId);
      } else {
        console.error("Invalid OTP utils");
      }

      return { otp, requestId }; // Return OTP and requestId
    } else {
      return {
        otp: sessionOtp,
        requestId: await SecureIndexedDB.getItem("requestId"),
      };
    }
  } else {
    console.error("API name not found in OTP services utils");
    return { otp: null, requestId: null };
  }
};

// export const checkOtp = async (apiName) => {
//     const otpServices = JSON.parse( SecureIndexedDB.getItem('otpServices')) || [];
//     const otpService = otpServices.find(service => service.apiName === apiName);

//     console.log('otpService utils', otpService);

//     if (otpService) {
//         const sessionOtp = sessionStorage.getItem('serviceOtp');
//         const sessionExpireTime = sessionStorage.getItem('expireTime');
//         const sessionRequestId = sessionStorage.getItem('requestId');
//         const currentTime = Date.now();
//         console.log('currentTime', currentTime , sessionRequestId);

//         if (!sessionOtp || !sessionExpireTime || currentTime > sessionExpireTime) {
//             console.log('otp required');

//             // const userOtp = await askForOtp(otpService.otpServiceId);
//             // console.log('userOtp', userOtp);

//             const { isValid, otp, requestId } = await askForOtp(otpService.otpServiceId);
//             console.log('userOtp', otp);

//             if (isValid) {
//                 const newExpireTime = currentTime + (otpService.expireInSec * 1000);
//                 sessionStorage.setItem('serviceOtp', otp);
//                 sessionStorage.setItem('expireTime', newExpireTime);
//                 sessionStorage.setItem('requestId', requestId);
//             } else {
//                 console.error("Invalid OTP utils");
//             }

//             // const isValidOtp = await validateOtp(userOtp);

//             // console.log('isValidOtp' , isValidOtp);

//             // if (isValidOtp) {
//             //     const newExpireTime = currentTime + (otpService.expireInSec * 1000);
//             //     sessionStorage.setItem('serviceOtp', userOtp);
//             //     sessionStorage.setItem('expireTime', newExpireTime);
//             // } else {
//             //     console.error("Invalid OTP utils");
//             // }
//         }
//     } else {
//         console.error("API name not found in OTP services utils");
//     }
// };

const askForOtp = (otpServiceId) => {
  return new Promise((resolve) => {
    const div = document.createElement("div");
    document.body.appendChild(div);

    const handleSubmitOtp = (isValid, otpValue, requestId) => {
      resolve({ isValid, otp: otpValue, requestId });
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    };

    ReactDOM.render(
      <GettingServiceOtp
        onSubmitOtp={handleSubmitOtp}
        otpServiceId={otpServiceId}
      />,
      div
    );
  });
};

const validateOtp = async (otp) => {
  console.log("demo validateOtp");
  // Implement actual OTP validation logic here
  return true; // Placeholder, replace with actual validation result
};
