import SecureIndexedDB from "../utils/IndexedDB";

const authHeader = () => {
  const token = localStorage.getItem("token");

  if (token && token != null && token !== "null") {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
};

export default authHeader;
