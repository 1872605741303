import { Add, Bookmark, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Trash } from "react-bootstrap-icons";
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { useState } from "react";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";
import zhPatientService from "../../../../../../../services/ZhPatientService";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";

export default function Investigations({ updateData, data, extraData }) {
  const loginUserDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [items, setItems] = useState([
    { name: "Blood Suger" },
    { name: "Lipid Profile" },
  ]);
  const options = {
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [5, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };

  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [investigationSearchString, setInvestigtionSearchString] = useState("");

  const [investigationList, setInvestigationList] = useState([]);
  const [userInvestigationList, setUserInvestigationList] = useState([]);
  const [centerDiagonesises, setCenterDiagonesises] = useState([]);

  const initializeForm = () => {
    setSelectedInvestigation(null);
    setInvestigtionSearchString("");
  };

  const genderWiseFilteredTestList = useMemo(() => {
    const { queuePatientGender } = extraData;
    const patientGender =
      queuePatientGender === "Male"
        ? "M"
        : queuePatientGender === "Female"
        ? "F"
        : "C";
    const temp = investigationList?.filter(
      (item) => item.gender === patientGender || item.gender === "C"
    );
    return temp || [];
  }, [investigationList]);

  const arrangeAvailableFunction = (data) => {
    let centerAvailableTest = [];
    let centerUnavailableTest = [];
    genderWiseFilteredTestList?.forEach((item) => {
      const isAvailable = data?.includes(item.testId);
      if (isAvailable) {
        centerAvailableTest.push({ ...item, isAvailableInCenter: true });
      } else {
        centerUnavailableTest.push(item);
      }
    });
    console.log("center available =", centerAvailableTest);
    return [...centerAvailableTest, ...centerUnavailableTest];
  };

  const processedTestList = useMemo(() => {
    const tempArrangedValue = arrangeAvailableFunction(centerDiagonesises);
    return tempArrangedValue;
  }, [centerDiagonesises, investigationList]);

  const testsString = useMemo(() => {
    const str =
      userInvestigationList.length > 0
        ? userInvestigationList.map((item) => `${item.testName} `).join(", ")
        : "";
    return str;
  }, [userInvestigationList]);

  useEffect(() => {
    getMedicalTestList();
    if (investigationList) {
      zhPatientService
        .getUnitTestIds(loginUserDetails.signleUnitId)
        .then((response) => {
          if (response.data) {
            setCenterDiagonesises(response.data);
            // arrangeAvailableFunction(data);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (centerDiagonesises && investigationList) {
      arrangeAvailableFunction(centerDiagonesises);
    }
  }, [centerDiagonesises]);

  useEffect(() => {
    if (data.investigation) {
      setUserInvestigationList(data.investigation);
    }
  }, [data?.investigation]);

  const getMedicalTestList = async () => {
    const symptomString = await SecureIndexedDB.getItem("medicalTestList");
    if (symptomString) {
      setInvestigationList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        setInvestigationList(response.data);
        SecureIndexedDB.setItem(
          "medicalTestList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const suggestedInvestigation = useMemo(() => {
    const userIdInvestigationList = userInvestigationList.map(
      (item) => item.testId
    );

    let filteredInvestigation = processedTestList.filter((disease) => {
      const alreadyTaken = userIdInvestigationList.some(
        (item) => item === disease.testId
      );
      return !alreadyTaken;
    });

    const filteredList = getFilteredList(
      filteredInvestigation,
      investigationSearchString,
      "testName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [investigationSearchString, processedTestList, userInvestigationList]);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("possible_investigations")}:{"  "}
            {!accordionExpanded && <span>{testsString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {/* <div className="wordCloud">
            <ReactWordcloud
              options={options}
              words={words}
              callbacks={{
                onWordClick: handleWordClick,
              }}
            />
          </div> */}

          <div className="complaintList ">
            <ul>
              {userInvestigationList &&
                userInvestigationList.map((item, index) => (
                  <li key={index}>
                    <div className="complstDtlGrp">
                      <div className="complstDtl">
                        <div className="compntName">{item.testName}</div>
                        <div className="compntName BrandMed">
                          {item.location}
                        </div>
                      </div>
                      <div className="d-flex">
                        {item.isAvailableInCenter && extraData?.isDoctor && (
                          <Button
                            disabled={userInvestigationList[index].goFortest}
                            className={
                              userInvestigationList[index].goFortest
                                ? "dfultDarkSecondaryBtn"
                                : "dfultPrimaryBtn"
                            }
                            onClick={() => {
                              let temp = [...userInvestigationList];
                              temp[index].goFortest = true;
                              setUserInvestigationList(temp);
                              updateData({ investigation: temp });
                              toast.success("Item added for test");
                            }}
                          >
                            {t("go_for_test")}
                          </Button>
                        )}

                        {item?.goFortest && !extraData?.isDoctor && (
                          <Button
                            disabled={userInvestigationList[index].goFortest}
                            className={"dfultDarkSecondaryBtn"}
                          >
                            {t("asked_for_test")}
                          </Button>
                        )}

                        {extraData?.isDoctor && (
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...userInvestigationList];
                              temp.splice(index, 1);
                              updateData({ investigation: temp });
                              setUserInvestigationList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          <div className="bb"></div>

          {extraData?.isDoctor && (
            <div className="addNewformGrp mt10">
              <div className="formElement">
                <FormControl className="formControl">
                  <Autocomplete
                    // freeSolo
                    className="formAutocompleteField"
                    variant="outlined"
                    value={selectedInvestigation}
                    options={suggestedInvestigation}
                    inputValue={investigationSearchString}
                    onChange={(e, newValue) => {
                      setSelectedInvestigation(newValue);
                    }}
                    onInputChange={(e, value, reason) => {
                      setInvestigtionSearchString(value);
                    }}
                    getOptionLabel={(option) => option.testName}
                    renderOption={(props, item) => {
                      return (
                        <li
                          {...props}
                          key={item.id}
                          // className={
                          //   item.isAvailableInCenter ? "available" : ""
                          // }
                        >
                          {item.testName}
                          {/* {`(${item.gender})`} */}
                          {item.isAvailableInCenter && (
                            <Bookmark className="centAvlTest" />
                          )}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="formAutoComInputField autocompFildPlaceholder"
                        placeholder={t("search_investigations")}
                        label={t("investigations")}
                        required
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>

              <Button
                className="dfultPrimaryBtn "
                startIcon={<Add />}
                onClick={() => {
                  let tempAllergicFood = selectedInvestigation;
                  if (!tempAllergicFood) {
                    if (
                      !investigationSearchString ||
                      investigationSearchString === ""
                    ) {
                      toast.error(t("please_provide_investigation_name"));
                      return;
                    }
                    tempAllergicFood = {
                      testId: uuidv4(),
                      testName: investigationSearchString,
                      location: "",
                      view: "",
                      technique: "",
                      locationId: 0,
                      viewId: 0,
                      techinqueId: 0,
                    };
                  }
                  setUserInvestigationList((prev) => {
                    const tempData = [...prev, tempAllergicFood];
                    updateData({ investigation: tempData });
                    return tempData;
                  });
                  initializeForm();
                }}
              >
                {t("add")}
              </Button>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
