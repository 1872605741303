import React, { useState, useEffect, useRef } from "react";

const RdkCaptureImage = ({ addRdkCapturedImage }) => {
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [error, setError] = useState("");
  const [isCaptured, setIsCaptured] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameras(videoDevices);
        if (videoDevices.length) {
          setSelectedCamera(videoDevices[0].deviceId);
        } else {
          setError("No cameras detected");
        }
      })
      .catch(() => {
        setError("Error accessing cameras");
      });
  }, []);

  useEffect(() => {
    if (selectedCamera) {
      startCamera();
    }
  }, [selectedCamera]);

  const startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: selectedCamera } })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch(() => {
        setError("Error accessing selected camera");
      });
  };

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      context.drawImage(videoRef.current, 0, 0);
      const base64Image = canvasRef.current.toDataURL("image/png");
      sendImageToBackend(base64Image);
      setIsCaptured(true);
    }
  };

  const retakeImage = () => {
    setIsCaptured(false);
    startCamera();
  };

  const sendImageToBackend = (base64Image) => {
    console.log("Captured Image Base64:", base64Image);
    addRdkCapturedImage({
      id: "unique-id",
      base64Data: "data:image/png;base64,...", // your base64 image data
      timestamp: new Date().toISOString(),
    });
    // fetch("/api/upload", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ image: base64Image }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => console.log("Upload successful:", data))
    //   .catch((error) => console.error("Error uploading image:", error));
  };

  const closeCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsCaptured(false);
  };

  return (
    <div>
      <h2>Scan RDK Kit</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div>
        <label htmlFor="cameraSelect">Select Camera: </label>
        <select
          id="cameraSelect"
          className="rdkTestKitCameraSel"
          value={selectedCamera}
          onChange={(e) => setSelectedCamera(e.target.value)}
          disabled={!cameras.length}
        >
          {cameras.map((camera) => (
            <option key={camera.deviceId} value={camera.deviceId}>
              {camera.label || `Camera ${camera.deviceId}`}
            </option>
          ))}
        </select>
      </div>
      <div>
        <video ref={videoRef} style={{ width: "100%", height: "auto" }}></video>
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <button onClick={closeCamera} className="dfultDarkSecondaryBtn">
          Close
        </button>
        {!isCaptured ? (
          <button
            onClick={captureImage}
            // style={{ backgroundColor: "blue", color: "white" }}
            className="dfultPrimaryBtn"
          >
            Capture
          </button>
        ) : (
          <button
            onClick={retakeImage}
            // style={{ backgroundColor: "orange", color: "white" }}
            className="dfultPrimaryBtn"
          >
            Retake
          </button>
        )}
      </div>
    </div>
  );
};

export default RdkCaptureImage;
