import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { getHealthDto, MAX_SUGGESTED_RESULT } from "./consultationUtil";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { Add } from "@mui/icons-material";
import { Save } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { staticDataSocket } from "../../../../../../../socket";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const timings = [
  "Stat",
  "Before Breakfast",
  "After Breakfast",
  "Before Lunch",
  "After Lunch",
];

export const MedicationBody = ({ handleEditData, data }) => {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const [dosage, setDosage] = useState("");
  const [mForm, setMForm] = useState("");
  const [frequency, setFrequency] = useState("");
  const [regimen, setRegimen] = useState("");

  const [mTiming, setMTiming] = useState([]);
  const [doctorGenericList, setDoctorGenericList] = useState([]);
  const [genericList, setGenericList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const [genericSearchInput, setGenericSearchInput] = useState("");
  const [selectedGeneric, setSelectedGeneric] = useState(null);
  const [medicationNote, setMedicationNote] = useState("");

  const [medicationList, setMedicationList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);

  const [dosageList, setDosageList] = useState([]);
  const [suggestedDosage, setSuggestedDosage] = useState([]);

  const [routeList, setRoutelist] = useState([]);

  //Brand item

  const [suggestedBrand, setSuggestedBrand] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandInputString, setBrandInputString] = useState("");

  const setUniqueDoses = () => {
    const uniqueDosage = {};
    dosageList.forEach((item) => {
      if (!uniqueDosage[item.dosage]) {
        uniqueDosage[item.dosage] = item;
      }
    });
    const uniqueItems = Object.values(uniqueDosage);
    setSuggestedDosage(uniqueItems);
    return uniqueItems;
  };

  const getFilteredList = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      const temp = data.slice(0, MAX_SUGGESTED_RESULT);
      return { startsWithList: temp, containsList: [] };
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (
        element.generic.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.generic.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return { startsWithList, containsList };
  };

  const getFilteredBrand = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (
        element.brandName.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.brandName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const suggestedMedicineList = useMemo(() => {
    const userAllergicIdList = medicationList.map(
      (item) => item.genericName.id
    );

    let filteredGeneric = genericList.filter((procedure) => {
      const alreadyTaken = userAllergicIdList.some(
        (item) => item === procedure.id
      );
      return !alreadyTaken;
    });

    let filteredDoctor = doctorGenericList.filter((procedure) => {
      const alreadyTaken = userAllergicIdList.some(
        (item) => item === procedure.id
      );
      return !alreadyTaken;
    });

    const genericListFilteredData = getFilteredList(
      filteredGeneric,
      genericSearchInput,
      "generic"
    );
    const doctorFilteredData = getFilteredList(
      filteredDoctor,
      genericSearchInput
    );
    const tempValue = [
      ...doctorFilteredData.startsWithList,
      ...genericListFilteredData.startsWithList,
      ...doctorFilteredData.containsList,
      ...genericListFilteredData.containsList,
    ];
    if (tempValue.length === 0) {
      setSuggestedBrand([]);
      setBrandInputString("");
      setSelectedBrand(null);
      setUniqueDoses();
    }
    return tempValue.slice(0, 10);
  }, [genericSearchInput, doctorGenericList, genericList]);

  // const suggestedBrand = useMemo(() => {
  //   const tempBrandList = getFilteredBrand(brandList, brandInputString);
  //   return tempBrandList.slice(0, MAX_SUGGESTED_RESULT);
  // }, [brandList, brandInputString]);

  const updateSuggestedBrand = (data, str) => {
    const tempBrandList = getFilteredBrand(data, str);
    setSuggestedBrand(tempBrandList.slice(0, MAX_SUGGESTED_RESULT));
  };

  useEffect(() => {
    const userAllergicIdList = medicationList.map(
      (item) => item.genericName.id
    );

    let filteredBrand = brandList.filter((brnd) => {
      const alreadyTaken = userAllergicIdList.some(
        (item) => item === brnd.genericId
      );
      return !alreadyTaken;
    });
    updateSuggestedBrand(filteredBrand, brandInputString);
  }, [brandList, brandInputString, medicationList]);

  useEffect(() => {
    const process = async () => {
      if (data) {
        setMedicationList(data);
      }
      let doctorGenList = JSON.parse(
        await SecureIndexedDB.getItem("doctor_generic")
      );
      setDoctorGenericList(doctorGenList);
      let genList = JSON.parse(await SecureIndexedDB.getItem("generic"));
      setGenericList(genList);
    };
    process();
    getBrands();
    getFrequencies();
    getDurations();
    getFormDosages();
    getRoutes();
  }, []);

  useEffect(() => {
    if (
      brandList &&
      genericList &&
      frequencyList &&
      durationList &&
      dosageList &&
      routeList
    ) {
      if (data) {
        setMedicationList(data);

        const editData = data[0];

        setSelectedGeneric(editData.genericName);
        setSelectedBrand(editData.brandName);

        const generic = editData.genericName;
        const brandObj = editData.brandName;
        if (!brandObj) {
          if (generic.locallyAdded === true) {
            setGenericSearchInput(generic.generic);
          }
        } else {
          const selectedGenericMed = genericList.find(
            (item) => item.id === generic.id
          );
          const selectedBrandName = brandList.find(
            (item) => item.id === brandObj.id
          );
          if (selectedGenericMed) {
            setSelectedGeneric(selectedGenericMed);
          }
          if (brandObj) {
            setSelectedBrand(selectedBrandName);
          }
        }
      }
    }
  }, [
    brandList,
    genericList,
    frequencyList,
    durationList,
    dosageList,
    routeList,
    data,
  ]);

  const getFrequencies = async () => {
    const diseasetring = await SecureIndexedDB.getItem("frequencyList");
    if (diseasetring) {
      setFrequencyList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getFrequencies(reqDto).then((response) => {
      if (response.data) {
        setFrequencyList(response.data);
        SecureIndexedDB.setItem("frequencyList", JSON.stringify(response.data));
      }
    });
  };

  const getDurations = async () => {
    const diseasetring = await SecureIndexedDB.getItem("durationList");
    if (diseasetring) {
      setDurationList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getDurations(reqDto).then((response) => {
      if (response.data) {
        setDurationList(response.data);
        SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      }
    });
  };

  const getFormDosages = async () => {
    const diseasetring = await SecureIndexedDB.getItem("dosageList");
    if (diseasetring) {
      setDosageList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getFormDosages(reqDto).then((response) => {
      if (response.data) {
        setDosageList(response.data);
        SecureIndexedDB.setItem("dosageList", JSON.stringify(response.data));
      }
    });
  };

  const getBrands = () => {
    staticDataSocket.emit("requestData", {
      orgId: loginUserDetail.orgId,
      countryCode: loginUserDetail.orgCountryCode,
    });
    staticDataSocket.on("responseData", async (obj) => {
      console.log("staticDataSocket ", obj);
      const brandList = obj.brands;
      if (brandList) {
        setBrandList(brandList);
        return;
      }

      const reqDto = await getHealthDto();
      DashboardServices.getBrands(reqDto).then((response) => {
        if (response.data) {
          setBrandList(response.data);
          // SecureIndexedDB.setItem("brandList", JSON.stringify(response.data));
        }
      });
    });

    // const brandString =  await SecureIndexedDB.getItem("brandList");
    // if (brandString) {
    //   setBrandList(JSON.parse(brandString));
    //   return;
    // }

    // const reqDto = await getHealthDto();
    // DashboardServices.getBrands(reqDto).then((response) => {
    //   if (response.data) {
    //     setBrandList(response.data);
    //     SecureIndexedDB.setItem("brandList", JSON.stringify(response.data));
    //   }
    // });
  };

  const getRoutes = async () => {
    const brandString = await SecureIndexedDB.getItem("routeList");
    if (brandString) {
      setRoutelist(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getRoutes(reqDto).then((response) => {
      if (response.data) {
        setRoutelist(response.data);
        SecureIndexedDB.setItem("routeList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangemTiming = (event) => {
    const {
      target: { value },
    } = event;
    setMTiming(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeDosage = (event) => {
    setDosage(event.target.value);
  };

  const handleChangemForm = (event) => {
    setMForm(event.target.value);
  };

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
  };

  const handleChangeRegimen = (event) => {
    setRegimen(event.target.value);
  };

  const setDosageAccordingToFormId = (formId) => {
    const dosageId = dosageList.filter((item) => item.formId === formId);
    setSuggestedDosage(dosageId);
    // return dosageId;
  };

  const handleSaveMedication = () => {
    if (
      !selectedGeneric &&
      (!genericSearchInput || genericSearchInput === "")
    ) {
      toast.error(t("please_select_medicine_name"));
      return;
    }
    if (!dosage || dosage === "") {
      toast.error(t("please_select_dosage"));
      return;
    }

    if (!frequency || frequency === "") {
      toast.error(t("please_select_frequency"));
      return;
    }

    if (!regimen || regimen === "") {
      toast.error(t("please_select_duration"));
      return;
    }

    const tempFreq = frequencyList.find((item) => item.id === frequency);
    const tempDuration = durationList.find((item) => item.id === regimen);
    let genericName = selectedGeneric;
    if (!genericName) {
      genericName = {
        id: 0,
        generic: genericSearchInput,
        formId: -1,
        locallyAdded: true,
      };
    }

    const dto = {
      frequency: tempFreq,
      dosage,
      root: mForm,
      note: medicationNote,
      duration: tempDuration,
      timing: mTiming,
      genericName,
      brandName: selectedBrand,
    };

    handleEditData(dto);
  };

  return (
    <>
      <div className="addNewformGrp">
        <div className="formElement">
          {/* <FormControl className="formControl">
                <TextField
                  // hiddenLabel
                  label="Search by Generic"
                  autoComplete="off"
                  variant="outlined"
                  className="formTextFieldArea"
                  value={genericSearchInput}
                  onChange={(e) => {
                    setGenericSearchInput(e.target.value);
                  }}
                />
              </FormControl> */}
          <FormControl className="formControl">
            <Autocomplete
              freeSolo
              className="modelformAutocompleteField"
              variant="outlined"
              value={selectedGeneric}
              options={suggestedMedicineList}
              inputValue={genericSearchInput}
              onChange={(e, newValue) => {
                setSelectedGeneric(newValue);
                if (newValue) {
                  const { id, formId } = newValue;
                  setDosageAccordingToFormId(formId);
                  const tempBrand = brandList.filter(
                    (item) => item.genericId === id
                  );
                  if (tempBrand && tempBrand.length > 0) {
                    setSuggestedBrand(tempBrand.slice(0, MAX_SUGGESTED_RESULT));
                  }
                } else {
                  setBrandInputString("");
                  setSelectedBrand(null);
                }
              }}
              onInputChange={(e, value, reason) => {
                setGenericSearchInput(value);
                if (value === "" || !value) {
                  // setBrandInputString("");
                  updateSuggestedBrand(brandList, "");
                }
                if (reason === "clear") {
                  setSuggestedDosage([]);
                }
              }}
              getOptionLabel={(option) => option.generic}
              renderOption={(props, item) => {
                return (
                  <li {...props} key={item.id}>
                    {item.generic}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="formAutoComInputField autocompFildPlaceholder"
                  placeholder={t("generic_name")}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            {/* <TextField
                  // hiddenLabel
                  label="Search by Brand"
                  autoComplete="off"
                  variant="outlined"
                  className="formTextFieldArea"
                /> */}

            <Autocomplete
              className="modelformAutocompleteField"
              variant="outlined"
              value={selectedBrand}
              options={suggestedBrand}
              inputValue={brandInputString}
              onChange={(e, newValue) => {
                setSelectedBrand(newValue);
                if (newValue) {
                  const { genericId, formId } = newValue;
                  setDosageAccordingToFormId(formId);
                  let tempDoctorGeneric = doctorGenericList.find(
                    (item) => item.id === genericId
                  );
                  if (tempDoctorGeneric) {
                    setSelectedGeneric(tempDoctorGeneric);
                  } else {
                    tempDoctorGeneric = genericList.find(
                      (item) => item.id === genericId
                    );
                    if (tempDoctorGeneric) {
                      setSelectedGeneric(tempDoctorGeneric);
                    } else {
                      toast.error(
                        t("this_brand_generic_name_is_not_present_in_the_table")
                      );
                    }
                  }
                }
              }}
              onInputChange={(e, value) => {
                setBrandInputString(value);
              }}
              getOptionLabel={(option) => option.brandName}
              renderOption={(props, item) => {
                return (
                  <li {...props} key={item.id}>
                    {item.brandName}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="formAutoComInputField autocompFildPlaceholder"
                  placeholder={t("search_by_brand")}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </div>
      </div>
      <div className="formElementGroup ">
        <div className="formElement ">
          <FormControl className="formControl">
            <InputLabel id="severity-select-label">{t("dosage")}*</InputLabel>
            <Select
              labelId="severity-select-label"
              id="severity-select"
              required
              value={dosage}
              label={t("dosage")}
              onChange={handleChangeDosage}
              className="modelSelectFild"
              variant="outlined"
            >
              {suggestedDosage &&
                suggestedDosage.map((item) => {
                  return <MenuItem value={item}>{item.dosage} </MenuItem>;
                })}
              {/* <MenuItem value="2">Two</MenuItem>
                  <MenuItem value="3"> Three </MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <div className="formElement ">
          <FormControl className="formControl">
            <InputLabel id="Form-select-label">{t("rout")}</InputLabel>
            <Select
              labelId="Form-select-label"
              id="Form-select"
              value={mForm}
              label={t("form")}
              onChange={handleChangemForm}
              className="modelSelectFild"
              variant="outlined"
            >
              {routeList &&
                routeList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item}>
                      {item.route}
                    </MenuItem>
                  );
                })}
              {/* <MenuItem value="Inject">Inject</MenuItem>
                  <MenuItem value="Apply"> Apply </MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <div className="formElement ">
          <FormControl className="formControl">
            <InputLabel id="frequency-select-label">
              {t("frequency")}*
            </InputLabel>
            <Select
              labelId="frequency-select-label"
              id="frequency-select"
              value={frequency}
              label={t("frequency")}
              onChange={handleChangeFrequency}
              className="modelSelectFild"
              variant="outlined"
            >
              {frequencyList &&
                frequencyList.map((item) => {
                  return <MenuItem value={item.id}>{item.frequency} </MenuItem>;
                })}
            </Select>
          </FormControl>
        </div>
        <div className="formElement ">
          <FormControl className="formControl">
            <InputLabel id="regimen-select-label">{t("duration")}*</InputLabel>
            <Select
              labelId="regimen-select-label"
              id="regimen-select"
              value={regimen}
              label={t("duration")}
              onChange={handleChangeRegimen}
              className="modelSelectFild"
              variant="outlined"
            >
              {durationList &&
                durationList.map((item) => {
                  return (
                    <MenuItem value={item.id}>{item.durationName} </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>

        <div className="formElement ">
          <FormControl className="formControl">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("timing")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={mTiming}
              onChange={handleChangemTiming}
              input={<OutlinedInput label={t("timing")} />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              className="modelSelectFild"
              variant="outlined"
            >
              {timings.map((time) => (
                <MenuItem key={time} value={time}>
                  <Checkbox checked={mTiming.indexOf(time) > -1} />
                  <ListItemText primary={time} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="addNewformGrp">
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              // hiddenLabel
              label={t("notes")}
              autoComplete="off"
              variant="outlined"
              value={medicationNote}
              onChange={(e) => {
                setMedicationNote(e.target.value);
              }}
              className="modelTextFild"
            />
          </FormControl>
        </div>

        <Button
          className="dfultPrimaryBtn "
          startIcon={<Save />}
          onClick={handleSaveMedication}
        >
          {t("save")}
        </Button>
      </div>
    </>
  );
};
