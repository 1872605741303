import React, { useEffect, useState } from "react";
import PatientQueueCard from "../cards/PatientQueueCard";
import { centerSocket, doctorSocket } from "../../../../../socket";
import { toast } from "react-toastify";

export default function PatientQueueMenuComponent({
  handelOpenRightPQueue,
  dataList,
  refreshData,
}) {
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    if (dataList) {
      doctorSocket.emit("requestInVideoPvId");
    }
  }, [dataList]);

  const handleResponseInVideoPvId = (data) => {
    console.log("data response in video  =", data);
    setOnlineUsers(data);
  };

  function handleRefreshDoctorQueue(data) {
    // toast.info("New Patient added in queue");
    refreshData();
    doctorSocket.emit("requestInVideoPvId");
  }

  useEffect(() => {
    if (doctorSocket) {
      doctorSocket.on("refreshDoctorQueue", handleRefreshDoctorQueue);
      doctorSocket.on("responseInVideoPvId", handleResponseInVideoPvId);
    }
    return () => {
      doctorSocket.off("refreshDoctorQueue", handleRefreshDoctorQueue);
      doctorSocket.off("responseInVideoPvId", handleResponseInVideoPvId);
    };
  }, [doctorSocket]);

  return (
    <>
      <div className="mnuCompoContainer">
        <div className="mnuCompoIncomingInstanse">
          {dataList &&
            dataList.length > 0 &&
            dataList.map((patientQueueItem) => {
              console.log("online users=", onlineUsers);
              const isOnline = onlineUsers.includes(patientQueueItem.visitId);
              return (
                <PatientQueueCard
                  handelOpenRightPQueue={(data) => {
                    handelOpenRightPQueue(data);
                  }}
                  data={patientQueueItem}
                  isOnline={isOnline}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}
