import React from "react";
import {
  Box,
  Button,
  Grid,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { toast } from "react-toastify";

import { XLg } from "react-bootstrap-icons";
import { grantModeratorRights } from "../../../../../../services/VideoConferenceService";
import {
  GetLoginUserDetails,
  getLoginUserDetailsLocal,
} from "../../../../../../utils/Utils";
import "../css/meetings.css";
import { withTranslation } from "react-i18next";

class MeetingParticipants extends React.Component {
  userDetails = getLoginUserDetailsLocal();
  constructor() {
    super();
    this.state = {
      anchorElCV: null,
      openCV: Boolean(null),
      idCV: false ? "anchorElCVPopover" : undefined,
      selectedParticipant: null,
      participantsList: [],
      participantsListSize: 0,
      timer: 0,
      myJitsiParticipantId: null,
      grantModParDto: null,
      tState: null,
      columns: [
        {
          id: "displayName",
          Header:  "person_name",
          filterable: true,
          width: 200,
          accessor: (row) =>
            `${
              this.state.myJitsiParticipantId === row.id
                ? row.displayName + ("me")
                : row.displayName
            }`,
          filterMethod: (filter, row) =>
            row._original.displayName
              .toLowerCase()
              .startsWith(filter.value.toLowerCase()),
          Filter: ({ filter, onChange }) => (
            <input
              type="text"
              placeholder={"type_to_search"}
              value={filter ? filter.value : ""}
              onChange={(event) => onChange(event.target.value)}
            />
          ),
        },
        {
          id: "role",
          Header: "role",
          filterable: true,
          width: 150,
          accessor: (row) =>
            `${row.role === "moderator" ? "moderator" : "participant"}`,
          filterMethod: (filter, row) =>
            row._original.role
              .toLowerCase()
              .startsWith(filter.value.toLowerCase()),
        },
      ],
    };
    this.intervalId = null;
    // this.props.jitsiObject.addListener(
    //   "incomingMessage",
    //   this.jitsiIncomingMessage
    // );
  }

  handleCloseCV = async () => {
    this.setState({ grantModParDto: null });
    this.setState({
      anchorElCV: null,
      openCV: Boolean(null),
      idCV: "anchorElCVPopover",
    });
  };

  async fetchParticipantData() {
    let columnsTemp = [
      {
        id: "displayName",
        Header: this.tState("person_name"),
        filterable: true,
        width: 200,
        accessor: (row) =>
          `${
            this.state.myJitsiParticipantId === row.id
              ? row.displayName +"("+ (this.tState("me"))+")"
              : row.displayName
          }`,
        filterMethod: (filter, row) =>
          row._original.displayName
            .toLowerCase()
            .startsWith(filter.value.toLowerCase()),
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={this.tState("type_to_search")}
            value={filter ? filter.value : ""}
            onChange={(event) => onChange(event.target.value)}
          />
        ),
      },
      {
        id: "role",
        Header: this.tState("role"),
        filterable: true,
        width: 150,
        accessor: (row) =>
          `${row.role === "moderator" ? this.tState("moderator") : this.tState("participant")}`,
        filterMethod: (filter, row) =>
          row._original.role
            .toLowerCase()
            .startsWith(filter.value.toLowerCase()),
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={this.tState("type_to_search")}
            value={filter ? filter.value : ""}
            onChange={(event) => onChange(event.target.value)}
          />
        ),
      },
    ];
    // await this.setState({ grantModParDto: null });
    // await this.setState({ participantsList: null });

    // console.log("fetchParticipantData", this.props.jitsiObject);
    // console.log("fetchParticipantData", this.props.jitsiObject._myUserID);
    this.setState({ myJitsiParticipantId: this.props.jitsiObject._myUserID });
    this.props.jitsiObject.getRoomsInfo().then((roomInfo) => {
      // console.log("MeetingParticipants", roomInfo);
      // console.log("MeetingParticipants", roomInfo.rooms[0].participants);
      this.setState({ participantsList: roomInfo.rooms[0].participants });
      this.setState({
        participantsListSize: roomInfo.rooms[0].participants.length,
      });
      if (
        roomInfo.rooms[0].participants.some(
          (row) =>
            row.id === this.props.jitsiObject._myUserID &&
            row.role === "moderator"
        )
      ) {
        // let columnsTemp = this.state.columns;
        columnsTemp.push({
          id: "grantRights",
          Header: this.tState("moderator_rights"),
          filterable: false,
          width: 150,
          Cell: (props, index) => (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {props.original.role !== "moderator" ? (
                  <Button
                    className="grtModBtn"
                    onClick={(event) => {
                      // console.log(props, index);
                      this.grantModerator(event, props.original);
                      // this.testSendMessage(props.original);
                    }}
                  >
                    {this.tState("grant")}
                  </Button>
                ) : (
                  <Button className="grtModBtn" disabled="true">
                    {this.tState("granted")}
                  </Button>
                )}
              </div>
            </>
          ),
        });
        this.setState({ columns: columnsTemp });
      }
    });
  }

  componentDidMount() {
    this.fetchParticipantData();

    this.intervalId = setInterval(async () => {
      await this.fetchParticipantData();
      this.setState((prevState) => ({
        timer: prevState.timer + 1,
      }));
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  grantModerator(event, participantDto) {
    console.log("grantModerator", participantDto);
    this.setState({
      anchorElCV: event.target,
      openCV: Boolean(event.target),
      idCV: "anchorElCVPopover",
    });
    this.setState({ grantModParDto: participantDto });
  }

  testSendMessage(participantDto) {
    // console.log(participantDto);
    this.props.jitsiObject.executeCommand(
      "sendChatMessage",
      `Moderator Rights granted to ${participantDto.displayName} by ${this.userDetails.userName}`, //the text message
      null, // the receiving participant ID or empty string/undefined for group chat.
      false // true if the privacy notification should be ignored. Defaulted to false.
    );
    this.props.jitsiObject.executeCommand(
      "sendChatMessage",
      `You are now a moderator`, //the text message
      participantDto.id, // the receiving participant ID or empty string/undefined for group chat.
      false // true if the privacy notification should be ignored. Defaulted to false.
    );
  }

  jitsiIncomingMessage(obj) {
    // console.log("jitsiIncomingMessage", obj);
  }

  render() {
    const { t } = this.props;
    this.tState = t;
    return (
      <>
        <div
          style={{ cursor: "pointer" }}
          className="closeDilogMeetPart"
          onClick={() => this.props.closeDialog()}
        >
          <XLg />
        </div>
        {this.state.participantsList && this.state.participantsListSize > 0 && (
          <ReactTable
            data={this.state.participantsList}
            columns={this.state.columns}
            // defaultSorted={[
            //   {
            //     id: "fileName",
            //     desc: false,
            //   },
            // ]}
            filterable={true}
            onFilteredChange={(filtered) => this.setState({ filtered })}
            defaultPageSize={10}
            className="-striped -highlight participantsRT"
            getPaginationProps={(state) => ({
              previousText: t("previous"),
              nextText: t("next"),
              pageText: t("page"),
              rowsText: t("rows"),
            })}
          />
        )}

        <Popover
          id={this.state.idCV}
          open={this.state.openCV}
          anchorEl={this.state.anchorElCV}
          onClose={this.handleCloseCV}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div style={{ padding: "20px" }}>
            <div className="modelTitle">{t("confirmation")}</div>
            <div
              className="modInputFild"
              style={{ padding: "20px 10px 10px 0px" }}
            >
              {/* {this.state.grantModParDto &&
                `Grant Moderator Rights to ${this.state.grantModParDto?.displayName} ?`} */}
                 {this.state.grantModParDto &&
                t("grant_rights_message", {
                  name: this.state.grantModParDto?.displayName,
                })}
            </div>

            <div
              className="modActionBtnGrp"
              style={{ justifyContent: "space-between !important" }}
            >
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={this.handleCloseCV}
              >
                {t("no")}
              </Button>
              <Button
                className="dfultPrimaryBtn"
                onClick={async (event) => {
                  event.target.disabled = true;
                  // event.target.style.backgroundColor = "gray";
                  console.log("grantModerator", this.state.grantModParDto);
                  const responseGM =
                    await this.props.jitsiObject.executeCommand(
                      "grantModerator",
                      this.state.grantModParDto.id
                    );
                  console.log(responseGM);
                  grantModeratorRights(
                    this.props.roomNumber,
                    this.state.grantModParDto.id,
                    this.userDetails.userId
                  ).then(async (response) => {
                    console.log("grantModeratorRights", response);
                    toast.success(
                      // `Moderator Rights granted to  ${this.state.grantModParDto.displayName} successfully`,
                      t("moderator_rights_granted", {
                        displayName: this.state.grantModParDto?.displayName,
                      }),
                      {
                        position: toast.POSITION.TOP_RIGHT,
                      }
                    );
                    // this.setState({ grantModParDto: null });
                    await this.handleCloseCV();
                    await this.fetchParticipantData();
                    event.target.disabled = false;
                    // event.target.style.backgroundColor = "unset";
                  });
                }}
              >
                {t("yes")}
              </Button>
            </div>
          </div>
        </Popover>
      </>
    );
  }
}

export default withTranslation()(MeetingParticipants);
