import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import zhPatientService from "../../../../../services/ZhPatientService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ViewCompound } from "./ViewCompound";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";
import DoctorCenterMapping from "./DoctorCenterMapping";
import UnitTestMapping from "./UnitTestMapping";
import { VitalUnit } from "./VitalUnit";
import HealthCareConfigInvestigation from "./HealthCareConfigInvestigation";
import { HealthCareParameter } from "./HealthCareParameter";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    maxHeight: 200,
    overflow: "overlay",
    padding: "6px 10px",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function DefineHealthcareConfig({
  handelOpenAddNewSTP,
  handleOpenEditSTP,
}) {
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t } = useTranslation();
  const loginUserDetails = GetLoginUserDetails();
  const [stpList, setStpList] = useState([]);
  const [regimeComponent, setRegimeComponent] = useState({});
  const [showCustomComponent, setShowCustomComponent] = useState(false);
  const [customCompoundData, setShowCustomCompoundData] = useState(null);
  // const [activeComponent, setActiveComponent] = useState(null);
  const [activeButton, setActiveButton] = useState(
    "standard_treatment_protocol"
  );

  function fetchStp() {
    zhPatientService.getStpList(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        const tempStpList = response.data?.map((item) => {
          const {
            test,
            procedure,
            ptm,
            lifestyle,
            regime,
            diseaseName,
            stpId,
          } = item;
          const testList = test?.split("~") || [];
          const procedureList = procedure?.split("~") || [];
          const ptmList = ptm?.split("~") || [];
          const lifeStyleList = lifestyle?.split("~") || [];
          const regimeComponent = regime?.split("~");
          const regimes = regimeComponent?.map((regime) => {
            const temp = regime.split("$$$");
            const regimeName = temp?.[0];
            const ageGroup = temp?.[1];

            fetchRegimeCompound(regimeName, ageGroup, stpId);
            return {
              regimeName,
              ageGroup,
            };
          });
          const ageGroup = regimeComponent?.[1] || "";
          const dto = {
            ...item,
            test: testList,
            procedure: procedureList,
            ptm: ptmList,
            lifestyle: lifeStyleList,
            regimes,
            ageGroup,
          };

          return dto;
        });
        setStpList(tempStpList);
      }
    });
  }

  const fetchRegimeCompound = (regimeName, ageGroup, stpId) => {
    const dto = {
      stpId,
      regime: regimeName,
      ageGroup,
    };
    function buildTree(data) {
      const map = {}; // A map to store nodes by indexId
      const tree = []; // Array to hold the root nodes

      // Initialize nodes and store in map
      data.forEach((item) => {
        map[item.indexId] = { ...item };
      });

      // Construct tree structure
      data.forEach((item) => {
        const node = map[item.indexId];
        if (item.previousIndexId === 0) {
          // No parent, so it is a root node
          tree.push(node);
        } else {
          // Find the parent node using previousIndexId
          const parent = map[item.previousIndexId];
          if (parent) {
            if (node.operatorName === "Then") {
              if (parent.thenList) {
                parent.thenList.push(node);
              } else {
                parent.thenList = [node];
              }
            } else if (node.operatorName === "Or") {
              if (parent.orList) {
                parent.orList.push(node);
              } else {
                parent.orList = [node];
              }
            }
          }
        }
      });

      return tree;
    }
    if (!regimeComponent?.[`${stpId}~${regimeName}~${ageGroup}`]) {
      zhPatientService.getCompoundsOfRegime(dto).then((response) => {
        if (response.data) {
          const tree = buildTree(response.data);
          setRegimeComponent((prev) => {
            const temp = { ...prev };
            temp[`${stpId}~${regimeName}~${ageGroup}`] = tree;
            console.log("tree value=", temp);
            return temp;
          });
        }
      });
    }
  };

  useEffect(() => {
    fetchStp();
  }, []);

  // const handleComponentChange = (componentName) => {
  //   setActiveComponent(componentName);
  // };

  useEffect(() => {
    setBookSteps([
      {
        target: ".healthCareConfigStepOne",
        content: "Select a configuration to manage healthcare settings.",
        disableBeacon: true,
      },
      {
        target: ".healthCareConfigStepTwo",
        content: "Click here to add a new Standard Treatment Protocol.",
        disableBeacon: true,
      },
      {
        target: ".healthCareConfigStepThree",
        content: "This table shows detailed information about the protocols.",
        disableBeacon: true,
      },
      {
        target: ".healthCareConfigStepFour",
        content: "Use these buttons to edit or delete a protocol.",
        disableBeacon: true,
      },
    ]);
  }, []);

  const deactivateStp = (stpId) => {
    zhPatientService
      ?.deactivatStp(loginUserDetails.userId, stpId)
      .then((response) => {
        if (response.data) {
          toast.success(t("Stp_deleted"));
          //TODO: manually handle data to avoid re render
          fetchStp();
        }
      });
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewhealthCareConfigAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp ">
          <div className="CalendarBtnViewGroup healthCareConfigStepOne">
            {/* <ButtonGroup>
              <Button>{t("vitals_unit")}</Button>
              <Button className="activeBtn">{t("standard_treatment_protocol")}</Button>
              <Button onClick={() => handleComponentChange("DoctorCenterMapping")}>{t("unit_doctor_mapping")}</Button>
              <Button  onClick={() => handleComponentChange("UnitTestMapping")}>{t("unit_investigation_mapping")}</Button>
            </ButtonGroup> */}
            <ButtonGroup>
              <Button
                className={activeButton === "vitals_unit" ? "activeBtn" : ""}
                onClick={() => {
                  setActiveButton("vitals_unit");
                  if (
                    run["viewhealthCareConfigAD"] === true ||
                    run["viewUnitDoctorConfigAD"] === true ||
                    run["viewUnitInvesticationConfigAD"] === true ||
                    run["viewInvesticationConfigAD"] === true ||
                    run["viewParameterConfigAD"] === true
                  ) {
                    setRun({
                      viewVitalConfigAD: true,
                    });
                  }
                }}
              >
                {t("vitals_unit")}
              </Button>
              <Button
                className={
                  activeButton === "standard_treatment_protocol"
                    ? "activeBtn"
                    : ""
                }
                onClick={() => {
                  setActiveButton("standard_treatment_protocol");
                  if (
                    run["viewVitalConfigAD"] === true ||
                    run["viewUnitDoctorConfigAD"] === true ||
                    run["viewUnitInvesticationConfigAD"] === true ||
                    run["viewInvesticationConfigAD"] === true ||
                    run["viewParameterConfigAD"] === true
                  ) {
                    setRun({
                      viewhealthCareConfigAD: true,
                    });
                  }
                }}
              >
                {t("standard_treatment_protocol")}
              </Button>
              <Button
                className={
                  activeButton === "unit_doctor_mapping" ? "activeBtn" : ""
                }
                onClick={() => {
                  setActiveButton("unit_doctor_mapping");
                  // if (run["viewhealthCareConfigAD"] === true) {
                  //   setRun({
                  //     viewUnitDoctorConfigAD: true,
                  //   });
                  // }
                  if (
                    run["viewhealthCareConfigAD"] === true ||
                    run["viewVitalConfigAD"] === true ||
                    run["viewUnitInvesticationConfigAD"] === true ||
                    run["viewInvesticationConfigAD"] === true ||
                    run["viewParameterConfigAD"] === true
                  ) {
                    setRun({
                      viewUnitDoctorConfigAD: true,
                    });
                  }
                }}
              >
                {t("unit_doctor_mapping")}
              </Button>
              {/* <Button
                className={
                  activeButton === "unit_investigation_mapping"
                    ? "activeBtn"
                    : ""
                }
                onClick={() => {
                  setActiveButton("unit_investigation_mapping");
                  // if (run["viewhealthCareConfigAD"] === true) {
                  //   setRun({
                  //     viewUnitInvesticationConfigAD: true,
                  //   });
                  // }
                  if (
                    run["viewhealthCareConfigAD"] === true ||
                    run["viewVitalConfigAD"] === true ||
                    run["viewUnitDoctorConfigAD"] === true ||
                    run["viewInvesticationConfigAD"] === true ||
                    run["viewParameterConfigAD"] === true
                  ){
                    setRun({
                      viewUnitInvesticationConfigAD: true,
                    });
                  }
                }}
              >
                {t("unit_investigation_mapping")}
              </Button> */}
              <Button
                className={activeButton === "investigation" ? "activeBtn" : ""}
                onClick={() => {
                  setActiveButton("investigation");
                  // if (run["viewhealthCareConfigAD"] === true) {
                  //   setRun({
                  //     viewInvesticationConfigAD: true,
                  //   });
                  // }
                  if (
                    run["viewhealthCareConfigAD"] === true ||
                    run["viewVitalConfigAD"] === true ||
                    run["viewUnitDoctorConfigAD"] === true ||
                    run["viewUnitInvesticationConfigAD"] === true ||
                    run["viewParameterConfigAD"] === true
                  ) {
                    setRun({
                      viewInvesticationConfigAD: true,
                    });
                  }
                }}
              >
                {t("investigation")}
              </Button>
              <Button
                className={activeButton === "parameter" ? "activeBtn" : ""}
                onClick={() => {
                  setActiveButton("parameter");
                  // if (run["viewhealthCareConfigAD"] === true) {
                  //   setRun({
                  //     viewParameterConfigAD: true,
                  //   });
                  // }
                  if (
                    run["viewhealthCareConfigAD"] === true ||
                    run["viewVitalConfigAD"] === true ||
                    run["viewUnitDoctorConfigAD"] === true ||
                    run["viewUnitInvesticationConfigAD"] === true ||
                    run["viewInvesticationConfigAD"] === true
                  ) {
                    setRun({
                      viewParameterConfigAD: true,
                    });
                  }
                }}
              >
                {t("parameter")}
              </Button>
            </ButtonGroup>
          </div>

          {activeButton === "standard_treatment_protocol" && (
            <div className="addUnitsBtn healthCareConfigStepTwo">
              <Button
                startIcon={<Add />}
                className="dfultPrimaryBtn"
                onClick={() => handelOpenAddNewSTP()}
              >
                {t("add")}
              </Button>
            </div>
          )}
        </div>

        <div className="cuContainArea">
          {activeButton === "unit_doctor_mapping" && <DoctorCenterMapping />}
          {activeButton === "unit_investigation_mapping" && <UnitTestMapping />}
          {activeButton === "vitals_unit" && <VitalUnit />}
          {activeButton === "investigation" && (
            <HealthCareConfigInvestigation />
          )}
          {activeButton === "parameter" && <HealthCareParameter />}
          {activeButton === "standard_treatment_protocol" && (
            <div className="TableContainer">
              <table className="taskListTable healthCareConfigStepThree">
                <thead className="taskListtableHeader ">
                  <tr>
                    <th>{t("disease")}</th>
                    <th>{t("investigation")}</th>
                    <th>{t("monitoring_parameters")}</th>
                    <th>{t("lifestyle")}</th>
                    <th>{t("procedure")}</th>
                    <th>{t("regime_name")}</th>
                    <th className="width100">{t("action")}</th>
                  </tr>
                </thead>
                <tbody className="scrolableTableBody">
                  {stpList?.map((item) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="tskLstTaskNM">
                              {item?.diseaseName}
                            </div>
                          </td>
                          <td>
                            <Tooltip title={item?.test?.join(",")} arrow>
                              <div className="tskLstTaskNM eclipTextlin2">
                                {item?.test?.join(",")}
                              </div>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title={item?.ptm?.join(",")} arrow>
                              <div className="tskLstTaskNM eclipTextlin2">
                                {item?.ptm?.join(",")}
                              </div>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title={item?.lifestyle?.join(",")} arrow>
                              <div className="tskLstTaskNM eclipTextlin2">
                                {item?.lifestyle?.join(",")}
                              </div>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title={item?.procedure?.join(",")} arrow>
                              <div className="tskLstTaskNM eclipTextlin2">
                                {item?.procedure?.join(",")}
                              </div>
                            </Tooltip>
                          </td>

                          <td>
                            <ul className="regimeLstVewPg">
                              {item?.regimes?.map((regime, index) => {
                                return (
                                  <>
                                    {console.log(
                                      "regime vaue  = ",
                                      regimeComponent[
                                        `${item.stpId}~${regime.regimeName}~${regime.ageGroup}`
                                      ]
                                    )}
                                    {regimeComponent[
                                      `${item.stpId}~${regime.regimeName}~${regime.ageGroup}`
                                    ] ? (
                                      <HtmlTooltip
                                        className="regimeTooltip healthCareConfigStepSix"
                                        title={
                                          <>
                                            <ViewCompound
                                              compoundList={
                                                regimeComponent[
                                                  `${item.stpId}~${regime.regimeName}~${regime.ageGroup}`
                                                ]
                                              }
                                            />
                                          </>
                                        }
                                      >
                                        <li
                                          onClick={() => {
                                            setShowCustomComponent(true);
                                            setShowCustomCompoundData(
                                              regimeComponent[
                                                `${item.stpId}~${regime.regimeName}~${regime.ageGroup}`
                                              ]
                                            );
                                            // fetchRegimeCompound(
                                            //   regime.regimeName,
                                            //   regime.ageGroup,
                                            //   item.stpId
                                            // );
                                          }}
                                        >
                                          <div className="tskLstTaskNM">
                                            {regime.regimeName}
                                          </div>
                                        </li>
                                      </HtmlTooltip>
                                    ) : (
                                      <li
                                        onClick={() => {
                                          fetchRegimeCompound(
                                            regime.regimeName,
                                            regime.ageGroup,
                                            item.stpId
                                          );
                                        }}
                                      >
                                        <div className="tskLstTaskNM">
                                          {regime.regimeName}
                                        </div>
                                      </li>
                                    )}
                                  </>
                                );
                              })}
                            </ul>
                          </td>

                          <td>
                            <div className="tblActionBtnGrp healthCareConfigStepFour">
                              <IconButton
                                className="removeRowBtn"
                                onClick={() => {
                                  handleOpenEditSTP(item);
                                }}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                className="removeRowBtn"
                                onClick={() => {
                                  deactivateStp(item.stpId);
                                }}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {showCustomComponent && (
        <CustomModal
          modalTitle={t("compound_items")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowCustomComponent(false);
            setShowCustomCompoundData(null);
          }}
          className={"width90"}
        >
          <>
            <ViewCompound compoundList={customCompoundData} />
          </>

          <div className="modActionBtnGrp actionsCreateOrg healthCareConfigStepEight">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowCustomComponent(false);
                setShowCustomCompoundData(null);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
}
