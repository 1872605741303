import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { XLg } from "react-bootstrap-icons";
import { ConfirmProvider, useConfirm } from "material-ui-confirm";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { PatientFaceLiveness } from "./PatientFaceLiveness";
import LinearWithValueLabel from "../../../../../utils/LinearProgressWithLabel";
import { useTranslation } from "react-i18next";

const PatientFacialBiometricsRegister = ({
  open,
  onClose,
  setRegisterFace,
  onFaceLivenessCheckComplete,
}) => {
  const loginUserDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [progMessage, setProgMessage] = useState("");
  const [livenessPassed, setLivenessPassed] = useState(false);

  const streamRef = useRef(null);
  const isCameraOpen = useRef(false);
  const deleteFaceRef = useRef(false);

  useEffect(() => {
    return () => {
      closeCamera();
    };
  }, [open]);

  const closeCamera = () => {
    if (isCameraOpen.current) {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      isCameraOpen.current = false;
    }
  };

  const submitImage = async (capturedImage) => {
    onFaceLivenessCheckComplete(capturedImage);
    // setPatientImgb64(capturedImage);
    // console.log("capturedImage", capturedImage);
    // onClose();
    // setProgress(20);
    // setLivenessPassed(true);
    // let facesList = [];
    // facesList.push(capturedImage);
    // let reqDto = {
    //   userFaces: facesList,
    // };
    // setProgMessage("Face liveness test passed....");
    // const responseCheckImage = await DashboardServices.checkImage(
    //   loginUserDetails.userId,
    //   loginUserDetails.orgId,
    //   reqDto
    // );
    // console.log("responseCheckImage", responseCheckImage.data);
    // setProgMessage("Checking facial biometrics uniqueness...");
    // if (responseCheckImage.data.returnValue === "1") {
    //   setProgress(33);
    //   const responseCheckColForImg =
    //     await DashboardServices.checkCollectionForImage(
    //       loginUserDetails.userId,
    //       loginUserDetails.orgId,
    //       reqDto
    //     );
    //   console.log("responseCheckColForImg", responseCheckColForImg.data);
    //   if (responseCheckColForImg.data.returnValue === "1") {
    //     toast.error(
    //       "These biometric are associated with another account please continue with that account."
    //     );
    //     onClose();
    //   } else if (responseCheckColForImg.data.returnValue === "-1") {
    //     toast.error("Something went wrong");
    //   } else {
    //     setProgress(66);
    //     setProgMessage(
    //       "Facial biometric uniqueness confirmed, registering face now..."
    //     );
    //     const responseAddFaceToCol =
    //       await DashboardServices.addFaceToCollection(
    //         loginUserDetails.userId,
    //         loginUserDetails.orgId,
    //         reqDto
    //       );
    //     if (responseAddFaceToCol.data.returnValue === "1") {
    //       setProgress(100);
    //       setProgMessage("Facial Biometrics added to account successfully");
    //       setTimeout(() => {
    //         toast.success("Facial Biometrics added to account successfully");
    //         setFaceId(responseAddFaceToCol.data.message);
    //         onClose();
    //       }, 1000);
    //       return;
    //     } else {
    //       toast.error("Something went wrong");
    //       onClose();
    //     }
    //     console.log("responseCheckImage", responseAddFaceToCol.data);
    //   }
    // } else {
    //   toast.error(responseCheckImage.data.message);
    //   onClose();
    // }
    // setProgress(0);
  };

  const confirm = useConfirm();

  useEffect(() => {
    const interval = setInterval(() => {
      const contentElement = document.querySelector(
        ".dialogContentFaceLiveness"
      );
      if (contentElement) {
        contentElement.classList.add("nsRegisterstepsTwo");
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        console.log("dialogContentFaceLiveness closed");
      }}
      maxWidth="sm"
    >
      <DialogContent className="dialogContentFaceLiveness">
        {/* <DialogContent className={`dialogContentFaceLiveness ${open && !livenessPassed ? "nsRegisterstepsTwo" : ""}`}>  */}
        {!livenessPassed && (
          <div
            role="button"
            style={{ cursor: "pointer" }}
            className="closeDilog"
            onClick={() => {
              confirm({
                description: t("cancel_facial_biometrics_registration_process"),
                title: t("are_you_sure"),
                confirmationText: t("ok"),
                cancellationText: t("cancel"),
              })
                .then(() => {
                  onClose();
                })
                .catch(() => {
                  console.log("Face liveness test cancelled");
                });
            }}
          >
            <XLg />
          </div>
        )}
        {!livenessPassed && (
          <>
            <PatientFaceLiveness
              onDone={(data) => {
                console.log(data);
                if (data.returnValue === "1") {
                  submitImage(data.image);
                } else {
                  onClose();
                }
              }}
              onClose={onClose}
              onRetry={() => {
                onClose();
                // closeCamera();
                setTimeout(() => {
                  setRegisterFace(true);
                }, 1000);
              }}
            />
          </>
        )}
        {livenessPassed && progress > 0 && (
          <>
            <p
              style={{
                fontSize: "14px",
                margin: "5px",
              }}
            >
              {progMessage}
            </p>
            <LinearWithValueLabel prog={progress} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PatientFacialBiometricsRegister;
