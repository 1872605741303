import { openDB } from "idb";
import CryptoJS from "crypto-js";

const SECRET_KEY =
  "ZHadetab127#$ndhwNgakqj1hdt38363hsid98f7f3hjfdf887h32jdjfse8re3r73HHAYWG";

export const encryptData = (data) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();

export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// const initializeDB = async () => {
//   return await openDB("encryptedDB", 1, {
//     upgrade(db) {
//       if (!db.objectStoreNames.contains("dataStore")) {
//         db.createObjectStore("dataStore", { keyPath: "key" });
//       }
//     },
//   });
// };

export const migrateLocalStorageToIndexedDB = async () => {
  const db = await SecureIndexedDB.initializeDB();

  const isMigrated = await SecureIndexedDB.getItem("migrationCompleted");
  if (isMigrated) return true;
  let storedToken = null;
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key === "migrationCompleted") continue;
    if (key === "token") {
      storedToken = localStorage.getItem("token");
    }

    const value = await SecureIndexedDB.getItem(key);
    const encryptedValue = encryptData(value);

    await db.put("dataStore", { key, value: encryptedValue });
  }
  SecureIndexedDB.setItem("migrationCompleted", "true");
  localStorage.clear();
  if (storedToken && storedToken !== "null") {
    localStorage.setItem("token", storedToken);
  }
  return true;
};

class SecureIndexedDB {
  // static dbInstance = null;

  static async initializeDB() {
    // if (this.dbInstance) {
    //   return this.dbInstance;
    // }
    return await openDB("secureDB", 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("dataStore")) {
          db.createObjectStore("dataStore", { keyPath: "key" });
        }
      },
    });
  }

  // Store data in IndexedDB
  static async setItem(key, value) {
    try {
      if (key === "token") {
        if (value && value !== "null") {
          localStorage.setItem("token", value);
        }
        // localStorage.setItem("token", value === "null" ? null : value);
        return;
      }
      const db = await this.initializeDB();
      const encryptedValue = encryptData(value);
      await db.put("dataStore", { key, value: encryptedValue });
    } catch (error) {
      console.error("Error storing data in IndexedDB:", error);
    }
  }

  // Retrieve data from IndexedDB
  static async getItem(key) {
    try {
      if (key === "token") {
        return localStorage.getItem("token");
      }
      const db = await this.initializeDB();
      const record = await db.get("dataStore", key);
      const decryptedData = record ? decryptData(record.value) : null;
      // console.log("getItem", key, typeof decryptedData, decryptedData === "[]");
      if (decryptedData && typeof decryptedData !== "string") {
        if (decryptedData.length === 0) {
          return null;
        } else {
          return decryptedData;
        }
      } else {
        if (decryptedData === "[]") {
          return null;
        } else {
          return decryptedData;
        }
      }

      // return record ? decryptData(record.value) : null;
    } catch (error) {
      console.error("Error retrieving data from IndexedDB:", error);
      return null;
    }
  }

  // Delete data from IndexedDB
  static async removeItem(key) {
    try {
      const db = await this.initializeDB();
      await db.delete("dataStore", key);
    } catch (error) {
      console.error("Error deleting data from IndexedDB:", error);
    }
  }

  // Clear all data from IndexedDB
  static async clearAll() {
    try {
      const db = await this.initializeDB();
      const keys = await db.getAllKeys("dataStore");
      for (const key of keys) {
        await db.delete("dataStore", key);
      }
    } catch (error) {
      console.error("Error clearing IndexedDB:", error);
    }
  }
}

export default SecureIndexedDB;
