import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Close,
  DescriptionOutlined,
  Verified,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { Microphone } from "iconoir-react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Play, Plus, Trash, XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetLoginUserDetails, validateEmail } from "../../../utils/Utils";
import { jwtDecode } from "jwt-decode";
import {
  getAllUsersMapOfOrg,
  getAllDays,
  createScheduleEvent,
  sendInvite,
  getContacts,
} from "../../../services/SchedulerService";
import DateUtils from "../../../utils/DateUtils";
import ProfileService from "../../../services/ProfileService";
import { getChatAllContacts } from "../../../services/ChatService";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { TourContext } from "../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

const RecurringEvent = ({ handleCloseMenuButton, loading, setLoading }) => {
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  console.log("userDetails", userDetails);

  const loginUser = GetLoginUserDetails();

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  /////////////////////////////////////////////////////////////

  // const [anchorEl, setAnchorEl] = useState(null);

  // const openPopover = Boolean(anchorEl);
  // const popoverId = openPopover ? 'simple-popover' : undefined;

  const [meetingParticipants, setMeetingParticipants] = useState([]);
  //   const [newContactButton , setNewContactButton] = useState(false)
  //   const [userName, setUserName] = useState("");
  //   const [userEmail, setUserEmail] = useState("");

  //   const handleNewContactButton = (event) => {
  //     setAnchorEl(event.currentTarget);
  //     setNewContactButton(true);
  //   };

  //   const handleCloseNeCwontactButtonModal = () => {
  //     setAnchorEl(null);
  //     setNewContactButton(false);
  //     setUserName("");
  //     setUserEmail("");
  //   };

  //   const handleUserNameChange = (event) => {
  //     console.log('handleUserNameChange' , event.target.value)
  //     setUserName(event.target.value);
  //   };

  //   const handleUserEmailChange = (event) => {
  //     setUserEmail(event.target.value);
  //   };

  //   const handleFormSubmit = () => {

  //     if (userName.trim() === "") {
  //       toast.error("Please Enter User Name", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       return;
  //     };

  //     if (userEmail.trim() === "") {
  //       toast.error("Please Enter User Email", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       return;
  //     };

  //     if (userEmail.trim() !== "" && !validateEmail(userEmail)) {
  //       toast.error("Please Enter Valid Email", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       return;
  //     }

  //     const newParticipant = {
  //       calledBy: "",
  //       chairperson: "",
  //       convener: "",
  //       id: null ,
  //       orgId: "",
  //       outSide: "" ,
  //       profileImage: "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png",
  //       userId: "",
  //       userName: userName+' ( '+userEmail+' )',
  //     }

  //     setMeetingParticipants([...meetingParticipants, newParticipant]);
  //     setNewContactButton(false);
  //     setAnchorEl(null);
  //     setUserName('');
  //     setUserEmail('');

  //     toast.success('New Contact added successfully', {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   };

  ////////////////////////////////////////////////

  const [anchorElRP, setAnchorElRP] = React.useState(null);
  const [allUsersList, setAllUsersList] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedGuestInput, setSelectedGuestInput] = useState("");
  const openRP = Boolean(anchorElRP);
  const idRP = openRP ? "simple-popover" : undefined;
  const buttonRPRef = useRef(null);
  const buttonRPCancelRef = useRef(null);
  const buttonApplyForAllRec = useRef(null);
  const buttonApplyForAllRecReschedule = useRef(null);
  const buttonApplyForAllRecAddParticipant = useRef(null);
  const buttonApplyForAllRecRemoveParticipant = useRef(null);

  const handleClickRP = (event) => {
    setAnchorElRP(event.currentTarget);
  };

  const handleCloseRP = () => {
    setAnchorElRP(null);
  };
  // const [meetingParticipants, setMeetingParticipants] = useState([]);
  // const [selectedParticipant, setSelectedParticipant] = React.useState(null);
  ////////////////////////

  ////////////////////////////////////private notes
  //add pvt note pop over
  const [userPrivateNotes, setUserPrivateNotes] = useState([]);
  const [anchorElPN, setAnchorElPN] = React.useState(null);

  const handleClickPN = (event) => {
    setAnchorElPN(event.currentTarget);
  };

  const handleClosePN = () => {
    setAnchorElPN(null);
  };

  const openPN = Boolean(anchorElPN);
  const idPN = openPN ? "add-pvtNotePopOver" : undefined;
  // const [openAddPvtNote, setOpenAddPvtNote] = useState(false);

  // const handleOpenAddPvtNote = () => {
  //   setOpenAddPvtNote(true);
  // };

  // const handleClosePvtNote = () => {
  //   setOpenAddPvtNote(false);
  // };

  const addPNRef = useRef(null);
  const cancelPNRef = useRef(null);
  const inputPNRef = useRef(null);
  const [pvtNote, setPvtNote] = useState(null);

  const onClickAddPvtNote = () => {
    console.log(pvtNote);
    if (pvtNote === null || pvtNote.trim() === "") {
      console.log("pvtNote");
      inputPNRef.current.style.borderColor = "red";
      inputPNRef.current.placeholder = "Please add note...";
      return;
    }
    // return;
    addPNRef.current.disabled = true;
    const userNotesDto = {
      eventNoteId: null,
      notes: pvtNote,
      notingTime: new Date().getTime(),
    };
    setUserPrivateNotes((prevList) => [...prevList, userNotesDto]);
    handleClosePN();
    setPvtNote(null);
    // setAnchorElPN(null);
    return;

    // addPrivateNotes(loginUser.userId, selectedEvent).then((response) => {
    //   console.log(response.data);
    //   getPrivateNotes(loginUser.userId, selectedEvent.eventId).then(
    //     (response) => {
    //       setUserPrivateNotes(response.data);
    //     }
    //   );
    // });
  };
  ///////////////////////////////////private notes end

  // const loginUser = GetLoginUserDetails();

  // const [loading, setLoading] = useState(false);

  const [selContacts, setSelContacts] = useState([]);

  useEffect(() => {
    getContacts(userDetails.userId).then((response) => {
      console.log("getContacts", response.data);
      // toast.success("Contact added Successfully", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });

      if (response.data) {
        const newSelContacts = response.data.map((contact) => {
          if (contact.userId === userDetails.userId) {
            return {
              accountExists: null,
              contactEmailId: "",
              contactName: userDetails.userName,
              contactUserId: userDetails.userId,
              id: "",
              inviteDate: "",
              inviteLink: "",
              inviteStatus: "",
              meetingId: null,
              userId: null,
            };
          } else {
            return {
              accountExists: null,
              contactEmailId: contact.contactEmailId,
              contactName: contact.contactName,
              contactUserId: contact.contactUserId,
              id: contact.id,
              inviteDate: contact.inviteDate,
              inviteLink: contact.inviteLink,
              inviteStatus: contact.inviteStatus,
              meetingId: null,
              userId: null,
            };
          }
        });
        setSelContacts((prevSelContacts) => [
          ...prevSelContacts,
          ...newSelContacts,
        ]);
      }
    });

    setSelContacts([
      {
        accountExists: null,
        contactEmailId: "",
        contactName: userDetails.userName,
        contactUserId: userDetails.userId,
        id: "",
        inviteDate: "",
        inviteLink: "",
        inviteStatus: "",
        meetingId: null,
        userId: null,
      },
    ]);
  }, []);

  // const [startDateselect, setStartDateselect] = useState("");
  const [eventType, setEventType] = useState("Event");
  const [descriptionVisible, setDescriptionVisible] = useState(true);

  const [selectEventType, setSelectEventType] = useState("onetime");

  const handleEventType = (event) => {
    setSelectEventType(event.target.value);
  };

  // const [selectEventMode, setSelectEventMode] = useState("virtual");

  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [tempDivWidth, setTempDivWidth] = useState(0);
  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);

    // Update visibility of description based on selected event type
    setDescriptionVisible(event.target.value === "Event");
  };
  ////////////////////////////////////////////////////////////////////////////////

  const [startDate, setStartDate] = useState(new Date());
  const [startDateselect, setStartDateselect] = useState("");
  const handleSetDate = (event) => {
    setStartDateselect(event.target.value);
  };

  ////////////////////////////////date////////////////////////////////////////////////////////

  const [startDay, setStartDay] = useState(new Date());
  const [endDay, setEndDay] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timeOptions, setTimeOptions] = useState([]);
  const [timeEndOptions, setTimeEndOptions] = useState([]);
  const [currentDay, setCurrentDay] = useState(getCurrentDay(startDay));
  const [currentEndDay, setEndCurrentDay] = useState(getCurrentDay(endDay));
  const [selectedDates, setSelectedDates] = useState([]);
  const [showAllTimes, setShowAllTimes] = useState(false);

  const [startRecuringTime, setStartRecuringTime] = useState("");
  const [endRecuringTime, setEndRecuringTime] = useState("");

  const [recuringStartDate, setRecuringStartDate] = useState(new Date());

  function getCurrentDay(date) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }

  const handleStartDateChange = (date) => {
    console.log("date");
    setStartDay(date);
    setCurrentDay(getCurrentDay(date));
    // setRecuringStartDate(date);

    const lastDayOfMonth = getLastDayOfMonth(
      date.getFullYear(),
      date.getMonth()
    );
    const datesInRange = generateDatesInRange(new Date(), lastDayOfMonth);

    setSelectedDates(datesInRange);
    console.log("Selected Dates:", datesInRange);

    if (endDay < date) {
      setEndDay(date);
      setEndCurrentDay(getCurrentDay(date));
    }
    // getCurrentTimeOptions(date);
    // setEventStartdate(date);
    setEventStartdate(date !== null ? date : new Date());
  };

  const generateDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const getLastDayOfMonth = (year, month) => {
    // Months are zero-based in JavaScript dates
    const lastDay = new Date(year, month + 1, 0);
    return lastDay;
  };

  // const lastDayOfMonth = getLastDayOfMonth(
  //   startDay.getFullYear(),
  //   startDay.getMonth()
  // );

  const handleEndDateChange = (date) => {
    console.log("date", date);
    // setEventEndDate(getCurrentDay(date))
    // Ensure end date is not less than start date
    if (date < startDay) {
      setStartDay(date);
      setCurrentDay(getCurrentDay(date));
    }
    setEndDay(date);
    setEndCurrentDay(getCurrentDay(date));
    // setEventEndDate(date)
    setEventEndDate(date !== null ? date : eventStartDate || new Date());
  };

  const getCurrentTimeOptions = () => {
    const options = [];

    for (let hour = 0; hour <= 23; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = (hour === 0 ? "00" : hour % 12 || 12)
          .toString()
          .padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        const period = hour < 12 ? "AM" : "PM";
        const timeString = `${formattedHour}:${formattedMinute} ${period}`;
        options.push(timeString);
      }
    }

    setTimeOptions(options);
  };

  // const getCurrentTimeOptions = (selectedDate) => {
  //   const options = [];
  //   const currentDate = new Date();

  //   let startHour = 0;
  //   let startMinute = 0;

  //   if (selectedDate.toDateString() === currentDate.toDateString()) {
  //     // If the selected date is today, start from the current time
  //     if (currentDate.getHours() >= 23 && currentDate.getMinutes() >= 45) {
  //       // If the current time is 11:45 PM or later, start from the next day
  //       selectedDate.setDate(selectedDate.getDate() + 1);
  //       startHour = 0;
  //       startMinute = 0;
  //     } else {
  //       // Otherwise, start from the current time
  //       startHour = currentDate.getHours();
  //       startMinute = Math.ceil(currentDate.getMinutes() / 15) * 15;
  //     }
  //   }

  //   for (let hour = startHour; hour <= 23; hour++) {
  //     const startMin =
  //       hour === startHour ? Math.ceil(startMinute / 15) * 15 : 0;
  //     for (let minute = startMin; minute < 60; minute += 15) {
  //       const formattedHour = (
  //         hour === 0 ? "00" : hour % 12 === 0 ? 12 : hour % 12
  //       ).toString();
  //       const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
  //       const period = hour < 12 ? "AM" : "PM";
  //       const timeString = `${formattedHour}:${formattedMinute} ${period}`;
  //       options.push(timeString);
  //     }
  //   }

  //   setTimeOptions(options);
  // };

  useEffect(() => {
    getCurrentTimeOptions();
  }, []);

  useEffect(() => {
    if (timeOptions.length > 0) {
      setStartTime(timeOptions[0]);
      setStartRecuringTime(timeOptions[0]);
      setEndTime(timeOptions[2] || "");
      setEndRecuringTime(timeOptions[2] || "");
      setTimeEndOptions(timeOptions.slice(1));
    }
    setEventStartTime(timeOptions[0]);
  }, [timeOptions]);

  useEffect(() => {
    if (endDay > startDay) {
      // getCurrentTimeOptions(endDay);
      // Set default value for eventEndDate if not already set
    }
  }, [endDay]);

  const handleStartTimeChange = (event) => {
    const newStartTime = event.target.value;
    setStartTime(newStartTime);
    setStartRecuringTime(newStartTime);

    // Update endTime list based on the newStartTime
    let newEndTimeList;

    if (newStartTime === "11:45 PM") {
      newEndTimeList = ["00:00 AM"]; // Set end time to '00:00 AM'
    } else {
      newEndTimeList = timeOptions.slice(timeOptions.indexOf(newStartTime) + 1);
    }

    setEndTime(newEndTimeList[0] || "");
    setEndRecuringTime(newEndTimeList[0] || "");
    setTimeEndOptions(newEndTimeList);
  };
  // ... (rest of your code)

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
    setEventEndTime(event.target.value);
    setEndRecuringTime(event.target.value);
  };

  const [newStartDate, setNewStartDate] = useState(new Date());
  const [currentNewDay, setCurrentNewDay] = useState(
    getCurrentDay(newStartDate)
  );

  const newStartDateChange = (date) => {
    setNewStartDate(date);
    setRecuringStartDate(date);
    setCurrentNewDay(getCurrentDay(date));
    // getCurrentTimeOptions(date);
  };
  //////////////////////////////////////////api//////////////////////////////////////

  const [eventStartTime, setEventStartTime] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [eventStartDate, setEventStartdate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  // const [eventGuest, setEventGuest] = useState([]);

  //////////////////////////////////////////////// guest ////////////////////////////////////////////

  // const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [refreshCount, setRefreshCount] = useState(1);
  const [participants, setParticipants] = useState([]);

  // useEffect(() => {
  //   getAllUsersMapOfOrg(userDetails.orgId)
  //     .then((response) => {
  //       console.log("alluser", response.data);

  //       const inputObject = response.data;

  //       const outputList = Object.entries(inputObject).map(
  //         ([userId, userName]) => ({
  //           userId,
  //           userName,
  //         })
  //       );
  //       console.log("outputList", outputList);

  //       setAllUsersList(outputList);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching participants:", error);
  //     });
  // }, []);

  useEffect(() => {
    getChatAllContacts(loginUser.userId, loginUser.orgId).then((response) => {
      console.log("getChatAllContacts", response.data);

      ProfileService.getAllUserDetailsByUserId(loginUser.userId).then(
        (response2) => {
          let userAllInfo = response2.data;
          const profileImageLink =
            userAllInfo?.userDtls?.profileImageLink || "";
          let myDetails = {
            userId: loginUser.userId,
            userName: loginUser.userName,
            userDtl: loginUser.externalCategory
              ? loginUser.externalCategory
              : "Internal",
            // profileUrl: `data:image/png;base64,${
            //   userAllInfo ? userAllInfo.userDtls.profileImageLink : ""
            // }`,
            profileUrl: profileImageLink.startsWith("http")
              ? profileImageLink
              : `data:image/png;base64,${profileImageLink}`,
            userType: loginUser.externalCategory ? "E" : "I",
            convener: "Y",
          };

          setEventGuest(myDetails.userId);
          setMeetingParticipants((prevList) => [
            ...prevList,
            {
              id: null,
              userId: myDetails.userId,
              userName: myDetails.userName,
              profileImage: myDetails.profileUrl,
              convener: "Y",
              chairperson: "",
              outSide: myDetails.userType === "I" ? "N" : "Y",
              calledBy: "N",
              orgId: loginUser.orgId,
            },
          ]);

          setAllUsersList([...response.data, myDetails]);
        }
      );
    });
  }, []);

  // const makeConveyor = (userId) => {
  //   let tmplist = meetingParticipants;
  //   for (let i = 0; i < tmplist.length; i++) {
  //     if (tmplist[i].userId == userId) {
  //       tmplist[i].convener = "Y";
  //     } else {
  //       tmplist[i].convener = "N";
  //     }
  //   }
  //   setMeetingParticipants(tmplist);
  //   setEventGuest(tmplist);
  //   setRefreshCount((r) => r + 1);
  // };

  // const handleRemoveParticipant = (userId) => {
  //   // Check if the participant is a conveyor
  //   const isConveyor = meetingParticipants.some(
  //     (participant) =>
  //       participant.userId === userId && participant.convener === "Y"
  //   );

  //   // If the participant is a conveyor, do not allow removal
  //   if (isConveyor) {
  //     // You can handle this case (e.g., show a message)
  //     console.log("Cannot remove a conveyor participant");
  //     return;
  //   }

  //   // Update the state to remove the participant with the specified userId
  //   const updatedParticipants = meetingParticipants.filter(
  //     (participant) => participant.userId !== userId
  //   );

  //   setMeetingParticipants(updatedParticipants);

  //   handleCloseRP();

  //   // You may also add additional logic here if needed
  // };

  const makeConveyor = (userId) => {
    const updatedParticipants = meetingParticipants.map((participant) => ({
      ...participant,
      convener: participant.userId === userId ? "Y" : "N",
    }));

    setMeetingParticipants(updatedParticipants);
    setEventGuest(updatedParticipants);
    setRefreshCount((r) => r + 1);
  };

  const handleRemoveParticipant = (userId) => {
    const isConveyor = meetingParticipants.some(
      (participant) =>
        participant.userId === userId && participant.convener === "Y"
    );

    if (isConveyor) {
      console.log("Cannot remove a conveyor participant");
      return;
    }

    const updatedParticipants = meetingParticipants.filter(
      (participant) => participant.userId !== userId
    );

    setMeetingParticipants(updatedParticipants);
    handleCloseRP();
  };

  useEffect(() => {
    // Function to format and set participants state
    const formatParticipants = () => {
      const formattedParticipants = meetingParticipants.map((participant) => {
        const formattedParticipant = { userId: participant.userId };
        if (participant.convener) {
          formattedParticipant.convener = participant.convener;
        }
        return formattedParticipant;
      });
      setParticipants(formattedParticipants);
    };

    formatParticipants();
  }, [meetingParticipants]);

  ///////////////////////////////// event days ////////////////////////////////
  const [eventLocation, setEventLocation] = useState(userDetails.orgAddress);
  const [eventDayInWeek, setEventDayInWeeK] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    getAllDays().then((response) => setEventDayInWeeK(response.data));
    setEventLocation(userDetails.orgAddress);
  }, []);

  // const handleChange = (event) => {

  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectedDays(
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  // const handleChange = (event) => {
  //   const selectedValues = event.target.value;
  //   setSelectedDays(selectedValues);

  //   // Extract IDs from the selected
  //   const selectedIds = eventDayInWeek
  //     .filter((day) => selectedValues.includes(day.name))
  //     .map((day) => day.id);

  //   console.log("Selected IDs:", selectedIds);
  //   // Do whatever you need with the selected IDs

  //   setSelectedDaysId(selectedIds);
  // };

  // const handleSelectAllChange = () => {
  //   if (selectAll) {
  //     setSelectedDays([]);
  //   } else {
  //     setSelectedDays(eventDayInWeek.map((option) => option.name));
  //   }
  //   setSelectAll(!selectAll);
  // };

  // const handleChange = (event) => {
  //   const selectedValues = event.target.value;
  //   setSelectedDays(selectedValues);

  //   // Extract IDs from the selected
  //   const selectedIds = eventDayInWeek
  //     .filter((day) => selectedValues.includes(day.name))
  //     .map((day) => day.id);

  //   console.log("Selected IDs:", selectedIds);
  //   // Do whatever you need with the selected IDs

  //   // Update selected IDs state
  //   setSelectedDaysId(selectedIds);
  // };

  // const handleSelectAllChange = () => {
  //   if (!selectAll) {
  //     const allDaysNames = eventDayInWeek.map((option) => option.name);
  //     const allDaysIds = eventDayInWeek.map((option) => option.id);
  //     setSelectedDays(allDaysNames);
  //     setSelectedDaysId(allDaysIds);
  //   } else {
  //     setSelectedDays([]);
  //     setSelectedDaysId([]);
  //   }
  //   setSelectAll(!selectAll);
  // };

  const handleChange = (event) => {
    const { value } = event.target;

    if (value.includes("select-all")) {
      if (!selectAll) {
        const allDaysNames = eventDayInWeek.map((option) => option.name);
        const allDaysIds = eventDayInWeek.map((option) => option.id);
        setSelectedDays(allDaysNames);
        setSelectedDaysId(allDaysIds);
      } else {
        setSelectedDays([]);
        setSelectedDaysId([]);
      }
      setSelectAll(!selectAll);
    } else {
      setSelectedDays(value);

      const selectedIds = eventDayInWeek
        .filter((day) => value.includes(day.name))
        .map((day) => day.id);

      console.log("Selected IDs:", selectedIds);
      setSelectedDaysId(selectedIds);
      setSelectAll(value.length === eventDayInWeek.length);
    }
  };

  /////////////////////////////////////final api////////////////////////////////////////////////////////////

  const [eventTitle, setEventTitle] = useState("");

  const [selectedDaysId, setSelectedDaysId] = useState([]);

  const [eventGuest, setEventGuest] = useState([]);

  const handleInputofevent = (e) => {
    console.log("event title", e.target.value);
    setEventTitle(e.target.value);
  };

  function convertTime12to24(time12) {
    const [time, period] = time12.split(" ");

    let [hours, minutes] = time.split(":");

    hours = parseInt(hours, 10);

    if (period === "PM" && hours < 12) {
      hours += 12;
    }

    if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  }

  const finalRecuringsubmit = () => {
    setLoading(true);
    console.log("startRecuringTime", startRecuringTime);
    console.log("endRecuringTime", endRecuringTime);

    const startTime12 = startRecuringTime;
    const startTime24 = convertTime12to24(startTime12);

    const endTime12 = endRecuringTime;
    const endTime24 = convertTime12to24(endTime12);

    console.log("endTime24", endTime24);

    console.log("eventTitle", eventTitle);
    console.log("eventLocation", eventLocation);
    console.log("selectedDaysId", selectedDaysId);
    console.log("startRecuringTime", startRecuringTime);
    console.log("endRecuringTime", endRecuringTime);
    console.log("recuringStartDate", recuringStartDate);
    console.log("participants", participants);

    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const currentTime = currentHour + ":" + currentMinute;
    console.log("currentTime", currentTime);
    console.log("startTime24", startTime24);
    console.log("recuringStartDate", recuringStartDate);

    const updateDated = DateUtils.getDateInYYYYMMDD(
      new Date(recuringStartDate)
    );
    console.log("updateDate", updateDated);
    const NewupdateDated = DateUtils.getDateInYYYYMMDD(new Date());
    console.log("NewupdateDated", NewupdateDated);

    if (eventTitle.trim() === "") {
      toast.error(t("please_enter_event_title"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (eventLocation.trim() === "") {
      toast.error(t("please_enter_event_location"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (selectedDaysId.length === 0) {
      toast.error(t("select_the_days"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (participants.length === 0) {
      toast.error(t("select_participants"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (NewupdateDated === updateDated && currentTime > startTime24) {
      toast.error(t("please_select_current_time"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    toast.info(t("please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });

    let reqDto = {
      title: eventTitle,
      repeatUntil: DateUtils.getDateInYYYYMMDD(new Date(recuringStartDate)),
      startTime: startTime24,
      endTime: endTime24,
      location: eventLocation,
      participants: participants,
      dayIds: selectedDaysId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    console.log("createRecurringEvent", reqDto);

    createRecurringEvent(reqDto);
  };

  const createRecurringEvent = (reqDto) => {
    console.log("selContacts", selContacts);

    // setLoading(false);
    // return;
    createScheduleEvent(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log(response.data);
        if (response.data.returnValue === "1") {
          toast.success(t("meeting_created_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleCloseMenuButton();

          if (userDetails.orgId === "ORG000000000000") {
            let contactsToSendJoinOrConnect = selContacts.filter(
              (contact) => contact.inviteStatus === "P"
            );

            if (
              contactsToSendJoinOrConnect &&
              contactsToSendJoinOrConnect.length > 0
            ) {
              contactsToSendJoinOrConnect.forEach((contact) => {
                sendInvite(contact, userDetails.userId).then((response) => {
                  console.log("sendCallInvite", response.data);

                  getContacts(userDetails.userId).then((response) => {
                    console.log(response.data);

                    toast.success(t("invite_sent_successfully"), {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  });
                });
              });
            }
          }

          setEventTitle("");
          setEventLocation("");
          setSelectedDaysId([]);
          setSelectedDays([]);
          setEventGuest([]);
          setMeetingParticipants([]);
          setRecuringStartDate(new Date());
          setLoading(false);
        } else {
          toast.error(t("fail_to_create_meeting"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      }
    );
  };

  const searchUserFilteredList = useMemo(() => {
    let filtered = [];
    if (selectedGuestInput.length > 0 && selectedGuestInput.trim() !== "") {
      filtered = allUsersList.filter((option) =>
        option.userName.toLowerCase().includes(selectedGuestInput.toLowerCase())
      );
    }

    return filtered;
  }, [selectedGuestInput, allUsersList]);

  useEffect(()=>{
  setBookSteps([
    {
      target: ".recuuringEventMeetStepOne",
      content: "Enter the event title of Recurring Event here.",
      disableBeacon: true,
    },
    {
      target: ".recuuringEventMeetStepTwo",
      content: "Specify the location for the event.",
      disableBeacon: true,
    },
    {
      target: ".recuuringEventMeetStepThree",
      content: "Select the days when the event will take place.",
      disableBeacon: true,
    },
    {
      target: ".recuuringEventMeetStepFour",
      content: "Set the start time for your event.",
      disableBeacon: true,
    },
    {
      target: ".recuuringEventMeetStepFive",
      content: "Set the end time for your event.",
      disableBeacon: true,
    },
    {
      target: ".recuuringEventMeetStepSix",
      content: "Repeat the event Upto This Date",
      disableBeacon: true,
    },
    {
      target: ".recuuringEventMeetStepSeven",
      content: "Add guests to your event here. You can select multiple participants.",
      disableBeacon: true,
    },
  ])
  },[])

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewOneTimeMettingSC"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      {loading ? (
        // <CircularProgress
        //   size={150}
        //   color="success"
        //   top="60"
        //   left="160"
        //   position="relative"
        // />
        <div className="meetingVEContainer">
          <div className="center-progress" style={{ height: "65vh" }}>
            <CircularProgress sx={{ marginTop: "180px" }} />
          </div>
        </div>
      ) : (
        <>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                className="MainTitleTexbox recuuringEventMeetStepOne"
                hiddenLabel
                variant="filled"
                placeholder={t("event_title")}
                required
                value={eventTitle}
                onChange={(e) => handleInputofevent(e)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                hiddenLabel
                placeholder={t("location")}
                required
                variant="outlined"
                className="formTextFieldArea recuuringEventMeetStepTwo"
                value={eventLocation}
                onChange={(e) => setEventLocation(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel>{t("select_days")}*</InputLabel>
              <Select
                hiddenLabel
                className="formInputField recuuringEventMeetStepThree"
                variant="outlined"
                multiple
                value={selectedDays}
                onChange={handleChange}
                // renderValue={(selected) => selected.join(", ")}
                renderValue={(selected) => 
                  selected.map((day) => t(day)).join(", ")
                } 
              >
                <MenuItem key="select-all" value="select-all">
                  <Checkbox checked={selectAll} />
                  <ListItemText primary={t("select_all")} />
                </MenuItem>
                {eventDayInWeek.map((days) => (
                  <MenuItem key={days.id} value={days.name}>
                    <Checkbox checked={selectedDays.indexOf(days.name) > -1} />
                    <ListItemText primary={t(days.name)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="formElement">
            <div className="medDateTime">
              <div
                className="frmDateTime recurDTW100"
                style={{
                  display: "flex !important",
                  flexWrap: "wrap",
                  justifyContent: "center !important",
                }}
              >
                <div className="frmTimeInput">
                  <Select
                    className="slctFrmTime recuuringEventMeetStepFour"
                    value={startTime}
                    onChange={handleStartTimeChange}
                    sx={{ maxWidth: "115px !important" }}
                  >
                    {timeOptions.map((time, index) => (
                      <MenuItem key={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              <span className="spanElement">{t("to")}</span>

              <div
                className="toDateTime recurDTW100"
                style={{
                  flexWrap: "wrap",
                  justifyContent: "center !important",
                }}
              >
                <div className="toTimeInput">
                  <Select
                    className="slctFrmTime recuuringEventMeetStepFive"
                    value={endTime}
                    onChange={handleEndTimeChange}
                    sx={{ maxWidth: "115px !important" }}
                  >
                    {timeEndOptions.map((time, index) => (
                      <MenuItem key={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              <span className="spanElement">
                {t("event_repeat_until_date")}
              </span>

              <div
                className="frmDateTime recuuringEventMeetStepSix"
                style={{
                  display: "flex !important",
                  flexWrap: "wrap",
                  justifyContent: "center !important",
                }}
              >
                <div className="frmDateInput">
                  <div className="frmDaysPick">{currentNewDay}</div>
                  {/* START TIME */}
                  <ReactDatePicker
                    locale={currentLocale}
                    className="frmdatePick "
                    selected={newStartDate}
                    // onChange={(date) => setStartDate(date)}
                    onChange={newStartDateChange}
                    minDate={new Date()}
                    maxDate={new Date().getTime() + 1051200 * 60000}
                    value={eventStartDate}
                    dateFormat="dd-MMM-yyyy"
                    // onChange={(e) => setEventStartdate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="formElement">
            <div className="medDateTime"></div>
          </div>

          {/* add guest start */}

          <div className="meetGustDtlElement recuuringEventMeetStepSeven">
            <div className="metGustDtlElementTitle">
              <span>{t("add_guests")}*</span>
            </div>
            <div
              // className="metEDAddGuestBTN"
              style={{ paddingTop: "10px" }}
            >
              {/* <Button>Add Guests</Button> */}
              <div
              // style={{ display: "flex" }}
              >
                <Autocomplete
                  noOptionsText={t("please_type_to_search")}
                  id="participantSelect"
                  sx={{
                    width: 300,
                    backgroundColor: "#333",
                    borderRadius: "10px",
                    color: "white",
                  }}
                  value={selectedGuest}
                  onChange={(event, selectedValue) => {
                    console.log("newValue", selectedValue);

                    // if (selectedEvent.eventType === "Recurring Event") {
                    //   setSelectedGuest(selectedValue);
                    // } else {
                    setMeetingParticipants((prevList) => {
                      if (
                        !prevList.some(
                          (participant) =>
                            participant.userId === selectedValue.userId
                        )
                      ) {
                        return [
                          ...prevList,
                          {
                            id: null,
                            userId: selectedValue.userId,
                            userName: selectedValue.userName,
                            profileImage: selectedValue.profileUrl,
                            convener: "",
                            chairperson: "",
                            outSide: selectedValue.userType === "I" ? "N" : "Y",
                            calledBy: "N",
                            orgId: loginUser.orgId,
                          },
                        ];
                      }
                      return prevList;
                    });
                    setSelectedGuest(null);
                    setSelectedGuestInput("");
                    // }
                  }}
                  inputValue={selectedGuestInput}
                  onInputChange={(event, newInputValue) => {
                    setSelectedGuestInput(newInputValue);
                  }}
                  options={searchUserFilteredList}
                  autoHighlight
                  getOptionLabel={(option) => option.userName}
                  getOptionDisabled={(option) =>
                    meetingParticipants.some(
                      (userDto) => userDto.userId === option.userId
                    )
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`${option.profileUrl}`}
                        src={`${option.profileUrl}`}
                        alt=""
                      />
                      {option.userName}
                      {option.userType === "I" ? (
                        <span style={{ padding: "5px" }}>
                          <Tooltip title={t("belongs_to_your_organization")}>
                            {/* <BadgeCheck /> */}
                            <Verified />
                          </Tooltip>
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      InputProps={
                        {
                          // style: { color: "white !important" },
                        }
                      }
                      {...params}

                      // label="Select a participant"

                      // inputProps={{
                      //   ...params.inputProps,
                      //   autoComplete: "new-password", // disable autocomplete and autofill
                      // }}
                    />
                  )}
                />
                {/* {selectedEvent.eventType === "Recurring Event" &&
                    selectedGuest != null && (
                      <div className="addParticipantBtn">
                        <Button
                          onClick={() => {
                            setMeetingParticipants((prevList) => [
                              ...prevList,
                              {
                                id: null,
                                userId: selectedGuest.userId,
                                userName: selectedGuest.userName,
                                profileImage: selectedGuest.profileUrl,
                                convener: "",
                                chairperson: "",
                                outSide:
                                  selectedGuest.userType === "I" ? "N" : "Y",
                                calledBy: "N",
                                orgId: loginUser.orgId,
                                applyForAllRecurringEvents:
                                  buttonApplyForAllRecAddParticipant.current
                                    ? buttonApplyForAllRecAddParticipant.current
                                        ?.checked
                                      ? "Y"
                                      : "N"
                                    : "N",
                              },
                            ]);
                            setSelectedGuest(null);
                            setSelectedGuestInput("");
                          }}
                        >
                          ADD
                        </Button>
                      </div>
                    )} */}
              </div>
            </div>
            <div className="metGustLstGrp">
              {meetingParticipants.map((participantDto) => {
                return (
                  <>
                    <div
                      className={`metGustLstInd`}
                      style={{ cursor: "unset" }}
                    >
                      <div className="metGstImg">
                        <img src={participantDto.profileImage} alt="" />
                        {/* <span className="metConVerd">
                            <CheckCircle />
                          </span> */}
                      </div>
                      <div className="metGstName">
                        {participantDto.userName}
                        {participantDto.outSide === "N" ? (
                          <span style={{ padding: "5px" }}>
                            <Tooltip title={"Belongs to your organization"}>
                              {/* <BadgeCheck /> */}
                              <Verified />
                            </Tooltip>
                          </span>
                        ) : (
                          <></>
                        )}

                        {participantDto.convener === "Y" && (
                          <span className="metConvenor">({t("convener")})</span>
                        )}
                        {/* {participantDto.chairperson === "Y" && (
                            <span className="metConvenor">(Chairperson)</span>
                          )} */}
                      </div>
                      {participantDto.userId &&
                        participantDto.convener !== "Y" &&
                        refreshCount > 0 && (
                          <div className="mkAsConvenorBtnGrp">
                            <Button
                              onClick={() =>
                                makeConveyor(participantDto.userId)
                              }
                            >
                              {t("make_convener")}
                            </Button>
                          </div>
                        )}
                      {/* {participantDto.chairperson !== "Y" &&
                          // selectedEvent.eventType === "Meeting" && 
                          (
                            <div className="mkAsConvenorBtnGrp">
                              <Button
                                onClick={() => makeChairperson(participantDto)}
                              >
                                Make Chairperson
                              </Button>
                            </div>
                          )} */}
                      {(participantDto.convener === "N" ||
                        participantDto.convener === "") &&
                        (participantDto.chairperson === "N" ||
                          participantDto.chairperson === "") && (
                          <div className="mkAsConvenorBtnGrp">
                            <IconButton
                              aria-describedby={idRP}
                              onClick={(event) => {
                                handleClickRP(event);
                                setSelectedParticipant(participantDto);
                              }}
                            >
                              <Trash />
                            </IconButton>
                            <Popover
                              id={idRP}
                              open={openRP}
                              anchorEl={anchorElRP}
                              onClose={handleCloseRP}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                            >
                              <div style={{ padding: "20px" }}>
                                <div className="modelTitle">
                                  {t("confirmation")}
                                </div>
                                <div
                                  className="modInputFild"
                                  style={{ padding: "20px 10px 10px 0px" }}
                                >
                                  {/* Remove {selectedParticipant?.userName} from
                                  guest list? */}
                                  {t("remove_from_guest_list", {
                                    userName: selectedParticipant?.userName,
                                  })}
                                </div>

                                {/* {selectedEvent.eventType ===
                                    "Recurring Event" && ( */}
                                {/* <div
                                      className="modActionBtnGrp"
                                      style={{ padding: "5px 0px 5px 0px" }}
                                    >
                                      <input
                                        type="checkbox"
                                        ref={
                                          buttonApplyForAllRecRemoveParticipant
                                        }
                                      />
                                      Remove for all recurring events
                                    </div> */}
                                {/* )} */}

                                <div className="modActionBtnGrp">
                                  <Button
                                    className="dfultPrimaryBtn"
                                    // onClick={() => removeParticipant()}
                                    onClick={() =>
                                      handleRemoveParticipant(
                                        selectedParticipant?.userId
                                      )
                                    }
                                    ref={buttonRPRef}
                                  >
                                    {t("remove")}
                                  </Button>
                                  <Button
                                    className="dfultDarkSecondaryBtn"
                                    onClick={handleCloseRP}
                                    ref={buttonRPCancelRef}
                                  >
                                    {t("cancel")}
                                  </Button>
                                </div>
                                {/* <div
                                  className="modActionBtnGrp"
                                  style={{ marginTop: "5px" }}
                                >
                                  <Button
                                    className="dfultPrimaryBtn"
                                    onClick={() => removeParticipant("Y")}
                                    // ref={buttonRefRP}
                                  >
                                    Remove from all Recurring Events
                                  </Button>
                                </div> */}
                              </div>
                            </Popover>
                          </div>
                        )}
                    </div>
                  </>
                );
              })}
              {/* <div className="metGustLstInd gustAsConvenor">
                <div className="metGstImg">
                  <img src={userImg1} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Aiyasha Hasan<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div> */}
              {/*
              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg2} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Sanket Santra<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div>

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg3} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Prashant Saha<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div>

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg4} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Sk. Aftabuddin<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div> */}
              {/* {selectedEvent.eventType === "Recurring Event" &&
                  meetingParticipants.some((obj) => obj.id === null) && (
                    <>
                      <div
                        className="modActionBtnGrp"
                        style={{ padding: "10px 0px 10px 0px" }}
                      >
                        <input
                          style={{ width: "16px", height: "16px" }}
                          type="checkbox"
                          ref={buttonApplyForAllRecAddParticipant}
                        />
                        <span style={{ fontSize: "17px", color: "white" }}>
                          Add New participants for all related recurring events
                        </span>
                      </div>
                      <div
                        className="modActionBtnGrp"
                        style={{ padding: "2px 0px 2px 0px" }}
                      >
                        <span style={{ fontSize: "10px", color: "red" }}>
                          *Convenor/Chairperson needs to be changed individually
                          for each recurring event
                        </span>
                      </div>
                    </>
                  )} */}
            </div>
          </div>
          {/* add guest end */}
        </>
      )}

      <div className="fixedButtonsContainer">
        <div className="formBtnElement">
          {/* <Button
            className="dfultPrimaryBtn"
            onClick={() => finalRecuringsubmit()}
          >
            Save
          </Button> */}
          <Button
            className="dfultPrimaryBtn"
            onClick={() => finalRecuringsubmit()}
            // disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>{t("submit")}</>
            )}
            {/* Submit */}
          </Button>
          {!loading && (
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handleCloseMenuButton()}
            >
              {t("cancel")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default RecurringEvent;
