import "./utils/disableConsole";
import { ThemeProvider, createTheme } from "@mui/material";
import React, { Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  HashRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "./config/LanguageConfig";
import "./index.css";
import "./lightMode.css";

// responsive css import start///////////////////////////////////////////////
import "../src/common/css/responsiveCSS/oriantationLandscape.css";
import "../src/common/css/responsiveCSS/laptopsTabDesktops-min-1024.css";
import "../src/common/css/responsiveCSS/landscapeTablets-min-768.css";
import "../src/common/css/responsiveCSS/portraitTabletsPhones-min-576.css";
import "../src/common/css/responsiveCSS/smallPhones-max-576.css";

// responsive css import end/////////////////////////////////////////////////

import { ToastContainer } from "react-toastify";

import { Provider, useSelector } from "react-redux";
import { AppRoute, RouteGuard } from "./config/router";
import store from "./redux/store";
import Loaders from "./utils/Loaders";
import LandingPage from "./features/landingPage/view/LandingPage";
import { DatepickerContainer } from "./common/DatepickerContainer";
import LoginMeetingLinks from "./features/landingPage/view/LoginMeetingLinks";
import DashboardPageGuest from "./features/dashboard/view/DashboardPageGuest";
import RegisterViaInvitePersonal from "./features/landingPage/view/component/RegisterViaInvitePersonal";
import ContactAddSuccess from "./features/landingPage/view/component/ContactAddSuccess";
import { ConfirmProvider } from "material-ui-confirm";
// import OpenSharedFileDrive from "./features/landingPage/view/OpenSharedFileDrive";
import LandscapeOriantation from "./utils/LandscapeOriantation";
import PrivacyPolicy from "./common/PrivacyPolicy";
import TermsConditionsZoyel from "./common/TermsAndConditions";
import { Ticket } from "react-bootstrap-icons";
import TicketForm from "./features/support/view/TicketForm";
import { RteContainer } from "./features/RTE/RteContainer";
import KycVerification from "./features/landingPage/view/KycVerification";
import ToggleMode from "./utils/ToggleMode";
import { TourProvider } from "./common/TourProvider";
import { AppInitializer } from "./utils/AppInitializer";
import { webApp } from "./constants/url_provider";
import PrivacyPolicyZH from "./common/PrivacyPolicyZH";
// import { GetLoginUserDetails } from "./utils/Utils";

const OrgLogin = lazy(() => import("./features/home/view/OrgLogin"));
const DashboardPage = lazy(() =>
  import("./features/dashboard/view/DashboardPage")
);
const OpenSharedFileDrive = lazy(() =>
  import("./features/landingPage/view/OpenSharedFileDrive")
);
const root = ReactDOM.createRoot(document.getElementById("root"));

const darkTheme = createTheme({
  palette: {
    dark: {
      main: "#000000",
      pageBg: "#1C1C1C",
      primaryNav: "#333333",
      navTab: "#606060",
      hr: "#545454",
      icon: "#707070",
      fabBar: "#474747",
      contrastText: "#ffffff",
    },
    light: {
      main: "#000000",
      pageBg: "#fafafa",
      primaryNav: "#333333",
      navTab: "#606060",
      hr: "#545454",
      icon: "#707070",
      fabBar: "#474747",
      contrastText: "#000000",
    },
  },
});

root.render(
  <ThemeProvider theme={darkTheme} className="darkTheam">
    <AppInitializer>
      <TourProvider>
        <Provider store={store}>
          <Suspense
            fallback={
              <>
                <Loaders />
              </>
            }
          >
            <HashRouter>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<RouteGuard url={"dashboard"} />}
                />
                <Route exact path="/login" element={<AppRoute />}></Route>
                <Route
                  exact
                  path="/dashboard"
                  element={<DashboardPage />}
                ></Route>
                <Route exact path="/org-login" element={<OrgLogin />}></Route>
                <Route
                  exact
                  path="/landing-page"
                  element={<LandingPage />}
                ></Route>
                <Route
                  exact
                  path="/join"
                  element={<LoginMeetingLinks />}
                ></Route>
                <Route
                  exact
                  path="/i"
                  element={<RegisterViaInvitePersonal />}
                ></Route>
                <Route exact path="/s" element={<ContactAddSuccess />}></Route>
                <Route
                  exact
                  path="/dashboardG"
                  element={<DashboardPageGuest />}
                ></Route>
                {/* test rout end */}

                <Route
                  exact
                  path="/test-component"
                  element={<RteContainer />}
                ></Route>
                <Route
                  path="/sd"
                  element={
                    <ConfirmProvider>
                      <OpenSharedFileDrive />
                    </ConfirmProvider>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    webApp === "zoyel.health" ||
                    webApp === "localhost:3000" ||
                    webApp === "ahlan.work" ? (
                      <PrivacyPolicyZH />
                    ) : (
                      <PrivacyPolicy />
                    )
                  }
                />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditionsZoyel />}
                />
                <Route
                  path="/ticket"
                  element={<TicketForm ticketTypeProps={"Software Issue"} />}
                />
                <Route path="/kyc-verification" element={<KycVerification />} />
              </Routes>
            </HashRouter>
          </Suspense>

          {/* <ToggleMode /> */}
        </Provider>
      </TourProvider>
    </AppInitializer>

    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <LandscapeOriantation />
  </ThemeProvider>
);
