import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { BoxIso, IconoirProvider, NavArrowDown, Trash } from "iconoir-react";
import React, { useRef, useState } from "react";
import ReactTable from "react-table-6";
import { PoPreviewDialog } from "../task/view/component/Forms/PoPreviewDialog";
import { Bank, CloudUpload, Plus, Search } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import dayjs from "dayjs";
import TaskService from "../../services/TaskService";
import { GetLoginUserDetails } from "../../utils/Utils";
import DateUtils from "../../utils/DateUtils";
import { QuotationContainer } from "../task/view/component/Forms/QuotationContainer";
import { toast } from "react-toastify";
import { Add, Cancel, Edit, Gavel } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  MAX_FILE_SIZE_ADD_VENDOR_QUOTATION,
  VALID_FILE_EXTENSION_ADD_VENDOR_QUOTATION,
} from "../../constants/const_values";
import { convertFileToBase64_Split } from "../../utils/FileUtils";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export const VendorMenu = () => {
  const tokenDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const inputFileRef = useRef(null);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [vendorPreviewData, setVendorPreviewData] = useState(undefined);

  const [vendorTAC, setVendorTAC] = useState([]);
  const [showAddOtherCharges, setShowAddOtherCharges] = useState(false);

  const [otherChargesDescription, setOtherChargesDescription] = useState("");
  const [otherChargesValue, setOtherChargesValue] = useState(0);
  const [otherChargesList, setOtherChargesList] = useState([]);
  const [showSelectVendBankDtl, setSelectVendBankDtl] = useState(false);

  const [advancedChecked, setAdvanceChecked] = useState(false);
  const [showAddTAC, setShowAddTAC] = useState(false);

  const [expendedAccordion, setExpendedAccordion] = useState(true);

  const [rfpDetailsOfVendorList, setRfpDetailsOfVendorList] = useState([]);

  const [fromData, setFromDate] = useState(new Date());
  const [selectedIndent, setSelectedIndent] = useState(undefined);

  const [showItemDtlDialog, setShowItemDtlDialog] = useState(false);
  const [itemDetailsList, setItemDetailsList] = useState([]);
  const [refreshCount, setRefreshCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [showTermsAndCondDialog, setShowTermsAndCondDialog] = useState(false);
  const [isAcceptedTermsAndCond, setIsAcceptedTermsAndCond] = useState(false);

  const [termsAndConditionList, setTermsAndConditionList] = useState([]);
  const [showAddQuotation, setShowAddQuotation] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedIndentItems, setSelectedIndentItems] = useState([]);

  const [selectedItem, setSelectedItem] = useState(undefined);
  const [showAddTaxDialog, setShowAddTaxDialog] = useState(false);
  const [addTaxDetails, setAddTaxDetails] = useState({
    sgstPc: 0,
    cgstPc: 0,
    igstPc: 0,
    sgstAmt: 0,
    cgstAmt: 0,
    igstAmt: 0,
    taxPc: 0,
    taxAmt: 0,
  });

  const [addQuotationFile, setAddQuotationFile] = useState({
    fileName: "",
    fileData: "",
  });

  const [rfpVendorId, setRfpVendorId] = useState(null);

  const [toDate, setToDate] = useState(new Date());

  const getFutureDate = (years = 2) => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + years);
    return today;
  };

  const fetchRfpDetailsOfVendorData = () => {
    TaskService.getRfpDetailsofVendor(
      tokenDetails.userId,
      DateUtils.getDateInDDMMYYYY(fromData),
      DateUtils.getDateInDDMMYYYY(toDate)
    ).then((response) => {
      if (response.data) {
        setRfpDetailsOfVendorList(response.data);
      }
    });
  };

  const handleShowAddQuotationDialog = (value) => {
    //
    let indentItems = [];
    for (let i = 0; i < formData.serviceGoods.length; i++) {
      const element = formData.serviceGoods[i];
      element["quotationQty"] = element.indentQty;
      element["quotationRate"] = element.estimatedRate;
      element["quotationValue"] = element.estimatedValue;
      element["varianceSpeciation"] = "";
      element["variance"] = "";
      element["sgstPc"] = 0;
      element["cgstPc"] = 0;
      element["igstPc"] = 0;
      element["taxPc"] = 0;
      element["sgstAmt"] = 0;
      element["cgstAmt"] = 0;
      element["igstAmt"] = 0;
      element["taxAmt"] = 0;
      element["quotationUnitCost"] = 0;
      element["newQuotationValue"] = 0;
      indentItems.push(element);
    }
    // setShowAddQuotationDialog({
    //   status: true,
    //   vendorDetails: value,
    // });
    setSelectedIndentItems(indentItems);
  };

  const handleChangeFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file.size > 1024 * 1024 * MAX_FILE_SIZE_ADD_VENDOR_QUOTATION) {
      toast.error(
        `Max File Size Allowed ${MAX_FILE_SIZE_ADD_VENDOR_QUOTATION}MB`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (
      !VALID_FILE_EXTENSION_ADD_VENDOR_QUOTATION.includes(
        file.name.split(".").pop().toLowerCase()
      )
    ) {
      toast.error(`Invalid File Extension`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success(`${file.name} Attached`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setAddQuotationFile({
        fileName: file.name,
        fileData: await convertFileToBase64_Split(file),
      });
    }

    inputFileRef.current.value = null;
  };

  const handleShowAddTax = (item) => {
    setSelectedItem(item);
    setAddTaxDetails({
      sgstPc: item.sgstPc,
      cgstPc: item.cgstPc,
      igstPc: item.igstPc,
      taxPc: item.taxPc,
      sgstAmt: item.sgstAmt,
      cgstAmt: item.cgstAmt,
      igstAmt: item.igstAmt,
      taxAmt: item.taxAmt,
    });
    setShowAddTaxDialog(true);
  };

  const calculateQuotationValue = (item) => {
    let value = 0;
    value = item.quotationQty * item.quotationRate + item.taxAmt;
    item.quotationValue = value;
    return value ? value : 0;
  };

  const calculateSubTotal = (itemList) => {
    let val = 0;
    itemList.forEach((item) => {
      const tempVal = calculateQuotationValue(item);
      val = val + tempVal;
    });
    return val;
  };

  const calculateTotal = (qotationList, otherChargesList) => {
    let val = 0;
    val = val + calculateSubTotal(qotationList);
    otherChargesList.forEach((item) => {
      val = val + parseFloat(item.chargesAmount);
    });
    return val;
  };

  const handleClickShowItemDetails = (obj) => {
    const { indentId, invRfpVendorId } = obj;
    setSelectedIndent(obj);
    setOtherChargesList([]);
    setShowAddOtherCharges(false);

    TaskService.getExpenseApprovalRequest(indentId).then((response) => {
      if (response.data) {
        let indentItems = [];

        for (let i = 0; i < response.data.serviceGoods.length; i++) {
          const element = response.data.serviceGoods[i];

          element["quotationQty"] = element.indentQty;
          element["quotationRate"] = element.estimatedRate;
          element["quotationValue"] = element.estimatedValue;
          element["varianceSpeciation"] = "";
          element["variance"] = "";
          element["sgstPc"] = 0;
          element["cgstPc"] = 0;
          element["igstPc"] = 0;
          element["taxPc"] = 0;
          element["sgstAmt"] = 0;
          element["cgstAmt"] = 0;
          element["igstAmt"] = 0;
          element["taxAmt"] = 0;
          element["quotationUnitCost"] = 0;
          element["newQuotationValue"] = 0;

          indentItems.push(element);
        }
        setItemDetailsList(indentItems);
        setShowItemDtlDialog(true);

        setSelectedMethodId(0);
        setIsAcceptedTermsAndCond(false);
        setAddQuotationFile({ fileName: "", fileData: "" });
        setFormData(response.data);
        // setSelectedIndentItems(indentItems);
      }
    });

    TaskService.getTermsConditionsByRfpVendorId(invRfpVendorId).then(
      (response) => {
        if (response.data) {
          setTermsAndConditionList(response.data);
          // reset({ slNo: response.data.length + 1 });
        }
      }
    );
    setShowAddQuotation(true);
  };

  return (
    <>
      <Box className="taskContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
        <Grid container spacing={0} className="taskContainArea">
          <Grid item xs={12} className="pRelative">
            <div className="taskContainer">
              <div className="taskListHeader">{t("vendor_menu")}</div>

              <div className="taskListTopBtnGroup">
                <div className="taskListTopBtnLeft">
                  <div className="searchTasklist">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel
                          id="setCompletionDate"
                          className="setCompletionDate"
                        >
                          {t("from_date")}
                        </InputLabel>
                        <ReactDatePicker
                          locale={currentLocale}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          showIcon
                          toggleCalendarOnIconClick
                          labelId="setCompletionDate"
                          className="formDatePicker"
                          selected={fromData}
                          // minDate={new Date()}
                          // maxDate={getFutureDate()}
                          dateFormat="dd/MM/yyyy"
                          onChange={(newValue) => {
                            setFromDate(newValue);
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel
                          id="setCompletionDate"
                          className="setCompletionDate"
                        >
                          {t("to_date")}
                        </InputLabel>
                        <ReactDatePicker
                          locale={currentLocale}
                          toggleCalendarOnIconClick
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          showIcon
                          labelId="setCompletionDate"
                          className="formDatePicker"
                          selected={toDate}
                          minDate={fromData}
                          // maxDate={getFutureDate()}
                          dateFormat="dd/MM/yyyy"
                          onChange={(newValue) => setToDate(newValue)}
                        />
                      </FormControl>
                    </div>

                    <IconButton
                      className="seacrhIcon"
                      onClick={() => fetchRfpDetailsOfVendorData()}
                    >
                      <Search />
                    </IconButton>
                  </div>
                  {/* <IconButton className="filterTaskListBtn">
                        <Tune />
                      </IconButton> */}
                </div>
              </div>

              <div className="taskListTableContainer">
                <ReactTable
                  data={rfpDetailsOfVendorList}
                  columns={[
                    {
                      id: "indentNo",
                      Header: t("indent_no"),
                      accessor: "indentNo",
                      Cell: (props) => (
                        <span className="drvfileSize">
                          {props.original.indentNo}
                        </span>
                      ),
                      filterable: true,
                      filterMethod: (filter, row) =>
                        row._original.indentNo &&
                        row._original.indentNo
                          .toLowerCase()
                          .includes(filter.value.toLowerCase()),
                    },
                    {
                      id: "indentBy",
                      Header: t("indent_by"),
                      accessor: (row) => (
                        <div className="indtUsRDtl">
                          {row.indentBy && <span>{row.indentBy}</span>}
                          {row.indentByContactNo && (
                            <span>{row.indentByContactNo}</span>
                          )}
                        </div>
                      ),
                      filterable: true,
                      filterMethod: (filter, row) =>
                        (row._original.indentBy &&
                          row._original.indentBy
                            .toLowerCase()
                            .includes(filter.value.toLowerCase())) ||
                        (row._original.indentByContactNo &&
                          row._original.indentByContactNo
                            .toLowerCase()
                            .includes(filter.value.toLowerCase())),
                    },
                    {
                      id: "billTo",
                      Header: t("bill_to"),
                      accessor: (row) =>
                        row.billTo && (
                          <div className="indtUsRDtl">
                            <span>{row.billTo.split(",")[0]}</span>
                            <span className="comPAdd">{row.billTo}</span>
                          </div>
                        ),
                      filterable: true,
                      filterMethod: (filter, row) =>
                        row._original.billTo &&
                        row._original.billTo
                          .toLowerCase()
                          .startsWith(filter.value.toLowerCase()),
                    },
                    {
                      id: "shipTo",
                      Header: t("ship_to"),
                      accessor: (row) =>
                        row.shipTo && (
                          <div className="indtUsRDtl">
                            <span>{row.shipTo.split(",")[0]}</span>
                            <span className="comPAdd">{row.shipTo}</span>
                          </div>
                        ),
                      filterable: true,
                      filterMethod: (filter, row) =>
                        row._original.shipTo &&
                        row._original.shipTo
                          .toLowerCase()
                          .startsWith(filter.value.toLowerCase()),
                    },
                    {
                      id: "responseBy",
                      Header: t("respond_by"),
                      width: 140,
                      accessor: (row) => row.responseBy && row.responseBy,
                      filterable: true,
                      filterMethod: (filter, row) =>
                        row._original.responseBy &&
                        row._original.responseBy
                          .toLowerCase()
                          .includes(filter.value.toLowerCase()),
                    },
                    {
                      id: "status",
                      Header: t("quotation_status"),
                      width: 150,
                      accessor: (row) =>
                        row.status && (
                          <div
                            className={`qutStatus ${
                              row.status === "P" ? `qstatPending` : `qstatIssue`
                            }`}
                          >
                            {row.status === "P"
                              ? t("pending")
                              : t("rfp_submitted")}
                          </div>
                        ),
                      filterable: true,
                      filterMethod: (filter, row) =>
                        row._original.status &&
                        row._original.status
                          .toLowerCase()
                          .startsWith(filter.value.toLowerCase()),
                    },
                    {
                      id: "itemsDtl",
                      Header: t("items_dtl"),
                      filterable: false,
                      sortable: false,
                      minWidth: 80,
                      maxWidth: 80,
                      Cell: (props) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* {props.original.status === "P" && ( */}
                          <div className="itmDtl">
                            <BoxIso
                              onClick={() => {
                                if (props.original.status === "P") {
                                  handleClickShowItemDetails(props.original);
                                  setRfpVendorId(props.original.invRfpVendorId);
                                } else {
                                  TaskService.getQuotationGivenByVendorRfpId(
                                    props.original.invRfpVendorId
                                  ).then((response) => {
                                    setVendorPreviewData(response.data);
                                  });
                                }
                              }}
                            />
                          </div>
                          {/* )} */}
                        </div>
                      ),
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>

      {/* {showItemDtlDialog && (
        <Modal
          open={showItemDtlDialog}
          onClose={() => setShowItemDtlDialog(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="coustomModel"
        >
          <Box
            className="createEvent mobUserprofile responsiveModelAdmin"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: "50vw",
              width: "100%",
              maxWidth: "90vw",
              minHeight: "40vh",
              maxHeight: "100%",
              bgcolor: "background.paper",
              boxShadow: 24,
              overflow: "overlay",
              p: 2,
            }}
          >
           

            <div
              style={{ cursor: "pointer" }}
              className="closeDilog"
              onClick={() => setShowItemDtlDialog(false)}
            >
              <Cancel />
            </div>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="viewItemDtel">
                  <div className="viewItemHeading">
                    Item Details ({selectedIndent.indentNo})
                  </div>

                  <TableContainer component={Paper}>
                    <Table sx={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Item Name</TableCell>
                          <TableCell align="left">Category</TableCell>
                          <TableCell align="left">Description</TableCell>
                          <TableCell align="left">Variance</TableCell>
                          <TableCell align="left">
                            Variance Speciation
                          </TableCell>
                          <TableCell align="left">Indent Qty</TableCell>
                          <TableCell align="left">Not Available</TableCell>
                          <TableCell align="left">Quotation Qty *</TableCell>
                          <TableCell align="left">Quotation Rate *</TableCell>
                          <TableCell align="left">Tax</TableCell>
                          <TableCell align="left">Quotation Value</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        
                        {itemDetailsList.map((item) => {
                          return (
                            <TableRow key={item.itemId}>
                              <TableCell component="th" scope="row">
                                {item.itemName}
                              </TableCell>

                              <TableCell align="left">
                                {item.itemCategory}
                              </TableCell>

                              <TableCell align="left">
                                {item.itemDescription}
                              </TableCell>

                              <TableCell align="left">
                                <Tooltip title={item.variance}>
                                  <TextField
                                    size="small"
                                    label="Enter Variance"
                                    variant="filled"
                                    value={refreshCount > 0 && item.variance}
                                    onChange={(e) => {
                                      item.variance = e.target.value;
                                      setRefreshCount((r) => r + 1);
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>

                              <TableCell align="left">
                                <Tooltip title={item.varianceSpeciation}>
                                  <TextField
                                    size="small"
                                    label="Enter Variance Speciation"
                                    variant="filled"
                                    value={
                                      refreshCount > 0 &&
                                      item.varianceSpeciation
                                    }
                                    onChange={(e) => {
                                      item.varianceSpeciation = e.target.value;
                                      setRefreshCount((r) => r + 1);
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>

                              <TableCell align="left">
                                {item.indentQty}
                              </TableCell>

                              <TableCell align="center">
                                {refreshCount > 0 && (
                                  <Checkbox
                                    checked={!item.available}
                                    onChange={() => {
                                      item.available = !item.available;
                                      item.quotationQty = 0;
                                      item.quotationRate = 0;
                                      item.sgstPc = 0;
                                      item.cgstPc = 0;
                                      item.igstPc = 0;
                                      item.taxPc = 0;
                                      item.sgstAmt = 0;
                                      item.cgstAmt = 0;
                                      item.igstAmt = 0;
                                      item.taxAmt = 0;
                                      setRefreshCount((r) => r + 1);
                                    }}
                                  />
                                )}
                              </TableCell>

                              <TableCell align="left">
                                <TextField
                                  size="small"
                                  label="Enter Qty"
                                  variant="filled"
                                  type="number"
                                  inputProps={{ min: 0 }}
                                  disabled={!item.available}
                                  value={refreshCount > 0 && item.quotationQty}
                                  onChange={(e) => {
                                    item.quotationQty = parseInt(
                                      e.target.value
                                    );
                                    item.sgstPc = 0;
                                    item.cgstPc = 0;
                                    item.igstPc = 0;
                                    item.taxPc = 0;
                                    item.sgstAmt = 0;
                                    item.cgstAmt = 0;
                                    item.igstAmt = 0;
                                    item.taxAmt = 0;
                                    setRefreshCount((r) => r + 1);
                                  }}
                                />
                              </TableCell>

                              <TableCell align="left">
                                <TextField
                                  size="small"
                                  label="Quote Rate"
                                  variant="filled"
                                  type="number"
                                  inputProps={{ min: 0 }}
                                  disabled={!item.available}
                                  value={refreshCount > 0 && item.quotationRate}
                                  onChange={(e) => {
                                    item.quotationRate = e.target.value;
                                    item.sgstPc = 0;
                                    item.cgstPc = 0;
                                    item.igstPc = 0;
                                    item.taxPc = 0;
                                    item.sgstAmt = 0;
                                    item.cgstAmt = 0;
                                    item.igstAmt = 0;
                                    item.taxAmt = 0;
                                    setRefreshCount((r) => r + 1);
                                  }}
                                />
                              </TableCell>

                              <TableCell align="left">
                                <div
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  {item.taxAmt}
                                  <Tooltip title={item.taxAmt && item.taxAmt}>
                                    {item.taxAmt && item.taxAmt > 0 ? (
                                      <Edit
                                        cursor={"pointer"}
                                        onClick={() =>
                                          item.available &&
                                          handleShowAddTax(item)
                                        }
                                      />
                                    ) : (
                                      <Plus
                                        cursor={"pointer"}
                                        onClick={() =>
                                          item.available &&
                                          handleShowAddTax(item)
                                        }
                                      />
                                    )}
                                  </Tooltip>
                                </div>
                              </TableCell>

                              <TableCell align="left">
                                {refreshCount > 0 &&
                                  calculateQuotationValue(item)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        
                        <TableRow>
                          <TableCell colSpan={9}></TableCell>
                          <TableCell colSpan={1}>Sub Total</TableCell>
                          <TableCell colSpan={2}>
                            {calculateSubTotal(itemDetailsList)}
                          </TableCell>
                        </TableRow>

                        {otherChargesList &&
                          otherChargesList.length > 0 &&
                          otherChargesList.map((item) => {
                            return (
                              <TableRow>
                                <TableCell colSpan={9}></TableCell>
                                <TableCell colSpan={1}>
                                  {item.chargesLabel}
                                </TableCell>
                                <TableCell colSpan={1}>
                                  {item.chargesAmount}
                                  <span style={{ float: "right" }}>
                                    <Tooltip title="Remove">
                                      <Trash
                                        height={"1.2rem"}
                                        width={"1.2rem"}
                                        cursor={"pointer"}
                                        onClick={() => {
                                          let tmpList = otherChargesList.filter(
                                            (obj) =>
                                              JSON.stringify(obj) !==
                                              JSON.stringify(item)
                                          );
                                          setOtherChargesList(tmpList);
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        {showAddOtherCharges && (
                          <TableRow>
                            <TableCell colSpan={6}></TableCell>
                            <TableCell colSpan={2}>
                              <TextField
                                size="small"
                                label={"Charge Description"}
                                value={otherChargesDescription}
                                onChange={(e) => {
                                  setOtherChargesDescription(e.target.value);
                                }}
                              />
                            </TableCell>
                            <TableCell colSpan={2}>
                              <TextField
                                size="small"
                                type="number"
                                label={"Price"}
                                value={otherChargesValue}
                                onChange={(e) => {
                                  setOtherChargesValue(e.target.value);
                                }}
                              />
                            </TableCell>
                            <TableCell colSpan={1}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title="Add Charge">
                                  <Button
                                    onClick={() => {
                                      const temp = {
                                        chargesLabel: otherChargesDescription,
                                        chargesAmount:
                                          parseFloat(otherChargesValue),
                                      };
                                      if (
                                        otherChargesDescription.trim() !== ""
                                      ) {
                                        setOtherChargesList((prev) => [
                                          ...prev,
                                          temp,
                                        ]);
                                        setOtherChargesDescription("");
                                        setOtherChargesValue(0);
                                        setShowAddOtherCharges(false);
                                      }
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Tooltip>

                                <Tooltip title="Close">
                                  <Button
                                    onClick={() => {
                                      setShowAddOtherCharges(false);
                                    }}
                                  >
                                    <Cancel />
                                  </Button>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}

                        {!showAddOtherCharges && (
                          <TableRow>
                            <TableCell colSpan={10}></TableCell>
                            <TableCell colSpan={1}>
                              <Tooltip title="Add Other Charges">
                                <Button
                                  disabled={showAddOtherCharges}
                                  onClick={() => {
                                    setShowAddOtherCharges(true);
                                  }}
                                >
                                  <Add />
                                </Button>
                              </Tooltip>
                            </TableCell>
                            
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell colSpan={9}></TableCell>
                          <TableCell colSpan={1}>Total</TableCell>
                          <TableCell colSpan={1}>
                            {calculateTotal(itemDetailsList, otherChargesList)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="vendBtnGroup">
                  <div className="slctVendBankDtl">
                    <Button
                      className={`slctBnkBtn ${
                        selectedMethodId !== 0 ? "ActiveBtn" : ""
                      }`}
                      variant="outlined"
                      startIcon={<Bank />}
                      onClick={() => {
                        setSelectVendBankDtl(true);
                      }}
                    >
                      Banking Details
                    </Button>

                    <div className="seltedBankDtl"></div>
                  </div>

                  <FormControl
                    style={{
                      display: "inline",
                    }}
                  >
                    <Button
                      variant="outlined"
                      className={`${isAcceptedTermsAndCond ? "ActiveBtn" : ""}`}
                      onClick={() => setShowTermsAndCondDialog(true)}
                    >
                      <Gavel /> &nbsp; Terms & Conditions
                    </Button>

                    <Tooltip
                      title={
                        addQuotationFile.fileName === "" &&
                        addQuotationFile.fileData === "" ? (
                          <ul>
                            <li>{`Max File Size Allowed : ${MAX_FILE_SIZE_ADD_VENDOR_QUOTATION}MB`}</li>
                            <li>{`Allowed Extension : ${VALID_FILE_EXTENSION_ADD_VENDOR_QUOTATION.map(
                              (x) => ` ${x}`
                            )}`}</li>
                          </ul>
                        ) : (
                          <ul>
                            <li>{addQuotationFile.fileName}</li>
                          </ul>
                        )
                      }
                    >
                      <Button
                        className={`${
                          addQuotationFile.fileName !== "" ? "ActiveBtn" : ""
                        }`}
                        variant="outlined"
                        onClick={() => inputFileRef.current.click()}
                      >
                        <CloudUpload /> &nbsp; Upload *
                      </Button>
                    </Tooltip>

                    <input
                      type="file"
                      ref={inputFileRef}
                      hidden
                      onChange={handleChangeFileUpload}
                    />
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: "#858489",
                      "&:hover": {
                        backgroundColor: "#858489",
                      },
                    }}
                    onClick={() => setShowItemDtlDialog(false)}
                  >
                    {"Close"}
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: "#858489",
                      "&:hover": {
                        backgroundColor: "#858489",
                      },
                    }}
                    onClick={() => {
                      if (showAddOtherCharges) {
                        toast.error("Please save the additional charges", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        // toast.error("Advance Amount Cannot be Zero or Empty", { position: toast.POSITION.TOP_RIGHT })
                      } else {
                        // handelSubmitVendorQuotation()
                      }
                    }}
                  >
                    {"Submit"}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )} */}

      {showItemDtlDialog && (
        <>
          <QuotationContainer
            itemList={itemDetailsList}
            setItemList={setItemDetailsList}
            rfpVendorId={rfpVendorId}
            vendorUserId={tokenDetails.userId}
            handleSubmitData={() => {
              // fetchRFPVendorData();
              // fetchQuotationComparisonData();
              // setShowAddQuotationDialog({
              //   status: false,
              //   vendorDetails: undefined,
              // });
              console.log("submit data =");
              setShowItemDtlDialog(false);
            }}
            expenseId={formData.expenseId}
            closeModal={() => {
              setShowItemDtlDialog(false);
            }}
          />
        </>
      )}

      {vendorPreviewData && (
        <PoPreviewDialog
          data={vendorPreviewData}
          mode={"vendor"}
          // effectiveDate={DateUtils.getDateInDDMMYYYY(effectiveDate.$d)}
          closeDialog={() => setVendorPreviewData(undefined)}
        />
      )}
    </>
  );
};
