import React, { useContext, useState } from "react";
import { AddTabContext } from "./DashboardPage";
import { GetLoginUserDetails } from "../../../utils/Utils";
import AppUtils from "../../../utils/AppUtils";
import { v4 as uuidv4 } from "uuid";
import TaskTabContainer from "../../task/view/TaskTabContainer";
import { ListTask } from "react-bootstrap-icons";
import EmailContainerWrapper from "../../email/view/EmailContainerWrapper";
import {
  AdminPanelSettings,
  CalendarMonth,
  ChatBubbleOutline,
  EmailOutlined,
  Event,
  Help,
  PlaylistAddOutlined,
  StorageOutlined,
  Support,
  VideocamOutlined,
} from "@mui/icons-material";
import ChatContainer from "../../chat/view/ChatContainer";
import SchedulerContainer from "../../scheduler/view/SchedulerContainer";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "../../scheduler/view/component/meetings/view/MeetingsTabContainer";
import DriveContainer from "../../drive/view/DriveContainer";
import CreateTaskContainer from "../../task/view/CreateTaskContainer";
import Admin from "../../admin/view/Admin";
import { VendorMenu } from "../../vendor/VendorMenu";
import SupportTicketView from "../../support/view/SupportTicketView";
import { useTranslation } from "react-i18next";
import { webApp } from "../../../constants/url_provider";
import Joyride from "react-joyride";
import { TourContext } from "../../../common/TourProvider";
// import "./styles.css";

function NewTabContent({ tabIndex }) {
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const { t } = useTranslation();
  const { addTab, getAllTabs } = useContext(AddTabContext);
  const loginUser = GetLoginUserDetails();
  const loginUserDetail = GetLoginUserDetails();

  return (
    <>
      {" "}
      <Joyride
        steps={[
          {
            target: ".tabConTentStepOne",
            content: "Please Select from Here",
            disableBeacon: true,
          },
        ]}
        run={run["NewTabAsDc"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
        locale={{
          last: "Next",
        }}
      />
      <div className="bottomMnuPopover">
        <div class="bottomMnuPopoverContainer tabConTentStepOne">
          <div class="apps">
            <h2>{t("Apps")}</h2>
            <ul>
              {!AppUtils.isPersonal(loginUser) && (
                <li
                  onClick={() => {
                    addTab({
                      key: uuidv4(),
                      title: t("task"),
                      content: <TaskTabContainer />,
                      isDraggable: true,
                      // //type: "New",
                      tabIndex: getAllTabs().length,
                    });
                    if (run["NewTabAsDc"] === true) {
                      setRun({
                        TaskDetailsAppAs: true,
                      });
                    }
                  }}
                >
                  <ListTask className="btmMenuAppsIcon" /> {t("task")}
                </li>
              )}
              {/* <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Email",
                    content: <EmailContainerWrapper />,
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                }}
              >
                <EmailOutlined className="btmMenuAppsIcon" /> Email
              </li> */}
              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: t("chat"),
                    content: <ChatContainer />,
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                  if (run["NewTabAsDc"] === true) {
                    setRun({
                      ChatDetailsAppCT: true,
                    });
                  }
                }}
              >
                <ChatBubbleOutline className="btmMenuAppsIcon" /> {t("Chat")}
              </li>

              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: t("scheduler"),
                    content: <SchedulerContainer />,
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                  if (run["NewTabAsDc"] === true) {
                    setRun({
                      SchedulerDetailsAppSC: true,
                    });
                  }
                }}
              >
                <CalendarMonth className="btmMenuAppsIcon" /> {t("scheduler")}
              </li>
              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: t("meeting_room"),
                    content: (
                      <ConfirmProvider>
                        <MeetingsTabContainer />
                      </ConfirmProvider>
                    ),
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                }}
              >
                <VideocamOutlined className="btmMenuAppsIcon" />{" "}
                {t("meeting_room")}
              </li>
              <li
                onClick={() => {
                  const tabkey = uuidv4();
                  console.log("key", tabkey);
                  addTab({
                    key: tabkey,
                    title: t("drive"),
                    content: <DriveContainer addTab={addTab} tabkey={tabkey} />,
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                    driveKey: tabkey,
                  });
                  if (run["NewTabAsDc"] === true) {
                    setRun({
                      DriveDetailsAppDV: true,
                    });
                  }
                }}
              >
                <StorageOutlined className="btmMenuAppsIcon" /> {t("drive")}
              </li>

              {!AppUtils.isPersonal(loginUser) && "zoyel.one" !== webApp && (
                <li
                  onClick={() => {
                    const tabkey = uuidv4();
                    console.log("key", tabkey);
                    addTab({
                      key: tabkey,
                      title: t("support_dashboard"),
                      content: <SupportTicketView />,
                      isDraggable: true,
                      tabIndex: getAllTabs().length,
                      driveKey: tabkey,
                    });
                  }}
                >
                  <Help className="btmMenuAppsIcon" /> {t("support_dashboard")}
                </li>
              )}

              {loginUserDetail.userType === "ADMIN" &&
                !AppUtils.isPersonal(loginUser) && (
                  <li
                    onClick={() => {
                      const tabkey = uuidv4();
                      console.log("key", tabkey);
                      addTab({
                        key: tabkey,
                        title: t("Admin"),
                        content: <Admin />,
                        isDraggable: true,
                        //type: "New",
                        tabIndex: getAllTabs().length,
                        driveKey: tabkey,
                      });
                      if (run["NewTabAsDc"] === true) {
                        setRun({
                          AdminDetailsAppAs: true,
                        });
                      }
                    }}
                  >
                    <AdminPanelSettings className="btmMenuAppsIcon" />{" "}
                    {t("Admin")}
                  </li>
                )}

              {loginUserDetail.userType === "EXTERNAL" &&
                loginUserDetail.externalCategory === "vendor" && (
                  <li
                    onClick={() => {
                      const tabkey = uuidv4();
                      console.log("key", tabkey);
                      addTab({
                        key: tabkey,
                        title: t("vendor_menu"),
                        content: <VendorMenu />,
                        isDraggable: true,
                        //type: "New",
                        tabIndex: getAllTabs().length,
                        driveKey: tabkey,
                      });
                    }}
                  >
                    <StorageOutlined className="btmMenuAppsIcon" />{" "}
                    {t("vendor_menu")}
                  </li>
                )}
            </ul>
          </div>
          <div class="quick-actions">
            <h2>{t("quick_actions")}</h2>
            <ul>
              {!AppUtils.isPersonal(loginUser) && (
                <li
                  onClick={() => {
                    addTab({
                      key: uuidv4(),
                      title: t("create_task"),
                      content: <CreateTaskContainer />,
                      isDraggable: true,
                      //type: "New",
                      tabIndex: getAllTabs().length,
                    });
                  }}
                >
                  <PlaylistAddOutlined className="btmMenuAppsIcon" />
                  {t("create_a_new_task")}
                </li>
              )}
              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: t("scheduler"),
                    content: (
                      <SchedulerContainer
                        bottomShortcutDto={{ eventType: "Event" }}
                      />
                    ),
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                }}
              >
                <Event className="btmMenuAppsIcon" /> {t("create_a_new_event")}
              </li>
              <li
                onClick={() => {
                  const key = uuidv4();
                  addTab({
                    key: key,
                    title: t("instant_meeting"),
                    content: (
                      <ConfirmProvider>
                        <MeetingsTabContainer
                          startInstantMeeting={true}
                          tabKey={key}
                        />
                      </ConfirmProvider>
                    ),
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                }}
              >
                <VideocamOutlined className="btmMenuAppsIcon" />
                {t("start_instant_meeting")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewTabContent;
