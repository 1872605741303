import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { AttachFile } from "@mui/icons-material";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

function DaTaForm({ DATA_data, setDATAdata, budgetHeadList }) {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [budgetHead, setBudgetHead] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [advanceTaken, setAdvanceTaken] = useState("");
  const claimedOnActualRate = useRef(false);
  const claimedOnActualBasis = useRef(false);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const docRef = useRef();

  const { register, watch, setValue, control } = useForm({
    defaultValues: {
      transportCost: 0,
      accomodationCost: 0,
      mealCost: 0,
      otherExpense: 0,
    },
  });
  const {
    transportCost,
    accomodationCost,
    mealCost,
    otherExpenses,
    advanceReceived,
  } = watch();

  useEffect(() => {
    setValue("transportCost", 0);
    setValue("accomodationCost", 0);
    setValue("mealCost", 0);
    setValue("otherExpenses", 0);
  }, []);

  const handleChangeClaimOnActualBasis = (e) => {
    const temp = { ...DATA_data };
    temp.claimOnActalBasis = e.target.checked;
    setDATAdata(temp);
  };
  const handleChangeClaimOnActualRate = (e) => {
    const temp = { ...DATA_data };
    temp.claimOnActalRate = e.target.checked;
    setDATAdata(temp);
  };

  const getNumberFromString = (numString) => {
    try {
      if (numString === 0) {
        return 0;
      }
      const stringList = numString.split(",");
      let num = "";
      stringList.forEach((element) => {
        num += element;
      });
      return parseInt(num);
    } catch (error) {
      // ;
    }
  };

  useEffect(() => {
    console.log("data form is =", DATA_data);
    if (DATA_data) {
      const temp = { ...DATA_data };
      temp.startDate = startDate;
      temp.endDate = endDate;
      temp.transportCost = transportCost;
      temp.accomodationCost = accomodationCost;
      temp.mealCost = mealCost;
      temp.otherExpenses = otherExpenses;
      temp.claimOnActalBasis = claimedOnActualBasis.current;
      temp.claimOnActalRate = claimedOnActualRate.current;
      temp.advanceReceived = advanceReceived;
      temp.budgetHead = budgetHead;
      temp.documents = documents;
      temp.totalExpenditure = calculateTotalExpenditure();
      setDATAdata(temp);
    } else {
      setDATAdata({});
    }
  }, [
    startDate,
    endDate,
    transportCost,
    accomodationCost,
    mealCost,
    otherExpenses,
    budgetHead,
    documents,
    claimedOnActualBasis.current,
    claimedOnActualRate.current,
    advanceReceived,
  ]);

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  const calculateTotalExpenditure = () => {
    const trCost = transportCost || 0;
    const accCost = accomodationCost || 0;
    const meCost = mealCost || 0;
    const othrExpense = otherExpenses || 0;
    const advanceAmount = advanceReceived || 0;
    const totalExpenditure =
      trCost + accCost + meCost + othrExpense - advanceAmount;
    console.log(
      `trCost = ${trCost} 
       accCost= ${accCost} 
       meCost= ${meCost} 
       othrExpense=${othrExpense} 
       advanceAmount=${advanceAmount} 
       totalExpenditure=${totalExpenditure}\n`
    );
    return totalExpenditure;
  };

  const totalExpenditure = useMemo(() => {
    const totalExpenditure = calculateTotalExpenditure();
    const temp = { ...DATA_data };
    temp.totalExpenditure = totalExpenditure;
    setDATAdata(temp);
    return totalExpenditure;
  }, [
    transportCost,
    accomodationCost,
    mealCost,
    otherExpenses,
    advanceReceived,
  ]);

  // const calculateTotalExpenditure = () => {
  //   return (
  //     parseInt(transportCost) +
  //     parseInt(accomodationCost) +
  //     parseInt(mealCost) +
  //     parseInt(otherExpenses)
  //   );
  // };

  return (
    <>
      <div className="formElementGroup flex-wrap">
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="setCompletionDate" className="setCompletionDate">
              {t("travelStartDate")}
            </InputLabel>
            <ReactDatePicker
              locale={currentLocale}
              toggleCalendarOnIconClick
              showYearDropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              showMonthDropdown
              showIcon
              labelId="setCompletionDate"
              className="formDatePicker"
              maxDate={new Date()}
              dateFormat="dd-MMM-yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </FormControl>
        </div>
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="setCompletionDate" className="setCompletionDate">
              {t("travelEndDate")}
            </InputLabel>
            <ReactDatePicker
              locale={currentLocale}
              toggleCalendarOnIconClick
              showYearDropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              showMonthDropdown
              showIcon
              labelId="setCompletionDate"
              className="formDatePicker"
              maxDate={new Date()}
              dateFormat="dd-MMM-yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </FormControl>
        </div>
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="selectLedgerHead">{t("budgetHead")}</InputLabel>
            <Select
              className="formInputField"
              variant="outlined"
              labelId="selectLedgerHead"
              id="select-LedgerHead"
              label={t("budgetHead")}
              value={budgetHead}
              onChange={(e) => {
                setBudgetHead(e.target.value);
              }}
            >
              {budgetHeadList.length > 0 &&
                budgetHeadList.map((itemName, index) => (
                  <MenuItem value={itemName} key={itemName.head + "_" + index}>
                    {itemName.head}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="formElementGroup flex-wrap">
        <div className="formElement width13">
          <FormControl className="formControl">
            <TextField
              label={t("transportCost")}
              name="numberformat"
              id="Transport_Cost"
              variant="outlined"
              defaultValue={0}
              className="formTextFieldArea"
              {...register("transportCost", { valueAsNumber: true })}
            />
          </FormControl>
        </div>
        <div className="formElement width13">
          <FormControl className="formControl">
            <TextField
              label={t("accomodationCost")}
              name="numberformat"
              id="Accomodation_Cost"
              defaultValue={0}
              variant="outlined"
              className="formTextFieldArea"
              {...register("accomodationCost", { valueAsNumber: true })}
            />
          </FormControl>
        </div>

        <div className="formElement width13">
          <FormControl className="formControl">
            <TextField
              label={t("mealCost")}
              name="numberformat"
              id="Meal_Cost"
              defaultValue={0}
              variant="outlined"
              className="formTextFieldArea"
              {...register("mealCost", { valueAsNumber: true })}
            />
          </FormControl>
        </div>

        <div className="formElement width13">
          <FormControl className="formControl">
            <TextField
              label={t("otherExpenses")}
              name="numberformat"
              defaultValue={0}
              variant="outlined"
              className="formTextFieldArea"
              {...register("otherExpenses", { valueAsNumber: true })}
            />
          </FormControl>
        </div>

        <div className="formElement width13">
          <FormControl className="formControl">
            <TextField
              label={t("advanceReceived")}
              name="numberformat"
              id="total_Expenses"
              defaultValue={0}
              variant="outlined"
              className="formTextFieldArea"
              {...register("advanceReceived", { valueAsNumber: true })}
              onChange={(e) => {
                const val = e.target.value;
                const numericRegex = /^[0-9]*$/;

                if (numericRegex.test(val)) {
                  const tempVal = parseFloat(val, 10);
                  if (val !== "" && !isNaN(tempVal)) {
                    const trCost = transportCost || 0;
                    const accCost = accomodationCost || 0;
                    const meCost = mealCost || 0;
                    const othrExpense = otherExpenses || 0;
                    if (tempVal > trCost + accCost + meCost + othrExpense) {
                      toast.error(
                        t("advance_amount_cannot_be_more_than_total_amount")
                      );
                    }
                    setValue("advanceReceived", tempVal);
                    setAdvanceTaken(val);
                  }
                } else {
                  if (advanceTaken === "") {
                    setAdvanceTaken("0");
                    setValue("advanceReceived", 0);
                  } else {
                    setValue("advanceReceived", advanceTaken);
                  }
                }
              }}
            />
          </FormControl>
        </div>

        <div className="formElement width13">
          <FormControl className="formControl">
            <TextField
              disabled
              label={t("reimbursementAmount")}
              name="numberformat"
              id="total_Expenses"
              variant="outlined"
              className="formTextFieldArea"
              value={totalExpenditure}
              // onChange={e=>{
              //   conso
              // }}
            />
          </FormControl>
        </div>
      </div>

      <div className="formElementGroup">
        <div className="formElement">
          <FormControl className="formControl">
            <FormGroup className="formRadioGroup">
              <FormControlLabel
                className="formRadioField"
                control={
                  <Checkbox
                    value={claimedOnActualBasis.current}
                    onChange={handleChangeClaimOnActualBasis}
                  />
                }
                label={t("claimedOnActualBasis")}
              />
            </FormGroup>
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <FormGroup className="formRadioGroup">
              <FormControlLabel
                className="formRadioField"
                control={
                  <Checkbox
                    value={claimedOnActualRate.current}
                    onChange={handleChangeClaimOnActualRate}
                  />
                }
                label={t("claimedOnActualRate")}
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>

      <div className="formElementGroup">
        <div className="formBtnElement ">
          {/* <div className={`formBtnElement`}> */}
          <Button
            variant="outlined"
            startIcon={<AttachFile />}
            className={`comBtnOutline  ${
              documents && documents.length > 0 ? "activeStatus" : ""
            }`}
            onClick={(e) => {
              docRef.current.click();
            }}
          >
            {t("attachFile")}
          </Button>
          <input
            ref={docRef}
            multiple
            style={{ display: "none" }}
            type="file"
            onChange={async (e) => {
              const files = e.target.files;
              console.log("files are", files);
              const results = [];

              for (const file of files) {
                try {
                  const processedData = await getFileDataObj(file);
                  results.push(processedData);
                } catch (error) {
                  console.error("Error processing file:", error);
                  results.push(null);
                }
              }

              console.log("processed files are", results);
              setDocuments(results);
            }}
          />
        </div>
        <div className="singleUpldfile">
          <ul class="elmListInfo custlist">
            {documents.map((document, index) => (
              <li key={index}>
                <span className="rolsNm">{document.fileName}</span>
                <IconButton
                  className="rmvBtn"
                  onClick={() => {
                    // deleteExpenditure(item);
                    const temp = [...documents];
                    temp.splice(index, 1);
                    setDocuments(temp);
                  }}
                  // onClick={()=>handleDeleteCapitalExpListItem(item)}
                >
                  <Trash />
                </IconButton>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <div class="elementListGrp">
        <div class="elementHeding">File Added</div>
        <ul class="elmListInfo">
          <li>
            <span className="rolsNm">Chair Person</span>
            <IconButton className="rmvBtn">
              <Trash />
            </IconButton>
          </li>
          <li>
            <span className="rolsNm">Treasurer</span>
            <IconButton className="rmvBtn">
              <Trash />
            </IconButton>
          </li>
          <li>
            <span className="rolsNm">Secretary</span>
            <IconButton className="rmvBtn">
              <Trash />
            </IconButton>
          </li>
          <li>
            <span className="rolsNm">Members</span>
            <IconButton className="rmvBtn">
              <Trash />
            </IconButton>
          </li>
        </ul>
      </div> */}
    </>
  );
}

export default DaTaForm;
