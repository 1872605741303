import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function DaTaFormPreview({ DATA_data }) {
  const { t } = useTranslation();
  console.log("data data=", DATA_data);

  const totalExpenditure = useMemo(() => {
    const trCost =
      DATA_data.transportCost === "" ? 0 : parseInt(DATA_data.transportCost);
    const accCost =
      DATA_data.accomodationCost === ""
        ? 0
        : parseInt(DATA_data.accomodationCost);
    const meCost = DATA_data.mealCost === "" ? 0 : parseInt(DATA_data.mealCost);
    const othrExpense =
      DATA_data.otherExpenses === "" ? 0 : parseInt(DATA_data.otherExpenses);

    return trCost + accCost + meCost + othrExpense;
  }, [DATA_data]);

  return (
    <>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">{t("budgetHead")}</div>
        <div class="taskContractNm">{DATA_data.budgetHead.head}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">{t("transportCost")}</div>
        <div class="taskContractNm">{DATA_data.transportCost}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">{t("accomodationCost")}</div>
        <div class="taskContractNm">{DATA_data.accomodationCost}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">{t("mealCost")}</div>
        <div class="taskContractNm">{DATA_data.mealCost}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">{t("otherExpenses")}</div>
        <div class="taskContractNm">{DATA_data.otherExpenses}</div>
      </div>

      <div class="taskElementGrpRow">
        <div class="tskElemHeding">{t("totalExpenses")}</div>
        <div class="taskContractNm">{DATA_data.totalExpenditure}</div>
      </div>

      <div class="taskElementGrpRow">
        <div class="tskElemHeding">{t("advanceReceived")}</div>
        <div class="taskContractNm">{parseInt(DATA_data.advanceReceived)}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">{t("claimedOnActualBasis")}</div>
        <div class="taskContractNm">
          {DATA_data.claimOnActalBasis ? t("yes") : t("no")}
        </div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">{t("claimedOnActualRate")}</div>
        <div class="taskContractNm">
          {DATA_data.claimOnActalRate ? t("yes") : t("no")}
        </div>
      </div>
    </>
  );
}

export default DaTaFormPreview;
