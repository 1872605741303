import React from "react";
import DateUtils, {
  monthNames,
  monthsOfYear,
  weekDays,
} from "../../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";

export default function AgendaView({
  dateWiseTask,
  events,
  currentWeek,
  holidayList,
  leaveList,
  defaultWorkWeek,
  setSelectedEvent,
}) {
  const [displayData, setDisplayData] = React.useState(new Map());
  const { t, i18n } = useTranslation();

  const getMainClassNameDay = (dateToDisplay) => {
    let dateToDisplayStr = DateUtils.getDateInYYYYMMDD(dateToDisplay);
    let className = "e-agenda-cells e-date-column";

    let holidays = holidayList.map(({ id }) => id);

    let leave = leaveList.map(({ id }) => id);

    let workingDays = defaultWorkWeek.map(({ day }) => day);

    let splDayName = [];

    if (holidays.indexOf(dateToDisplayStr) !== -1) {
      className = className + " bgHoliday";
      let holidayName = holidayList.filter(
        (holiday) => holiday.id === dateToDisplayStr
      );
      splDayName = holidayName;
    } else if (leave.indexOf(dateToDisplayStr) !== -1) {
      className = className + " bgOnLeav";
      let leaveName = leaveList.filter(
        (leave) => leave.id === dateToDisplayStr
      );
      splDayName = leaveName;
    } else if (workingDays.indexOf(weekDays[dateToDisplay.getDay()]) === -1) {
      className = className + " bgWekOff";
    } else {
    }
    return className;
  };

  React.useEffect(() => {
    console.log("agenda week", currentWeek);
    const displayDataTemp = new Map();
    events.forEach((eventDto) => {
      let eventDate = DateUtils.getDateInYYYYMMDD(new Date(eventDto.start));
      let isDateInRange = false;
      currentWeek.forEach((weekDay) => {
        if (DateUtils.getDateInYYYYMMDD(weekDay) === eventDate) {
          isDateInRange = true;
        }
      });
      if (isDateInRange) {
        if (displayDataTemp.has(eventDate)) {
          let eventList = displayDataTemp.get(eventDate).events;
          eventList.push(eventDto);
          displayDataTemp.get(eventDate).events = eventList;
        } else {
          let tempEventArray = [];
          tempEventArray.push(eventDto);
          displayDataTemp.set(eventDate, {
            events: tempEventArray,
            tasks: [],
          });
        }
      }
    });
    // dateWiseTask.forEach((taskDto) => {
    //   let taskDate = DateUtils.getDateInYYYYMMDD(new Date(taskDto.taskDate));
    //   let isDateInRange = false;
    //   currentWeek.forEach((weekDay) => {
    //     if (DateUtils.getDateInYYYYMMDD(weekDay) === taskDate) {
    //       isDateInRange = true;
    //     }
    //   });
    //   if (isDateInRange) {
    //     if (displayDataTemp.has(taskDate)) {
    //       let taskList = displayDataTemp.get(taskDate).tasks;
    //       taskList.push(taskDto);
    //       displayDataTemp.get(taskDate).tasks = taskList;
    //     } else {
    //       let tempTaskArray = [];
    //       tempTaskArray.push(taskDto);
    //       displayDataTemp.set(taskDate, {
    //         events: [],
    //         tasks: tempTaskArray,
    //       });
    //     }
    //   }
    // });

    var sortedDisplayData = new Map(
      [...displayDataTemp.entries()].sort((first, second) => {
        return DateUtils.compare(
          DateUtils.convert(first[0]),
          DateUtils.convert(second[0])
        );
      })
    );
    setDisplayData(sortedDisplayData);
  }, [events, currentWeek]);

  const render = () => {
    console.log("render", displayData);
    const items = [];
    displayData.forEach((value, key, index) =>
      items.push(
        <tr>
          <td className={`${getMainClassNameDay(new Date(key))}`}>
            <div className="e-day-date-header">
              <div className="e-day-date-Group">
                <div className="agenDate">
                  <span
                    className={`agenDT ${
                      DateUtils.isToday(new Date(key)) ? "todayDD" : ""
                    }`}
                  >
                    {" "}
                    {new Date(key).getDate()}
                  </span>
                </div>
                <div className="date-text">
                  <div className="days-text">
                    {t(monthsOfYear[new Date(key).getMonth()])} -{" "}
                    {new Date(key).getFullYear()}
                  </div>
                </div>
                <div className="dayName">
                  {t(weekDays[new Date(key).getDay()])}
                </div>
              </div>
            </div>
          </td>
          <td className="e-day-border">
            <ul className="e-agenda-parent e-ul e-agenda-view">
              {value.events.map((eventDto) => {
                return (
                  <>
                    <li className="e-agenda-item e-agenda-view e-level-1">
                      <div
                        className="e-appointment"
                        // style={{ cursor: "none !important" }}
                        onClick={() => setSelectedEvent(eventDto)}
                      >
                        <div
                          className={`${
                            eventDto.source === "google"
                              ? "gCalendar"
                              : "myCalendar"
                          } `}
                        ></div>
                        <div className="e-subject-wrap">
                          <div className="e-subject">{eventDto.title}</div>
                        </div>
                        <div
                          className="e-date-time"
                          // onClick={() => setSelectedEvent(eventDto)}
                        >
                          {DateUtils.displayDateMonthViewToolTip(eventDto)}
                        </div>
                      </div>
                    </li>
                  </>
                );
              })}
              {/* {value.tasks.map((taskDto, index) => {
                return (
                  <>
                    <li className="e-agenda-item e-agenda-view e-level-1">
                      <div className="e-appointment-tasks">
                        <div className="e-subject-wrap">
                          <div className="e-subject">{taskDto.taskName}</div>
                          <div className="e-icons e-recurrence-icon"></div>
                        </div>
                        <div className="e-date-time">
                          {DateUtils.getDateInYYYYMMDD(
                            new Date(taskDto.createDate)
                          )}
                          {" - "}
                          {DateUtils.getDateInYYYYMMDD(
                            new Date(taskDto.completionDate)
                          )}
                        </div>
                      </div>
                    </li>
                  </>
                );
              })} */}
            </ul>
          </td>
        </tr>
      )
    );

    return <>{items}</>;
  };

  return (
    <>
      <div className="agendaViewContainer">
        <div className="scheduler-wrapper">
          <div className="scheduler-header">
            <div className="overview-content">
              <div className="left-panel">
                <div className="overview-scheduler">
                  <div className="e-control e-schedule e-lib schedule-overview e-keyboard">
                    <div className="e-table-container">
                      <div className="e-table-wrap e-agenda-view">
                        <table
                          className="e-schedule-table e-outer-table"
                          role="presentation"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <div className="e-content-wrap AgendaviewHeight">
                                  <table
                                    className="e-schedule-table e-content-table"
                                    role="grid"
                                    aria-label="Agenda"
                                  >
                                    <tbody>
                                      {render()}
                                      {/* <tr>
                                        <td className="e-agenda-cells e-date-column">
                                          <div className="e-day-date-header">
                                            <div className="e-day-date-Group">
                                              <div className="agenDate">
                                                <span className="agenDT">
                                                  4
                                                </span>
                                              </div>
                                              <div className="date-text">
                                                <div className="days-text">
                                                  Feb - 2024
                                                </div>
                                              </div>
                                              <div className="dayName">
                                                Monday
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-day-border">
                                          <ul className="e-agenda-parent e-ul e-agenda-view">
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="gCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  10:00 AM - 10:30 AM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    UX Discussion
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  3:00 PM - 3:30 PM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Zoyel Development
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  6:00 PM - 7:00 PM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Instant Meeting
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  7:06 PM - 7:36 PM
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-agenda-cells e-date-column">
                                          <div className="e-day-date-header">
                                            <div className="e-day-date-Group">
                                              <div className="agenDate">
                                                <span className="agenDT todayDD">
                                                  15
                                                </span>
                                              </div>
                                              <div className="date-text">
                                                <div className="days-text">
                                                  Feb - 2024
                                                </div>
                                              </div>
                                              <div className="dayName">
                                                Monday
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-day-border">
                                          <ul className="e-agenda-parent e-ul e-agenda-view">
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="gCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  10:00 AM - 10:30 AM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    UX Discussion
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  3:00 PM - 3:30 PM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="gCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Zoyel Development
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  6:00 PM - 7:00 PM
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-agenda-cells e-date-column bgOnLeav">
                                          <div className="e-day-date-header">
                                            <div className="e-day-date-Group">
                                              <div className="agenDate">
                                                <span className="agenDT">
                                                  6
                                                </span>
                                              </div>
                                              <div className="date-text">
                                                <div className="days-text">
                                                  Feb - 2024
                                                </div>
                                              </div>
                                              <div className="dayName">
                                                Monday
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-day-border">
                                          <ul className="e-agenda-parent e-ul e-agenda-view">
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  10:00 AM - 10:30 AM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    UX Discussion
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  4:00 PM - 4:30 PM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="gCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Zoyel Development
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  6:00 PM - 7:00 PM
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-agenda-cells e-date-column">
                                          <div className="e-day-date-header">
                                            <div className="e-day-date-Group">
                                              <div className="agenDate">
                                                <span className="agenDT">
                                                  7
                                                </span>
                                              </div>
                                              <div className="date-text">
                                                <div className="days-text">
                                                  Feb - 2024
                                                </div>
                                              </div>
                                              <div className="dayName">
                                                Monday
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-day-border">
                                          <ul className="e-agenda-parent e-ul e-agenda-view">
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  10:00 AM - 10:30 AM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="gCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Zoyel Development
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  6:00 PM - 7:00 PM
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-agenda-cells e-date-column bgWekOff">
                                          <div className="e-day-date-header">
                                            <div className="e-day-date-Group">
                                              <div className="agenDate">
                                                <span className="agenDT">
                                                  8
                                                </span>
                                              </div>
                                              <div className="date-text">
                                                <div className="days-text">
                                                  Feb - 2024
                                                </div>
                                              </div>
                                              <div className="dayName">
                                                Monday
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-day-border">
                                          <ul className="e-agenda-parent e-ul e-agenda-view">
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  10:00 AM - 10:30 AM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="gCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    UX Discussion
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  3:01 PM - 3:31 PM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Zoyel Development
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  6:00 PM - 7:00 PM
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-agenda-cells e-date-column">
                                          <div className="e-day-date-header">
                                            <div className="e-day-date-Group">
                                              <div className="agenDate">
                                                <span className="agenDT">
                                                  9
                                                </span>
                                              </div>
                                              <div className="date-text">
                                                <div className="days-text">
                                                  Feb - 2024
                                                </div>
                                              </div>
                                              <div className="dayName">
                                                Monday
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-day-border">
                                          <ul className="e-agenda-parent e-ul e-agenda-view">
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="gCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Morning Meeting (Applicable
                                                    for Work From Home Employee)
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  10:00 AM - 10:30 AM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Instant Meeting
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  4:22 PM - 4:52 PM
                                                </div>
                                              </div>
                                            </li>
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Zoyel Development
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  6:00 PM - 7:00 PM
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="e-agenda-cells e-date-column bgHoliday">
                                          <div className="e-day-date-header">
                                            <div>
                                              <div className="agenDate">
                                                <span className="agenDT">
                                                  10
                                                </span>
                                              </div>
                                              <div className="date-text">
                                                <div className="days-text">
                                                  Feb - 2024
                                                </div>
                                              </div>
                                              <div className="dayName">
                                                Monday
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="e-day-border">
                                          <ul className="e-agenda-parent e-ul e-agenda-view">
                                            <li className="e-agenda-item e-agenda-view e-level-1">
                                              <div className="e-appointment">
                                                <div className="myCalendar"></div>
                                                <div className="e-subject-wrap">
                                                  <div className="e-subject">
                                                    Instant Meeting
                                                  </div>
                                                </div>
                                                <div className="e-date-time">
                                                  10:44 AM - 11:14 AM
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr> */}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
