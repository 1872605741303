import { useEffect, useState, useRef } from "react";
import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ImageRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
} from "lexical";

import { $createImageNode, ImageNode, ImagePayload } from "../nodes/ImageNode";
import ZoyelRTEServices from "../../../services/ZoyelRTEServices";
import { GetLoginUserDetails } from "../../../utils/Utils";
import SecureIndexedDB from "../../../utils/IndexedDB";

// export type InsertImagePayload = Readonly<ImagePayload>;

export const INSERT_IMAGE_COMMAND = createCommand("INSERT_IMAGE_COMMAND");

const ImagePlugin = async ({ onChange, setImageList, imageList }) => {
  let jwt = await SecureIndexedDB.getItem("token");
  const userDetails = GetLoginUserDetails();
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload);
          $insertNodes([imageNode]);
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  const imageRef = useRef();

  const handleUploadOrgLogo = async (e) => {
    console.log(e);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    console.log("uploaded image", file);
    const base64EncodedImg = await convertToBase64(file);
    let reqDto = {
      field1: file.name,
      field2: base64EncodedImg,
      field3: userDetails.orgId,
    };

    // const payload = {
    //   altText: file.name,
    //   src: base64EncodedImg,
    // };

    // editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);

    ZoyelRTEServices.uploadTempImageBlog(reqDto).then((response) => {
      const payload = {
        altText: file.name,
        src: response.data,
      };
      setImageList([...imageList, response.data]);
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    });

    // console.log("handleUploadOrgLogo", file);
    // setOrgLogob64(await convertToBase64(file));
    // setOrgLogo(file);
  };

  const convertToBase64 = (file) => {
    try {
      return new Promise((resolve) => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          baseURL = reader.result;
          resolve(baseURL.split(",")[1]);
          // console.log(baseURL.split(",")[0]);
          // resolve(baseURL);
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <IconButton onClick={() => imageRef.current.click()}>
        <ImageRounded />
      </IconButton>
      <input
        accept="image/*"
        ref={imageRef}
        type="file"
        single
        style={{ display: "none" }}
        onChange={(e) => {
          handleUploadOrgLogo(e);
          imageRef.current.value = null;
        }}
      />
    </>
  );
};
export default ImagePlugin;
