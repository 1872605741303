import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import DateUtils, { weekDays } from "../../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";

export default function DayView({
  currentWeek,
  selectedDate,
  events,
  holidayList,
  leaveList,
  defaultWorkWeek,
  loginUser,
  startTime,
  endTime,
  showWeekView,
  rows,
  slotDuration,
  cols,
  setSelectedEvent,
  setAdditionalEventDetails,
  setShowAdditionalEventDetails,
  showAdditionalEventDetails,
  setSelectedConvenientTime,
}) {
  const { t, i18n } = useTranslation();
  const [currentDaysEvents, setCurrentDaysEvents] = useState([]);
  const [currentDaysTasks, setCurrentDaysTasks] = useState([]);
  const [splDayClassName, setSplDayClassName] = useState("");
  const [splDayName, setSplDayName] = useState("");
  const [sortedEventList, setSortedEventList] = useState([]);
  const [tdWidth, setTdWidth] = useState(null);
  const tdRef = useRef(null);
  useEffect(() => {
    let currentDateStr = DateUtils.getDateInYYYYMMDD(new Date(selectedDate));
    let currentDayEventsTemp = [];
    let currentDayTasksTemp = [];
    events.forEach((eventDto) => {
      let eventDate = DateUtils.getDateInYYYYMMDD(new Date(eventDto.start));
      if (eventDate === currentDateStr) {
        currentDayEventsTemp.push(eventDto);
      }
    });

    console.log("", currentDayTasksTemp);
    setCurrentDaysEvents(currentDayEventsTemp);

    let holidays = holidayList.map(({ id }) => id);

    let leave = leaveList.map(({ id }) => id);

    let workingDays = defaultWorkWeek.map(({ day }) => day);

    if (holidays.indexOf(currentDateStr) !== -1) {
      setSplDayClassName("bgHoliday");
      let holidayName = holidayList.filter(
        (holiday) => holiday.id === currentDateStr
      );
      setSplDayName(holidayName);
    } else if (leave.indexOf(currentDateStr) !== -1) {
      setSplDayClassName("bgOnLeav");
      let leaveName = leaveList.filter((leave) => leave.id === currentDateStr);
      setSplDayName(leaveName);
    } else if (
      workingDays.indexOf(weekDays[new Date(selectedDate).getDay()]) === -1
    ) {
      setSplDayClassName("bgWekOff");
      setSplDayName(null);
    } else {
      setSplDayClassName("");
      setSplDayName(null);
    }

    /////////////////////////////////////////
    console.log("use effect called", events, selectedDate, currentWeek);
    const sortedList = [...events].sort((a, b) => {
      return new Date(a.start) - new Date(b.start);
    });

    console.log("sortedList", new Date().getTime(), sortedList.length);

    // setSortedEventList(sortedList);

    const updateTdWidth = () => {
      if (tdRef.current) {
        const width = tdRef.current.clientWidth;
        setTdWidth(width);
      }
    };
    updateTdWidth();
    window.addEventListener("resize", updateTdWidth);
    // generateEventsDisplayMap();
    setSortedEventList(sortedList);
    return () => {
      window.removeEventListener("resize", updateTdWidth);
    };
  }, [events]);
  const displayLeftSideTime = () => {
    const items = [];
    for (let count = startTime; count <= endTime; count++) {
      if (count === 24) {
        continue;
      }
      items.push(
        <>
          <tr>
            <td class="e-time-slots">
              <span>
                {DateUtils.tConvert(
                  count > 9 ? count + ":00" : "0" + count + ":00"
                )}
              </span>
            </td>
          </tr>
          <tr>
            <td class="e-time-slots"></td>
          </tr>
          <tr>
            <td class="e-time-slots"></td>
          </tr>
          <tr>
            <td class="e-time-cells e-time-slots"></td>
          </tr>
        </>
      );
    }
    return items;
  };

  ///////////////////////////////////////////////
  const [eventsToDisplay, setEventsToDisplay] = useState(new Map());
  const displayEvents = () => {
    return eventsToDisplay;
  };
  function generateTimeIntervalsRoundedTo15Minutes() {
    const intervals = [];
    const totalMinutesInDay = 24 * 60;

    const currentHours = 0;
    const currentMinutes = 0;

    const remainingMinutesInDay =
      totalMinutesInDay - (currentHours * 60 + currentMinutes);

    const nextMultipleOf15 = Math.ceil(currentMinutes / 15) * 15;
    // console.log(nextMultipleOf15);

    for (
      let minutes = nextMultipleOf15;
      currentHours + Math.floor(minutes / 60) <= 23 && minutes % 60 <= 45;
      minutes += 15
    ) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      let period = "AM";
      let formattedHours = currentHours + Math.floor(minutes / 60);

      if (formattedHours >= 12) {
        period = "PM";
        formattedHours = formattedHours === 12 ? 12 : formattedHours - 12;
      }

      const formattedTime = `${String(formattedHours).padStart(
        2,
        "0"
      )}:${String(mins).padStart(2, "0")} ${period}`;

      intervals.push({ time: formattedTime, available: true });
    }

    return intervals;
  }

  useEffect(() => {
    if (sortedEventList.length > 0) {
      generateEventsDisplayMap();
    }
  }, [sortedEventList]);

  const generateEventsDisplayMap = async () => {
    console.log(
      "generateEventsDisplayMap called---->1",
      new Date().getTime(),
      currentWeek.length,
      sortedEventList.length
    );
    // setEventsDisplayMap(null);
    // setEventsToDisplay(null);
    let timeDivMap = new Map();

    let weekDayDateYYYYMMDD = DateUtils.getDateInYYYYMMDD(selectedDate);

    timeDivMap.set(weekDayDateYYYYMMDD + "_1", {
      timeSlots: generateTimeIntervalsRoundedTo15Minutes(),
    });

    timeDivMap.set(weekDayDateYYYYMMDD + "_2", {
      timeSlots: generateTimeIntervalsRoundedTo15Minutes(),
    });

    timeDivMap.set(weekDayDateYYYYMMDD + "_3", {
      timeSlots: generateTimeIntervalsRoundedTo15Minutes(),
    });

    generateWeekDayData(0, weekDayDateYYYYMMDD, timeDivMap);

    generateDisplayData(timeDivMap);
    // setRefresh(refresh + 1);

    // return timeDivMap;
  };

  const generateDisplayData = (timeDivMap) => {
    console.log(
      "generateEventsDisplayMap called---->4",
      new Date().getTime(),
      currentWeek.length,
      sortedEventList.length
    );
    console.log("eventsDisplayMap", timeDivMap);

    const items = [];
    if (timeDivMap == null || timeDivMap.size === 0) {
      return;
    }
    let weekIndex = 0;
    // for (weekIndex = 0; weekIndex < currentWeek.length; weekIndex++) {
    const weekDay = DateUtils.getDateInYYYYMMDD(selectedDate);

    let timeSlotListFirstSlot = timeDivMap.get(weekDay + "_1")?.timeSlots;

    const firstSlotWeekDay = [];
    let currentEventDto = null;

    for (let index = 0; index < timeSlotListFirstSlot?.length; index++) {
      const timeSlotDto = timeSlotListFirstSlot[index];
      if (
        !timeSlotDto.available &&
        (currentEventDto == null ||
          currentEventDto.eventId !== timeSlotDto.eventDtls.eventDto.eventId)
      ) {
        currentEventDto = timeSlotDto.eventDtls.eventDto;
        console.log(
          "currentEventDto",
          currentEventDto,
          timeSlotDto.eventDtls,
          `${timeSlotDto.eventDtls.topOffset}px !important`
        );

        let style = {
          top: timeSlotDto.eventDtls.topOffset + "px",
          height: timeSlotDto.eventDtls.height + "px",
          left: timeSlotDto.eventDtls.left + "px",
          width: timeSlotDto.eventDtls.width + "px",
        };

        console.log("style---->>>>>>>>", style);

        firstSlotWeekDay.push(
          <>
            <div
              className="e-appointment e-lib e-draggable  "
              style={style}
              key={weekDay + "_1"}
            >
              <Tooltip
                title={`${
                  currentEventDto.title
                } ${DateUtils.displayDateMonthViewToolTip(currentEventDto)}`}
                arrow
              >
                <div
                  className={`e-appointment-details ${
                    currentEventDto.source === "google"
                      ? "gCalendar"
                      : "myCalendar"
                  } `}
                  onClick={() => {
                    setSelectedEvent(timeSlotDto.eventDtls.eventDto);
                  }}
                >
                  <div
                    className="e-subject"
                    style={{
                      maxHeight: `${timeSlotDto.eventDtls.height}px !important`,
                    }}
                  >
                    {`${currentEventDto.title}`}
                  </div>
                  <div
                    className="e-time"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {DateUtils.displayDateMonthViewToolTip(currentEventDto)}
                  </div>
                </div>
              </Tooltip>
            </div>
          </>
        );
      }
    }

    let timeSlotListSecondSlot = timeDivMap.get(weekDay + "_2")?.timeSlots;

    const secondSlotWeekDay = [];
    let currentEventDtoSec = null;
    for (let index = 0; index < timeSlotListSecondSlot?.length; index++) {
      const timeSlotDto = timeSlotListSecondSlot[index];

      if (
        !timeSlotDto.available &&
        (currentEventDtoSec == null ||
          currentEventDtoSec.eventId !== timeSlotDto.eventDtls.eventDto.eventId)
      ) {
        currentEventDtoSec = timeSlotDto.eventDtls.eventDto;
        console.log("currentEventDtoSec", currentEventDtoSec);
        let style = {
          top: timeSlotDto.eventDtls.topOffset + "px",
          height: timeSlotDto.eventDtls.height + "px",
          left: timeSlotDto.eventDtls.left + "px",
          width: timeSlotDto.eventDtls.width + "px",
        };
        secondSlotWeekDay.push(
          <>
            <div
              className="e-appointment e-lib e-draggable  "
              style={style}
              key={weekDay + "_2"}
            >
              <Tooltip
                title={`${
                  currentEventDtoSec.title
                } ${DateUtils.displayDateMonthViewToolTip(currentEventDtoSec)}`}
                arrow
              >
                <div
                  className={`e-appointment-details ${
                    currentEventDto.source === "google"
                      ? "gCalendar"
                      : "myCalendar"
                  } `}
                  onClick={() => {
                    setSelectedEvent(timeSlotDto.eventDtls.eventDto);
                  }}
                >
                  <div
                    className="e-subject"
                    style={{
                      maxHeight: `${timeSlotDto.eventDtls.height}px !important`,
                    }}
                  >
                    {`${currentEventDtoSec.title}`}
                  </div>
                  <div
                    className="e-time"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {DateUtils.displayDateMonthViewToolTip(currentEventDtoSec)}
                  </div>
                </div>
              </Tooltip>
            </div>
          </>
        );
      }
    }

    let timeSlotListThirdSlot = timeDivMap.get(weekDay + "_3")?.timeSlots;

    const thirdSlotWeekDay = [];
    // let currentEventDtoSec = null;
    for (let index = 0; index < timeSlotListThirdSlot?.length; index++) {
      const timeSlotDto = timeSlotListThirdSlot[index];

      if (timeSlotDto.eventList && timeSlotDto.eventList.length > 0) {
        let hours = parseInt(timeSlotDto.time.split(":")[0]);
        if (timeSlotDto.time.split(" ")[1] === "PM") {
          hours += 12;
        }
        let topOffset = ((60 * hours) / 15) * 21;
        // let left = weekIndex * tdWidth;
        let left = (tdWidth - 60) / 2 + (tdWidth - 60) / 2;
        let style = {
          top: topOffset + "px",
          height: "42px",
          position: "absolute",
          // maxHeight: "42px",
          left: left + "px",
          // width: timeSlotDto.eventDtls.width + "px",
        };
        thirdSlotWeekDay.push(
          <Button
            className="e-appointment-details moreEvent"
            sx={style}
            key={weekDay + "_3"}
            onClick={() => {
              if (showAdditionalEventDetails) {
                setShowAdditionalEventDetails(false);
              } else {
                setAdditionalEventDetails(timeSlotDto.eventList);
                setShowAdditionalEventDetails(true);
              }
            }}
          >
            <div className="e-subject-more" style={{ maxHeight: "42px" }}>
              +{timeSlotDto.eventList.length}
            </div>
          </Button>
        );
      }
    }
    // <td className="e-day-wrapper">
    // <div
    //   className="e-appointment-wrapper"
    //   id="e-appointment-wrapper-0"
    // >

    items.push(
      <>
        <td className="e-day-wrapper" key={weekIndex}>
          <div
            className="e-appointment-wrapper"
            id={`e-appointment-wrapper-${weekIndex}`}
          >
            {firstSlotWeekDay}
            {secondSlotWeekDay}
            {thirdSlotWeekDay}
          </div>
        </td>
      </>
    );
    // }
    // console.log(
    //   "refresh---->",
    //   new Date().getMinutes(),
    //   new Date().getSeconds(),
    //   refresh,
    //   items
    // );
    setEventsToDisplay(items);
    // setRefresh(refresh + 1);
  };

  const generateWeekDayData = (weekIndex, weekDayYYYYMMDD, timeDivMap) => {
    // const items = [];
    console.log(
      "generateEventsDisplayMap called---->2",
      new Date().getTime(),
      sortedEventList
    );
    for (let index = 0; index < sortedEventList.length; index++) {
      const eventDto = sortedEventList[index];
      let eventDateYYYYMMDD = DateUtils.getDateInYYYYMMDD(
        new Date(eventDto.start)
      );
      if (weekDayYYYYMMDD === eventDateYYYYMMDD) {
        console.log(
          "generateEventsDisplayMap called eventDto---->2",
          eventDto,
          DateUtils.formatDateTo12HourFormat(new Date(eventDto.start))
        );
        generateDayData(eventDto, weekIndex, weekDayYYYYMMDD, timeDivMap);
      }
    }
    // return items;
  };

  const generateDayData = (
    eventDto,
    weekIndex,
    weekDayYYYYMMDD,
    timeDivMap
  ) => {
    const items = [];

    if (timeDivMap.has(weekDayYYYYMMDD + "_1")) {
      let timeSlotsList = timeDivMap.get(weekDayYYYYMMDD + "_1").timeSlots;

      let storedEventDetails = null;
      for (let index = 0; index < timeSlotsList.length; index++) {
        const timeSlotDto = timeSlotsList[index];

        if (storedEventDetails) {
          // console.log(
          //   "isEventJSXStore",
          //   DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)),
          //   timeSlotDto.time,
          //   DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
          //     timeSlotDto.time
          // );
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
            timeSlotDto.time
          ) {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;

            index = timeSlotsList.length;
            break;
          } else {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;
          }
        } else {
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.start)) ===
            timeSlotDto.time
          ) {
            if (timeSlotDto.available) {
              let topOffset =
                (DateUtils.timeToMinutes(new Date(eventDto.start)) / 15) * 21;
              let height = "32px";
              if (
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.start)) !==
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.end))
              ) {
                var endOfDay = new Date(eventDto.start);
                endOfDay.setHours(23, 59, 0, 0);
                var difference = endOfDay - new Date(eventDto.start);

                // Convert the difference to hours, minutes, and seconds
                var minutesDifference = Math.floor(
                  (difference % (1000 * 60 * 60)) / (1000 * 60)
                );

                height = (minutesDifference / 15) * 21;
              } else {
                height =
                  (DateUtils.getDifferenceInMinutes(
                    eventDto.start,
                    eventDto.end
                  ) /
                    15) *
                  21;
              }

              // let left = weekIndex * tdWidth;

              storedEventDetails = {
                eventDto: eventDto,
                topOffset: topOffset,
                height: height,
                left: 0,
              };

              timeSlotDto.eventDtls = storedEventDetails;
              timeSlotDto.available = false;
            } else if (timeDivMap.has(weekDayYYYYMMDD + "_2")) {
              addEventInSecondSlotForTheDay(
                eventDto,
                weekIndex,
                weekDayYYYYMMDD,
                timeDivMap,
                timeSlotDto
              );
            }
          } else {
          }
        }
      }
    }

    return items;
  };

  const addEventInSecondSlotForTheDay = (
    eventDto,
    weekIndex,
    weekDayYYYYMMDD,
    timeDivMap,
    firstTimeSlotDto
  ) => {
    const items = [];

    let storedEventDetails = null;

    if (timeDivMap.has(weekDayYYYYMMDD + "_2")) {
      let timeSlotsList = timeDivMap.get(weekDayYYYYMMDD + "_2").timeSlots;

      for (let index = 0; index < timeSlotsList.length; index++) {
        const timeSlotDto = timeSlotsList[index];
        if (storedEventDetails) {
          console.log(
            "isEventJSXStore",
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)),
            timeSlotDto.time,
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
              timeSlotDto.time
          );
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
            timeSlotDto.time
          ) {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;

            index = timeSlotsList.length;
            break;
          } else {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;
          }
        } else {
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.start)) ===
            timeSlotDto.time
          ) {
            if (timeSlotDto.available) {
              let topOffset =
                (DateUtils.timeToMinutes(new Date(eventDto.start)) / 15) * 21;

              // let height =
              //   (DateUtils.getDifferenceInMinutes(
              //     eventDto.start,
              //     eventDto.end
              //   ) /
              //     15) *
              //   21;

              let height = "32px";
              if (
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.start)) !==
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.end))
              ) {
                var endOfDay = new Date(eventDto.start);
                endOfDay.setHours(23, 59, 0, 0);
                var difference = endOfDay - new Date(eventDto.start);

                // Convert the difference to hours, minutes, and seconds
                var minutesDifference = Math.floor(
                  (difference % (1000 * 60 * 60)) / (1000 * 60)
                );

                height = (minutesDifference / 15) * 21;
              } else {
                height =
                  (DateUtils.getDifferenceInMinutes(
                    eventDto.start,
                    eventDto.end
                  ) /
                    15) *
                  21;
              }

              // let left = weekIndex * tdWidth;
              let left = (tdWidth - 60) / 2;
              storedEventDetails = {
                eventDto: eventDto,
                topOffset: topOffset,
                height: height,
                left: left,
                width: (tdWidth - 60) / 2,
              };
              timeSlotDto.eventDtls = storedEventDetails;
              timeSlotDto.available = false;
              firstTimeSlotDto.eventDtls.width = (tdWidth - 60) / 2;
            } else if (timeDivMap.has(weekDayYYYYMMDD + "_3")) {
              let timeSlotsList = timeDivMap.get(
                weekDayYYYYMMDD + "_3"
              ).timeSlots;
              let timeFormat = DateUtils.formatDateTo12HourFormat(
                new Date(eventDto.start)
              );

              console.log(
                "eventDto 3--->>>>>>>>",
                weekDayYYYYMMDD + "_3",
                eventDto,
                timeFormat,
                timeSlotsList
              );

              for (let index = 0; index < timeSlotsList.length; index++) {
                const thirdSlot = timeSlotsList[index];

                if (
                  thirdSlot.time.split(":")[0] === timeFormat.split(":")[0] &&
                  thirdSlot.time.split(" ")[0].split(":")[1] === "00" &&
                  ((timeFormat.split(" ")[1] === "PM" &&
                    thirdSlot.time.split(" ")[1] === "PM") ||
                    (timeFormat.split(" ")[1] === "AM" &&
                      thirdSlot.time.split(" ")[1] === "AM"))
                ) {
                  console.log(
                    "eventDto 3--->>>>>>>> 2",
                    weekDayYYYYMMDD + "_3",
                    eventDto,
                    timeFormat,
                    timeSlotsList
                  );
                  if (thirdSlot.eventList && thirdSlot.eventList.length > 0) {
                    thirdSlot.eventList.push(eventDto);
                  } else {
                    let tempList = [];
                    tempList.push(eventDto);
                    thirdSlot.eventList = tempList;
                  }
                }
              }
            }
          } else {
          }
        }
      }
    }

    return items;
  };
  /////////////////////////////////////////////

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const monthsOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getDateDisplay = (date) => {
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const year = date.getFullYear();

  return `${t(dayOfWeek)} - ${t(month)} - ${year}`;
};

  return (
    <>
      <div className="dayViewContainer">
        <div className="scheduler-wrapper">
          <div className="scheduler-header">
            <div className="overview-content">
              <div className="left-panel">
                <div className="overview-scheduler">
                  <div className="e-control e-schedule e-lib schedule-overview e-keyboard">
                    <div className="e-table-container">
                      <div className="e-table-wrap e-vertical-view e-day-view e-current-panel">
                        <ScrollSync>
                          <table
                            className="e-schedule-table e-outer-table"
                            role="presentation"
                          >
                            <tbody>
                              <tr>
                                <td className="e-left-indent"></td>
                                <td>
                                  <div className="e-date-header-container">
                                    <div className="e-date-header-wrap e-all-day-auto">
                                      <table className="e-schedule-table ">
                                        <tbody>
                                          <tr className="e-header-row">
                                            <td
                                              colspan="1"
                                              className="e-header-cells e-current-day "
                                            >
                                              <div>
                                                <div class="cell_Date_Days">
                                                  <div
                                                    class={`cell_Date ${
                                                      DateUtils.isToday(
                                                        selectedDate
                                                      )
                                                        ? "todayDD"
                                                        : ""
                                                    }`}
                                                  >
                                                    {DateUtils.formatDayWithLeadingZero(
                                                      selectedDate
                                                    )}
                                                  </div>
                                                  <div class="date-text cell_Days">
                                                    {/* {DateUtils.getDateDisplayWeekViewHeader(
                                                      selectedDate
                                                    )} */}
                                                     {getDateDisplay(
                                                      selectedDate
                                                    )}
                                                    {splDayName &&
                                                      splDayName.length > 0 &&
                                                      " (" +
                                                        splDayName[0].name +
                                                        ")"}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="rightTimeCell">
                                  <ScrollSyncPane group="vertical">
                                    <div
                                      className="e-time-cells-wrap leftRightCalHeight"
                                      //   style={{
                                      //     // height: " 407px",
                                      //     overflowY: "auto",
                                      //   }}
                                    >
                                      {/* <div
                                        className="e-current-time"
                                        style={{ top: "370px" }}
                                      >
                                        04:30 PM
                                      </div> */}
                                      <table className="e-schedule-table ">
                                        <tbody>{displayLeftSideTime()}</tbody>
                                        {/* <tbody>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>12:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>1:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>2:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>3:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>4:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>5:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>6:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>7:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>8:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>9:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>10:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>11:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>12:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>1:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>2:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>3:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>4:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>5:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>6:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>7:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>8:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>9:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>10:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>11:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                        </tbody> */}
                                      </table>
                                    </div>
                                  </ScrollSyncPane>
                                </td>
                                <td>
                                  <ScrollSyncPane group="vertical">
                                    <div className="e-content-wrap leftRightCalHeight">
                                      <table
                                        className="e-schedule-table e-content-table"
                                        role="grid"
                                      >
                                        <thead>
                                          {displayEvents()}
                                          {/* <tr>
                                            <td className="e-day-wrapper">
                                              <div
                                                className="e-appointment-wrapper"
                                                id="e-appointment-wrapper-0"
                                              >
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    height: "42px",
                                                    left: "0px",
                                                    top: "252px",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title="Morning Meeting
                                                        (Applicable for Work From
                                                        Home Employee) (10:00 AM - 10:30 AM)"
                                                    arrow
                                                  >
                                                    <div className="e-appointment-details gCalendar">
                                                      <div
                                                        className="e-subject"
                                                        style={{
                                                          maxHeight: "42px",
                                                        }}
                                                      >
                                                        Morning Meeting
                                                        (Applicable for Work
                                                        From Home Employee)
                                                      </div>
                                                    </div>
                                                  </Tooltip>

                                                  <Tooltip
                                                    title="Morning Meeting
                                                        (Applicable for Work From
                                                        Home Employee) (10:00 AM - 10:30 AM)"
                                                    arrow
                                                  >
                                                    <div className="e-appointment-details myCalendar">
                                                      <div
                                                        className="e-subject"
                                                        style={{
                                                          maxHeight: "84px",
                                                        }}
                                                      >
                                                        Morning Meeting
                                                        (Applicable for Work
                                                        From Home Employee)
                                                      </div>
                                                    </div>
                                                  </Tooltip>

                                                  <Tooltip
                                                    title="Morning Meeting (Applicable for Work From Home Employee) (10:00 AM - 10:30 AM)"
                                                    arrow
                                                  >
                                                    <div className="e-appointment-details myCalendar">
                                                      <div
                                                        className="e-subject"
                                                        style={{
                                                          maxHeight: "42px",
                                                        }}
                                                      >
                                                        Morning Meeting
                                                        (Applicable for Work
                                                        From Home Employee)
                                                      </div>
                                                    </div>
                                                  </Tooltip>

                                                  <Button className="e-appointment-details moreEvent">
                                                    <div
                                                      className="e-subject-more"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      +3
                                                    </div>
                                                  </Button>
                                                </div>

                                                <div
                                                  className="e-appointment e-lib e-draggable "
                                                  style={{
                                                    top: "840px",
                                                    height: "168px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details gCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "168px",
                                                      }}
                                                    >
                                                      Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee)
                                                    </div>
                                                    <div className="e-time">
                                                      (10:00 AM-12:00 AM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable "
                                                  style={{
                                                    top: "1260px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      UX Discussion
                                                    </div>
                                                    <div className="e-time">
                                                      (3:00 PM-3:30 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1512px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details gCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      Zoyel Development
                                                    </div>
                                                    <div className="e-time">
                                                      (6:00 PM-7:00 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1604.4px",
                                                    height: "42px",
                                                    left: "11.1px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      Instant Meeting
                                                    </div>
                                                    <div className="e-time">
                                                      (7:06 PM-7:36 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr> */}
                                          {/* <tr>
                                            <td className="e-timeline-wrapper">
                                              <div
                                                className="e-current-timeline"
                                                style={{ top: "377px" }}
                                              ></div>
                                            </td>
                                          </tr> */}
                                        </thead>
                                        {/* <tbody>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              label="Hello"
                                              colspan="1"
                                              className="e-work-cells e-alternate-cells firstSlot  "
                                            ></td>
                                          </tr>
                                        </tbody> */}
                                        <tbody>
                                          {rows.map((row) => {
                                            let noOfDivisions = parseInt(
                                              60 / slotDuration
                                            );
                                            let className =
                                              "e-work-cells e-alternate-cells";

                                            if (noOfDivisions === 4) {
                                              className =
                                                "e-work-cells e-alternate-cells" +
                                                " firstSlot";
                                            } else if (noOfDivisions === 2) {
                                              className =
                                                "e-work-cells e-alternate-cells" +
                                                " secondSlot";
                                            } else if (noOfDivisions === 1) {
                                              className =
                                                "e-work-cells e-alternate-cells" +
                                                " thirdSlot";
                                            }
                                            let startHour = startTime;
                                            let startMinute =
                                              row * slotDuration;
                                            if (slotDuration === 15) {
                                              if (row === 0) {
                                                startHour = 0;
                                              } else if (row % 4 === 0) {
                                                // startHour = row / 4;
                                              }
                                            } else if (slotDuration === 30) {
                                              if (row === 0) {
                                                startHour = 0;
                                              } else if (row % 2 === 0) {
                                                // startHour = row / 2;
                                              }
                                            } else if (slotDuration === 60) {
                                              if (row === 0) {
                                                startHour = 0;
                                              } else if (row % 1 === 0) {
                                                // startHour = row / 1;
                                              }
                                            }

                                            let timeValueDate = new Date(
                                              selectedDate
                                            );

                                            timeValueDate.setHours(
                                              startHour,
                                              startMinute,
                                              0,
                                              0
                                            );

                                            let fromTime = new Date(
                                              timeValueDate
                                            ).toLocaleTimeString("en-US", {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              hour12: true,
                                            });

                                            let endTimeStart = new Date(
                                              timeValueDate
                                            );

                                            endTimeStart.setMinutes(
                                              endTimeStart.getMinutes() +
                                                slotDuration
                                            );

                                            let toTime = new Date(
                                              endTimeStart
                                            ).toLocaleTimeString("en-US", {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              hour12: true,
                                            });

                                            let timeValue = {
                                              convStartDate: selectedDate,
                                              convFromTime: fromTime,
                                              convToTime: toTime,
                                            };

                                            let selectableClassName = "";
                                            if (
                                              DateUtils.getDifferenceInMinutes(
                                                timeValueDate,
                                                new Date()
                                              ) <= 0
                                            ) {
                                              selectableClassName +=
                                                " tdCellsSelectable";
                                            }
                                            return (
                                              <tr>
                                                <td
                                                  onClick={() => {
                                                    if (
                                                      DateUtils.getDifferenceInMinutes(
                                                        timeValueDate,
                                                        new Date()
                                                      ) <= 0
                                                    ) {
                                                      setSelectedConvenientTime(
                                                        timeValue
                                                      );
                                                    }
                                                  }}
                                                  ref={tdRef}
                                                  label="Hello"
                                                  colspan="1"
                                                  className={
                                                    className +
                                                    " " +
                                                    splDayClassName +
                                                    selectableClassName
                                                  }
                                                ></td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </ScrollSyncPane>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </ScrollSync>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
