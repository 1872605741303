import React from "react";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { Loader, ThemeProvider } from "@aws-amplify/ui-react";
// import MeetingService from "../../services/MeetingService";
import { toast } from "react-toastify";
import { Amplify } from "aws-amplify";
// import "@aws-amplify/ui-react/styles.css";
import awsexports from "../../../aws-exports";
import "../css/FaceLiveness.css";
import DashboardServices from "../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";

Amplify.configure(awsexports);

export function FaceLivenessAwsAmplify({ onDone, onClose, onRetry }) {
  const loginUser = GetLoginUserDetails();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [createLivenessApiData, setCreateLivenessApiData] =
    React.useState(null);

  React.useEffect(() => {
    const fetchCreateLiveness = async () => {
      /*
       * This should be replaced with a real call to your own backend API
       */
      //   await new Promise((r) => setTimeout(r, 2000));
      //   const mockResponse = { sessionId: "mockSessionId" };
      const response = await DashboardServices.getFaceLivenessSession(
        loginUser.userId
      );
      //   toast.info(response.data.status);
      const mockResponse = { sessionId: response.data.status };
      const data = mockResponse;
      //   const data = response.data.status;

      setCreateLivenessApiData(data);
      setLoading(false);
    };

    fetchCreateLiveness();
  }, []);

  const handleAnalysisComplete = async () => {
    /*
     * This should be replaced with a real call to your own backend API
     */
    // const response = await fetch(
    //   `/api/get?sessionId=${createLivenessApiData.sessionId}`
    // );
    // const data = await response.json();
    const response = await DashboardServices.getFaceLivenessSessionResult(
      createLivenessApiData.sessionId
    );
    // toast.info(response.data.status);
    console.log(response.data.message);
    console.log(parseFloat(response.data.message));
    let confidence = parseFloat(response.data.message);
    let base64Image = response.data.status;

    /*
     * Note: The isLive flag is not returned from the GetFaceLivenessSession API
     * This should be returned from your backend based on the score that you
     * get in response. Based on the return value of your API you can determine what to render next.
     * Any next steps from an authorization perspective should happen in your backend and you should not rely
     * on this value for any auth related decisions.
     */
    if (confidence > 95) {
      // toast.success("Face Liveness test passed, Please wait");
      onDone({
        status: "Success",
        returnValue: "1",
        image: `data:image/jpeg;base64,${base64Image}`,
      });
      return true;
    } else {
      toast.error(t("face_liveness_test_failed_please_try_again"));
      onDone({ status: "Failure", returnValue: "0", image: base64Image });
      return false;
    }
  };

  const { i18n } = useTranslation();

  return (
    <ThemeProvider>
      {loading ? (
        <Loader />
      ) : (
        <FaceLivenessDetector
          // displayText={{
          //   photosensitivityWarningBodyText: "Advertencia de fotosensibilidad",
          //   photosensitivityWarningHeadingText:
          //     "Esta verificación muestra luces de colores. Tenga cuidado si es fotosensible.",
          // }}
          // i18nIsDynamicList={dictionary["es"]}
          displayText={
            i18n.language === "es"
              ? {
                  photosensitivityWarningBodyText:
                    "Este cheque parpadea en diferentes colores. Tenga cuidado si es fotosensible.",
                  photosensitivityWarningHeadingText:
                    "Advertencia de fotosensibilidad",
                  photosensitivityWarningInfoText:
                    "Algunas personas pueden experimentar ataques epilépticos cuando se exponen a luces de colores. Tenga cuidado si usted o alguien de su familia padece una enfermedad epiléptica.",
                  photosensitivityWarningLabelText:
                    "Este cheque parpadea en diferentes colores. Tenga cuidado si es fotosensible.",
                  hintCenterFaceText: "centra tu cara",
                  startScreenBeginCheckText: "Iniciar verificación de video",
                  hintConnectingText: "Conectando",
                  hintTooFarText: "Acércate",
                  hintVerifyingText: "verificando",
                  serverHeaderText: "Servproblema del servidor",
                  serverMessageText:
                    "No se puede completar la verificación debido a problemas con el servidor",
                  hintHoldFaceForFreshnessText: "Quédate quieto",
                  waitingCameraPermissionText:
                    "esperando que permitas el permiso de la cámara",
                  hintMoveFaceFrontOfCameraText:
                    "Mover la cara frente a la cámara",
                  hintTooCloseText: "Retroceder",
                }
              : {}
          }
          sessionId={createLivenessApiData.sessionId}
          region="us-east-1"
          onAnalysisComplete={handleAnalysisComplete}
          onError={(error) => {
            console.log("FaceLivenessDetector", error);
          }}
          onUserCancel={(error) => {
            console.log("FaceLivenessDetector", error);
            onRetry();
          }}
        />
      )}
    </ThemeProvider>
  );
}
