import React from "react";
import QueueHistoryTable from "./QueueHistoryTable";
import CardManagementConsultation from "../../CardManagementConsultation";

export default function ConsultationAnalyticDetails({ selectedMenu }) {
  return (
    <>
      <div className="overViewConatiner">
        {selectedMenu === "_const_graph_" && (
          <CardManagementConsultation
            showMoreBtn={false}
            chartDataOfItem={[]}
          />
        )}
        {selectedMenu === "_queue_history_" && <QueueHistoryTable />}
      </div>
    </>
  );
}
