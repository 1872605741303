import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { NumericFormatCustom } from "./NumericFormatCustom";
import {
  ArrowDown,
  ArrowUp,
  FileArrowUp,
  GraphUpArrow,
  Trash,
} from "react-bootstrap-icons";
import DateUtils from "../../../../../utils/DateUtils";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

function ReimbursementForm({
  reimbursementList,
  setReimbursementList,
  contractList,
  costCenterList,
  ExpenditureGoods,
  budgetHeadList,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const { t, i18n } = useTranslation();

  const { register, watch, control, setValue } = useForm();
  const { invoiceDate, remTax } = watch();

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [allTimeFormData, setAllTimeFormData] = useState({
    updating: true,
    invoiceRcptDt: dayjs(new Date()),
    reimbursementItemId: 0,
    itemDescriptions: "",
    supportingDocumentsFileName: null,
    supportingDocumentsFileData: null,
    supportingDocumentsFileLink: null,
    invoiceRcptNo: "",
    costCenterId: "",
    contractId: "",
    category: "goods",
    completionDate: new Date(),
    estimatedValue: 0,
    total: 0,
    tax: 0,
    grn: "",
    reason: "",
    editMode: true,
    budgetHead: null,
  });
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);

  const addItemToReimbursementList = () => {
    const temp = [
      ...reimbursementList,
      {
        updating: true,
        invoiceRcptDt: dayjs(new Date()),
        reimbursementItemId: 0,
        itemDescriptions: "",
        supportingDocumentsFileName: null,
        supportingDocumentsFileData: null,
        supportingDocumentsFileLink: null,
        invoiceRcptNo: "",
        costCenterId: "",
        contractId: "",
        category: "goods",
        completionDate: new Date(),
        estimatedValue: 0,
        total: 0,
        tax: 0,
        grn: "",
        reason: "",
        editMode: false,
      },
    ];

    setReimbursementList(temp);
    // if (!isUpdating) {
    //   setIsUpdating(true);
    // }
  };

  const handleClickRemoveRow = (row) => {
    const filterData = reimbursementList.filter(
      (obj) => JSON.stringify(obj) !== JSON.stringify(row)
    );
    setReimbursementList(filterData);
  };

  const initializeAllTimeForeData = () => {
    setAllTimeFormData({
      updating: true,
      invoiceRcptDt: dayjs(new Date()),
      reimbursementItemId: 0,
      itemDescriptions: "",
      supportingDocumentsFileName: null,
      supportingDocumentsFileData: null,
      supportingDocumentsFileLink: null,
      invoiceRcptNo: "",
      costCenterId: "",
      contractId: "",
      category: "goods",
      completionDate: new Date(),
      estimatedValue: 0,
      grn: "",
      reason: "",
      total: 0,
      tax: 0,
      editMode: true,
      budgetHead: null,
    });
  };

  const calculateRowTotal = (index) => {
    // const temp=[...reimbursementList]
    const value = reimbursementList[index].qty * reimbursementList[index].rate;

    return value ? value : 0;
  };

  const insertAt = (index) => {
    const temp = [...reimbursementList];
    temp.splice(index, 0, {
      updating: true,
      invoiceRcptDt: dayjs(new Date()),
      reimbursementItemId: 0,
      itemDescriptions: "",
      supportingDocumentsFileName: null,
      supportingDocumentsFileData: null,
      supportingDocumentsFileLink: null,
      invoiceRcptNo: "",
      costCenterId: "",
      contractId: "",
      category: "goods",
      completionDate: new Date(),
      estimatedValue: 0,
      total: 0,
      tax: 0,
      grn: "",
      reason: "",
      editMode: true,
    });
    setReimbursementList(temp);
  };

  const insertAbove = (index) => {
    insertAt(index);
  };

  const insertBelow = (index) => {
    insertAt(index + 1);
  };

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th>{t("description")}</th>
                <th>{t("reason")}</th>
                <th>{t("budget_head")}</th>
                <th>{t("invoiceReceiptNo")}</th>
                <th>{t("invoice_date")}</th>
                {(ExpenditureGoods === "goods" ||
                  ExpenditureGoods === "services") && (
                  <th>{ExpenditureGoods === "goods" ? t("grn") : t("srn")}</th>
                )}
                <th>{t("taxAmount")}</th>
                <th>{t("totalAmount")}</th>
                <th>{t("documents")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reimbursementList &&
                reimbursementList.map((item, index) => {
                  if (item.editMode) {
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  className="formTextFieldArea"
                                  size="small"
                                  step={1.0}
                                  inputProps={{ min: 0 }}
                                  value={item.itemDescriptions}
                                  onChange={(e) => {
                                    // const element = e.target.value;
                                    const getItem = [...reimbursementList];
                                    getItem[index].itemDescriptions =
                                      e.target.value;
                                    setReimbursementList(getItem);
                                  }}
                                />
                              </FormControl>
                            </div>
                          </td>

                          <td>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel id="selectPriority">
                                  {t("reason")}
                                </InputLabel>

                                <Select
                                  className="formInputField"
                                  variant="outlined"
                                  labelId="selectPriority"
                                  id="priority-select"
                                  value={reimbursementList[index].reason}
                                  onChange={(e) => {
                                    const getItem = [...reimbursementList];
                                    getItem[index].reason = e.target.value;
                                    setReimbursementList(getItem);
                                  }}
                                >
                                  <MenuItem value={""} key={""}>
                                    --{t("select")}--
                                  </MenuItem>
                                  <MenuItem
                                    value={"smallItems"}
                                    key={"smallItems"}
                                  >
                                    {t("small_items")}
                                  </MenuItem>

                                  <MenuItem
                                    value={"emergency"}
                                    key={"emergency"}
                                  >
                                    {t("emergency")}
                                  </MenuItem>

                                  <MenuItem value={"online"} key={"online"}>
                                    {t("online")}
                                  </MenuItem>
                                  <MenuItem
                                    value={"rateContract"}
                                    key={"rateContract"}
                                  >
                                    {t("rate_contract")}
                                  </MenuItem>
                                  <MenuItem value={"other"} key={"other"}>
                                    {t("other")}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </td>

                          <td>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel id="selectLedgerHead">
                                  {t("budget_head")}
                                </InputLabel>
                                <Select
                                  className="formInputField"
                                  variant="outlined"
                                  labelId="selectLedgerHead"
                                  id="select-LedgerHead"
                                  label={t("budget_head")}
                                  value={reimbursementList[index].budgetHead}
                                  onChange={(e) => {
                                    const temp = [...reimbursementList];
                                    temp[index].budgetHead = e.target.value;
                                    setReimbursementList(temp);
                                  }}
                                >
                                  {budgetHeadList.length > 0 &&
                                    budgetHeadList.map((itemName, index) => (
                                      <MenuItem
                                        value={itemName}
                                        key={itemName.head + "_" + index}
                                      >
                                        {itemName.head}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </div>
                          </td>
                          <td>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  autoComplete="off"
                                  label={t("invoice")}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  value={reimbursementList[index].invoiceRcptNo}
                                  onChange={(e) => {
                                    // const element = e.target.value;
                                    const getItem = [...reimbursementList];
                                    getItem[index].invoiceRcptNo =
                                      e.target.value;
                                    setReimbursementList(getItem);
                                  }}
                                />
                              </FormControl>
                            </div>
                          </td>
                          <td>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel
                                  id="setCompletionDate"
                                  className="setCompletionDate"
                                >
                                  {t("invoice_date")}
                                </InputLabel>

                                <ReactDatePicker
                                  locale={currentLocale}
                                  toggleCalendarOnIconClick
                                  showYearDropdown
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  showIcon
                                  labelId="setCompletionDate"
                                  className="formDatePicker"
                                  maxDate={new Date()}
                                  dateFormat="dd-MMM-yyyy"
                                  selected={
                                    reimbursementList[index].completionDate
                                  }
                                  onChange={(date) => {
                                    const getItem = [...reimbursementList];
                                    getItem[index].completionDate = date;
                                    setReimbursementList(getItem);
                                  }}
                                />
                              </FormControl>
                            </div>
                          </td>

                          {(ExpenditureGoods === "goods" ||
                            ExpenditureGoods === "services") && (
                            <td>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <TextField
                                    label=""
                                    name="numberformat"
                                    id="IndentQty"
                                    variant="outlined"
                                    className="formTextFieldArea"
                                    value={reimbursementList[index].grn}
                                    onChange={(e) => {
                                      const temp = [...reimbursementList];
                                      temp[index].grn = e.target.value;
                                      setReimbursementList(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </td>
                          )}
                          <td>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  label=""
                                  name="numberformat"
                                  id="IndentQty"
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                  }}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  //   {...register("remTax")}
                                  value={reimbursementList[index].tax}
                                  onChange={(e) => {
                                    const tempReimbList = [
                                      ...reimbursementList,
                                    ];
                                    const temp = parseInt(e.target.value);
                                    if (temp < 100 && temp >= 0) {
                                      tempReimbList[index].tax = temp;
                                      setReimbursementList(tempReimbList);
                                    }
                                  }}
                                />
                              </FormControl>
                            </div>
                          </td>
                          <td>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  label=""
                                  name="numberformat"
                                  id="IndentQty"
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                  }}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  //   {...register("remTax")}
                                  value={reimbursementList[index].total}
                                  onChange={(e) => {
                                    const temp = parseInt(e.target.value);
                                    const tempReimbList = [
                                      ...reimbursementList,
                                    ];
                                    tempReimbList[index].total = temp;
                                    setReimbursementList(tempReimbList);
                                  }}
                                />
                              </FormControl>
                            </div>
                          </td>
                          <td>
                            <IconButton
                              onClick={(e) => {
                                console.log("icon button clicked");
                                const inputElement = document.querySelector(
                                  `#file_input_${index}`
                                );
                                if (inputElement) {
                                  inputElement.click();
                                }
                              }}
                            >
                              <FileArrowUp />
                            </IconButton>
                            <input
                              style={{ display: "none" }}
                              type="file"
                              id={`file_input_${index}`}
                              onChange={async (e) => {
                                console.log("files are", e.target.files);
                                const files = e.target.files;
                                const data = await getFileDataObj(files[0]);
                                const tempReimbList = [...reimbursementList];
                                tempReimbList[index].document = data;
                                setReimbursementList(tempReimbList);
                              }}
                            />
                          </td>

                          <td>
                            <>
                              <Button
                                onClick={() => {
                                  console.log("hello");
                                  const temp = [...reimbursementList];
                                  temp[index].editMode = false;
                                  setReimbursementList(temp);
                                }}
                              >
                                {t("save")}
                              </Button>

                              <IconButton
                                onClick={() => {
                                  if (reimbursementList.length === 1) {
                                    setReimbursementList([]);
                                    return;
                                  }
                                  const temp = [...reimbursementList];
                                  temp.splice(index, 1);
                                  if (temp.length === 0) {
                                    temp.push({
                                      updating: true,
                                      amount: 0,
                                      invoiceRcptDt: dayjs(new Date()),
                                      reimbursementItemId: 0,
                                      itemDescriptions: "",
                                      supportingDocumentsFileName: null,
                                      supportingDocumentsFileData: null,
                                      supportingDocumentsFileLink: null,
                                      invoiceRcptNo: "",
                                    });
                                  }
                                  setReimbursementList(temp);
                                  setTax(0);
                                  setTotal(0);
                                }}
                              >
                                <Trash />
                              </IconButton>

                              {/* <IconButton
                                aria-label="delete"
                                onClick={(e) => {
                                  insertAbove(index);
                                }}
                              >
                                <ArrowUp />
                              </IconButton> */}

                              {/* <IconButton
                                onClick={(e) => {
                                  insertBelow(index);
                                }}
                              >
                                <ArrowDown />
                              </IconButton> */}
                            </>
                          </td>
                        </tr>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="textOnly">
                              {item.itemDescriptions}
                            </div>
                          </td>
                          <td>
                            <div className="textOnly">{item.reason}</div>
                          </td>
                          <td>
                            <div className="textOnly">
                              {item.budgetHead && item.budgetHead.head}
                            </div>
                          </td>
                          <td>
                            <div className="textOnly">{item.invoiceRcptNo}</div>
                          </td>
                          <td>
                            <div className="textOnly">
                              {DateUtils.getDateInDDMMYYYY(item.completionDate)}
                            </div>
                          </td>
                          <td>
                            <div className="textOnly">{item.grn}</div>
                          </td>
                          <td>
                            <div className="textOnly">{item.tax}</div>
                          </td>
                          <td>
                            <div className="textOnly">{item.total}</div>
                          </td>
                          <td>
                            <div className="textOnly">
                              {item.document && item.document.fileName}
                            </div>
                          </td>
                          <td>
                            {item.editMode === false && (
                              <Tooltip title={"Edit Item"} placement="left">
                                <IconButton
                                  className={
                                    item.editMode === false &&
                                    isEditing &&
                                    `deactivatedEditButton`
                                  }
                                  disabled={
                                    item.editMode === false && isEditing
                                  }
                                  onClick={() => {
                                    console.log("edit button clicked");
                                    setIsEditing(true);
                                    const temp = [...reimbursementList];
                                    temp[index].editMode = true;
                                    setReimbursementList(temp);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => {
                                if (item.editMode === true) {
                                  setIsEditing(false);
                                }
                                handleClickRemoveRow(item);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </td>
                        </tr>
                      </>
                    );
                  }
                })}

              <tr>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        className="formTextFieldArea"
                        size="small"
                        step={1.0}
                        inputProps={{ min: 0 }}
                        value={allTimeFormData.itemDescriptions}
                        onChange={(e) => {
                          // const element = e.target.value;
                          const getItem = { ...allTimeFormData };
                          getItem.itemDescriptions = e.target.value;
                          setAllTimeFormData(getItem);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectPriority">{t("reason")}</InputLabel>

                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectPriority"
                        id="priority-select"
                        value={allTimeFormData.reason}
                        onChange={(e) => {
                          const getItem = { ...allTimeFormData };
                          getItem.reason = e.target.value;
                          setAllTimeFormData(getItem);
                        }}
                      >
                        <MenuItem value={""} key={""}>
                          --{t("select")}--
                        </MenuItem>
                        <MenuItem value={"smallItems"} key={"smallItems"}>
                          {t("small_items")}
                        </MenuItem>

                        <MenuItem value={"emergency"} key={"emergency"}>
                          {t("emergency")}
                        </MenuItem>

                        <MenuItem value={"online"} key={"online"}>
                          {t("online")}
                        </MenuItem>
                        <MenuItem value={"rateContract"} key={"rateContract"}>
                          {t("rate_contract")}
                        </MenuItem>
                        <MenuItem value={"other"} key={"other"}>
                          {t("other")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectLedgerHead">
                        {t("budget_head")}
                      </InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectLedgerHead"
                        id="select-LedgerHead"
                        label={t("budget_head")}
                        value={allTimeFormData.budgetHead}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.budgetHead = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {budgetHeadList.length > 0 &&
                          budgetHeadList.map((itemName, index) => (
                            <MenuItem
                              value={itemName}
                              key={itemName.head + "_" + index}
                            >
                              {itemName.head}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        autoComplete="off"
                        label={t("invoice")}
                        variant="outlined"
                        className="formTextFieldArea"
                        value={allTimeFormData.invoiceRcptNo}
                        onChange={(e) => {
                          // const element = e.target.value;
                          const getItem = { ...allTimeFormData };
                          getItem.invoiceRcptNo = e.target.value;
                          setAllTimeFormData(getItem);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setCompletionDate"
                        className="setCompletionDate"
                      >
                        {t("invoice_date")}
                      </InputLabel>

                      <ReactDatePicker
                        locale={currentLocale}
                        toggleCalendarOnIconClick
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        showIcon
                        labelId="setCompletionDate"
                        className="formDatePicker"
                        maxDate={new Date()}
                        dateFormat="dd-MMM-yyyy"
                        selected={allTimeFormData.completionDate}
                        onChange={(date) => {
                          const getItem = { ...allTimeFormData };
                          getItem.completionDate = date;
                          setAllTimeFormData(getItem);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>

                {(ExpenditureGoods === "goods" ||
                  ExpenditureGoods === "services") && (
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label=""
                          name="numberformat"
                          id="IndentQty"
                          variant="outlined"
                          className="formTextFieldArea"
                          value={allTimeFormData.grn}
                          onChange={(e) => {
                            const temp = { ...allTimeFormData };
                            temp.grn = e.target.value;
                            setAllTimeFormData(temp);
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>
                )}
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label=""
                        name="numberformat"
                        id="IndentQty"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        variant="outlined"
                        className="formTextFieldArea"
                        //   {...register("remTax")}
                        value={allTimeFormData.tax}
                        onChange={(e) => {
                          const tempReimbList = { ...allTimeFormData };
                          const temp = parseInt(e.target.value);
                          if (temp < 100 && temp >= 0) {
                            tempReimbList.tax = temp;
                            setAllTimeFormData(tempReimbList);
                          }
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label=""
                        name="numberformat"
                        id="IndentQty"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        variant="outlined"
                        className="formTextFieldArea"
                        //   {...register("remTax")}
                        value={allTimeFormData.total}
                        onChange={(e) => {
                          const temp = parseInt(e.target.value);
                          const tempReimbList = { ...allTimeFormData };
                          tempReimbList.total = temp;
                          setAllTimeFormData(tempReimbList);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <IconButton
                    onClick={(e) => {
                      console.log("icon button clicked");
                      const inputElement =
                        document.querySelector(`#file_input`);
                      if (inputElement) {
                        inputElement.click();
                      }
                    }}
                  >
                    <FileArrowUp />
                  </IconButton>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id={`file_input`}
                    onChange={async (e) => {
                      console.log("files are", e.target.files);
                      const files = e.target.files;
                      const data = await getFileDataObj(files[0]);
                      const tempReimbList = { ...allTimeFormData };
                      tempReimbList.document = data;
                      setAllTimeFormData(tempReimbList);
                    }}
                  />
                </td>
                <td>
                  <div className="iconBtnGroup">
                    <Button
                      className="saveBtn"
                      onClick={(e) => {
                        const temp = { ...allTimeFormData, editMode: false };
                        let isValid = true;
                        if (temp.itemDescriptions === "") {
                          toast.error(t("Please_enter_description"));
                          isValid = false;
                        } else if (temp.reason === "") {
                          toast.error(t("please_enter_reason"));
                          isValid = false;
                        } else if (temp.budgetHead === null) {
                          toast.error(t("please_select_budget_head"));
                          isValid = false;
                        } else if (temp.invoiceRcptNo === null) {
                          toast.error(t("please_provide_invoice_number"));
                          isValid = false;
                        } else if (temp.tax === 0 || isNaN(temp.tax)) {
                          toast.error(t("please_provide_tax_amount"));
                          isValid = false;
                        } else if (temp.total === 0 || isNaN(temp.total)) {
                          toast.error(t("please_provide_total_amount"));
                          isValid = false;
                        }
                        if (!isValid) {
                          return;
                        }
                        setReimbursementList((prev) => [...prev, temp]);
                        initializeAllTimeForeData();
                      }}
                    >
                      {t("save")}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              {/* <tr>
                <td colSpan="8">
                  <div className="textOnly tvText">Total Value</div>
                </td>
                <td>
                  <div className="textOnly fw600">0</div>
                </td>
                <td colSpan="1"></td>
              </tr> */}
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
}

export default ReimbursementForm;
