export const BookAppointmentFromNurseStep = [
    {
        target: ".nsAppointmentstepOne",
        content:
          "You can select any doctor's available slot and book an appointment. If you want to check other slots for the doctor, click the 'More Slots' button.",
        disableBeacon: true,
    },
    {
      target: '.nsAppointmentstepTwo',
      content: 'You can search for a doctor by name or speciality.',
      placement: 'right',
      disableBeacon: true,
    },
    {
      target: '.nsAppointmentstepThree',
      content: "Here you can see the Doctor's available slots.",
      placement: 'right',
      disableBeacon: true,
    },
    {
      target: '.nsAppointmentstepFour',
      content: 'Select a time slot to book an appointment.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.nsAppointmentstepFive',
      content: 'Click here to view more available slots for the selected doctor.',
      placement: 'top',
      disableBeacon: true,
    },
    
]