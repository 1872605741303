import React, { useEffect, useRef, useState } from "react";
import "../css/meetings.css";
import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { PencilSquare, Trash3, X, XLg } from "react-bootstrap-icons";
import MeetingsView from "./MeetingsView";
import MeetingsEdits from "./MeetingsEdits";
import {
  cancelEvent,
  getChairpersonOrConvenerList,
  getEventParticipants,
  getMyEventAvailability,
  updateMyEventAvailability,
} from "../../../../../../services/MeetingService";
import { GetLoginUserDetails } from "../../../../../../utils/Utils";
import { toast } from "react-toastify";
import { meetingSocket } from "../../../../../../socket";
import { socketEndPoint } from "../../../../../../constants/url_provider";
import axios from "axios";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { useContext } from "react";
import { AddTabContext } from "../../../../../dashboard/view/DashboardPage";
import MeetingSummary from "./MeetingSummary";
export default function MeetingsContainer({
  selectedEvent,
  setSelectedEvent,
  closeFromDashboard,
  setEventData,
}) {
  const { addTab } = useContext(AddTabContext);
  const loginUser = GetLoginUserDetails();
  const { t } = useTranslation();
  const [editMode, setEditMode] = React.useState(false);

  const [saveChangesClicked, setSaveChangesClicked] = React.useState(false);

  const saveBtnRef = useRef(null);

  const [isConvenor, setIsConvenor] = React.useState(false);
  const [isChairperson, setIsChairPerson] = React.useState(false);

  const [eventParticipants, setEventParticipants] = useState([]);
  const [isAvailable, setIsAvailable] = useState(null);
  const [showTopOptions, setShowTopOptions] = useState(true);
  const [canJoinCall, setCanJoinCall] = useState(true);

  const deleteGoogleCalendarEvent = async () => {
    // toast.info(`Cancelling ${selectedEvent.eventType}, please wait...`);
    toast.info(t("cancellingEvent", { eventType: selectedEvent.eventType }), {
      position: toast.POSITION.TOP_RIGHT,
    });
    setLoading(true);
    setAnchorElMC(null);
    const responseSaveToken = await axios.put(
      `${socketEndPoint}/api/gcal/deleteEvent/${loginUser.userId}/${selectedEvent.eventId}`
    );
    console.log("deleteGoogleCalendarEvent", responseSaveToken);
    // setLoading(false);
    // const response = responseSaveToken;
    if (responseSaveToken.data.returnValue === "1") {
      toast.success(responseSaveToken.data.message);
      closeFromDashboard("refresh");
      setLoading(false);
      meetingSocket.emit("update", {
        userId: selectedEvent.eventId,
        eventParticipants,
      });
    } else if (responseSaveToken.data.returnValue === "2") {
      toast.success(responseSaveToken.data.message);
      closeFromDashboard("refresh");
      setLoading(false);
      meetingSocket.emit("update", {
        userId: selectedEvent.eventId,
        eventParticipants,
      });
    } else {
      toast.error(t("something_went_wrong"));
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("refresh, Meetings Container", selectedEvent);

    if (selectedEvent.eventSource === "google") {
      setShowTopOptions(true);
      setIsConvenor(true);
      return;
    }

    var minutesDifference =
      (new Date(selectedEvent.eventStartTime) - new Date()) / (1000 * 60);

    if (minutesDifference <= 5) {
      setShowTopOptions(false);
    }

    if (
      (new Date(selectedEvent.eventEndTime) - new Date()) / (1000 * 60) <=
      -60
    ) {
      setCanJoinCall(false);
    }

    getChairpersonOrConvenerList(selectedEvent.eventId, "convener").then(
      (response) => {
        console.log(" -->>>", response.data);
        if (response.data && response.data.length > 0) {
          if (response.data[0].userId === loginUser.userId) {
            setIsConvenor(true);
          } else {
            setIsConvenor(false);
          }
        }
      }
    );
    getChairpersonOrConvenerList(selectedEvent.eventId, "chairperson").then(
      (response) => {
        console.log("getChairpersonOrConvenerList-->>>", response.data);
        if (response.data && response.data.length > 0) {
          if (response.data[0].userId === loginUser.userId) {
            setIsChairPerson(true);
          } else {
            setIsChairPerson(false);
          }
        }
      }
    );
    getEventParticipants(selectedEvent.eventId).then((response) => {
      console.log(response.data);
      setEventParticipants(response.data);
      const partDto = response.data.find(
        (user) => user.userId === loginUser.userId
      );
      console.log("getEventParticipants111-->>>", partDto);
      if (partDto.availability === "Y") {
        console.log("getEventParticipants111-->>> true", partDto);
        setIsAvailable(true);
      } else {
        console.log("getEventParticipants111-->>> false", partDto);
        setIsAvailable(false);
      }
    });
  }, [selectedEvent, showTopOptions]);

  const [anchorElMC, setAnchorElMC] = React.useState(null);

  const handleClickMC = (event) => {
    setAnchorElMC(event.currentTarget);
  };

  const handleCloseMC = () => {
    setAnchorElMC(null);
  };

  const openMC = Boolean(anchorElMC);
  const idMC = openMC ? "cancel-event-popover" : undefined;
  const buttonMCRef = useRef(null);
  const buttonMCCancelRef = useRef(null);
  const buttonApplyForAllRec = useRef(null);
  const [loading, setLoading] = useState(false);
  const [cancelEventNote, setCancelEventNote] = React.useState(null);
  const addCNRef = useRef(null);

  const onClickCancelEvent = async () => {
    var minutesDifference =
      (new Date(selectedEvent.eventStartTime) - new Date()) / (1000 * 60);

    if (minutesDifference <= 5) {
      //   toast.error(
      //     `You cannot cancel the ${selectedEvent.eventType.toLowerCase()} within 5 minutes or after the ${selectedEvent.eventType.toLowerCase()} start time`
      //   );
      //   return;
      // }
      toast.error(
        t("cannotCancelEvent", {
          eventType: selectedEvent.eventType.toLowerCase(),
        }),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }

    if (cancelEventNote === null || cancelEventNote.trim() === "") {
      console.log("cancelEventNote");
      addCNRef.current.style.borderColor = "red";
      addCNRef.current.placeholder = "Please add cancellation reason...";
      addCNRef.current.focus();
      return;
    }

    // toast.info(`Cancelling ${selectedEvent.eventType}, please wait...`);
    toast.info(t("cancellingEvent", { eventType: selectedEvent.eventType }), {
      position: toast.POSITION.TOP_RIGHT,
    });
    setLoading(true);
    setAnchorElMC(null);
    // return;
    const response = await cancelEvent(
      selectedEvent.eventId,
      loginUser.userId,
      buttonApplyForAllRec?.current == null
        ? "N"
        : buttonApplyForAllRec?.current.checked
        ? "Y"
        : "N",
      cancelEventNote
    );
    if (response.data.returnValue === "1") {
      toast.success(t("meeting_canceled_successfully"));
      closeFromDashboard("refresh");
      setLoading(false);
      meetingSocket.emit("update", {
        userId: selectedEvent.eventId,
        eventParticipants,
      });
    } else if (response.data.returnValue === "2") {
      toast.success(t("cancellation_applied_to_all_occurrences_of_the_event"));
      closeFromDashboard("refresh");
      setLoading(false);
      meetingSocket.emit("update", {
        userId: selectedEvent.eventId,
        eventParticipants,
      });
    } else {
      toast.error(t("something_went_wrong"));
      setLoading(false);
    }
  };

  const [anchorElUN, setAnchorElUN] = React.useState(null);
  const [unavailNote, setUnavailNote] = React.useState(null);
  const [selectedReason, setSelectedReason] = useState("");

  const handleClickUN = (event) => {
    setAnchorElUN(event.currentTarget);
  };

  const handleCloseUN = () => {
    setAnchorElUN(null);
    setUnavailNote(null);
    setSelectedReason("");
  };

  const openUN = Boolean(anchorElUN);
  const idUN = openUN ? "unavailable-event-popover" : undefined;
  const buttonUNRef = useRef(null);
  const buttonUNCancelRef = useRef(null);
  const buttonApplyForAllRecUN = useRef(null);
  // const [loading, setLoading] = useState(false);

  const addUNRef = useRef(null);

  const setAvailability = async () => {
    setLoading(true);
    console.log("setAvailability", isAvailable, unavailNote);
    if (isAvailable) {
      var minutesDifference =
        (new Date(selectedEvent.eventStartTime) - new Date()) / (1000 * 60);

      if (minutesDifference <= 5) {
        // toast.error(
        //   `You cannot mark yourself unavailable for the ${selectedEvent.eventType.toLowerCase()} within 5 minutes or after the ${selectedEvent.eventType.toLowerCase()} start time`
        // );
        toast.error(
          t("cannotMarkUnavailable", {
            eventType: selectedEvent.eventType.toLowerCase(),
          }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoading(false);
        return;
      }

      // const reason = selectedReason === "Other" ? unavailNote : selectedReason;
      // if (reason == null || reason.trim() === "") {
      //   toast.error("Please give some unavailability reason...", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      //   setLoading(false);
      //   return;
      // }

      if (selectedReason == null || selectedReason.trim() === "") {
        toast.error(t("please_select_a_reason_for_unavailability"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (
        selectedReason === "Other" &&
        (unavailNote == null || unavailNote.trim() === "")
      ) {
        toast.error(t("please_give_some_unavailability_reason"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      // if (unavailNote == null || unavailNote.trim() === "") {
      //   console.log("unavailNote");
      //   addUNRef.current.style.borderColor = "red";
      //   addUNRef.current.placeholder =
      //     "Please give some unavailability reason...";
      //   addUNRef.current.focus();
      //   setLoading(false);
      //   return;
      // }

      // toast.info(
      //   `Updating unavailability for ${selectedEvent.eventType}, please wait...`
      // );
      toast.info(
        t("updatingUnavailability", { eventType: selectedEvent.eventType }),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      setAnchorElUN(null);

      // return;
      const partDto = eventParticipants.find(
        (user) => user.userId === loginUser.userId
      );

      console.log("eventParticipants", partDto);
      // return;

      let reqDto = {
        eventParticipantId: partDto.id,
        availability: isAvailable ? "N" : "Y",
        unAvailabilityReason:
          selectedReason === "Other" ? unavailNote : selectedReason,
        applicableForAllRecurringEvents: "N",
      };

      console.log("setAvailability", partDto);

      console.log("setAvailability reqDto ", reqDto);

      // setLoading(false);
      // return

      let responseUnavail = await updateMyEventAvailability(
        loginUser.userId,
        reqDto
      );
      if (responseUnavail.data.returnValue === "1") {
        toast.success(t("user_marked_unavailable"));
        setIsAvailable(false);
        setUnavailNote(null);
        setSelectedReason("");
        // closeFromDashboard();
        setLoading(false);
        meetingSocket.emit("update", {
          userId: selectedEvent.eventId,
          eventParticipants: eventParticipants,
        });
      } else {
        toast.error(t("something_went_wrong"));
        setLoading(false);
      }
    } else {
      const myDetails = await getMyEventAvailability(
        selectedEvent.eventId,
        loginUser.userId
      );
      let partDto = myDetails.data;
      let reqDto = {
        eventParticipantId: partDto.eventParticipantId,
        availability: isAvailable ? "N" : "Y",
        unAvailabilityReason: null,
        applicableForAllRecurringEvents: "N",
      };

      console.log("setAvailability", partDto);
      let responseUnavail = await updateMyEventAvailability(
        loginUser.userId,
        reqDto
      );
      if (responseUnavail.data.returnValue === "1") {
        toast.success(t("availability_marked_successfully"));
        meetingSocket.emit("update", {
          userId: selectedEvent.eventId,
          eventParticipants: eventParticipants,
        });
        // closeFromDashboard();
        setIsAvailable(true);
        getEventParticipants(selectedEvent.eventId).then((response) => {
          console.log(response.data);
          setEventParticipants(response.data);
          const partDto = response.data.find(
            (user) => user.userId === loginUser.userId
          );
          console.log("getEventParticipants111-->>>", partDto);
          if (partDto.availability === "Y") {
            console.log("getEventParticipants111-->>> true", partDto);
            setIsAvailable(true);
          } else {
            console.log("getEventParticipants111-->>> false", partDto);
            setIsAvailable(false);
          }
        });
        setLoading(false);
      } else {
        toast.error(t("something_went_wrong"));
        setLoading(false);
      }
    }
  };

  const [checkForMeetingEdits, setCheckForMeetingEdits] = useState(0);
  const [pendingActions, setPendingActions] = useState(null);
  const [cancelAnchor, setCancelAnchor] = useState(null);

  const checkAndClose = (event) => {
    if (editMode) {
      setCheckForMeetingEdits(checkForMeetingEdits + 1);
      setCancelAnchor(event.currentTarget);
    } else {
      closeFromDashboard("");
    }
  };

  const [anchorElCV, setAnchorElCV] = React.useState(null);

  const handleCloseCV = () => {
    setAnchorElCV(null);
  };

  const openCV = Boolean(anchorElCV);
  const idCV = openCV ? "anchorElCVPopover" : undefined;

  useEffect(() => {
    console.log("pendingActions", pendingActions);
    if (
      pendingActions &&
      (pendingActions.rescheduled ||
        pendingActions.participantsAdded ||
        pendingActions.participantsDeleted ||
        pendingActions.privateNotesAdded ||
        pendingActions.privateNotesDeleted ||
        pendingActions.convenorChanged ||
        pendingActions.chairpersonChanged)
    ) {
      setAnchorElCV(cancelAnchor);
    } else if (pendingActions) {
      console.log("xxxxxxxxxxxxxxxxxx", cancelAnchor);
      setPendingActions(null);
      if (cancelAnchor?.id === "edit-button") {
        if (editMode) {
          setEditMode(false);
          setCheckForMeetingEdits(0);
        }
      } else {
        closeFromDashboard("");
      }
    }
  }, [pendingActions]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#4c4c4c",
      color: "white",
      maxWidth: 300,
      fontSize: "12px",
      border: "1px solid #4c4c4c",
    },
  }));

  useEffect(() => {
    meetingSocket.on("updateDashboard", async (data) => {
      console.log("useEffect updateDashboard", data);
      handleCloseUN();
      if (selectedEvent.eventSource === "google") {
        setShowTopOptions(true);
        setIsConvenor(true);
        return;
      }

      var minutesDifference =
        (new Date(selectedEvent.eventStartTime) - new Date()) / (1000 * 60);

      if (minutesDifference <= 5) {
        setShowTopOptions(false);
      }

      if (
        (new Date(selectedEvent.eventEndTime) - new Date()) / (1000 * 60) <=
        -60
      ) {
        setCanJoinCall(false);
      }

      getChairpersonOrConvenerList(selectedEvent.eventId, "convener").then(
        (response) => {
          console.log(" -->>>", response.data);
          if (response.data && response.data.length > 0) {
            if (response.data[0].userId === loginUser.userId) {
              setIsConvenor(true);
            } else {
              setIsConvenor(false);
            }
          }
        }
      );
      getChairpersonOrConvenerList(selectedEvent.eventId, "chairperson").then(
        (response) => {
          console.log("getChairpersonOrConvenerList-->>>", response.data);
          if (response.data && response.data.length > 0) {
            if (response.data[0].userId === loginUser.userId) {
              setIsChairPerson(true);
            } else {
              setIsChairPerson(false);
            }
          }
        }
      );
      getEventParticipants(selectedEvent.eventId).then((response) => {
        console.log(response.data);
        setEventParticipants(response.data);
        const partDto = response.data.find(
          (user) => user.userId === loginUser.userId
        );
        console.log("getEventParticipants111-->>>", partDto);
        if (partDto.availability === "Y") {
          console.log("getEventParticipants111-->>> true", partDto);
          setIsAvailable(true);
        } else {
          console.log("getEventParticipants111-->>> false", partDto);
          setIsAvailable(false);
        }
      });
    });
  }, [meetingSocket]);

  const openMeetingSummary = () => {
    console.log("openMeetingSummary", isConvenor, isChairperson, selectedEvent);
    const key = v4();
    addTab({
      key: key,
      title: `${selectedEvent.eventTitle}, summary`,
      content: (
        <MeetingSummary
          handleMenuCloseButton={() => {}}
          roomNumber={selectedEvent.eventLink}
          selectedEvent={selectedEvent}
          tabKey={key}
          isConvenor={isConvenor}
          isChairperson={isChairperson}
        />
      ),
      isDraggable: true,
      type: "Meeting Summary",
    });
  };

  return (
    <>
      <div className="meetingsViewEditPannel">
        {!loading && (
          <div className="meetingVEContainer">
            {showTopOptions && (
              <div className="metngVEHeaderPnl">
                <div className="metVEControlBtnGrp">
                  {selectedEvent.eventSource !== "google" && (
                    <div className="metVEContBtn">
                      {!isConvenor &&
                        !isChairperson &&
                        new Date(selectedEvent?.eventEndTime) - new Date() >
                          0 && (
                          <Button
                            onClick={(event) => {
                              if (isAvailable) {
                                handleClickUN(event);
                              } else {
                                setAvailability();
                              }
                            }}
                            variant="contained"
                            // startIcon={<CheckCircle />}
                          >
                            {isAvailable != null &&
                              isAvailable &&
                              t("unable_to_attend")}
                            {isAvailable != null && !isAvailable && "Attend"}
                          </Button>
                        )}
                      {!isConvenor &&
                        !isChairperson &&
                        new Date(selectedEvent?.eventEndTime) - new Date() <
                          0 && (
                          <Button
                            variant="contained"
                            onClick={openMeetingSummary}
                          >
                            {t("meeting_summary")}
                          </Button>
                        )}

                      {(isConvenor || isChairperson) && (
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                {new Date(selectedEvent?.eventEndTime) -
                                  new Date() >
                                0
                                  ? t(
                                      "please_change_the_convenor_chairperson_first"
                                    )
                                  : t("view_meeting_summary")}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          {new Date(selectedEvent?.eventEndTime) - new Date() >
                            0 && (
                            <Button
                              variant="contained"
                              style={{ cursor: "not-allowed" }}
                            >
                              {t("unable_to_attend")}
                            </Button>
                          )}
                          {new Date(selectedEvent?.eventEndTime) - new Date() <
                            0 && (
                            <Button
                              variant="contained"
                              onClick={openMeetingSummary}
                            >
                              {t("meeting_summary")}
                            </Button>
                          )}
                        </HtmlTooltip>
                      )}
                      <Popover
                        id={idUN}
                        open={openUN}
                        anchorEl={anchorElUN}
                        onClose={handleCloseUN}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        className="custPopoverModel"
                      >
                        <div style={{ padding: "10px 20px 10px 20px" }}>
                          {/* <div className="modelTitle">Confirmation</div> */}

                          <div
                            className="modInputFild"
                            style={{ padding: "10px" }}
                          >
                            {/* Mark Unavailability for {selectedEvent.eventTitle} ? */}
                            {t("mark_unavailability_for", {
                              eventTitle: selectedEvent.eventTitle,
                            })}{" "}
                            ?
                          </div>

                          <div
                            className="formElement"
                            style={{ padding: "0px 0 10px 0" }}
                          >
                            <FormControl className="formControl">
                              <InputLabel id="unavailability-reason-label">
                                {t("please_select_unavailability_reason")}
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="unavailability-reason-label"
                                value={selectedReason}
                                onChange={(event) =>
                                  setSelectedReason(event.target.value)
                                }
                                label={t("please_select_unavailability_reason")}
                              >
                                <MenuItem value="Another Meeting">
                                  {t("another_meeting")}
                                </MenuItem>
                                <MenuItem value="Sick Leave">
                                  {t("sick_leave")}
                                </MenuItem>
                                <MenuItem value="Leave">{t("leave")}</MenuItem>
                                <MenuItem value="Travel">
                                  {t("travel")}
                                </MenuItem>
                                <MenuItem value="Not relevant to me">
                                  {t("not_relevant_to_me")}
                                </MenuItem>
                                <MenuItem value="Other">{t("other")}</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          {selectedReason === "Other" && (
                            <div
                              className="modInputFild"
                              style={{ padding: "0px 0px 10px 0px" }}
                            >
                              <input
                                value={unavailNote}
                                onChange={(event) =>
                                  setUnavailNote(event.target.value)
                                }
                                type="text"
                                placeholder={t(
                                  "please_type_your_unavailability_reason"
                                )}
                                ref={addUNRef}
                              />
                            </div>
                          )}

                          {/* {selectedEvent.eventType === "Recurring Event" && (
                        <div
                          className="modActionBtnGrp"
                          style={{ padding: "10px 0px 10px 0px" }}
                        >
                          <input type="checkbox" ref={buttonApplyForAllRecUN} />
                          Mark Unavailability for all recurring events
                        </div>
                      )} */}

                          <div className="modActionBtnGrp">
                            <Button
                              className="dfultPrimaryBtn"
                              onClick={() => setAvailability()}
                              ref={buttonUNRef}
                            >
                              {t("yes")}
                            </Button>
                            <Button
                              className="dfultDarkSecondaryBtn"
                              onClick={handleCloseUN}
                              ref={buttonUNCancelRef}
                            >
                              {t("no")}
                            </Button>
                          </div>
                          {/* <div
                                  className="modActionBtnGrp"
                                  style={{ marginTop: "5px" }}
                                >
                                  <Button
                                    className="dfultPrimaryBtn"
                                    onClick={() => removeParticipant("Y")}
                                    // ref={buttonRefRP}
                                  >
                                    Remove from all Recurring Events
                                  </Button>
                                </div> */}
                        </div>
                      </Popover>
                    </div>
                  )}
                </div>

                <div className="metVEActionBtnGrp">
                  {isConvenor && (
                    <div className="metVEActBtn">
                      <IconButton
                        id="edit-button"
                        aria-label="edit"
                        onClick={(e) => {
                          var minutesDifference =
                            (new Date(selectedEvent.eventStartTime) -
                              new Date()) /
                            (1000 * 60);
                          if (minutesDifference <= 5) {
                            // toast.error(
                            //   `You cannot edit the ${selectedEvent.eventType.toLowerCase()} within 5 minutes or after the ${selectedEvent.eventType.toLowerCase()} start time`
                            // );
                            toast.error(
                              t("cannotEditEvent", {
                                eventType:
                                  selectedEvent.eventType.toLowerCase(),
                              }),
                              {
                                position: toast.POSITION.TOP_RIGHT,
                              }
                            );
                            return;
                          }
                          console.log("pendingActions editMode", editMode);
                          if (editMode) {
                            checkAndClose(e);
                          } else {
                            setEditMode(!editMode);
                          }
                        }}
                        className={`${editMode ? "editModeOn" : ""}`}
                      >
                        <PencilSquare />
                      </IconButton>
                    </div>
                  )}
                  {isConvenor && (
                    <div className="metVEActBtn">
                      <IconButton
                        onClick={(event) => {
                          if (selectedEvent.eventSource !== "google") {
                            var minutesDifference =
                              (new Date(selectedEvent.eventStartTime) -
                                new Date()) /
                              (1000 * 60);
                            if (minutesDifference <= 5) {
                              // toast.error(
                              //   `You cannot edit the ${selectedEvent.eventType.toLowerCase()} within 5 minutes or after the ${selectedEvent.eventType.toLowerCase()} start time`
                              // );
                              toast.error(
                                t("cannotEditEvent", {
                                  eventType:
                                    selectedEvent.eventType.toLowerCase(),
                                }),
                                {
                                  position: toast.POSITION.TOP_RIGHT,
                                }
                              );
                              return;
                            }
                          }
                          handleClickMC(event);
                        }}
                        aria-label="delete"
                      >
                        <Trash3 />
                      </IconButton>
                      <Popover
                        id={idMC}
                        open={openMC}
                        anchorEl={anchorElMC}
                        onClose={handleCloseMC}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <div style={{ padding: "10px 20px 10px 20px" }}>
                          {/* <div className="modelTitle">Confirmation</div> */}

                          <div
                            className="modInputFild"
                            style={{ padding: "0px 10px 5px 0px" }}
                          >
                            {/* Delete Event {selectedEvent.eventTitle} ? */}
                            {t("delete_event_title", {
                              eventTitle: selectedEvent.eventTitle,
                            })}{" "}
                            ?
                          </div>
                          {selectedEvent.eventSource !== "google" && (
                            <div
                              className="modInputFild"
                              style={{ padding: "10px 0px 10px 0px" }}
                            >
                              <input
                                value={cancelEventNote}
                                onChange={(event) =>
                                  setCancelEventNote(event.target.value)
                                }
                                type="text"
                                placeholder={t("cancellation_reason")}
                                ref={addCNRef}
                              />
                            </div>
                          )}

                          {selectedEvent.eventType === "Recurring Event" && (
                            <div
                              className="modActionBtnGrp"
                              style={{ padding: "10px 0px 10px 0px" }}
                            >
                              <input
                                type="checkbox"
                                ref={buttonApplyForAllRec}
                              />
                              {t("cancel_all_recurring_events")}
                            </div>
                          )}

                          <div className="modActionBtnGrp">
                            <Button
                              className="dfultPrimaryBtn"
                              onClick={() => {
                                if (selectedEvent.eventSource === "google") {
                                  deleteGoogleCalendarEvent();
                                } else {
                                  onClickCancelEvent();
                                }
                              }}
                              ref={buttonMCRef}
                            >
                              {t("yes")}
                            </Button>
                            <Button
                              className="dfultDarkSecondaryBtn"
                              onClick={handleCloseMC}
                              ref={buttonMCCancelRef}
                            >
                              {t("no")}
                            </Button>
                          </div>
                          {/* <div
                                  className="modActionBtnGrp"
                                  style={{ marginTop: "5px" }}
                                >
                                  <Button
                                    className="dfultPrimaryBtn"
                                    onClick={() => removeParticipant("Y")}
                                    // ref={buttonRefRP}
                                  >
                                    Remove from all Recurring Events
                                  </Button>
                                </div> */}
                        </div>
                      </Popover>
                    </div>
                  )}
                  <div className="metVEActBtn closeBtnD10">
                    <IconButton onClick={checkAndClose} aria-label="close">
                      <XLg />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}
            {!showTopOptions && (
              <>
                <div className="metngVEHeaderPnl">
                  <div className="metVEControlBtnGrp">
                    <div className="metVEContBtn">
                      {!isConvenor &&
                        !isChairperson &&
                        new Date(selectedEvent?.eventEndTime) - new Date() >
                          0 && (
                          <Button
                            onClick={(event) => {
                              if (isAvailable) {
                                handleClickUN(event);
                              } else {
                                setAvailability();
                              }
                            }}
                            variant="contained"
                            // startIcon={<CheckCircle />}
                          >
                            {isAvailable != null &&
                              isAvailable &&
                              t("unable_to_attend")}
                            {isAvailable != null && !isAvailable && t("attend")}
                          </Button>
                        )}

                      {!isConvenor &&
                        !isChairperson &&
                        new Date(selectedEvent?.eventEndTime) - new Date() <
                          0 && (
                          <Button
                            variant="contained"
                            onClick={openMeetingSummary}
                          >
                            {t("meeting_summary")}
                          </Button>
                        )}

                      {(isConvenor || isChairperson) && (
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                {new Date(selectedEvent?.eventEndTime) -
                                  new Date() >
                                0
                                  ? t(
                                      "please_change_the_convenor_chairperson_first"
                                    )
                                  : t("view_meeting_summary")}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          {new Date(selectedEvent?.eventEndTime) - new Date() >
                            0 && (
                            <Button
                              variant="contained"
                              style={{ cursor: "not-allowed" }}
                            >
                              {t("unable_to_attend")}
                            </Button>
                          )}
                          {new Date(selectedEvent?.eventEndTime) - new Date() <
                            0 && (
                            <Button
                              variant="contained"
                              onClick={openMeetingSummary}
                            >
                              {t("meeting_summary")}
                            </Button>
                          )}
                        </HtmlTooltip>
                        // <Tooltip
                        //   title={"Please change the convenor/chairperson first"}
                        // >
                        //   <Button
                        //     // onClick={handleClickUN}
                        //     variant="contained"
                        //     style={{ cursor: "not-allowed" }}
                        //     // startIcon={<CheckCircle />}
                        //   >
                        //     Unable to attend
                        //   </Button>
                        // </Tooltip>
                      )}
                      {/* <Popover
                        id={idUN}
                        open={openUN}
                        anchorEl={anchorElUN}
                        onClose={handleCloseUN}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div style={{ padding: "10px 20px 10px 20px" }}>
                          

                          <div
                            className="modInputFild"
                            style={{ padding: "0px 10px 0px 0px" }}
                          >
                            Mark Unavailability for {selectedEvent.eventTitle} ?
                          </div>
                          <div
                            className="modInputFild"
                            style={{ padding: "10px 0px 10px 0px" }}
                          >
                            <input
                              value={unavailNote}
                              onChange={(event) =>
                                setUnavailNote(event.target.value)
                              }
                              type="text"
                              placeholder="Unavailability reason...."
                              ref={addUNRef}
                            />
                          </div>

                        

                          <div className="modActionBtnGrp">
                            <Button
                              className="dfultPrimaryBtn"
                              onClick={() => setAvailability()}
                              ref={buttonUNRef}
                            >
                              Yes
                            </Button>
                            <Button
                              className="dfultDarkSecondaryBtn"
                              onClick={handleCloseUN}
                              ref={buttonUNCancelRef}
                            >
                              No
                            </Button>
                          </div>
                          
                        </div>
                      </Popover> */}
                      <Popover
                        id={idUN}
                        open={openUN}
                        anchorEl={anchorElUN}
                        onClose={handleCloseUN}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        className="custPopoverModel"
                      >
                        <div style={{ padding: "10px 20px 10px 20px" }}>
                          {/* <div className="modelTitle">Confirmation</div> */}

                          <div
                            className="modInputFild"
                            style={{ padding: "10px" }}
                          >
                            {/* Mark Unavailability for {selectedEvent.eventTitle} ? */}
                            {t("mark_unavailability_for", {
                              eventTitle: selectedEvent.eventTitle,
                            })}{" "}
                            ?
                          </div>

                          <div
                            className="formElement"
                            style={{ padding: "0px 0 10px 0" }}
                          >
                            <FormControl className="formControl">
                              <InputLabel id="unavailability-reason-label">
                                {t("please_select_unavailability_reason")}
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="unavailability-reason-label"
                                value={selectedReason}
                                onChange={(event) =>
                                  setSelectedReason(event.target.value)
                                }
                                label={t("please_select_unavailability_reason")}
                              >
                                <MenuItem value="Another Meeting">
                                  {t("another_meeting")}
                                </MenuItem>
                                <MenuItem value="Sick Leave">
                                  {t("sick_leave")}
                                </MenuItem>
                                <MenuItem value="Leave">{t("leave")}</MenuItem>
                                <MenuItem value="Travel">
                                  {t("travel")}
                                </MenuItem>
                                <MenuItem value="Not relevant to me">
                                  {t("not_relevant_to_me")}
                                </MenuItem>
                                <MenuItem value="Other">{t("other")}</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          {selectedReason === "Other" && (
                            <div
                              className="modInputFild"
                              style={{ padding: "0px 0px 10px 0px" }}
                            >
                              <input
                                value={unavailNote}
                                onChange={(event) =>
                                  setUnavailNote(event.target.value)
                                }
                                type="text"
                                placeholder={t(
                                  "please_type_your_unavailability_reason"
                                )}
                                ref={addUNRef}
                              />
                            </div>
                          )}

                          {/* {selectedEvent.eventType === "Recurring Event" && (
                        <div
                          className="modActionBtnGrp"
                          style={{ padding: "10px 0px 10px 0px" }}
                        >
                          <input type="checkbox" ref={buttonApplyForAllRecUN} />
                          Mark Unavailability for all recurring events
                        </div>
                      )} */}

                          <div className="modActionBtnGrp">
                            <Button
                              className="dfultPrimaryBtn"
                              onClick={() => setAvailability()}
                              ref={buttonUNRef}
                            >
                              {t("yes")}
                            </Button>
                            <Button
                              className="dfultDarkSecondaryBtn"
                              onClick={handleCloseUN}
                              ref={buttonUNCancelRef}
                            >
                              {t("no")}
                            </Button>
                          </div>
                          {/* <div
                                  className="modActionBtnGrp"
                                  style={{ marginTop: "5px" }}
                                >
                                  <Button
                                    className="dfultPrimaryBtn"
                                    onClick={() => removeParticipant("Y")}
                                    // ref={buttonRefRP}
                                  >
                                    Remove from all Recurring Events
                                  </Button>
                                </div> */}
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div className="metVEActBtn closeBtnD10">
                    <IconButton
                      onClick={() => closeFromDashboard("")}
                      aria-label="close"
                    >
                      <XLg />
                    </IconButton>
                  </div>
                </div>
              </>
            )}
            <div className="meetVEContainDtl">
              {!editMode ? (
                <MeetingsView
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                  setEventData={setEventData}
                  showTopOptions={showTopOptions}
                  canJoinCall={canJoinCall}
                />
              ) : (
                <MeetingsEdits
                  editMode={editMode}
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                  saveChangesClicked={saveChangesClicked}
                  saveBtnRef={saveBtnRef}
                  setEditMode={setEditMode}
                  setSaveChangesClicked={setSaveChangesClicked}
                  closeFromDashboard={closeFromDashboard}
                  setShowTopOptions={setShowTopOptions}
                  setCheckForMeetingEdits={setCheckForMeetingEdits}
                  checkForMeetingEdits={checkForMeetingEdits}
                  setPendingActions={setPendingActions}
                  pendingActions={pendingActions}
                />
              )}
            </div>
            {editMode && (
              <div className="metngVEFooterPnl">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => setSaveChangesClicked(true)}
                  ref={saveBtnRef}
                >
                  {t("save_changes")}
                </Button>
                {/* <Button className="dfultSecondaryBtn">Cancel</Button> */}
              </div>
            )}
          </div>
        )}
        {loading && (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        )}
        <Popover
          id={idCV}
          open={openCV}
          anchorEl={anchorElCV}
          onClose={handleCloseCV}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div style={{ padding: "20px" }}>
            <div className="modelTitle">{t("confirmation")}</div>
            <div
              className="modInputFild"
              style={{ padding: "20px 10px 10px 0px" }}
            >
              {t("following_changes_made_will_not_be_saved_discard_changes")}
              <ul>
                {pendingActions?.rescheduled && (
                  <li>{t("re_scheduled_the_meeting")}</li>
                )}
                {pendingActions?.participantsAdded && (
                  <li>{t("added_participants")}</li>
                )}
                {pendingActions?.participantsDeleted && (
                  <li>{t("deleted_participants")}</li>
                )}
                {pendingActions?.privateNotesAdded && (
                  <li>{t("added_private_notes")}</li>
                )}
                {pendingActions?.privateNotesDeleted && (
                  <li>{t("deleted_private_notes")}</li>
                )}
                {pendingActions?.convenorChanged && (
                  <li>{t("changed_the_convenor")}</li>
                )}
                {pendingActions?.chairpersonChanged && (
                  <li>{t("changed_the_chairperson")}</li>
                )}
              </ul>
            </div>

            <div
              className="modActionBtnGrp"
              style={{ justifyContent: "space-between !important" }}
            >
              <Button className="dfultDarkSecondaryBtn" onClick={handleCloseCV}>
                {t("no")}
              </Button>
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX", cancelAnchor);
                  setPendingActions(null);
                  setCheckForMeetingEdits(0);
                  // setEditMode(!editMode);
                  handleCloseCV();
                  if (cancelAnchor?.id === "edit-button") {
                    if (editMode) {
                      setEditMode(false);
                      setCheckForMeetingEdits(0);
                    }
                  } else {
                    closeFromDashboard("");
                  }
                }}
              >
                {t("yes")}
              </Button>
            </div>
          </div>
        </Popover>
      </div>
    </>
  );
}
