import React from "react";

export default function RightFlotingAdmin({ children, className }) {
  return (
    <>
      <div
        className={`AdminRightFlotingContainer ${className ? className : ""}`}
      >
        {children}
      </div>
    </>
  );
}
