import { Add, ArrowBack, Search, Visibility } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React from "react";
import { Download, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function ViewRoutineWork({
  handelCloseViewRoutineWork,
  handelOpenAddRoutineWork,
  closeAll
})
 {
  const { t } = useTranslation();

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton onClick={() => {handelCloseViewRoutineWork(); closeAll();}}>
            <ArrowBack />
          </IconButton>
          {t("routine_work")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_activity")}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddRoutineWork()}
                >
                  {t("routine_work")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              <div className="TableContainer">
                <table className="taskListTable">
                  <thead className="taskListtableHeader">
                    <tr>
                      <th className="width100">{t("sl_no")}</th>
                      <th>{t("activity_name")}</th>
                      <th>{t("date_time")}</th>
                      <th className="width100"></th>
                    </tr>
                  </thead>
                  <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Letter of Intent</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          18-03-2024 &nbsp; 5:30PM
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Visibility />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
