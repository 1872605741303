import React, { useEffect, useRef, useState } from "react";
import asgnUimg from "../../../image/6.png";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTask } from "../../../../../redux/reducers/rightSideTaskPanelSlice";
import dashboardValue from "../../../../../services/DashboardClass";
import DashboardServices from "../../../../../services/DashboardServices";
import {
  getFormattedDate,
  GetLoginUserDetails,
} from "../../../../../utils/Utils";
import { useTimeProgressor } from "../../../../task/view/component/hooks/useTimerProgressor";
import SupportService from "../../../../../services/SupportService";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import {
  ArrowUpRightSquare,
  ArrowUpRightSquareFill,
} from "react-bootstrap-icons";

export default function TaskCard({ task, active, onClick }) {
  const { t } = useTranslation();
  // const [progressWidth, setProgressWidth] = useState(50);
  const selectedTask = useSelector((state) => state.taskPanel.selectedDataId);
  const progress = useRef(0);
  const dispatch = useDispatch();
  const loginUser = GetLoginUserDetails();
  const intervalId = useRef();

  const rightTaskData = useSelector((state) => state.taskPanel.selectedDataId);

  const progressWidth = useTimeProgressor(
    task.taskCreationTime,
    task.taskCompletionTime
  );

  const progressWidthMaxResponseTime = useTimeProgressor(
    task.taskCreationTime,
    task.taskMaxResponseTime
  );

  useEffect(() => {
    // getTaskDifference();
    dashboardValue.consoller();
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  const [lastTicketUpdatedBy, setLastTicketUpdatedBy] = useState([]);

  useEffect(() => {
    if (task.taskId > 0 && task.taskUIComponent === "TaskSupportRequest") {
      console.log("fetchSupportTicketComments TaskCard", task);
      fetchLastTicketUpdatedBy();
    } else {
      console.log("No Comments for Support ticket TaskCard", task);
    }
  }, [task]);

  const fetchLastTicketUpdatedBy = async () => {
    const response = await SupportService.getLastTicketUpdateByUserDetails(
      task.taskId
    );
    setLastTicketUpdatedBy(response.data);
  };

  // const intervalProgressor = (timeDelay) => {
  //   console.log("increasing interval", progressWidth);
  //   if (progressWidth < 100) {
  //     const timeoutValue = setTimeout(() => {
  //       clearTimeout(timeoutValue);
  //       setProgressWidth((prev) => prev + 1);
  //       if (progressWidth < 100) {
  //         intervalProgressor(timeDelay);
  //       }
  //     }, timeDelay);
  //   }
  // };

  // const intervalProgressor = (timeDelay) => {
  //   const interval = setInterval(() => {
  //     setProgressWidth((prev) => {
  //       console.log("interval progressor=", prev, " ", task.taskId);
  //       if (prev < 100) {
  //         progress.current = progress.current + 1;
  //         return prev + 1;
  //       } else {
  //         clearInterval(interval);
  //         return 100;
  //       }
  //     });
  //   }, timeDelay);
  //   intervalId.current = interval;
  // };

  // const getTaskDifference = () => {
  //   // const time1=task.taskCreationTime
  //   // const time2 =task.taskCompletionTime
  //   const creationTime = new Date(task && task.taskCreationTime);
  //   const completionTime = new Date(task && task.taskCompletionTime);
  //   const currentTime = new Date();

  //   //check if already time passed

  //   const diffFromCurrent = currentTime - completionTime;
  //   if (diffFromCurrent > 0) {
  //     setProgressWidth(100);
  //     return;
  //   }
  //   // console.log("before interval progress", task.taskId);

  //   // Calculate the time difference in milliseconds
  //   const totalTimeDifference = completionTime - creationTime;
  //   const timeDifferenceFromNow = currentTime - creationTime;
  //   const milisecondPerPercentIncrease = totalTimeDifference / 100;
  //   const currentPercent = (timeDifferenceFromNow * 100) / totalTimeDifference;
  //   const tempValue = Math.floor(currentPercent);
  //   setProgressWidth(tempValue < 100 ? Math.floor(currentPercent) : 100);
  //   progress.current = Math.floor(currentPercent);
  //   const nextPercent = Math.ceil(currentPercent);
  //   const difference = nextPercent - currentPercent;
  //   const diffInMilisecond = milisecondPerPercentIncrease * difference;
  //   console.log(
  //     "before interval progress",
  //     milisecondPerPercentIncrease,
  //     " ",
  //     task.taskId
  //   );
  //   const clearTimeoutVal = setTimeout(() => {
  //     if (currentPercent < 100) {
  //       clearTimeout(clearTimeoutVal);
  //       setProgressWidth((prev) => prev + 1);
  //       progress.current = progress.current + 1;
  //       if (milisecondPerPercentIncrease > 0) {
  //         intervalProgressor(milisecondPerPercentIncrease);
  //       }
  //     }
  //   }, diffInMilisecond);
  //   console.log("currentPercent", currentPercent, " ", task.taskId);
  //   console.log(
  //     "milisecondPerPercentIncrease = ",
  //     milisecondPerPercentIncrease / 1000
  //   );
  // };

  return (
    <>
      <div
        className={`taskCardContainer ${
          active && selectedTask ? "activeContainer" : ""
        }`}
        onClick={async (e) => {
          const taskNature = task?.taskNature;
          onClick(task);
          console.log(
            taskNature
              ? `Selected task nature is = ${taskNature}`
              : "TaskNature is not present"
          );

          if (
            taskNature === "CC_APPROVED_EXPENDITURE" ||
            taskNature === "CC_APPROVED_EXPENDITURE_WITH_CHANGES"
          ) {
            // const values =
            //   await DashboardServices.getCCDataOfApprovalOfExpenditure(
            //     loginUser.userId,
            //     task.taskId,
            //     taskNature,
            //     task.taskFormDataTransId
            //   );
            // console.log("Value = ", values);
            const values = await DashboardServices.getTaskById(task.taskId);
            dispatch(setSelectedTask({ ...task, ...values.data }));

            dispatch(setSelectedTask({ ...task, ...values.data }));
          } else if (taskNature === null) {
            const values = await DashboardServices.getTaskById(task.taskId);
            dispatch(setSelectedTask({ ...task, ...values.data }));
          } else {
            const values = await DashboardServices.getTaskById(task.taskId);
            dispatch(setSelectedTask({ ...task, ...values.data }));
          }
        }}
      >
        <div className="contMxWidthLim">
          <div className="tskInOutIoc">
            <div className="incomTask ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="24"
                viewBox="0 0 21 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.6524 1.78286V2.77714C14.6524 3.76286 13.8525 4.56 12.874 4.56H8.12602C7.14749 4.56 6.34765 3.76286 6.34765 2.77714V1.78286C6.34765 0.797143 7.14749 0 8.12602 0H12.874C13.8525 0 14.6524 0.797143 14.6524 1.78286ZM17.5964 2.28H15.9287V2.77714C15.9287 4.46571 14.5588 5.84571 12.874 5.84571H8.12602C6.44125 5.84571 5.07131 4.46571 5.07131 2.77714V2.28H3.40357C1.5231 2.28 0 3.81429 0 5.70857V20.5714C0 22.4657 1.5231 24 3.40357 24H17.5964C19.4769 24 21 22.4657 21 20.5714V5.70857C21 3.81429 19.4769 2.28 17.5964 2.28ZM14.2061 15.2281L10.8055 18.614C10.6492 18.7694 10.4375 18.8567 10.2166 18.8567C10.2098 18.8572 10.2029 18.8572 10.196 18.8567C10.1891 18.8573 10.1822 18.8573 10.1753 18.8567C10.0659 18.8571 9.95754 18.8358 9.85647 18.7942C9.75539 18.7525 9.66363 18.6913 9.5865 18.614L6.18583 15.2281C6.03605 15.0714 5.95368 14.8627 5.9563 14.6465C5.95892 14.4302 6.04632 14.2235 6.19985 14.0705C6.35338 13.9175 6.56088 13.8303 6.77808 13.8275C6.99528 13.8247 7.20498 13.9066 7.36243 14.0556L9.35717 16.0386V9.40042C9.35717 9.18056 9.4449 8.9697 9.60104 8.81424C9.75718 8.65877 9.96896 8.57143 10.1898 8.57143C10.4106 8.57143 10.6224 8.65877 10.7785 8.81424C10.9347 8.9697 11.0224 9.18056 11.0224 9.40042V16.0571L13.0295 14.0556C13.1061 13.9754 13.1981 13.9114 13.2999 13.8672C13.4017 13.823 13.5114 13.7996 13.6225 13.7982C13.7336 13.7969 13.8438 13.8177 13.9467 13.8595C14.0496 13.9012 14.143 13.963 14.2216 14.0412C14.3001 14.1195 14.3621 14.2126 14.4039 14.3151C14.4457 14.4175 14.4665 14.5273 14.4651 14.6379C14.4637 14.7485 14.44 14.8577 14.3955 14.9591C14.3511 15.0604 14.2867 15.1519 14.2061 15.2281Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="outGoingTask d-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="24"
                viewBox="0 0 21 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.6524 1.78286V2.77714C14.6524 3.76286 13.8525 4.56 12.874 4.56H8.12602C7.14749 4.56 6.34765 3.76286 6.34765 2.77714V1.78286C6.34765 0.797143 7.14749 0 8.12602 0H12.874C13.8525 0 14.6524 0.797143 14.6524 1.78286ZM17.5964 2.28H15.9287V2.77714C15.9287 4.46571 14.5588 5.84571 12.874 5.84571H8.12602C6.44125 5.84571 5.07131 4.46571 5.07131 2.77714V2.28H3.40357C1.5231 2.28 0 3.81429 0 5.70857V20.5714C0 22.4657 1.5231 24 3.40357 24H17.5964C19.4769 24 21 22.4657 21 20.5714V5.70857C21 3.81429 19.4769 2.28 17.5964 2.28ZM6.21527 12.2005L9.61594 8.81457C9.77218 8.65921 9.98395 8.57191 10.2048 8.57184C10.2116 8.57135 10.2185 8.57135 10.2254 8.57184C10.2323 8.57129 10.2392 8.57129 10.2461 8.57184C10.3555 8.57148 10.4639 8.59275 10.5649 8.63442C10.666 8.67608 10.7578 8.73732 10.8349 8.81457L14.2356 12.2005C14.3854 12.3571 14.4677 12.5658 14.4651 12.7821C14.4625 12.9984 14.3751 13.205 14.2216 13.358C14.068 13.511 13.8605 13.5982 13.6433 13.601C13.4261 13.6038 13.2164 13.522 13.059 13.373L11.0642 11.39V18.0281C11.0642 18.248 10.9765 18.4589 10.8204 18.6143C10.6642 18.7698 10.4524 18.8571 10.2316 18.8571C10.0108 18.8571 9.79902 18.7698 9.64288 18.6143C9.48673 18.4589 9.39901 18.248 9.39901 18.0281V11.3715L7.39187 13.373C7.31527 13.4531 7.22334 13.5172 7.1215 13.5614C7.01966 13.6055 6.90997 13.629 6.79889 13.6303C6.68781 13.6317 6.57758 13.6109 6.4747 13.5691C6.37182 13.5274 6.27837 13.4656 6.19985 13.3873C6.12133 13.3091 6.05933 13.216 6.01751 13.1135C5.97568 13.011 5.95487 12.9013 5.95631 12.7907C5.95775 12.6801 5.9814 12.5709 6.02587 12.4695C6.07035 12.3682 6.13474 12.2767 6.21527 12.2005Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="tskInfoDtl">
            <div className="tskNmPriot">
              <div className="tskTitle">
                <span>{task && task.taskName}</span>
              </div>
              {/* statUrgent statImportant statNormal */}

              <div className="groupbtnPriot">
                {/* {task.taskUIComponent === "TaskSupportRequest" && (
                  <div className="fordBtn">
                    <Tooltip arrow title="Task forwarded">
                      <IconButton className="rippleEffect">
                        <ArrowUpRightSquare />
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow title="Forwarded task completed">
                      <IconButton className="fordTskcompleted ">
                        <ArrowUpRightSquareFill />
                      </IconButton>
                    </Tooltip>
                  </div>
                )} */}
                <div
                  className={`tskPriot ${
                    task && task.taskPriority === 0 && "statNormal"
                  }
                ${task && task.taskPriority === 1 && "statImportant"}
                ${task && task.taskPriority === 2 && "statUrgent"}
                ${task && task.taskPriority === -1 && "statChangeReq"}`}
                >
                  {task.taskUIComponent === "TaskSupportRequest" && (
                    <div className="priStat">
                      {task && task.taskPriority === 0 && "Low"}
                      {task && task.taskPriority === 1 && "Medium"}
                      {task && task.taskPriority === 2 && "High"}
                      {task && task.taskPriority === -1 && "Change Request"}
                    </div>
                  )}
                  {task.taskUIComponent !== "TaskSupportRequest" && (
                    <div className="priStat">
                      {task && task.taskPriority === 0
                        ? "Routine"
                        : task && task.taskPriority === 1
                        ? "Important"
                        : "Critical"}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {task &&
              task.taskUIComponent === "TaskSupportRequest" &&
              task.taskType !== "Rejected" &&
              task.taskCreationTime &&
              task.taskMaxResponseTime && (
                <div className="tskTimeline">
                  <div className="tskTimeHeading">
                    {t("First_Response_Timeline")}
                  </div>
                  <div className="tskTimBar">
                    <div className="progress">
                      <div
                        // up50 up80 up100
                        className={`progress-done ${
                          progressWidthMaxResponseTime < 50
                            ? "up50"
                            : progressWidthMaxResponseTime < 80
                            ? "up80"
                            : "up100"
                        }`}
                        style={{
                          width: `${progressWidthMaxResponseTime}%`,
                          opacity: 1,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="tskDtSE">
                    <div className="tskDTStart">
                      {task && getFormattedDate(task.taskCreationTime)}
                    </div>
                    <div className="tskDTEnd">
                      {task && getFormattedDate(task.taskMaxResponseTime)}
                    </div>
                  </div>
                </div>
              )}
            {task &&
              task.taskType !== "Rejected" &&
              task.taskCreationTime &&
              task.taskCompletionTime && (
                <div className="tskTimeline">
                  <div className="tskTimeHeading">
                    {" "}
                    {task.taskUIComponent === "TaskSupportRequest"
                      ? t("Resolution_Timeline")
                      : t("timeline")}
                  </div>
                  <div className="tskTimBar">
                    <div className="progress">
                      <div
                        // up50 up80 up100
                        className={`progress-done ${
                          progressWidth < 50
                            ? "up50"
                            : progressWidth < 80
                            ? "up80"
                            : "up100"
                        }`}
                        style={{ width: `${progressWidth}%`, opacity: 1 }}
                      ></div>
                    </div>
                  </div>
                  <div className="tskDtSE">
                    <div className="tskDTStart">
                      {task && getFormattedDate(task.taskCreationTime)}
                    </div>
                    <div className="tskDTEnd">
                      {task && getFormattedDate(task.taskCompletionTime)}
                    </div>
                  </div>
                </div>
              )}

            <div className="tskTyp">
              <div className="tskTypLabel">{task && task.taskType}</div>
              {task && task.taskRmks === "" && (
                <div className="tsktypCommnt">{task.taskRmks}</div>
              )}
            </div>
            {task.taskUIComponent !== "TaskSupportRequest" && (
              <div className="tskUser">
                <div className="tskAsgnUsr">
                  <div className="asgnUsrImg">
                    <img src={task && task.taskLeftActionByProfileUrl} alt="" />
                  </div>
                  <div className="tskAsginNM">
                    <div className="tskAsginHead">
                      {task && task.taskLeftActionByLabel}
                    </div>
                    <div className="tskAsginUsrName">
                      {task && task.taskLeftActionByUserName}
                    </div>
                  </div>
                </div>

                {task && task.taskRightActionByLabel && (
                  <div className="tskAsgnUsr">
                    <div className="asgnUsrImg">
                      <img
                        src={task && task.taskRightActionByProfileUrl}
                        alt=""
                      />
                    </div>
                    <div className="tskAsginNM">
                      <div className="tskAsginHead">
                        {task && task.taskRightActionByLabel}
                      </div>
                      <div className="tskAsginUsrName">
                        {task && task.taskRightActionByUserName}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {task.taskUIComponent === "TaskSupportRequest" && (
              <div className="tskUser">
                <div className="tskAsgnUsr">
                  <div className="asgnUsrImg">
                    <img src={task && task.taskLeftActionByProfileUrl} alt="" />
                  </div>
                  <div className="tskAsginNM">
                    <div className="tskAsginHead">
                      {task && task.taskLeftActionByLabel}
                    </div>
                    {task?.taskLeftActionByUserName.split("~").length === 1 && (
                      <div className="tskAsginUsrName">
                        {task.taskLeftActionByUserName}
                      </div>
                    )}
                    {task?.taskLeftActionByUserName.split("~").length > 1 && (
                      <>
                        <div className="tskAsginUsrName">
                          {task.taskLeftActionByUserName.split("~")[0]}
                        </div>
                        <div className="tskAsginUsrName">
                          {task.taskLeftActionByUserName.split("~")[4]}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="tskAsgnUsr">
                  <div className="tskAsginNM" style={{ width: "300px" }}>
                    <div className="tskAsginHead">{t("contact_details")}:</div>
                    {task?.taskLeftActionByUserName.split("~").length > 1 && (
                      <>
                        <div className="tskAsginUsrName">
                          {task.taskLeftActionByUserName.split("~")[1]}
                        </div>
                        <div className="tskAsginUsrName">
                          {task.taskLeftActionByUserName.split("~")[2]}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {task.taskUIComponent === "TaskSupportRequest" &&
              lastTicketUpdatedBy.userId != null && (
                <>
                  <div className="tskTyp">
                    <div className="tskTypLabel">{t("last_updated_by")}</div>
                  </div>
                  <div className="tskAsgnUsr">
                    <div className="asgnUsrImg">
                      <img src={lastTicketUpdatedBy.profileImageLink} alt="" />
                    </div>
                    <div className="tskAsginNM" style={{ width: "300px" }}>
                      <div className="tskAsginHead">
                        {lastTicketUpdatedBy.personName}
                      </div>
                      {lastTicketUpdatedBy.userId ===
                        task.taskLeftActionByUserName.split("~")[3] && (
                        <div className="tskAsginUsrName">
                          <div class="star-text-support">
                            {t("action_required_from_your_end")}
                          </div>
                        </div>
                      )}
                      {lastTicketUpdatedBy.userId !==
                        task.taskLeftActionByUserName.split("~")[3] && (
                        <div className="tskAsginUsrName">
                          {t("action_required_from_user_end")}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
