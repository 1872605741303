import React, { useRef, useState } from "react";
import "./css/terms-conditions.css";
import Logo from "./assets/tc.png";
import FooterLP from "../features/landingPage/view/component/FooterLP";
import NavBarLP from "../features/landingPage/view/component/NavBarLP";
import LoginRegister from "../features/landingPage/view/component/LoginRegister";
import { useTranslation } from "react-i18next";

export default function TermsConditionsZoyel() {
  const { t } = useTranslation();
  const [showLogReg, setShowLogReg] = useState(false);

  const handelCloseLogRegContain = () => {
    setShowLogReg(false);
  };

  const handelOpenLogRegContain = () => {
    setShowLogReg(true);
  };

  const scrollableNavRef = useRef(null);
  return (
    <>
      <div className="lp-full-container" ref={scrollableNavRef}>
        <NavBarLP
          handelOpenLogRegContain={handelOpenLogRegContain}
          scrollableNavRef={scrollableNavRef}
        />
        {showLogReg && (
          <LoginRegister handelCloseLogRegContain={handelCloseLogRegContain} />
        )}
        <>
          <div class="tcmaindiv">
            <div class="tcleft">
              <div class="tcimagearea">
                <img src={Logo} class="trmcndimage" alt="imgLogo" />
              </div>
            </div>

            <div class="tcright">
              <div class="tctextarea">
                <div class="tcmainheading">
                  <h1 class="tcheading">
                  {t("terms")}<span class="cndtn"> {t("and")} </span>{t("conditions")}
                  </h1>
                </div>
                <h3 class="tcsubline">{t("acceptance")}</h3>
                <div class="tctext">
                  {/* <p class="tcsubtext">
                    It is important that you read all the following terms and
                    conditions carefully. This Terms of Use Agreement
                    ("Agreement") is a legal agreement between you and the Zoyel
                    Technologies LLC owner and operator ("Owner") of this Web
                    site (the "Web site"). It states the terms and conditions
                    under which you may access and use the Web site and all
                    written and other materials displayed or made available
                    through the Web site, including, without limitation,
                    articles, text, photographs, images, illustrations, audio
                    clips, video clips, computer software and code (the
                    "Content"). By accessing and using the Web site, you are
                    indicating your acceptance to be bound by the terms and
                    conditions of this Agreement. If you do not accept these
                    terms and conditions, you must not access or use the Web
                    site. The Owner may revise this Agreement at any time by
                    updating this posting. Use of the Web site after such
                    changes are posted will signify your acceptance of these
                    revised terms. You should visit this page periodically to
                    review this Agreement.
                  </p> */}
                    <p class="tcsubtext">
                    {t("acceptance_text_details")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="tcsubtextarea">
            <div class="subheadingtc">
              <h2 class="tcsubheading">{t("no_endorsements")}</h2>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  Unless specifically stated, the Owner does not recommend or
                  endorse any specific brand of products, services, procedures
                  or other information that appears or that may be advertised on
                  the Web site.
                </p> */}
                 <p class="tcabout">
                 {t("no_endorsements_text_details")}
                </p>
              </div>
            </div>

            <div class="subheadingtc">
              <h3 class="tcsubline">{t("disclaimer_of_warranties")}</h3>
              {/* <p class="tcabout">
                The Web site and the Content are provided "AS IS". While the
                Owner endeavours to provide information that is correct,
                accurate, current and timely, the Owner makes no
                representations, warranties or covenants, express or implied,
                regarding the Web site and the Content including, without
                limitation, no representation, warranty or covenant that
              </p> */}
               <p class="tcabout">
               {t("disclaimer_of_warranties_text_details")}
              </p>
              <div class="tclinetextarea">
                <ul class="tcul">
                  <li>
                    {/* <p class="tcabout">
                      The Content contained in or made available through the Web
                      site or any item(s) made available on or through the Web
                      site will be of merchantable quality and/or fit for a
                      particular purpose.{" "}
                    </p> */}
                    <p class="tcabout">
                    {t("content_quality")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      The Web site or Content will be accurate, complete,
                      current, reliable, timely or suitable for any particular
                      purpose.
                    </p> */}
                    <p class="tcabout">
                    {t("accuracy")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      That the operation of the Web site will be uninterrupted
                      or error-free.
                    </p> */}
                    <p class="tcabout">
                    {t("operation")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      That defects or errors in the Web site or the Content, be
                      it human or computer errors, will be corrected.
                    </p> */}
                     <p class="tcabout">
                     {t("error_correction")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      That the Web site will be free from viruses or harmful
                      components.
                    </p> */}
                    <p class="tcabout">
                    {t("virus_free")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      That communications to or from the Web site will be secure
                      and/or not intercepted. You acknowledge and agree that
                      your access and use of the Web site and the Content is
                      entirely at your own risk and liability.
                    </p> */}
                    <p class="tcabout">
                    {t("security_details")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">{t("limitation_of_liability")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  In no event shall the Owner, its officers, directors,
                  employees, agents, licensors, and their respective successors
                  and assigns be liable for damages of any kind, including,
                  without limitation, any direct, special, indirect, punitive,
                  incidental or consequential damages including, without
                  limitation, any loss or damages in the nature of, or relating
                  to, lost business, inaccurate information, or any other loss
                  incurred in connection with your use, misuse or reliance upon
                  the Web site or the Content, or your inability to use the Web
                  site, regardless of the cause and whether arising in contract
                  (including fundamental breach), tort (including negligence),
                  or otherwise. The foregoing limitation shall apply even if the
                  Owner knew of or ought to have known of the possibility of
                  such damages.
                </p> */}
                <p class="tcabout">
                {t("liability_statement")}
                </p>

                {/* <p class="tcabout">
                  The Owner also expressly disclaims any and all liability for
                  the acts, omissions and conduct of any third-party user of the
                  Web site, or any advertiser or sponsor of the Web site
                  ("third-party"). Under no circumstances shall the Owner, its
                  officers, directors, employees, agents, licensors and their
                  respective successors and assigns, be liable for any injury,
                  loss, damage (including direct, special, indirect, punitive,
                  incidental or consequential damages), or expense arising in
                  any manner whatsoever from
                </p> */}
                 <p class="tcabout">
                 {t("disclaimer")}
                </p>
                <ul class="tcul">
                  <li>
                    {/* <p class="tcabout">
                      The acts, omissions or conduct of any third-party.
                    </p> */}
                     <p class="tcabout">
                     {t("third_party_actions")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Any access, use, reliance upon or inability to use any
                      materials, content, goods or services located at, or made
                      available at, any Web site linked to or from the Web site,
                      regardless of the cause and whether arising in contract
                      (including fundamental breach), tort (including
                      negligence), or otherwise. The foregoing limitation shall
                      apply even if the owner knew of or ought to have known of
                      the possibility of such damages.
                    </p> */}
                    <p class="tcabout">
                    {t("linked_sites")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">{t("indemnity")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  You agree to indemnify, defend and hold harmless the Owner and
                  its officers, directors, employees, agents, licensors, and
                  their respective successors and assigns, from and against any
                  and all claims, demands, liabilities, costs, or expenses
                  whatsoever, including, without limitation, legal fees and
                  disbursements, resulting directly or indirectly from
                </p> */}
                <p class="tcabout">
                {t("indemnity_statement")}
                </p>

                <ul class="tcul">
                  <li>
                    {/* <p class="tcabout">
                      Your breach of any of the terms and conditions of this
                      Agreement.
                    </p> */}
                    <p class="tcabout">
                    {t("breach_terms")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Your access to, use, misuse, reliance upon or inability to
                      access or use the Web site, the Content or any Web site to
                      which the Web site is or may be linked to from time to
                      time or
                    </p> */}
                    <p class="tcabout">
                    {t("website_access")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Your use of, reliance on, publication, communication,
                      distribution, uploading or downloading of anything
                      (including the Content) on or from the Web site.
                    </p> */}
                    <p class="tcabout">
                    {t("content_use")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">{t("copy_right_in_caps")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  The Content is protected by copyright law and is owned by the
                  Owner and its licensors, or the party accredited as the
                  provider of the Content. Except as granted in the limited
                  licence herein, any use of the Content, including
                  modification, transmission, presentation, distribution,
                  republication, or other exploitation of the Web site or of its
                  Content, whether in whole or in part, is prohibited without
                  the express prior written consent of the Owner.
                </p> */}
                <p class="tcabout">
                {t("content_protection")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">{t("limited_license")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  Subject to the terms and conditions of this Agreement, you are
                  hereby granted a limited, non-transferable and non-exclusive
                  licence to access, view and use the Web site and the Content.
                  for your personal, non-commercial use. You are granted the
                  right to download, store and/or print single copies of items
                  comprising the content for your personal, non-commercial use,
                  provided that you maintain all copyright and other notices
                  contained in such content. You may not copy and/or repost
                  items comprising the Content online. You must also abide by
                  any additional requirements governing the use of any specific
                  Content that may be set out in the Web site. In the event of a
                  conflict between the terms of a licence governing specific
                  content and this Agreement, the terms of the specific licence
                  shall govern.
                </p> */}
                 <p class="tcabout">
                 {t("limited_licence_term_condition")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">{t("mise_en_relation")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  The Web site may contain links to third-party Web sites. These
                  links are provided solely as a convenience to you and not as
                  an endorsement by the owner of any third-party Web site or the
                  content thereof. Unless expressly stated, the Owner does not
                  operate any third-party Web site linked to the Web site and is
                  not responsible for the content of any third-party Web site,
                  nor does it make any representation, warranty or covenant of
                  any kind regarding any third-party Web site including, without
                  limitation
                </p> */}
                <p class="tcabout">
                {t("third_party_links_term_and_condition")}
                </p>

                <ul class="tcul">
                  <li>
                    {/* <p class="tcabout">
                      Any representation, warranty or covenant regarding the
                      legality, accuracy, reliability, completeness, timeliness
                      or suitability of any content on such third-party Web
                      sites,
                    </p> */}
                     <p class="tcabout">
                     {t("third_party_content_disclaimer")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Any representation, warranty or covenant regarding the
                      merchantability and/or fitness for a particular purpose of
                      any third-party Web sites or material, content, software,
                      goods, or services located at or made available through
                      such third-party Web sites, or
                    </p> */}
                    <p class="tcabout">
                    {t("third_party_merchantability_disclaimer")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Any representation, warranty or covenant that the
                      operation of such third-party Web sites will be
                      uninterrupted or error free, that defects or errors in
                      such third-party Web sites will be corrected, or that such
                      third-party Web sites will be free from viruses or other
                      harmful components. While the Owner encourages links to
                      the Web site, it does not wish to be linked to or from any
                      third-party Web site which contains, posts or transmits
                      any unlawful or indecent information of any kind,
                      including, without limitation
                    </p> */}
                    <p class="tcabout">
                    {t("third_party_website_disclaimer")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Any content constituting or encouraging conduct that would
                      constitute a criminal offense, give rise to civil
                      liability or otherwise violate any local, state,
                      provincial, national, international law or regulation
                      which may be damaging or detrimental to the activities,
                      operations, credibility or integrity of the Owner, or
                    </p> */}
                    <p class="tcabout">
                    {t("criminal_offense_content")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      The Owner reserves the right any time, and from time to
                      time, to modify or discontinue, temporarily or
                      permanently, the Web site (or any part thereof) with or
                      without notice to you. The Owner shall have no liability
                      to you or any third party for any modifications,
                      suspension or discontinuance of the Web site or any part
                      thereof.
                    </p> */}
                    <p class="tcabout">
                    {t("owner_right_to_modify")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="subtcheading">
              <h3 class="tcsublinetxt">{t("user_content")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  In these terms and conditions of use, "your content" means
                  material (including without limitation text, images, audio
                  material, video material and audio-visual material) that you
                  submit to our website, for whatever purpose.
                </p> */}
                 <p class="tcabout">
                 {t("your_content_definition")}
                </p>


                {/* <p class="tcabout">
                  You grant to us a worldwide, irrevocable, non-exclusive,
                  royalty-free licence to use, reproduce, adapt, publish,
                  translate and distribute your content in any existing or
                  future media. You also grant to us the right to sub-license
                  these rights, and the right to bring an action for
                  infringement of these rights. You warrant and represent that
                  your content will comply with these terms and conditions. Your
                  content must not be illegal or unlawful, must not infringe any
                  third party's legal rights, and must not be capable of giving
                  rise to legal action whether against you or us or a third
                  party (in each case under any applicable law).
                </p> */}
                <p class="tcabout">
                {t("content_license_term_and_condition")}
                </p>

                {/* <p class="tcabout">
                  Your content (and its publication on our website) must not:
                </p> */}
                 <p class="tcabout">
                 {t("content_publication_restrictions")}
                </p>

                <ul class="tcul">
                  <li>
                    {/* <p class="tcabout">Be libellous or maliciously false.</p> */}
                    <p class="tcabout">{t("be_libellous_or_maliciously_false")}</p>
                  </li>
                  <li>
                    {/* <p class="tcabout">Be obscene or indecent.</p> */}
                    <p class="tcabout">{t("be_obscene_or_indecent")}</p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Infringe any copyright, moral right, database right, trade
                      mark right, design right, right in passing off, or other
                      intellectual property right.
                    </p> */}
                     <p class="tcabout">
                     {t("infringe_intellectual_property")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Infringe any right of confidence, right of privacy, or
                      right under data protection legislation.
                    </p> */}
                    <p class="tcabout">
                    {t("infringe_rights")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Constitute negligent advice or contain any negligent
                      statement.
                    </p> */}
                     <p class="tcabout">
                     {t("negligent_advice")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Constitute an incitement to commit a crime.
                    </p> */}
                     <p class="tcabout">
                     {t("constitute_an_incitement_to_commit_a_crime")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Be in contempt of any court, or in breach of any court
                      order.
                    </p> */}
                    <p class="tcabout">
                    {t("be_in_contempt_of_any_court_or_in_breach_of_any_court_order")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Be in breach of racial or religious hatred or
                      discrimination legislation.
                    </p> */}
                     <p class="tcabout">
                     {t("racial_or_religious_hatred")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">Be blasphemous.</p> */}
                    <p class="tcabout">{t("blasphemous")}</p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Be in breach of official secrets legislation.
                    </p> */}
                    <p class="tcabout">
                    {t("breach_of_official_secrets")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Be in breach of any contractual obligation owed to any
                      person.
                    </p> */}
                    <p class="tcabout">
                    {t("breach_of_contractual_obligation")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Depict violence in an explicit, graphic or gratuitous
                      manner.
                    </p> */}
                    <p class="tcabout">
                    {t("depict_violence")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">Be pornographic or sexually explicit.</p> */}
                    <p class="tcabout">{t("pornographic")}</p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Be untrue, false, inaccurate or misleading.
                    </p> */}
                    <p class="tcabout">
                    {t("false_misleading")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Consist of or contain any instructions, advice or other
                      information which may be acted upon and could, if acted
                      upon, cause illness, injury or death, or any other loss or
                      damage.
                    </p> */}
                    <p class="tcabout">
                    {t("cause_damage")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">Constitute spam</p> */}
                    <p class="tcabout">{t("spam")}</p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Be offensive, deceptive, threatening, abusive, harassing,
                      or menacing, hateful, discriminatory or inflammatory.
                    </p> */}
                    <p class="tcabout">
                    {t("offensive")}
                    </p>
                  </li>
                  <li>
                    {/* <p class="tcabout">
                      Cause annoyance, inconvenience or needless anxiety to any
                      person.
                    </p> */}
                    <p class="tcabout">
                    {t("cause_inconvenience")}
                    </p>
                  </li>
                </ul>
                {/* <p class="tcabout">
                  Your content must be appropriate, civil, tasteful and accord
                  with generally accepted standards of etiquette and behaviour
                  on the internet. You must not use our website to link to any
                  website or web page consisting of or containing material that
                  would, were it posted on our website, breach the provisions of
                  these terms and conditions.
                </p> */}
                 <p class="tcabout">
                 {t("appropriate_terms_and_conditions")}
                </p>
                {/* <p class="tcabout">
                  You must not submit any user content to the website that is or
                  has ever been the subject of any threatened or actual legal
                  proceedings or other similar complaint.
                </p> */}
                <p class="tcabout">
                {t("no_legal_issues")}
                </p>
                {/* <p class="tcabout">
                  We reserve the right to edit or remove any material submitted
                  to our website, or stored on our servers, or hosted or
                  published upon our website.
                </p> */}
                 <p class="tcabout">
                 {t("edit_or_remove_content")}
                </p>
                {/* <p class="tcabout">
                  Notwithstanding our rights under these terms and conditions in
                  relation to user content, we do not undertake to monitor the
                  submission of such content to, or the publication of such
                  content on, our website.
                </p> */}
                <p class="tcabout">
                 {t("no_monitoring_undertaking")}
                </p>
                {/* <p class="tcabout">
                  If you become aware of any content on the website that
                  breaches these terms and conditions, please notify us
                  immediately by email
                </p> */}
                <p class="tcabout">
                 {t("notify_breaches")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">SECURITY</h3> */}
              <h3 class="tcsublinetxt">security_in_caps</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  Any information sent or received over the Internet is
                  generally not secure. The Owner cannot guarantee the security
                  or confidentiality of any communication to or from the Web
                  site.
                </p> */}
                <p class="tcabout">
                {t("internet_not_secure")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">MODIFICATION OF THE WEBSITE</h3> */}
              <h3 class="tcsublinetxt">{t("modification_of_website_title")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  The Owner reserves the right any time, and from time to time,
                  to modify or discontinue, temporarily or permanently, the Web
                  site (or any part thereof) with or without notice to you. The
                  Owner shall have no liability to you or any third party for
                  any modifications, suspension or discontinuance of the Web
                  site or any part thereof.
                </p> */}
                <p class="tcabout">
                {t("modification_of_website_content")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">USE PROHIBITED WHERE CONTRARY TO LAW</h3> */}
              <h3 class="tcsublinetxt">{t("useProhibited_where_contrary_to_law_title")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  Use of this Web site is unauthorized in any jurisdiction where
                  the Web site or any of the Content may violate any laws or
                  regulations. You agree not to access or use the Web site in
                  such jurisdictions. You agree that you are responsible for
                  compliance with all applicable laws or regulations. Any
                  contravention of this provision (or any provision of this
                  Agreement) is entirely at your own risk.
                </p> */}
                <p class="tcabout">
                {t("useProhibited_where_contrary_to_law_content")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">GOVERNING LAW AND JURISDICTION</h3> */}
              <h3 class="tcsublinetxt">{t("governing_law_and_jurisdiction_title")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  You agree that all matters relating to your access, or use of
                  the Web site and its Content{" "}
                  <span>
                    shall be governed by the applicable laws without regard to
                    conflict of laws principles.
                  </span>{" "}
                  You agree and hereby submit to the exclusive and preferential
                  jurisdiction of the courts of the Province applicable with
                  respect to all matters relating to your access and use of the
                  Web site and the Content as well as any dispute that may arise
                  there-from.
                </p> */}
                <p class="tcabout">
                {t("governing_law_and_jurisdiction_content")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">WAIVER</h3> */}
              <h3 class="tcsublinetxt">{t("waiver_title")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  Any consent by the Owner to, or waiver of, a breach of this
                  Agreement which you have committed, whether express or
                  implied, shall not constitute a consent to, or waiver of any
                  other, different or subsequent breach.
                </p> */}
                 <p class="tcabout">
                 {t("waiver_content")}
                </p>
              </div>
            </div>
            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">SEVERABILITY</h3> */}
              <h3 class="tcsublinetxt">{t("severability_title")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  The invalidity or unenforceability of any provision of this
                  Agreement or any covenant contained herein shall not affect
                  the validity or enforceability of any other provision or
                  covenant contained herein and any such invalid provision or
                  covenant shall be deemed severable from the rest of this
                  Agreement.
                </p> */}
                <p class="tcabout">
                {t("severability_content")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">NOTICE</h3> */}
              <h3 class="tcsublinetxt">{t("notice_title")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  Questions or comments regarding the Web site should be
                  directed by e-mail to noreply@zoyel.one
                </p> */}
                 <p class="tcabout">
                 {t("notice_content")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">TERMINATION</h3> */}
              <h3 class="tcsublinetxt">{t("termination_title")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  The Owner may, in its sole discretion, cancel or terminate
                  your right to use the Web site, or any part of the Web site,
                  at any time without notice. In the event of termination, you
                  are no longer authorized to access the Web site, or the part
                  of the Web site affected by such cancellation or termination.
                  The restrictions imposed on you with respect to material
                  downloaded from the Web site and the disclaimers and
                  limitations of liabilities set forth in this Agreement, shall
                  survive termination of this Agreement. The Owner shall not be
                  liable to any party for such termination.
                </p> */}
                 <p class="tcabout">
                 {t("termination_content")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">WEBSITE AVAILABILITY</h3> */}
              <h3 class="tcsublinetxt">{t("website_availability_title")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  From time to time the website or features of the website will
                  be unavailable. Such unavailability may be the result of
                  defects in the website software, scheduled or emergency
                  maintenance procedures, or failures of third party service
                  providers.
                </p> */}
                <p class="tcabout">
                {t("website_availability_content1")}
                </p>

                {/* <p class="tcabout">
                  We do not commit to ensuring that the website will be
                  available at any particular time.
                </p> */}
                <p class="tcabout">
                {t("website_availability_content2")}
                </p>
                

                {/* <p class="tcabout">
                  Furthermore, we do not commit to ensure that the website will
                  continue to be published in the future.
                </p> */}
                 <p class="tcabout">
                {t("website_availability_content3")}
                </p>
              </div>
            </div>

            <div class="subtcheading">
              {/* <h3 class="tcsublinetxt">ENTIRE AGREEMENT</h3> */}
              <h3 class="tcsublinetxt">{t("entire_agreement_title")}</h3>
              <div class="tclinetextarea">
                {/* <p class="tcabout">
                  This is the entire Agreement between you and the Owner
                  relating to your access and use of the Web site.
                </p> */}
                <p class="tcabout">
                {t("entire_agreement_content")}
                </p>
              </div>
            </div>
          </div>
        </>
        <FooterLP />
      </div>
    </>
  );
}
