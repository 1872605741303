import {
  Autocomplete,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Search, SortDown, SortUp } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { socketEndPoint } from "../../../../../../../constants/url_provider";
import DashboardServices from "../../../../../../../services/DashboardServices";
import DateUtils from "../../../../../../../utils/DateUtils";
import {
  calculateDateRangeByRange,
  GetLoginUserDetails,
} from "../../../../../../../utils/Utils";
import { getHealthDto } from "../../../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";

export default function DetailsAnalyticTable() {
  const { t } = useTranslation();
  const loginUser = GetLoginUserDetails();

  const [specializationList, setSpecializationList] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [selectedRange, setSelectedRange] = useState("Today");
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [sortedBy, setSortedBy] = useState({
    columnName: null,
    direction: null,
  });

  useEffect(() => {
    getSpecializations();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { startDate, endDate } = calculateDateRangeByRange(selectedRange);
      const reqDto = {
        orgId: loginUser.orgId,
        specilizationId: selectedSpecialization.specializationId,
        fromDate: DateUtils.getDateInDDMMYYYY(startDate),
        toDate: DateUtils.getDateInDDMMYYYY(endDate),
      };
      const response =
        await DashboardServices.getDoctorConsultationWaitTimeConsutTime(reqDto);
      const response2 = await axios.get(
        `${socketEndPoint}/api/health/getOnlineDoctor`
      );
      const doctorList = [];
      const onlineList = response2.data || [];
      if (response.data) {
        for (let index = 0; index < response.data.length; index++) {
          const obj = response.data[index];
          if (onlineList.includes(obj.doctorId)) {
            obj.status = "Online";
          } else {
            obj.status = "Offline";
          }
          doctorList.push(obj);
        }
      }
      setDataList([...doctorList]);
      setFilterDataList([...doctorList]);
    };
    if (selectedRange && selectedSpecialization) {
      fetchData();
    }
  }, [selectedSpecialization, selectedRange]);

  useEffect(() => {
    if (searchKey !== "") {
      const key = searchKey.toLowerCase();
      const searchableFields = [
        "doctorName",
        "doctorSpecialization",
        "numberOfConsult",
        "numberOfCenter",
        "avgWaitingTime",
        "avgConsultationTime",
        "status",
      ];
      const tempList = dataList.filter((item) =>
        searchableFields.some((field) =>
          item[field].toString().toLowerCase().includes(key)
        )
      );
      setFilterDataList([...tempList]);
    } else {
      setFilterDataList([...dataList]);
    }
  }, [searchKey]);

  const getSpecializations = async () => {
    const temp = { specializationId: 0, specializationName: t("all") };
    const lifeStyleString = await SecureIndexedDB.getItem("specializationList");
    if (lifeStyleString) {
      let tempList = [...[temp], ...JSON.parse(lifeStyleString)];
      setSpecializationList(tempList);
      setSelectedSpecialization(temp);
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getSpecializations(reqDto).then((response) => {
      if (response.data) {
        let tempList = [...[temp], ...response.data];
        setSpecializationList(tempList);
        setSelectedSpecialization(temp);
        SecureIndexedDB.setItem(
          "specializationList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const handleSort = (columnName) => {
    let direction = "ascending";
    if (
      sortedBy.columnName === columnName &&
      sortedBy.direction === "ascending"
    ) {
      direction = "descending";
    }

    const sortedData = [...filterDataList].sort((a, b) => {
      const aValue = a[columnName];
      const bValue = b[columnName];

      if (aValue == null || bValue == null) return 0;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return direction === "ascending"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return direction === "ascending" ? aValue - bValue : bValue - aValue;
      }

      if (dayjs(aValue).isValid() && dayjs(bValue).isValid()) {
        return direction === "ascending"
          ? dayjs(aValue).diff(dayjs(bValue))
          : dayjs(bValue).diff(dayjs(aValue));
      }

      return 0;
    });

    setFilterDataList([...sortedData]);
    setSortedBy({ columnName, direction });
  };

  return (
    <>
      <div className="detailsAnalyticTableContainer">
        <div className="cuHeaderGrp">
          <div className="formSearchGrp">
            <div className="searchTasklist">
              <input
                type="text"
                class="serchTaskInput"
                placeholder={t("search")}
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <IconButton className="seacrhIcon">
                <Search />
              </IconButton>
            </div>
          </div>
          <div className="addUnitsBtn">
            {specializationList && specializationList.length > 0 && (
              <FormControl
                size="small"
                className="formControl SpecialityFilter"
              >
                <Autocomplete
                  freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedSpecialization}
                  options={specializationList}
                  onChange={(e, newValue) => {
                    console.log(newValue);
                    setSelectedSpecialization(newValue);
                  }}
                  getOptionLabel={(option) => option.specializationName}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.specializationId}>
                        {item.specializationName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("select_speciality")}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            )}

            <FormControl size="small" className="formControl w110">
              <Select
                className="formInputField"
                labelId="filterBy"
                id="filterBy-select"
                value={selectedRange}
                onChange={(e) => setSelectedRange(e.target.value)}
              >
                <MenuItem value="Today">{t("Today")}</MenuItem>
                <MenuItem value="Yesterday">{t("Yesterday")}</MenuItem>
                <MenuItem value="Last 7 Days">{t("Last_7_Days")}</MenuItem>
                <MenuItem value="This Week">{t("This_Week")}</MenuItem>
                <MenuItem value="Last Week">{t("Last_Week")}</MenuItem>
                <MenuItem value="This Month">{t("This_Month")}</MenuItem>
                <MenuItem value="Last Month">{t("Last_Month")}</MenuItem>
                <MenuItem value="Last 30 Days">{t("Last_30_Days")}</MenuItem>
                <MenuItem value="All Time">{t("All_Time")}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="TableContainer organizationPosition responsiveTable">
          <table className="taskListTable">
            <thead className="taskListtableHeader">
              <tr>
                <th onClick={() => handleSort("doctorName")}>
                  {t("dr_name")}
                  {sortedBy.columnName === "doctorName" && (
                    <span>
                      {sortedBy.direction === "ascending" ? (
                        <IconButton className="filterActive">
                          <SortDown />
                        </IconButton>
                      ) : (
                        <IconButton className="filterActive">
                          <SortUp />
                        </IconButton>
                      )}
                    </span>
                  )}
                </th>
                <th onClick={() => handleSort("doctorSpecialization")}>
                  {t("specialization")}
                  {sortedBy.columnName === "doctorSpecialization" && (
                    <span>
                      {sortedBy.direction === "ascending" ? (
                        <IconButton className="filterActive">
                          <SortDown />
                        </IconButton>
                      ) : (
                        <IconButton className="filterActive">
                          <SortUp />
                        </IconButton>
                      )}
                    </span>
                  )}
                </th>
                <th onClick={() => handleSort("numberOfConsult")}>
                  {t("no_of_consultation")}
                  {sortedBy.columnName === "numberOfConsult" && (
                    <span>
                      {sortedBy.direction === "ascending" ? (
                        <IconButton className="filterActive">
                          <SortDown />
                        </IconButton>
                      ) : (
                        <IconButton className="filterActive">
                          <SortUp />
                        </IconButton>
                      )}
                    </span>
                  )}
                </th>
                <th onClick={() => handleSort("numberOfCenter")}>
                  {t("no_of_center")}
                  {sortedBy.columnName === "numberOfCenter" && (
                    <span>
                      {sortedBy.direction === "ascending" ? (
                        <IconButton className="filterActive">
                          <SortDown />
                        </IconButton>
                      ) : (
                        <IconButton className="filterActive">
                          <SortUp />
                        </IconButton>
                      )}
                    </span>
                  )}
                </th>
                <th onClick={() => handleSort("avgWaitingTime")}>
                  {t("avg_waiting_time")}
                  {sortedBy.columnName === "avgWaitingTime" && (
                    <span>
                      {sortedBy.direction === "ascending" ? (
                        <IconButton className="filterActive">
                          <SortDown />
                        </IconButton>
                      ) : (
                        <IconButton className="filterActive">
                          <SortUp />
                        </IconButton>
                      )}
                    </span>
                  )}
                </th>
                <th onClick={() => handleSort("avgConsultationTime")}>
                  {t("avg_consultation_time")}
                  {sortedBy.columnName === "avgConsultationTime" && (
                    <span>
                      {sortedBy.direction === "ascending" ? (
                        <IconButton className="filterActive">
                          <SortDown />
                        </IconButton>
                      ) : (
                        <IconButton className="filterActive">
                          <SortUp />
                        </IconButton>
                      )}
                    </span>
                  )}
                </th>
                <th onClick={() => handleSort("status")}>
                  {t("current_status")}
                  {sortedBy.columnName === "status" && (
                    <span>
                      {sortedBy.direction === "ascending" ? (
                        <IconButton className="filterActive">
                          <SortDown />
                        </IconButton>
                      ) : (
                        <IconButton className="filterActive">
                          <SortUp />
                        </IconButton>
                      )}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            {filterDataList && filterDataList.length > 0 && (
              <tbody className="scrolableTableBody">
                {filterDataList.map((item) => (
                  <tr key={item.doctorId}>
                    <td>
                      <div className="tdText">{item?.doctorName || ""}</div>
                    </td>
                    <td>
                      <div className="tdText">
                        {item?.doctorSpecialization || ""}
                      </div>
                    </td>
                    <td>
                      <div className="tdText">{item?.numberOfConsult || 0}</div>
                    </td>
                    <td>
                      <div className="tdText">{item?.numberOfCenter || 0}</div>
                    </td>
                    <td>
                      <div className="tdText">{item?.avgWaitingTime || 0}</div>
                    </td>
                    <td>
                      <div className="tdText">
                        {item?.avgConsultationTime || 0}
                      </div>
                    </td>
                    <td>
                      <div className="tdText">
                        <span
                          className={
                            item.status === "Online"
                              ? `docStatOnline`
                              : `docStatOfline`
                          }
                        ></span>
                        {item.status === "Online" ? t("Online") : t("Offline")}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
