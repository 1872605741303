import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ConstrainedSurface,
  LogOut,
  ProfileCircle,
  ReportColumns,
  SystemRestart,
  UserBadgeCheck,
} from "iconoir-react";
import React, { useCallback, useEffect, useState } from "react";
import ConfirmDialog from "../../../common/ConfirmDialog";
import LoginSignupService from "../../../services/LoginSignupService";
import { GetLoginUserDetails } from "../../../utils/Utils";
import "../css/navbar.css";
import profileImg from "../image/logo.png";
import profileImg1 from "../image/6.png";
import profileImg2 from "../image/dummyuser-100.png";
import {
  Cancel,
  ContactsOutlined,
  CorporateFare,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import Profile from "../../profile/view/Profile";
import ProfileService from "../../../services/ProfileService";
import ConfirmModal from "./ConfirmModal";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AddTabContext } from "./DashboardPage";
import SubscriptionOptions from "../../organization/view/SubscriptionOptions";
import { v4 as uuidv4 } from "uuid";
import AppUtils from "../../../utils/AppUtils";
import { getOrgDetailsByOrgId } from "../../../services/AdminService";
import Register from "../../landingPage/view/component/Register";
import Contacts from "./component/Contacts";
import { useForm } from "react-hook-form";
import { XLg } from "react-bootstrap-icons";
import LogoutEvents from "./LogoutEvents";
import { ContactsFunctional } from "./component/ContactsFunctional";
import i18n from "i18next";
import HeaderSupportButton from "../../support/view/HeaderSupportButton";
import RightSideSupportContainer from "../../support/view/RightSideSupportContainer";
import { doctorSocket } from "../../../socket";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../utils/IndexedDB";
import Joyride from "react-joyride";
import { TourContext } from "../../../common/TourProvider";

export default function TopNavbar({ state }) {
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const loginUser = GetLoginUserDetails();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showLogoutConf, setShowLogoutConf] = useState(false);
  const [showProfileSettings, setProfileSettings] = useState(false);
  const [userOrgList, setUserOrgList] = useState([]);
  const [userAllInfo, setUserAllInfo] = useState(null);
  const [showSwithcAccountModal, setShowSwitchAccountModal] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const { addTab } = useContext(AddTabContext);
  const [showSupportRightPanel, setShowSupportRightPanel] = useState(false);

  const {
    handleSubmit,
    register,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const handelContracts = () => {
    setShowContacts(true);
    setAnchorElUser(null);
  };
  const handelProfile = () => {
    setProfileSettings(!showProfileSettings);
    setAnchorElUser(null);
  };
  const closeprofile = () => {
    setProfileSettings(false);
  };
  useEffect(() => {
    if (loginUser && loginUser.userType !== "GUEST") {
      // console.log(loginUser);
      ProfileService.getOrgListByLoginId(loginUser.userEmailId).then(
        (response) => {
          console.log("profile orgs =", response.data);
          setUserOrgList(response.data);
        }
      );

      ProfileService.getAllUserDetailsByUserId(loginUser.userId).then(
        (response) => {
          setUserAllInfo(response.data);
          const costCenterId = response.data.userDtls.costCenterId;
          SecureIndexedDB.setItem("COST_CENTER_ID", costCenterId);
        }
      );
    }

    // if(loginUser.userType !== "GUEST"){
    //   getOrgDetailsByOrgId(loginUser.org)
    // }
  }, []);

  const refreshUserData = useCallback(() => {
    ProfileService.getAllUserDetailsByUserId(loginUser.userId).then(
      (response) => {
        setUserAllInfo(response.data);
      }
    );
  }, []);

  // handle submit logout user start
  const handelSubmitLogout = async () => {
    const reqDto = {
      tokenId: null,
      device: navigator.userAgent,
      platform: "WEB",
    };
    try {
      LoginSignupService.clearFcmToken(loginUser.userId, reqDto);
    } catch (err) {
      console.log(err);
    }

    localStorage.clear();
    await SecureIndexedDB.clearAll();
    if (window.caches) {
      window.caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          window.caches.delete(cacheName);
        });
      });
    }
    window.location.replace(
      `${window.location.protocol}//${window.location.host}`
    );
  };
  // handle submit logout user end

  const confirmSwitch = () => {
    console.log("switched successfully");
    // localStorage.clear();
    let domainArray = window.location.host.split(".");
    let host = "";
    if (domainArray.length === 3) {
      host = domainArray[1] + "." + domainArray[2];
    } else {
      host = window.location.host;
    }
    if (selectedOrg.orgId !== "ORG000000000000") {
      console.log(
        "switched acc",
        window.location.protocol + "//" + selectedOrg.subdomain + "." + host
      );
      window.location.replace(
        window.location.protocol + "//" + selectedOrg.subdomain + "." + host
      );
    } else {
      console.log("switched acc", window.location.protocol + "//" + host);
      window.location.replace(window.location.protocol + "//" + host);
    }
  };

  const [isArrowDown, setArrowDown] = useState(true);
  const [showAccountsList, setAccountsList] = useState(false);
  const toggleAccountsList = () => {
    setAccountsList(!showAccountsList);
    setArrowDown(!isArrowDown);
  };

  const renderMenuList = () => {
    console.log("rendering menuList");
    return (
      <>
        {userOrgList &&
          userOrgList.length > 0 &&
          userOrgList.map((org) => (
            <MenuItem
              onClick={() => {
                // toast.error("Switching is not currrently implemented");
                console.log("user details = ", loginUser);
                if (loginUser.orgId !== org.orgId) {
                  switchAcc(org);
                } else {
                  toast.info(t("you_are_already_in_the_selected_organization"));
                }
                setAnchorElUser(null);
              }}
              className="listOfAccountItem"
            >
              <span className="listOrgImg">
                {org && org.orgImage && org.orgImage !== "" ? (
                  <img src={org.orgImage} alt="AccoutImg" />
                ) : (
                  <img src={profileImg} alt="AccoutImg" />
                )}
              </span>
              {org.orgName}
            </MenuItem>
          ))}
        {/* <MenuItem
          onClick={() => setAnchorElUser(null)}
          className="listOfAccountItem"
        >
          <span className="listOrgImg">
            <img src={profileImg} alt="AccoutImg" />
          </span>
          Zoyel engineering
        </MenuItem>

        <MenuItem
          onClick={() => setAnchorElUser(null)}
          className="listOfAccountItem"
        >
          <span className="listOrgImg">
            <img src={profileImg1} alt="AccoutImg" />
          </span>
          Ripple Effect
        </MenuItem>

        <MenuItem
          onClick={() => setAnchorElUser(null)}
          className="listOfAccountItem"
        >
          <span className="listOrgImg">
            <img src={profileImg2} alt="AccoutImg" />
          </span>
          Personal
        </MenuItem> */}
      </>
    );
  };

  const switchAcc = (org) => {
    console.log("switchAcc", org);
    setShowSwitchAccountModal(true);
    setSelectedOrg(org);
  };

  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const createAccountGuest = () => {};

  const defaultLanguage = window.location.hostname.includes("massalud")
    ? "es"
    : "en";

  // const [selectedLanguage, setSelectedLanguage] = useState("en");

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || defaultLanguage
  );

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  }, [selectedLanguage]);

  // const handleSelectedLanguageChange = (event) => {
  //   console.log("handgleChangeSelectedLanguage", event.target.value);
  //   i18n.changeLanguage(event.target.value);
  //   setSelectedLanguage(event.target.value);
  // };

  const handleSelectedLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
    setSelectedLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  const [isOnline, setIsOnline] = useState(false); // default to "Online"
  // useEffect(() => {
  //   console.log("Socket  =", doctorSocket);
  //   if (doctorSocket.connected) {
  //     setIsOnline(true);
  //   } else {
  //     setIsOnline(false);
  //   }
  // }, [doctorSocket.connected]);

  const handleSocketDisconnect = (data) => {
    setIsOnline(false);
  };
  const handleSocketConnect = (data) => {
    setIsOnline(true);
  };

  useEffect(() => {
    if (doctorSocket) {
      if (doctorSocket.connected) {
        setIsOnline(true);
      } else {
        setIsOnline(false);
      }
    }
    doctorSocket.on("disconnect", handleSocketDisconnect);
    doctorSocket.on("connect", handleSocketConnect);
    return () => {
      doctorSocket.off("disconnect", handleSocketDisconnect);
      doctorSocket.off("connect", handleSocketConnect);
    };
  }, [doctorSocket]);

  const handleChange = (event) => {
    console.log(doctorSocket);
    if (doctorSocket.connected) {
      doctorSocket.disconnect();
      // setIsOnline(false);
    } else {
      doctorSocket.connect();
      doctorSocket.emit("join", {
        userId: loginUser.userId,
        orgId: loginUser.orgId,
        // unitId: loginUser.signleUnitId,
      });
      // setIsOnline(true);
    }
    // setIsOnline(event.target.checked); // update state based on switch status
  };

  const [profileSteps, setProfileStep] = useState([]);
  const [createSTSteps, setCreateStStep] = useState([]);

  useEffect(() => {
    setProfileStep([
      {
        target: "#profileDetailsOne",
        content:
          "Click on this Avatar and then Select profile from the DropDown",
        disableBeacon: true,
      },
    ]);
    setCreateStStep([
      {
        target: ".supportTickOne",
        content: "Click on this Button to create Support Ticket",
        disableBeacon: true,
      },
    ]);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index } = data;
    console.log("status index", status, index);
    if (status == "finished" && index == 0) {
      if (run["creatSupportTicketAs"] === true) {
        setRun({ rightSideCreateTicket: true });
      }
    }
  };

  return (
    <>
      <Joyride
        steps={
          run["profilepf"]
            ? profileSteps
            : run["creatSupportTicketAs"]
            ? createSTSteps
            : []
        }
        run={run["profilepf"] === true || run["creatSupportTicketAs"] === true}
        continuous={true}
        callback={handleJoyrideCallback}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
        locale={{
          last: "Next",
        }}
      />
      <AppBar
        position="static"
        className="header"
        sx={{ bgcolor: "dark.pageBg" }}
      >
        <Container maxWidth="false" className="tempContainer">
          <Toolbar disableGutters variant="dense">
            {/* <ConstrainedSurface
              sx={{
                display: { xs: "flex", md: "flex", lg: "flex", xl: "flex" },
                mr: 1,
              }}
            /> */}
            {userAllInfo ? (
              <Avatar
                style={{
                  display: "flex",
                  marginRight: "10px",
                  width: "36px",
                  height: "36px",
                }}
                src={userAllInfo.orgDetails.orgLogo}
                alt="org logo"
              />
            ) : loginUser.userType === "GUEST" ? (
              <Avatar
                style={{
                  display: "flex",
                  marginRight: "10px",
                  width: "36px",
                  height: "36px",
                }}
                src={loginUser.orgLogo}
                alt="org logo"
              />
            ) : (
              <Skeleton
                animation="wave"
                variant="circular"
                width={36}
                height={36}
                style={{ marginRight: "10px", display: "flex" }}
              />
            )}
            {userAllInfo ? (
              <Typography
                variant="h5"
                noWrap
                component="a"
                className="orgNameTypography"
              >
                {userAllInfo.orgDetails.orgName}
              </Typography>
            ) : loginUser.userType === "GUEST" ? (
              <Typography
                variant="h5"
                noWrap
                component="a"
                className="orgNameTypography"
              >
                {loginUser.orgName}
              </Typography>
            ) : (
              <Skeleton
                animation="wave"
                variant="text"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "flex" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              />
            )}

            {loginUser.zhUserType === "DOCTOR" && (
              <div className="formElement mxW-100 docOnOffOption">
                <FormControlLabel
                  className="docOnOffLineSwitch"
                  control={
                    <Switch checked={isOnline} onChange={handleChange} />
                  }
                  label={isOnline ? t("online") : t("offline")}
                />
              </div>
            )}

            <div className="formElement mxW-100 supportOption supportTickOne">
              <HeaderSupportButton
                setShowSupportRightPanel={setShowSupportRightPanel}
                showSupportRightPanel={showSupportRightPanel}
              />
            </div>

            <div className="formElement mxW-100 langOption">
              <FormControl className="formControl">
                <Select
                  variant="outlined"
                  required
                  id="selectLang"
                  value={selectedLanguage}
                  onChange={handleSelectedLanguageChange}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Box className="usercardGrp" sx={{ flexGrow: 0 }}>
              {loginUser.userType === "GUEST" && (
                // <MenuItem onClick={() => setOpenRegisterModal(true)}>
                //   <UserBadgeCheck />
                //   Create Account
                // </MenuItem>
                <div
                  className="userInfo freeAccountBtn"
                  onClick={() => setOpenRegisterModal(true)}
                >
                  <MenuItem onClick={() => setOpenRegisterModal(true)}>
                    <div className="userName">{t("create_account")}</div>
                  </MenuItem>
                </div>
              )}
              <div className="userInfo">
                <div className="userName">
                  {loginUser && loginUser.userName}
                  {loginUser.userType === "GUEST" && t("guest_nav")}
                </div>
                <div className="UserOrg">{loginUser.orgName}</div>
              </div>
              <div id="profileDetailsOne">
                <Tooltip title={t("open_settings")}>
                  <IconButton
                    onClick={(event) => setAnchorElUser(event.currentTarget)}
                    sx={{ p: 0 }}
                  >
                    <Avatar
                      alt={loginUser && loginUser.userName}
                      src={`data:image/png;base64,${
                        userAllInfo ? userAllInfo.userDtls.profileImageLink : ""
                      }`}
                    />
                  </IconButton>
                </Tooltip>
              </div>

              <Menu
                className="topMenu"
                id="menu-appbar"
                anchorEl={anchorElUser}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
              >
                {loginUser.userType !== "GUEST" && (
                  <MenuItem
                    onClick={() => {
                      handelProfile();
                      if (run["profilepf"] === true) {
                        setRun({
                          viewProfileDetailsPF: true,
                        });
                      }
                    }}
                  >
                    <UserBadgeCheck />
                    {t("profile")}
                  </MenuItem>
                )}
                {/* {loginUser.userType === "GUEST" && (
                  <MenuItem onClick={() => setOpenRegisterModal(true)}>
                    <UserBadgeCheck />
                    Create Account
                  </MenuItem>
                )} */}
                {AppUtils.isPersonal(loginUser) && (
                  <MenuItem onClick={handelContracts}>
                    <ContactsOutlined />
                    {t("contacts")}
                  </MenuItem>
                )}
                {loginUser.userType !== "GUEST" && (
                  <MenuItem
                    onClick={toggleAccountsList}
                    className={showAccountsList ? "activeMenuList" : ""}
                  >
                    <ProfileCircle />
                    <div className="moreAccountLst">
                      <span>{t("accounts")}</span>
                      <span className="arrowIoc">
                        {isArrowDown ? (
                          <KeyboardArrowDown />
                        ) : (
                          <KeyboardArrowUp />
                        )}
                      </span>
                    </div>
                  </MenuItem>
                )}
                {showAccountsList && renderMenuList()}
                {/* <MenuItem onClick={() => setAnchorElUser(null)}>
                  <ReportColumns />
                  Dashboard
                </MenuItem> */}
                {loginUser.orgId === "ORG000000000000" && (
                  <MenuItem
                    onClick={() => {
                      addTab({
                        key: uuidv4(),
                        title: t("create_org"),
                        content: <SubscriptionOptions />,
                        isDraggable: true,
                      });
                      setAnchorElUser(null);
                    }}
                  >
                    <CorporateFare />
                    {t("create_org")}
                  </MenuItem>
                )}
                {/* <MenuItem onClick={() => setAnchorElUser(null)}>
                  <SystemRestart />
                  Take a Break
                </MenuItem> */}
                <MenuItem onClick={() => setShowLogoutConf(true)}>
                  <LogOut />
                  {t("logout")}
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {showLogoutConf && (
        <ConfirmDialog
          open={showLogoutConf}
          title={t("confirmation")}
          message={t("do_you_want_to_logout")}
          onCancel={() => setShowLogoutConf(false)}
          onOk={handelSubmitLogout}
        >
          <LogoutEvents />
        </ConfirmDialog>
      )}

      {showProfileSettings && (
        <Profile
          profileData={userAllInfo.userDtls.profileImageLink}
          closeprofile={closeprofile}
          refreshUserData={refreshUserData}
        />
      )}

      {showSwithcAccountModal && (
        <>
          {/* <ConfirmModal
          handleClose={(e) => {
            setShowSwitchAccountModal(false);
          }}
          org={selectedOrg}
        /> */}
          <ConfirmDialog
            open={showSwithcAccountModal}
            title={t("switch_account")}
            message={t("switch_to_org", { orgName: selectedOrg.orgName })}
            onCancel={() => setShowSwitchAccountModal(false)}
            onOk={() => {
              setShowSwitchAccountModal(false);
              confirmSwitch();
            }}
          />
        </>
      )}

      {showSupportRightPanel && (
        <RightSideSupportContainer
          setShowSupportRightPanel={setShowSupportRightPanel}
        />
      )}

      {openRegisterModal && (
        <Modal
          open={openRegisterModal}
          onClose={() => setOpenRegisterModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="coustomModel"
        >
          <Box
            className="modelBox"
            sx={{
              position: "absolute",
              width: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              opacity: 1,
            }}
          >
            <div className="modeltitle">{t("create_account")}</div>
            <div className="setModelFileName">
              <Register
                handelCloseLogRegContain={() => setOpenRegisterModal(false)}
                state={state}
              />
            </div>
            <div className="modelFooter">
              <div className="modActionBtnGrp">
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => setOpenRegisterModal(false)}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}

      {showContacts && (
        <Modal
          className="showContract coustomModel"
          open={showContacts}
          onClose={() => setShowContacts(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="ModelBox contactsModel" sx={{ boxShadow: 24, p: 4 }}>
            <IconButton
              className="closeContactModelBtn"
              onClick={() => {
                setShowContacts(false);
              }}
            >
              <XLg />
            </IconButton>
            <div className="modeltitle">{t("contacts")}</div>
            {/* <ThemeProvider theme={mdTheme}> */}
            {/* <Contacts
              register={register}
              handleSubmit={handleSubmit}
              errors={errors}
              setError={setError}
              reset={reset}
            /> */}

            {/* <ContactsFunctional
              register={register}
              handleSubmit={handleSubmit}
              errors={errors}
              setError={setError}
              reset={reset}
            /> */}

            {/* </ThemeProvider> */}

            <div className="modelFooter">
              <div className="modActionBtnGrp">
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => setShowContacts(false)}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}
